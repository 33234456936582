import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  IconDefinition,
  faCalendarAlt,
  faAngleDown
} from '@fortawesome/pro-regular-svg-icons';
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode
} from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'report-grid-filters',
  templateUrl: './grid-filters.component.html',
  styleUrls: ['./grid-filters.component.css']
})
export class GridFiltersComponent
  implements IFilterAngularComp, OnInit, OnChanges, AfterViewInit {
  @Input() usageLogData: any[];
  @Output() timeDate = new EventEmitter();
  @Output() userFilter = new EventEmitter();
  @Output() exportCsv = new EventEmitter();
  @Output() printGrid = new EventEmitter();
  faCalendarAlt: IconDefinition = faCalendarAlt;
  faAngleDown: IconDefinition = faAngleDown;
  details: any[];

  // Angular filtering
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public text: string = '';
  @ViewChild('input', { static: false }) public input;

  constructor() {}

  ngOnInit() {
    // this.usageLogData();
  }

  onDate() {
    this.timeDate.emit(null);
  }

  // Angular Filters

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    // return this.text !== null && this.text !== undefined && this.text !== '';
    return true;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.text
      .toLowerCase()
      .split(' ')
      .every(filterWord => {
        return (
          this.valueGetter(params.node)
            .toString()
            .toLowerCase()
            .indexOf(filterWord) >= 0
        );
      });
  }

  getModel(): any {
    return { value: this.text };
  }

  setModel(model: any): void {
    this.text = model ? model.value : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['usageLogData'].currentValue !== undefined) {
      this.details = Array.from(
        new Set(
          this.usageLogData.map(function(item) {
            return item.details;
          })
        )
      );
      // (this.details);
    }
  }

  onChange(newValue): void {
    if (this.text !== newValue) {
      this.text = newValue;
      this.userFilter.emit(newValue);
    }
  }

  onBtExport() {
    const params = {
      //   skipHeader: getBooleanValue("#skipHeader"),
      //   columnGroups: getBooleanValue("#columnGroups"),
      //   skipFooters: getBooleanValue("#skipFooters"),
      //   skipGroups: getBooleanValue("#skipGroups"),
      //   skipPinnedTop: getBooleanValue("#skipPinnedTop"),
      //   skipPinnedBottom: getBooleanValue("#skipPinnedBottom"),
      //   allColumns: getBooleanValue("#allColumns"),
      //   onlySelected: getBooleanValue("#onlySelected"),
      //   suppressQuotes: getBooleanValue("#suppressQuotes"),
      fileName: 'report'
      //   columnSeparator: document.querySelector("#columnSeparator").value
    };
    // if (getBooleanValue("#customHeader")) {
    //   params.customHeader = "[[[ This ia s sample custom header - so meta data maybe?? ]]]\n";
    // }
    // if (getBooleanValue("#customFooter")) {
    //   params.customFooter = "[[[ This ia s sample custom footer - maybe a summary line here?? ]]]\n";
    // }
    this.exportCsv.emit(params);
  }

  onBtPrint() {
    this.printGrid.emit();
  }
}
