<div class="disclosure-container">
  <report-loader></report-loader>

  <h1 class="title report-main-content-padding pdf">
    {{ title }}
  </h1>
  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="disclosureToolbarConfig"
    [toolbarConfig]="disclosureToolbarConfig"
  ></report-finra-toolbar>

  <div id="grid-container" class="grid-container" *ngIf="reportLoadedSuccessfully">
    <!-- Custom grid only for disclosure 4530 report -->
    <disclosure-timeliness
      class="pdf"
      *ngIf="reportName == 'd4530'"
      [rowData]="rowData['firm4530DisclosureFilings']"
      [reportDate]="reportDate"
    ></disclosure-timeliness>
    <div
      class="table-container table-{{ reportName }} pdf"
      *ngFor="let tableName of tableNamesByOrder"
    >
      <div class="table-header">
        {{ tableTitles[tableName] }}
      </div>
      <report-finra-grid
        #reportfinragrid
        id="id"
        [columnDefs]="columnDefs[tableName]"
        [rowData]="rowData[tableName]"
      ></report-finra-grid>
    </div>
  </div>

  <hr />

  <div
    class="report-main-content-padding report-disclaimer pdf"
    *ngIf="disclaimers[reportName]"
    [innerHtml]="disclaimers[reportName]"
  ></div>
</div>
