import { Component, ElementRef, OnDestroy } from '@angular/core';
import { IHeaderParams } from 'ag-grid-community';
import { IHeaderAngularComp } from 'ag-grid-angular';

@Component({
  templateUrl: 'finra-grid-header.component.html',
  styleUrls: ['finra-grid-header.component.scss']
})
export class FinraGridHeaderComponent implements OnDestroy, IHeaderAngularComp {
  public params: IHeaderParams;
  public sorted: string;
  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  agInit(params: IHeaderParams): void {
    this.params = params;

    if (params.context && params.context['registerHeader']) {
      params.context['registerHeader'](this);
    }
  }

  ngOnDestroy() {}

  onMenuClick() {
    this.params.showColumnMenu(this.querySelector('.customHeaderMenuButton'));
  }

  toggleSorting() {
    if (this.sorted === 'asc') {
      this.sorted = 'desc';
    } else if (this.sorted === 'desc') {
      this.sorted = '';
    } else {
      this.sorted = 'asc';
    }

    this.params.setSort(this.sorted);
  }

  private querySelector(selector: string) {
    return <HTMLElement>(
      this.elementRef.nativeElement.querySelector(
        '.customHeaderMenuButton',
        selector
      )
    );
  }
}
