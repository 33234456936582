<div class="equity-container">
  <report-loader></report-loader>
  <report-alert class="report-main-content-padding no-print"></report-alert>
  <h1 class="title report-main-content-padding pdf">
    {{ title }}
  </h1>
  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="equityToolbarConfig"
    [toolbarConfig]="equityToolbarConfig"
  ></report-finra-toolbar>

  <div id="grid-container" class="grid-container" *ngIf="reportLoadedSuccessfully">
    <ng-container *ngIf="hasPeriodData" [ngSwitch]="reportName">
      <ng-container *ngSwitchCase="EQUITY_REPORT_IDENTIFIERS.OATSM">
        <ng-container *ngFor="let section of oatsSections">
          <report-finra-grid
            [columnDefs]="columnDefs"
            [rowData]="rowData[section]"
            class="pdf"
            [gridOptions]="gridOptions"
            [autoGroupColumnDef]="autoGroupColumnDefs"
          ></report-finra-grid>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="EQUITY_REPORT_IDENTIFIERS.MOTS">
        <div class="mots-table-container bg-white table-container" *ngFor="let mapping of motsDataMapping">
          <div class="table-header pdf" *ngIf="marketOrderTimelinessStatisticalTitles[mapping]">
            <div>
              <span>
                <fa-icon
                  data-html2canvas-ignore
                  [icon]="marketOrderTimelinessStatisticalCollapsedState[mapping] ? faChevronRight : faChevronDown"
                  matTooltip="{{ marketOrderTimelinessStatisticalCollapsedState[mapping] ? 'Show' : 'Hide' }}"
                  class="fa-lg cursor-pointer table-collapse-btn"
                  (click)="marketOrderTimelinessStatisticalCollapsedState[mapping] = !marketOrderTimelinessStatisticalCollapsedState[mapping]"
                ></fa-icon>
              </span>
              <span>{{ marketOrderTimelinessStatisticalTitles[mapping] }}</span>
            </div>

          </div>
          <div class="mots-table" *ngIf="!marketOrderTimelinessStatisticalCollapsedState[mapping]">
            <report-finra-grid
              [columnDefs]="columnDefs[mapping]"
              [rowData]="rowData[mapping]"
              class="pdf"
              [containerCssClass]="'finra-grid-container-mots'"
              [gridOptions]="gridOptions"
              [autoGroupColumnDef]="autoGroupColumnDefs"
            ></report-finra-grid>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <report-finra-grid
          [columnDefs]="columnDefs"
          [rowData]="rowData"
          class="pdf"
          [gridOptions]="gridOptions"
          [autoGroupColumnDef]="autoGroupColumnDefs"
        ></report-finra-grid>
      </ng-container>
    </ng-container>
  </div>
  <hr />
  <div
    class="report-main-content-padding report-disclaimer pdf"
    *ngIf="disclaimer"
    [innerHtml]="disclaimer"
  ></div>
</div>
