/**
 * Define all the types of errors from HTTP requests.
 *
 * For reference of status codes,
 * see this link: `https://en.wikipedia.org/wiki/List_of_HTTP_status_codes`
 */


export enum ClientErrors {
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    ProxyAuthRequired = 407,
    RequestTimeout = 408,
    TooManyRequests = 429,
}

export enum ServerErrors {
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
    HttpVersionNotSupported = 505,
}
