import {
  ToolbarConfigOptions,
  ToolbarConfig,
  ToolbarComponentTypes,
  ToolbarControlComponentTypes,
} from 'src/app/configs/model/finra-toolbar.model';
import { weakSort } from 'src/app/shared/utils/utils';

export const DqsToolbarConfig = (
  config: ToolbarConfigOptions
): ToolbarConfig => ({
  description: config.description,
  containerClass: 'some-class',
  rows: [
    // first row
    {
      rowClass: 'display-flex flex-space-between',
      components: [
        {
          cellClass: 'display-flex',
          components: [
            {
              cellClass: 'margin-right-30',
              component: {
                type: ToolbarComponentTypes.LABEL,
                label: 'Firm',
                value: config.firmName,
                labelId: 'firmName',
              },
            },
            {
              cellClass: 'margin-right-30',
              component: {
                type: ToolbarComponentTypes.LABEL,
                label: 'CRD ID',
                value: config.crdId,
                labelId: 'crdId',
              },
            },
          ],
        },
        {
          cellClass: 'help-btn',
          cellId: 'help-button-cell',
          component: {
            type: ToolbarComponentTypes.BUTTON,
            buttonLabel: 'Help',
            buttonIcon: config.helpIcon,
            buttonIconClass: '',
            buttonId: 'help-button',
            buttonClass: 'pointer-cursor',
            clickHandler: config.helpBtnCallback,
          },
        },
      ],
    },
    // second row
    {
      rowClass: 'display-flex flex-space-between',
      components: [
        {
          cellClass: 'display-flex',
          components: [
            {
              cellClass: 'margin-right-15',
              component: {
                type: ToolbarComponentTypes.CONTROL,
                controlType: ToolbarControlComponentTypes.SELECT,
                controlLabel: 'Exam Year:',
                dataList: config.examYears,
                formControl: config.examYearControl,
                formControlClass: '',
                formControlStyle: { width: '60px', margin: '0px 10px 0px 0px' },
                onSelectionChange: config.onExamYearChange,
              },
            },
            {
              cellClass: 'margin-right-15',
              component: {
                type: ToolbarComponentTypes.CONTROL,
                controlType: ToolbarControlComponentTypes.SELECT,
                controlLabel: 'Exam ID:',
                dataList: config.examIds,
                formControl: config.examIdControl,
                formControlClass: '',
                formControlStyle: {
                  width: '140px',
                  margin: '0px 20px 0px 0px',
                },
                onSelectionChange: config.onExamIdChange,
              },
            },
            {
              cellClass: 'margin-right-15',
              component: {
                type: ToolbarComponentTypes.CONTROL,
                controlType: ToolbarControlComponentTypes.SELECT,
                controlLabel: 'Request ID:',
                dataList: config.requestIds,
                formControl: config.requestIdControl,
                formControlClass: '',
                formControlStyle: {
                  width: '110px',
                  margin: '0px 20px 0px 0px',
                },
                onSelectionChange: config.onRequestIdChange,
              },
            },
            {
              cellClass: 'margin-right-30',
              component: {
                type: ToolbarComponentTypes.LABEL,
                label: 'As of Date',
                valueObject: config.asOfDateObj,
                useValueObject: true,
                valueObjectProp: config.asOfDateObjProp,
                labelId: 'asOfDate',
                displayPipeFilter: 'date-month-day-year-numbers',
              },
            },
            {
              cellClass: 'margin-right-15',
              component: {
                type: ToolbarComponentTypes.BUTTON,
                buttonLabel: 'Go',
                buttonIcon: null,
                buttonIconClass: '',
                buttonClass: '',
                buttonStyle: {
                  'font-size': '1.5em'
                },
                clickHandler: config.changeClickHandler,
              }
            }
          ],
        },
        {
          cellClass: 'pdf-btn',
          cellReadyCallback: config.setPdfClickHandler,
          component: {
            type: ToolbarComponentTypes.BUTTON,
            buttonLabel: 'Print',
            buttonIcon: config.pdfIcon,
            buttonIconClass: 'pointer-cursor',
            buttonClass: '',
            clickHandler: null,
          },
        },
      ],
    },
  ],
});
