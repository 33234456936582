<div id="upload-business-objects-container">
  <report-loader></report-loader>

  <h1 class="title pdf">Upload/Register DM Object</h1>

  <ng-container [ngSwitch]="hasAuthorization === null">
    <div *ngSwitchCase="true">
      <!-- is loading permission -->
    </div>

    <div *ngSwitchCase="false" [ngSwitch]="hasAuthorization">
      <div *ngSwitchCase="false">
        <!-- User does not have permission -->
        <section class="description-section">
          <p>
            You do not have permission to use this functionality. Please contact support.
          </p>
        </section>
      </div>

      <div *ngSwitchCase="true">
        <!-- user does have permission -->
        <section class="description-section">
          <p>
            Files uploaded are sent to DM for registration and upload. The selected file name must match an accepted format. The list of currently accepted reports are below.
          </p>

          <div class="examples-table-container">
            <table>
              <thead>
                <tr>
                  <th>DM Object Name</th>
                  <th>Report Name</th>
                  <th>File Name Format</th>
                  <th>Example</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let accepted of accepted_file_formats_object_name_examples">
                  <td>{{ accepted.objectName }}</td>
                  <td>{{ accepted.reportName }}</td>
                  <td>{{ accepted.format }}</td>
                  <td>{{ accepted.example }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section class="description-section">
          <p>
            Select an appropriate CSV file containing the business object to upload and register to DM.
          </p>
          <p>
            You may click anywhere in the shaded box below to manually select multiple files or drag multiple files into the shaded box.
          </p>
          <p>
            Files that correspond to a report and period that already exists will be ignored if "Create new version" is not checked.
          </p>

          <form id="dropFilesZoneElm" #dropFilesZoneElm (drop)="dropHandler($event)" (dragover)="onDragOver($event, dropFilesZoneElm)" (dragleave)="onDragLeave($event, dropFilesZoneElm)">
            <input #dropFilesZoneInputElm type="file" accept="text/csv" multiple id="dropFilesZoneInputElm" (change)="onFilesSelected($event)" />
            <label [attr.data-isDragging]="isDragging" #dropFilesZoneLabelElm id="dropFilesZoneLabelElm" for="dropFilesZoneInputElm">
              <div class="text-center" id="dropFilesZoneInnerElm" [ngClass]="{ 'text-black': isDragging }">
                <p>
                  Click<br/>
                  or<br/>
                  Drop Files Here
                </p>
                <fa-icon [icon]="faUpload" class="fa-lg"></fa-icon>
              </div>
            </label>
          </form>

          <div *ngIf="selectedFiles.length">
            <div class="flex-row">
              <p>Selected files for upload.</p>
              <button mat-flat-button color="accent" (click)="clearInput()">Clear Form</button>
            </div>

            <div class="csv-table-container">
              <table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>File Size</th>
                    <th>DM Object Name</th>
                    <th>Partition</th>
                    <th>Create new version?</th>
                    <th>Data Exists?</th>
                    <th>Remove</th>
                    <!-- <th>Upload Result</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let fileInfo of selectedFiles; let index = index">
                    <td>{{ fileInfo.file.name }}</td>
                    <td>{{ fileInfo.file.size | bytes }}</td>
                    <td>{{ fileInfo.dm_object_name }}</td>
                    <td>{{ fileInfo.partition }}</td>
                    <td>
                      <div class="flex-center">
                        <mat-checkbox [(ngModel)]="fileInfo.isNewDataVersion"></mat-checkbox>
                      </div>
                      <p class="text-center" *ngIf="!fileInfo.isNewDataVersion && !!fileInfo.objectExists">The data you are trying to upload has already been created.</p>
                    </td>
                    <td>
                      <div class="text-center">
                        <ng-container [ngSwitch]="fileInfo.loading">
                          <div *ngSwitchCase="true">
                            <finra-loading-spinner></finra-loading-spinner>
                          </div>
                          <div *ngSwitchCase="false">
                            <span>{{ fileInfo.objectExists === true ? 'Yes' : fileInfo.objectExists === false ? 'No' : '' }}</span>
                          </div>
                        </ng-container>
                      </div>
                    </td>
                    <td>
                      <div class="remove-file-td">
                        <fa-icon [icon]="faTrash" class="fa-lg cursor-pointer" (click)="selectedFiles.splice(index, 1)"></fa-icon>
                      </div>
                    </td>
                    <!-- <td>
                      <div class="upload-results-file-td">
                        <fa-icon
                          *ngIf="fileInfo.uploadingResult !== null"
                          [icon]="fileInfo.uploadingResult ? faCheck : faTimes"
                          class="fa-lg"
                          [ngStyle]="{ 'color': fileInfo.uploadingResult ? 'green' : 'red' }"
                          matTooltip="{{ fileInfo.uploadingResult ? 'Request for processing was successful' : 'Request for processing failed for this file; something went wrong...' }}"
                        ></fa-icon>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <p>If this information looks correct, you may proceed to upload/register by clicking "Upload".</p>
              <button mat-flat-button color="accent" (click)="uploadFiles()" [disabled]="!canUpload">Upload</button>
              <!-- <div *ngIf="isLoading">
                <finra-loading-spinner></finra-loading-spinner>
              </div> -->
            </div>
          </div>


          <div class="flex-row">
            <!-- <input #inputFileElm class="dm-object-input" type="file" name="dm-object" id="dm-object-input" (change)="fileSelected($event)" /> -->

            <!-- <div>
              <mat-checkbox
                [(ngModel)]="isNewDataVersion"
              ></mat-checkbox>
              <span>Create new version is one already exists?</span>
            </div> -->
            <!-- <button *ngIf="selectedFileInfo" mat-flat-button color="accent" (click)="clearInput()">Clear Form</button> -->
          </div>

          <!-- if the object exists and user does not want to create a new data version -->
          <!-- <div *ngIf="!isNewDataVersion && !!objectExists">
            <br>
            <p>The data you are trying to upload has already been created.</p>
          </div> -->

          <!-- if user wantss to create a new data version -->
          <!-- <div *ngIf="selectedFileInfo && isNewDataVersion">
            <p>The following information was identified:</p>
            <div class="info-container">
              <p>DM Object Name: {{ selectedFileInfo.dm_object_name }}</p>
              <p>Partition: {{ selectedFileInfo.partition }}</p>

              <div *ngIf="csvParseResults" class="csv-data-container">
                <button mat-flat-button color="accent" (click)="isShowingCsvData = !isShowingCsvData">{{ isShowingCsvData ? 'Hide File Contents' : 'Show File Contents' }}</button>
                <br *ngIf="isShowingCsvData" />

                <div *ngIf="isShowingCsvData" class="csv-table-container">
                  <p>
                    NOTE: an asterisk (*) represents empty data for UI only; it is not a character found in the actual file
                  </p>
                  <p>{{ csvParseResults.data.length | number }} records found</p>

                  <table>
                    <tbody>
                      <tr *ngFor="let row_data of csvParseResults.data">
                        <td *ngFor="let column_data of row_data">{{ column_data === '' ? '*' : column_data }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>



            <div>
              <p>If this information and the selected file is correct, you may proceed to upload/register by clicking "Upload".</p>
              <button mat-flat-button color="accent" (click)="uploadFile()" [disabled]="isLoading">Upload</button>
            </div>
          </div> -->
        </section>
      </div>
    </div>
  </ng-container>
</div>
