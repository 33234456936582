import { PRIMARY_OFFERING_DISCLOSURE_COLUMN_DEFS } from './primary-offering-disclosure.model';
import { G_32_COLUMN_DEFS } from './g32-underwriting.model';
import { TRADES_BELOW_COLUMS_DEFS } from './trades-below.model';
import {
  UNDERWRITER_FIN_COL_DEFS,
} from './underwriter-financial-status.model';
import {
  FIRM_COLUMN_DEFS,
} from './markup-markdown-analysis.model';
import {
  ALL_MSRB_CONTINUING_DISCLOSURES_COLUMN_DEFS,
} from './continuing-disclosure.model';
import { ReportInstanceMetadata } from '../reports.model';

/** MSRB information */

export const MSRB_REPORT_CATEGORY_ID = 3;

export enum MsrbReportTypes {
  TRADES_BELOW_MINIMUM_DENOMINATION = 46,
  PRIMARY_OFFERING_DISCLOSURE = 30,
  G_32_UNDERWRITING = 26,
  MARKUP_MARKDOWN_ANALYSIS = 77,
  UNDERWRITER_FINANCIAL_STATUS = 45,
  DUE_DILIGENCE_SUMMARY = 47,
  CONTINUING_DISCLOSURE = 32
}

export enum MsrbReportNames {
  TRADES_BELOW_MINIMUM_DENOMINATION = 'mindenomination',
  PRIMARY_OFFERING_DISCLOSURE = 'mpodr',
  G_32_UNDERWRITING = 'g32',
  MARKUP_MARKDOWN_ANALYSIS = 'msrbmumd',
  UNDERWRITER_FINANCIAL_STATUS = 'MUFSR',
  DUE_DILIGENCE_SUMMARY = 'MDDR',
  CONTINUING_DISCLOSURE = 'mcd'
}

export const msrbNumberReportsByTypeId = [
  MsrbReportTypes.G_32_UNDERWRITING,
  MsrbReportTypes.DUE_DILIGENCE_SUMMARY,
  MsrbReportTypes.UNDERWRITER_FINANCIAL_STATUS,
];

export const mpidReportsByTypeId = [
  MsrbReportTypes.TRADES_BELOW_MINIMUM_DENOMINATION,
  MsrbReportTypes.PRIMARY_OFFERING_DISCLOSURE,
  MsrbReportTypes.MARKUP_MARKDOWN_ANALYSIS,
  MsrbReportTypes.CONTINUING_DISCLOSURE,
];

export const showDetailLinkReportTypeIDs = [
  MsrbReportTypes.MARKUP_MARKDOWN_ANALYSIS,
  MsrbReportTypes.CONTINUING_DISCLOSURE,
  MsrbReportTypes.UNDERWRITER_FINANCIAL_STATUS
];

export const showViewsDropdownReportTypeIDs = [
  MsrbReportTypes.TRADES_BELOW_MINIMUM_DENOMINATION,
  MsrbReportTypes.MARKUP_MARKDOWN_ANALYSIS,
  MsrbReportTypes.CONTINUING_DISCLOSURE
];

export interface MsrbPeerGroupParams {
  reportInstanceMetadata: ReportInstanceMetadata,
  rowData: any;
  reportId: number;
  reportDate: string;
  reportName: string;
  viewName: string;
  columnTitle?: string;
  useField: string;
  tierCode: string;
  printFn: () => void;
}

// all MSRB reports are monthly; no other views
export const download_tradesbelow =
  'MSRB-Trades-Below-Minimum-Denomination_${firmId}_${reportPeriodDate}';
export const download_primaryofferingdisclosure =
  'MSRB-Primary-Offering-Disclosure_${firmId}_${reportPeriodDate}';
export const download_continuingdisclosure =
  'MSRB-Continuing-Disclosure_${firmId}_${reportPeriodDate}';
export const download_markupmarkdownanalysis =
  'MSRB-Markup-Markdown-Analysis_${firmId}_${reportPeriodDate}';
export const download_g32underwriting =
  'MSRB-G-32-Report-Underwriting_${firmId}_${reportPeriodDate}';
export const download_underwriterfinancialstatus =
  'MSRB-Underwriter-Financial-Status_${firmId}_${reportPeriodDate}';
export const download_duediligencesummary =
  'MSRB-Due-Diligence-Summary_${firmId}_${reportPeriodDate}';

export const DOWNLOAD_FILE_NAMES = {
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: {
    _default: `Details_${download_tradesbelow}_Monthly`,
    pdf: `${download_tradesbelow}_PDF`
  },
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: {
    _default: `Details_${download_primaryofferingdisclosure}_Monthly`,
    pdf: `${download_primaryofferingdisclosure}_PDF`
  },
  [MsrbReportNames.CONTINUING_DISCLOSURE]: {
    _default: `Details_${download_continuingdisclosure}_Monthly`,
    pdf: `${download_continuingdisclosure}_PDF`
  },
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: {
    _default: `Details_${download_markupmarkdownanalysis}_Monthly`,
    pdf: `${download_markupmarkdownanalysis}_PDF`
  },
  [MsrbReportNames.G_32_UNDERWRITING]: {
    _default: `Details_${download_g32underwriting}_Monthly`,
    pdf: `${download_g32underwriting}_PDF`
  },
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: {
    _default: `Details_${download_underwriterfinancialstatus}_Monthly`,
    pdf: `${download_underwriterfinancialstatus}_PDF`
  },
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: {
    _default: `Details_${download_duediligencesummary}_Monthly`,
    pdf: `${download_duediligencesummary}_PDF`
  }
};

export const MsrbFirmNameLabels = {
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: 'Firm',
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: 'Firm',
  [MsrbReportNames.G_32_UNDERWRITING]: 'Underwriter',
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: 'Firm',
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: 'Firm',
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: 'Firm',
  [MsrbReportNames.CONTINUING_DISCLOSURE]: 'Firm'
};

export const MsrbFirmIdLabels = {
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: 'MPID',
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: 'MPID',
  [MsrbReportNames.G_32_UNDERWRITING]: 'MSRB Number',
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: 'MPID',
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: 'MSRB Number',
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: 'MSRB Number',
  [MsrbReportNames.CONTINUING_DISCLOSURE]: 'MPID'
};

export const MsrbPeerGroupFirmIdLabels = {
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: 'MPID',
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: null, // report does not have peer group
  [MsrbReportNames.G_32_UNDERWRITING]: 'MSRB ID',
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: null, // report does not have peer group
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: 'MSRB ID', // report does have peer group but handled elsewhere
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: 'MSRB ID',
  [MsrbReportNames.CONTINUING_DISCLOSURE]: 'MPID'
};

export const MsrbPeerGroupFields = {
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: 'firmId',
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: null, // report does not have peer group
  [MsrbReportNames.G_32_UNDERWRITING]: 'firmId',
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: null, // report does not have peer group
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: 'firmId', // report does have peer group but handled elsewhere
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: 'firmId',
  [MsrbReportNames.CONTINUING_DISCLOSURE]: 'firmId'
};

/** Toolbar Descriptions */

const tradesBelowToolbarDescription: string =
  'This report displays statistics about customer transactions your firm effected with customers with volumes below the ' +
  'minimum denomination reported to DTCC\'s New Issue Information Dissemination Service and/or provided by Thomson Reuters. ' +
  'This report is designed to aid firms in monitoring their compliance with MSRB rules, which apply to all broker-dealers conducting ' +
  'municipal securities transactions with customers.' +
  '\n\n' +
  'For questions regarding this specific report, please contact your firm\'s Coordinator ' +
  'or <a href="mailto:reportcenter-admin@finra.org">reportcenter-admin@finra.org</a>.';

const primaryOfferingsDisclosureToolbarDescription: string =
  'This report displays statistics about transactions your firm effected with customers during the securities\' ' +
  'Primary Offering Disclosure Period. This report is designed to aid firms in monitoring their compliance with Rule G-32(a) ' +
  'customer disclosure requirements, which apply to all broker-dealers selling offered municipal securities.' +
  '\n\n' +
  'If you have questions regarding the content of this report, please contact FINRA Regulatory Operations at (816) 802-4714.';

const continuingDisclosureToolbarDescription: string =
  'This report displays statistics about transactions your firm effected with customers and information about ' +
  'the availability of financial and event filings submitted to EMMA pursuant to SEC Rule 15c2-12 and MSRB Rule G-32. ' +
  'The report is designed to support a firm\'s supervision associated with MSRB rule G-47. Additional material information about ' +
  'transactions may exist that is not included in this report.' +
  '\n\n' +
  'If you have questions regarding the content of this report, please contact FINRA Regulatory Operations at (816) 802-4714.';

const markupMarkdownAnalysisToolbarDescription: string =
  'The report is a monthly compliance tool that provides member firms an analysis of the markup, markdown, ' +
  'and customer-to-customer pairs identified by FINRA during the month. This report and supporting transaction details ' +
  'are designed to support firms\' fair pricing and disclosure compliance procedures and reviews.' +
  '\n\n' +
  'If you have questions regarding the content of this specific report, please contact the FINRA Market Regulation Department' +
  'at (800) 321-6273 and ask for the Fixed Income Team.';

const g32UnderwritingToolbarDescription: string =
  'This report provides a summary of MSRB Rule G-32 documents, exempt filings, ' +
  'and cancellations submitted by your firm to EMMA for the months displayed. ' +
  '\n\n' +
  'Click the month hyperlink for the details of the filings submitted for that month, ' +
  'or use the View drop-down to navigate to the filing details. ' +
  '\n\n' +
  'If inaccuracies are discovered or you have questions regarding the content of this specific report, ' +
  'please contact FINRA Regulatory Operations at (816) 802-4714.';

const underwriterFinancialStatusToolbarDescription: string =
  'The Underwriter Financial Status report is a monthly report that shows all outstanding issuances ' +
  'that have previously been brought to market by a firm. The report shows which of these outstanding ' +
  'issuances are lacking current financials (LCF) in order to help firms evaluate the timeliness of financial disclosures made to EMMA.' +
  '\n\n' +
  'If inaccuracies are discovered or you have questions regarding the content of this specific report, ' +
  'please contact FINRA Regulatory Operations at (816) 802-4714.';

const dueDiligenceSummaryToolbarDescription: string =
  'The MSRB Due Diligence Report Card is a monthly report that shows total issuances brought to market by a firm. ' +
  'The report shows which of these issuances are Lacking Current Financials (LCF) in order to help firms evaluate the ' +
  'timeliness of financial disclosures made to EMMA. Click the month hyperlink for the details of the filings submitted ' +
  'for that month, or use the View drop-down to navigate to the filing details.' +
  '\n\n' +
  'If you have questions regarding the content of this report, please contact FINRA Regulatory Operations at (816) 802-4714.';

export const MsrbToolbarDescriptions = {
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: tradesBelowToolbarDescription,
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: primaryOfferingsDisclosureToolbarDescription,
  [MsrbReportNames.CONTINUING_DISCLOSURE]: continuingDisclosureToolbarDescription,
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: markupMarkdownAnalysisToolbarDescription,
  [MsrbReportNames.G_32_UNDERWRITING]: g32UnderwritingToolbarDescription,
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: underwriterFinancialStatusToolbarDescription,
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: dueDiligenceSummaryToolbarDescription
};

/** Footer Message */

const primaryOfferingDisclosureFooterMessage: string =
  '* If this symbol appears on your report, this indicates that no data has been provided to FINRA ' +
  'for this value for the specified period. This is an expected behavior for weekday holidays but may also ' +
  'indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'This information is provided to assist FINRA members in assessing and monitoring compliance with certain reporting obligations. ' +
  'All filing information contained in this report has been provided by the MSRB and its accuracy has not been verified by FINRA.' +
  '\n\n' +
  'This report uses data from the MSRB\'s EMMA system to identify the Primary Offering Disclosure Period of each security. ' +
  'If a security\'s offering information has not been submitted to and disseminated by EMMA, sales of that security may not ' +
  'be identified as occurring during the Primary Offering Disclosure Period on this report. ' +
  'To review the MSRB\'s definition of Primary Offering Disclosure Period, see MSRB Rule G-32(d)(ix). ' +
  'For a definition of offered municipal securities, review MSRB Rule G-32(d)(vi).';

const tradesBelowFooterMessage: string =
  `* If this symbol appears on your report, this indicates that no data has been provided to FINRA for this value \
  for the specified period. This is an expected behavior for weekday holidays but may also indicate information that was not \
  sent, incorrectly formatted, or late.

  This information is provided to assist FINRA members in assessing and monitoring compliance with MSRB rules. \
  This report uses data from the DTCC's New Issue Information Dissemination Service (NIIDS) system and licensed \
  data from Thomson Reuters to identify the minimum denomination of the issue. If a security's information has not been \
  submitted to and disseminated by NIIDS (or submitted and disseminated prior to FINRA's collection of this information) \
  or available in Thomson Reuters data feed, transactions of that security may not be identified on this report. \
  Completeness of the NIIDS information or Thomson Reuters information contained in this report has not been verified by FINRA.`;

const continuingDisclosureFooterMessage: string =
  `* If this symbol appears on your report, this indicates that no data has been provided to FINRA for this value \
  for the specified period. This is an expected behavior for weekday holidays but may also indicate \
  information that was not sent, incorrectly formatted, or late.

  ** Trades in issues exempt from OS requirement per MSRB Rule G-32 are not included in this count.

  Brokers, dealers and municipal securities dealers are required to obtain, analyze and disclose all material facts \
  about secondary market transactions that are known to the dealer, or that are reasonably accessible to the market \
  through established industry sources. This report uses data from the MSRB's EMMA system. For information about what is \
  required to be submitted to EMMA, please review Securities Exchange Act Rule 15c2-12, MSRB Rule G-17, \
  MSRB Rule G-32, and FINRA Regulatory Notice 10-41.`;

const markupMarkdownAnalysisFooterMessage: string =
  'An * in the Firm Section indicates that there were no customer transactions by your firm during the month.' +
  '\n' +
  'An * in the Industry Section indicates that there were not at least five pairs for the relevant criteria.' +
  '\n\n' +
  'This report is a compliance tool designed to provide statistics related to the markup, markdown, ' +
  'and customer-to-customer pairs identified by FINRA. Member firms should make no inference that staff of ' +
  'FINRA has or has not determined that the information contained on the ' +
  'Markup/Markdown Analysis does or does not constitute rule violations.';

const g32UnderwritingFooterMessage: string =
  '* If this symbol appears on your report, this indicates that no data has been provided to FINRA ' +
  'for this value for the specified period. This is an expected behavior for weekday holidays but may also ' +
  'indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'This information is provided to assist FINRA members in assessing and monitoring compliance with certain ' +
  'reporting obligations. All filing information contained in this report has been provided by the MSRB and ' +
  'its accuracy has not been verified by FINRA.';

const underwriterFinancialStatusFooterMessage: string =
  '* If this symbol appears on your report, this indicates that no data has been provided to FINRA ' +
  'for this value for the specified period. This is an expected behavior for weekday holidays but may also ' +
  'indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'This information is provided to assist FINRA members in assessing and monitoring compliance with certain reporting obligations. ' +
  'All filing information contained in this report has been provided by the MSRB and its accuracy has not been verified by FINRA.';

const dueDiligenceSummaryFooterMessage: string =
  'An * indicates that information was not available or applicable for this period.' +
  '\n\n' +
  'This information is provided to assist FINRA members in assessing and monitoring compliance with certain disclosure obligations. ' +
  'The filing information contained in this report has been provided by the MSRB and its accuracy has not been verified by FINRA.';

export const MsrbFooterMessages = {
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: tradesBelowFooterMessage,
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: primaryOfferingDisclosureFooterMessage,
  [MsrbReportNames.CONTINUING_DISCLOSURE]: continuingDisclosureFooterMessage,
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: markupMarkdownAnalysisFooterMessage,
  [MsrbReportNames.G_32_UNDERWRITING]: g32UnderwritingFooterMessage,
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: underwriterFinancialStatusFooterMessage,
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: dueDiligenceSummaryFooterMessage
};

/* Table Titles */

export const TABLE_TITLES = {
  // g32
  firmIndustry: 'Transactions',
  officialStatement: '',
  remarketing: '',
  advanceRefunding: '',
  municipalFund: '',
  exemptFilings: '',

  // Underwriter Fin
  firm: 'Firm',
  peerGroup: 'Peer Group',
  industry: 'Industry',

  // continuing disclosures
  allMunicipalTransaction: 'All Municipal Transactions',
  transactionsWithNoOSOnEmma: 'Transactions for Issues with No OS on EMMA**',
  lackingCurrentFinancials: 'Transactions for Issues Lacking Current Financials',
  transactionDuringTradeMonth: 'Transactions for Issues with 1 or More Events Filed During Trade Month',
  transactionDuring1To3O: 'Transactions for Issues with 1 or More Events Filed in 1st - 3rd Months Prior to the Trade Month',
  transactionDuring4To6: 'Transactions for Issues with 1 or More Events Filed in 4th - 6th Months Prior to the Trade Month',

  // trades below minimum denomination
  transactions: 'Transactions',
  tradesBelowMinDenomination: 'Trades Below Minimum Denomination',
};

/* Help Links */

export const HELP_LINKS = {
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]:
    'https://tools.finra.org/reportcenterhelp/#Municipal_Bond_Reports.htm#Municipal_Primary_Offering_Disclosure_Report',
  [MsrbReportNames.G_32_UNDERWRITING]:
    'https://tools.finra.org/reportcenterhelp/#Municipal_Bond_Reports.htm#MSRB_G-32_Report_Card__Summary_View',
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]:
    'https://tools.finra.org/reportcenterhelp/#Municipal_Bond_Reports.htm#MSRB_G-15_f__Trades_Below_Minimum_Denomination_Reportbc-18',
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]:
    'https://tools.finra.org/reportcenterhelp/#Municipal_Bond_Reports.htm#Underwriter_Financial_Status_Report',
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]:
    'https://tools.finra.org/reportcenterhelp/#Municipal_Bond_Reports.htm#MSRB_Markup_Markdown_Analysis_Report',
  [MsrbReportNames.CONTINUING_DISCLOSURE]:
    'https://tools.finra.org/reportcenterhelp/#Municipal_Bond_Reports.htm#Municipal_Continuing_Disclosure_Report',
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]:
    'https://tools.finra.org/reportcenterhelp/#Municipal_Bond_Reports.htm#MSRB_Due_Diligence_Report_Card_Summary_Viewbc-13'
};

/* Table Names by Order */

export const TABLE_NAMES_BY_ORDER = {
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: ['data'],
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: ['mumd'],
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: [
    'transactions',
    'tradesBelowMinDenomination',
  ],
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: [
    'firm',
    'peerGroup',
    'industry'
  ],
  [MsrbReportNames.G_32_UNDERWRITING]: [
    'firmIndustry',
    'officialStatement',
    'remarketing',
    'advanceRefunding',
    'municipalFund',
    'exemptFilings'
  ],
  [MsrbReportNames.CONTINUING_DISCLOSURE]: [
    'allMunicipalTransaction',
    'transactionsWithNoOSOnEmma',
    'lackingCurrentFinancials',
    'transactionDuringTradeMonth',
    'transactionDuring1To3O',
    'transactionDuring4To6'
  ],
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: []
};

/* Column Definitions Tree */

export const ALL_COLUMN_DEFINITIONS = {
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: {
    data: PRIMARY_OFFERING_DISCLOSURE_COLUMN_DEFS
  },
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: {
    mumd: FIRM_COLUMN_DEFS
  },
  [MsrbReportNames.G_32_UNDERWRITING]: G_32_COLUMN_DEFS,
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: TRADES_BELOW_COLUMS_DEFS,
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: UNDERWRITER_FIN_COL_DEFS,
  [MsrbReportNames.CONTINUING_DISCLOSURE]: ALL_MSRB_CONTINUING_DISCLOSURES_COLUMN_DEFS
};

/** RATINGS, MATURITY */

export const MSRB_RATINGS = [
  { value: 'all', name: 'All' },
  { value: '1', name: 'Investment Grade' },
  { value: '-1', name: 'Non-Investment Grade' },
  { value: '0', name: 'Not-Rated' },
];

export const MSRB_MATURITIES = [
  { value: 'all', name: 'All' },
  { value: '1', name: '<1 Year' },
  { value: '2', name: '1-5 Years' },
  { value: '3', name: '5-10 Years' },
  { value: '4', name: '>=10 Years' },
  { value: '100', name: 'N/A' }
];
