/**
 * Risk Monitoring
 */

import { getRegisteredRepExportConfig } from './registered-reps-composition.model';

export enum RiskMonitoringReportTypes {
  CANCELED_AS_OF_TRADES = 19,
  CUSTOMER_DEBITS = 20,
  SALES_PRACTICE_COMPLAINT = 21,
  REGISTERED_REPRESENTATIVES = 22,
  CUSTOMER_COMPLAINT = 23,
}

export enum RiskMonitoringReportNames {
  CANCELED_AS_OF_TRADES = 'cncldasof',
  CUSTOMER_DEBITS = 'custdebits',
  SALES_PRACTICE_COMPLAINT = 'salescomplaint',
  REGISTERED_REPRESENTATIVES = 'regreprcomp',
  CUSTOMER_COMPLAINT = 'custcomplaint',
}

// IDs of report types that have details link
export const showDetailLinkReportTypeIDs = [
  RiskMonitoringReportTypes.REGISTERED_REPRESENTATIVES,
];

export const showViewsDropdownReportTypeIDs = [
  RiskMonitoringReportTypes.CUSTOMER_COMPLAINT,
];

// IDs of report types that require quarter period formatting
export const RiskMonitoringQuarterlyReportsByTypeId = [
  RiskMonitoringReportTypes.SALES_PRACTICE_COMPLAINT,
  RiskMonitoringReportTypes.REGISTERED_REPRESENTATIVES,
  RiskMonitoringReportTypes.CUSTOMER_COMPLAINT,
];

// the {_default} property is for getting the default filename across all report types, since each may have different views.
// for example, the default for customer debits is monthly; the default for registered reps is quarterly.
export const DOWNLOAD_FILE_NAMES = {
  [RiskMonitoringReportNames.CUSTOMER_DEBITS]: {
    _default:
      'Details_RISK-MONITORING-Customer-Debits_${firmId}_${reportPeriodDate}_Monthly',
    monthly:
      'Details_RISK-MONITORING-Customer-Debits_${firmId}_${reportPeriodDate}_Monthly',
    daily:
      'Details_RISK-MONITORING-Customer-Debits_${firmId}_${reportPeriodDate}_Daily',
    pdf:
      'Details_RISK-MONITORING-Customer-Debits_${firmId}_${reportPeriodDate}_PDF'
  },
  [RiskMonitoringReportNames.CANCELED_AS_OF_TRADES]: {
    _default:
      'Details_RISK-MONITORING-Canceled-AsOf-Trades_${firmId}_${reportPeriodDate}_Monthly',
    monthly:
      'Details_RISK-MONITORING-Canceled-AsOf-Trades_${firmId}_${reportPeriodDate}_Monthly',
    daily:
      'Details_RISK-MONITORING-Canceled-AsOf-Trades_${firmId}_${reportPeriodDate}_Daily',
    pdf:
      'Details_RISK-MONITORING-Canceled-AsOf-Trades_${firmId}_${reportPeriodDate}_PDF'
  },
  [RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES]: {
    _default:
      'Details_RISK-MONITORING-Registered-Representative-Composition_${firmId}_${reportPeriodDate}_Quarterly',
    quarterly:
      'Details_RISK-MONITORING-Registered-Representative-Composition_${firmId}_${reportPeriodDate}_Quarterly',
    pdf:
      'Details_RISK-MONITORING-Registered-Representative-Composition_${firmId}_${reportPeriodDate}_PDF'
  },
  [RiskMonitoringReportNames.CUSTOMER_COMPLAINT]: {
    _default:
      'Details_RISK-MONITORING-Customer-Complaint_${firmId}_${reportPeriodDate}_Quarterly',
    quarterly:
      'Details_RISK-MONITORING-Customer-Complaint_${firmId}_${reportPeriodDate}_Quarterly',
    pdf:
      'Details_RISK-MONITORING-Customer-Complaint_${firmId}_${reportPeriodDate}_PDF'
  },
  [RiskMonitoringReportNames.SALES_PRACTICE_COMPLAINT]: {
    _default:
      'Details_RISK-MONITORING-Sales-Practice-Complaint-Report_${firmId}_${reportPeriodDate}_Quarterly',
    quarterly:
      'Details_RISK-MONITORING-Sales-Practice-Complaint-Report_${firmId}_${reportPeriodDate}_Quarterly',
    pdf:
      'Details_RISK-MONITORING-Sales-Practice-Complaint-Report_${firmId}_${reportPeriodDate}_PDF'
  }
};

const TITLE_PREFIX: string = 'Risk Monitoring -';
// what appears in the tab of the broswer
export const RiskMonitoringReportWebTitles = {
  [RiskMonitoringReportNames.CUSTOMER_DEBITS]: `${TITLE_PREFIX} Customer Debits Report`,
  [RiskMonitoringReportNames.CANCELED_AS_OF_TRADES]: `${TITLE_PREFIX} Canceled and As-Of Trades Report`,
  [RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES]: `${TITLE_PREFIX} Registered Representative Composition Report`,
  [RiskMonitoringReportNames.CUSTOMER_COMPLAINT]: `${TITLE_PREFIX} Customer Complaint Report`,
  [RiskMonitoringReportNames.SALES_PRACTICE_COMPLAINT]: `${TITLE_PREFIX} Sales Practice Complaint Report`
};

// what shows on the view
export const RiskMonitoringReportPageTitles = {
  [RiskMonitoringReportNames.CUSTOMER_DEBITS]: `Customer Debits Report`,
  [RiskMonitoringReportNames.CANCELED_AS_OF_TRADES]: `Canceled and As-Of Trades Report`,
  [RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES]: 'Registered Representative Composition Report',
  [RiskMonitoringReportNames.CUSTOMER_COMPLAINT]: `Customer Complaint Report`,
  [RiskMonitoringReportNames.SALES_PRACTICE_COMPLAINT]: 'Sales Practice Complaint Report'
};

/** Toolbar Descriptions */

const TOOLBAR_CONTACT_LINE: string =
  '\n\n' +
  "For questions regarding this specific report, please contact your firm's Coordinator " +
  'or <a href="mailto:reportcenter-admin@finra.org">reportcenter-admin@finra.org</a>.';



const customerDebitsToolbarDescription: string =
  'This report displays a monthly summary of unsecured customer debits ' +
  "and margin debits in customer accounts provided to FINRA by the firm's " +
  'clearing party. Click a month below to see daily totals for the month.' +
  TOOLBAR_CONTACT_LINE;

const canceledAsOfTradesToolbarDescription: string =
  'This report displays a summary of as-of trades cancels in equity trading activity ' +
  "provided to FINRA by the firm's clearing party. " +
  'Click a month to see the daily totals for that month.' +
  TOOLBAR_CONTACT_LINE;

const registeredRepsToolbarDescription: string =
  'This report displays trends in the profile of registered representatives associated with the firm.' +
  TOOLBAR_CONTACT_LINE;

const customerComplaintToolbarDescription: string =
  'This report displays trends in customer complaint information reported as required by FINRA Rule 4530.' +
  '\n\n' +
  'Complaints by Problem - Click the Arrow to the left of a problem to see the products reported for that problem. ' +
  'Italicized labels indicate the problem is considered a sales practice complaint. ' +
  TOOLBAR_CONTACT_LINE;

const salescomplaintToolbarDescription =
  'This report displays trends in sales practice complaints reported to FINRA on a quarterly basis, ' +
  'evaluated against the number of registered representatives at the firm and sales revenue of the firm.' +
  TOOLBAR_CONTACT_LINE;

export const riskMonitoringToolbarDescriptions = {
  [RiskMonitoringReportNames.CUSTOMER_DEBITS]: customerDebitsToolbarDescription,
  [RiskMonitoringReportNames.CANCELED_AS_OF_TRADES]: canceledAsOfTradesToolbarDescription,
  [RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES]: registeredRepsToolbarDescription,
  [RiskMonitoringReportNames.CUSTOMER_COMPLAINT]: customerComplaintToolbarDescription,
  [RiskMonitoringReportNames.SALES_PRACTICE_COMPLAINT]: salescomplaintToolbarDescription
};

/** Footer Messages */

const customerDebitsFooterMessage: string =
  '* - If this symbol appears on your report, this indicates that no data has been provided ' +
  'to FINRA for this value for the specified period. This is an expected behavior for ' +
  'weekday holidays but may also indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. ' +
  'Information provided does not necessarily indicate a rule violation or issue. Member firms should make ' +
  'no inference that the staff of FINRA has or has not determined that the information contained on this report ' +
  'does or does not constitute rule violations or that the data has been accurately reported to FINRA.';

const canceledAsOfTradesFooterMessage: string =
  '* - If this symbol appears on your report, this indicates that no data has been provided ' +
  'to FINRA for this value for the specified period. This is an expected behavior for ' +
  'weekday holidays but may also indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. ' +
  'Information provided does not necessarily indicate a rule violation or issue. Member firms should make ' +
  'no inference that the staff of FINRA has or has not determined that the information contained on this report ' +
  'does or does not constitute rule violations or that the data has been accurately reported to FINRA.';

const registeredRepsFooterMessage: string =
  '* indicates that there was no information for your firm during that period.' +
  '\n\n' +
  'This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. ' +
  'Information provided does not necessarily indicate a rule violation or issue. Member firms should make ' +
  'no inference that the staff of FINRA has or has not determined that the information contained on this report ' +
  'does or does not constitute rule violations or that the data has been accurately reported to FINRA.';

const customerComplaintFooterMessage: string =
  '* - If this symbol appears on your report, this indicates that no data has been provided ' +
  'to FINRA for this value for the specified period. This is an expected behavior for ' +
  'weekday holidays but may also indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. ' +
  'FINRA applies logic to identify for which quarter complaints should be counted; this logic may not ' +
  'align exactly with firms\' analysis. Information provided does not necessarily indicate a rule violation or issue. ' +
  'Member firms should make no inference that the staff of FINRA has or has not determined that the information contained ' +
  'on this report does or does not constitute rule violations or that the data has been accurately reported to FINRA.';

const salescomplaintFooterMessage: string =
  '* - If this symbol appears on your report, this indicates that no data has been provided ' +
  'to FINRA for this value for the specified period. This is an expected behavior for ' +
  'weekday holidays but may also indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. ' +
  'Information provided does not necessarily indicate a rule violation or issue. Member firms should make ' +
  'no inference that the staff of FINRA has or has not determined that the information contained on this report ' +
  'does or does not constitute rule violations or that the data has been accurately reported to FINRA.';

export const riskMonitoringFooterMessages = {
  [RiskMonitoringReportNames.CUSTOMER_DEBITS]: customerDebitsFooterMessage,
  [RiskMonitoringReportNames.CANCELED_AS_OF_TRADES]: canceledAsOfTradesFooterMessage,
  [RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES]: registeredRepsFooterMessage,
  [RiskMonitoringReportNames.CUSTOMER_COMPLAINT]: customerComplaintFooterMessage,
  [RiskMonitoringReportNames.SALES_PRACTICE_COMPLAINT]: salescomplaintFooterMessage
};

export const RISK_MONITORING_FOOTER_MESSAGE: string =
  '* If this symbol appears on your report, this indicates that no data has been provided to FINRA ' +
  'for this value for the specified period. This is an expected behavior for weekday holidays but may also ' +
  'indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. ' +
  'Information provided does not necessarily indicate a rule violation or issue. Member firms should make no inference that ' +
  'the staff of FINRA has or has not determined that the information contained on this report does or does not constitute rule ' +
  'violations or that the data has been accurately reported to FINRA.';

export const RISK_MONITORING_EXPORT_CONFIG = {
  [RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES]: getRegisteredRepExportConfig,
};
