import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewContainerRef
} from '@angular/core';
import { ToolbarConfig } from 'src/app/configs/model/finra-toolbar.model';

@Component({
  selector: 'report-finra-toolbar',
  templateUrl: './finra-toolbar.component.html',
  styleUrls: ['./finra-toolbar.component.scss']
})
export class FinraToolbarComponent implements OnInit, AfterViewInit {
  @ViewChild('toolbarContainerElm', { static: false })
  toolbarContainerElm: ElementRef;
  @Input() toolbarConfig: ToolbarConfig;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.toolbarConfig.toolbarReadyCallback) {
      this.toolbarConfig.toolbarReadyCallback(this.toolbarContainerElm);
    }
  }
}
