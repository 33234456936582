import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { DatePipe } from '@angular/common';
import { FinraGridClickableCellComponent } from 'src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component';
import { finraGridDateTimeFormatter, addFinraGridColumnId } from 'src/app/shared/utils/utils';
import { ReportType, ReportConfigurationView } from './reports.model';

export const DQS_CATEGORY_ID = 13;

export enum DqsFqsFlag {
  DQS_READY = 'DQS_READY',
  FQS_READY = 'FQS_READY',
}

/**
 * DQS filter
 * Example:
 {
  examId: "20190606444"
  examYear: "2019"
  firmId: "249"
  fqsDqsFlg: "DQS_READY"
  requestId: "3290003"
 }
*/

export interface DqsFilterData {
  examId: string;
  examYear: string;
  firmId: string;
  fqsDqsFlg?: DqsFqsFlag;
  requestId: string;
}

export type DqsFilterDataResponse = Array<DqsFilterData>;

/**
 * DQS Data Object
 * ---
 * example:
 * {
    timeStamp: "2017-08-07T18:30:13.375-0400",
    validationDescription: "[SADT] Count of ACSM records where Allocation Code is not in SADT.",
    firmCRD: "79",
    requestID: "3043475",
    validationType: "SOFT",
    examID: null,
    validationGroup: "SADT",
    firmName: "J.P. MORGAN SECURITIES LLC",
    validationResultState: "FAIL",
    asOfDate: "2016-10-31T00:00:00.0-0400",
    results: "4",
    validation: "ACSM Allocation Code Not In SADT"
 * }
 */
export interface DqsSummaryData {
  timeStamp: string;
  validationDescription: string;
  firmCRD: string;
  requestID: string;
  validationType: string;
  examID: string;
  validationGroup: string;
  firmName: string;
  validationResultState: string;
  asOfDate: string;
  results: string;
  validation: string;
}

export interface DqsSummaryDataResponse {
  columnsData: DqsSummaryData[];
}

export interface DqsReportConfiguration {
  reportConfigurationId: number;
  reportTypeId: number;
  reportDisplayName: string;
  reportEdition: string;
  otpArmed: boolean;
  reportCardTemplate: any; // currently unknown
  effectiveDate: string;
  expirationDate: string;
  reportType: ReportType;
  rptDesc: string;
  reportConfigurationViews: ReportConfigurationView[];
}

export interface DqsReportConfigurationData {
  reportDataVersion: 0;
  reportPeriodDate: '';
  reportConfiguration: DqsReportConfiguration;
  periodicities: [];
}

export type DqsReportConfigurationResponse = Array<DqsReportConfigurationData>;

export interface FqsSummaryData {
  asOfDate: string;
  caseIdentifier: string;
  fileFailureCnt: number;
  firmCrdNumber: number;
  firmName: string;
  itemIdentifier: string;
  requestGroupIdentifier: number;
  validationCode: string;
  validationDatetime: string;
  validationName: string;
  validationResultStateCode: string;
}

export type FqsSummaryResponse = { columnsData: Array<FqsSummaryData> };

export const VALIDATIONS_WITHOUT_DETAILS_RPT = [
  'Invalid As Of Date',
  'Invalid Firm CRD Number',
  'Invalid As Of Date',
  'Invalid Firm CRD Number',
  'Invalid As Of Date',
  'Invalid Firm CRD Number',
  'Invalid As Of Date',
  'Invalid Firm CRD Number',
  'Invalid As Of Date',
  'Invalid Firm CRD Number',
  'Missing Account Type Mapping - Cash',
  'Missing Account Type Mapping - Margin',
  'Missing Allocation Files',
  'Missing Grand Total Algorithm',
  'No SR Files Submitted',
  'Invalid As Of Date',
  'Invalid Firm CRD Number',
  'SR - Missing Allocation Codes with SADT',
  'Grand Total Mismatch - Number of Securities',
  'Invalid As Of Date',
  'Invalid Firm CRD Number'
];

export const DQS_STATES = [
  { className: 'warning', value: 'WARNING', title: 'Warning' },
  { className: 'failed', value: 'FAIL', title: 'Failed' },
  // { className: 'not-executed', value: 'NOT EXECUTED', title: 'Not Executed' },
  { className: 'passed', value: 'PASS', title: 'Passed' }
];

export const FQS_STATES = [
  { className: 'failed', value: 'FAIL', title: 'Failed' },
  { className: 'passed', value: 'PASS', title: 'Passed' }
];

export const DQS_AUTO_GROUP_COLUMN_DEFS = {
  cellClass: 'rcExpand',
  cellRendererParams: {
    suppressCount: true
  } 
};

export const DQS_CREATE_COLUMN_DEFS = (
  handleClick: (params, rowIndex) => void
) => [
  {
    headerName: '',
    cellRendererFramework: FinraGridClickableCellComponent,
    field: '',
    cellClass: 'download-link',
    width: 50,
    buttonLabel: '',
    cellRendererFrameworkOptions: {
      handleClick: (params, rowIndex) => handleClick(params, rowIndex),
      isClickable: (params: object) => {
        const canClick = params['data']['results'] !== '0' &&
        !VALIDATIONS_WITHOUT_DETAILS_RPT.includes(
          params['data']['validation']
        );
        return canClick;
      },
      icon: (params) => {
        // DDWA-4114 Show Detail icons for Fail and Warning tables only
        const icon =
          params['data']['validationResultState'] === 'FAIL' ||
          params['data']['validationResultState'] === 'WARNING'
            ? faDownload
            : null;
        return icon;
      }
    }
  },
  {
    headerName: 'Validation Group',
    field: 'validationGroup'
  },
  {
    headerName: 'Validation',
    field: 'validation'
  },
  {
    headerName: 'Validation Type',
    field: 'validationType'
  },
  {
    headerName: 'Result',
    field: 'results',
    type: 'numericColumn'
  },
  {
    headerName: 'Timestamp',
    field: 'timeStamp',
    cellClass: 'timestamp',
    headerClass: 'timestamp',
    valueFormatter: finraGridDateTimeFormatter
  }
];

export const FQS_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Validation Name',
    headerClass: 'fqs-first-header',
    field: 'validationName',
    cellStyle: {
      'white-space': 'normal',
      'padding-left': '20px !important',
      'padding-top': '17px !important',
      'line-height': 'normal'
    },
  },
  {
    headerName: 'Result',
    field: 'fileFailureCnt',
    minWidth: 125,
    maxWidth: 125,
  },
  {
    headerName: 'Time Stamp',
    field: 'validationDatetime',
    valueFormatter: (params) => {
      return (new DatePipe(`en-US`).transform(params.value, 'MMM d, y - hh:mm a'))
    },
    minWidth: 250,
    maxWidth: 250,
  }
]);
