export const ProblemsMap = {
  '10PCTRVW': '10% Review - AMEX Rule 190',
  '1100MNPLTNEDGA': '1:100 Manipulation - EDGA',
  '1100MNPLTNEDGX': '1:100 Manipulation - EDGX',
  '130XCHNGTRDRPTG': 'Rule 130 - Exchange Trade Reporting',
  '15C2BDFAILPYMNT': 'SEC Rule 15c2-4-BD Failed ot transmit Payment',
  '15C2MM_RVW_ISSR': 'SEC Rule 15c2-11 - MM to Review Issuer Information',
  '7225MM': '75/25 Market Maker',
  '80INPERSN': '80% In-Person',
  '98CRPRTREACQSN': 'Rule 98 Corporate Reacquisition',
  'AAUDITVLTN': 'Annual Audit',
  'ACCSS_RQMNT': 'Access Requirements',
  'ACCTADMIN': 'Account Admin and Processing - Daily Activities',
  'ACCTMAINT': 'Account Admin and Processing - Account Maintenance',
  'ACCTOPEN': 'Account Admin and Processing - Account Opening',
  'ACCTTRNSF': 'Transfer of Accounts',
  'ACMIN': 'Accumulation - Cross',
  'ACTACCSSVLTN': 'ACT Access',
  'ACTCMPLCVLTN': 'ACT Compliance - 20 Min',
  'ACTVMNGDETPRVW': 'Actively Managed ETP Firewall Review',
  'ADEQUATECLTRL': 'Adequacey of Collateral',
  'ADF10CNTRA': 'Rule 4300A - ADF - Entry/Contra MPID',
  'ADF10SECRULE': 'FINRA Rule 4300A - ADF - 10 Second Rule',
  'ADFAUTOQUOTE': 'Rule 4300A(e)(1) - Auto Quoting',
  'ADFECNRSRVSZ': 'ADF ECN Reserve Size',
  'ADFEXCTNQTY': 'Rule 4300A - ADF - Execution Quantity',
  'ADFFIRMQTCMPLC': 'ADF Firm Quote Compliance',
  'ADFIOCODRRSPNS': 'Rule 4300(e)(1)-ADF-IOC Order Response Timeliness',
  'ADFODRRSPNS': 'Rule 4300A - ADF - Order Response Timeliness',
  'ADFODRRSPNSQLTY': 'Rule 4300A(b)-ADF-Order Reponse Reporting Quality',
  'ADFORPHANRSPNS': 'RULE 4300A - ADF - Orphan Response',
  'ADFOUTOFSEQ': 'Rule 4300A - ADF - Out of Sequence',
  'ADFTRDORMOVE': 'Rule 4613A(d) - ADF - Trade or Move',
  'ADFTRDORMOVEFL': 'Rule 4300A - ADF - Trade or Move Flag Usage',
  'ADTRTRMNNNTFCN': 'Failure to Notify Auditor Termination to FINRA',
  'AEMMAUCTN': 'Arca Equities Market Order Auction',
  'AEMMOBGTN': 'Arca Equities Market Maker Obligations',
  'AEMNPLTNOTC': 'Auto-Execution Manipulation - OTC',
  'AEPRECLSMRKNG': 'Arca Equities PreClose Marking',
  'AEPRTFLCRSSN': 'Arca Equities Portfolio Crossing Session',
  'AERBTFIPRVW': 'Arca Equities - Rebate and FIP Review',
  'AEREGM': 'Arca Equities - Reg M',
  'AEREGSHO': 'Arca Equities - Reg SHO',
  'AEREGSHOMMARVW': 'Arca Equities - Reg SHO Market Maker Abuse Review',
  'AESTCKHLTVTPRVW': 'Arca Equities - Stock Halt and VTP Review',
  'AEUNUSUALTRDS': 'Arca Equities Unusual Trades',
  'AEXMNPLTNX': 'Auto Exec Manipulation - Cross',
  'AGCYFRPRCNG': 'Agency Fair Pricing',
  'AGNCYBESTEXCTN': 'Agency Best Execution',
  'AGNCYDATAINTGY': 'Agency Data Integrity',
  'AIMUBNDLG': 'AIM Unbundling',
  'ALCTNPRCS': 'Allocation Process',
  'ALGOGAMING': 'Algo Gaming',
  'ALT_LISTG_MANIP': 'Alternative Listing Manipulation',
  'AMEX100VLTN': 'AMEX Rule 1000, 1000A, 1200 - ETF',
  'AMEX115VLTN': 'AMEX Rule 115 - SEC Rule 602 Violation',
  'AMEX150VLTN': 'AMEX Rule 150 - Failure to use due diligence',
  'AMEX154VLTN': 'AMEX Rule 2, 1, .5',
  'AMEX170IA': 'AMEX Rule-170 Violation-Investment Account Changes',
  'AMEX170VLTN': 'AMEX Rule 170 Violation',
  'AMEX922-135VLTN': 'Amex Rule 922 and 135 -Applied/Unapplied',
  'AMEX933VLTN': 'AMEX Rule 933',
  'AMEX934VLTN': 'AMEX Rule 934',
  'AMEX950VLTN': 'AMEX Rule 950(d) - Anticipatory Hedge',
  'AMLRGLTNVLTN': 'Anti Money Laundering',
  'ANNLXMPTNREVIEW': 'SEC Rule 11AC1-5/6 - Annual Exemption Review',
  'ANNTYSWTCHNG': 'Annuity Switching',
  'ANTESTREAMQUOTE': 'AMEX Rule 958- AMEX ANTE Class Streaming Quote ',
  'ANTEVOL50': 'AMEX Rule 958 - AMEX ANTE Class 50% Volume',
  'ANTICMPTVBHVR': 'Anticompetitive Behavior/Harassment',
  'AONCSTMRNTFCN': 'AON-AIM Customer Notification',
  'ARBTNDSCVY': 'Arbitration Discovery',
  'ARCAEXCSVMSG': 'Arca Quote Stuffing/Excessive Messages',
  'ARGNTRDGOPTNSX': 'Pre-Arranged Trading - Options Cross',
  'ASGNDPRDCT': 'AMEX Rule 110 - AEMI Assigned Products',
  'ATR': 'Audit Trail',
  'ATSCROSSMNPLTN': 'ATS Cross Manipulation',
  'ATSVOLRPTG': 'ATS Volume Reporting',
  'AUTOEXCTNMNPLTN': 'Auto Exececution Manipulation',
  'BARSEQTYVLTN': 'BARS Equity OEBE',
  'BARSEQTYVOLVLTN': 'BARS - Equity Volume',
  'BARSOPTNSVLTN': 'BARS - Options OEBE',
  'BARSOPTNVOLVLTN': 'BARS - Options Volume',
  'BARSPRFMCVLTN': 'BARS Performance',
  'BATSINTRM': 'BATS (Interim)',
  'BCPDRSYSTSTNG': 'BCP/DR And Mandatory Systems Testing',
  'BDCUSTODRRTNG': 'SEC Rule 606-BD Customer Order Routing',
  'BIDTESTRULEVLTN': 'Bid Test Rule',
  'BKRPCXMPTN': 'Bankruptcy Exemption',
  'BOOKSRCRDSVLTN': 'Books and Records',
  'BOXBOOKSWPSZ': 'BOX Book Sweep Size',
  'BOXODRMSRPRSNTN': 'BOX Order Misrepresentation',
  'BRNCHRSK': 'Branch Risk',
  'BSACCURACYVLTN': 'Blue Sheet Accuracy',
  'BSTEXCOTSDINNMS': 'Best Execution Outside-of-the-Inside - NMS',
  'BSTEXCOTSDINOTC': 'Best Execution Outside-of-the-Inside - OTC',
  'BSTEXCTNISOX': 'Best Execution Agency ISO - Cross',
  'BSTEXCTNOTSDIN': 'Best Execution Outside-of-the-Inside',
  'BSTEXCTNPRCIMPR': 'Best Execution Price Improve Trade Center - Cross',
  'BSTEXDLGNOPTNSX': 'Options Best-Ex Due Diligence - Options Cross',
  'BSTMLSVLTN': 'Blue Sheet Timeliness',
  'BTTNEARREALTM': 'Book Trade Through - Near Real Time',
  'BTTPSTTRDREALTM': 'Book Trade Through - Post Trade Real Time',
  'C2HRLYINTRM': 'C2 (Hourly - Interim)',
  'C2INTRM': 'C2 (Interim)',
  'C2NRSA': 'C2 (Non-RSA)',
  'CAPPNG': 'Capping - Cross',
  'CBOEHRLYINTRM': 'CBOE (Hourly - Interim)',
  'CBOEINTRM': 'CBOE (Interim)',
  'CBOELEGALRSA': 'CBOE Legal RSA',
  'CBOENRSA': 'CBOE (Non-RSA)',
  'CBOEOWNED': 'CBOE Owned',
  'CBSXHRLYINTRM': 'CBSX (Hourly - Interim)',
  'CE': 'Continuing Education',
  'CEADIFFRNC': 'CEA-Difference',
  'CEALATEFLNG': 'CEA-Late Filing',
  'CEANOFLNG': 'CEA-No Filing',
  'CHNGSCROWDTRDS': 'Changes to Crowd Trades',
  'CHOICEOFLAW': 'Choice of Law',
  'CHRTCOFQTTNVLTN': 'FINRA Rule 6272 Violation - Character Of Quotation',
  'CLEARING': 'Clearing Operations',
  'CLRGTPHGVP': 'Clearing TPH Give Up',
  'CLRLYERRNSFLNG': 'Clearly Erroneous Filings',
  'CLRNGTRADEVLTN': 'Unprofitable Clearing Trades',
  'CLRVSPRT': 'Clear Vs Print',
  'CLSGAUCTN': 'Arca Closing Auction',
  'CMBNNREVIEW': 'SEC Rule 11AC1-5/6 - Combination Review',
  'CMPLNFIRMLQDTN': 'Complaints related to BD/Clearing Firm Liquidation',
  'CMPLXODRCSHSPRD': 'Complex Orders Cash Spreads',
  'CMPLXORDAUCTNET': 'Complex Order Auction - Early Termination',
  'CMPLXORDAUCTNNI': 'Complex Order Auction - Non- Standard Increments',
  'CMPRSNFORUMS': 'Compression Forums',
  'CMSNRATE': 'Commission Rate',
  'CMSNSPLITVLTN': 'Commission Splitting With Unregistered Person',
  'CNFLTINTRSOUTSD': 'Conflict of Interest - Outside FINRA',
  'CNNCTTYAPRVL': 'Connectivity Approvals',
  'CNSLDSNGLMTCOTC': 'Consolidated Single Instance Marking the Close-OTC',
  'CNSLDSNGLMTO': 'Consolidated Single Instance Marking the Open',
  'CNTGTTRDS': 'Contingent Trades',
  'CNTTYANDDEPTH': 'Continuity and Depth',
  'COATS': 'COATS',
  'COMMPBLC': 'Communication with Public',
  'CONFRATEDIFF': 'CONF RATE DIFF',
  'CORPBSTEXCTN': 'Corporate Best Execution',
  'CORRLTDPRDCT': 'Correlated Products',
  'CQSNONQUOTEVLTN': 'NASD Marketplace Rule 5220(e)-continuous display.',
  'CQSQUOTEVLTN': 'CQS Quote Violation',
  'CRLTDPRDCTOPTNX': 'Correlated Products - Options Cross',
  'CROWDFUNDGISSRS': 'Crowdfunding - Issuers',
  'CROWDFUNDGPRTLS': 'Crowdfunding - Funding Portals',
  'CRSMKTALGOGAMNG': 'Algo Gaming - Cross',
  'CSTMRCRSSS': 'Customer to Customer Crosses',
  'CTMI': 'CTMI',
  'CUSTLOANRR': 'RR Accepts Loan from Customer w/o Firm Approval',
  'DBLDPPNG': 'Double Dipping',
  'DCRNYTRANS': 'Discretionary Transactions',
  'DIVIDEND': 'Dividend and Interest Problems',
  'DLTNTRLCMB': 'Delta Neutral Combo',
  'DLVRYMONEY': 'Non-Delivery - Money',
  'DLVRYPROSPECTUS': 'Non-Delivery - Prospectus',
  'DLVRYPROXY': 'Non-Delivery - Proxy',
  'DMMPBLCBUS': 'DMM Public Business',
  'DOCMTN': 'Documentation',
  'DOLFIDRULE': 'DOL Fiduciary Rule',
  'DRCTACCSS': 'Direct Access',
  'DRCTDORDR3SCND': 'BOX Rule Chapter IV-Directed Order Under 3 Second',
  'DRTCDCMSNVLTN': 'FINRA Rule 2830(k) - Directed Brokerage',
  'DRVTVRVW': 'Derivative Review - AMEX Rule 170',
  'DSCLRFEES': 'Disclosure of Fees',
  'DSCRNATHRN': 'Discretion without Written Authorization',
  'DSCVYABUSEVLTN': 'FINRA Arbitration Code of Procedure violation',
  'DSMTNOFQTTN': 'Dissemination Of Quotations',
  'DSPLYLIMITORDR': 'SEC Rule 604 - Display of Customer Limit Order',
  'DSTRNPRTCPACTVS': 'SEC Rule 101-Activity of Distribution Participants',
  'DUALRPRTN': 'Dual Representation',
  'DVDNDCAPGAINS': 'Dividend and Capital Gains Problems',
  'EAIEVGRSSEXRCS': 'Exercise Audit Index Exercise Violations',
  'ECNEPHMRLQUOTE': 'ECN Ephemeral Quote',
  'ECNMNPLTN': 'ECN Manipulation',
  'ECNOPRNLISSUE': 'ECN Operational Issue',
  'EDGAEXCSVMSG': 'EDGA Quote Stuffing/Excessive Messages',
  'EDGAMMPOBGTN': 'EDGA Market Maker Pricing Obligations',
  'EDGXEXCSVMSG': 'EDGX Quote Stuffing/Excessive Messages',
  'EDGXMMPOBGTN': 'EDGX Market Maker Pricing Obligations',
  'EEDOPTNSX': 'EED - Options Cross',
  'EFCTNGSYNDCACTY': 'SEC Rule 104-Activites Effecting Syndicate',
  'EFPFTRSRVW': 'Futures Review of Exchange for Physical Delivery',
  'ELCTCRPTAVLBY': 'SEC Rule 605-Monthly Electronic Report Avalability',
  'ELOTOPTNSCRSS': 'ELOT - Options Cross',
  'EODHANDHLDBRCH': 'End of Day Handheld Breach',
  'EQTBLPRNCPLS': 'ISE Rule 400 - Just Equitab;e Principles of Trade',
  'EQTYCNCTVYAPRVL': 'Arca Equity Connectivity Approvals',
  'ETAIMAUCTN': 'Early Termination of AIM Auction',
  'ETFETNSURV': 'ETF/ETN Surveillance - Cross',
  'ETPCALCAGNTISSS': 'ETP Calculation Agent Issues',
  'ETPDVTNPTTRN': 'ETP Deviation Pattern - Cross',
  'EXCSDWTHRL': 'Excused Withdrawal',
  'EXCSVCMSN': 'Excessive Commission',
  'EXCSVMARKUPDWN': 'Excessive Markup / Markdown',
  'EXCSVMSGNGTRFFC': 'Excessive Messaging Traffic',
  'EXCSVTRDG': 'Excessive Trading',
  'EXCTNBUYODR': 'Execution - Buy Order',
  'EXCTNINCRCTPR': 'Execution - Incorrect priced',
  'EXCTNINTNT': 'Execution - Internet',
  'EXCTNMBR': 'Execution - Member',
  'EXCTNMKT': 'Execution - Market',
  'EXCTNMTLFUND': 'Execution - Mutual Funds',
  'EXCTNSELLODR': 'Execution - Sell Order',
  'EXCTNTIMEFLR': 'FINRA 7130-Failure to Submit Trade Report',
  'EXECUTION': 'Execution',
  'EXPRTNONFRIDAY': 'Artificially influence price on Expiration Friday',
  'EXRCSADVC': 'Rule 980C-Delivery of ExerciseAdvice After close',
  'EXRCSLIMIT': 'Exercise Limit Violation - BOX Rule III',
  'EXRCSLMOPTNSX': 'Exercise Limit - Options Cross',
  'FAILAPPRV': 'Fail to Approve',
  'FAILBESTEXCTN': 'Best Execution',
  'FAILFOLLOW': 'Failure to Follow Instructions',
  'FAILFUNDPRTCN': 'SEC Rule 15C3-3',
  'FAILSPBESTEXCTN': 'Sales Practice Best Execution',
  'FAILSPRVS': 'Failure to supervise',
  'FAILTODISCTVLTN': 'Failure to Waive Discount/Commission on MTL Funds ',
  'FAIRNESSOPNN': 'Fairness Opinions Violation',
  'FALSERPTGXCHNG': 'Missstatement/False Reporting to Exchange',
  'FCMRVW': 'Futures Commission Merchant Review',
  'FCTTSTRDG': 'Fictitious Trading',
  'FEEBASEDSRVCS': 'Fail to Provide Value for Fee-Based Account',
  'FEES': 'Fees and Commissions',
  'FINRA2010VLTN': 'FINRA Rule 2010 -2020 Violation',
  'FINRA2360VLTN': 'FINRA Rule 2360 Violation',
  'FINRA3050VLTN': 'NASD Rule 3050 - Adverse Interest',
  'FINRA3060VLTN': 'FINRA Rule 3060 - Gifts and Gratuity',
  'FINRA3230VLTN': 'FINRA Rule 3230c',
  'FINRA4530VLTN': 'FINRA Rule 4530 - Reporting Requirements',
  'FINRA5190VLTN': 'FINRA Rule 5190-Failure toward Underwriting Rpt',
  'FINRA6182VLTN': 'FINRA Rule 6182 - Trade Reporting of Short Sales',
  'FINRA6279VLTN': 'FINRA Rule 6279  Violation',
  'FINRA6380AVLTN': 'FINRA Rule 6380A Transaction Reporting ',
  'FINRA6440VLTN': 'FINRA Rule 6440',
  'FINRA6530XMPTN': 'FINRA Rule 6530 - OTCBB-Eligible Securities ',
  'FINRA6643VLTN': 'FINRA Rule 6643 - No Longer Effective',
  'FINRA6740VLTN': 'FINRA Rule 6740',
  'FINRA7450VLTN': 'FINRA Rule 7450 Violation',
  'FINRARGSTNISSUE': 'FINRA registration issues Prohibiting Registration',
  'FIRMEMPOTHER': 'Firm Employee Other',
  'FIRMEMPRECORD': 'Firm Employee Record Violation',
  'FIRMFCLTTN': 'Firm Facilitation',
  'FIRMOATSRPTGFLR': 'FINRA 7440-7450-Firm Failed to report OATS',
  'FIRMPLCY': 'Firm Policy',
  'FIRMRPLCYVLTN': 'Firm Policy Procedures Violation',
  'FIRMRRDSPT': 'Dispute Between Firm and Registered Rep',
  'FLEXNODRS': 'FLEX N Orders',
  'FLEXTLGA': 'FLEX Trader Letter of Guarantee or Authorization',
  'FLOORAGNCYCROSS': 'AMEX Rule 126 - Floor Based Agency Cross',
  'FLOORBRKRACTVY': 'Floor Broker Activities',
  'FLOORBRKRATR': 'NASDAQ OMX PHLX Rule 1063 Floor Broker ATR',
  'FLOORBRKRTRADE': 'Exchange Rule 110 - Floor Broker Day Trading',
  'FLOORCANVASSING': 'Floor Canvassing',
  'FLOOROFCLAPRVL': 'Floor Official Approval',
  'FLOORRLTD': 'Floor Related',
  'FLOORRLTDLEGAL': 'Floor Related - Legal',
  'FLRAWARFDPYMNT': 'Failure to Pay an Arbitration Award',
  'FLRBRKRPERFRVW': 'Floor Broker Performance Review',
  'FLRBRKRTRDTHRGH': 'Floor Broker Trade Through',
  'FLRCASHDLVRY': 'Failure to Deliver Cash/Securities on Settlement',
  'FLRFILECRDDSCLR': 'Failure to File CRD Disclosure',
  'FLRSBMSNXCHNG': 'Failure To Make Timely Submissions To Exchange',
  'FLRSBMTCNCLNTC': 'FINRA 5190 - Failure to Submit Cancel Notice',
  'FLRSBMTCNFRMNTC': 'FINRA 5190 - Failure to Submit Confirm Notice',
  'FLRSBMTINTNTNTC': 'FINRA 5190 - Failure to Submit Intent Notice',
  'FLRSBMTREGM': 'FINRA 6275 - Failure to Submit Reg M ',
  'FLRSBMTTRDNGNTC': 'FINRA 5190 - Failure to Submit REGM Notification ',
  'FLRTODSCLS': 'Failure to Disclose',
  'FLRTORSPND': 'Failure to Respond',
  'FLRXCTBLLNKG': 'AMEX Rule 958 A-Failure-execute executable Linkage',
  'FLTFOCUSFLNG': 'Failure to File FOCUS or late FOCUS filing',
  'FLTFORM191FLNG': 'Amex Rule 191 - Failure to file Form 191 ',
  'FLXMINSZRPTPRC': 'FLEX Minimum Size Report Procedures',
  'FNNCLCMPLCMNTRG': 'Financial Compliance Monitoring',
  'FNNCLMNTRG': 'Financial Monitoring',
  'FORM211A1': 'SEC Rule 15c2-11 - Form 211 A-1',
  'FORM211A2': 'SEC Rule 15c2-11 - Form 211 A-2',
  'FORM211A3': 'SEC Rule 15c2-11 - Form 211 A-3',
  'FORM211A4': 'SEC Rule 15c2-11 - Form 211 A-4',
  'FORM211A5': 'Form 211 A-5',
  'FORM211F2': 'SEC Rule 15c2-11 - Form 211F-2',
  'FORM211H': 'SEC Rule 15c2-11 - Form 211 H',
  'FORMTTRDRPTG': 'FINRA6380-6420-6622-Members Report Last Sale',
  'FQNEARREALTM': 'Firm Quote - Near Real Time',
  'FQPOSTTRD': 'Firm Quote - Post Trade',
  'FREERIDEWITHLD': 'Free-Riding and Withholding',
  'FREEZE': 'Freeze - AMEX Rule 170 and SEC Rule 10b-5',
  'FRGRYINSRC': 'Forgery - Insurance',
  'FRGRYTHIRDPARTY': 'Forgery - Third Party',
  'FRGYSCRTS': 'Forgery - Securities',
  'FRONTENDCPTR': 'Front - End Systemic Capture',
  'FRONTRNNGOPTNSX': 'Anticipatory Hedge / Front Running - Options Cross',
  'FRONTRUNNG': 'Front Running - Cross',
  'FRONTRUNNGOTC': 'Front Running - OTC',
  'FRONTRUNNGSURV': 'Front Running Surveillance - Cross',
  'FRONTRUNNING': 'ISE Rule 408 - AMEX Rule 111 - Front Running',
  'FUNDS': 'Receipt and/or Disbursement of Funds',
  'GNRLMFCMPLNC': 'General Mutula Fund Compliance',
  'GRNTLOSS': 'Guarantee Loss',
  'GRNTPROFIT': 'Guarantee Profit',
  'GRNTRETURN': 'Guarantee Return',
  'HARASS': 'Harassment/Just & Equitable Princples of Trade',
  'HBRDCROSS': 'Hybrid Cross',
  'HEDGE': 'Hedge Fund - Other',
  'HIGHFREQTRDG': 'High Frequency Trading',
  'HIGHPRESSURE': 'High Pressure',
  'HOLDCSTMRFUNDS': 'Hold Customer Funds',
  'HOLDRCQSEXCTN': 'HOLDR CQS listed Securities Execution',
  'IDAYHNDHLDBRCH': 'Intra-Day Handheld Breach',
  'IDAYMNPLTNOTC': 'Intraday Manipulation - OTC',
  'IDAYWASHSALES': 'Intraday Wash Sales - Cross',
  'IDTHEFT': 'Identity Theft',
  'IDTRCK': 'ID Track',
  'IDXALCTN': 'Index Allocation',
  'IDXXPRTNVLTY': 'Index Expiration and Volatility',
  'IEXAUCTNMNPLTNC': 'IEX Auction Manipulation - Close',
  'IEXAUCTNMNPLTNO': 'IEX Auction Manipulation - Open',
  'IEXLSTNGMNPLTN': 'IEX Listing Manipulation',
  'IEXMIDPTMNPLTN': 'IEX Midpoint Manipulation',
  'IEXMRKGCLSQUOTE': 'Marking the Close Quote - IEX',
  'IEXMRKGCLSTRD': 'Marking the Close Trade - IEX',
  'IEXQUOTESTFNG': 'IEX Quote Stuffing/Excessive Messages',
  'IFIM': 'Intermarket Frontrunning/Intermarket Manipulation',
  'IM': 'Instant Messaging',
  'IMPRPMKTMKNG': 'Improper Market Making',
  'IMPRRUPTICKBID': 'Improper Uptick of Bid',
  'IMPSTRMBRFIRM': 'Non Registered Firm representing as Member Firm',
  'IMPSTRRGSTDREP': 'NonRegistered Individual represents Registered Rep',
  'INCRWDRVW': 'In-Crowd Review',
  'INFOBARR': 'Information Barrier',
  'INFOBARROPTNSX': 'Information Barrier - Options Cross',
  'INPERSON': 'In-Person 25% Minimum - AMEX Rule 958',
  'INSDRTRADING': 'Insider Trading',
  'INSIDEINFO': 'Inside Information',
  'INTEREST': 'Interest',
  'INTERMKTSWPODR': 'Intermarket Sweep Order',
  'INTERNALIZATION': 'Internalization',
  'INTGRTDSWEEP': 'FINRA 7440-7450 - Firm Failed to report',
  'INTRADAYTRD': 'AMEX Rule 719c - Intra Day trades for Clearence',
  'INTRSN': 'Intrusion',
  'INVGNWRKFWEXMPT': 'Investigation Workflow Exempt',
  'INVMTBANKG': 'Product Origination/Investment Banking',
  'IPOFRSTPRINT': 'FINRA Rule 6440 - IPO First Print',
  'IPOMKTODRSWEEP': 'IPO Market Order Sweep',
  'ISE804QTTNVLTN': 'ISE Rule 804(e)(2)(i)',
  'ISERULE1407': 'ISE Rule 1407 - Member Firm Compliance',
  'ISSRACTVS': 'SEC Rule 102-Activity of Issuer/Securities holder',
  'ISSRFRAUD': 'Issuer Engaging in Potentially Fraudulent Activity',
  'ISSRRLTDDSCLR': 'Issuer Related Disclosure',
  'ISSRRLTDNOFIRM': 'Issuer Related No Firm Noted',
  'ITSPOSTCLOSE': 'AMEX Rule 1100-223-Issuance-ITS Post after Close',
  'ITSPREOPNG': 'AMEX Rule 232 - ITS Pre Opening',
  'KNOWCSTMR': 'Know Your Customer',
  'LACKRATESCHED': 'Lack Rate Schedule',
  'LARGEODRMNPLTN': 'Large Order Manipulation',
  'LARGEOPTNRPTG': 'FINRA Rule 2360-Large option Poistion reporting',
  'LARGERVWOPTNSX': 'Large Unhedged Review - Options Cross',
  'LARGETRDRPTD': 'Large Trade Reoprted Late',
  'LATEFORMFLNG': 'Member Firm Filed form Late',
  'LATEFRGNTRDRPTG': 'FINRA Rule 6622 - Late Foreign Trade Reporting',
  'LATELRGTRDRPTG': 'Late Large Trade Reporting',
  'LATESHRTINTRST': 'Late Short Interest Reporting by Member Firm',
  'LATETRDNG': 'Late Trading',
  'LEP': 'Limited English Proficiency (LEP)',
  'LIMITORDRPRTCTN': 'FINRA Rule IM-2110-2 - Limit Order Protection',
  'LKBK': 'Look Back',
  'LMODRDSPLY': 'Limit Order Display - Cross',
  'LMUPLMDOWN': 'Limit Up Limit Down',
  'LNKGE8020': 'AMEX Rule 944-BOX Rule Chapter VII - Linkage 80/20',
  'LNKGTRDVLTN': 'AMEX Rule 942-Sale of Linkage Eligible Option',
  'LOCKAGRMTVLTN': 'Securities Subject to Lockup Agreement violation',
  'LOHRROC': 'Large Options Hedge Reporting Review',
  'LOPROPTNSX': 'LOPR - Options Cross',
  'LOSSONTRANS': 'Loss on Transaction',
  'LOSTSCRTS': 'Lost or Stolen Customer Securities',
  'LQDTN': 'Liquidation - Cross',
  'LQDTYREPLNPT': 'Liquidity  Replenishment Point',
  'LRGUNHEDGEDRVW': 'Large Unhedged Review',
  'LSTDPR': 'Arca Listed Price',
  'LTPPRNCLAGNT': 'AMEX Rule Article IV-LTP holder  Principal & Agent',
  'LTPSCRTSVLTN': 'AMEX Rule Article IV-LTP holders-Ineligible Scrts',
  'LYRCROSS': 'Layering - Cross',
  'LYRNG': 'Layering',
  'MAR15C35': 'Market Access Rule (SEC Rule 15c3-5)',
  'MARGIN': 'Margin Problems',
  'MARRIEDPUTS': 'Married put Strategy-Circumvent Short Sale',
  'MBRFIRMFRAUD': 'FINRA Firm - In Potentially Faraudulent Activities',
  'MBRFIRMISO': 'AMEX Rule 131-Monitor Member Firms sending ISO',
  'MBRTRDRPTVLTN': 'FINRA 6380A-6622-Member Report Trade-Non Member',
  'MCBBIS': 'Multi-Class Broad-Based Index Spread',
  'MEDIAVSNONMEDIA': 'Media vs Non-Media',
  'MIAXINTRM': 'MIAX (Interim)',
  'MIN_QUOTE_SIZE': 'Minimum Quote Size',
  'MINEXPSRPRDVLTN': 'ISE Rule 717(d),717(e),811(e)(2) Violation',
  'MINPRCNGINCRT': 'Minimum Pricing Increment',
  'MISAPPR': 'Misappropriation/Forgery',
  'MISLEADSALE': 'Mislead Sale',
  'MISREP': 'Misrepresentation',
  'MISREPACCNTTYPE': 'Misrepresentation - Account Type',
  'MKTMAINT': 'Market Maintenance',
  'MKTNGSALES': 'Marketing/Sales Literature',
  'MKTONCLOSEIMBLC': 'AMEX Rule 131A-Market On Close Order Imbalance',
  'MKTORDEREXCTN': 'Market Order Execution Complaint',
  'MKTORDERPRTCTTN': 'Market Order Protection',
  'MKTORDERTMLS': 'AMEX Rule -Market Order Timeliness',
  'MKTPRMNPLTN': 'Market Price Manipulation on a Single day',
  'MKTTMNG': 'Market Timing',
  'MLQALGTN': 'Issuer Market Listing Qualifications Allegation',
  'MLTPLALGTN': 'Multiple Allegation',
  'MLTPLMPIDTRDRPT': 'FINRA 7230A - Multiple MPID Trade Reporting',
  'MMACTVS': 'SEC Rule 103_MM Acts as Passive MM',
  'MMJNTTRDGACCT': 'MM Joint Account Trading',
  'MMNTMIGNTN': 'Momentum Ignition - Cross',
  'MMOBGTN': 'Arca Market Maker Obligations',
  'MMQTTNOBGTN': 'BOX CHPTR VI - Market Maker Quotation Obligations',
  'MMRGSTDVOLOBGTN': 'BOX CHPTR VI-MM Firm Registered Volume Obligations',
  'MMRSTCOB': 'Market-Maker Orders to Rest in Complex Order Book',
  'MNCPLSCRTSINTGY': 'Trade Reporting accuracy-Muni Securities',
  'MNCPLSCRTSXCTN': 'Municipal Securities Best Execution',
  'MNFCTRDDATA': 'Manufactured Evidence',
  'MNGDACCTS': 'Managed Accounts',
  'MNPLTNATCLOSE': 'Manipulation at the Close',
  'MNPLTNOPTNSX': 'Manipulation - Options Cross',
  'MNPLTVQTODRFDG': 'Manipulative Quote - Order Fading',
  'MNTHYORDERDLVRY': 'Monthly Order Delivery Status Review',
  'MOCLOC': 'MOC/LOC',
  'MONELODA': 'MONE/LODA',
  'MRTGGEQTYSCRTS': 'RR recommend using Home Equity to Trade securities',
  'MSRBRGTSNISSUE': 'MSRB Registration issues Prohibiting Registration',
  'MSRBRULEVLTN': 'MSRB Rule Violation',
  'MSSGACCTTYPE': 'Amex Rule 719-Member Firm to Submit Account Type',
  'MSSNGBADGENB': 'AMEX Rule 719-Participants submit Badge Numbers',
  'MSSNGRPT': 'Missing Report',
  'MTCQUOTEEDGA': 'Marking the Close Quote - EDGA',
  'MTCQUOTEEDGX': 'Marking the Close Quote - EDGX',
  'MTCQUOTEFOREDGA': 'Marking the Close - Quote for EDGA',
  'MTCQUOTEFOREDGX': 'Marking the Close - Quote for EDGX',
  'MTCQUOTEVLTN': 'Influence closing by displaying fictitious quotes',
  'MTCTRDEDGA': 'Marking the Close Trade - EDGA',
  'MTCTRDEDGX': 'Marking the Close Trade - EDGX',
  'MTCTRDFOREDGA': 'Marking the Close - Trade for EDGA',
  'MTCTRDFOREDGX': 'Marking the Close - Trade for EDGX',
  'MTCTRDORF': 'Marking the Close Trade - ORF',
  'MTCVLTN': 'BOX Chapter II-Influence the day\'s closing',
  'MTLFUNDASHARES': 'A Shares -MF',
  'MTLFUNDBSHARES': 'B Shares - MF',
  'MTLFUNDCSHARES': 'Mutual Funds - C Shares',
  'MTOC': 'Marking the Open / Close - Cross',
  'MTOVLTN': 'Influencing the Market day\'s opening',
  'MTRGGONMRGN': 'Margin Account Linked to Home Equity',
  'MUNICOI': 'Municipal Advisor Conflict of Interest',
  'MUNIFRPRCNG': 'Muni Fair Pricing',
  'MXDCPCTY': 'Mixed Capacity',
  'NAAUCTNRSPNDRS': 'Non-Appointed Auction Responders',
  'NASDQRULE4613': 'Nasdaq Market Maker Quoting Obligations',
  'NASDQRULE4626': 'NASDAQ Rule 4626',
  'NBDAFFLTACTVY': 'Non-Broker-Dealer Affiliate Activity',
  'NETACAPDFCNY': 'Failure to maintain Firm\'s Net Capital Requirement',
  'NETASSETVALUE': 'Net Asset Value Program',
  'NETCAPPREPYMNT': 'SEC Rule 15c-3-Member Firm prepaid on SubLoan',
  'NETCAPXMPTN': 'Net Capital Exemption',
  'NEWFIRMOATSLFR': 'FINRA 7440-7450-New Firm Failed to report OATS',
  'NHLDODRSRVW': 'Not Held Orders Review',
  'NOALGTN': 'No Apparent Allegation',
  'NOMINEEACCT': 'Nominee Account',
  'NON_RSA': 'Non-RSA',
  'NONBONAFIDEMM': 'Non Bona Fide Market Making',
  'NONCASHCMPNS': 'Non Cash Compensation for Sale of Securites',
  'NONCUSTACCT': 'Non Customer Account',
  'NONDELFUNDMF': 'NON-DEL FUND-MF',
  'NONDELOFBOND': 'NON-DEL OF BOND',
  'NONDELOFSEC': 'NON-DEL OF SEC',
  'NONDELOPTPRO': 'NON-DEL OPT PRO',
  'NONDPMAPLCNS': 'Non-DPM Applications',
  'NONECNSELFWASH': 'FINRA 2010-Same Participant on Buy/Sell of Trade',
  'NONEXECOPTFIRM': 'Non Exec Option Firm',
  'NONEXECOPTN': 'Non Exec Option',
  'NONHDGGRVW': 'Non-Hedging Review',
  'NONMBRFIRMFRAUD': 'NON FINRA Firm - Potential Fraudulent Activity',
  'NONMBRTRDG': 'Non-Member Trading',
  'NONSCRTYLTGTN': 'Litigation Unrelated to Securities Transaction',
  'NOTAPPLICABLE': 'No Complaints Provided',
  'NOTSPCFD': 'Not Specified',
  'NSDQCROSSMNPLTN': 'FINRA 2010-NASDAQ Intraday Cross Manipulation',
  'NSDQCRTNGSALES': 'FINRA 2010-NASDAQ Routing Wash Sales',
  'NSE': 'No Subsequent Events',
  'NSRNCTHEFT': 'Theft of Customers\' Insurance by Associated person',
  'NTRNLRVW': 'Internal Review',
  'NTTNEARREALTM': 'NBBO Trade Through - Near Real Time',
  'NTTPOSTTRD': 'NBBO Trade Through - Post Trade',
  'NYSE410BSBMSNS': 'NYSE Rule 410B Submissions',
  'NYSECSIIVLTN': 'Crossing Session II violation',
  'NYSEMKTWIFIPLCY': 'NYSE/NYSE MKT Wireless Policy',
  'NYSEODDLTPTTRN': 'NYSE Odd Lot Pattern',
  'NYSEQUOTEPTTRN': 'NYSE Quote Stuffing Pattern',
  'OATSFLRFILEDATA': 'FINRA Rule 7440  Failure to Submit Data',
  'OATSRPTGVAR': 'FINRA 7450-OATS/ACT Variance Reporting',
  'OBFFRONTRUNNG': 'Options Before Futures - FrontRunning',
  'OBGTNSMM': 'Obligations of Market Makers',
  'OBVSERRFLNGS': 'Obvious Error Filings',
  'OCCADJTMISUSE': 'BOX Chapter III-Misuse of OCC Adjustment',
  'OCCAJSMTOPTNSX': 'OCC Adjustment - Options Cross',
  'ODDLOTORDR': 'Odd-Lot Orders',
  'ODDLTODRRVW': '1-5 Odd Lot Order Review',
  'ODRCNCLNPTTRN': 'Order Cancellation Pattern by Initiating Firm.',
  'ODREXPSR': 'Order Exposure',
  'ODRMNPLTN': 'Baiting the Specialist',
  'ODRMSRPRTNAJSMT': 'Order Misrepresentation via OCC Adjustment',
  'ODRPRTCTNTRDG': 'Order Protection / Trading Ahead - Cross',
  'OEXEXRCSVLTNS': 'OEX Exercise Violations',
  'OFCRDIRMF': 'Officer DIR MF',
  'OFFERREIMBRS': 'Offer Reimburse',
  'OFFFLOOR': 'AMEX Rule 958-Off-Floor In-Person 80% Minimum ',
  'OFFFLOORMM': 'NASDAQ OMX PHLX Rule 10680 Off Floor Member',
  'OFFXCHNGTRDG': 'Off-Exchange Trading',
  'OFLR_LMM_RVW': 'On-Floor LMM Only Review',
  'OMXPHLX1064VLTN': 'Crossing, Facilitation, Solicited Orders',
  'ONFLOORTRDG': 'On Floor Trading',
  'ONLINE': 'On-Line Trading',
  'OPNGQT': 'Opening Quote',
  'OPNGRTTNBIDASK': 'ISE RULE 803-Opening Rotation Quote-Bid/Ask Spread',
  'OPNGRTTNQUOTE': 'ISE RULE 803-Opening Rotation Quote',
  'OPNGTAPEANDGAP': 'Opening Tape and Gap-Surveil Openings',
  'OPR928': 'System Issues, Failures or Delays',
  'OPR932': 'Ownership Review',
  'OPR939': 'Registration - NYSE - MKT',
  'OPR940': 'Registration - NQ',
  'OPR942': 'Electronic Offsite Exam',
  'OPRTNOFLINKAGE': 'ISE Rule 1901 and 1904-Operation of the Linkage',
  'OPTNDRCTDORDER': 'Exchange Rule 997-ANTE-Option Directed Order Flow',
  'OPTNINTERPSTNNG': 'AMEX Rule 156 and 950(g)-Option Interpositioning',
  'OPTNLATETRDNG': 'Option Late Trade Reporting-90 Secs of Execution',
  'OPTNORGNCDOC': 'Options Origin Code',
  'OPTNRECOMEND': 'Option Recommend',
  'OPTNRLNC': 'AMEX Rule 958A-Option Reliance',
  'OPTNSBESTEXCTN': 'Options - Best Execution',
  'OPTNSODREXPSR': 'Options Order Exposure',
  'OPTNSPSTNLIMIR': 'FINRA 2360-Options Position Limit Compliance',
  'OPTNSTPPDORDERS': 'AMEX Rule 950(o)Option Stopped Orders',
  'OPTNTRDG': 'AMEX Rule 175-Options Trading ',
  'OPTNXCTBLUNXCTD': 'AMEX Rule 958-Executable Order Unexecutable',
  'ORALDISCNOWR': 'Oral Disc No WR',
  'ORDERPRTCTN': 'ISS Rule 1902 - Order Protection',
  'ORDERRTNGMTCHG': 'Order/Route Matching-OATS to SuperMontage Orders',
  'ORDERTCKTRVW': 'AMEX Rule 154-156-Order Ticket Review',
  'ORDRSHRDNG': 'FINRA 5290-Shred Order - Falsely Generate Credit',
  'ORGN_CD': 'Origin Code',
  'OTCMINPRCNGINCR': 'OTC Minimum Pricing Increment',
  'OTHER': 'Other',
  'OTHERFIRMONLY': 'Miscellaneous (for firm related complaints only.)',
  'OTHERNONSP': 'Miscellaneous (Non-Sales Practice Only)',
  'OTHERSP': 'Miscellaneous (Sales Practice Only)',
  'OTHERTHEFT': 'Other Theft/Forgery',
  'OUTSDBUSACTVS': 'Outside Business Activities',
  'PAROFCLXCPTNRPT': 'PAR Officials Exception Report',
  'PARTRNDLT': 'Partial Round Lot',
  'PATRIOTACT': 'PATRIOT Act Liquidation',
  'PBLCNOFTRNSCTN': 'FINRA 5210-Truthful Publication of Transactions',
  'PBLSHQTNNVLTN': 'FINRA Rule 5220,6170 Published-Displayed Quotation',
  'PEGGINGCAPPING': 'BOX Chapter III-Pegging and Capping',
  'PEGGNG': 'Pegging - Cross',
  'PFSNEARREALTM': 'PAR Filter Surveillance - Near Real Time',
  'PGMTRDGCSTMR': 'Program Trading - Trading Ahead of a Customer',
  'PGMTRDGVLTN': 'Program Trading Violation',
  'PGNGCPNGOPTNSX': 'Pegging and Capping - Options Cross',
  'PHLXOPTNS': 'PHLX Options FBMS Deficiencies',
  'PIPMNPLTN': 'BOX Rule Chapter V - Improper PIP Allocation',
  'PLMMONEMNTQTNG': 'PLMM One Minute Quoting',
  'PMM': 'Preferred Market Maker',
  'POLITICALCNTRB': 'Political Contributions - G-37/38',
  'POORADVICE': 'Poor Recommendation/Poor Advice',
  'POORPRFMC': 'Poor Performance',
  'POORPRFMCSPLST': 'Poor Performance - Specialist/Trade Related',
  'POORSRVC': 'Poor Service',
  'POSITMNPLTN': 'Matching Window-Influence POSIT Match Price',
  'PPRTYQUOTESYSTM': 'Proprietary Quoting System',
  'PREARGNDTRDNG': 'Pre Arranged Trading',
  'PREMKTMNPLTN': 'Pre-Open Manipulation-Executing Ficticious Trades',
  'PREVMPLYR': 'Complaints referred to Previous Employer',
  'PRIMPMCNSM': 'Price Improvement Mechanism (M PRIME)',
  'PRNCPLORDRACCSS': 'ISE Rule-1904-Limitation of Pricipal Order Access',
  'PROXY': 'Proxy/Prospectus',
  'PRRTYOFQUOTES': 'ISE Rule 713 - Priority Of Quotes',
  'PRRTYRVW': 'Priority Review',
  'PRTCTOFFRD': 'Protect Offered',
  'PRVTSCRTSSALE': 'Selling Away Private Securities w/o Firm Approval',
  'PSTNLMSOPTNSX': 'Position Limits - Options Cross',
  'PTNTLMAVLTN': 'Potential Membership Agreement Violation',
  'PTORGNCDCHNG': 'Post-Trade Origin Code Change',
  'PTTRNMTC': 'Pattern Of MTC Activity over Several Days',
  'PURPSFLLATETRD': 'Purposeful Late Trade Report',
  'PYMNTMM': 'Rule 2460-Payment for a Market Maker',
  'PYMNTORDERFLOW': 'Payments/Rebates to Direct order to an MM or ECN',
  'QLFDCNTGTCROSS': 'Qualified Contingent Cross',
  'QMMALCTN': 'QMM Allocation',
  'QTNGNACLASSES': 'Quoting in Non-Appointed Classes',
  'QTTNMLTPLOTCMED': 'Quotations in Multiple OTC Mediums',
  'QTTNRQRMNTOBGTN': 'FINRA 6272-Character of Quotations',
  'QUOTESPOOFING': 'Quote Spoofing - Cross',
  'QUOTEWDTH': 'Quote Width',
  'RATECHNGNTC': 'Rate Change Notice',
  'RATEDFFRNC': 'Rate Difference',
  'REALCTN': 'Reallocation',
  'REGA': 'Regulation A',
  'REGB': 'Regulation B',
  'REGDRULE504': 'Regulation D Rule 504',
  'REGE': 'Regulation E',
  'REGM': 'Regulation M',
  'REGM104': 'Regulation M 104 - Cross',
  'REGM104OTCSPCFC': 'Regulation M 104 - OTC-Specific',
  'REGNMSTRDTHRGH': 'SEC Rule 611-Order Protection Trade Through',
  'REGNMSXMPTN': 'FINRA 6282- Reg NMS Exemption Modifier',
  'REGS': 'Regulation S',
  'REGSCI': 'Regulation SCI',
  'REGSHO200GVLTN': 'Regulation SHO Rule 200(g) Violation',
  'REGSHO204': 'SEC Rule 204-Compliance',
  'REGSHO205': 'SEC Rule 204-Compliance',
  'REGSHO35DAY': 'SEC Rule 203(b)(2)(iii)-Compliance',
  'REGSHOCLOSEOUT': 'SEC Rule 203(b)(3)-Compliance',
  'REGSHOLOCATE': 'SEC Rule 203(b)-Compliance',
  'REGSP': 'Regulation S-P',
  'REGTCRDTEXTVLTN': 'Failure to Follow REG T Credit extension',
  'REGTFREERIDE': 'Free Riding per REGT',
  'REIMBURSEMENT': 'Reimbursement',
  'REMMDESTBL': 'Registered Equity MM  Destabilizing Percentages',
  'REMMPRDSCRTS': 'AMEX 958-Minimum 60 Minute wait-Paired Securities',
  'REORG': 'Reorganization/Redemption',
  'RESEARCH': 'Research',
  'RETIREMENT': 'Retirement',
  'RGLTNATSCMPLC': 'Regulation ATS Compliance',
  'RGLTNM': 'Regulation M - Cross',
  'RGLTNSHO': 'Regulation SHO - Cross',
  'RGLTYFLOORCNDCT': 'Regulatory Floor Conduct',
  'RGM105CROSS': 'Reg M Rule 105 ? Cross',
  'RGMTQNCROSS': 'Reg M Trading/Quoting/Notification - Cross',
  'RGMTQNOTCSPEC': 'Reg M Trading/Quoting/Notification - OTC Specific',
  'RGNMSLC': 'RegNMS Lock/Cross - Cross',
  'RGNMSTRDTHRGH': 'RegNMS Trade Through - Cross',
  'RGSHONONMBRLCT': 'Regulation SHO Non-Member Locate - Cross',
  'RGSTD33ACT': 'Registered 33 Act - Issuer Reports to SEC',
  'RGSTD34ACT': 'Registered 34 Act - Issuer Reports to SEC',
  'RGSTD34ACT12B': 'Registered 34 Act 12b - Issuer Reports to SEC',
  'RGSTD34ACT12G': 'Registered 34 Act 12g - Issuer Reports to SEC',
  'RGSTD34ACT15D': 'Registered 34 Act 15d - Issuer Reports to SEC',
  'RGSTN_NYSE_ARCA': 'Registration - NYSE - Arca',
  'RGSTNC2': 'Registration - C2',
  'RGSTNCBOE': 'Registration - CBOE',
  'RGSTNMIAX': 'Registration - MIAX',
  'RJCTRPR': 'Ensure Firms Repair Rejects',
  'RLSNOACTN': 'Release No Action',
  'RLTDMC': 'Related Market Center',
  'RNDLOTORDER': 'Rule 208-Divide Round Lot Order Into Two',
  'ROMMNPLTNX': 'Resting Order Manipulation - Cross',
  'RORPTGVLTN': 'FINRA Rule 7230 A - Trade Report Input',
  'ROTPRFRNTLMRGN': 'AMEX 958-Rgstd Options Trader-Preferential Margins',
  'ROTPRNCPLAGNCY': 'AMEX Rule 111-Registered Options Traders',
  'ROTREMOTETRDNG': 'AMEX 958-Rgstd Options Trader-Remote Trading',
  'RQSTQUOTES': 'BOX Rule Chapter VI-Request Of Quotes',
  'RRNONPYMNT': 'Registered Rep Non Payment',
  'RROTAFLTNQUOTE': 'Remote Rgstd Options Trader-Affiliation Limitation',
  'RROTHERBUSACTVY': 'RR Conducts Other Business Activity-No Disclosure',
  'RROTINFOBARRS': 'AMEX 994(d)(iii)-RROT Remote Quote',
  'RROTPREMKTORDER': 'AMEX 918-RROT Cannot Submit Order-Prior Opening',
  'RROTREMOTEQUOTE': 'AMEX 994(c)(iii)-RROT Remote Quote',
  'RROTSEATASGNT': 'AMEX Rule993(c)(i)-RROT Seat Assignment',
  'RROTSROTJNTQT': 'AMEX 958-RROT/SROT cannot join Specialist Quote',
  'RROTSTRMNGQUOTE': 'Exchange Rule 994-RROT Streaming Quote Requirement',
  'RRPAIDFORCSTMR': 'Registered Rep Paid for Customer-To Obtain Account',
  'RSKLESSPRNCPL': 'AMEX Rule 24-Riskless Principal transactions',
  'RSKLESSTRDNG': 'Options Trader-Prearranged Riskless Transactions',
  'RSRVODR': 'Reserve Order',
  'RSTRDOPTN': 'Restricted Option',
  'RSTRDSCRTSRPTG': 'Restricted Securities Reporting',
  'RTDWSHSALESEDGA': 'Routed Wash Sales - EDGA',
  'RTDWSHSALESEDGX': 'Routed Wash Sales - EDGX',
  'RULE118K': 'Rule 118(k)',
  'RULE12G3-2B': 'Rule 12g3-2b',
  'RULE144(k)':	'Rule 144(k)',
  'RULE191LFR': 'Rule 191 - Non Respondents',
  'RULE193EQTYOPTN': 'Rule 193 - Equities & Options',
  'RVNSHRNG': 'Revenue Sharing',
  'RVRSMTCHG': 'Reverse Matching',
  'RVWFINRAEMP': 'Review BD Firm-Hires former FINRA Employee',
  'RVWLIMITORDR': 'SEC Rule 604 - Review Customer Limit Order',
  'SAL927': 'Sales Practice',
  'SALESLOADFEE': 'Sales Load Fee',
  'SALESPROMF': 'Sales Pro MF',
  'SBLTYHEDGE': 'Suitability - Hedge Fund',
  'SCNDYOFRNGRVW': 'Review Specialist Participation/Secondary Offering',
  'SCRTSDLVRY': 'Receipt and/or Delivery of Securities',
  'SCRTSTHEFT': 'Theft of Customers\' Security by Associated person',
  'SEC10ACMPLNC': 'SEC Rule 10a-1-Member Firm Compliance with Rule',
  'SEC602VLTN': 'SEC Rule 602',
  'SEC604LODFLSHNG': 'SEC Rule 604 Equity LOD Flashing Violation',
  'SEC604LODRLNC': 'SEC Rule 604 Equity LOD Reliance Violation',
  'SEC604SPRRLIMIT': 'SEC Rule 604 Equity LOD Superior Limits Violation',
  'SECFEESTRDRPT': 'SEC Fees Assessed Correctly on Trade Report',
  'SECFLNGFLR': 'SEC Filing Failure',
  'SELFWASHTRD': 'FINRA 2010-2020-Fraud-Self Wash /Matched Order',
  'SHOPPING': 'AMEX Rule 731(c)-Third parties entering Trade',
  'SHORTSELLACTVS': 'SEC Rule 105-Activity related to Short Selling',
  'SHRTINTRSCMPLNC': 'Short Interest Compliance',
  'SHRTINTRSCYC': 'Short Interest Cycle',
  'SHRTINTRSTRPT': 'Inaccurate Short Interest Reporting by Member Firm',
  'SHRTSALECMPLNC': 'Short Sale Rule Compliance',
  'SHRTSALEEXCTN': 'Short Sale Transaction-Influence Stock Price',
  'SHRTSALETRDRPTG': 'FINRA 7230(A)-Short Sale Trade Reporting',
  'SHRTTDRRVW': 'Short Tender Review',
  'SINGLETRDDATE': 'Single Trade Date',
  'SLCTDHBRDCROSS': 'Solicited Hybrid Cross',
  'SLCTN': 'Solicitation',
  'SLLNGWY': 'Selling Away',
  'SMALLLOTTRD': 'FINRA 2110-2120-Small Lot Trade Through Avoidance',
  'SMPAUCTNLASN': 'Simple Auction Liaison',
  'SOFTDLLRCRDT': 'Credits-Result of Arrangements between RR and IAR',
  'SPCLTACTVY': 'Specialist Related Activities',
  'SPCLTDSTBLZTN': 'AMEX Rule 170-Specialist Destabilization',
  'SPCLTOPTNHEDNG': 'Specialist Option Hedging',
  'SPDATAINTGY': 'Sales Practice Data Integrity',
  'SPFAIRPRCNG': 'SP Fair Pricing',
  'SPLTYOPTNIDXXPR': 'Specialty Options Index Expiration',
  'SPREAD': 'AMEX Rule 170-Spread ',
  'SPREADDFRNL': 'AMEX Rule 958(c)-950(n)-Spread Differential',
  'SPRVNCMPNCFLR': 'Supervisory System Failure-Comply with FINRA Rules',
  'SPWASHSALES': 'SP - Wash Sales',
  'SPXMTHFVAL': 'SPX Month and Fair Value',
  'SQNTLORDRRPTG': 'Order Reports Reported in Sequential Order',
  'SROTOFFFLR': 'AMEX Rule 993-Supplemental Rgstd Options Trader',
  'SRVCISSUES': 'Service Issues',
  'SSCBNONEXMPT': 'Short Sale Circuit Breaker Non-Exempt',
  'SSCIRCUITBREAK': 'Reg SHO Rule 201',
  'SSCLSOUT': 'Short Sale Close-out - Cross',
  'SSNMBRLCT': 'Short Sale Non-Member Locate',
  'SSNONMANIPPTTRN': 'Short Sale Non-Manipulative Pattern - Cross',
  'SSODRTRDMSMCH': 'Short Sales order Trade Mismatch - Cross',
  'STATEMENT': 'Statement/Confirms',
  'STATERGSTNISSUE': 'State Registration issues Prohibiting Registration',
  'STOCKPRCMNPLTN': 'Attempt to Influence Stock Price-Place Large Quote',
  'STOPORDER': 'AMEX 154-Stop Order',
  'STOPSTOCK': 'Stopping Stock - AMEX Rule 109',
  'SUITABILITY': 'Suitability',
  'SUITABILITYMF': 'Suitability MF',
  'SUPERMNTGMNPLTN': 'SuperMontage Manipulation',
  'SUPERMNTGMOP': 'SuperMontage Pre-Open Manipulation-MOP',
  'SUPERMNTGODDLOT': 'SuperMontage Odd Lot Harassment',
  'SUPERMNTGQUEUE': 'SuperMontage Queue Jumping',
  'SWCHNGCMSN': 'Switching B/W Similar Mutual Funds for Commission',
  'SYSTMCHNGSBUGS': 'Arca System Changes/System Bugs',
  'T1FLOORBROKER': 'T1 - Florr Broker',
  'TAPESHRED': 'Tape Shredding',
  'TAPINGRULE': 'FINRA Conduct Rule Violation',
  'TAXADVICE': 'Investor Alleges RR Provided Tax Advice',
  'TAXRPTNG': 'Tax Reporting',
  'TCKSZPLTOATSNCS': 'Tick Size Pilot OATS Non-Compliance Sweep',
  'TCKSZPLTOATSRRS': 'Tick Size Pilot OATS Reject Repair Sweep',
  'TCSSSCB': 'Trading Center Specific Short Sale Circuit Breaker',
  'TFLRGLTYRVW': 'TFL Regulatory Review',
  'THEFT': 'Theft of Customers\' Money by Associated person',
  'THIRDPARTY': 'Third Party/Anonymous',
  'THREESECFCLTTN': 'BOX Rule Chapter V-3 Second order Facilitation',
  'TICKSZNONRPTG': 'Tick Size Non-Reporting',
  'TICKSZPLTBDTCC': 'Tick Size Pilot BD Trading Center Compliance',
  'TICKSZPLTIC': 'Tick Size Pilot Trade Increment Compliance',
  'TICKSZPLTMMTSC': 'Tick Size Pilot MktMkr Trans System Compliance',
  'TICKSZPLTOATSWP': 'Tick Size Pilot OATS Integrated Sweep',
  'TICKSZPLTOC': 'Tick Size Pilot Order Compliance',
  'TICKSZPLTTAC': 'Tick Size Pilot Trade-At - Cross',
  'TICKSZPLTTAOTC': 'Tick Size Pilot Trade-At OTC',
  'TIEDHEDGE': 'Tied Hedge',
  'TMLYALCTNVLTN': 'AMEX Rule 153-AEMI-Timely Allocation Violation',
  'TOUTING': 'TOUTING',
  'TRACSCMPLNC': 'FINRA 7230(A)-TRACS Compliance-Within 20 Mins',
  'TRADEAHEAD': 'Trading Ahead',
  'TRADEHALT': 'Trading Halt',
  'TRADEORFADE': 'FINRA 6170-Trade or Fade',
  'TRADERPTG': 'Trade Reporting',
  'TRADERVRSL': 'Trade Reversal',
  'TRADETHRGH': 'Trade Through',
  'TRADETHRGHHALT': 'Trade Through Halt',
  'TRADING': 'Trading',
  'TRANSASCTDPERSN': 'Transcation by Associated Person',
  'TRANSRGLTYFEE': 'FINRA Rule 7130-Reporting Transactions-Rglty Fee',
  'TRD933': 'IEX Market Maker Obligation',
  'TRD934': 'Quote Width - BOX',
  'TRD935': 'Auction Ramping the Open - Cross',
  'TRD936': 'Auction Ramping the Close - Cross',
  'TRD941': 'MM Failure to Maintain Adequate Clearing Equity',
  'TRD943': 'Delta Combo Non-Delta Neutral Legs Lined Up',
  'TRD944': 'Cross Market Tick Marking',
  'TRD945': 'Cross Mkt Enh Auction Ramping the Close',
  'TRD946': 'Market Maker Obligations',
  'TRD947': 'ATS Order Book Odd Lot',
  'TRD948': 'Index Expiration for MIAX Cash Settled Options',
  'TRDADJSTVLTN': 'Bust Adjust - AMEX Rule 936',
  'TRDGAFTCLS': 'Trading After the Close',
  'TRDGMKTSURV': 'Trading and Markets Surveillance',
  'TRDGRMRS': 'Trading on Rumors',
  'TRDGRSTRDOPTNS': 'Trading in Restricted Options',
  'TRDINFORPTVLTN': 'FINRA Rule 7230A(d)-Trade Report Information Input',
  'TRDNGCSTMR': 'Trading Improprieties - By Customer',
  'TRDNGERRACCT': 'Trading in Error Account',
  'TRDNGFIRMEMP': 'Trading Improprieties - By Firm Employee',
  'TRDNGONFLOOR': 'Trading Improprieties - On Floor',
  'TRDPOSTDPOSTTRD': 'AMEX-ArticleV-Trade posted 180 Seconds After Close',
  'TRNSFPKG': 'Transfer Package',
  'TRR': 'Targeted Registered Representative',
  'TRSRYBESTEXTN': 'Treasury Best Execution',
  'TRSRYFAIRPRCNG': 'Treasury Fair Pricing',
  'TRSRYINTG': 'Treasury Data Integrity',
  'TRSRYMNPLMRKG': 'Treasury Manipulation - Marking',
  'TRSRYWASHSALE': 'Treasuries Wash Sale',
  'TSTNGFRAUD': 'Test Cheating or Score Fraud by Applicant',
  'U5RTLTTN': 'U5 Retaliation',
  'UNATHRDACCT': 'Unauthorized Margin Account',
  'UNATHRDOPTN': 'Unauthorized Option',
  'UNATHRDTRDG': 'Unauthorized Trading',
  'UNCLRRATES': 'Unclear Rates',
  'UNK929': 'Complaint',
  'UNK930': 'Regulatory Tip',
  'UNKNOWN': 'Unknown',
  'UNKNOWNMDR': 'Not Found',
  'UNTIMELYOPENING': 'AMEX Rule 1/119-Untimely Opening',
  'UNUSUALACTVY': 'Unusual Observed Trading Activity',
  'UNUSULPRMVT': 'Unusual Price Movement - Cross',
  'UNUSULVOL': 'Unusual Volume - Cross',
  'UTPTRDNGAHEAD': 'AMEX Rule 155-UTP Trading Ahead',
  'UTPXCTBLUNXCTD': 'UTP Executable Unexecuted',
  'VIXWKYVLTYSTLMT': 'VIX Weekly Volatility Settlement',
  'VNDRDSPLYRULE': 'Vendor Display Rule',
  'VWAPCALCMNPLTN': 'NASDAQ 100 VWAP Calculation Manipulation',
  'WASHSALEMNPLTN': 'Wash Sale Manipulation-Trade Self/Related Party',
  'WEBSITE': 'On-Line Issues',
  'WNDWDRSSNGOTC': 'Window Dressing - OTC',
  'XCHNGBUSTS': 'Arca Exchange Busts',
  'XCLSNVLTN': 'Exclusion Violation',
};

export const ProductsMap = {
  '529PLAN': '529 Plan',
  'ADR': 'American Depositary Receipt',
  'AGENCY': 'Us Agency Bond',
  'ANNUITY': 'Unspecified Annuity',
  'ARS': 'Auction Rate Security-General',
  'ARSCLOSEDEND': 'Auction Rate Security-Closed End Fund',
  'ARSDEBTCRPRT': 'Auction Rate Security-Corporate Debt',
  'ARSDEBTMNCPL': 'Auction Rate Security-Municipal Debt',
  'BANKPRDCT': 'Banking Product other than CD',
  'CASHMGMT': 'Cash Management Account (e.g., debit/credit card)',
  'CD': 'Certificates of Deposit (Coupon & Zero CDs)',
  'CDO': 'General Collateralized Debt Obligation (CDO)',
  'CHARITABLE': 'Charitable Annuity',
  'CHURCH': 'Church Bond',
  'CLOSEDEND': 'Closed End Fund',
  'CMDTYFTR': 'Commodity/Future',
  'CMDTYOPTN': 'Commodity Option',
  'CMMDTY': 'Unspecified Commodity',
  'CMO': 'Collateralized Mortgage Obligation (CMO)',
  'CNVRTBL': 'Preferred Convertible Stock',
  'COMMON': 'Common Stock',
  'CONTRACTUAL': 'Contractual Plan',
  'CONVOPTION': 'Conventional Option',
  'DEBENTURE': 'Debenture',
  'DEBTASSETBCKD': 'Debt-Asset Backed',
  'DEBTCRPRT': 'Debt-Corporate',
  'DEBTFRGN': 'Debt-Foreign',
  'DEBTMNCPL': 'Debt - Municipal',
  'DEBTUS': 'Debt-US Government',
  'DEFBENEFIT': 'Defined Benefit Plan',
  'DEFCONTRIB': 'Defined Contribution Plan',
  'DERIVATIVE': 'Unspecified Derivative',
  'DRCTINVMT': 'Direct Investments (Limited Partnerships, DPP etc.)',
  'EIA': 'Equity Indexed Annuity',
  'EQTYFRGN': 'Foreign Equity',
  'EQTYLSTD': 'Listed Equity',
  'EQTYOTC': 'Unlisted Equity (OTC)',
  'ETF': 'Exchange Traded Fund (ETF)',
  'ETN': 'Exchange Traded Note',
  'FANNIE': 'Federal National Mortgage Association',
  'FHLB': 'Federal Home Loan Bank',
  'FIXEDANNTY': 'Fixed Annuity',
  'FNNCLFTR': 'Financial Future',
  'FOREIGN': 'Miscellaneous Foreign Security',
  'FREDDIE': 'Federal Home Loan Mortgage Corporation',
  'FUTURES': 'Unspecified Future',
  'GDR': 'Global Depositary Receipt',
  'GINNIE': 'Government National Mortgage Association',
  'HEDGEFUND': 'Hedge Fund',
  'IDS': 'Income Deposit Security',
  'INDEX': 'Index Security',
  'INDEXOPTN': 'Index Option',
  'INSIDEMGR': 'Managed/Wrap Account-Inhouse Manager',
  'INSURANCE': 'Unspecified Insurance Product (not Annuity)',
  'INVMTCNTRC': 'Investment Contract',
  'INVMTCO': 'Unspecified Investment Company',
  'IRA': 'Individual Retirement Account (IRA)',
  'KEOGH': 'Keogh Plan',
  'LEASE': 'Equipment Lease',
  'LIFE': 'Life Insurance',
  'LIFESETTLE': 'Life Settlement',
  'MONEYMKT': 'Money Market - Non Fund',
  'MONEYMRKTFNDS': 'Money Market Fund',
  'MORTGAGE': 'Mortgage',
  'MUNIFUND': 'Municipal Bond Fund',
  'MUTUALA': 'Mutual Fund Class A Specific',
  'MUTUALB': 'Mutual Fund Class B Specific',
  'MUTUALC': 'Mutual Fund Class C Specific',
  'MUTUALFUND': 'Mutual Fund',
  'NBDAFFLTPRDCT': 'Non-Broker-Dealer Affiliate Product',
  'NONE': 'No Product',
  'NONSPECIFIC': 'Non-Specific Security',
  'NOTAPPLICABLE': 'No Complaints Provided',
  'NOTE': 'Deposit Note',
  'NOTSPCFD': 'Not Specified',
  'OILGAS': 'Oil And Gas Interest',
  'OPTION': 'Option',
  'OTHER-SEC': 'Other Security',
  'OTHER': 'Other Product',
  'OUTSIDEMGR': 'Managed/Wrap Account-Outside Manager',
  'PENNYSTOCK': 'Penny Stock',
  'PREFERRED': 'Preferred Stock',
  'PRIMEBANK': 'Prime Bank Instrument',
  'PRIVATE': 'Private Equity',
  'PROMISSORY': 'Promissory Note',
  'PROTECTED': 'Mutual Fund - Principal Protected',
  'PRVTPLCMT': 'Private Placement (e.g. PIPES, etc.)',
  'REIT': 'Real Estate Investment Trust (REIT)',
  'REPO': 'Repurchase/Reverse Repurchase Agreement',
  'RES': 'Real Property',
  'SECFUTURE': 'Securities Future',
  'SECPRDCT': 'Securitized Product',
  'SELFDRCTD': 'Self Directed Fee Based Account (Non-Managed)',
  'SEP': 'Simplified Employee Pension',
  'SIF': 'Stock Index Future',
  'SIMPLE': 'Simple Plan',
  'SNGLSTOCK': 'Single Stock Future',
  'SPAC': 'Special Purpose Acquisition Company (SPAC)',
  'SPCURRENCY': 'Currency Linked Note',
  'SPEQUITY': 'Equity Linked Note',
  'SPINDEX': 'Index Linked Note',
  'STOCKOPTN': 'Employee/Employer Stock Option Plan',
  'STRETCH': 'Extended/Stretch IRA',
  'STRUCTURED': 'Structured Product',
  'TIC': 'Tenants in Common 1031 Exchange (TIC)',
  'TREAS': 'U.S. Government Treasury',
  'UIT': 'Unit Investment Trust (UIT)',
  'UNITS': 'Units',
  'UNKNOWN': 'Unknown',
  'UNKNOWNMDR': 'Not Found',
  'UTP': 'Unlisted Trading Privilege',
  'VARIABLEANNTY': 'Variable Annuity',
  'VARLIFE': 'Variable Life Insurance',
  'VIATICAL': 'Viatical Settlement',
  'WARRANT': 'Warrant/Right',
  'WRAP': 'Wrap Account-Unknown Manager',
};
