import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'report-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  public show = false;
  private subscription: Subscription;
  @Input() isStatic: boolean = false; // isStatic means ignore if there are pending requests, so this component can be reusable.

  constructor(
    public loaderService: LoaderService,
  ) { }

  ngOnInit() {
    if (this.isStatic) {
      this.show = true;
    } else {
      this.subscription = this.loaderService.show.subscribe((state: boolean) => {
        this.show = state;
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
