import {
  deepSort,
  getPrior12Quarters,
  buildRowDataString,
  buildColumnLabelsString,
  SummaryExportData,
  buildExportDataString,
  trimTrailingChar
} from "src/app/shared/utils/utils";
import {
  ProductsMap,
  ProblemsMap,
} from './customer-complaint-mappings.model';

/**
 * Interfaces for the customer complaint.
 */

export interface CustomerComplaintProblemLookup {
  description: string;
  problemCd: string;
  salesPracticeFlag: string;
}

export interface CustomerComplaintProductLookup {
  description: string;
  productCd: string;
}

export interface ReportIndustryCustomerComplaintDetail {
  batchId: number;
  customerComplaintProblemLookup: CustomerComplaintProblemLookup;
  customerComplaintProductLookup: CustomerComplaintProductLookup;
  indCcmplntDtlId: number;
  indCmplntCt: number;
  indCmplntPt: number;
  period: string;
  problemCd: string;
  productCd: string;
}

export interface ReportIndustryCustomerComplaintSummary {
  batchId: number;
  indAssocCt: number;
  indCcmplntSumryId: number;
  indCmplntCt: number;
  indCmplntPerAssocPt: number;
  indCmplntRltd2RegRepCt: number;
  indCmplntRltd2RegRepPt: number;
  indOprtnlCmplntCt: number;
  indOprtnlCmplntPt: number;
  indSlsPrctcCmplntCt: number;
  indSlsPrctcCmplntPt: number;
  period: string;
}

export interface ReportCustomerComplaintsQuarterlyEntity {
  associatesCt: number;
  ccmplntCt: number;
  ccmplntId: number;
  cmplntRltd2BrnchCt: number;
  cmplntRltd2RegRepCt: number;
  cmplntRltd2SlsPrctcCt: number;
  customerComplaintProblemLookup: CustomerComplaintProblemLookup;
  customerComplaintProductLookup: CustomerComplaintProductLookup;
  firmId: string;
  period: string;
  problemCd: string;
  productCd: string;
  rptId: number;
}

export interface ReportCustomerComplaintDetail {
  batchId: number;
  ccmplntDtlId: number;
  cmplntCt: number;
  cmplntPt: number;
  customerComplaintProblemLookup: CustomerComplaintProblemLookup;
  customerComplaintProductLookup: CustomerComplaintProductLookup;
  indCcmplntDtlId: number;
  problemCd: string;
  productCd: string;
  reportCustomerComplaintsQuarterlyEntityList: ReportCustomerComplaintsQuarterlyEntity[];
  reportIndustryCustomerComplaintDetail: ReportIndustryCustomerComplaintDetail;
  rptId: number;
}

export interface ReportCustomerComplaintSummary {
  associatesCt: number;
  batchId: number;
  cmplntCt: number;
  cmplntPerAssocPt: number;
  cmplntRltd2RegRepCt: number;
  cmplntRltd2RegRepPt: number;
  firmCcmplntSumryId: number;
  firmId: string;
  indCcmplntSumryId: number;
  oprtnlCmplntCt: number;
  oprtnlCmplntPt: number;
  period: string;
  reportCustomerComplaintDetails: ReportCustomerComplaintDetail[];
  reportIndustryCustomerComplaintSummary: ReportIndustryCustomerComplaintSummary;
  rptId: number;
  slsPrctcCmplntCt: number;
  slsPrctcCmplntPt: number;
}


// interface for main response from: https://api-isso.ddwa.dev.finra.org/reportData/reportId/21505716/viewName/Problem/dataType/s
// EXAMPLE: ReportService.getReport(this.reportId, this.viewName, 's').subscribe((data: CustomerComplaintSummary[]) => { ... });
export interface CustomerComplaintSummary {
  reportCustomerComplaintSummary: ReportCustomerComplaintSummary;
  reportIndustryCustomerComplaintDetails: ReportIndustryCustomerComplaintDetail[];
}

export interface CustomerComplaintSummaryResponse {
  reportCustomerComplaintSummaries: CustomerComplaintSummary[];
}

export interface CustomerComplaintQuarterData {
  totalCount?: number;
  firmPercentage?: number;
  industryPercentage?: number;
}

export interface CustomerComplaintIndustryQuarterData {
  indCcmplntDtlId: number;
  period: string;
  batchId: number;
  productCd?: string;
  problemCd?: string;
  indCmplntCt: number;
  indCmplntPt: number;
  customerComplaintProblemLookup?: {
    problemCd: string;
    description: string;
    salesPracticeFlag: string;
  };
  customerComplaintProductLookup?: {
    productCd: string;
    description: string;
  };
  sortOrder: number;
}

export interface CustomerComplaintTotalsQuarterData {
  grandTotalCount: number;
  sales: {
    totalCount: number;
    firmPercentage: number;
    industryPercentage: number;
  };
  operational: {
    totalCount: number;
    firmPercentage: number;
    industryPercentage: number;
  };
}


export interface CustomerComplaintRowData {
  sortOrder: number;
  displayName: string;
  viewName: string;
  problemCd?: string;
  problemCdDisplay?: string;
  productCd?: string;
  productCdDisplay?: string;

  productsObj?: {
    [key: string]: {
      productCd: string;
      productCdDisplay: string;
      displayName: string;
      quarter1?: number;
      quarter2?: number;
      quarter3?: number;
      quarter4?: number;
    };
  };
  products?: {
    productCd: string;
    productCdDisplay: string;
    displayName: string;
    quarter1?: number;
    quarter2?: number;
    quarter3?: number;
    quarter4?: number;
  }[];
  problemsObj?: {
    [key: string]: {
      problemCd: string;
      problemCdDisplay: string;
      displayName: string;
      quarter1?: number;
      quarter2?: number;
      quarter3?: number;
      quarter4?: number;
    };
  };
  problems?: {
    problemCd: string;
    problemCdDisplay: string;
    displayName: string;
    quarter1?: number;
    quarter2?: number;
    quarter3?: number;
    quarter4?: number;
  }[];

  quarter1?: CustomerComplaintQuarterData;
  quarter2?: CustomerComplaintQuarterData;
  quarter3?: CustomerComplaintQuarterData;
  quarter4?: CustomerComplaintQuarterData;
}


export interface CustomerComplaintIndustryData {
  sortOrder: number;
  displayName: string;
  productCd?: string;
  productCdDisplay?: string;
  problemCd?: string;
  problemCdDisplay?: string;
  quarter1?: CustomerComplaintIndustryQuarterData;
  quarter2?: CustomerComplaintIndustryQuarterData;
  quarter3?: CustomerComplaintIndustryQuarterData;
  quarter4?: CustomerComplaintIndustryQuarterData;
}

export interface CustomerComplaintTransformedResponse {
  headings: string[];
  rowData: CustomerComplaintRowData[];
  topTenIndustryComplaintsRowData: CustomerComplaintIndustryData[];
  complaintsAsscIndv: {
    quarter1?: CustomerComplaintQuarterData;
    quarter2?: CustomerComplaintQuarterData;
    quarter3?: CustomerComplaintQuarterData;
    quarter4?: CustomerComplaintQuarterData;
  };
  complaintsRepsRelated: {
    quarter1?: CustomerComplaintQuarterData;
    quarter2?: CustomerComplaintQuarterData;
    quarter3?: CustomerComplaintQuarterData;
    quarter4?: CustomerComplaintQuarterData;
  };
  allOtherComplaintsObj: {
    quarter1?: CustomerComplaintQuarterData;
    quarter2?: CustomerComplaintQuarterData;
    quarter3?: CustomerComplaintQuarterData;
    quarter4?: CustomerComplaintQuarterData;
  };
  totalsObj: {
    quarter1?: CustomerComplaintTotalsQuarterData;
    quarter2?: CustomerComplaintTotalsQuarterData;
    quarter3?: CustomerComplaintTotalsQuarterData;
    quarter4?: CustomerComplaintTotalsQuarterData;
  };
}


/**
 * This function is quite complex.
 * The main objective of this function is to transform
 * the response of the customer complaint summary data into a data-structure/format
 * that is more tabular and easier to loop through.
 *
 * The problem comes from how the data is supposed to be displayed in the UI.
 * There are a list of problems and with each problem, there are products.
 * Then there are 4 quarters of data for each problem and each product.
 * The latest quarter sorts the problems by count and the quarters before the latest
 * must follow that sort by name so that they all appear in the same row and align properly.
 *
 * This pure function tries to solve that issue by following these steps:
 * 1. sort the latest quarter products/problems by complaint count
 * 2. sort the latest quarter products' problems/problems' products by complaint count
 * 3. loop through the problems' products and create a sort order number that the older quarters will follow by
 * 4. loop through the older quarters and apply the sort order to the problems
 * 5. loop through all of the quarters and start aggregating/transforming the data by quarter
 *
 * while looping, the function is also building a data-structure for distinct problems and their products.
 *
 * @param data - the response from the AJAX summary call
 * @return {results} - an object with all of the transformed data
 */
export function transformCustomerComplaintsSummaryDataResponse(
  data: CustomerComplaintSummary[],
  viewName: string
): CustomerComplaintTransformedResponse {
  if (!data) {
    throw new Error('cannot process customer complaint summary data...');
  }
  const unknownView = !['Product', 'Problem'].includes(viewName);
  if (unknownView) {
    throw new Error('unknown view...');
  }
  // first, get quarter dates for the column headings
  const dataCopy = [ ...data ];
  // const dataMonthsList = dataCopy.map(i => i.reportCustomerComplaintSummary.period);
  const priorQuarters = getPrior12Quarters(dataCopy[0].reportCustomerComplaintSummary.period).slice(0, 4);
  const headings = priorQuarters.map(i => i.formattedQuarterFull);
  const priorQuartersList = priorQuarters.map(i => i.dateString);

  const allOtherComplaintsObj = {}; // Complaints per Associated Individual
  const totalsObj = {}; // Complaints per Associated Individual
  const complaintsAsscIndv = {}; // Complaints per Associated Individual
  const complaintsRepsRelated = {}; // Complaints Identified as Related to Representative

  // next, sort the latest quarter's reportCustomerComplaintDetails by complaint count
  const latestSummaryDetailsList = dataCopy[0].reportCustomerComplaintSummary.reportCustomerComplaintDetails;
  deepSort(latestSummaryDetailsList, 'cmplntCt', true);

  // now for the sorting.
  // Each summary's details are out of order.
  // the first summary is sorted by count. now for that first list, assign each detail a sortOrder number;
  // the next 3 will have to follow that sort for the problemCd to align properly in the view
  const sortOrderTree = {};
  const sortOrderList = [];

  latestSummaryDetailsList.forEach((detail, index) => {
    const sortOrder = index + 1;
    let dataObj: CustomerComplaintRowData;

    if (viewName === 'Problem') {
      const problemCd = detail.problemCd;
      const problemCdDisplay = ProblemsMap[problemCd]; // the display value
      (detail as any).sortOrder = sortOrder;
      sortOrderTree[problemCd] = sortOrder;
      dataObj = { viewName, sortOrder, problemCd, problemCdDisplay, productsObj: {}, displayName: problemCdDisplay, };
      if (detail.reportCustomerComplaintsQuarterlyEntityList) {
        // for each of the problems' products, add it to an object map, the following quarters will have to follow this order
        detail.reportCustomerComplaintsQuarterlyEntityList.forEach(e => {
          const productCdDisplay = ProductsMap[e.productCd];
          dataObj.productsObj[e.productCd] = {
            productCd: e.productCd,
            productCdDisplay, // the display value
            displayName: productCdDisplay, // the display value
          };
        });
      }
    } else {
      const productCd = detail.productCd;
      const productCdDisplay = ProductsMap[productCd]; // the display value
      (detail as any).sortOrder = sortOrder;
      sortOrderTree[productCd] = sortOrder;
      dataObj = { viewName, sortOrder, productCd, productCdDisplay, problemsObj: {}, displayName: productCdDisplay };
      if (detail.reportCustomerComplaintsQuarterlyEntityList) {
        // for each of the products' problems, add it to an object map, the following quarters will have to follow this order
        detail.reportCustomerComplaintsQuarterlyEntityList.forEach(e => {
          const problemCdDisplay = ProblemsMap[e.problemCd];
          dataObj.problemsObj[e.problemCd] = {
            problemCd: e.problemCd,
            problemCdDisplay, // the display value
            displayName: problemCdDisplay, // the display value
          };
        });
      }
    }

    sortOrderList.push(dataObj);
  });

  // now, for the industry data.
  // similar process; sort latest quarter then make previous quarters follow that sort order by name
  const latestIndustryComplaintDetails = dataCopy[0].reportIndustryCustomerComplaintDetails;
  deepSort(latestIndustryComplaintDetails, 'indCmplntCt', true);

  // create the sort order
  const industrySortOrderTree = {};
  const industrySortOrderList = [];

  // for the UI, we only care about the top 10 from the first/most recent quarter
  latestIndustryComplaintDetails.slice(0, 10).forEach((industryDetail, index) => {
    const sortOrder = index + 1;
    let dataObj;

    if (viewName === 'Problem') {
      const problemCd = industryDetail.problemCd;
      const problemCdDisplay = ProblemsMap[problemCd]; // the display value
      (industryDetail as any).sortOrder = sortOrder;
      industrySortOrderTree[problemCd] = sortOrder;
      dataObj = { sortOrder, problemCd, problemCdDisplay, displayName: problemCdDisplay };
    } else {
      const productCd = industryDetail.productCd;
      const productCdDisplay = ProductsMap[productCd]; // the display value
      (industryDetail as any).sortOrder = sortOrder;
      industrySortOrderTree[productCd] = sortOrder;
      dataObj = { sortOrder, productCd, productCdDisplay, displayName: productCdDisplay };
    }

    industrySortOrderList.push(dataObj);
  });

  // now loop through the rest of the quarters.
  // assign a sort order then sort
  const dataLength = dataCopy.length;
  for (let i = 1; i < dataLength; i++) {
    // process the quarter's summary data
    const summaryObj = dataCopy[i].reportCustomerComplaintSummary;
    const summaryDetailsList = summaryObj.reportCustomerComplaintDetails;

    if (viewName === 'Problem') {
      summaryDetailsList.forEach((detail) => {
        (detail as any).sortOrder = sortOrderTree[detail.problemCd];
        // check if there are products
        if (detail.reportCustomerComplaintsQuarterlyEntityList) {
            detail.reportCustomerComplaintsQuarterlyEntityList.forEach(e => {
              // find the problem from the sort order list
              const dataObj = sortOrderList.find(s => s.problemCd === e.problemCd);
              // if there is a problem for this product, added it to the problem's product object map
              if (dataObj && !dataObj.productsObj[e.productCd]) {
                const productCdDisplay = ProductsMap[e.productCd];
                dataObj.productsObj[e.productCd] = {
                  productCd: e.productCd,
                  productCdDisplay,
                  displayName: productCdDisplay,
                };
              }
            });
          }
      });
    } else {
      summaryDetailsList.forEach((detail) => {
        (detail as any).sortOrder = sortOrderTree[detail.productCd];
        // check if there are problems
        if (detail.reportCustomerComplaintsQuarterlyEntityList) {
            detail.reportCustomerComplaintsQuarterlyEntityList.forEach(e => {
              // find the product from the sort order list
              const dataObj = sortOrderList.find(s => s.productCd === e.productCd);
              // if there is a product for this problem, added it to the product's problem object map
              if (dataObj && !dataObj.problemsObj[e.problemCd]) {
                const problemCdDisplay = ProblemsMap[e.problemCd];
                dataObj.problemsObj[e.problemCd] = {
                  problemCd: e.problemCd,
                  problemCdDisplay,
                  displayName: problemCdDisplay,
                };
              }
            });
          }
      });
    }

    // now sort it. all of the problems/products should align properly
    deepSort(summaryDetailsList, 'sortOrder', false);

    // process the quarter's industry data
    const industryComplaintDetails = dataCopy[i].reportIndustryCustomerComplaintDetails;
    if (viewName === 'Problem') {
      industryComplaintDetails.forEach((industryDetail) => {
        (industryDetail as any).sortOrder = industrySortOrderTree[industryDetail.problemCd];
      });
    } else {
      industryComplaintDetails.forEach((industryDetail) => {
        (industryDetail as any).sortOrder = industrySortOrderTree[industryDetail.productCd];
      });
    }

    // now sort the industry data
    deepSort(industryComplaintDetails, 'sortOrder', false);
  }

  // loop through each problem/product and aggregate/transform the data by:
  // quarter > detail > entity
  const len = sortOrderList.length;
  const quartersLen = priorQuartersList.length;
  for (let i = 0; i < len; i++) {
    const problemProductObj = sortOrderList[i];
    // loop through the 4 quarters starting from the latest
    for (let quarterIndex = 0; quarterIndex < quartersLen; quarterIndex++) {
      const quarterKey = `quarter${quarterIndex + 1}`;
      // maybe the response doesn't have 4 quarters in the list. check for that.
      const quarterData = dataCopy.find(
        quarter => quarter.reportCustomerComplaintSummary.period === priorQuartersList[quarterIndex]
      );

      // check if this quarter in the iteration matches any in the responseData.
      // if it doesn't, it must be a gap; no data for this quarter.
      // this could happen when there is 3 quarters in the response and there is a gap:
      // [{ period: '2018-10-01' }, { period: '2018-04-01' }, { period: '2018-01-01' }] // 3rd quarter missing
      if (!quarterData) {
        // create empty data for this quarter and move to next quarter iteration.
        totalsObj[quarterKey] = null;
        complaintsAsscIndv[quarterKey] = null;
        complaintsRepsRelated[quarterKey] = null;
        problemProductObj[quarterKey] = null;
        continue;
      }

      const summaryObj = quarterData.reportCustomerComplaintSummary;
      const industrySummaryObj = summaryObj.reportIndustryCustomerComplaintSummary;

      // while looping through all the quarters,
      // lets go ahead and extract the data for the other two tables, all other complaints and the totals rows
      totalsObj[quarterKey] = {
        grandTotalCount: summaryObj.cmplntCt,
        sales: {
          totalCount: summaryObj.slsPrctcCmplntCt,
          firmPercentage: summaryObj.slsPrctcCmplntPt,
          industryPercentage: industrySummaryObj && industrySummaryObj.indSlsPrctcCmplntPt,
        },
        operational: {
          totalCount: summaryObj.oprtnlCmplntCt,
          firmPercentage: summaryObj.oprtnlCmplntPt,
          industryPercentage: industrySummaryObj && industrySummaryObj.indOprtnlCmplntPt,
        },
      };

      complaintsAsscIndv[quarterKey] = {
        totalCount: summaryObj.associatesCt,
        firmPercentage: summaryObj.cmplntPerAssocPt,
        industryPercentage: industrySummaryObj && industrySummaryObj.indCmplntPerAssocPt,
      };

      complaintsRepsRelated[quarterKey] = {
        totalCount: summaryObj.cmplntRltd2RegRepCt,
        firmPercentage: summaryObj.cmplntRltd2RegRepPt,
        industryPercentage: industrySummaryObj && industrySummaryObj.indCmplntRltd2RegRepPt,
      };

      const summaryDetailsList = summaryObj.reportCustomerComplaintDetails;
      const summaryProductProblem = viewName === 'Problem'
        ? summaryDetailsList.find(detail => detail.problemCd === problemProductObj.problemCd)
        : summaryDetailsList.find(detail => detail.productCd === problemProductObj.productCd);

      // check if there is firm data for this quarter
      if (summaryProductProblem) {
        const totalCount = summaryProductProblem && summaryProductProblem.cmplntCt;
        const firmPercentage = summaryProductProblem && summaryProductProblem.cmplntPt;
        let industryPercentage;
        if (summaryProductProblem && summaryProductProblem.reportIndustryCustomerComplaintDetail) {
          industryPercentage = summaryProductProblem.reportIndustryCustomerComplaintDetail.indCmplntPt;
        }

        problemProductObj[quarterKey] = {
          totalCount,
          firmPercentage,
          industryPercentage,
        };
      } else {
        // looks like there is no firm data for this quarter.
        // check if there is industry data for this quarter.
        // if there is, set it on the product/problem.
        const industryDetailsList = quarterData.reportIndustryCustomerComplaintDetails;
        const industryDetail = viewName === 'Problem'
        ? industryDetailsList.find(detail => detail.problemCd === problemProductObj.problemCd)
        : industryDetailsList.find(detail => detail.productCd === problemProductObj.productCd);
        if (industryDetail) {
          problemProductObj[quarterKey] = {
            totalCount: 0,
            firmPercentage: 0,
            industryPercentage: industryDetail.indCmplntPt,
          };
        } else {
          // there is no firm OR industry data for this product/problem this quarter.
          problemProductObj[quarterKey] = null;
        }
      }

      if (viewName === 'Problem') {
        Object.keys(problemProductObj.productsObj).forEach(productName => {
          if (summaryProductProblem) {
            const entityList = summaryProductProblem.reportCustomerComplaintsQuarterlyEntityList;
            const summaryProduct = entityList.find(e => e.productCd === productName);
            if (summaryProduct) {
              problemProductObj.productsObj[productName][quarterKey] = summaryProduct.ccmplntCt;
            } else {
              problemProductObj.productsObj[productName][quarterKey] = null;
            }
          }
        });
      } else {
        Object.keys(problemProductObj.problemsObj).forEach(problemName => {
          if (summaryProductProblem) {
            const entityList = summaryProductProblem.reportCustomerComplaintsQuarterlyEntityList;
            const summaryProduct = entityList.find(e => e.problemCd === problemName);
            if (summaryProduct) {
              problemProductObj.problemsObj[problemName][quarterKey] = summaryProduct.ccmplntCt;
            } else {
              problemProductObj.problemsObj[problemName][quarterKey] = null;
            }
          }
        });
      }
    }
  }

  // if there is more than 5 problems/products, aggregate all other complaints
  if (sortOrderList.length > 5) {
    // the the aggregate data for all other complaints
    const allOtherComplaints = sortOrderList.slice(5);

    for (let quarterIndex = 0; quarterIndex < quartersLen; quarterIndex++) {
      const quarterKey = `quarter${quarterIndex + 1}`;
      const firstComplaint = allOtherComplaints[0];
      if (!firstComplaint[quarterKey]) {
        allOtherComplaintsObj[quarterKey] = null;
        continue;
      }

      const totalCount = allOtherComplaints.reduce((accumulator, current) => {
        const quarter = current[quarterKey];
        const newValue = accumulator + (quarter && quarter.totalCount || 0);
        return newValue;
      }, 0);
      const firmPercentage = allOtherComplaints.reduce((accumulator, current) => {
        const quarter = current[quarterKey];
        const newValue = accumulator + (quarter && quarter.firmPercentage || 0);
        return newValue;
      }, 0);
      const industryPercentage = allOtherComplaints.reduce((accumulator, current) => {
        const quarter = current[quarterKey];
        const newValue = accumulator + (quarter && quarter.industryPercentage || 0);
        return newValue;
      }, 0);

      allOtherComplaintsObj[quarterKey] = {
        totalCount,
        firmPercentage,
        industryPercentage,
      };
    }
  }

  // lastly, convert each productsObj to a list
  if (viewName === 'Problem') {
    for (let i = 0; i < len; i++) {
      const problemObj = sortOrderList[i];
      problemObj.products = [];
      Object.keys(problemObj.productsObj).forEach(productName => {
        problemObj.products.push(problemObj.productsObj[productName]);
      });
    }
  } else {
    for (let i = 0; i < len; i++) {
      const productObj = sortOrderList[i];
      productObj.problems = [];
      Object.keys(productObj.problemsObj).forEach(problemName => {
        productObj.problems.push(productObj.problemsObj[problemName]);
      });
    }
  }

  // aggregate/transform industry percentages by quarter
  const industrySortOrderLen = industrySortOrderList.length;
  if (viewName === 'Problem') {
    for (let i = 0; i < industrySortOrderLen; i++) {
      const industryProblemObj = industrySortOrderList[i];
      for (let quarterIndex = 0; quarterIndex < quartersLen; quarterIndex++) {
        const quarterKey = `quarter${quarterIndex + 1}`;
        const quarterData = dataCopy.find(quarter => {
          const firstDetail = quarter.reportIndustryCustomerComplaintDetails[0];
          return firstDetail && firstDetail.period === priorQuartersList[quarterIndex];
        });
        if (!quarterData) {
          industryProblemObj[quarterKey] = null;
          continue;
        }
        const industryComplaintDetails = quarterData.reportIndustryCustomerComplaintDetails;
        const industryDetail = industryComplaintDetails.find(detail => detail.problemCd === industryProblemObj.problemCd);
        industryProblemObj[quarterKey] = industryDetail;
      }
    }
  } else {
    for (let i = 0; i < industrySortOrderLen; i++) {
      const industryProductObj = industrySortOrderList[i];
      for (let quarterIndex = 0; quarterIndex < quartersLen; quarterIndex++) {
        const quarterKey = `quarter${quarterIndex + 1}`;
        const quarterData = dataCopy.find(quarter => {
          const firstDetail = quarter.reportIndustryCustomerComplaintDetails[0];
          return firstDetail && firstDetail.period === priorQuartersList[quarterIndex];
        });
        if (!quarterData) {
          industryProductObj[quarterKey] = null;
          continue;
        }
        const industryComplaintDetails = quarterData.reportIndustryCustomerComplaintDetails;
        const industryDetail = industryComplaintDetails.find(detail => detail.productCd === industryProductObj.productCd);
        industryProductObj[quarterKey] = industryDetail;
      }
    }
  }

  const results = {
    headings,
    rowData: sortOrderList,
    topTenIndustryComplaintsRowData: industrySortOrderList,
    complaintsAsscIndv,
    complaintsRepsRelated,
    totalsObj,
    allOtherComplaintsObj,
  };

  return results;
}

/**
 * Summary Export Logic
 */

// export complaints table
export function summaryComplaintsExport(
  viewName,
  // the below should be the same thing that was given from the above transformSummaryDataResponse function.
  rowData,
  totalsObj,
  headings,
) {
  const generateRowDataCsv = (data) => {
    const dataStringList = [];

    const groupStringData = buildRowDataString({
      rowData: [data],
      dataMappings: [
        'displayName',
        '',
        'quarter1.totalCount',
        'quarter1.firmPercentage',
        'quarter1.industryPercentage',
        'quarter2.totalCount',
        'quarter2.firmPercentage',
        'quarter2.industryPercentage',
        'quarter3.totalCount',
        'quarter3.firmPercentage',
        'quarter3.industryPercentage',
        'quarter4.totalCount',
        'quarter4.firmPercentage',
        'quarter4.industryPercentage',
      ],
    });

    const list = data.problems || data.products;
    const detailStringData = buildRowDataString({
      rowData: list,
      dataMappings: [
        '',
        'displayName',
        'quarter1',
        '',
        '',
        'quarter2',
        '',
        '',
        'quarter3',
        '',
        '',
        'quarter4',
        '',
        '',
      ],
    });

    dataStringList.push(groupStringData);
    dataStringList.push(detailStringData);

    const dataCsvString = dataStringList.join('');
    return dataCsvString;
  };

  const massDataStringList = [`Complaints by ${viewName}\n`];

  const priorMonthHeadingsRow = buildColumnLabelsString([
    [
      `${viewName}s Reported`,
      '',
      headings[0],
      '',
      '',
      headings[1],
      '',
      '',
      headings[2],
      '',
      '',
      headings[3],
      '',
      '',
    ],
    [
      '',
      '',
      'Count',
      'Firm %',
      'Industry %',
      'Count',
      'Firm %',
      'Industry %',
      'Count',
      'Firm %',
      'Industry %',
      'Count',
      'Firm %',
      'Industry %',
    ],
  ]);

  massDataStringList.push(priorMonthHeadingsRow);

  for (const dataObj of rowData) {
    const dataString = generateRowDataCsv(dataObj);
    massDataStringList.push(dataString);
  }

  // build totals rows
  if (viewName === 'Problem') {
    // build the sales/operational totals rows
    const totalSalesStringData = buildRowDataString({
      rowData: [totalsObj],
      dataMappings: [
        { value: 'Total - Sales Practice' },
        '',
        'quarter1.sales.totalCount',
        'quarter1.sales.firmPercentage',
        'quarter1.sales.industryPercentage',
        'quarter2.sales.totalCount',
        'quarter2.sales.firmPercentage',
        'quarter2.sales.industryPercentage',
        'quarter3.sales.totalCount',
        'quarter3.sales.firmPercentage',
        'quarter3.sales.industryPercentage',
        'quarter4.sales.totalCount',
        'quarter4.sales.firmPercentage',
        'quarter4.sales.industryPercentage',
      ],
    });

    const totalOperationalStringData = buildRowDataString({
      rowData: [totalsObj],
      dataMappings: [
        { value: 'Total - Operational' },
        '',
        'quarter1.operational.totalCount',
        'quarter1.operational.firmPercentage',
        'quarter1.operational.industryPercentage',
        'quarter2.operational.totalCount',
        'quarter2.operational.firmPercentage',
        'quarter2.operational.industryPercentage',
        'quarter3.operational.totalCount',
        'quarter3.operational.firmPercentage',
        'quarter3.operational.industryPercentage',
        'quarter4.operational.totalCount',
        'quarter4.operational.firmPercentage',
        'quarter4.operational.industryPercentage',
      ],
    });

    massDataStringList.push(totalSalesStringData);
    massDataStringList.push(totalOperationalStringData);
  }

  const grandTotalStringData = buildRowDataString({
    rowData: [totalsObj],
    dataMappings: [
      { value: 'Grand Total' },
      '',
      'quarter1.grandTotalCount',
    ],
  });

  massDataStringList.push(grandTotalStringData);

  const massCsvDataString = massDataStringList.join('');
  return massCsvDataString;
}



// export industry table
export function summaryIndustryExport(
  viewName,
  // the below should be the same thing that was given from the above transformSummaryDataResponse function.
  industryRowData,
  headings,
) {
  const exportData: SummaryExportData[] = [
    {
      title: `Industry Percentages for the Top Ten Complaints by ${viewName}`,
      rowData: industryRowData,
      columnLabels: [
        `${viewName}s Reported`,
        headings[0],
        headings[1],
        headings[2],
        headings[3],
      ],
      dataMappings: [
        'displayName',
        'quarter1.indCmplntPt',
        'quarter2.indCmplntPt',
        'quarter3.indCmplntPt',
        'quarter4.indCmplntPt',
      ],
    }
  ];

  const exportString = buildExportDataString(exportData) + '\n';
  return exportString;
}

// export industry table
export function summaryAssociatedIndividualExport(
  // the below should be the same thing that was given from the above transformSummaryDataResponse function.
  data,
  headings,
) {
  const exportData: SummaryExportData[] = [
    {
      title: `Complaints per Associated Individual`,
      rowData: [data],
      columnLabels: [
        [
          '',
          headings[0],
          '',
          '',
          headings[1],
          '',
          '',
          headings[2],
          '',
          '',
          headings[3],
          '',
          '',
        ],
        [
          '',
          'Associated Individual Count',
          'Firm',
          'Industry',
          'Associated Individual Count',
          'Firm',
          'Industry',
          'Associated Individual Count',
          'Firm',
          'Industry',
          'Associated Individual Count',
          'Firm',
          'Industry',
        ],
      ],
      dataMappings: [
        '',
        'quarter1.totalCount',
        'quarter1.firmPercentage',
        'quarter1.industryPercentage',
        'quarter2.totalCount',
        'quarter2.firmPercentage',
        'quarter2.industryPercentage',
        'quarter3.totalCount',
        'quarter3.firmPercentage',
        'quarter3.industryPercentage',
        'quarter4.totalCount',
        'quarter4.firmPercentage',
        'quarter4.industryPercentage',
      ],
    }
  ];

  const exportString = buildExportDataString(exportData) + '\n';
  return exportString;
}

// export industry table
export function summaryRepsRelatedExport(
  // the below should be the same thing that was given from the above transformSummaryDataResponse function.
  data,
  headings,
) {
  const exportData: SummaryExportData[] = [
    {
      title: `Complaints Identified as Related to Representative`,
      rowData: [data],
      columnLabels: [
        [
          '',
          headings[0],
          '',
          '',
          headings[1],
          '',
          '',
          headings[2],
          '',
          '',
          headings[3],
          '',
          '',
        ],
        [
          '',
          'Complaint Count',
          'Firm',
          'Industry',
          'Complaint Count',
          'Firm',
          'Industry',
          'Complaint Count',
          'Firm',
          'Industry',
          'Complaint Count',
          'Firm',
          'Industry',
        ],
      ],
      dataMappings: [
        '',
        'quarter1.totalCount',
        'quarter1.firmPercentage',
        'quarter1.industryPercentage',
        'quarter2.totalCount',
        'quarter2.firmPercentage',
        'quarter2.industryPercentage',
        'quarter3.totalCount',
        'quarter3.firmPercentage',
        'quarter3.industryPercentage',
        'quarter4.totalCount',
        'quarter4.firmPercentage',
        'quarter4.industryPercentage',
      ],
    }
  ];

  const exportString = buildExportDataString(exportData);
  return exportString;
}

export function customerComplaintSummaryExportData(
  data: CustomerComplaintTransformedResponse,
  viewName: string,
) {
  const complaintsCsvDataString = summaryComplaintsExport(
    viewName,
    data.rowData,
    data.totalsObj,
    data.headings
  );
  const industryCsvDataString = summaryIndustryExport(
    viewName,
    data.topTenIndustryComplaintsRowData,
    data.headings
  );
  const associatedIndividualsCsvDataString = summaryAssociatedIndividualExport(
    data.complaintsAsscIndv,
    data.headings
  );
  const repsRelatedCsvDataString = summaryRepsRelatedExport(
    data.complaintsRepsRelated,
    data.headings
  );

  const csvString = [
    complaintsCsvDataString,
    industryCsvDataString,
    associatedIndividualsCsvDataString,
    repsRelatedCsvDataString,
  ].join('\n');

  const csvFormatted = trimTrailingChar(csvString, '\n');
  return csvFormatted;
}
