import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Filters {
  categoryId: string;
  firmId: string;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterStoreService {
  private currentFilters: Filters = {
    categoryId: '',
    firmId: '',
    state: 'Published'
  };
  private filtersValid: boolean = false;
  private filtersChanges = new BehaviorSubject<{ isValid: boolean; filters: Filters }>(null);

  constructor() {}

  getFilters() {
    return { ...this.currentFilters };
  }

  setFilters(categoryId: string, firmId: string, state: string) {
    this.currentFilters.categoryId = categoryId;
    this.currentFilters.firmId = firmId;
    this.currentFilters.state = state;
    const isValid: boolean = (!!categoryId && !!firmId && !!state);
    this.filtersValid = isValid;

    // let listeners know that filters changed
    this.filtersChanges.next({
      isValid: this.filtersValid,
      filters: this.getFilters()
    });
  }

  getCategoryId() {
    return this.currentFilters.categoryId;
  }

  setCategoryId(categoryId: string) {
    this.currentFilters.categoryId = categoryId;
  }

  getFirmId() {
    return this.currentFilters.firmId;
  }

  setFirmId(firmId: string) {
    this.currentFilters.firmId = firmId;
  }

  getState() {
    return this.currentFilters.state;
  }

  setState(state: string) {
    this.currentFilters.state = state;
  }
}
