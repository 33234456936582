import {
  addFinraGridColumnId,
  buildColumnLabelsString,
  SummaryExportData,
  buildExportDataString,
  sort_distinct,
  buildRowDataString,
  roundUptoTwoDecimal,
  trimTrailingChar
} from 'src/app/shared/utils/utils';
import {
  finraGridQuarterDateFormatter,
  finraGridPercentValueFormatter,
  finraGridNumericValueFormatter,
  aggCellRenderer,
  customSumAggFunc,
  customCellClassFunc,
  submissionTotalPercentCellRenderer
} from 'src/app/shared/utils/utils';

export enum CorpFinReportTypeIDs {
  LATE_FILING = 202,
  PRIVATE_PLACEMENT = 201,
  RULE_5110 = 200,
}

export enum CorpFinReportShortNames {
  LATE_FILING = 'corpfinlatefilings',
  PRIVATE_PLACEMENT = 'corpfinppftr',
  RULE_5110 = 'corpfinpublicoffer',
}

export const CorpFinQuarterlyReportsByTypeId = [
  CorpFinReportTypeIDs.PRIVATE_PLACEMENT
];

export const CorpFinShowDetailsByReportTypeAndEdition = {
  [CorpFinReportShortNames.LATE_FILING]: {
    2: true,
  }
};

export const CorpFinAddPeriodAsAdditionalDetailsInfoByReportTypeAndEdition = {
  [CorpFinReportShortNames.LATE_FILING]: {
    2: true,
  }
};

/** Interfaces */

// Base Interface
export interface Rule5110SummaryData {
  reportDate: string;
  firmCrdNumber: number;
  firmCrdName: string;
}

export interface CurrentPeriodSubmissionSummaryData extends Rule5110SummaryData {
  filingPercent: number;
  industryFilingPercent: number;
  dealFilingPercent: number;
  filingCount: number;
  dealDescription: string;
  dealFilingCount: number;
  currentReviewProgramDescription: string;
}

export interface PriorPeriodSubmissionSummaryData extends Rule5110SummaryData {
  filingPercent: number;
  dealOver6MonthsFromInitialFilingCount: number;
  over6MonthsFromInitialFilingCount: number;
  over6MonthsFromInitialFilingPercent: number;
  dealFilingPercent: number;
  dealOver6MonthsFromInitialFilingPercent: number;
  industryWithin6MonthsFromInitialFilingPercent: number;
  dealWithin6MonthsFromInitialFilingCount: number;
  within6MonthsFromInitialFilingPercent: number;
  dealFilingCount: number;
  currentReviewProgramDescription: string;
  industryOver6MonthsFromInitialFilingPercent: number;
  dealWithin6MonthsFromInitialFilingPercent: number;
  filingCount: number;
  within6MonthsFromInitialFilingCount: number;
  dealDescription: string;
}

export interface CurrentPeriodClearanceSummaryData extends Rule5110SummaryData {
  clearedBetween1And6MonthsPercent: number;
  clearedOver6MonthsCount: number;
  dealClearedBetween1And6MonthsCount: number;
  dealClearedWithin1MonthPercent: number;
  clearedBetween1And6MonthsCount: number;
  dealFilingCount: number;
  currentReviewProgramDescription: string;
  clearedWithin1MonthCount: number;
  dealClearedOver6MonthsCount: number;
  clearedWithin1MonthPercent: number;
  dealClearedBetween1And6MonthsPercent: number;
  clearedOver6MonthsPercent: number;
  dealClearedWithin1MonthCount: number;
  filingCount: number;
  dealDescription: string;
  dealClearedOver6MonthsPercent: number;
}

export interface CommentLetterSummaryData extends Rule5110SummaryData {
  over6MonthsFromInitialFilingCount: number;
  over6MonthsFromInitialFilingPercent: number;
  industryWithin6MonthsFromInitialFilingPercent: number;
  currentPeriodPercent: number;
  within6MonthsFromInitialFilingPercent: number;
  industryOver6MonthsFromInitialFilingPercent: number;
  industryCurrentPeriodPercent: number;
  currentPeriodCount: number;
  letterTypeCode: string;
  within6MonthsFromInitialFilingCount: number;
}

export interface DistributionMethodSummaryData extends Rule5110SummaryData {
  distributionMethodDescription: string;
  over6MonthsFromInitialFilingCount: number;
  over6MonthsFromInitialFilingPercent: number;
  industryWithin6MonthsFromInitialFilingPercent: number;
  currentPeriodPercent: number;
  within6MonthsFromInitialFilingPercent: number;
  industryOver6MonthsFromInitialFilingPercent: number;
  industryCurrentPeriodPercent: number;
  currentPeriodCount: number;
  within6MonthsFromInitialFilingCount: number;
}


/** Constants */

export const DEALS_CHART_VALUES = {
  'Limited Review': { count: 0, percent: 0 },
  'Full Review': { count: 0, percent: 0 },
  'Expedited Review': { count: 0, percent: 0 },
  Shelf: { count: 0, percent: 0 }
};

export const DEALS_SORT_KEY = 'currentReviewProgramDescription';
export const DEALS_SORT_ORDER = {
  'Limited Review': 0,
  'Full Review': 1,
  'Expedited Review': 2,
  Shelf: 3,
  Total: 4
};

export const COMMENT_SORT_KEY = 'letterTypeCode';
export const COMMENT_SORT_ORDER = {
  Unreasonable: 0,
  Defer: 1,
  'Additional Comments': 2
};

export const DISTRIBUTION_SORT_KEY = 'distributionMethodDescription';
export const DISTRIBUTION_SORT_ORDER = {
  'Firm Commitment': 0,
  'Best Efforts': 1,
  'Best Efforts with Contingency': 2
};

export const REPORT_IDENTIFIERS = {
  PUB_OFFER: 'corpfinpublicoffer',
  PRV_PLC_FLG_TM: 'corpfinppftr',
  LATE_FILING: 'corpfinlatefilings'
};

export const DEFAULT_TABLE_VIEWS = {
  corpfinpublicoffer: 'chart',
  corpfinppftr: 'table'
};

export const VIEW_NAMES = {
  DEALS_FILED_CURRENT: 'firmCorpFin5110ReviewCurrentPeriodSubmissionSummary',
  DEALS_FILED_PRIOR: 'firmCorpFin5110ReviewPriorPeriodSubmissionSummary',
  DEALS_CLEARED_CURRENT: 'firmCorpFin5110ReviewCurrentPeriodClearanceSummary',
  COMMENT_LETTERS: 'firmCorpFin5110ReviewCommentLetterSummary',
  DISTRIBUTION_METHOD: 'firmCorpFin5110ReviewDistributionMethodSummary'
};

function baseSort(a, b) {
  const order = DEALS_SORT_ORDER;

  a = order[a[DEALS_SORT_KEY]];
  b = order[b[DEALS_SORT_KEY]];

  if (a === b) {
    return 0;
  }

  return a < b ? -1 : 1;
}

export const PUB_OFFER_HELP_LINKS = {
  firmCorpFin5110ReviewCurrentPeriodSubmissionSummary:
    'https://tools.finra.org/reportcenterhelp/#Corporate_Financing_Public_Offering_Late_Filings_Report.htm#Public_Offering_Rule_5110_Filing_Summary_-_Deals_Filed_During_the_Selected_Period',
  firmCorpFin5110ReviewPriorPeriodSubmissionSummary:
    'https://tools.finra.org/reportcenterhelp/#Corporate_Financing_Public_Offering_Late_Filings_Report.htm#Public_Offering_Rule_5110_Filing_Summary_-_Deals_Filed_Prior_to_the_Selected_Period',
  firmCorpFin5110ReviewCurrentPeriodClearanceSummary:
    'https://tools.finra.org/reportcenterhelp/#Corporate_Financing_Public_Offering_Late_Filings_Report.htm#Public_Offering_Rule_5110_Filing_Summary_-_Deals_Cleared_During_the_Selected_Period',
  firmCorpFin5110ReviewCommentLetterSummary:
    'https://tools.finra.org/reportcenterhelp/#Corporate_Financing_Public_Offering_Late_Filings_Report.htm#Public_Offering_Rule_5110_Filing_Summary_-_Comment_Letters_Issued',
  firmCorpFin5110ReviewDistributionMethodSummary:
    'https://tools.finra.org/reportcenterhelp/#Corporate_Financing_Public_Offering_Late_Filings_Report.htm#Public_Offering_Rule_5110_Filing_Summary_-_Distribution_Method'
};

export const VIEW_NAMES_SORT_FUNCTIONS = {
  firmCorpFin5110ReviewCurrentPeriodSubmissionSummary: baseSort,
  firmCorpFin5110ReviewPriorPeriodSubmissionSummary: baseSort,
  firmCorpFin5110ReviewCurrentPeriodClearanceSummary: baseSort,
  firmCorpFin5110ReviewCommentLetterSummary: function(a, b) {
    const order = COMMENT_SORT_ORDER;

    a = order[a[COMMENT_SORT_KEY]];
    b = order[b[COMMENT_SORT_KEY]];

    if (a === b) {
      return 0;
    }

    return a < b ? -1 : 1;
  },
  firmCorpFin5110ReviewDistributionMethodSummary: function(a, b) {
    const order = DISTRIBUTION_SORT_ORDER;

    a = order[a[DISTRIBUTION_SORT_KEY]];
    b = order[b[DISTRIBUTION_SORT_KEY]];

    if (a === b) {
      return 0;
    }

    return a < b ? -1 : 1;
  }
};

export const PUB_OFFER_VIEW_ORDER = [
  VIEW_NAMES.DEALS_FILED_CURRENT,
  VIEW_NAMES.DEALS_FILED_PRIOR,
  VIEW_NAMES.DEALS_CLEARED_CURRENT,
  VIEW_NAMES.COMMENT_LETTERS,
  VIEW_NAMES.DISTRIBUTION_METHOD
];

export const LATE_FILING_VIEW_ORDER = [
  'submissionTotals',
  'lateFilingAnalysis',
  'latenessMagnitude'
];

export const LATE_FILING_VIEW_ORDER_BY_REPORT_TYPE_AND_EDITION = {
  1: [
    'submissionTotals',
    'lateFilingAnalysis',
    'latenessMagnitude'
  ],
  2: [
    'submissionTotals',
    'latenessMagnitude'
  ],
};

export const LATE_FILING_TITLES = {
  lateFilingAnalysis: 'Late Filing Analysis',
  latenessMagnitude: 'Lateness Magnitude',
  submissionTotals: 'Submission Totals'
};

export const PUBLIC_FILING_TITLES = {
  firmCorpFin5110ReviewCurrentPeriodSubmissionSummary:
    'Deals Filed During the Selected Period',
  firmCorpFin5110ReviewPriorPeriodSubmissionSummary:
    'Deals Filed Prior to the Selected Period',
  firmCorpFin5110ReviewCurrentPeriodClearanceSummary:
    'Deals Cleared During the Selected Period',
  firmCorpFin5110ReviewCommentLetterSummary: 'Comment Letters Issued',
  firmCorpFin5110ReviewDistributionMethodSummary: 'Distribution Method'
};

export const CURRENT_SUBMISSION_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: '',
    hide: true,
    field: 'currentReviewProgramDescription',
    rowGroup: true,
    valueFormatter: params => {
      return params.value === 'Shelf' ? `${params.value} Review` : params.value;
    }
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: '',
        field: 'dealFilingCount',
        aggFunc: 'sum',
        headerClass: 'lines',
        cellClass: customCellClassFunc,
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: aggCellRenderer,
        minWidth: 215,
        maxWidth: 215
      },
      {
        headerName: '(%)',
        field: 'filingPercent',
        aggFunc: 'sum',
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: aggCellRenderer,
        minWidth: 215,
        maxWidth: 215
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: '(%)',
        field: 'industryFilingPercent',
        aggFunc: 'sum',
        headerClass: 'lines',
        cellClass: customCellClassFunc,
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: aggCellRenderer
      }
    ]
  }
]);

export const PRIOR_SUBMISSION_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: '',
    hide: true,
    field: 'currentReviewProgramDescription',
    rowGroup: true,
    valueFormatter: params => {
      return params.value === 'Shelf' ? `${params.value} Review` : params.value;
    }
  },
  {
    headerName: 'Total Filed',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'dealFilingCount',
            headerClass: 'lines',
            cellClass: customCellClassFunc,
            aggFunc: 'sum',
            valueFormatter: finraGridNumericValueFormatter,
            cellRenderer: aggCellRenderer,
            minWidth: 100,
            maxWidth: 100
          },
          {
            headerName: 'Firm(%)',
            field: 'filingPercent',
            aggFunc: 'sum',
            valueFormatter: finraGridPercentValueFormatter,
            cellRenderer: aggCellRenderer,
            minWidth: 100,
            maxWidth: 100
          }
        ]
      }
    ]
  },
  {
    headerName: 'Aging Since Initial Filing',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: '< 6 Months Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'dealWithin6MonthsFromInitialFilingCount',
            aggFunc: 'sum',
            headerClass: 'lines',
            cellClass: customCellClassFunc,
            valueFormatter: finraGridNumericValueFormatter,
            cellRenderer: aggCellRenderer
          },
          {
            headerName: 'Firm(%)',
            field: 'within6MonthsFromInitialFilingPercent',
            aggFunc: 'sum',
            valueFormatter: finraGridPercentValueFormatter,
            cellRenderer: aggCellRenderer
          },
          {
            headerName: 'Industry(%)',
            field: 'industryWithin6MonthsFromInitialFilingPercent',
            aggFunc: 'sum',
            valueFormatter: finraGridPercentValueFormatter,
            cellRenderer: aggCellRenderer
          }
        ]
      },
      {
        headerName: '≥ 6 Months Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'dealOver6MonthsFromInitialFilingCount',
            aggFunc: 'sum',
            headerClass: 'lines',
            cellClass: customCellClassFunc,
            valueFormatter: finraGridNumericValueFormatter,
            cellRenderer: aggCellRenderer
          },
          {
            headerName: 'Firm(%)',
            field: 'over6MonthsFromInitialFilingPercent',
            aggFunc: 'sum',
            valueFormatter: finraGridPercentValueFormatter,
            cellRenderer: aggCellRenderer
          },
          {
            headerName: 'Industry(%)',
            field: 'industryOver6MonthsFromInitialFilingPercent',
            aggFunc: 'sum',
            valueFormatter: finraGridPercentValueFormatter,
            cellRenderer: aggCellRenderer
          }
        ]
      }
    ]
  }
]);

export const CURRENT_CLEARENCE_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: '',
    hide: true,
    field: 'currentReviewProgramDescription',
    rowGroup: true,
    valueFormatter: params => {
      return params.value === 'Shelf' ? `${params.value} Review` : params.value;
    }
  },
  {
    headerName: 'Total Cleared',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'dealFilingCount',
            aggFunc: 'sum',
            headerClass: 'lines',
            cellClass: customCellClassFunc,
            valueFormatter: finraGridNumericValueFormatter,
            cellRenderer: aggCellRenderer
          }
        ]
      }
    ]
  },
  {
    headerName: 'Time to Clearance from Initial Filing',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: '< 1 Month Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'dealClearedWithin1MonthCount',
            aggFunc: 'sum',
            headerClass: 'lines',
            cellClass: customCellClassFunc,
            valueFormatter: finraGridNumericValueFormatter,
            cellRenderer: aggCellRenderer
          },
          {
            headerName: '(%)',
            field: 'clearedWithin1MonthPercent',
            aggFunc: 'sum',
            valueFormatter: finraGridPercentValueFormatter,
            cellRenderer: aggCellRenderer
          }
        ]
      },
      {
        headerName: '> 1 and < 6 Months Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'dealClearedBetween1And6MonthsCount',
            aggFunc: 'sum',
            headerClass: 'lines',
            cellClass: customCellClassFunc,
            valueFormatter: finraGridNumericValueFormatter,
            cellRenderer: aggCellRenderer
          },
          {
            headerName: '(%)',
            field: 'clearedBetween1And6MonthsPercent',
            aggFunc: 'sum',
            valueFormatter: finraGridPercentValueFormatter,
            cellRenderer: aggCellRenderer
          }
        ]
      },
      {
        headerName: '≥ 6 Months Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'dealClearedOver6MonthsCount',
            aggFunc: 'sum',
            headerClass: 'lines',
            cellClass: customCellClassFunc,
            valueFormatter: finraGridNumericValueFormatter,
            cellRenderer: aggCellRenderer
          },
          {
            headerName: '(%)',
            field: 'clearedOver6MonthsPercent',
            aggFunc: 'sum',
            valueFormatter: finraGridPercentValueFormatter,
            cellRenderer: aggCellRenderer
          }
        ]
      }
    ]
  }
]);

export const REVIEW_COMMENT_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Type of Comment Letter',
    field: 'letterTypeCode',
    minWidth: 250,
    maxWidth: 250
  },
  {
    headerName: 'Selected Reporting Period',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'currentPeriodCount',
            headerClass: 'lines',
            cellClass: 'lines',
            valueFormatter: finraGridNumericValueFormatter
          },
          {
            headerName: 'Firm(%)',
            field: 'currentPeriodPercent',
            valueFormatter: finraGridPercentValueFormatter
          },
          {
            headerName: 'Industry(%)',
            field: 'industryCurrentPeriodPercent',
            valueFormatter: finraGridPercentValueFormatter
          }
        ]
      }
    ]
  },
  {
    headerName: 'Prior Reporting Periods',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: '> 1 and < 6 Months Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'within6MonthsFromInitialFilingCount',
            headerClass: 'lines',
            cellClass: 'lines',
            valueFormatter: finraGridNumericValueFormatter
          },
          {
            headerName: 'Firm(%)',
            field: 'within6MonthsFromInitialFilingPercent',
            valueFormatter: finraGridPercentValueFormatter
          },
          {
            headerName: 'Industry(%)',
            field: 'industryWithin6MonthsFromInitialFilingPercent',
            valueFormatter: finraGridPercentValueFormatter
          }
        ]
      },
      {
        headerName: '≥ 6 Months Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'over6MonthsFromInitialFilingCount',
            headerClass: 'lines',
            cellClass: 'lines',
            valueFormatter: finraGridNumericValueFormatter
          },
          {
            headerName: 'Firm(%)',
            field: 'over6MonthsFromInitialFilingPercent',
            valueFormatter: finraGridPercentValueFormatter
          },
          {
            headerName: 'Industry(%)',
            field: 'industryOver6MonthsFromInitialFilingPercent',
            valueFormatter: finraGridPercentValueFormatter
          }
        ]
      }
    ]
  }
]);

export const DIST_METHOD_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Distribution Method',
    field: 'distributionMethodDescription',
    minWidth: 250,
    maxWidth: 250
  },
  {
    headerName: 'Selected Reporting Period',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'currentPeriodCount',
            headerClass: 'lines',
            cellClass: 'lines',
            valueFormatter: finraGridNumericValueFormatter
          },
          {
            headerName: 'Firm(%)',
            field: 'currentPeriodPercent',
            valueFormatter: finraGridPercentValueFormatter
          },
          {
            headerName: 'Industry(%)',
            field: 'industryCurrentPeriodPercent',
            valueFormatter: finraGridPercentValueFormatter
          }
        ]
      }
    ]
  },
  {
    headerName: 'Prior Reporting Periods',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: '> 1 and < 6 Months Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'within6MonthsFromInitialFilingCount',
            headerClass: 'lines',
            cellClass: 'lines',
            valueFormatter: finraGridNumericValueFormatter
          },
          {
            headerName: 'Firm(%)',
            field: 'within6MonthsFromInitialFilingPercent',
            valueFormatter: finraGridPercentValueFormatter
          },
          {
            headerName: 'Industry(%)',
            field: 'industryWithin6MonthsFromInitialFilingPercent',
            valueFormatter: finraGridPercentValueFormatter
          }
        ]
      },
      {
        headerName: '≥ 6 Months Old',
        headerClass: 'lines',
        cellClass: 'lines',
        children: [
          {
            headerName: '',
            field: 'over6MonthsFromInitialFilingCount',
            headerClass: 'lines',
            cellClass: 'lines',
            valueFormatter: finraGridNumericValueFormatter
          },
          {
            headerName: 'Firm(%)',
            field: 'over6MonthsFromInitialFilingPercent',
            valueFormatter: finraGridPercentValueFormatter
          },
          {
            headerName: 'Industry(%)',
            field: 'industryOver6MonthsFromInitialFilingPercent',
            valueFormatter: finraGridPercentValueFormatter
          }
        ]
      }
    ]
  }
]);

export const PUBLIC_OFFER_AUTO_GROUP_COLUMN_DEFS = {
  firmCorpFin5110ReviewCurrentPeriodSubmissionSummary: {
    headerName: 'FINRA Review Program',
    field: 'dealDescription',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true
    },
    // NOTE: using width does not seem to work.
    minWidth: 250,
    maxWidth: 250
  },
  firmCorpFin5110ReviewPriorPeriodSubmissionSummary: {
    headerName: 'FINRA Review Program',
    field: 'dealDescription',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true
    },
    minWidth: 250,
    maxWidth: 250
  },
  firmCorpFin5110ReviewCurrentPeriodClearanceSummary: {
    headerName: 'FINRA Review Program',
    field: 'dealDescription',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true
    },
    minWidth: 250,
    maxWidth: 250
  }
};

export const PUBLIC_OFFER_COLUMN_DEFS = {
  firmCorpFin5110ReviewCurrentPeriodSubmissionSummary: CURRENT_SUBMISSION_COLUMN_DEFS,
  firmCorpFin5110ReviewPriorPeriodSubmissionSummary: PRIOR_SUBMISSION_COLUMN_DEFS,
  firmCorpFin5110ReviewCurrentPeriodClearanceSummary: CURRENT_CLEARENCE_COLUMN_DEFS,
  firmCorpFin5110ReviewCommentLetterSummary: REVIEW_COMMENT_COLUMN_DEFS,
  firmCorpFin5110ReviewDistributionMethodSummary: DIST_METHOD_COLUMN_DEFS
};

export const SUBMISSION_TOTAL_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: '',
    field: 'monthStartDate',
    rowGroup: true,
    hide: true
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Total Filings Count',
        field: 'filerTotalFilingCount',
        headerClass: 'lines',
        cellClass: 'lines',
        aggFunc: 'sum',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Late Filings Count',
        field: 'filerLateFilingCount',
        aggFunc: 'sum',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Late Filing Percentage',
        field: 'firmLateFilingPercent',
        aggFunc: 'sum',
        cellRenderer: submissionTotalPercentCellRenderer
      },
      {
        headerName: 'Rank',
        field: 'rankNumber',
        aggFunc: customSumAggFunc,
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: aggCellRenderer
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Firms',
        field: 'industryTotalFirmCount',
        headerClass: 'lines',
        cellClass: 'lines',
        aggFunc: customSumAggFunc,
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: aggCellRenderer
      },
      {
        headerName: 'Total Filings Count',
        field: 'industryTotalFilingCount',
        aggFunc: customSumAggFunc,
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: aggCellRenderer
      },
      {
        headerName: 'Late Filings Count',
        field: 'industryLateFilingCount',
        aggFunc: customSumAggFunc,
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: aggCellRenderer
      },
      {
        headerName: 'Late Filing Percentage',
        field: 'industryLateFilingPercent',
        aggFunc: customSumAggFunc,
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: aggCellRenderer
      }
    ]
  }
]);

export const FILING_ANALYSIS_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Month',
    field: 'monthStartDate',
    minWidth: 320,
    maxWidth: 320
  },
  {
    headerName: 'Late Initial Filings',
    field: 'lateInitialFilingCount',
    headerClass: 'lines',
    cellClass: 'lines',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Late Amended Filings',
    field: 'lateAmendedFilingCount',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Late Filings',
    field: 'monthStartDate',
    isDetailLink: true
  }
]);

export const LATENESS_MAGNITUDE_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Month',
    field: 'monthStartDate',
    minWidth: 320,
    maxWidth: 320
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: '1-3 days',
        field: 'late1To3DayCount',
        headerClass: 'lines',
        cellClass: 'lines',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '4-10 days',
        field: 'late4To10DayCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '11-30 days',
        field: 'late11To30DayCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '31-59 days',
        field: 'late31To59DayCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '>59 days',
        field: 'lateGreaterThan59DayCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Average Lateness',
        field: 'averageLatenessNumber',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Median Lateness',
        field: 'medianLatenessNumber',
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Industry Average Lateness',
        field: 'industryAverageLatenessNumber',
        headerClass: 'lines',
        cellClass: 'lines',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Industry Median Lateness',
        field: 'industryMedianLatenessNumber',
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  }
]);

export const LATE_FILING_COLUMN_DEFS = {
  lateFilingAnalysis: FILING_ANALYSIS_COLUMN_DEFS,
  latenessMagnitude: LATENESS_MAGNITUDE_COLUMN_DEFS,
  submissionTotals: SUBMISSION_TOTAL_COLUMN_DEFS
};

export const LATE_FILING_AUTO_GROUP_COL_DEFS = {
  submissionTotals: {
    headerName: 'Month',
    field: 'filerFirmName',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true
    },
    minWidth: 320,
    maxWidth: 320
  }
};

export const CORP_FIN_HEADER_TEXT = {
  // tslint:disable-next-line:max-line-length
  corpfinpublicoffer: `This report provides a summary of the review activity for the filings submitted during the selected and previous reporting periods. \n\n If you have questions regarding the content of this report, please contact FINRA Corporate Financing at <strong>(240) 386-4623</strong>.`,
  // tslint:disable-next-line:max-line-length
  corpfinppftr: `This report displays statistics about timely filings submitted to Corporate Financing through the Private Placement Filing System. \n See <a href="http://finra.complinet.com/en/display/display_main.html?rbid=2403&element_id=10753" target="_blank">FINRA Rule 5123(a)</a> and <a href="http://finra.complinet.com/en/display/display_main.html?rbid=2403&element_id=6837" target="_blank">FINRA Rule 5122(b)(2)</a> for filing requirements.\n Click <a href="https://firms.cdip.finra.org/cdip-cabinet/PrivateOfferingFiling" target="_blank">here</a> to access the Private Placement Filing System.\n\nIf you have questions regarding the content of this report, please contact FINRA Corporate Financing at <strong>(240) 386-5520</strong>.`
};


export const CORP_FIN_HEADER_TEXT_BY_REPORT_TYPE_AND_EDITION = {
  [CorpFinReportShortNames.RULE_5110]: {
    default: `This report provides a summary of the review activity for the filings submitted during the selected and previous reporting periods. \n\n \
    If you have questions regarding the content of this report, please contact FINRA Corporate Financing at \
    <a title="FINRA Corporate Financing number" href="tel:2403864623"><span>(240) 386-4623</span></a>.`
  },

  [CorpFinReportShortNames.PRIVATE_PLACEMENT]: {
    default: `This report displays statistics about timely filings submitted to \
    Corporate Financing through the Private Placement Filing System. \n \
    See <a href="http://finra.complinet.com/en/display/display_main.html?rbid=2403&element_id=10753" target="_blank">FINRA Rule 5123(a)</a> and \
    <a href="http://finra.complinet.com/en/display/display_main.html?rbid=2403&element_id=6837" target="_blank">FINRA Rule 5122(b)(2)</a> \
    for filing requirements.\n Click <a href="https://firms.cdip.finra.org/cdip-cabinet/PrivateOfferingFiling" target="_blank">here</a> to access the \
    Private Placement Filing System.\n\nIf you have questions regarding the content of this report, please contact FINRA Corporate Financing at \
    <a title="FINRA Corporate Financing number" href="tel:2403864623"><span>(240) 386-4623</span></a>.`
  },

  [CorpFinReportShortNames.LATE_FILING]: {
    1: `<p>This report displays statistics about late filings submitted to Corporate Financing through the \
    Public Offering System. \
    See <a href='http://www.finra.org/finramanual/rules/r5110' target='_blank'>FINRA Rule 5110(b)(4)(A)</a> \
    for filing requirements. Click <a href='https://firms.di.finra.org/cdip-cabinet/FirmsDashboard' target='_blank'>here</a> \
    to access the Public Offering Dashboard.</p>\
    <p> If you have questions regarding the content of this report, please contact FINRA Corporate Financing at (240) 386-4623.</p>`,
    2:
    `<p>This report displays statistics about late filings submitted to Corporate Financing through the Public Offering System. \
    See <a href="http://www.finra.org/finramanual/rules/r5110">FINRA Rule 5110(a)(3)</a> and <a href="https://www.finra.org/rules-guidance/notices/20-10">Regulatory Notice 20-10</a> \
    regarding amendments to the timely filing requirements implemented on March 20, 2020. \
    For filings submitted prior to March 20, 2020, please refer to the requirements for filing described in the past version of FINRA Rule 5110(b)(4) \
    effective from April 11, 2016 to March 19, 2020. Click <a href="https://firms.di.finra.org/cdip-cabinet/FirmsDashboard">here</a> to access the Public Offering Dashboard.</p> \
    <p>If you have questions regarding the content of this report, please contact FINRA Corporate Financing at <a href="tel:2403864623">(240) 386-4623</a>.</p>`
  },
};

export const PUBLIC_OFFERING_LATE_FILING_SUBMISSION_TOTALS_SUMMARY_EXPORT_DETAIL_FIELDS_BY_EDITION = {
  1: [
    '',
    'filerFirmName',
    'filerTotalFilingCount',
    'firmLateFilingCount',
    'filerLateFilingPercent',
  ],
  2: [
    '',
    'filerFirmName',
    'filerTotalFilingCount',
    'filerLateFilingCount',
    'filerLateFilingPercent',
  ]
};

export const PUBLIC_OFFERING_LATE_FILING_LATENESS_MAGNITUDE_SUMMARY_EXPORT_FIELDS_BY_EDITION = {
  1: [
    'monthStartDate',
    'late1To3DayCount',
    'late4To10DayCount',
    'late11To30DayCount',
    'late31To59DayCount',
    'lateGreaterThan59DayCount',
    'averageLatenessNumber',
    'medianLatenessNumber',
    'industryAverageLatenessNumber',
    'industryMedianLatenessNumber',
  ],
  2: [
    'monthStartDate',
    'late1To3DayCount',
    'late4To10DayCount',
    'late11To30DayCount',
    'late31To59DayCount',
    'lateGreaterThan59DayCount',
    'averageLatenessNumber',
    'medianLatenessNumber',
    { prop: 'industryAverageLatenessNumber', formatter: (value) => finraGridNumericValueFormatter({ value }) },
    { prop: 'industryMedianLatenessNumber', formatter: (value) => finraGridNumericValueFormatter({ value }) },
  ]
};



export const PREFER_LOCAL_CORPFIN_HEADERS = {
  [CorpFinReportShortNames.LATE_FILING]: true,
};

// tslint:disable-next-line:max-line-length
export const CORP_FIN_FOOTER_TEXT = `This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. Information provided does not necessarily indicate a rule violation or issue. \n Member firms should make no inference that the staff of FINRA has or has not determined that the information contained on this report does or does not constitute rule violations or that the data has been accurately reported to FINRA.`;

export const PRV_PLC_FLG_TM_COLUMN_DEFS = {
  '5122': addFinraGridColumnId([
    {
      headerName: 'Period',
      children: [
        {
          headerName: '',
          children: [
            {
              headerName: '',
              field: 'quarterStartDate',
              valueFormatter: finraGridQuarterDateFormatter,
              headerClass: 'no-border-top',
              cellClass: 'strong',
              sort: 'desc'
            }
          ]
        }
      ]
    },
    {
      headerName: 'Firm',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: 'Total Filings by Firm',
          headerClass: 'lines accent',
          groupHeaderClass: 'lines accent',
          cellClass: 'lines',
          children: [
            {
              headerName: '',
              field: 'firmTotalFilingCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent lines no-border-top',
              cellClass: 'accent ag-numeric-cell lines'
            }
          ]
        },
        {
          headerName:
            'Filings Submitted At or Prior to the Date of First Offer',
          children: [
            {
              headerName: '',
              field: 'filingsSubmittedAtOrPriorToFirstOfferCount',
              headerClass: 'no-border-top',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn'
            },
            {
              headerName: '(%)',
              field: 'filingsSubmittedAtOrPriorToFirstOfferPercent',
              headerClass: 'no-border-top right',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        },
        {
          headerName: 'Filings Submitted After the Date of First Offer',
          headerClass: 'accent',
          cellClass: 'accent ag-numeric-cell',
          children: [
            {
              headerName: 'Total',
              field: 'filingsSubmittedAfterFirstOfferCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent',
              cellClass: 'accent ag-numeric-cell'
            },
            {
              headerName: 'Total (%)',
              field: 'filingsSubmittedAfterFirstOfferPercent',
              type: 'numericColumn',
              headerClass: 'accent',
              cellClass: 'accent ag-numeric-cell',
              valueFormatter: finraGridPercentValueFormatter
            },
            {
              headerName: '1 to 30 Days',
              field: 'filingsSubmitted1To30DaysAfterFirstOfferCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent',
              cellClass: 'accent ag-numeric-cell'
            },
            {
              headerName: 'Over 30 Days',
              field: 'filingsSubmittedOver30DaysAfterFirstOfferCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent',
              cellClass: 'accent ag-numeric-cell'
            }
          ]
        },
        {
          headerName: 'Filings with Unknown Date of First Offer',
          children: [
            {
              headerName: '',
              field: 'filingsSubmittedUnknownDaysAfterFirstOfferDateCount',
              valueFormatter: finraGridNumericValueFormatter,
              headerClass: 'no-border-top',

              type: 'numericColumn'
            },
            {
              headerName: '(%)',
              field: 'filingsSubmittedUnknownDaysAfterFirstOfferDatePercent',
              headerClass: 'no-border-top right',

              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        },
        {
          headerName: "Firm's Rank in Industry: Timeliness",
          headerClass: 'accent',
          children: [
            {
              // tslint:disable-next-line:quotemark
              headerName: '',
              field: 'rankNumber',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent no-border-top',
              cellClass: 'accent ag-numeric-cell'
            }
          ]
        }
      ]
    },
    {
      headerName: 'Industry',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: 'Total Number of Firms',
          headerClass: 'lines',
          cellClass: 'lines',
          children: [
            {
              headerName: '',
              field: 'industryTotalFirmCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'lines no-border-top',
              cellClass: 'lines'
            }
          ]
        },
        {
          headerName: 'Total Filings',
          headerClass: 'accent',
          cellClass: '',
          children: [
            {
              headerName: '',
              field: 'industryTotalFilingCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent no-border-top',
              cellClass: 'accent ag-numeric-cell'
            }
          ]
        },
        {
          headerName:
            'Filings Submitted At of Prior to the Date of First Offer (%)',

          headerClass: '',
          cellClass: '',
          children: [
            {
              headerName: '',
              headerClass: 'no-border-top',
              field: 'industryFilingsSubmittedAtOrPriorToFirstOfferPercent',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        },
        {
          headerName: 'Filings Submitted After the Date of First Offer (%)',
          headerClass: 'accent',
          cellClass: '',
          children: [
            {
              headerName: '',
              field: 'industryFilingsSubmittedAfterFirstOfferPercent',
              type: 'numericColumn',
              headerClass: 'accent no-border-top',
              cellClass: 'accent ag-numeric-cell',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        },
        {
          headerName: 'Filings with Unknown Date of First Offer (%)',
          groupHeaderClass: '',
          cellClass: '',
          children: [
            {
              headerName: '',
              headerClass: 'no-border-top',
              field:
                'industryFilingsSubmittedUnknownDaysAfterFirstOfferDatePercent',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        }
      ]
    }
  ]),
  '5123': addFinraGridColumnId([
    {
      headerName: 'Period',
      children: [
        {
          headerName: '',
          children: [
            {
              headerName: '',
              field: 'quarterStartDate',
              valueFormatter: finraGridQuarterDateFormatter,
              headerClass: 'no-border-top',
              cellClass: 'strong',
              sort: 'desc'
            }
          ]
        }
      ]
    },

    {
      headerName: 'Firm',
      cellClass: 'lines',
      headerClass: 'lines',
      children: [
        {
          headerName: 'Total Filings by Firm',
          cellClass: 'lines accent',
          groupCellClass: 'lines accent',
          headerClass: 'lines accent',
          children: [
            {
              headerName: '',
              field: 'firmTotalFilingCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent lines no-border-top',
              cellClass: 'accent ag-numeric-cell lines'
            }
          ]
        },
        {
          headerName: 'Filing Submitted Within 15 Days of Date of First Sale',
          children: [
            {
              headerName: '',
              headerClass: 'no-border-top',
              field: 'filingsSubmittedWithin15DaysOfFirstSaleCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn'
            },
            {
              headerName: '(%)',
              headerClass: 'no-border-top right',
              field: 'filingsSubmittedWithin15DaysOfFirstSalePercent',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        },
        {
          headerName: 'Filings Submitted >15 Days after Date of First Sale',
          headerClass: 'accent',
          cellClass: 'accent ag-numeric-cell',
          children: [
            {
              headerName: 'Total',
              field: 'filingsSubmittedOver15DaysAfterFirstSaleCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent',
              cellClass: 'accent ag-numeric-cell'
            },
            {
              headerName: 'Total (%)',
              field: 'filingsSubmittedOver15DaysAfterFirstSalePercent',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter,
              headerClass: 'accent',
              cellClass: 'accent ag-numeric-cell'
            },
            {
              headerName: '16 to 30 Days',
              field: 'filingsSubmitted16To30DaysAfterFirstSaleCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent',
              cellClass: 'accent ag-numeric-cell'
            },
            {
              headerName: 'Over 30 Days',
              field: 'filingsSubmittedOver30DaysAfterFirstSaleCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent',
              cellClass: 'accent ag-numeric-cell'
            }
          ]
        },
        {
          headerName: 'Filings with Unknown Date of First Sale',
          children: [
            {
              headerName: '',
              headerClass: 'no-border-top',
              field: 'filingsSubmittedUnknownDaysAfterFirstSaleDateCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn'
            },
            {
              headerName: '(%)',
              headerClass: 'no-border-top right',
              field: 'filingsSubmittedUnknownDaysAfterFirstSaleDatePercent',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        },
        {
          headerName: "Firm's Rank in Industry: Timeliness",
          headerClass: 'accent',
          children: [
            {
              headerName: '',
              field: 'rankNumber',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent no-border-top',
              cellClass: 'accent ag-numeric-cell'
            }
          ]
        }
      ]
    },
    {
      headerName: 'Industry',
      cellClass: 'lines',
      headerClass: 'lines',
      children: [
        {
          headerName: 'Total Number of Firms',
          cellClass: 'lines',
          headerClass: 'lines',
          children: [
            {
              headerName: '',
              field: 'industryTotalFirmCount',
              type: 'numericColumn',
              valueFormatter: finraGridNumericValueFormatter,
              cellClass: 'lines',
              headerClass: 'lines no-border-top'
            }
          ]
        },
        {
          headerName: 'Total Filings',
          headerClass: 'accent',
          cellClass: 'accent',
          children: [
            {
              headerName: '',
              field: 'industryTotalFilingCount',
              valueFormatter: finraGridNumericValueFormatter,
              type: 'numericColumn',
              headerClass: 'accent no-border-top',
              cellClass: 'accent ag-numeric-cell'
            }
          ]
        },
        {
          headerName:
            'Filings Submitted Within 15 Days of Date of First Sale (%)',
          children: [
            {
              headerName: '',
              headerClass: 'no-border-top',
              field: 'industryFilingsSubmittedWithin15DaysOfFirstSalePercent',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        },
        {
          headerName: 'Filings Submitted >15 Days after Date of First Sale (%)',
          headerClass: 'accent',
          children: [
            {
              headerName: '',
              field: 'industryFilingsSubmittedOver15DaysAfterFirstSalePercent',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter,
              headerClass: 'accent no-border-top',
              cellClass: 'accent ag-numeric-cell'
            }
          ]
        },
        {
          headerName: 'Filings with Unknown Date of First Sale (%)',
          children: [
            {
              headerName: '',
              headerClass: 'no-border-top',
              field:
                'industryFilingsSubmittedUnknownDaysAfterFirstSaleDatePercent',
              type: 'numericColumn',
              valueFormatter: finraGridPercentValueFormatter
            }
          ]
        }
      ]
    }
  ])
};

export const FILE_DOWNLOAD_NAMES = {
  PUB_OFFER:
    'PublicOfferingRule5110FilingSummary_${firmId}_${reportPeriodDate}',
  PRV_PLACE:
    'PrivatePlacementFilingTimelinessReportSummary_${firmId}_${reportPeriodDate}',
  LATE_FILING:
    'CorporateFinancingPublicOfferingsLateFilingsReport_${firmId}_${reportPeriodDate}'
};


/** Export Summary Logic */

// export Private Placement Filing Timeliness Report
export function exportPrivatePlacementFilingSummaryData(
  ruleData
): string {
  const topHeadingRow = [
    'Period',
    'Firm',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Industry',
    '',
    '',
    '',
    '',
  ];

  const exportStringData: SummaryExportData[] = [
    {
      title: 'Filings Made Under FINRA Rule 5123',
      rowData: ruleData['5123'],
      columnLabels: [
        topHeadingRow,
        [
          '',
          'Total Filings by Firm',
          'Filing Submitted Within 15 Days of Date of First Sale',
          '',
          'Filings Submitted >15 Days after Date of First Sale',
          '',
          '',
          '',
          'Filings with Unknown Date of First Sale',
          '',
          'Firm\'s Rank in Industry: Timeliness',
          'Total Number of Firms',
          'Total Filings',
          'Filings Submitted Within 15 Days of Date of First Sale (%)',
          'Filings Submitted >15 Days after Date of First Sale (%)',
          'Filings with Unknown Date of First Sale (%)',
        ],
        [
          '',
          '',
          '',
          '(%)',
          'Total',
          'Total (%)',
          '16 to 30 Days',
          'Over 30 Days',
          '',
          '(%)',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      ],
      dataMappings: [
        'quarterStartDate',
        'firmTotalFilingCount',
        'filingsSubmittedWithin15DaysOfFirstSaleCount',
        'filingsSubmittedWithin15DaysOfFirstSalePercent',
        'filingsSubmittedOver15DaysAfterFirstSaleCount',
        'filingsSubmittedOver15DaysAfterFirstSalePercent',
        'filingsSubmitted16To30DaysAfterFirstSaleCount',
        'filingsSubmittedOver30DaysAfterFirstSaleCount',
        'filingsSubmittedUnknownDaysAfterFirstSaleDateCount',
        'filingsSubmittedUnknownDaysAfterFirstSaleDatePercent',
        'rankNumber',
        'industryTotalFirmCount',
        'industryTotalFilingCount',
        'industryFilingsSubmittedWithin15DaysOfFirstSalePercent',
        'industryFilingsSubmittedOver15DaysAfterFirstSalePercent',
        'industryFilingsSubmittedUnknownDaysAfterFirstSaleDatePercent',
      ],
    },

    {
      title: 'Filings Made Under FINRA Rule 5122',
      rowData: ruleData['5122'],
      columnLabels: [
        topHeadingRow,
        [
          '',
          'Total Filings by Firm',
          'Filings Submitted At or Prior to the Date of First Offer',
          '',
          'Filings Submitted After the Date of First Offer',
          '',
          '',
          '',
          'Filings with Unknown Date of First Offer',
          '',
          'Firm\'s Rank in Industry: Timeliness',
          'Total Number of Firms',
          'Total Filings',
          'Filings Submitted At of Prior to the Date of First Offer (%)',
          'Filings Submitted After the Date of First Offer (%)',
          'Filings with Unknown Date of First Offer (%)',
        ],
        [
          '',
          '',
          '',
          '(%)',
          'Total',
          'Total (%)',
          '1 to 30 Days',
          'Over 30 Days',
          '',
          '(%)',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      ],
      dataMappings: [
        'quarterStartDate',
        'firmTotalFilingCount',
        'filingsSubmittedAtOrPriorToFirstOfferCount',
        'filingsSubmittedAtOrPriorToFirstOfferPercent',
        'filingsSubmittedAfterFirstOfferCount',
        'filingsSubmittedAfterFirstOfferPercent',
        'filingsSubmitted1To30DaysAfterFirstOfferCount',
        'filingsSubmittedOver30DaysAfterFirstOfferCount',
        'filingsSubmittedUnknownDaysAfterFirstOfferDateCount',
        'filingsSubmittedUnknownDaysAfterFirstOfferDatePercent',
        'rankNumber',
        'industryTotalFirmCount',
        'industryTotalFilingCount',
        'industryFilingsSubmittedAtOrPriorToFirstOfferPercent',
        'industryFilingsSubmittedAfterFirstOfferPercent',
        'industryFilingsSubmittedUnknownDaysAfterFirstOfferDatePercent',
      ],
    },
  ];

  const exportString = buildExportDataString(exportStringData);
  const csvString = trimTrailingChar(exportString, '\n');
  return csvString;
}

// export Public Offering Late Filings
export function exportPublicOfferingLateFilingSummaryData(
  reportData,
  edition: string | number
): string {
  const useEdition = parseInt(edition.toString(), 10); // ensure int value
  const useSubmissionTotalsDetailMonthFields = PUBLIC_OFFERING_LATE_FILING_SUBMISSION_TOTALS_SUMMARY_EXPORT_DETAIL_FIELDS_BY_EDITION[useEdition];
  const useLatenessMadnitudeDataMappings = PUBLIC_OFFERING_LATE_FILING_LATENESS_MAGNITUDE_SUMMARY_EXPORT_FIELDS_BY_EDITION[useEdition];

  const massDataStringList: string[] = [];

  if (reportData.submissionTotals) {
    // submission totals
    const submissionTotalsHeadings = buildColumnLabelsString([
      [
        '',
        '',
        'Firm',
        '',
        '',
        '',
        'Industry',
        '',
        '',
        '',
      ],
      [
        'Month',
        '',
        'Total Filings Count',
        'Late Filings Count',
        'Late Filing Percentage',
        'Rank',
        'Firms',
        'Total Filings Count',
        'Late Filings Count',
        'Late Filing Percentage',
      ],
    ]);

    massDataStringList.push(`Submission Totals\n`);
    massDataStringList.push(submissionTotalsHeadings);

    const submissionTotalsByMonthObj = sort_distinct(
      reportData.submissionTotals,
      'monthStartDate'
    );
    const submissionTotalsByMonthList = Object.keys(submissionTotalsByMonthObj);
    const mappings = [
      'firmTotalFilingCount',
      'firmLateFilingCount',
      'firmLateFilingPercent',
      'rankNumber',
      'industryTotalFirmCount',
      'industryTotalFilingCount',
      'industryLateFilingCount',
      'industryLateFilingPercent',
    ];

    for (const totalsMonthKey of submissionTotalsByMonthList) {
      const totalsMonthList = submissionTotalsByMonthObj[totalsMonthKey];
      const monthRowData = { monthStartDate: totalsMonthKey };
      mappings.forEach((mapping: string) => {
        if (mapping === 'firmLateFilingPercent') {
          try {
            const late = totalsMonthList[0].firmLateFilingCount;
            const total = totalsMonthList[0].firmTotalFilingCount;
            const percentage = (late * 100) / total;
            const rounded = roundUptoTwoDecimal(percentage);
            monthRowData[mapping] = rounded;
          } catch (e) {
            // 'corp-fin submissions total summary export error: could not create mapping for field:'
            monthRowData[mapping] = '';
          }
        } else {
          monthRowData[mapping] = totalsMonthList[0][mapping];
        }
      });

      const monthRowDataString = buildRowDataString({
        rowData: [monthRowData],
        dataMappings: [
          'monthStartDate',
          '',
          ...mappings,
        ],
      });

      // build details row data
      const detailsMonthRowDataString = buildRowDataString({
        rowData: totalsMonthList,
        dataMappings: useSubmissionTotalsDetailMonthFields,
      });

      // add to list
      massDataStringList.push(monthRowDataString);
      massDataStringList.push(detailsMonthRowDataString);
    }

    massDataStringList.push(`\n`);
  }

  if (reportData.lateFilingAnalysis) {
    const lateFilingAnalysisDataString = buildExportDataString([
      {
        title: `Late Filing Analysis`,
        rowData: reportData.lateFilingAnalysis,
        columnLabels: FILING_ANALYSIS_COLUMN_DEFS.slice(0, 3).map((c: any) => c.headerName),
        dataMappings: FILING_ANALYSIS_COLUMN_DEFS.slice(0, 3).map((c: any) => c.field),
      }
    ]);

    massDataStringList.push(lateFilingAnalysisDataString);
    massDataStringList.push(`\n\n`);
  }

  if (reportData.latenessMagnitude) {
    const latenessMagnitudeDataString = buildExportDataString([
      {
        title: `Lateness Magnitude`,
        rowData: reportData.latenessMagnitude,
        columnLabels: [
          [
            '',
            'Firm',
            '',
            '',
            '',
            '',
            '',
            '',
            'Industry',
            '',
          ],
          [
            'Month',
            '1-3 days',
            '4-10 days',
            '11-30 days',
            '31-59 days',
            '>59 days',
            'Average Lateness',
            'Median Lateness',
            'Industry Average Lateness',
            'Industry Median Lateness',
          ],
        ],
        dataMappings: useLatenessMadnitudeDataMappings,
      }
    ]);

    massDataStringList.push(latenessMagnitudeDataString);
  }


  const exportDataString = massDataStringList.join('');
  const csvString = trimTrailingChar(exportDataString, '\n');
  return csvString;
}

// export Public Offering Rule 5110 Filing Summary
export function exportpublicOfferingRuleSummaryData(
  reportData,
): string {
  const massDataStringList = [];

  /** Helper Methods */

  const getAggDataString = (list, aggMappings, aggDataMappings, aggDefaults) => {
    const getAggValue = (prop) => list.reduce((acc, cur) => acc + (cur && cur[prop] || 0), 0);
    const aggregateMonthRowData = { ...aggDefaults };
    aggMappings.forEach(mapping => {
      aggregateMonthRowData[mapping] = getAggValue(mapping);
    });

    const aggregateMonthRowDataString = buildRowDataString({
      rowData: [aggregateMonthRowData],
      dataMappings: aggDataMappings,
    });

    return aggregateMonthRowDataString;
  };

  const generateRowData = (list, aggMappings, aggDataMappings, listMappings, aggDefaults) => {
    // build aggregate row
    const aggregateMonthRowDataString = getAggDataString(
      list,
      aggMappings,
      aggDataMappings,
      aggDefaults
    );

    // build details row data
    const detailsMonthRowDataString = buildRowDataString({
      rowData: list,
      dataMappings: listMappings,
    });

    return {
      aggregateMonthRowDataString,
      detailsMonthRowDataString,
    };
  };

  /** Convert Each Table To CSV */

  // Deals Current
  massDataStringList.push(
    `${PUBLIC_FILING_TITLES[VIEW_NAMES.DEALS_FILED_CURRENT]}\n`
  );
  const dealsCurrentHeadings = buildColumnLabelsString([
    [
      '',
      '',
      'Firm',
      '',
      'Industry',
    ],
    [
      'FINRA Review Program',
      '',
      '',
      '(%)',
      '(%)',
    ],
  ]);
  massDataStringList.push(dealsCurrentHeadings);
  const dealsCurrentByDescriptionObj = sort_distinct(
    reportData[VIEW_NAMES.DEALS_FILED_CURRENT] || [],
    'currentReviewProgramDescription'
  );
  const dealsCurrentByDescriptionList = Object.keys(dealsCurrentByDescriptionObj);
  for (const dealsKey of dealsCurrentByDescriptionList) {
    const dealsList = dealsCurrentByDescriptionObj[dealsKey];
    const aggMappings = [
      'dealFilingCount',
      'filingPercent',
      'industryFilingPercent',
    ];
    const result = generateRowData(
      dealsList,
      aggMappings,
      [
        'currentReviewProgramDescription',
        '',
        ...aggMappings
      ],
      [
        '',
        (dealsKey === 'Total' ? '' : 'dealDescription'),
      ],
      { currentReviewProgramDescription: dealsKey }
    );
    massDataStringList.push(result.aggregateMonthRowDataString);
    massDataStringList.push(result.detailsMonthRowDataString);
  }
  massDataStringList.push(`\n`);

  // Deals Prior
  massDataStringList.push(
    `${PUBLIC_FILING_TITLES[VIEW_NAMES.DEALS_FILED_PRIOR]}\n`
  );
  const dealsPriorHeadings = buildColumnLabelsString([
    [
      '',
      '',
      'Total Filed',
      '',
      'Aging Since Initial Filing',
    ],
    [
      '',
      '',
      '',
      '',
      '< 6 Months Old',
      '',
      '',
      '>= 6 Months Old',
    ],
    [
      'FINRA Review Program',
      '',
      '',
      'Firm (%)',
      '',
      'Firm (%)',
      'Industry (%)',
      '',
      'Firm (%)',
      'Industry (%)',
    ],
  ]);
  massDataStringList.push(dealsPriorHeadings);
  const dealsPriorByDescriptionObj = sort_distinct(
    reportData[VIEW_NAMES.DEALS_FILED_PRIOR] || [],
    'currentReviewProgramDescription'
  );
  const dealsPriorByDescriptionList = Object.keys(dealsPriorByDescriptionObj);
  for (const dealsKey of dealsPriorByDescriptionList) {
    const dealsList = dealsPriorByDescriptionObj[dealsKey];
    const aggMappings = [
      'dealFilingCount',
      'filingPercent',
      'dealWithin6MonthsFromInitialFilingCount',
      'within6MonthsFromInitialFilingPercent',
      'industryWithin6MonthsFromInitialFilingPercent',
      'dealOver6MonthsFromInitialFilingCount',
      'over6MonthsFromInitialFilingPercent',
      'industryOver6MonthsFromInitialFilingPercent'
    ];
    const result = generateRowData(
      dealsList,
      aggMappings,
      [
        'currentReviewProgramDescription',
        '',
        ...aggMappings
      ],
      [
        '',
        (dealsKey === 'Total' ? '' : 'dealDescription'),
      ],
      { currentReviewProgramDescription: dealsKey }
    );
    massDataStringList.push(result.aggregateMonthRowDataString);
    massDataStringList.push(result.detailsMonthRowDataString);
  }
  massDataStringList.push(`\n`);

  // Deals Cleared
  massDataStringList.push(
    `${PUBLIC_FILING_TITLES[VIEW_NAMES.DEALS_CLEARED_CURRENT]}\n`
  );
  const dealsClearedHeadings = buildColumnLabelsString([
    [
      '',
      '',
      'Total Cleared',
      'Time to Clearance from Initial Filing',
    ],
    [
      '',
      '',
      '',
      '< 1 Month Old',
      '',
      '> 1 and < 6 Months Old',
      '',
      '>= 6 Months Old',
    ],
    [
      'FINRA Review Program',
      '',
      '',
      '',
      '(%)',
      '',
      '(%)',
      '',
      '(%)',
    ],
  ]);
  massDataStringList.push(dealsClearedHeadings);
  const dealsClearedByDescriptionObj = sort_distinct(
    reportData[VIEW_NAMES.DEALS_CLEARED_CURRENT] || [],
    'currentReviewProgramDescription'
  );
  const dealsClearedByDescriptionList = Object.keys(dealsClearedByDescriptionObj);
  for (const dealsKey of dealsClearedByDescriptionList) {
    const dealsList = dealsClearedByDescriptionObj[dealsKey];
    const aggMappings = [
      'dealFilingCount',
      'dealClearedWithin1MonthCount',
      'clearedWithin1MonthPercent',
      'dealClearedBetween1And6MonthsCount',
      'clearedBetween1And6MonthsPercent',
      'dealClearedOver6MonthsCount',
      'clearedOver6MonthsPercent',
    ];
    const result = generateRowData(
      dealsList,
      aggMappings,
      [
        'currentReviewProgramDescription',
        '',
        ...aggMappings
      ],
      [
        '',
        (dealsKey === 'Total' ? '' : 'dealDescription'),
      ],
      { currentReviewProgramDescription: dealsKey }
    );
    massDataStringList.push(result.aggregateMonthRowDataString);
    massDataStringList.push(result.detailsMonthRowDataString);
  }
  massDataStringList.push(`\n`);

  // Comment Letters
  massDataStringList.push(
    `${PUBLIC_FILING_TITLES[VIEW_NAMES.COMMENT_LETTERS]}\n`
  );
  const commentLettersHeadings = buildColumnLabelsString([
    [
      '',
      'Selected Reporting Period',
      '',
      '',
      '',
      'Prior Reporting Periods',
    ],
    [
      '',
      '',
      '',
      '',
      '> 1 and < 6 Months Old',
      '',
      '',
      '',
      '>= 6 Months Old',
    ],
    [
      'Type of Comment Letter',
      '',
      'Firm (%)',
      'Industry (%)',
      '',
      'Firm (%)',
      'Industry (%)',
      '',
      'Firm (%)',
      'Industry (%)',
    ],
  ]);
  massDataStringList.push(commentLettersHeadings);
  const commentLettersAggMappings = [
    'currentPeriodCount',
    'currentPeriodPercent',
    'industryCurrentPeriodPercent',
    'within6MonthsFromInitialFilingCount',
    'within6MonthsFromInitialFilingPercent',
    'industryWithin6MonthsFromInitialFilingPercent',
    'over6MonthsFromInitialFilingCount',
    'over6MonthsFromInitialFilingPercent',
    'industryOver6MonthsFromInitialFilingPercent',
  ];
  const commentLettersDataString = buildRowDataString({
    rowData: reportData[VIEW_NAMES.COMMENT_LETTERS] || [],
    dataMappings: [
      'letterTypeCode',
      ...commentLettersAggMappings
    ]
  });
  const commentLettersTotalDataString = getAggDataString(
    reportData[VIEW_NAMES.COMMENT_LETTERS] || [],
    commentLettersAggMappings,
    [
      'letterTypeCode',
      ...commentLettersAggMappings
    ],
    { letterTypeCode: 'Total' }
  );
  massDataStringList.push(commentLettersDataString);
  massDataStringList.push(commentLettersTotalDataString);
  massDataStringList.push(`\n`);

  // Distribution Method
  massDataStringList.push(
    `${PUBLIC_FILING_TITLES[VIEW_NAMES.DISTRIBUTION_METHOD]}\n`
  );
  const distributionMethodHeadings = buildColumnLabelsString([
    [
      '',
      'Selected Reporting Period',
      '',
      '',
      '',
      'Prior Reporting Periods',
    ],
    [
      '',
      '',
      '',
      '',
      '> 1 and < 6 Months Old',
      '',
      '',
      '',
      '>= 6 Months Old',
    ],
    [
      'Distribution Method',
      '',
      'Firm (%)',
      'Industry (%)',
      '',
      'Firm (%)',
      'Industry (%)',
      '',
      'Firm (%)',
      'Industry (%)',
    ],
  ]);
  massDataStringList.push(distributionMethodHeadings);
  const distributionMethodAggMappings = [
    'currentPeriodCount',
    'currentPeriodPercent',
    'industryCurrentPeriodPercent',
    'within6MonthsFromInitialFilingCount',
    'within6MonthsFromInitialFilingPercent',
    'industryWithin6MonthsFromInitialFilingPercent',
    'over6MonthsFromInitialFilingCount',
    'over6MonthsFromInitialFilingPercent',
    'industryOver6MonthsFromInitialFilingPercent',
  ];
  const distributionMethodDataString = buildRowDataString({
    rowData: reportData[VIEW_NAMES.DISTRIBUTION_METHOD] || [],
    dataMappings: [
      'distributionMethodDescription',
      ...distributionMethodAggMappings
    ]
  });
  const distributionMethodTotalDataString = getAggDataString(
    reportData[VIEW_NAMES.DISTRIBUTION_METHOD] || [],
    distributionMethodAggMappings,
    [
      'distributionMethodDescription',
      ...distributionMethodAggMappings
    ],
    { letterTypeCode: 'Total' }
  );
  massDataStringList.push(distributionMethodDataString);
  massDataStringList.push(distributionMethodTotalDataString);

  // join mass string list and export it
  const exportDataString = massDataStringList.join('');
  const csvString = trimTrailingChar(exportDataString, '\n');
  return csvString;
}
