import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  ToolbarCell,
  ToolbarComponentTypes,
  ToolbarControlComponentTypes
} from 'src/app/configs/model/finra-toolbar.model';
import { IconDefinition, faAngleDown } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'report-finra-toolbar-cell',
  templateUrl: './finra-toolbar-cell.component.html',
  styleUrls: ['./finra-toolbar-cell.component.scss']
})
export class FinraToolbarCellComponent implements OnInit, AfterViewInit {
  @ViewChild('cellContainerElm', { static: false })
  cellContainerElm;
  @Input() cell: ToolbarCell;
  faAngleDown: IconDefinition = faAngleDown;
  toolbarComponentTypes = ToolbarComponentTypes;
  toolbarControlComponentTypes = ToolbarControlComponentTypes;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.cell.cellReadyCallback) {
      this.cell.cellReadyCallback(
        this.cellContainerElm,
        this.cell
      );
    }
  }
}
