<mat-form-field>
  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="dateControl"
    (dateInput)="onDateInput('input', $event)"
    [placeholder]="title"
    [name]="name"
  />
  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon matDatepickerToggleIcon
      ><fa-icon [icon]="faCalendarAlt" class="fa-lg"></fa-icon
    ></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
