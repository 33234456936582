<div #finraChart>
  <div [ngSwitch]="template" class="finra-chart-container">
    <div *ngSwitchCase="'line-chart'" class="chart-container">
      <ngx-charts-line-chart
        [scheme]="colorScheme"
        [results]="rowData"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [legendTitle]="legendLabel"
        [legendPosition]="legendPosition"
        [showXAxisLabel]="!!xAxisLabel"
        [showYAxisLabel]="!!yAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [autoScale]="autoScale"
        [tooltipDisabled]="tooltipDisabled"
        (select)="onSelect($event)"
        [xAxisTicks]="xAxisTicks"
        [yAxisTicks]="yAxisTicks"
        [xScaleMax]="xScaleMax"
        [xScaleMin]="xScaleMin"
        [yScaleMax]="yScaleMax"
        [yScaleMin]="yScaleMin"
        [xAxisTickFormatting]="xAxisTickFormatting"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [view]="view"
      >
        <ng-template #tooltipTemplate let-model="model">
          <div class="tooltip-container">
            <span class="tooltip-text tooltip-text-series">
              {{ model.series }} &#9679; {{ model.name }}
            </span>
            <!-- <span class="tooltip-text tooltip-text-series">{{ model.name }}</span> -->
            <div class="tooltip-text tooltip-text-value">{{ lineTooltipTemplateValueFormat(model) }}</div>
          </div>
          <!-- {{model | json}} -->
        </ng-template>
        <ng-template #seriesTooltipTemplate let-model="model">
          <div *ngFor="let mod of model; let i = index" class="tooltip-container">
            <!-- {{ mod | json}} -  -->
            <div>
              <span
                class="tooltip-color"
                [ngStyle]="{ 'background-color': mod.color }"
              ></span>
              <span class="tooltip-text tooltip-text-series">
                {{ mod.series }}: {{ lineSeriesTooltipTemplateValueFormat(mod) }}
              </span>
            </div>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
    <div *ngSwitchCase="'advanced-pie'" class="chart-container pie">
      <ngx-charts-advanced-pie-chart
        [scheme]="colorSchemeBar"
        [results]="rowData"
        [view]="view"
        [label]="pieLabel"
        [percentageFormatting]="percentageFormatting"
      >
        <ng-template #tooltipTemplate let-model="model">
          <div class="tooltip-container">
            <div class="tooltip-text tooltip-text-value">
              {{ model.name }}: {{ model.value }}
            </div>
          </div>
        </ng-template>
      </ngx-charts-advanced-pie-chart>
    </div>
    <div *ngSwitchCase="'bar-group-stacked'" class="chart-container">
      <ngx-charts-bar-vertical-stacked
        [scheme]="colorSchemeBar"
        [gradient]="gradient"
        [legend]="showLegend"
        [legendTitle]="legendLabel"
        [legendPosition]="legendPosition"
        [showXAxisLabel]="!!xAxisLabel"
        [showYAxisLabel]="!!yAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [tooltipDisabled]="tooltipDisabled"
        (select)="onSelect($event)"
        [xAxisTicks]="xAxisTicks"
        [yAxisTicks]="yAxisTicks"
        [xAxisTickFormatting]="xAxisTickFormatting"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [view]="view"
        [xAxis]="true"
        [yAxis]="true"
        [showGridLines]="true"
        [results]="rowData"
        [barPadding]="barPadding"
      >
        <ng-template #tooltipTemplate let-model="model">
          <div class="tooltip-container">
            <div class="tooltip-text tooltip-text-value">
              {{ model.series }} - {{ formattedTooltip(model) }}
            </div>
          </div>
        </ng-template>
      </ngx-charts-bar-vertical-stacked>
    </div>
    <div *ngSwitchCase="'bar-group'" class="chart-container">
      <ngx-charts-bar-vertical-2d
        [xAxis]="true"
        [yAxis]="true"
        [showGridLines]="true"
        [scheme]="colorSchemeBar"
        [legend]="true"
        [legendTitle]="legendLabel"
        [legendPosition]="legendPosition"
        [results]="rowData"
        [view]="view"
        [barPadding]="20"
        [groupPadding]="20"
        [yAxisTickFormatting]="yAxisTickFormatting"
      >
        <ng-template #tooltipTemplate let-model="model">
          <div class="tooltip-container">
            <div class="tooltip-text tooltip-text-value">
              {{ formattedTooltip(model) }}
            </div>
          </div>
        </ng-template>
      </ngx-charts-bar-vertical-2d>
    </div>
  </div>
</div>