import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Subscriber } from 'rxjs';

const maxAge = 300000;

export class RequestCacheServiceEntry {
  private _err: HttpErrorResponse;

  get error(): HttpErrorResponse {
    return this._err;
  }

  set error(value: HttpErrorResponse) {
    this._err = value;

    while (this.subscribers.length > 0) {
      this.subscribers.shift().error(value);
    }
  }

  private _result: HttpResponse<any>;

  get result(): HttpResponse<any> {
    return this._result;
  }

  set result(value: HttpResponse<any>) {
    this._result = value;

    while (this.subscribers.length > 0) {
      this.subscribers.shift().next(value);
    }
  }

  subscribers: Subscriber<HttpEvent<any>>[] = [];

  push(observer: Subscriber<HttpEvent<any>>) {
    this.subscribers.push(observer);
  }
}

@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {
  constructor() {}

  cache = new Map();

  getKey(req: HttpRequest<any>): string {
    return req.urlWithParams;
  }

  get(req: HttpRequest<any>): RequestCacheServiceEntry {
    const url = this.getKey(req);
    const cached = this.cache.get(url);

    if (!cached) {
      return null;
    }

    if (cached.lastRead < Date.now() - maxAge) {
      // tslint:disable-next-line:no-console
      this.remove(req);

      return null;
    }

    return cached.response;
  }

  remove(req: HttpRequest<any>): void {
    const url = this.getKey(req);

    // TODO: Call any pending subscribers?
    this.cache.delete(url);
  }

  put(req: HttpRequest<any>, response: RequestCacheServiceEntry): void {
    const url = this.getKey(req);
    const entry = { url, response, lastRead: Date.now() };

    // tslint:disable-next-line:no-console
    this.cache.set(url, entry);
  }
}
