import { Injectable } from '@angular/core';
import {
  DetailDatasetDownloadRequest,
  DetailDatasetDownloadRequestStatus,
  DetailDatasetDownloadRequestResponse
} from 'src/app/configs/model/home.model';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'src/app/configs/model/http-status-codes.model';
import { ReportService } from './report.service';
import { EquitiesService } from './equities.service';
import { UiWidgetsService } from './ui-widgets.service';


/**
 * Detail Dataset Requests Service
 * ---
 * This service is for doing the following:
 * - containing the detail dataset requests list that comes from the backend.
 * - emit events when there is a new list fetched from the backend.
 * - keep track of pending requests and when the status changes
 * - emit event when a detail dataset request changes from pending to either error, no data or ready.
 *
 * How things are setup/working
 * ---
 * As of 5/1/2020, Report Center is now supporting detail dataset requests via AWS s3.
 * The app.component.ts first authenticates the user.
 * Once a user is authenticated, the component tells this service to start polling.
 * This service will set the data from the first call as the current list and emit an event to notify listeners
 * of the new data. Then it checks if there were any changes of the processing state
 * from previous calls. If there were any in the state, emit the changed state.
 * If/when nothing is in processing state, stop polling.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class DetailDatasetRequestsService {
  private detailDatasetRequests: DetailDatasetDownloadRequest[] = null; // the list of detail datasets requests
  private errorRequests: DetailDatasetDownloadRequest[] = []; // list of errored detail dataset requests
  private readyRequests: DetailDatasetDownloadRequest[] = []; // list of ready detail dataset requests
  private noDataRequests: DetailDatasetDownloadRequest[] = []; // list of no data detail dataset requests

  private isLoading: boolean = false;
  private currentPollingState: boolean = false;
  private detailDatasetRequestReadyPreviousState = false;
  private detailDatasetRequestErrorPreviousState = false;
  private detailDatasetRequestNoDataPreviousState = false;
  private detailDatasetRequestProcessingPreviousState = false;

  private DETAIL_DATASET_REQUEST_REFRESH_RATE = 1000 * 5;

  private processingRequestsMap = new Map<number, DetailDatasetDownloadRequestStatus>(); // map to track processing state

  private pollingState = new BehaviorSubject<boolean>(false); // stream events of polling state
  private listChanges = new BehaviorSubject<DetailDatasetDownloadRequest[]>(null); // stream events of list changes
  private isLoadingState = new BehaviorSubject<boolean>(false); // stream events when a status changes to ready
  private detailDatasetRequestReady = new BehaviorSubject<boolean>(false); // stream events when a status changes to ready
  private detailDatasetRequestError = new BehaviorSubject<boolean>(false); // stream events when a status changes to error
  private detailDatasetRequestNoData = new BehaviorSubject<boolean>(false); // stream events when a status changes to error
  private detailDatasetRequestProcessing = new BehaviorSubject<boolean>(false); // stream events when a status is processing

  constructor(
    private reportService: ReportService,
    private equitiesService: EquitiesService,
    private uiWidgetsService: UiWidgetsService
  ) {}

  startPolling() {
    if (this.currentPollingState) {
      // already polling
      return;
    }

    // get detail dataset requests
    this.getUserDetailDatasetDownloadRequests();

    // set polling state and emit event
    this.currentPollingState = true;
    this.pollingState.next(this.currentPollingState);
  }

  getLoadingState() {
    return this.isLoadingState.asObservable();
  }

  getPollingState() {
    return this.pollingState.asObservable();
  }

  setDetailDatasetRequests(detailDatasetRequests: DetailDatasetDownloadRequest[]) {
    if (!detailDatasetRequests) {
      return;
    }
    // set the detailDatasetRequests
    this.detailDatasetRequests = detailDatasetRequests;
    // emit the event for any other service/component that wants the data
    // send a copy of the data in a new array instance to trigger angular change detection(s)
    this.listChanges.next([ ...detailDatasetRequests ]);
    // check the status of the new list
    this.checkChanges();
  }

  addDetailDatasetRequest(detailDatasetRequest: DetailDatasetDownloadRequest) {
    if (!detailDatasetRequest) {
      return;
    }
    // add the detailDatasetRequest to front of list
    this.detailDatasetRequests.unshift(detailDatasetRequest);
    // emit the event for any other service/component that wants the data
    this.listChanges.next([ ...this.detailDatasetRequests ]);
    // there is a new item, start polling if not already
    if (!this.currentPollingState) {
      this.startPolling();
    }
  }

  removeDetailDatasetRequest(detailDatasetRequestId: number) {
    if (!detailDatasetRequestId) {
      return;
    }
    const index = this.detailDatasetRequests.findIndex(detailDatasetRequest => {
      const match = detailDatasetRequest.detailDatasetRequestId === detailDatasetRequestId;
      return match;
    });

    const noResultsFound = index === -1;
    if (noResultsFound) {
      return;
    }

    const detailDatasetRequest = this.detailDatasetRequests[index];

    this.reportService.deleteUserReportDetailDownloadStatus({
      reportId: detailDatasetRequest.reportId,
      requestId: detailDatasetRequest.requestId,
      reportConfigurationViewId: detailDatasetRequest.reportConfigurationViewId,
    }).subscribe({
      next: (response) => {
        if (response.recordsDeleted === 0) {
          this.uiWidgetsService.showErrorSnackbar(
            `Could not delete detail data request; something went wrong...`
          );
        }
        else {
          console.log(`detail Dataset Request deleted`);
          this.detailDatasetRequests.splice(index, 1);
          // emit the event for any other service/component that wants the data
          this.listChanges.next([ ...this.detailDatasetRequests ]);
        }
      },
      error: (error: HttpErrorResponse) => {
        const message = `Could not delete detail data request; something went wrong...`;
        console.error(message, { detailDatasetRequestId });
        // this.uiWidgetsService.showErrorSnackbar(message);
      }
    });
  }

  getDetailDatasetRequestByReportIdAndViewName(
    reportId: number,
    viewName: string
  ) {
    if (!reportId || !viewName) {
      return;
    }
    // right now, OATS is the only report that generates large detail datasets.
    // OATS views are formatted in a unique way so we have to check against the viewName argument
    // and the formatted viewName to be sure we don't miss it.
    // if/when more reports have large detail datasets, just add the view name
    // to the expression to check againt
    const useReportId = parseInt(reportId.toString(), 10);
    const result = this.detailDatasetRequests.find(detailDatasetRequest => {
      const equityViewName = this.equitiesService.getOATSComplianceDetailViewName(viewName);
      const match = (
        detailDatasetRequest.reportId === useReportId &&
        (
          detailDatasetRequest.viewName === viewName ||
          detailDatasetRequest.viewName === equityViewName
        )
      );
      return match;
    });

    return result;
  }

  // similar to an ngrx store where you can select a part of the entire app state,
  // select the behavior subject that this service has.
  // adding generic option which SHOULD/MUST match the behavior subject's generic type
  select<T>(desiredBehaviorSubjectKey: string) {
    // find behavior subject by arg
    const subject = this[desiredBehaviorSubjectKey];
    // check if the behavior subject exists on this service
    const badKey = (
      !subject ||
      subject.constructor !== BehaviorSubject
    );
    if (badKey) {
      throw new ReferenceError(
        `UnreadReportsServiceError - no behavior subject exists on this service with the key "${desiredBehaviorSubjectKey}"`
      );
    }
    // if there is a behavior subject by arg key, return it as observable
    const observable = (<BehaviorSubject<T>> subject).asObservable();
    return observable;
  }

  getUserDetailDatasetDownloadRequests() {
    this.isLoading = true;
    this.isLoadingState.next(true);
    this.reportService.getUserReportDetailDownloadStatuses().subscribe(
      (response: DetailDatasetDownloadRequestResponse) => {
        this.setDetailDatasetRequests(response.userDetailDatasetDownloadRequests);
        this.isLoading = false;
        this.isLoadingState.next(false);
      },
      (error: HttpErrorResponse) => {
        console.log(error);

        this.isLoading = false;
        this.isLoadingState.next(false);
        this.currentPollingState = false;
        this.pollingState.next(this.currentPollingState);
      }
    );
  }

  private checkChanges() {
    const readyList = [];
    const errorList = [];
    const noDataList = [];

    let processingRequestsCount = 0;
    let readyChanges = 0;
    let errorChanges = 0;
    let noDataChanges = 0;

    // loop through the list of detail dataset requests
    for (let i = 0; i < this.detailDatasetRequests.length; i++) {
      const detailDatasetRequest: DetailDatasetDownloadRequest = this.detailDatasetRequests[i];

      // get tracking status, if there is any
      const entry = this.processingRequestsMap.has(detailDatasetRequest.detailDatasetRequestId);
      // check the status
      switch (detailDatasetRequest.status) {
        case DetailDatasetDownloadRequestStatus.PROCESSING: {
          // if status is pending, check if it is being tracked. if not, add it
          if (!entry) {
            this.processingRequestsMap.set(detailDatasetRequest.detailDatasetRequestId, DetailDatasetDownloadRequestStatus.PROCESSING);
          }
          processingRequestsCount = processingRequestsCount + 1;
          break;
        }
        case DetailDatasetDownloadRequestStatus.READY: {
          // if status is ready, add to the ready list. check if it is being tracked.
          // if not, ignore; it was probably been ready since the last time the user was on RC. no need to notify again.
          // if it WAS being tracked, then it used to be processing/pending; notify the user and remove from processing map.
          if (entry) {
            readyChanges = readyChanges + 1;
            this.processingRequestsMap.delete(detailDatasetRequest.detailDatasetRequestId);
            const detailFileName = detailDatasetRequest.fileName;
            const successMessage = `${detailFileName} is now ready for download.`;
            this.uiWidgetsService.showSuccessSnackbar(successMessage);
          }
          readyList.push(detailDatasetRequest);
          break;
        }
        case DetailDatasetDownloadRequestStatus.ERROR: {
          // if status is error, add to the error list. check if it is being tracked.
          // if not, ignore; it was probably been errored since the last time the user was on RC. no need to notify again.
          // if it WAS being tracked, then it used to be processing/pending; notify the user and remove from processing map.
          if (entry) {
            errorChanges = errorChanges + 1;
            const detailFileName = detailDatasetRequest.fileName;
            const errorMessage = `${detailFileName} cannot be downloaded, an error occurred...`;
            this.uiWidgetsService.showErrorSnackbar(errorMessage);
            this.processingRequestsMap.delete(detailDatasetRequest.detailDatasetRequestId);
          }
          errorList.push(detailDatasetRequest);
          break;
        }
        case DetailDatasetDownloadRequestStatus.NO_DATA: {
          // if status is no data, add to the no data list. check if it is being tracked.
          // if not, ignore; it was probably been there since the last time the user was on RC. no need to notify again.
          // if it WAS being tracked, then it used to be processing/pending; notify the user and remove from processing map.
          if (entry) {
            noDataChanges = noDataChanges + 1;
            const detailFileName = detailDatasetRequest.fileName;
            const infoMessage = `${detailFileName} has no data.`;
            this.uiWidgetsService.showInfoSnackbar(infoMessage);
            this.processingRequestsMap.delete(detailDatasetRequest.detailDatasetRequestId);
          }
          noDataList.push(detailDatasetRequest);
          break;
        }
      }
    }

    // update the lists on the service
    this.readyRequests = readyList;
    this.errorRequests = errorList;
    this.noDataRequests = noDataList;

    // emit events if there were changes

    const readyChangesState: boolean = readyChanges > 0;
    const readyChanged = readyChangesState !== this.detailDatasetRequestReadyPreviousState;
    if (readyChanged) {
      this.detailDatasetRequestReady.next(readyChangesState);
      // once this.detailDatasetRequestReadyPreviousState is true, no need to hide it again later,
      // it will be hidden again when the page reloads or closes the tab/window
    }
    // else if (this.readyRequests.length) {
    //   // if there are ready downloads, notify user
    //   this.detailDatasetRequestReady.next(true);
    // }

    const errorChangesState: boolean = errorChanges > 0;
    const errorChanged = errorChangesState !== this.detailDatasetRequestErrorPreviousState;
    if (errorChanged) {
      this.detailDatasetRequestError.next(errorChangesState);
      // once this.detailDatasetRequestErrorPreviousState is true, no need to hide it again later,
      // it will be hidden again when the page reloads or closes the tab/window
    }
    // else if (this.errorRequests.length) {
    //   // if there are failed downloads, notify user
    //   this.detailDatasetRequestError.next(true);
    // }

    const noDataChangesState: boolean = noDataChanges > 0;
    const noDataChanged = noDataChangesState !== this.detailDatasetRequestNoDataPreviousState;
    if (noDataChanged) {
      this.detailDatasetRequestNoData.next(noDataChangesState);
      // once this.detailDatasetRequestNoDataPreviousState is true, no need to hide it again later,
      // it will be hidden again when the page reloads or closes the tab/window
    }
    // else if (this.noDataRequests.length) {
    //   // if there are failed downloads, notify user
    //   this.detailDatasetRequestNoData.next(true);
    // }

    const processingRequestsCountState: boolean = processingRequestsCount > 0;
    const processingChanged = processingRequestsCountState !== this.detailDatasetRequestProcessingPreviousState;
    if (processingChanged) {
      this.detailDatasetRequestProcessing.next(processingRequestsCountState);
      this.detailDatasetRequestProcessingPreviousState = processingRequestsCountState;
    }

    // stop polling IF none have status PROCESSING; else continue polling via recursive calling getUserDetailDatasetDownloadRequests()
    const someRequestsAreProcessing = processingRequestsCount > 0;
    if (someRequestsAreProcessing) {
      console.log(`Pending detail dataset request(s) found; calling again in ${this.DETAIL_DATASET_REQUEST_REFRESH_RATE} ms...`);
      setTimeout(() => {
        this.getUserDetailDatasetDownloadRequests();
      }, this.DETAIL_DATASET_REQUEST_REFRESH_RATE);
    }
    else {
      console.log(`No detail dataset request(s) pending; done polling for now...`);
      this.currentPollingState = false;
      this.pollingState.next(this.currentPollingState);
    }
  }
}
