import { Pipe, PipeTransform } from '@angular/core';
import {
  FINRA_GRID_NULL_VALUE,
  finraGridDateTimeFormatter,
  numericValueFormatter,
  finraGridPercentValueFormatter,
  quarterDateFormat,
  getMonthYrDate
} from '../shared/utils/utils';
import { DatePipe } from '@angular/common';
import { ViewLabels } from '../enums/view-labels.enum';

/**
 * @example
 * - {{ 'abc' | finraValidValue }} // 'abc'
 * - {{ 1234 | finraValidValue : 'number' }} // 1,234
 * - {{ 12.34 | finraValidValue : 'percent' }} // 12.34%
 * - {{ 12.34 | finraValidValue : 'date-format-custom' : 'MMM d, y - h:mm a' }} // Oct 4, 2019 - 12:37 PM
 */

@Pipe({
  name: 'finraValidValue'
})
export class FinraValidValuePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe
  ) {}

  transform(value: any, type: string, arg?: any): any {
    switch (type) {
      case 'date':
        return finraGridDateTimeFormatter({ value });
      case 'date-quarter':
        return quarterDateFormat(value);
      case 'date-month-year':
        return getMonthYrDate(value);
      case 'date-format-custom':
        return this.datePipe.transform(value, arg || 'MMM y');
      case 'date-monthShort-year':
        return this.datePipe.transform(value, 'MMM y');
      case 'date-month-day-year':
        return this.datePipe.transform(value, 'MMM d, y');
      case 'date-month-day-year-full':
        return this.datePipe.transform(value, 'MMMM d, y');
      case 'date-month-day-year-numbers':
        return this.datePipe.transform(value, 'MM/dd/yyyy');
      case 'date-time':
        return this.datePipe.transform(value, 'MMM d, y - hh:mm a');
      case 'date-time-2':
        return this.datePipe.transform(value, 'MM/dd/yyyy - hh:mm a');
      case 'percent':
        return finraGridPercentValueFormatter({ value });
      case 'percent-multiplied':
        return finraGridPercentValueFormatter({ value: value * 100 });
      case 'number':
        return numericValueFormatter(value, null);
      case 'period-avg-text':
        return value !== 'Average' ? getMonthYrDate(value) : value;

      case 'viewName': {
        switch (value) {
          case 'NMSNQCQS':
            return 'NMS (NQ/CQS)';
          case 'Spread':
            return ViewLabels.Spread;
          case 'OTCForeign':
            return 'OTC-Foreign';
          default:
            return value;
        }
      }

      default: {
        if (value === null || value === '' || value === undefined) {
          return FINRA_GRID_NULL_VALUE;
        }
        return value;
      }
    }
  }
}
