/**
 * MSRB Markup Markdown Analysis Report
 */

import {
  addFinraGridColumnId,
  finraGridNumericValueFormatter,
  finraGridPercentValueFormatter,
} from 'src/app/shared/utils/utils';

export interface MsrbMarkupMarkdownData {
  dollarTradedAmount: string;
  // firm
  numberOfTradesEvaluated: number;
  meanFirm: number;
  medianFirm: number;
  // industry
  meanIndustry: number;
  medianIndustry: number;
  p25: number;
  p50: number;
  p75: number;
  p80: number;
  p90: number;
  p95: number;
  p99: number;
}

// AG-GRID column definitions //

/**
 * @see: https://reportcenter.qa.finra.org/scrc/view_rpt.do?rptId=5452181&viewId=708&bgurl=show_rpt&backCatID=3&auditAction=2
 */

export const FIRM_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Dollar Traded Amount',
    valueGetter: params =>
      (<MsrbMarkupMarkdownData>params.data).dollarTradedAmount
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Number of Trades Evaluated',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<MsrbMarkupMarkdownData>params.data).numberOfTradesEvaluated,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Mean',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).meanFirm,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Median',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).medianFirm,
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Mean',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<MsrbMarkupMarkdownData>params.data).meanIndustry,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Median',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<MsrbMarkupMarkdownData>params.data).medianIndustry,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P25',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).p25,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P50',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).p50,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P75',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).p75,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P80',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).p80,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P90',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).p90,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P95',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).p95,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P99',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params => (<MsrbMarkupMarkdownData>params.data).p99,
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  }
]);
