<div id="admin-report-explorer-container" #explorerContainer>
  <report-loader></report-loader>

  <h1 class="title pdf">{{ title }}</h1>

  <section class="description-section">
    <p>
      This tool is designed to show the firms currently regulated by your organization (refreshed daily) that
      had a report card during the most recent reporting period (per Report Type selected below).
      To see if the same firms had reports in previous periods, change the Period in the menu below.
      If you wish to review a particular firm’s report, click on View Report;
      the report will display in a new browser tab.
    </p>
    <p>
      Note: You may need to temporarily disable any pop-up blocker software you might be using.
    </p>
  </section>

  <section>
    <form
      [formGroup]="searchFirmByReportTypeForm"
      class="form-container white-box"
      (ngSubmit)="searchFirmByReportTypeForm.valid && onSubmitForm()"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <!-- categories input -->
        <div fxFlex="30" class="box-pad-1">
          <span class="typography-1">Categories</span>
          <mat-form-field class="form-field-block">
            <mat-select formControlName="categoryId">
              <mat-option
                *ngFor="let category of reportCategories"
                [value]="category.reportCategoryId"
              >
                {{ category.categoryDisplayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- report type input -->
        <div fxFlex="45" class="box-pad-1">
          <span
            class="typography-1"
            [ngClass]="{ 'text-grey': !searchFirmByReportTypeForm.value.categoryId }"
          >
            Report Types
          </span>
          <mat-form-field class="form-field-block">
            <mat-select
              formControlName="reportType"
            >
              <mat-option
                *ngFor="let reportType of reportTypesListFiltered"
                [value]="reportType"
                [attr.data-report-config-id]="reportType.rptCfgId"
                [attr.title]="reportType.rptTypeNm + ', ' + 'Edition ' + reportType.rptEdtn + ', ' + 'Config Id ' + reportType.rptCfgId"
              >
                {{ reportType.rptTypeNm }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- period input -->
        <div fxFlex="25" class="box-pad-1">
          <span
            class="typography-1"
            [ngClass]="{ 'text-grey': !searchFirmByReportTypeForm.value.reportType }"
          >
            Report Periods
          </span>
          <ng-container [ngSwitch]="!!isDailyReport">
            <!-- Is Daily Report -->
            <mat-form-field class="form-field-block" *ngSwitchCase="true">
              <mat-label>Choose Report Period</mat-label>
              <input
                matInput
                formControlName="datepickerperiod"
                [matDatepicker]="picker"
                [matDatepickerFilter]="matDatepickerFilter"
                (dateInput)="onDateInput('input', $event)"
              />
              <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="searchFirmByReportTypeForm.value.reportType">
                <mat-icon matDatepickerToggleIcon>
                  <fa-icon [icon]="faCalendarAlt" class="fa-lg"></fa-icon>
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker [disabled]="false"></mat-datepicker>
            </mat-form-field>

            <!-- Not Daily Report -->
            <mat-form-field class="form-field-block" *ngSwitchCase="false">
              <mat-select
                *ngSwitchCase="false"
                class="period-select"
                formControlName="reportPeriod"
              >
                <!--
                  Making the value of this form an object instead of a string
                  since each object has the versions associated with the period;
                  it's easier to find
                -->
                <mat-option
                  *ngFor="let period of periodsList"
                  [value]="period"
                >
                  {{ period.rptPrdDt | finraValidValue : 'date-month-year' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </form>
  </section>

  <report-error-message [errorMessage]="errorMessage"></report-error-message>

  <ng-container *ngIf="!errorMessage && paginator">
    <div class="help-icon-container">
      <fa-icon
        data-html2canvas-ignore
        title="Show filter info modal"
        [icon]="faQuestionCircle"
        class="fa-lg question-icon"
        (click)="showFilterInfoModal()"
      ></fa-icon>
    </div>
    <section class="white-box-1">
      <form [formGroup]="filterForm" fxLayout="row" fxLayoutAlign="start center">
        <!-- CRD filter -->
        <div [fxFlex]="10" class="box-pad-1">
          <span class="typography-1">
            CRD #
            <!-- <fa-icon [icon]="faFilterIcon"
              class="cursor-pointer"
              [ngClass]="{ 'fa-lock-active': currentSort === 'crdid' }"
              (click)="setSort('crdid')"
            ></fa-icon>  -->
          </span>
          <mat-form-field class="form-field-block">
            <input matInput placeholder="Search on CRD #" formControlName="crdid" />
          </mat-form-field>
        </div>

        <!-- Firm Id filter -->
        <div fxFlex="10" class="box-pad-1" *ngIf="!isCrdReport">
          <span class="typography-1">
            {{ firmIdColumnName }}
            <!-- <fa-icon [icon]="faFilterIcon"
              class="cursor-pointer"
              [ngClass]="{ 'fa-lock-active': currentSort === 'firmId' }"
              (click)="setSort('firmId')"
            ></fa-icon>  -->
          </span>
          <mat-form-field class="form-field-block">
            <input matInput [placeholder]="'Search by ' + firmIdColumnName" formControlName="mpId" />
          </mat-form-field>
        </div>

        <!-- MSRB Number filter -->
        <div fxFlex="10" class="box-pad-1" *ngIf="isMsrbReportAndIsMpidBased">
          <span class="typography-1">
            MSRB Number
            <!-- <fa-icon [icon]="faFilterIcon"
              class="cursor-pointer"
              [ngClass]="{ 'fa-lock-active': currentSort === 'msrbNumber' }"
              (click)="setSort('msrbNumber')"
            ></fa-icon>  -->
          </span>
          <mat-form-field class="form-field-block">
            <input matInput placeholder="Search by MSRB Number" formControlName="msrbNumber" />
          </mat-form-field>
        </div>

        <div [fxFlex]="isMsrbReportAndIsMpidBased ? 45 : !isCrdReport ? 65 : 55" class="box-pad-1">
          <span class="typography-1">
            Firm Name
            <!-- <fa-icon [icon]="faFilterIcon"
              class="cursor-pointer"
              [ngClass]="{ 'fa-lock-active': currentSort === 'firmName' }"
              (click)="setSort('firmName')"
            ></fa-icon>  -->
          </span>
          <mat-form-field class="form-field-block">
            <input matInput placeholder="Search on Firm Name" formControlName="firmName"/>
          </mat-form-field>
        </div>
        <div fxFlex="25" class="box-pad-1" *ngIf="!!paginator">
          <span class="typography-1">
            {{ paginatorState.startNum }} - {{ paginatorState.endNum }} of {{ paginatorState.copyArrayObject.length }} items
            <span>
              <fa-icon [icon]="faStepBackward" class="paginator-icon fa-sm" (click)="setPage(1)"></fa-icon>
              <fa-icon [icon]="faCaretLeft" class="paginator-icon fa-lg" (click)="setPrev()"></fa-icon>
            </span>
            Page
            <input
              id="page-number-input"
              type="number"
              [value]="paginatorState.page"
              [min]="1"
              [max]="paginatorState.pages"
              (input)="onPageNumber($event)"
            />
            of {{ paginatorState.pages }}
            <span>
              <fa-icon [icon]="faCaretRight" class="paginator-icon fa-lg" (click)="setNext()"></fa-icon>
              <fa-icon [icon]="faStepForward" class="paginator-icon fa-sm" (click)="setPage(paginatorState.pages)"></fa-icon>
            </span>
          </span>
          <mat-form-field class="form-field-block ghost">
            <mat-label>Choose Page</mat-label>
            <mat-select
              [disabled]="!searchFirmByReportTypeForm.value.categoryId"
              (selectionChange)="setPage($event.value)"
            >
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <!-- data list -->
      <div *ngFor="let firm of firmsListFiltered" fxLayout="row" fxLayoutAlign="start center">
        <!-- CRD -->
        <div fxFlex="10" class="box-pad-1">
          <span class="typography-1">{{ firm.crdid }}</span>
        </div>
        <!-- mpId -->
        <div fxFlex="10" class="box-pad-1" *ngIf="!isCrdReport && !isMsrbBasedReport">
          <span class="typography-1">{{ firm.mpId }}</span>
        </div>
        <!-- MSRB Number -->
        <div fxFlex="10" class="box-pad-1" *ngIf="isMsrbBasedReport || isMsrbReportAndIsMpidBased">
          <span class="typography-1">{{ firm.msrbNumber }}</span>
        </div>

        <div [fxFlex]="isMsrbReportAndIsMpidBased ? 45 : !isCrdReport ? 65 : 55" class="box-pad-1">
          <span class="typography-1">{{ firm.firmName }}</span>
        </div>
        <div fxFlex="25" class="box-pad-1">
          <p>
            <span
              (click)="openReportPage(firm)"
              [attr.data-firm-name]="firm.firmName"
              [attr.data-firm-id]="firm.firmId"
              [attr.data-crd-id]="firm.crdid"
              class="typography-1 view-report-link"
            >
              View Report
            </span>
          </p>
        </div>
      </div>
    </section>
  </ng-container>

  <hr/>

  <section class="report-main-content-padding report-disclaimer pdf"></section>

</div>
