import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import {
  PlainObject,
  getPrior12Months,
  getPrior12Quarters,
  sort,
  VIEW_NAME_WILDCARD,
} from '../shared/utils/utils';
import { EQUITY_REPORT_IDENTIFIERS } from 'src/app/configs/model/equities-report.model';
import { Router } from '@angular/router';
import {
  RATINGS as TRACE_RATINGS,
  MATURITIES as TRACE_MATURITIES,
  PRODUCTS as TRACE_PRODUCTS
} from 'src/app/configs/model/trace.model';
import { MSRB_RATINGS, MSRB_MATURITIES } from 'src/app/configs/model/msrb/msrb.model';
import { SidenavigationService } from './sidenavigation.service';
import { mainNavItems } from 'src/app/configs/model/sidenavigation';
import { DatePipe } from '@angular/common';
import { Observable, of, forkJoin } from 'rxjs';
import { RiskMonitoringReportNames } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import { DetailDatasetDownloadRequestResponse } from 'src/app/configs/model/home.model';
import { map, catchError, flatMap } from 'rxjs/operators';
import { DqsFilterData } from 'src/app/configs/model/dqs.model';
import { FirmInfo } from '../configs/model/firm-info.model';
import { ReportInstanceMetadata } from '../configs/model/reports.model';
import { UserProfile } from '../configs/model/user-profile.model';
import { FirmInfoService } from './firm-info.service';
import { ReportsService } from './reports.service';
import { UserProfileService } from './user-profile.service';

export const GLOBAL_REPORT_DISCLAIMER =
  '* If this symbol appears on your report, this indicates that no data has ' +
  'been provided to FINRA for this value for the specified period.' +
  '\n\n' +
  'This is an expected behavior for weekday holidays but may also indicate ' +
  'information that was not sent, incorrectly formatted, or late.';

export const GLOBAL_REPORT_DISCLAIMER_2 =
  'This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. ' +
  'Information provided does not necessarily indicate a rule violation or issue. Member firms should make no ' +
  'inference that the staff of FINRA has or has not determined that the information contained on this report does or ' +
  'does not constitute rule violations or that the data has been accurately reported to FINRA.';

export interface ReportPageMetadataInfo {
  firmInfoList: FirmInfo[];
  firmName: string;
  firmId: string;
  userProfile: UserProfile;
  isExternalUser: boolean;
  reportInstanceMetadata: ReportInstanceMetadata;
  reportInstanceMetadatas: Array<ReportInstanceMetadata>;
  periods: Array<string>;
  versions: Array<number>;
  view?: string;
  views: Array<string>;
}

export interface ReportFileNames {
  summaryFileName: string;
  detailsFileName: string;
  exportedDateTime: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private baseUrl: string;

  constructor(
    @Inject('environment') environment,
    private http: HttpClient,
    private router: Router,
    private sidenavService: SidenavigationService,
    private reportsService: ReportsService,
    private userProfileService: UserProfileService,
    private firmInfoService: FirmInfoService,
    private datePipe: DatePipe,
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getReport(reportId, viewName, dataType, reportDate?): Observable<PlainObject> {
    const url = this.getReportUrl(reportId, viewName, dataType);
    if (reportDate) {
      // Passing query param report date for month Year cases if it exists
      // Only valid for BE, RegNMS and MktOrder summary reports
      const params = new HttpParams().set('additionalInfo', reportDate);
      return this.http.get(url, { params: params });
    }
    const value = this.http.get(url);
    return value;
  }

  getReportFileName(reportId, viewName, dataType, additionalInfo?: string): Observable<{ fileName: string; }> {
    const url = this.getReportFileNameUrl(reportId, viewName, dataType);
    if (additionalInfo) {
      const params = new HttpParams().set('additionalInfo', additionalInfo);
      return this.http.get<{ fileName: string; }>(url, { params: params });
    }
    const value = this.http.get<{ fileName: string; }>(url);
    return value;
  }

  getMsrbMarkUpMarkDownReport(
    reportId,
    viewName,
    dataType,
    additionalInfoParams: {
      incomingRatingIndicator: string;
      incomingMaturityIndicator: string;
    }
  ) {
    const url = this.getReportUrl(reportId, viewName, dataType);
    const params = JSON.stringify(additionalInfoParams);
    const api_url = url + '?' + 'additionalInfo=' + encodeURI(params);
    const value = this.http.get(api_url);
    return value;
  }

  // DDWA-3091 Summary call with additionalInfo params for trace markup markdown report
  getReportByAdditionalInfo(reportId, viewName, dataType, additionalInfo): Observable<object> {
    const url = this.getReportUrl(reportId, viewName, dataType);
    const params = new HttpParams().set(
      'additionalInfo',
      JSON.stringify(additionalInfo)
    );
    return this.http.get(url, { params: params });
  }

  getReportFileNameByAdditionalInfo(reportId, viewName, dataType, additionalInfo): Observable<{ fileName: string; }> {
    const url = this.getReportFileNameUrl(reportId, viewName, dataType);
    const params = new HttpParams().set(
      'additionalInfo',
      JSON.stringify(additionalInfo)
    );
    return this.http.get<{ fileName: string; }>(url, { params: params });
  }

  getPeerGroupData(reportId, viewName, monthStartDate, tierCode?): Observable<object> {
    const url = this.getReportUrl(reportId, viewName, 'peer');
    const additionalInfo = { monthStartDate, tierCode };
    const params = new HttpParams().set(
      'additionalInfo',
      JSON.stringify(additionalInfo)
    );
    return this.http.get(url, { params: params });
  }

  getReportUrl(
    reportId: number,
    viewName: string = 'All',
    dataType: string = 'D',
    additionalInfo: string = null
  ): string {
    let url = `${this.baseUrl}reportData/reportId/${reportId}/viewName/${viewName}/dataType/${dataType}`;
    if (additionalInfo) {
      url += '?additionalInfo=' + additionalInfo;
    }
    return url;
  }

  getReportFileNameUrl(
    reportId: number,
    viewName: string = 'All',
    dataType: 's' | 'd' = 'd',
    additionalInfo: string = null
  ): string {
    let url = `${this.baseUrl}reportFileNames/reportId/${reportId}/viewName/${viewName}/dataType/${dataType}`;
    if (additionalInfo) {
      url += '?additionalInfo=' + additionalInfo;
    }
    return url;
  }

  getProblemMessage(reportId): Observable<object> {
    const url = `${this.baseUrl}rptProblems/rptId/${reportId}`;
    return this.http.get(url);
  }

  getUserReportDetailDownloadStatuses(): Observable<DetailDatasetDownloadRequestResponse> {
    // adding ignorelist=true to stop the loader/spinner from showing for every call
    const api_url = `${this.baseUrl}userDetailDatasetDownloadRequests`;
    return this.http.get<DetailDatasetDownloadRequestResponse>(api_url, {
      params: {
        cache: 'false',
        ignorelist: 'true'
      }
    }).pipe(
      map((response) => {
        // do extra processing here if needed.
        const responseSorted = sort(
          response.userDetailDatasetDownloadRequests,
          'detailDatasetRequestId',
          true
        );
        return {
          userDetailDatasetDownloadRequests: responseSorted
        } as DetailDatasetDownloadRequestResponse;
      })
    );
  }

  /**
   * @description Backend deletes upon request
   * @param {number} detailDatasetRequestId
   */
  deleteUserReportDetailDownloadStatus(body: {
    reportId: number | undefined,
    requestId: number | undefined,
    reportConfigurationViewId: number,
  }) {
    // adding ignorelist=true to stop the loader/spinner from showing for every call
    const api_url = `${this.baseUrl}detailDatasetDownloadRequestUser/delete`;
    return this.http.put<{ recordsDeleted: number; }>(api_url, body);
  }

  getReportFileNames(
    reportId: number,
    viewName: string,
    dataType: 's' | 'd' = 'd',
    additionalInfo: string = null
  ) {
    let api_url = `${this.baseUrl}reportFileNames/reportId/${reportId}/viewName/${viewName}/dataType/${dataType}`;
    if (additionalInfo) {
      api_url += '?additionalInfo=' + additionalInfo;
    }
    return this.http.get<ReportFileNames>(api_url).pipe(
      map((response) => {
        // do extra processing here if needed.
        return response;
      })
    );
  }

  /**
   * Fill Gap Months
   * ---
   * This method is for filling in period gaps within a report summary response data
   * whose report period is on the monthly basis.
   *
   * The method works by getting the period of the first item in the `dataList` array,
   * assuming that is the latest period. It uses the `getPrior12Months` utility to get the
   * prior 12 months from the given date. Then it loops through the prior months. In the loop,
   * the method tries to find if a report in the `dataList` has a period that matches the date (string format) of the current
   * month iteration: If there is, push it to the list; if not, push an empty object with a period key of the current month iteration.
   * The method also accepts a third argument, which is a function.
   * The method can use that function that the caller provided to transform the gap object before it is put in the list.
   *
   * NOTE:
   * The format of the report period that the `periodProp` key points to should be in one of the following:
   * - `yyyy-mm-dd`
   * - `mm-dd-yyyy`
   *
   * The following formats won't work:
   * - any date string that has a time zone in it. example: `"2019-10-04T15:17:17.085Z"`
   * - dates with the actual month name. example: `"April-2019"`
   *
   * It won't work because the `getPrior12Months` wasn't defined to parse those formats.
   * The backend should be consistent across all reports anyways and `yyyy-mm-dd` is what was used first
   * so it is best to stick with it. If for some reason a report returns an unknow format,
   * it will have to be converted before using this method, like so:
   * @example
   * - const convertedResponsePeriod = someMethodThatConvertsPeriod(response, 'periodKey');
   * - const newData = this.baseReportService.fillGapMonths(convertedResponsePeriod, 'periodKey');
   *
   * @param dataList the list of summary data
   * @param periodProp the key that points to the report period date
   * @returns {object[]} the formated data list.
   */
  fillGapMonths(
    dataList: object[],
    periodProp: string,
    transformGapObj?: (arg: object) => void
  ): object[] {
    if (!dataList) {
      // `no list was given...`
      return;
    }
    if (!dataList.length) {
      return [];
    }
    const monthsList = getPrior12Months(dataList[0][periodProp], true);
    const list = [];
    monthsList.forEach(m => {
      const data = dataList.filter(i => i[periodProp] === m.dateStr)[0];
      if (data) {
        list.push({ ...data });
      } else {
        const gap = { [periodProp]: m.dateStr };
        if (transformGapObj) {
          transformGapObj(gap);
        }
        list.push(gap);
      }
    });
    return list;
  }

  /**
   * Fill Gap Quarters
   * ---
   * nearly identical to the `fillGapMonths`, but done on a quarterly basis.
   *
   * @see {fillGapMonths}
   *
   * @param dataList the list of summary data
   * @param periodProp the key that points to the report period date
   * @returns {object[]} the formated data list.
   */
  fillGapQuarters(
    dataList: object[],
    periodProp: string,
    transformGapObj?: (arg: object) => void
  ): object[] {
    if (!dataList) {
      // `no list was given...`
      return;
    }
    if (!dataList.length) {
      return [];
    }
    const quartersList = getPrior12Quarters(dataList[0][periodProp]);
    const list = [];
    quartersList.forEach(q => {
      const data = dataList.filter(i => i[periodProp] === q.dateString)[0];
      if (data) {
        list.push({ ...data });
      } else {
        const gap = { [periodProp]: q.dateString };
        if (transformGapObj) {
          transformGapObj(gap);
        }
        list.push(gap);
      }
    });
    return list;
  }

  navigateToReportDetails(
    params: {
      report: ReportInstanceMetadata,
      firmId?: string,
      viewName?: string,
      rating?: string,
      maturity?: string,
      product?: string,
      getPathUrl?: boolean
      dqsFilter?: DqsFilterData
    }
  ) {
    // Extracting parameters to pass to Equity Component via routing
    const reportId = params.report.reportId;
    const version = params.report.reportDataVersion;
    const typeId = params.report.reportConfiguration.reportType.reportTypeId;
    const reportCategoryId =
      params.report.reportConfiguration.reportType.reportCategoryId;
    const reportName =
      params.report.reportConfiguration.reportType.reportTypeShortName;
    const defaultView = this.getDefaultView(params.report);
    const view = (!params.viewName || params.viewName === VIEW_NAME_WILDCARD)
      ? defaultView
      : params.viewName;
    const categoryPath = this.getReportPath(reportCategoryId);

    let pathList;

    if (reportCategoryId === 13) {
      if (params.dqsFilter) {
        pathList = [
          '/reports/' + categoryPath + '/report-card-view/firm',
          params.firmId,
          'view',
          view,
          'exam-year',
          params.dqsFilter.examYear,
          'exam-id',
          params.dqsFilter.examId,
          'request-id',
          params.dqsFilter.requestId,
        ];
      } else {
        pathList = [
          '/reports/' + categoryPath + '/report-card-view',
          params.firmId,
          view
        ];
      }
    } else if (reportName === 'traceqmrcmumd') {
      // DDWA-3091 Handling route with additionalInfo params for trace markup markdown report
      pathList = [
        '/reports/' + categoryPath + '/report-card-view',
        reportId,
        version,
        typeId,
        view,
        reportName,
        (params.rating || TRACE_RATINGS[0].value),
        (params.maturity || TRACE_MATURITIES[0].value),
        (params.product || TRACE_PRODUCTS[0].value),
      ];
    } else if (reportName === 'msrbmumd') {
      // DDWA-3091 Handling route with additionalInfo params for trace markup markdown report
      pathList = [
        '/reports/' + categoryPath + '/report-card-view',
        reportId,
        version,
        typeId,
        view,
        reportName,
        (params.rating || MSRB_RATINGS[0].value),
        (params.maturity || MSRB_MATURITIES[0].value),
      ];
    } else {
      pathList = [
        '/reports/' + categoryPath + '/report-card-view',
        reportId,
        version,
        typeId,
        view,
        reportName
      ];
    }

    if (params.getPathUrl) {
      const path = pathList.join('/');
      return path;
    }

    this.router.navigate(pathList);

    // Set sidenav state
    this.reportsService.getReportCategories().subscribe(response => {
      const navigationItem = mainNavItems['reports'];
      const child = response.reportCategories.find(
        c => c.reportCategoryId === reportCategoryId
      );

      this.sidenavService.setNavState({
        navigationItem,
        child
      });
    });
  }

  /* Set default views for various report categories here */
  getDefaultView(report: ReportInstanceMetadata): string {
    const views = report.reportConfiguration.reportConfigurationViews;
    const thereAreNoViews = views.length === 0;
    if (thereAreNoViews) {
      return null;
    }

    const reportName =
      report.reportConfiguration.reportType.reportTypeShortName;
    let defaultViewName: string = null;

    switch (report.reportConfiguration.reportType.reportCategoryId) {
      // TRACE
      case 1:
        defaultViewName = 'All';
        // DDWA-4088 Default view Mark Up
        if (reportName === 'traceqmrcmumd') {
          defaultViewName = 'Mark-Up';
        }
        break;
      // MSRB
      case 3:
        if (reportName === 'MUFSR') {
          defaultViewName = 'Summary';
          // DDWA-4220 Due Diligence Summary default view.
        } else if (reportName === 'MDDR') {
          defaultViewName = 'MSRBDueDiligenceRepSummary';
        } else if (reportName === 'mcd') {
          defaultViewName = 'All';
        }
        break;
      // Equity
      case 4:
        defaultViewName = this.getEquityDefaultView(reportName);
        break;
      case 6:
        defaultViewName = this.getRiskMonitoringDefaultView(reportName);
        break;
      break;
      // CorpFin
      case 10:
        if (reportName === 'corpfinlatefilings') {
          defaultViewName = 'Lead';
        }
        break;
      // Options (LOPR)
      case 12:
        defaultViewName = 'All';
        break;
      case 13:
        defaultViewName = views.find(
          x => x.summaryDatasetConfigurationId != null
        ).viewName;
        break;
    }

    const defaultViewIndex = views.findIndex(
      x => x.viewName === defaultViewName
    );
    const viewName = defaultViewName == null || defaultViewIndex === -1
      ? views[0].viewName
      : defaultViewName;

    return viewName;
  }

  getRiskMonitoringDefaultView(reportName: string) {
    switch (reportName) {
      case RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES:
      case RiskMonitoringReportNames.SALES_PRACTICE_COMPLAINT:
        return 'Quarterly';
      case RiskMonitoringReportNames.CUSTOMER_COMPLAINT:
        return 'Problem';
      case RiskMonitoringReportNames.CANCELED_AS_OF_TRADES:
      case RiskMonitoringReportNames.CUSTOMER_DEBITS:
        return 'Monthly';
    }
  }

  getEquityDefaultView(reportName: string): string {
    // Sets default view for on first load of the equity reports
    switch (reportName) {
      case EQUITY_REPORT_IDENTIFIERS.BEST_EXE:
        return 'NMSNQCQS';
      case EQUITY_REPORT_IDENTIFIERS.FIRM_SUM:
        return 'Scorecard';
      default:
        return 'All';
    }
  }

  getReportPath(reportCategoryId: number): string {
    switch (reportCategoryId) {
      case 1:
        return 'trace';
      case 2:
        return 'disclosure';
      case 3:
        return 'msrb';
      case 4:
        return 'equity';
      case 5:
        return 'online-learning';
      case 6:
        return 'risk-monitoring';
      case 8:
        return 'cross-market-supervision';
      case 10:
        return 'corp-fin';
      case 12:
        return 'options';
      case 13:
        return 'file-and-data-quality-scorecard';
    }
  }

  getSelectedReportInstanceMetadata(
    period,
    version,
    reportInstanceMetadatas: ReportInstanceMetadata[],
  ): ReportInstanceMetadata {
    const dateFormat = 'yyyy-MM-dd';
    const dateString = this.datePipe.transform(period, dateFormat);

    const result = reportInstanceMetadatas.find(r => {
      const dateStr = this.datePipe.transform(r.reportPeriodDate, dateFormat);
      const samePeriod = dateStr === dateString;
      const sameVersion = r.reportDataVersion === version;
      const match = samePeriod && sameVersion;
      return match;
    });

    return result;
  }

  /**
   * Get Report Page Metadata Info
   * ---
   *
   * This method consolidates all of the information needed to display the report page.
   * It gathers the following:
   * - user profile
   * - firm info
   * - report instance metadata
   * --- report instance metadata list
   * --- periods going back from the provided `reportId`
   * --- versions of the `reportId`
   * --- views of the report instance metadata
   *
   * @param reportId
   * @param viewName
   * @return Object
   */
  getReportPageMetadataInfo(reportId, viewName): Observable<ReportPageMetadataInfo> {
    // load user porfile
    const userProfileObservable = this.userProfileService.getUserProfile().pipe(
      catchError((error: HttpErrorResponse) => {
        return of(<UserProfile> {});
      })
    );

    // load report instance metadata
    const reportObservable = this.reportsService.getReportInstanceMetadata(reportId).pipe(
      catchError((error: HttpErrorResponse) => {
        throw error;
      })
    );

    let view = '';

    // load firm info and look back periods.
    // pipe from report observable since the firm info and report periods endpoints depend on report instance
    const dataObservable = <Observable<ReportPageMetadataInfo>> reportObservable.pipe(
      // load firm info from report instance
      flatMap((report: ReportInstanceMetadata) => {
        // pass all data to next operator
        return forkJoin([
          userProfileObservable,
          reportObservable,
          this.firmInfoService.getFirmInfo(report.reportFirmId)
        ]);
      }),
      // load report periods from user profile and report instance
      flatMap((values: [UserProfile, ReportInstanceMetadata, FirmInfo[]]) => {
        const userProfile: UserProfile = values[0];
        const report: ReportInstanceMetadata = values[1];
        const firmInfoList: FirmInfo[] = values[2];
        const isExternalUser = this.userProfileService.isExternalUser(userProfile);
        view = !isExternalUser ? null : this.getReportConfigViewId(report, viewName);
        const reportInstanceMetadatasObs = this.reportsService
          .getReportInstanceMetadataByReportTypeAndFirmAndStatus(
            report.reportConfiguration.reportTypeId,
            report.reportFirmId,
            report.reportStateLookup.reportStateDescription,
            view
          );
        // pass all data to next operator
        return forkJoin([
          of(userProfile),
          of(report),
          of(firmInfoList),
          reportInstanceMetadatasObs
        ]);
      }),
      // consolidate all the data and return as observable
      flatMap((values: [UserProfile, ReportInstanceMetadata, FirmInfo[], ReportInstanceMetadata[]]) => {
        const userProfile: UserProfile = values[0];
        const report: ReportInstanceMetadata = values[1];
        const firmInfoList: FirmInfo[] = values[2];
        const reportInstanceMetadatas: ReportInstanceMetadata[] = values[3] && values[3].length
          ? values[3]
          : [report];

        const isExternalUser = this.userProfileService.isExternalUser(userProfile);
        const periods = !!reportInstanceMetadatas.length
          ? this.getReportPeriods(reportInstanceMetadatas)
          : this.getReportPeriods([report]);
        const versions = (!!report.reportId && !!reportInstanceMetadatas.length)
          ? this.getReportVersions(report.reportPeriodDate, reportInstanceMetadatas)
          : this.getReportVersions(report.reportPeriodDate, [report]);
        const views = !!report.reportId
          ? this.getReportViews(report)
          : [];

        const firmName = !!firmInfoList[0]
          ? firmInfoList[0].firmName
          : '';
        // DDWA-5012 - just use what was in the report instance metadata
        const firmId = report.reportFirmId;
        const currentView = view || (viewName !== VIEW_NAME_WILDCARD && viewName) || views[0];

        const reportPageMetadataInfo: ReportPageMetadataInfo = {
          view: currentView,
          firmInfoList,
          firmName,
          firmId,
          userProfile,
          isExternalUser,
          reportInstanceMetadatas,
          periods,
          versions,
          views: views.sort(),
          reportInstanceMetadata: report,
        };

        console.log(`%c Finished loading report page info.`, `color: #0082d1`);
        console.log({ reportPageMetadataInfo });
        return of(reportPageMetadataInfo);
      })
    );
    return dataObservable;
  }

  createSummaryReportExportAudit(reportId: number | string, viewName: string) {
    return this.http.post(`${this.baseUrl}auditActions/createSummaryReportExportAuditAction/reportId/${reportId}/viewName/${viewName}`, {});
  }

  getReportPeriods(reports: ReportInstanceMetadata[]): string[] {
    if (!reports.length) {
      return [];
    }
    const periods = reports
      .map(x => x.reportPeriodDate)
      .filter((value, index, array) => array.findIndex(x => x === value) === index);
    return periods;
  }

  getReportVersions(period: string, reports: ReportInstanceMetadata[]): number[] {
    if (!period) {
      return [];
    } else if (!reports.length) {
      return [];
    }
    const versions = period == null
      ? []
      : reports
          .filter(x => x.reportPeriodDate === period)
          .map(x => x.reportDataVersion)
          .filter((c, index, array) => array.findIndex(x => x === c) === index)
          .sort();
    return versions;
  }

  getReportViews(report: ReportInstanceMetadata): string[] {
    if (!report) {
      return [];
    }
    const views = report == null
      ? []
      : report.reportConfiguration.reportConfigurationViews
        .map(x => x.viewName);
    return views;
  }

  private getReportConfigViewId(report: ReportInstanceMetadata, viewName: string): string {
    const view = report.reportConfiguration.reportConfigurationViews.find(view => view.viewName === viewName);
    const configIdString = view
      ? view.reportConfigurationViewId.toString()
      : (
          viewName === VIEW_NAME_WILDCARD
            ? report.reportConfiguration.reportConfigurationViews[0].reportConfigurationViewId.toString()
            : null
        );
    return configIdString;
  }
}
