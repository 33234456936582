// import { environment } from 'src/environments/environment';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationAnnouncement, Announcements } from '../configs/model/announcements.model';
import { HttpStatusCode } from '../configs/model/http-status-codes.model';
import { AppEnvironment } from '../interfaces/environment.interface';
import { multiDeepSort } from '../shared/utils/utils';
import { UserProfileService } from './user-profile.service';



export enum AnnouncementsApiUrls {
  getAnnoucements = 'announcement',
  announcementContent = 'notification/contentType/'
}

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {
  private baseUrl: string;

  constructor(
    @Inject('environment') private environment: AppEnvironment,
    private userProfileService: UserProfileService,
    private http: HttpClient
  ) {
    this.baseUrl = this.environment.ddwaBaseUrl;
  }

  getNotificationAnnouncements(): Observable<Array<NotificationAnnouncement>> {
    const endpoint = `${this.environment.notificationsAnnouncementUrl}/v3/notifications`;
    const useParams = this.userProfileService.isLocationISSO()
      ? { // ISSO
          sourceApplication: `Report Center`,
          expired: 'false',
          publishedStatus: 'PUBLISHED',
          internalStatus: 'OPEN',
          // custom
          ignoreHeaders: 'X-XSRF-TOKEN,Content-Type',
          ignorelist: 'true',
        }
      : { // EWS
          sourceApplication: `Report Center`,
          // custom
          ignoreHeaders: 'X-XSRF-TOKEN,Content-Type',
          ignorelist: 'true',
        }

    return this.http.get(endpoint, {
      observe: 'response',
      params: useParams
    }).pipe(
      map((response: HttpResponse<Array<NotificationAnnouncement>>) => {
        return this.handleNotificationAnnouncements(response);
      })
    );
  }

  handleNotificationAnnouncements(
    response: HttpResponse<Array<NotificationAnnouncement>>
  ): Array<NotificationAnnouncement> {
    if (response.status === HttpStatusCode.NO_CONTENT) {
      return [];
    }
    const today = new Date();
    // get response body; default to empty list. then filter
    const announcements = (response.body || []).filter((announcement) => {
      const isActive = (new Date(announcement.activeDate)) <= today;
      return isActive;
    });
    // apply sorting
    const value = multiDeepSort(announcements, [
      { prop: 'urgencyIndicator', desc: true }, // sort by urgency first
      { prop: 'activeDate', type: 'date', desc: true } // then by active date
    ]);
    return value;
  }

  getNotificationAnnouncementBody(
    announcement: NotificationAnnouncement
  ): Observable<Array<NotificationAnnouncement>> {
    const endpoint = `${this.environment.notificationsAnnouncementUrl}/v3/notifications/details`;
    const params = {
      ignorelist: 'true',
      id: announcement.id,
      ignoreHeaders: 'X-XSRF-TOKEN,Content-Type',
    };

    if (this.userProfileService.isLocationISSO()) {
      params['render'] = 'true';
    }

    return this.http.get<Array<NotificationAnnouncement>>(endpoint, {
      params: params,
    });
  }

  getAnnoucements() {
    return this.http.get<Announcements[]>(
      this.baseUrl + AnnouncementsApiUrls.getAnnoucements
    );
  }

  getAnnouncementContent(contentType, shouldCache: boolean = true) {
    const url = `${this.baseUrl +
      AnnouncementsApiUrls.announcementContent +
      contentType}` + (!shouldCache ? `?cache=false` : ``);
    return this.http.get(url);
  }

  updateAnnouncementContent(contentType, body) {
    const url = `${this.baseUrl +
      AnnouncementsApiUrls.announcementContent +
      contentType}`;
    return this.http.put(url, body);
  }
}
