import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  DqsSummaryDataResponse,
  DqsFilterDataResponse,
  DqsReportConfiguration,
  DqsReportConfigurationResponse
} from 'src/app/configs/model/dqs.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DqsService {
  baseUrl: string;
  NO_PERIOD_DATA_MESSAGE: any;

  constructor(
    @Inject('environment') environment,
    private http: HttpClient
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getReportConfiguration(): Observable<DqsReportConfigurationResponse> {
    const url = `${this.baseUrl}reportConfigurations/dqsReportConfiguration`;
    return this.http.get<DqsReportConfiguration>(url).pipe(
      map(data => {
        return [
          {
            reportDataVersion: 0,
            reportPeriodDate: '',
            reportConfiguration: data,
            periodicities: []
          }
        ];
      })
    );
  }

  getDataByFirmId(firmId: string) {
    const url = `${this.baseUrl}dqsdata/filters?firmId=${firmId}`;
    return this.http.get<DqsFilterDataResponse>(url);
  }

  getSummaryData(
    firmId: string,
    requestId: string,
    viewName: string
  ) {
    const url = `${
      this.baseUrl
    }dqsdata/readData/requestId/${requestId}/viewName/${viewName}/dataType/s?firmId=${firmId}`;
    return this.http.get<DqsSummaryDataResponse>(url);
  }

  getDqsData<T>(
    firmId: string,
    requestId: string,
    viewName: string,
    dataType: string = 's'
  ): Observable<T> {
    const url = `${
      this.baseUrl
    }dqsdata/readData/requestId/${requestId}/viewName/${viewName}/dataType/${dataType}?firmId=${firmId}`;
    return this.http.get<T>(url);
  }

  getDetailsDataUrl(
    firmId: string,
    requestId: string,
    viewName: string
  ): string {
    const url = `${
      this.baseUrl
    }dqsdata/readData/requestId/${requestId}/viewName/${viewName}/dataType/d?firmId=${firmId}`;
    return url;
  }
}
