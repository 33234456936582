import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  IconDefinition,
  faQuestionCircle
} from '@fortawesome/pro-regular-svg-icons';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { RiskMonitoringReportNames } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';


@Component({
  selector: 'report-customer-complaint',
  templateUrl: './customer-complaint-report.component.html',
  styleUrls: ['./customer-complaint-report.component.scss']
})
export class CustomerComplaintReportComponent implements OnInit, OnChanges {
  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  @Input() viewName: string;
  @Input() quartersHeadings: string[] = [];
  @Input() rowData = [];
  @Input() totalsObj = {};
  @Input() allOtherComplaintsObj = {};
  @Input() industryComplaintsRowData = [];
  @Input() complaintsAsscIndv;
  @Input() complaintsRepsRelated;

  faQuestionCircle: IconDefinition = faQuestionCircle;

  constructor(
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {}

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {

  }

  sortByDate(arr) {
    arr.sort((x, y) => {
      const a = new Date(x.PERIOD);
      const b = new Date(y.PERIOD);
      if (a < b) {
        return 1;
      } else if (a > b) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  openHelp() {
    this.helpLinksService.open(RiskMonitoringReportNames.CUSTOMER_COMPLAINT);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}
