import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AlertItem } from 'src/app/interfaces/alert-item.interface';

/**
 * Alert Service
 *
 * This service will create a behavior subject for dedicated parts of the entire
 * report center application.
 *
 * The valid streams are illustrated by the `StreamTypes` enum below.
 * This allows for viewing different alerts on different views of the app.
 * Each stream type will also have a queue, keeping track of all the alerts of its kind.
 * When a stream is emitted, it provided an `enum` of the stream to trigger and pass on the alert to the observers.
 */

export enum AlertStreamTypes {
  HOME = 1,
  CBOE,
  CORP_FIN,
  CROSS_MARKET,
  DISCLOSURE,
  DQS,
  EQUITY,
  ONLINE_LEARNING,
  RISK_MONITORING,
  TRACE,
  MSRB,
  OPTIONS,
  TICK_SIZE_PILOT,
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private streamsMap: Map<AlertStreamTypes, BehaviorSubject<AlertItem>> = new Map<AlertStreamTypes, BehaviorSubject<AlertItem>>();

  constructor() {
    // create BehaviorSubject for all alert streams
    const list = Object.keys(AlertStreamTypes).filter(i => !parseInt(i, 10));
    for (const streamName of list) {
      const id: AlertStreamTypes = AlertStreamTypes[streamName];
      const newBehaviorSubject = new BehaviorSubject(null);
      this.streamsMap.set(id, newBehaviorSubject);
    }
  }

  emit(streamType: AlertStreamTypes, alertItem: AlertItem) {
    const behaviorSubject = this.streamsMap.get(streamType);
    behaviorSubject.next(alertItem);
  }

  subscribe(streamType: AlertStreamTypes, callback: (val?: any) => void): Subscription {
    const behaviorSubject = this.streamsMap.get(streamType);
    return behaviorSubject.subscribe(callback);
  }

  resetStream(streamType: AlertStreamTypes) {
    this.streamsMap.delete(streamType);
    const newBehaviorSubject = new BehaviorSubject(null);
    this.streamsMap.set(streamType, newBehaviorSubject);
  }
}
