<div class="disclosure-timeliness-container" *ngIf="rowData">
  <div class="title">{{ title }}</div>
  <div class="table">
    <div class="table-header">
      <div class="table-cell" *ngFor="let header of parentHeaders">
        {{ header }}
      </div>
    </div>
    <ng-container *ngFor="let row of rowData; let i = index">
      <div
        class="table-row"
        [ngClass]="{ 'row-bold': i == rowData.length - 1 }"
      >
        <div class="table-cell">
          <span class="icon-container">
            <fa-icon
              *ngIf="row.filings && row.filings.length"
              [icon]="row.expanded ? faChevronDown : faChevronRight"
              [attr.title]="row.expanded ? 'Hide' : 'Show'"
              class="fa-xs icon-chevron"
              data-html2canvas-ignore
              (click)="row.expanded = !row.expanded"
            ></fa-icon>
          </span>
          {{ row.period | finraValidValue: 'period-avg-text' }}
        </div>
        <div class="table-cell">
          {{ row.associateIndividualCount | finraValidValue: 'number' }}
        </div>
        <div class="table-cell">
          {{ row.firmInitialFilingsCount | finraValidValue: 'number' }}
        </div>
        <div class="table-cell">
          {{ row.firmLateFilingsCount | finraValidValue: 'number' }}
        </div>
        <div class="table-cell">
          {{ row.firmLateFilingsPrct | finraValidValue: 'percent' }}
        </div>
        <div class="table-cell">
          {{ row.firmAmndFilingsCount | finraValidValue: 'number' }}
        </div>
      </div>
      <ng-container *ngIf="row.expanded">
        <div class="table-header child">
          <div class="table-cell" *ngFor="let header of childHeaders">
            {{ header }}
          </div>
          <div class="table-cell"></div>
          <div class="table-cell"></div>
        </div>
        <div class="table-row child" *ngFor="let filing of row.filings">
          <div class="table-cell">{{ filing.flngNumber }}</div>
          <div class="table-cell">{{ filing.discDate | date: 'M/d/yyyy' }}</div>
          <div class="table-cell">{{ filing.subDate | date: 'M/d/yyyy' }}</div>
          <div class="table-cell">{{ filing.dsclrEventDs }}</div>
          <div class="table-cell"></div>
          <div class="table-cell"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
