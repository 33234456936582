<div id="products-problems-grid-container">
  <div class="scroll-x-box">
    <div class="table-header">
      <div class="table-title">
        Complaints by {{ viewName === 'Problem' ? 'Problem' : 'Product' }}
      </div>

      <span class="question-icon-container" (click)="openHelp()">
        <fa-icon
          data-html2canvas-ignore
          [icon]="faQuestionCircle"
          class="fa-lg question-icon"
        ></fa-icon>
        <span>Help</span>
      </span>
    </div>

    <!-- header -->
    <div class="row-header row-container grey-border-bottom-1">
      <div class="col col-20 grey-border-right-1">
        <div class="box-padding-1">{{ viewName === 'Problem' ? 'Problems' : 'Products' }} Reported</div>
      </div>

      <div class="col col-80">
        <div class="row-container col-flex-grow grey-border-bottom-1">
          <!--  -->
          <div
            class="col col-15"
            *ngFor="let heading of quartersHeadings; last as isLastHeading; index as headingIndex"
            [ngClass]="{
              'grey-border-right-1': !isLastHeading
            }"
          >
            <div class="box-padding-1">{{ heading }}</div>
          </div>
        </div>

        <div class="row-container col-flex-grow">
          <!--  -->
          <ng-container *ngFor="let heading of quartersHeadings; last as isLastHeading; index as headingIndex">
            <div class="col col-5 grey-border-right-1">
              <div class="box-padding-1">Count</div>
            </div>
            <div class="col col-5 grey-border-right-1">
              <div class="box-padding-1">Firm %</div>
            </div>
            <div class="col col-5"
              [ngClass]="{
                'grey-border-right-1': !isLastHeading
              }"
            >
              <div class="box-padding-1">Industry %</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <report-products-problems-grid-body
      [rowData]="rowData.slice(0, 5)"
      [viewName]="viewName"
      [quartersHeadings]="quartersHeadings"
    ></report-products-problems-grid-body>

    <!-- All Other Complaints -->
    <section class="row-body row-container grey-border-top-2 grey-border-bottom-1" *ngIf="rowData.length > 5">
      <div class="col col-20 grey-border-right-1">
        <div class="box-padding-1 period-flex-row">
          <div>
            <span class="icon-space-1">
              <fa-icon
                [icon]="showAdditional ? faChevronDown : faChevronRight"
                [attr.title]="(showAdditional ? 'Hide' : 'Show')"
                class="fa-lg cursor-pointer summary-chevron"
                (click)="showAdditional = !showAdditional"
                data-html2canvas-ignore
              ></fa-icon>
            </span>
            <span>All Other Complaints</span>
          </div>
        </div>
      </div>
      <div class="col col-80">
        <div class="row-container col-flex-grow height-full">
          <ng-container *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex">
            <div class="row-container col col-15" [ngSwitch]="!!allOtherComplaintsObj['quarter' + (quarterIndex + 1)]">
              <ng-container *ngSwitchCase="true">
                <div class="col col-5 grey-border-right-1">
                  <div class="box-padding-1">
                    {{ allOtherComplaintsObj['quarter' + (quarterIndex + 1)].totalCount | finraValidValue : 'number' }}
                  </div>
                </div>
                <div class="col col-5 grey-border-right-1">
                  <div class="box-padding-1">
                    {{ allOtherComplaintsObj['quarter' + (quarterIndex + 1)].firmPercentage | finraValidValue : 'percent' }}
                  </div>
                </div>
                <div class="col col-5"
                  [ngClass]="{
                    'grey-border-right-1': !isLastQuarter
                  }"
                >
                  <div class="box-padding-1">
                    {{ allOtherComplaintsObj['quarter' + (quarterIndex + 1)].industryPercentage | finraValidValue : 'percent' }}
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <div class="col col-15"
                  [ngClass]="{
                    'grey-border-right-1': !isLastQuarter
                  }"
                >
                  <div class="box-padding-1">No information available for the period.</div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </section>

    <report-products-problems-grid-body
      *ngIf="showAdditional"
      [rowData]="rowData.slice(5)"
      [viewName]="viewName"
      [quartersHeadings]="quartersHeadings"
    ></report-products-problems-grid-body>

    <!-- Total - Sales Practice -->
    <section *ngIf="viewName === 'Problem'">
      <div class="row-body row-container grey-border-bottom-1 grey-border-top-2">
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1">Total - Sales Practice</div>
        </div>

        <div class="col col-80">
          <div class="row-container row-body col-flex-grow height-full">
            <ng-container *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex">
              <div class="row-container col col-15" [ngSwitch]="!!totalsObj['quarter' + (quarterIndex + 1)]">
                <ng-container *ngSwitchCase="true">
                  <div class="col col-5 grey-border-right-1">
                    <div class="box-padding-1">
                      {{ totalsObj['quarter' + (quarterIndex + 1)].sales.totalCount | finraValidValue : 'number' }}
                    </div>
                  </div>
                  <div class="col col-5 grey-border-right-1">
                    <div class="box-padding-1">
                      {{ totalsObj['quarter' + (quarterIndex + 1)].sales.firmPercentage | finraValidValue : 'percent' }}
                    </div>
                  </div>
                  <div class="col col-5"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">
                      {{ totalsObj['quarter' + (quarterIndex + 1)].sales.industryPercentage | finraValidValue : 'percent' }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div class="col col-15"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">No information available for the period.</div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>

    <!-- Total - Operational -->
    <section *ngIf="viewName === 'Problem'">
      <div class="row-body row-container grey-border-bottom-1">
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1">Total - Operational</div>
        </div>

        <div class="col col-80">
          <div class="row-container row-body col-flex-grow height-full">
            <ng-container *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex">
              <div class="row-container col col-15" [ngSwitch]="!!totalsObj['quarter' + (quarterIndex + 1)]">
                <ng-container *ngSwitchCase="true">
                  <div class="col col-5 grey-border-right-1">
                    <div class="box-padding-1">
                      {{ totalsObj['quarter' + (quarterIndex + 1)].operational.totalCount | finraValidValue : 'number' }}
                    </div>
                  </div>
                  <div class="col col-5 grey-border-right-1">
                    <div class="box-padding-1">
                      {{ totalsObj['quarter' + (quarterIndex + 1)].operational.firmPercentage | finraValidValue : 'percent' }}
                    </div>
                  </div>
                  <div class="col col-5"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">
                      {{ totalsObj['quarter' + (quarterIndex + 1)].operational.industryPercentage | finraValidValue : 'percent' }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div class="col col-15"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">No information available for the period.</div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>

    <!-- Total - Operational -->
    <section>
      <div class="row-body row-container" [ngClass]="{ 'grey-border-top-2': viewName === 'Product' }">
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1">Grand Total</div>
        </div>

        <div class="col col-80">
          <div class="row-container row-body col-flex-grow height-full">
            <ng-container *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex">
              <div class="row-container col col-15" [ngSwitch]="!!totalsObj['quarter' + (quarterIndex + 1)]">
                <ng-container *ngSwitchCase="true">
                  <div class="col col-15"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">{{ totalsObj['quarter' + (quarterIndex + 1)].grandTotalCount | finraValidValue : 'number' }}</div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div class="col col-15"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">No information available for the period.</div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
