import { Injectable } from '@angular/core';

import {
    ClientErrors,
    ServerErrors,
} from './error-statuses.enum';


/**
 * ErrorMessagesService
 * ---
 *
 * This service is just for generic HTTP error messages.
 * The backend is, ideally, supposed to send
 * an error message along with the response
 * because the UI should only be responsible
 * for the Presentation of the application,
 * not the information.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {
    private Client = {
        Default:
            `Could not process request`,

        BadRequest:
            `There was an error with the request sent to the server. 
            Check the inputs and try again.`,
        Unauthorized:
            `Sorry, but you do not have the required authorization. 
            Please contact your application administrator to obtain the required entitlements.`,
        Forbidden:
            `The action you performed is not supported. 
            Please contact the Report Center administrator 
            at reportcenter-admin@finra.org or call FINRA at (301) 869-6699 to get this issue reolved.`,
        NotFound:
            `There is no data available for the selection criteria.  Please update your selection criteria and try again.`,
        MethodNotAllowed: 405,
        NotAcceptable: 406,
        ProxyAuthRequired: 407,
        RequestTimeout: 
            `Sorry, but due to high usage volumes, the system is busy and cannot process your request. 
            Please try again later.`,
        TooManyRequests: `Too many requests. Please try again later.`,
    };

    private Server = {
        Default:
            `Sorry, but the system is currently unavailable. 
            Please try again later. If the issue persists, 
            please contact the Report Center administrator at 
            reportcenter-admin@finra.org or call FINRA at (301) 869-6699.`,

        InternalServerError: 500,
        NotImplemented: 501,
        BadGateway: 502,
        ServiceUnavailable: 503,
        GatewayTimeout: 504,
        HttpVersionNotSupported: 505,
    };

    constructor() {}

    getErrorMessage(status, useDefault: boolean = false) {
        const argumentIsInvalid = (
            status === null ||
            status === undefined ||
            isNaN(status)
        );
        if (argumentIsInvalid) {
            throw new Error(`'status' argument was invalid...`);
        }

        if (status === 0) {
            // CORS error
            return 'Could not complete request...';
        } else if (ClientErrors[status]) {
            return useDefault
                ? this.Client['Default']
                : this.Client[ClientErrors[status]];
        } else if (ServerErrors[status]) {
            // there are no specific servers. use general default for now.
            return this.Server['Default'];
        } else {
            throw new Error(`could not identify/parse 'status' argument...`);
        }
    }

    getClientErrorMessages() {
        return { ...this.Client };
    }

    getServerErrorMessages() {
        return { ...this.Server };
    }
}
