<div class="late-filing-container pdf" *ngIf="rowData">
  <div class="table-header">
    <div class="table-title">{{ title }}</div>

    <span class="question-icon-container" *ngIf="tableName == 'submissionTotals'" (click)="openHelp()">
      <fa-icon
        data-html2canvas-ignore
        [icon]="faQuestionCircle"
        class="fa-lg question-icon"
      ></fa-icon>
      <span>Help</span>
    </span>
  </div>
  <report-finra-grid
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [groupDefaultExpanded]="0"
    [autoGroupColumnDef]="autoGroupColumnDef"
  ></report-finra-grid>
</div>
