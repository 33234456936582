<div class="corp-fin-container">
  <report-loader></report-loader>

  <h1 class="title report-main-content-padding pdf">
    {{ title }}
  </h1>

  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="corpFinToolbarConfig"
    [toolbarConfig]="corpFinToolbarConfig"
  ></report-finra-toolbar>

  <div class="margin-bottom-20"></div>

  <div id="data-container" [ngSwitch]="reportName" *ngIf="reportLoadedSuccessfully">
    <div *ngSwitchCase="'corpfinpublicoffer'">
      <public-offering-report
        *ngFor="let view of publicOfferingReportViews"
        [rowData]="reportData[view]"
        [columnDefs]="publicOfferingColumnDefs[view]"
        [title]="viewTitles[view]"
        [viewName]="view"
        [loading]="loading[view]"
        [autoGroupColumnDef]="autoGroupColumnDefs[view]"
        [helpLink]="helpLink"
        [reportInstanceMetadata]="reportInstanceMetadata"
      ></public-offering-report>
    </div>
    <div *ngSwitchCase="'corpfinppftr'">
      <private-placement-filing-timeliness-report
        *ngIf="rowData && rowData.length"
        [rowData]="rowData"
        [columnDefs]="privatePlacementColumnDefs"
        [reportPeriodDate]="reportPeriodDate"
        [reportInstanceMetadata]="reportInstanceMetadata"
        [rules]="rules"
        [ruleData]="ruleData"
        [chartRuleData]="chartRuleData"
        (reportDetails)="onReportDetails($event)"
      ></private-placement-filing-timeliness-report>
    </div>
    <div *ngSwitchCase="'corpfinlatefilings'">
      <late-filing-report
        *ngFor="let view of lateFilingViews[reportInstanceMetadata.reportConfiguration.reportEdition]"
        [title]="lateFilingTitles[view]"
        [rowData]="reportData[view]"
        [columnDefs]="lateFilingColumnDefs[view]"
        [autoGroupColumnDef]="lateFilingAutoGroupColDefs[view]"
        [reportInstanceMetadata]="reportInstanceMetadata"
        [reportId]="reportId"
        [viewName]="viewName"
        [tableName]="view"
        [downloadFileName]="downloadFileName"
        [helpLink]="helpLink"
        (reportDetails)="onReportDetails($event)"
      ></late-filing-report>
    </div>
  </div>
  <hr />
  <div
    class="report-disclaimer pdf"
    *ngIf="disclaimer"
    [innerHtml]="disclaimer"
  ></div>
</div>
