import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  IconDefinition,
  faLockAlt,
  faBell,
} from '@fortawesome/pro-light-svg-icons';
import {
  faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ICellRendererParams } from 'ag-grid-community';
import { AnnouncementContent, NotificationAnnouncement } from 'src/app/configs/model/announcements.model';
import { DetailDatasetDownloadRequest, DetailDatasetDownloadRequestStatus, GetReportsDowloadColumnDefs, GetUsageLogExportColumnDefs, UsageLogExportRequest } from 'src/app/configs/model/home.model';
import { Reports, ReportInstanceMetadata, Periodicities, DqsFqsNames } from 'src/app/configs/model/reports.model';
import { getUnreadReportsColumnDefs } from 'src/app/configs/model/unread-report.model';
import { UserProfile } from 'src/app/configs/model/user-profile.model';
import { BeastClickActions, BeastOtherActions } from 'src/app/enums/beast.enum';
import { BeastHomePageDqsDetailDownloadInfo, BeastHomePageDetailDownloadInfo, BeastViewUnreadReportInfo } from 'src/app/interfaces/beast.interface';
import { AnnouncementsService } from 'src/app/services/announcements.service';
import { BeastService } from 'src/app/services/beast.service';
import { DetailDatasetRequestsService } from 'src/app/services/detail-dataset-requests.service';
import { DqsService } from 'src/app/services/dqs.service';
import { HelperService } from 'src/app/services/helper.service';
import { ReportService } from 'src/app/services/report.service';
import { ReportsService } from 'src/app/services/reports.service';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { Subscription } from 'rxjs';
import { UsageLogExportRequestsService } from 'src/app/services/usage-log-export-requests.service';
import { AuditActionLookup, UsageLogSearch } from 'src/app/configs/model/usage-log.model';
import { createMapFromObjectProps, makeDateStartOfDay } from 'src/app/shared/utils/utils';
import { UsageLogService } from 'src/app/services/usage-log.service';



@Component({
  selector: 'report-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  reports: any[];
  faLockAlt: IconDefinition = faLockAlt;
  faBell: IconDefinition = faBell;
  faExclamationCircle: IconDefinition = faExclamationCircle;
  template: string = 'card';
  announcement: AnnouncementContent;
  announcements: Array<NotificationAnnouncement> = [];
  announcementsError: boolean = false;
  announcementsErrorMessage: string = `Could not load announcements at this time. Please contact support.`;
  reportsData: Reports[];
  unReadReportsData: ReportInstanceMetadata[];
  clientHeight: number;
  footerHeight: number = 220;
  isExternalUser: boolean = false;
  hasUnreadReportsAccess: boolean = false;
  periodicities: Periodicities[];
  userProfile: UserProfile;
  firmId;
  unreadReportsColumnDefs;
  reportsDownloadsColumnDefs;
  usageLogExportRequestsColumnDefs;
  errorMessage;
  reportsDownloadsData: DetailDatasetDownloadRequest[];
  usageLogExportRequests: UsageLogExportRequest[];
  announcementsScrollTimer; // this acts as a debouncer to prevent dozens of BEAST calls per second for scroll event

  // loading flags
  announcementsLoading: boolean = false;
  announcementsLoadingDelay: boolean = false;
  detailDatasetRequestsInitialLoading: boolean = false;
  usageLogExportRequestsInitialLoading: boolean = false;
  unreadReportsInitialLoading: boolean = false;
  unreadReportsListChangesSub: Subscription;
  auditActionLookups: Array<AuditActionLookup> = [];
  auditActionLookupsById;

  constructor(
    private announcementsService: AnnouncementsService,
    private reportsService: ReportsService,
    private reportService: ReportService,
    private titleService: Title,
    private userProfileService: UserProfileService,
    private helperService: HelperService,
    private uiWidgetsService: UiWidgetsService,
    private detailDatasetRequestsService: DetailDatasetRequestsService,
    private usageLogExportRequestsService: UsageLogExportRequestsService,
    private unreadReportsService: UnreadReportsService,
    private dqsService: DqsService,
    private beastService: BeastService,
    private usageLogService: UsageLogService,
  ) {
    this.clientHeight = window.innerHeight;
    this.titleService.setTitle('Home');
  }

  ngOnInit() {
    this.getAnnouncements();

    const userProfile = this.userProfileService.getProfileObj();
    this.handleUserProfileAndReportsAccess(userProfile);
    this.startEventListeners();
    // get unread reports
    const shouldLoadUnreadReports = this.isExternalUser && this.hasUnreadReportsAccess;
    if (shouldLoadUnreadReports) {
      this.unreadReportsService.getUnReadReports();
    }

    this.usageLogService.getAuditActionLookups().subscribe({
      next: (auditActionLookups) => {
        this.auditActionLookups = auditActionLookups;
        this.auditActionLookupsById = createMapFromObjectProps({
          list: auditActionLookups,
          keyProp: 'auditActionLookupId',
          valueProp: 'auditActionDescription'
        });
        console.log(this.auditActionLookupsById);
      }
    });

    this.periodicities = this.reportsService.getPeriodicitiesObj().periodicities;
    this.initUnreadReportsGrid();
    this.initDetailDatasetGrid();
    this.initUsageLogExportRequestsGrid();
  }

  ngOnDestroy() {
    if (this.unreadReportsListChangesSub) {
      this.unreadReportsListChangesSub.unsubscribe();
    }
  }

  getAnnouncements() {
    this.announcementsLoading = true;
    const announcementsSub = this.announcementsService.getNotificationAnnouncements().subscribe({
      next: (announcements: Array<NotificationAnnouncement>) => {
        this.announcementsLoading = false;
        this.announcements = announcements;
        // get details for each notification immediately
        for (const announcement of this.announcements) {
          this.loadDetailsForAnnouncement(announcement);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.announcementsLoading = false;
        this.announcementsError = true;
      },
    });

    /*
      check if the request for announcements takes long (more than 5 seconds).
      if it does, notify the user to wait a little longer
    */
    setTimeout(() => {
      this.announcementsLoadingDelay = true;
      // if announcements does not load in another 5 seconds, cancel the request; treat as error
      setTimeout(() => {
        // 10 seconds passed. check if request is still in progress. if so, cancel the request; treat as error
        this.announcementsLoadingDelay = false;
        if (announcementsSub && this.announcementsLoading) {
          announcementsSub.unsubscribe();
          this.announcementsLoading = false;
          this.announcementsError = true;
        }
      }, 5000);
    }, 5000);
  }

  startEventListeners() {
    this.detailDatasetRequestsInitialLoading = true;
    this.detailDatasetRequestsService.select<DetailDatasetDownloadRequest[]>('listChanges').subscribe((list) => {
      this.reportsDownloadsData = list;
      if (this.detailDatasetRequestsInitialLoading && !!list) {
        this.detailDatasetRequestsInitialLoading = false;
      }
    });

    this.usageLogExportRequestsInitialLoading = true;
    this.usageLogExportRequestsService.select<UsageLogExportRequest[]>('listChanges').subscribe((list) => {
      this.usageLogExportRequests = list;
      if (this.usageLogExportRequestsInitialLoading && !!list) {
        this.usageLogExportRequestsInitialLoading = false;
      }
    });

    const shouldLoadUnreadReports = this.isExternalUser && this.hasUnreadReportsAccess;
    if (shouldLoadUnreadReports) {
      this.unreadReportsInitialLoading = true;
      this.unreadReportsListChangesSub = this.unreadReportsService.select('listChanges').subscribe({
        next: (list) => {
          this.unReadReportsData = list;
          if (this.unreadReportsInitialLoading && !!list) {
            this.unreadReportsInitialLoading = false;
          }
        },
        error: () => {
          this.unreadReportsInitialLoading = false;
          if (this.unreadReportsListChangesSub) {
            this.unreadReportsListChangesSub.unsubscribe();
          }
        }
      });
    }
  }

  loadDetailsForAnnouncement(
    announcement: NotificationAnnouncement
  ) {
    if (!announcement) {
      return;
    }
    announcement.loading = true;
    announcement.loadingError = false;
    this.announcementsService.getNotificationAnnouncementBody(announcement).subscribe({
      next: (data: Array<NotificationAnnouncement>) => {
        announcement.body = data[0].body;
        announcement.loading = false;
        announcement.showBody = true;
      },
      error: (error: HttpErrorResponse) => {
        announcement.loading = false;
        announcement.loadingError = true;
        announcement.showBody = false;
      }
    });
  }

  initUnreadReportsGrid() {
    const readReportClickHandler = (params) => {
      this.openReportDetail(params.data);
    };
    this.unreadReportsColumnDefs = getUnreadReportsColumnDefs({
      readReportClickHandler,
      periodicities: this.periodicities
    });
  }

  onDownloadDetailsDatasetClick(params: ICellRendererParams) {
    const { reportId, viewName, reportPeriodDate, fileName, requestId, detailDatasetRequestId, reportDisplayName } = params.data;
    const shouldDelete = (
      params.data.status === DetailDatasetDownloadRequestStatus.NO_DATA ||
      params.data.status === DetailDatasetDownloadRequestStatus.ERROR
    );
    if (shouldDelete) {
      this.detailDatasetRequestsService.removeDetailDatasetRequest(detailDatasetRequestId);
      return;
    }
    const isDqsDetail = !!requestId && !reportId;
    if (isDqsDetail) {
      const dqsDataParts = fileName.split('_');
      const dqsFirmCrd = dqsDataParts[1];
      const dqsFileName = dqsDataParts[dqsDataParts.length - 1];
      const dqsViewName = dqsFileName.split('.')[0];
      const dqsDetailUrl = this.dqsService.getDetailsDataUrl(
        dqsFirmCrd,
        requestId,
        dqsViewName
      );

      this.helperService.downloadFile(
        dqsDetailUrl,
        true,
        fileName
      ).then((successful) => {
        // send request to delete this ONLY IF they downloaded the file successfully.
        if (successful) {
          this.detailDatasetRequestsService.removeDetailDatasetRequest(detailDatasetRequestId);
        }
      });

      /** Log BEAST event */
      const eventInfo: BeastHomePageDqsDetailDownloadInfo = {
        reportId,
        viewName,
        reportPeriodDate,
        fileName,
        requestId,
        detailDatasetRequestId,
        type: DqsFqsNames.TYPE_DQS
      };
      this.beastService.clickStream.postEvent(
        BeastClickActions.REPORT_DQS_DETAIL_DOWNLOAD_FROM_HOME_PAGE,
        eventInfo
      );
    } else {
      const exportUrl: string = this.reportService.getReportUrl(
        reportId,
        viewName,
        'd',
        reportPeriodDate
      );
      this.helperService.downloadFile(exportUrl).then((successful) => {
        // send request to delete this ONLY IF they downloaded the file successfully.
        if (successful) {
          this.detailDatasetRequestsService.removeDetailDatasetRequest(detailDatasetRequestId);
        }
      });

      /** Log BEAST event */
      this.reportsService.getReportInstanceMetadata(reportId)
      .subscribe(
        (response: ReportInstanceMetadata) => {
          const eventInfo: BeastHomePageDetailDownloadInfo = {
            reportId,
            viewName,
            reportPeriodDate,
            fileName,
            requestId,
            detailDatasetRequestId,
            reportType: response.reportConfiguration.reportDisplayName,
          };
          this.beastService.clickStream.postEvent(
            BeastClickActions.REPORT_DETAIL_DOWNLOAD_FROM_HOME_PAGE,
            eventInfo
          );
        }
      );
    }
  }

  initDetailDatasetGrid() {
    const onDownloadDetailsDatasetClick = (params: ICellRendererParams) => {
      this.onDownloadDetailsDatasetClick(params);
    };
    const onViewReportClick = async (params: ICellRendererParams) => {
      const { reportId } = (<DetailDatasetDownloadRequest> params.data);
      // get report by id
      const report = await this.reportsService.getReportInstanceMetadata(reportId).toPromise();
      // navigate to the report
      this.reportService.navigateToReportDetails({ report });
    };

    this.reportsDownloadsColumnDefs = GetReportsDowloadColumnDefs(
      onDownloadDetailsDatasetClick,
      onViewReportClick
    );
  }

  initUsageLogExportRequestsGrid() {
    const onDownloadClick = (params: ICellRendererParams) => {
      const { exportRequestId } = (params.data as UsageLogExportRequest);
      this.usageLogExportRequestsService.downloadReadyUsageLogExport(exportRequestId);
    };
    const onDeleteClick = (params: ICellRendererParams) => {
      const { exportRequestId } = (params.data as UsageLogExportRequest);
      this.usageLogExportRequestsService.deleteUsageLogExport(exportRequestId).subscribe({
        next: (response) => {
          this.uiWidgetsService.showSuccessSnackbar(`Deleted Successfully!`);
          this.usageLogExportRequests = this.usageLogExportRequests.filter(i => i.exportRequestId !== exportRequestId);
        },
        error: (error) => {
          this.uiWidgetsService.showErrorSnackbar(`Could not delete export request; something went wrong...`);
        }
      });
    };
    const onInfoClick = (params: ICellRendererParams) => {
      try {
        const { metadata } = (params.data as UsageLogExportRequest);
        const searchParams: UsageLogSearch = JSON.parse(metadata);
        this.uiWidgetsService.showInfoModal({
          header: 'Original Audit Action Search',
          body: `
          <div>
            <h3>This info is the audit action search that generated this export.</h3>
            <p>Firm CRD: ${searchParams.firmCrdNumber}</p>
            <p>Start Date: ${makeDateStartOfDay(searchParams.startDate).toISOString().split('T')[0]}</p>
            <p>End Date: ${makeDateStartOfDay(searchParams.endDate).toISOString().split('T')[0]}</p>
            <br />
            <div class="">
              <p>Audit Actions</p>
              ${searchParams.auditActionLookupIds?.map(a => `<span class="audit-action-tag cursor-pointer transition">` + this.auditActionLookupsById[a] + `</span>`).join('')}
            </div>
            ${
              !searchParams.rcUserIds ? '' : `
              <div>
                <p>User Ids</p>
                ${!searchParams.rcUserIds ? '' : searchParams.rcUserIds.map(a => `<span class="audit-action-tag cursor-pointer transitiond">${a}</span>`).join('')}
              </div>
              `
            }
          </div>
          `
        }, { width: '900px', height: '600px' });
      }
      catch (ex) {
        console.log(ex);
      }
    };

    this.usageLogExportRequestsColumnDefs = GetUsageLogExportColumnDefs({
      downloadClickHandler: onDownloadClick,
      deleteClickHandler: onDeleteClick,
      infoClickHandler: onInfoClick,
    });
  }

  handleUserProfileAndReportsAccess(userProfile: UserProfile) {
    this.userProfile = userProfile;
    this.isExternalUser = this.userProfileService.isExternalUser(this.userProfile);
    this.firmId = userProfile.orgId;
    this.hasUnreadReportsAccess = this.helperService.hasUnreadReportsAccess(this.userProfile);
  }

  openReportDetail(report: ReportInstanceMetadata) {
    /** Log BEAST event */
    const eventInfo: BeastViewUnreadReportInfo = {
      reportId: report.reportId.toString(),
      reportType: report.reportConfiguration.reportDisplayName,
      reportPeriod: report.reportPeriodDate,
      firmId: report.reportFirmId
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.VIEW_UNREAD_REPORT,
      eventInfo
    );

    this.reportsService.setCurrentReportInstance(report);
    this.reportService.navigateToReportDetails({ report });
  }

  onAnnouncementsScroll(event) {
    /** User is scrolling through announcement items, log BEAST event */
    if (this.announcementsScrollTimer) {
      // the timeout is still active, wait until it is not active
      return;
    }

    /** Log BEAST event */
    this.beastService.clickStream.postEvent(
      BeastOtherActions.ANNOUNCEMENTS_SCROLL,
      ({} as any) // no details to capture with this event
    );

    // set timeout to prevent rapid calls on scroll events
    this.announcementsScrollTimer = window.setTimeout(() => {
      // reset timeout flag after 1 second
      // so another event can be logged upon next occurrence of action
      this.announcementsScrollTimer = null;
    }, 1000);
  }
}
