export const ONLINE_LEARNING_TITLE_1 = 'Continuing Education Regulatory Element Report';
export const ONLINE_LEARNING_TITLE_2 = 'Continuing Education Regulatory Element Report Summary - Sessions Attended - Firm';

export const ONLINE_LEARNING_DISCLAIMER_1 = `* This symbol indicates that no data is available for this value. \
Asterisks appear for modules that do not apply and will also appear \
if your firm did not have reps of each type attend sessions during the quarter.\n\n`;

export const ONLINE_LEARNING_TOOLBAR_HEADER =
  `The CE Regulatory Element Report is published each quarter for any firm that has had at least one \
  representative take a Regulatory Element CE session during the quarter. The report provides information \
  about the firm's performance as well as industry performance during the quarter for comparison. \
  <p>For questions regarding this report, \
  please contact the Continuing Education Department by email at \
  <a href="mailto:continuingeducation@finra.org">continuingeducation@finra.org</a> \
  or by phone at (800) 999-6647 (press 6).</p>`;

export enum OnlineLearningReportTypeIDs {
  CE_REGULATORY_ELEMENT = 29,
}

export enum OnlineLearningReportShortNames {
  CE_REGULATORY_ELEMENT = 'ceregelement',
}

export const OnlineLearningQuarterlyReportsByTypeId = [
  OnlineLearningReportTypeIDs.CE_REGULATORY_ELEMENT
];
