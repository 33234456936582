import {
  finraGridPercentValueFormatter,
  finraGridNumericValueFormatter,
  groupEmptyCellRenderer
} from 'src/app/shared/utils/utils';
import {
  ICellRendererParams,
  ValueFormatterParams,
  CellClassParams
} from 'ag-grid-community';
import { TRACE_TIMELINESS_TOOLBAR_DESCRIPTION } from './trace-timeliness.model';

export enum TraceReportTypeIds {
  MARKUP_MARKDOWN = 72,
  TREASURIES = 65,
  SECURITIZED_PRODUCTS = 33,
  CORPORATE_BONDS = 27,
  AGENCY_DEBT = 28,
  TIMELINESS = 204,
  FOREIGN_SOVEREIGN = 208,
}

export enum TraceReportShortNames {
  MARKUP_MARKDOWN = 'traceqmrcmumd',
  TREASURIES = 'traceqmrcts',
  SECURITIZED_PRODUCTS = 'traceqmrcsp',
  CORPORATE_BONDS = 'traceqmrccorpbond',
  AGENCY_DEBT = 'traceqmrcagency',
  TIMELINESS = 'traceqmrctr',
  FOREIGN_SOVEREIGN = 'tracefrgnsvgn',
}

export const traceGroupEmptyCellRenderer = function(params: ICellRendererParams) {
  return (params.data && params.data.isTotalColumn) || params.node.group
    ? ''
    : params.valueFormatted;
};

export const TITLES = {
  activityTotal: 'Activity Totals',
  tradeReportingPeerGroup: 'TRACE Reporting Peer Group (Total Trades)',
  lateTrade: 'Late Trades',
  tradeReportingAnalysis: 'Trade Reporting Analysis'
};

export const HEADER_TEXTS = {
  [TraceReportShortNames.AGENCY_DEBT]:
    'The TRACE Quality of Markets Report Card for Agency Debt is a monthly status report for trades that a member firm reported to TRACE. Firms are required to report trades in accordance with established FINRA rules and regulations.\n\n For questions regarding the content of this specific report, please contact the FINRA Market Regulation Department at (800) 321-6273 and ask for the Fixed Income Team.',
  [TraceReportShortNames.CORPORATE_BONDS]:
    'The TRACE Quality of Markets Report Card for Corporate Bonds is a monthly status report for trades that a member firm reported to TRACE. Firms are required to report trades in accordance with established FINRA rules and regulations.\n\n For questions regarding the content of this specific report, please contact the FINRA Market Regulation Department at (800) 321-6273 and ask for the Fixed Income Team.',
  [TraceReportShortNames.SECURITIZED_PRODUCTS]:
    'The TRACE Quality of Markets Report Card for Securitized Products is a monthly status report for the reporting of transactions in securitized products to TRACE. Firms are required to report trades in accordance with established FINRA rules and regulations.\n\n For questions regarding the content of this specific report, please contact the FINRA Market Regulation Department at (800) 321-6273 and ask for the Fixed Income Team.',
  [TraceReportShortNames.TREASURIES]:
    ' The TRACE Quality of Markets Report Card for Treasuries is a monthly status report for trades that a member firm reported to TRACE. Firms are required to report trades in accordance with established FINRA rules and regulations.\n\n For questions regarding the content of this specific report, please contact the FINRA Market Regulation Department at (800) 321-6273 and ask for the Fixed Income Team.',
  [TraceReportShortNames.MARKUP_MARKDOWN]: `This report is a monthly compliance tool that provides member firms an analysis of the markup, markdown, and customer-to-customer pairs identified by FINRA during the month. This report and supporting transaction details are designed to support firms' fair pricing and disclosure compliance procedures and reviews.\n\n If you have questions regarding the content of this specific report, please contact the FINRA Market Regulation Department at (800) 321-6273 and ask for the Fixed Income Team.`,
  [TraceReportShortNames.TIMELINESS]: TRACE_TIMELINESS_TOOLBAR_DESCRIPTION,
  [TraceReportShortNames.FOREIGN_SOVEREIGN]: `The TRACE Quality of Markets Report Card for Foreign Sovereign Debt \
  is a monthly status report for trades that a member firm reported to TRACE. \
  Firms are required to report trades in accordance with established FINRA rules and regulations.\
  \n
  For questions regarding the content of this specific report, please contact the FINRA Market Regulation Department at (800) 321-6273 and ask for the Fixed Income Team.
  `
};

export const SHOW_TRACE_PEER_GROUP_HELP_BUTTON_BY_REPORT: { [key: string]: boolean; } = {
  [TraceReportShortNames.AGENCY_DEBT]: true,
  [TraceReportShortNames.CORPORATE_BONDS]: true,
  [TraceReportShortNames.SECURITIZED_PRODUCTS]: true,
  [TraceReportShortNames.TREASURIES]: true,
  [TraceReportShortNames.MARKUP_MARKDOWN]: false,
  [TraceReportShortNames.TIMELINESS]: false,
  [TraceReportShortNames.FOREIGN_SOVEREIGN]: true,
};

export const HELP_LINKS: { [key: string]: string; } = {
  [TraceReportShortNames.AGENCY_DEBT]:
    'https://tools.finra.org/reportcenterhelp/#TRACE_Reports.htm#TRACE_Quality_of_Markets_Report_Cards_-_Corporate_Bond_and_Agency_Debt',
  [TraceReportShortNames.CORPORATE_BONDS]:
    'https://tools.finra.org/reportcenterhelp/#TRACE_Reports.htm#TRACE_Quality_of_Markets_Report_Cards_-_Corporate_Bond_and_Agency_Debt',
  [TraceReportShortNames.SECURITIZED_PRODUCTS]:
    'https://tools.finra.org/reportcenterhelp/#TRACE_Reports.htm#TRACE_Quality_of_Markets_Report_Cards_-_Securitized_Products',
  // TODO: Need for find out the forth report name
  [TraceReportShortNames.TREASURIES]:
    'https://tools.finra.org/reportcenterhelp/#TRACE_Reports.htm#TRACE_Quality_of_Markets_Report_Cards_-_Treasuriesbc-5',
  [TraceReportShortNames.MARKUP_MARKDOWN]:
    'https://tools.finra.org/reportcenterhelp/#TRACE_Reports.htm#TRACE_Markup_Markdown_Analysis_Report',
  [TraceReportShortNames.TIMELINESS]:
    'https://www.finra.org/compliance-tools/report-center/trace/treasuries-execution-time-difference',
  [TraceReportShortNames.FOREIGN_SOVEREIGN]:
    'https://tools.finra.org/reportcenterhelp/#TRACE_Reports.htm#TRACE_Quality_of_Markets_Report_Cards_-_Corporate_Bond_and_Agency_Debt',
};

export const ACTIVITY_GROUP_NAMES = {
  interDealer: 'Inter-Dealer Trades',
  lockedIntrade: 'Locked-In Trades'
};

export const LATE_TRADE_GROUP_NAMES = {
  Customer: 'Customer Trades',
  InterDealer: 'Inter-Dealer Trades',
  LockedIn: 'Locked-In Trades',
  Affiliate: 'Affiliate Trades',
  All: 'All Trades'
};

export const TRADE_ANALYSIS_GROUP_NAMES = {
  executionTime: 'Execution Time Reporting',
  amendedTradeReports: 'Amended Trade Reports',
  pricing: 'Pricing',
  interDealer: 'Inter-Dealer Difference',
  executionTimeDifference: 'Execution Time Difference',
  intraFirmReport: 'Intra-Firm Trade Reports',
  alternateATS: 'Alternative Trading Systems',
  securityIndicator: 'Security Indicator',
  factorProvided: 'Factor Provided',
  tradingModifier: 'Trading Modifier'
};

export const FIELD_NAMES = {
  ReportEntryCount: 'reportEntryCount',
  ValidReportCount: 'validReportCount',
  PriorMonthReportEntryCount: 'priorMonthReportEntryCount',
  PriorMonthValidReportCount: 'priorMonthValidReportCount',
  EntryCount: 'reportEntryCount',
  ValidCount: 'validReportCount',
  PriorMonthEntryCount: 'priorMonthReportEntryCount',
  PriorMonthValidCount: 'priorMonthValidReportCount'
};

export const ACTIVITY_TOTALS_COL_DEFS = [
  {
    field: 'group',
    headerName: '',
    rowGroup: true,
    hide: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return ACTIVITY_GROUP_NAMES[params.value];
    }
  },
  {
    headerName: '',
    field: 'displayName',
    cellClass: (params: CellClassParams) => {
      return !(params.data && params.data.group) ? 'indent-left' : null;
    }
  },
  {
    headerName: '',
    field: '',
    children: [
      {
        headerName: 'Total Trade Report Entries',
        field: 'ReportEntryCount',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      },
      {
        headerName: 'Total Valid Trade Reports',
        field: 'ValidReportCount',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      }
    ]
  },
  {
    headerName: 'Prior Month',
    field: '',
    children: [
      {
        headerName: 'Total Trade Report Entries',
        field: 'PriorMonthReportEntryCount',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      },
      {
        headerName: 'Total Valid Trade Reports',
        field: 'PriorMonthValidReportCount',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      }
    ]
  }
];

export const TRADE_PEER_COLUMN_DEFS = [
  {
    headerName: 'Number of Firms in Peer Group',
    field: 'TotalNumberFirmsInCount'
  },
  {
    headerName: 'Rank In Peer Group',
    field: 'TotalRankInNumber'
  }
];

export const LATE_TRADE_COLUMN_DEFS = [
  {
    field: 'group',
    headerName: '',
    rowGroup: true,
    hide: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return LATE_TRADE_GROUP_NAMES[params.value];
    }
  },
  {
    headerName: '',
    field: 'displayName',
    cellRenderer: (params: ICellRendererParams) => {
      return params.data && params.data['hasFootNote']
        ? `${params.value}<sup>1</sup>`
        : params.value;
    }
  },
  {
    headerName: '',
    field: '',
    children: [
      {
        headerName: 'Trade Count',
        field: 'Count',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      },
      {
        headerName: 'Firm %',
        field: 'FirmPercent',
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: traceGroupEmptyCellRenderer
      },
      {
        headerName: 'Industry Average %',
        field: 'IndicatorAveragePercent',
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: traceGroupEmptyCellRenderer
      },
      {
        headerName: 'Peer Average %',
        field: 'PeerAveragePercent',
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: traceGroupEmptyCellRenderer
      },
      {
        headerName: 'Rank In Peer Group',
        field: 'RankInPeerGroupNumber',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: traceGroupEmptyCellRenderer
      }
    ]
  },
  {
    headerName: 'Prior Month',
    field: '',
    children: [
      {
        headerName: 'Trade Count',
        field: 'PriorMonthCount',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      },
      {
        headerName: '% Change',
        field: 'PriorMonthCountPercentChange',
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      }
    ]
  }
];

export const TRADE_ANALYSIS_COLUMN_DEFS = [
  {
    field: 'group',
    headerName: '',
    rowGroup: true,
    hide: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return TRADE_ANALYSIS_GROUP_NAMES[params.value];
    }
  },
  { headerName: '', field: 'displayName' },
  {
    headerName: '',
    field: '',
    children: [
      {
        headerName: 'Trade Count',
        field: 'Count',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      },
      {
        headerName: 'Firm %',
        field: 'FirmPercent',
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      },
      {
        headerName: 'Peer Average %',
        field: 'PeerAveragePercent',
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      },
      {
        headerName: 'Rank In Peer Group',
        field: 'RankInPeerGroupNumber',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      }
    ]
  },
  {
    headerName: 'Prior Month',
    field: '',
    children: [
      {
        headerName: 'Trade Count',
        field: 'PriorMonthCount',
        valueFormatter: finraGridNumericValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      },
      {
        headerName: '% Change',
        field: 'PriorMonthCountPercentChange',
        valueFormatter: finraGridPercentValueFormatter,
        cellRenderer: groupEmptyCellRenderer
      }
    ]
  }
];

export const MRKUP_MRKDN_COL_DEFS = [
  {
    headerName: 'Dollar Traded Amount',
    field: 'dollarTradedAmount'
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Number of Trades Evaluated',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'numberOfTradesEvaluated',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Mean',
        field: 'meanFirm',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Median',
        field: 'medianFirm',
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Mean',
        field: 'meanIndustry',
        headerClass: 'lines',
        cellClass: 'lines',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Median',
        field: 'medianIndustry',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P25',
        field: 'p25',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P50',
        field: 'p50',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P75',
        field: 'p75',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P80',
        field: 'p80',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P90',
        field: 'p90',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P95',
        field: 'p95',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'P99',
        field: 'p99',
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  }
];

export const COLUMN_DEFS = {
  // securitized products, corporate bonds, treasuries, agency debt
  activityTotal: ACTIVITY_TOTALS_COL_DEFS,
  tradeReportingPeerGroup: TRADE_PEER_COLUMN_DEFS,
  lateTrade: LATE_TRADE_COLUMN_DEFS,
  tradeReportingAnalysis: TRADE_ANALYSIS_COLUMN_DEFS
};

export const DISCLAIMER = `An * indicates that there were no customer transactions by your firm during the month.\n
This report is a compliance tool designed to provide statistics related to trade reporting in TRACE. Member firms should make no inference that staff of FINRA has or has not determined that the information contained on the TRACE Quality of Markets Report Card does or does not constitute rule violations.`;

export const SEC_DISCLAIMER = `An * indicates that there were no customer transactions by your firm during the month.\n
<sup>1</sup>The Same Day Late category does not apply to transactions that must be reported by the end of the business day the transaction occurred. This category applies to transactions that have to be reported within a certain number of minutes after the transaction has occurred. See FINRA Rule 6730 for information regarding when transactions must be reported.\n
  This report is a compliance tool designed to provide statistics related to trade reporting in TRACE. Member firms should make no inference that staff of FINRA has or has not determined that the information contained on the TRACE Quality of Markets Report Card does or does not constitute rule violations.`;

export const MKUP_MKDN_DISCLAIMER = `An * in the Firm Section indicates that there were no customer transactions by your firm during the month.\nAn * in the Industry Section indicates that there were not at least five pairs for the relevant criteria.\n\nThis report is a compliance tool designed to provide statistics related to the markup, markdown, and customer-to customer pairs identified by FINRA. Member firms should make no inference that staff of FINRA has or has not determined that the information contained on the Markup/Markdown Analysis does or does not constitute rule violations.`;

export const TIMELINESS_FOOTER = `* If this symbol appears on your report, this indicates that no data has been provided to FINRA for this value for the specified period.`;

export const DISCLAIMERS = {
  [TraceReportShortNames.AGENCY_DEBT]: DISCLAIMER,
  [TraceReportShortNames.CORPORATE_BONDS]: DISCLAIMER,
  [TraceReportShortNames.TREASURIES]: DISCLAIMER,
  [TraceReportShortNames.SECURITIZED_PRODUCTS]: SEC_DISCLAIMER,
  [TraceReportShortNames.MARKUP_MARKDOWN]: MKUP_MKDN_DISCLAIMER,
  [TraceReportShortNames.TIMELINESS]: TIMELINESS_FOOTER,
  [TraceReportShortNames.FOREIGN_SOVEREIGN]: DISCLAIMER,
};

export const MATURITIES = [
  { value: 'all', name: 'All' },
  { value: '1', name: '< 1 year' },
  { value: '2', name: '>=1 to <5 years' },
  { value: '3', name: '>=5 to <10 years' },
  { value: '4', name: '>=10 years' },
  { value: '100', name: 'N/A' }
];
export const RATINGS = [
  { value: 'all', name: 'All' },
  { value: '1', name: 'Investment Grade' },
  { value: '-1', name: 'Non-Investment Grade' },
  { value: '0', name: 'Not-Rated' },
];
export const PRODUCTS = [
  { value: 'agcy', name: 'Agency' },
  { value: 'corp', name: 'Corporate' },
];

/** Trace Report Interfaces */

export interface TraceMarkupDownReportData {
  dollarTradedAmount: string;
  p90: number; // float
  meanFirm: number; // float
  p80: number; // float
  p50: number; // float
  meanIndustry: number; // float
  p95: number; // float
  p75: number; // float
  p99: number; // float
  p25: number; // float
  medianIndustry: number; // float
  medianFirm: number; // float
  numberOfTradesEvaluated: number;
}
export type TraceMarkupDownReportDataResponse = TraceMarkupDownReportData[];
