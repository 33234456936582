<div id="usage-log-container">
  <report-loader></report-loader>

  <div class="flex flex-row justify-between items-center mb-5">
    <h1 class="page-title title-color pdf font-bold">
      {{ title }}
    </h1>

    <span class="question-icon-container" (click)="openHelp()">
      <fa-icon
        data-html2canvas-ignore
        [icon]="faQuestionCircle"
        class="fa-lg question-icon"
      ></fa-icon>
      <span>Help</span>
    </span>
  </div>

  <section class="description-section pdf">
    <span>
      The log below shows the actions performed by representatives of your firm on
      the Report Center. Use the controls on this page to customize the report and
      the data can be sorted by clicking on the column headers.
    </span>
  </section>

  <report-error-message [errorMessage]="errorMessage"></report-error-message>

  <div
    class="usage-log-main-container"
    fxFlexLayout="row wrap"
    fxLayoutGap="13px"
    [ngStyle]="{ 'min-height': clientHeight - footerHeight + 'px' }"
  >
    <div class="usage-log-container" fxFlex="80">
      <div class="searchFilter form-box grey-border-1">
        <div>
          <h3 class="title-color text-lg">Audit Actions</h3>
          <p class="mb-5">Click the audit action to add/remove it from the search query.</p>

          <p>
            <span class="link-btn" (click)="setAllAuditActionsSelectedState(true)">Select All</span>
            <span>&nbsp;|&nbsp;</span>
            <span class="link-btn" (click)="setAllAuditActionsSelectedState(false)">Deselect All</span>
          </p>
          <div class="tags-container">
            <span
              class="tag cursor-pointer transition"
              [attr.title]="'Click to ' + (!auditAction.selected ? 'select' : 'de-select') + ' audit action: ' + auditAction.auditActionDescription"
              *ngFor="let auditAction of auditActionLookups"
              (click)="auditAction.selected = !auditAction.selected"
              [ngClass]="{
                'action-enabled': !!auditAction.selected,
                'action-disabled': !auditAction.selected
              }"
            >
              {{ auditAction.auditActionDescription }}
            </span>
          </div>
        </div>
      </div>

      <section class="usage-log-grid-container" *ngIf="!loading && rowData && rowData.length > 0">
        <div class="controls-container">
          <span class="margin-right-30"
            (click)="exportUsageLogData()" data-html2canvas-ignore>
            <fa-icon
              class="fa-lg"
              [icon]="faDownload"
            ></fa-icon>
            Export
          </span>

          <span (click)="onExportPdf()" data-html2canvas-ignore>
            <fa-icon
              class="fa-lg"
              [icon]="printState.icon"
              [spin]="printState.processing"
            ></fa-icon>
            Print
          </span>
        </div>

        <report-finra-grid
          class="pdf"
          #reportfinragrid
          [id]="id"
          [columnDefs]="columnDefs"
          [rowData]="rowData"
          [autoResize]="false"
          [pageSize]="20"
        >
        </report-finra-grid>
      </section>
    </div>

    <div fxFlex="20" *ngIf="isViewable">
      <div>
        <form class="form-box grey-border-1" fxFlex="100" [formGroup]="auditSearchFormGroup">
          <h3 class="title-color" style="margin: 0 0 20px 0">Usage Log Filter</h3>
          <div *ngIf="showChangeFirm">
            <!-- Firm ID -->
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="Firm CRD #"
                formControlName="firmCrdNumber"
              />
            </mat-form-field>
            <!-- <div>
              <button
                mat-flat-button
                color="accent"
                class="change-firm-button"
                (click)="refreshReportCenterUsers()"
              >
                Change Firm
              </button>
            </div> -->
          </div>

          <!-- Start Date -->
          <finra-datepicker
            [control]="auditSearchFormGroup.controls.startDate"
            [name]="'startDate'"
            [title]="'Start Date'"
          ></finra-datepicker>

          <!-- End Date -->
          <finra-datepicker
            [control]="auditSearchFormGroup.controls.endDate"
            [name]="'endDate'"
            [title]="'End Date'"
          ></finra-datepicker>

          <div *ngIf="showCrdExamNumber">
            <!-- CRD # -->
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="CRD #"
                formControlName="examCrdNumber"
              />
            </mat-form-field>
            <!-- Exam # -->
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="Exam #"
                formControlName="examNumber"
              />
            </mat-form-field>
          </div>

          <!-- report type input -->
          <div class="box-pad-1">
            <span class="typography-1">Report Types</span>
            <mat-form-field class="form-field-block">
              <mat-select [formControl]="reportTypeControl" multiple>
                <mat-option
                  *ngFor="let reportType of reportTypesList"
                  [value]="reportType.reportTypeName"
                  [attr.title]="reportType.reportTypeName + ', ' + 'Active: ' + !reportType.reportDeleted"
                >
                  {{ reportType.reportTypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <button
              mat-flat-button
              color="accent"
              class="filter-button"
              (click)="refreshGridData()"
              [disabled]="loading"
            >
              Filter
            </button>

            <br />
            <br />

            <p>
              The filter will return at most 10,000 by default to maintain acceptable performance.
              To request the full results of a filter, use the "Export All Results" button below.
              This will start an async process to generate the file; you will be notified with any status updates as they occur.
              The results will be displayed on the home page.
            </p>
            <button
              *ngIf="true"
              mat-flat-button
              color="accent"
              class="filter-button"
              (click)="exportUsageLogDataFull()"
              [disabled]="loading"
            >
              Export All Results
            </button>
          </div>

          <hr />

          <!-- Users -->
          <div *ngIf="usersErrorMessage" class="alert-box snackbar-error">
            {{ usersErrorMessage }}
          </div>
          <div *ngIf="filteredReportCenterUsers && usersPaginatorState.copyArrayObject">
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="Filter users by name, email, or id"
                formControlName="filterUsersQuery"
              />
            </mat-form-field>
            <div>
              <span>
                {{ usersPaginatorState.startNum }} - {{ usersPaginatorState.endNum }} of {{ usersPaginatorState.copyArrayObject.length }}
              </span>
              <br />
              <span>
                <span>
                  <fa-icon style="margin-left: 0"
                    [icon]="faStepBackward" class="paginator-icon fa-sm" (click)="usersPaginator.setPage(1)"></fa-icon>
                  <fa-icon [icon]="faCaretLeft" class="paginator-icon fa-lg" (click)="usersPaginator.setPrev()"></fa-icon>
                </span>
                Page
                <input
                  id="page-number-input"
                  type="number"
                  [value]="usersPaginatorState.page"
                  [min]="1"
                  [max]="usersPaginatorState.pages"
                  (input)="usersPaginator.setPage($event.target.value)"
                />
                of {{ usersPaginatorState.pages }}
                <span>
                  <fa-icon [icon]="faCaretRight" class="paginator-icon fa-lg" (click)="usersPaginator.setNext()"></fa-icon>
                  <fa-icon [icon]="faStepForward" class="paginator-icon fa-sm" (click)="usersPaginator.setPage(usersPaginatorState.pages)"></fa-icon>
                </span>
              </span>
              <br/>
              <br/>
              <span>Deselect all to ignore the user filter in the query.</span>
              <br/>
              <br/>
              <span>
                <span class="link-btn" (click)="setAllUsersSelectedState(true)">Select All</span>
                <span>&nbsp;|&nbsp;</span>
                <span class="link-btn" (click)="setAllUsersSelectedState(false)">Deselect All</span>
                <br/>
                <span>{{ selectedUsersLength }} User(s) Selected</span>
              </span>
            </div>
            <br/>
            <div id="rc-users-list-container">
              <ul class="rc-users-list">
                <li *ngFor="let user of usersPaginatorState.currentSection"
                  class="rc-user"
                  [attr.title]="user.userName + ' | email: ' + user.userEmail + ' | id: ' + user.reportCenterUserId"
                  [attr.data-userName]="user.userName"
                  [attr.data-userEmail]="user.userEmail"
                  [attr.data-userReportCenterUserId]="user.reportCenterUserId"
                  [attr.data-userOrgClass]="user.userOrganizationClass"
                  [attr.data-userOrgId]="user.userOrganizationId"
                  [attr.data-userCreatedTimestamp]="user.userCreatedTimestamp"
                >
                  <mat-checkbox
                    [(ngModel)]="user.selected"
                    [ngModelOptions]="{standalone: true}"
                  >
                    <span>
                      {{ user.userName }} ({{ user.userCreatedTimestamp | date: 'MM/dd/yyyy' }})
                    </span>
                  </mat-checkbox>
                </li>
              </ul>
            </div>
          </div>
          <div class="field-users-container"></div>
          <!-- Actions -->
          <!-- <div class="field-actions-container">
            <mat-form-field class="field-action-input" placeholder="Actions">
              <mat-select
                formControlName="auditActionLookupIds"
                placeholder="Actions"
                multiple
              >
                <mat-option disabled="disabled" class="filter-option">
                  <button
                    mat-raised-button
                    class="mat-primary fill text-sm"
                    (click)="selectAll('auditActionLookupIds')"
                  >
                    Select All
                  </button>
                  <button
                    mat-raised-button
                    class="mat-accent fill text-sm"
                    (click)="deselectAll('auditActionLookupIds')"
                  >
                    Deselect All
                  </button>
                </mat-option>

                <mat-option
                  *ngFor="let auditActionLookup of auditActionLookups"
                  [value]="auditActionLookup.auditActionLookupId"
                >
                  {{ auditActionLookup.auditActionDescription }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <!-- Refresh -->
        </form>
      </div>
    </div>
  </div>
</div>
