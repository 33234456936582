{{ title }}
<ng-container *ngIf="showButton">
  <ng-container [ngSwitch]="isClickable">
    <span *ngSwitchCase="true">
      <a
        href="javascript:void(0)"
        class="no-print {{ buttonClass }}"
        (click)="triggerClick()"
      >
        <fa-icon
          [icon]="icon"
          class="fa-lg dialog-tools-icon"
          *ngIf="icon"
        ></fa-icon>
        {{ buttonLabel }}
      </a>
    </span>

    <span *ngSwitchCase="false">
      <fa-icon
        [icon]="icon"
        class="fa-lg dialog-tools-icon"
        *ngIf="icon"
      ></fa-icon>
      {{ buttonLabel }}
    </span>
  </ng-container>
</ng-container>
