import {
  IconDefinition,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { GridFiltersService } from 'src/app/services/grid-filters.service';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { FinraGridHeaderComponent } from '../finra-grid-header/finra-grid-header.component';

@Component({
  selector: 'report-finra-grid',
  templateUrl: './finra-grid.component.html',
  styleUrls: ['./finra-grid.component.scss']
})
export class FinraGridComponent implements OnInit {
  @Input() title: string;
  @Input() autoResize: boolean = true;
  @Input() columnDefs: any[];
  @Input() rowData: any[];
  @Input() id: string;
  @Input() gridOptions: GridOptions;
  @Input() groupDefaultExpanded = -1;
  @Input() hasNoRowGroup;
  @Input() context: object;
  @Input() help: string;
  @Input() helpLink: string;
  @Input() rowSelection: string;
  @Input() suppressRowClickSelection: string;
  @Input() pageSize: number = 0;
  @Input() autoGroupColumnDef;
  @Input() defaultColDef;
  @Input() gridFilters;
  @Input() statusBar;
  @Input() containerCssClass;
  @Input() containerCssStyle;
  @Input() tableHeaderCssClass;
  @Input() tableTitleCssClass;
  @Input() agGridClass;
  
  @Output() selectedRows = new EventEmitter();
  @Output() gridIsReady: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();

  private gridApi;
  private gridColumnApi;
  private resizing: boolean = false;
  public frameworkComponents: any;

  faQuestionCircle: IconDefinition = faQuestionCircle;

  constructor(gridFiltersService: GridFiltersService) {
    this.gridFilters = gridFiltersService;
    this.defaultColDef = { resizable: true };
    this.autoGroupColumnDef = {
      cellRendererParams: {
        suppressCount: true
      }
    };
    this.frameworkComponents = {
      finraGridHeaderComponent: FinraGridHeaderComponent,
    };
  }

  ngOnInit() {
    if (!this.gridOptions) {
      this.gridOptions = {};
    }

    if (this.pageSize > 0) {
      this.gridOptions.pagination = true;
      this.gridOptions.paginationPageSize = this.pageSize;
    }

    if (this.hasNoRowGroup) {
      // Creating total row at the bottom for some of the corp-fin reports
      this.gridOptions.pinnedBottomRowData = this.createPinnedTotalRow();
      // Removing groupTotalFooter for non rowGrouped data
      this.gridOptions.groupIncludeTotalFooter = false;
    }

    // found: https://www.ag-grid.com/javascript-grid-properties/#miscellaneous
    this.gridOptions.suppressCsvExport = true;
    this.gridOptions.suppressExcelExport = true;
  }

  createPinnedTotalRow() {
    // Adding 'Total' title for non rowGroup data
    const total = {
      distributionMethodDescription: 'Total',
      letterTypeCode: 'Total',
      currentReviewProgramDescription: 'Total'
    };
    // Iterating through the keys of the first row object
    Object.keys(this.rowData[0]).forEach(key => {
      // Create total only for numeric values
      if (!isNaN(this.rowData[0][key])) {
        const agg = this.sum(this.rowData, key);
        total[key] = Math.round(agg) !== 100 ? agg : 100;
      }
    });
    return [total];
  }

  sum(arr, key) {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }

  displayedColsChanged(params) {
    params.api.sizeColumnsToFit();
  }

  rowGroupOpened(event) {
    this.autoSizeAllColumns();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridColumnApi = params.columnApi;

    this.autoSizeAllColumns();
    this.gridIsReady.emit(params);
  }

  autoSizeAllColumns() {
    if (!this.gridApi || !this.autoResize || this.resizing) {
      return;
    }
    this.resizing = true;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
    // Resizing the columns will fire gridSizeChanged to avoid an
    // infinite loop we give some time for the grid to finish its work
    // before turning resizing off. There is probably a better way to handle this.
    setTimeout(() => {
      this.resizing = false;
    }, 250);
  }

  openHelp() {
    window.open(this.helpLink, '_blank');
  }

  onTimeDate() {
    this.gridFilters.dateTime(this.gridApi);
  }

  onUserFilter(event) {
    this.gridFilters.user(this.gridApi, event);
  }

  onExportCsv(event) {
    this.gridFilters.export(this.gridApi, event);
  }

  onPrintGrid() {
    this.gridFilters.print(this.gridApi);
  }

  onSelectionChanged(event) {
    this.selectedRows.emit(event.api.getSelectedRows());
  }
}
