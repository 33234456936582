<div id="report-problems-container">
  <report-loader></report-loader>
  <report-alert class="report-main-content-padding no-print"></report-alert>
  <h1 class="title pdf">
    {{ title }}
  </h1>

  <div class="section-space">
    <a [routerLink]="['/', 'admin', 'report-view', 'report-problems', 'create']">
      <button
        mat-flat-button
        color="accent"
        class="filter-button"
      >
        Create New Report Problem
      </button>
    </a>

    <span>&nbsp;</span>

    <a *ngIf="reportProblemId" [routerLink]="['/', 'admin', 'report-view', 'report-problems']">
      <button
        mat-flat-button
        color="accent"
        class="filter-button"
      >
        See All Report Problems
      </button>
    </a>
  </div>

  <!--  -->

  <section class="data-container ovf-scroll" *ngIf="!error">
    <ng-container [ngSwitch]="!!reportProblemId">
      <div class="table-header" *ngSwitchCase="true">
        Report Problem
      </div>
      <div class="table-header display-flex" *ngSwitchCase="false">
        List Report Problems 
        <span class="link-inactive">|</span>
        <!-- Paginator -->
        <div class="paginator-box" *ngIf="!!paginator">
          <span class="typography-1">
            {{ paginatorState.startNum }} - {{ paginatorState.endNum }} of {{ paginatorState.copyArrayObject.length }} items
            <span>
              <fa-icon [icon]="faStepBackward" class="paginator-icon fa-sm" (click)="paginator.setPage(1)"></fa-icon> 
              <fa-icon [icon]="faCaretLeft" class="paginator-icon fa-lg" (click)="paginator.setPrev()"></fa-icon> 
            </span>
            Page 
            <input 
              id="page-number-input" 
              type="number"
              [value]="paginatorState.page" 
              [min]="1" 
              [max]="paginatorState.pages"
              (input)="paginator.setPage($event.target.value)"
            /> 
            of {{ paginatorState.pages }}
            <span>
              <fa-icon [icon]="faCaretRight" class="paginator-icon fa-lg" (click)="paginator.setNext()"></fa-icon> 
              <fa-icon [icon]="faStepForward" class="paginator-icon fa-sm" (click)="paginator.setPage(paginatorState.pages)"></fa-icon> 
            </span>
          </span>
        </div>
        <div class="paginator-box margin-space-1" *ngIf="!!paginator">
          <span class="typography-1">Size Per Page: </span>
          <button 
            mat-flat-button 
            color="accent" 
            class="filter-button margin-space-1 size-btn" 
            [disabled]="paginatorState.sectionSize === 10" 
            (click)="paginator.setSize(10)">10</button>
          <button 
            mat-flat-button 
            color="accent" 
            class="filter-button margin-space-1 size-btn" 
            [disabled]="paginatorState.sectionSize === 20" 
            (click)="paginator.setSize(20)">20</button>
          <button 
            mat-flat-button 
            color="accent" 
            class="filter-button margin-space-1 size-btn" 
            [disabled]="paginatorState.sectionSize === 50" 
            (click)="paginator.setSize(50)">50</button>
          <button 
            mat-flat-button 
            color="accent" 
            class="filter-button margin-space-1 size-btn" 
            [disabled]="paginatorState.sectionSize === 100" 
            (click)="paginator.setSize(100)">100</button>
          <button 
            mat-flat-button 
            color="accent" 
            class="filter-button margin-space-1 size-btn" 
            [disabled]="paginatorState.sectionSize === paginatorState.totalSize" 
            (click)="paginator.setSize(paginatorState.totalSize)">All</button>
        </div>
      </div>
    </ng-container>
    
    <div class="scroll-x-box">
      <!-- header -->
      <div class="row-header row-container grey-border-bottom-1">
        <div class="col col-20">
          <div class="box-padding-1">Report Type</div>
        </div>
        <div class="col col-10" >
          <div class="box-padding-1">Period</div>
        </div>
        <div class="col col-5">
          <div class="box-padding-1">Version</div>
        </div>
        <div class="col col-40">
          <div class="box-padding-1">Message</div>
        </div>
        <div class="col col-10" >
          <div class="box-padding-1">Last Updated</div>
        </div>
        <div class="col col-10">
          <div class="box-padding-1">User Updated</div>
        </div>
        <div class="col col-5">
          <div class="box-padding-1">Visible</div>
        </div>
      </div>
  
      <div class="data-body">
        <ng-container *ngFor="let data of paginatorState.currentSection; last as isLast; index as i">
          <section 
            [ngClass]="{ 
              'grey-border-bottom-1': !isLast
            }"
          >
            <div class="month-data-row row-container row-body" [attr.data-row-number]="i + 1">
              <div class="col col-20">
                <div class="box-padding-1" [innerHTML]="data.reportDisplayName + ' - Edition ' + data.reportEdition"></div>
              </div>
              <div class="col col-10" [ngSwitch]="data.reportPriodicityName">
                <div class="box-padding-1" *ngSwitchCase="'Quarterly'">
                  {{ data.reportPeriodDate | finraValidValue : 'date-quarter' }}
                </div>
                <div class="box-padding-1" *ngSwitchCase="'Daily'">
                  {{ data.reportPeriodDate | finraValidValue : 'date-month-day-year-full' }}
                </div>
                <div class="box-padding-1" *ngSwitchDefault>
                  {{ data.reportPeriodDate | finraValidValue : 'date-month-year' }}
                </div>
              </div>
              <div class="col col-5">
                <div class="box-padding-1">{{ data.reportVersion === 0 && 'All' || data.reportVersion }}</div>
              </div>
              <div class="col col-40">
                <div class="box-padding-1">
                  <span class="message-text-container">
                    <a
                      title="Edit this report problem"
                      [routerLink]="['/', 'admin', 'report-view', 'report-problems', data.reportProblemId, 'edit']"
                    >
                      {{ data.message }}
                    </a>
                  </span>
  
                  <button
                    mat-flat-button
                    color="accent" 
                    (click)="setEditReport(data)"
                  >
                    {{ data.isEditing ? 'Cancel Edit' : 'Edit' }}
                  </button>
                </div>
              </div>
              <div class="col col-10" >
                <div class="box-padding-1">{{ data.lastUpdated | finraValidValue : 'date-time' }}</div>
              </div>
              <div class="col col-10">
                <div class="box-padding-1">{{ data.userUpdated }}</div>
              </div>
              <div class="col col-5">
                <div class="box-padding-1">
                  <mat-checkbox
                    (change)="onHideToggle($event, data)"
                    [checked]="data.isVisible"
                  ></mat-checkbox>
                </div>
              </div>
            </div>
            <div *ngIf="data.isEditing">
              <div class="col col-100">
                <div class="box-padding-1">
                  <report-admin-report-problems-form
                    [isEditing]="data.isEditing"
                    [reportProblem]="data"
                    [reportTypesList]="reportTypesList"
                    (formSubmitted)="onFormSubmitForProblemUpdate($event)"
                  ></report-admin-report-problems-form>
                </div>
              </div>
            </div>
          </section>
        </ng-container>
      </div>
    </div>
  </section>
</div>
