import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'report-finra-grid-clickablecell',
  templateUrl: './finra-grid-clickablecell.component.html',
  styleUrls: ['./finra-grid-clickablecell.component.scss']
})
export class FinraGridClickableCellComponent implements ICellRendererAngularComp {
  icon: IconDefinition;
  params: ICellRendererParams;
  title: string;
  buttonLabel: string;
  buttonClass: string;
  showButton: boolean;
  isClickable: boolean;
  rowIndex: number;

  handleClick: (
    params: ICellRendererParams,
    rowIndex: number,
    arg?: any
  ) => void = null;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.rowIndex = this.params.node.rowIndex;

    const options = params['colDef']['cellRendererFrameworkOptions'];
    this.title = (
      params.data['title'] ||
      params.data['label'] ||
      options && (
        typeof (options['title']) === 'function' && options['title'](params) ||
        options['title']
      )
    );

    if (options) {
      if (typeof (options['icon']) === 'function') {
        this.icon = options['icon'](params);
      } else {
        this.icon = options['icon'];
      }

      if (typeof (options['buttonClass']) === 'function') {
        this.buttonClass = options['buttonClass'](params);
      } else {
        this.buttonClass = options['buttonClass'];
      }

      if (typeof (options['buttonLabel']) === 'function') {
        this.buttonLabel = options['buttonLabel'](params);
      } else {
        this.buttonLabel = options['buttonLabel'];
      }

      if (typeof (options['isClickable']) === 'function') {
        this.isClickable = options['isClickable'](params);
      } else {
        this.isClickable = options['isClickable'];
      }

      if (options.hasOwnProperty('showButton')) {
        if (typeof (options['showButton']) === 'function') {
          this.showButton = options['showButton'](params);
        } else {
          this.showButton = options['showButton'];
        }
      } else {
        // default to true
        this.showButton = true;
      }

      // this.showButton = options['isClickable'](params);
      this.handleClick = options['handleClick'];
    }
  }

  public triggerClick() {
    if (this.handleClick) {
      this.handleClick(this.params, this.rowIndex, this);
    }
  }

  refresh(): boolean {
    return false;
  }
}
