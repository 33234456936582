<div
  class="reports-main-container"
  [ngStyle]="{ 'min-height': clientHeight - footerHeight + 'px' }"
  fxLayout="column"
>
  <report-loader></report-loader>
  <report-alert class="report-main-content-padding no-print"></report-alert>
  <div
    fxLayoutGap="15px"
    fxLayoutAlign="space-between center"
    fxLayout="row wrap"
    class="reports-title-container report-main-content-padding"
  >
    <div fxFlex="1 1 calc(vw - 5%)">
      <h1 class="title">{{ title2 }}</h1>
    </div>
    <div
      fxFlex="1"
      (click)="changeView('card')"
      class="view-icon"
      [ngClass]="{ 'active-icon': isActive && template == 'card' }"
    >
      <fa-icon [icon]="faThLarge" class="fa-lg"></fa-icon>
    </div>
    <div
      fxFlex="2"
      (click)="changeView('list')"
      class="view-icon"
      [ngClass]="{
        'active-icon': isActive && template == 'list'
      }"
    >
      <fa-icon [icon]="faBars" class="fa-lg"></fa-icon>
    </div>
  </div>
  <div class="cards-filter-main-container report-main-content-padding">
    <div *ngIf="!userAcessible" class="white-box">
      Your account does not have permission to view these reports. Please
      contact your firm's Super Account Administrator or the
      <a href="mailto:reportcenter-admin@finra.org">Report Center Team</a>
    </div>


    <report-cards-filter
      *ngIf="isVisible && userAcessible"
      (firmFilter)="triggerFirmFilter($event)"
      [mpidsEWS]="mpidsEWS"
      [isEWSUser]="isEWSUser"
      [isSROUser]="isSROUser"
      [isFRBUser]="isFRBUser"
      [showPublishedOptions]="showPublishedOptions"
      [categoryId]="category"
    ></report-cards-filter>

    <report-error-message
      *ngIf="userAcessible"
      [errorMessage]="errorMessage"
    ></report-error-message>
  </div>
  <div *ngIf="userAcessible && !hasNoData">
    <ng-container [ngSwitch]="template">
      <!-- Card Template -->
      <ng-container *ngSwitchCase="'card'">
        <div
          fxlayoutAlign="center stretch"
          fxFlexFill
          class="report-main-content-padding"
        >
          <div class="report-card-container">
            <div *ngFor="let report of reportsData">
              <a [routerLink]="report.url" (click)="sendReportOpenedBeastEvent(report)">
                <report-card

                  [isDQS]="isDqs"
                  [template]="template"
                  [cardSectionUnread]="true"
                  [isExternalUser]="isExternalUser"
                  [faIcon]="report.isUnread ? faBellSolid : faBell"
                  fxFill="100"
                  [reportDate]="getDateLabel(report.reportPeriodDate, report['reportConfiguration']['reportType'].reportPeriodicityId)"
                  [reportVersion]="report.reportDataVersion"
                  [reportSubHeading]="
                    !(
                      report.reportConfiguration.reportType.reportCategoryId == 3 &&
                      report.reportFirmId.match('[A-Za-z]{4}')
                    )
                      ? report['reportConfiguration'].reportDisplayName
                      : report['reportConfiguration'].reportDisplayName +
                        ' - MPID: ' +
                        report.reportFirmId
                  "
                  [reportPeriod]="
                    getPeriodInText(
                      report['reportConfiguration']['reportType']
                        .reportPeriodicityId
                    )
                  "
                ></report-card>
              </a>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- List Template -->
      <ng-container *ngSwitchCase="'list'">
        <div class="report-main-content-padding">
          <div *ngFor="let report of reportsData">
            <a [routerLink]="report.url" (click)="sendReportOpenedBeastEvent(report)">
              <report-card

                [isDQS]="isDqs"
                fxLayout="column wrap"
                fxLayoutAlign="none"
                [template]="template"
                [cardSectionUnread]="true"
                [faIcon]="report.isUnread ? faBellSolid : faBell"
                class="report-list-container"
                [reportDate]="getDateLabel(report.reportPeriodDate, report['reportConfiguration']['reportType'].reportPeriodicityId)"
                [reportVersion]="report.reportDataVersion"
                [reportSubHeading]="
                  !(
                    report.reportConfiguration.reportType.reportCategoryId == 3 &&
                    report.reportFirmId.match('[A-Za-z]{4}')
                  )
                    ? report['reportConfiguration'].reportDisplayName
                    : report['reportConfiguration'].reportDisplayName +
                      ' - MPID: ' +
                      report.reportFirmId
                "
                [reportPeriod]="
                getPeriodInText(
                  report['reportConfiguration']['reportType'].reportPeriodicityId
                )
                "
              ></report-card>
            </a>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- <button (click)="openReportDetail(20009194, 1, 67)">
  Firm Summary Scorecard
</button> -->
