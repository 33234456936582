import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PEER_GROUP_COL_DEF,
  PEER_GROUP_DESC
} from 'src/app/configs/model/equities-report.model';
import { IconDefinition, faTimes, faPrint, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import {Grid, GridOptions, GridReadyEvent} from "ag-grid-community";
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { HelperService } from 'src/app/services/helper.service';
import { PdfService } from 'src/app/services/pdf.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { PdfButtonStatus } from 'src/app/enums/pdf-btn-status.enum';

export interface DialogData {
  rowData: Array<any>;
  reportName: string;
  reportView: string;
  period: string;
  tierCode?: string;
  peerGroup?: string;
  columnTitle: string;
  useField: string;
  reportInstanceMetadata: ReportInstanceMetadata;
  print?(args?: any): void;
}

@Component({
  selector: 'peer-group',
  templateUrl: './peer-group.component.html',
  styleUrls: ['./peer-group.component.scss']
})
export class PeerGroupComponent implements OnInit {
  printElClassName = 'mat-dialog-container';
  columnDefs = PEER_GROUP_COL_DEF;
  description = PEER_GROUP_DESC;
  pdfButtonStatus: PdfButtonStatus = PdfButtonStatus.ENABLED;

  faTimes: IconDefinition = faTimes;
  faPrint: IconDefinition = faPrint;
  faSpinner: IconDefinition = faSpinner;

  modalRootDivId = 'peer-group-modal';
  gridOptions: GridOptions;
  @ViewChild('finraGrid',{static:false}) finraGrid:any;
  private gridWidth: string;
  private gridHeight: string;
  private gridDomLayout: any;
  peerPrintGridId="peerPrintGridId";

  constructor(
    public dialogRef: MatDialogRef<PeerGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private pdfService: PdfService,
    private helperService: HelperService,
    private toolbarService: ToolbarService,
  ) {
    if (!this.gridOptions) {
      this.gridOptions ={};
    }
  }

  ngOnInit() {
    // allow other report categories to use this PeerGroupComponent
    this.columnDefs[0].headerName = this.data.columnTitle;
    this.columnDefs[0].field = this.data.useField;
    this.pdfService.processing.subscribe(x => {
      this.pdfButtonStatus = x
        ? PdfButtonStatus.BUSY
        : PdfButtonStatus.ENABLED;
    });
  }

  closeDilog() {
    this.dialogRef.close();
  }

  isGeneratingPdf() {
    return this.pdfButtonStatus === PdfButtonStatus.BUSY;
  }

  showPdf() {
    return this.pdfButtonStatus !== PdfButtonStatus.UNAVAILABLE;
  }

  onExportPdf() {
    if (this.data.print) {
      this.data.print();
    } else {
      // use default/generic print method
      this.exportPdf();
    }
  }

  exportPdf() {
    /** New */
    const firmId = this.data.reportInstanceMetadata.reportFirmId;
    const { reportName, tierCode, peerGroup, period } = this.data;
    const useCode = tierCode
      ? `TierCode_${tierCode}`
      : peerGroup
        ? `PeerGroup_${peerGroup}`
        : '';
    const fileName = `PeerGroup_${reportName}_${firmId}_${period}_${useCode}.pdf`;
    const useFileName = fileName.replace(/\s/gi, '_');

    const rootElm = window.document.getElementById(this.modalRootDivId);
    this.toolbarService.onExportPdf(useFileName, null, [rootElm]);

    /** Old */
    // const report = this.data.reportInstanceMetadata;
    // const reportName = this.data.reportName.replace(/\s/gi, '-');
    // const reportView = this.data.reportView.replace(/\s/gi, '-');
    // const tierCode = this.data.tierCode;
    // const peerGroup = this.data.peerGroup ;

    // const filenameTemplate =
    //   'Peer-Group-Data-' + reportView +
    //   (tierCode && '_tiercode-' + tierCode || '') +
    //   (peerGroup && '_peerGroup-' + peerGroup || '') +
    //   '_${firmId}_${reportPeriodDate}_PDF';

    // const reportInfo = {
    //   reportFirmId: report && report.reportFirmId || reportName,
    //   reportPeriodDate: report && report.reportPeriodDate || this.data.period
    // };
    // const fileName = this.helperService.getDownloadFileName(
    //   reportInfo,
    //   filenameTemplate
    // );

    // this.pdfService.processing.next(true);
    // this.pdfService.exportPdf(fileName, 3900, 2).then(() => {
    //   this.pdfService.processing.next(false);
    // });
  }

  htmlPrint() {
    const firmId = this.data.reportInstanceMetadata.reportFirmId;
    const { reportName, tierCode, peerGroup, period } = this.data;
    const useCode = tierCode
      ? `TierCode_${tierCode}`
      : peerGroup
        ? `PeerGroup_${peerGroup}`
        : '';
    const fileName = `PeerGroup_${reportName}_${firmId}_${period}_${useCode}.pdf`;
    const useFileName = fileName.replace(/\s/gi, '_');

    this.preparePrint();
    const printElement = window.document.getElementsByClassName(this.printElClassName);
    this.toolbarService.htmlPrint(<HTMLElement>printElement[0],useFileName);
    this.resetPrint();
  }

  preparePrint(){
    var eGridDiv:HTMLElement = document.getElementById(this.peerPrintGridId);
    this.gridWidth=eGridDiv.style.width;
    this.gridHeight=eGridDiv.style.height;
    this.gridDomLayout=this.finraGrid.gridOptions['domLayout'];
    eGridDiv.style.width='';
    eGridDiv.style.height='';
    this.finraGrid.gridOptions.api.setDomLayout('print');
  }


  resetPrint() {
    var eGridDiv:HTMLElement = document.querySelector('#'+this.peerPrintGridId);
    eGridDiv.style.width = this.gridWidth;
    eGridDiv.style.height = this.gridHeight;
    this.finraGrid.gridOptions['domLayout']=this.gridDomLayout;
  }


  onGridReady($event: any) {
    this.gridOptions.api=$event.api;
  }
}
