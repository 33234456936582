import { MatSelectChange } from '@angular/material/select';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { PlainObject } from 'src/app/shared/utils/utils';

/**
 * Finra Toolbar Model Interface
 * ---
 *
 * This file outlines the configurations for constructing the report toolbar.
 * It allows the toolbar to be very flexible and customizable/configurable.
 *
 * The toolbar has three main aspects/components: `labels`, `buttons`, and `controls`.
 * Each of these will fit into a `cell` and many cells will fit into a `row`,
 * Which will be accomplished with CSS flex-grid.
 *
 * Every report type page is responsible for defining the configuration for the toolbar
 * since there are case-by-case scenarios. For example, markup markdown has controls that other reports don't
 */



/**
 * This enum lists all of the toolbar components.
 * Each `ToolbarComponent` needs an interface that defines the configuration for it.
 *
 * NOTE: the `(arg: any) => string | string;` type is to allow for dynamic values.
 * The type is basically saying `function` or `string`. The function is if the value(s)
 * depends on some information.
 */

export enum ToolbarComponentTypes {
  LABEL = 1,
  BUTTON,
  CONTROL,
}

export enum ToolbarControlComponentTypes {
  SELECT = 1,
  TEXT_INPUT,
  DATE_PICKER,
}

export interface ToolbarComponent {
  type: ToolbarComponentTypes;
}

export interface ToolbarLabelComponent extends ToolbarComponent {
  type: ToolbarComponentTypes.LABEL;
  label: string;
  labelId: string;
  value?: string;
  valueObject?: PlainObject; // use obj ref for easily changing value
  useValueObject?: boolean;
  valueObjectProp?: string;
  displayPipeFilter?: string;
  displayPipeFilterArg?: string;
  labelIcon?: IconDefinition;
  labelIconId?: string;
  labelIconTitle?: string;
  iconClickHandler?: (arg?: any) => void;
}

export interface ToolbarButtonComponent extends ToolbarComponent {
  type: ToolbarComponentTypes.BUTTON;
  buttonClass?: string;
  buttonStyle?: { [key: string]: string; };
  buttonId?: string;
  buttonIconClass: string;
  buttonLabel?: string; // this is the text inside the <button> tag
  buttonLabelObject?: PlainObject;
  useLabelObject?: boolean;
  labelObjectProp?: string;
  spin?: boolean;
  buttonIcon?: IconDefinition;
  clickHandler?: (arg?: any) => void;
}

/**
 * this interface is not meant to be implemented;
 * it only serves as a parent interface, like abstract.
 * Use the extended interfaces
 */
export interface ToolbarControlComponent extends ToolbarComponent {
  type: ToolbarComponentTypes.CONTROL;
  controlType: ToolbarControlComponentTypes;
  controlLabel: string;
  formControl?: FormControl;
  formControlName?: string;
  formControlClass?: string;
  formControlStyle?: { [key: string]: string | number; };
  isDisabled?: boolean;
}


export interface ToolbarSelectControlComponent extends ToolbarControlComponent {
  controlType: ToolbarControlComponentTypes.SELECT;
  useObjectDisplayProperty?: boolean; // this is for referencing a property of the item rather than the value itself.
  useObjectValueProperty?: boolean; // this is for referencing a property of the item rather than the value itself.
  displayProp?: string;
  valueProp?: string;
  displayPipeFilter?: string;
  displayPipeFilterArg?: string;
  onSelectionChange?: (event: MatSelectChange) => any;
  dataList: any[];
}

export interface ToolbarDatePickerControlComponent extends ToolbarControlComponent {
  controlType: ToolbarControlComponentTypes.DATE_PICKER;
  onDateInput?: (eventType: string, event: MatDatepickerInputEvent<Date>) => any;
  matDatepickerFilter: (date: Date) => boolean;
  datePickerName: string;
  datePickerLabel: string;
  datePickerIcon: IconDefinition;
}


export interface ToolbarRow {
  rowClass: string;
  rowId?: string;
  rowStyle?: { [key: string]: any; };
  components: ToolbarCell[];
  hideRow?: boolean;
}

export type CellComponent =
  ToolbarLabelComponent |
  ToolbarButtonComponent |
  ToolbarSelectControlComponent |
  ToolbarDatePickerControlComponent;

/**
 * Cells can have cells in them!
 */
export interface ToolbarCell {
  cellTag?: string;
  cellClass?: string;
  cellId?: string;
  cellStyle?: { [key: string]: any; };
  hideCell?: boolean;
  cellReadyCallback?: (...arg: any) => any;

  component?: CellComponent;
  components?: ToolbarCell[];
}

export interface ToolbarConfig {
  containerClass?: string;
  rows: ToolbarRow[];
  description: string;
  toolbarReadyCallback?: (arg?: any) => any;
}

/**
 * Config
 * ---
 *
 * When composing a toolbar, it is likely that a function will be needed
 * to pass in specific information, like click handlers, data for control values, etc.
 * This interface determines what is required when composing a toolbar since
 * all toolbar instance have similarities. For example, every toolbar must have
 * a firm label and a firm name; the value actual values may vary. This goes for
 * period, version, print, etc. Everything else can be handled case by case (case meaning configuration).
 */

export interface ToolbarConfigOptions extends PlainObject {
  // properties
  description: string;
  firmNameLabel: string;
  firmNameValue: string;
  firmIdLabel: string;
  firmIdValue: string;
  periods: any[];
  versions: any[];
  views: any[];

  // methods
  pdfClickHandler: (arg?: any) => void;
  changeClickHandler?: (arg?: any) => void;
  summaryExportClickHandler?: (arg?: any) => void;
  detailsClickHandler: (arg?: any) => void;

  // custom
  [key: string]: any;
}

export interface ToolbarConfigMapping {
  [key:string]: (config: ToolbarConfigOptions) => ToolbarConfig;
}



/**
 * Shared Generic Constants
 *
 */

export const COMMON_PDF_PRINT_BUTTON_CELL_TAG = `pdf-print-btn`;
