<div class="trace-container">
  <report-loader></report-loader>
  <report-alert class="report-main-content-padding no-print"></report-alert>
  <h1 class="title report-main-content-padding pdf">
    {{ title }}
  </h1>
  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="traceToolbarConfig"
    [toolbarConfig]="traceToolbarConfig"
  ></report-finra-toolbar>

  <div id="grid-container" class="grid-container" *ngIf="reportLoadedSuccessfully">
    <div class="tables-container" [ngSwitch]="reportName">
      <ng-container *ngSwitchCase="TraceReportShortNames.TIMELINESS">
        <div class="table-container pdf" *ngFor="let mapping of TRACE_TIMELINESS_DATA_MAPPINGS">
          <div class="table-header">
            <div class="table-title">
              <span
                class="link-btn"
                (click)="currentTimelinessDataView = 'table'"
                [ngClass]="{ 'link-inactive': currentTimelinessDataView == 'chart' }"
              >
                {{ TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING[mapping] }}
              </span>
              <span class="link-inactive">|</span>
              <span
                class="link-btn"
                (click)="currentTimelinessDataView = 'chart'"
                [ngClass]="{ 'link-inactive': currentTimelinessDataView == 'table' }"
              >
                Charts
              </span>
            </div>


          </div>

          <ng-container [ngSwitch]="currentTimelinessDataView">
            <report-finra-grid
              *ngSwitchCase="'table'"
              [columnDefs]="columnDefs[mapping]"
              [rowData]="rowData[mapping]"
              [autoGroupColumnDef]="autoGroupColumnDefs"
            ></report-finra-grid>

            <report-finra-chart
              *ngSwitchCase="'chart'"
              [template]="'line-chart'"
              [legendLabel]="'Legend'"
              [legendPosition]="'below'"
              [showLegend]="true"
              [autoScale]="true"
              [rowData]="chartData[mapping]"
              [yAxisTickFormatting]="TRACE_TIMELINESS_Y_AXIS_FORMATTING[mapping]"
              [lineTooltipTemplateValueFormatter]="TRACE_TIMELINESS_LINE_TOOLTIP_VALUE_FORMATTER[mapping]"
              [lineSeriesTooltipTemplateValueFormatter]="TRACE_TIMELINESS_LINE_SERIES_TOOLTIP_VALUE_FORMATTER[mapping]"
            ></report-finra-chart>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div *ngFor="let view of traceTableViews" class="table-container pdf">
          <div *ngIf="view == 'lateTrade'" class="def-link">
            <a
              target="_blank"
              [href]="peerGroupHelpLink"
            >
              Peer Group Definitions
            </a>
          </div>
          <div class="table-header" *ngIf="tableTitles[view]">
            {{ tableTitles[view] }}
          </div>
          <report-finra-grid
            #reportfinragrid
            id="id"
            [columnDefs]="columnDefs[view]"
            [rowData]="rowData[view]"
            [autoGroupColumnDef]="autoGroupColumnDefs"
          ></report-finra-grid>
        </div>
      </ng-container>
    </div>
  </div>
  <hr />
  <div
    class="report-main-content-padding report-disclaimer pdf"
    *ngIf="disclaimers[reportName]"
    [innerHtml]="disclaimers[reportName]"
  ></div>
</div>
