import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';

import {
  IconDefinition,
  faTimes,
  faExclamationTriangle
} from '@fortawesome/pro-light-svg-icons';

import {
  AlertItem,
} from 'src/app/interfaces/alert-item.interface';

@Component({
  selector: 'report-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnChanges {
  @Input() alertItem: AlertItem;
  @Output() alertClosed: EventEmitter<AlertItem> = new EventEmitter<AlertItem>();
  show: boolean;

  alertClass: string;
  faTimes: IconDefinition = faTimes;
  faExclamationTriangle: IconDefinition = faExclamationTriangle;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.alertItem) {
      this.show = true;
      this.alertClass = `alert alert-${this.alertItem.type}`;
      if (this.alertItem.autoClose) {
        const duration = this.alertItem.duration || 3000;
        setTimeout(() => {
          this.closeAlert();
        }, duration);
      }
    }
  }

  closeAlert() {
    this.show = false;
    // in case we want to tell the parent that the alert was closed.
    this.alertClosed.emit(this.alertItem);
  }
}
