import {
  IconDefinition,
  faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Location } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup, FormControl } from "@angular/forms";
import { Subscription, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  CrossMarketSupervisionViewConfig,
  CrossMarketEquitiesSupervisionReport,
  CrossMarketSupervisionSummaryChartConfig,
  ChartData,
  CrossMarketEquitiesSupervisionGridHeader,
  CrossMarketEquitiesSupervisionData,
  CrossMarketSupervisionSummaryGridConfig,
  exportCrossMarketSupervisionSummaryData,
} from "src/app/configs/model/cross-manipulation-report.model";
import {
  ToolbarConfig,
  ToolbarConfigOptions,
} from "src/app/configs/model/finra-toolbar.model";
import { ReportInstanceMetadata } from "src/app/configs/model/reports.model";
import { BeastOtherActions, BeastClickActions } from "src/app/enums/beast.enum";
import {
  BeastLeaveReportPageInfo,
  BeastClickEventReportHelpLinkInfo,
} from "src/app/interfaces/beast.interface";
import { BeastService } from "src/app/services/beast.service";
import { CrossMarketService } from "src/app/services/cross-market.service";
import { HelpLinksService } from "src/app/services/help-links.service";
import { HelperService } from "src/app/services/helper.service";
import {
  ReportPageMetadataInfo,
  ReportService,
} from "src/app/services/report.service";
import { ReportsService } from "src/app/services/reports.service";
import { ToolbarService } from "src/app/services/toolbar.service";
import { UnreadReportsService } from "src/app/services/unread-reports.service";
import { saveCsv } from "src/app/shared/utils/utils";
import { CrossMarketSupervisionToolbarConfig } from "src/app/configs/toolbar-config/cross-market-supervision.toolbar";



@Component({
  selector: 'report-cross-market-supervision',
  templateUrl: './cross-market-supervision.component.html',
  styleUrls: ['./cross-market-supervision.component.scss']
})
export class CrossMarketSupervisionComponent implements OnInit, OnDestroy {
  private viewTemplateConfiguration: CrossMarketSupervisionViewConfig;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  disclaimer: string;
  reportType: string = 'monthly';
  buttonEnabled: boolean = false;
  footerHeight: number = 220;
  firstIsVisible: boolean = false;
  secondIsVisible: boolean = false;
  exportParams: Object = {};
  crossMarketExportConfig: any = {};
  title: string;
  description: string;
  reportName: string;
  reportId: number;
  reportTypeId: number;
  reportVersion: number;
  viewName: string;
  category: string;
  clientHeight: number;
  reportInstanceMetadata: ReportInstanceMetadata;
  summaryResponse: CrossMarketEquitiesSupervisionReport;
  reportLoadedSuccessfully: boolean;

  /** Chart Props */

  chartConfig: CrossMarketSupervisionSummaryChartConfig;
  chartData: ChartData[] = [];
  chartTemplate: string;
  chartTitle: string;
  xValues: number;
  yAxisLabel: string;
  chartType: string;
  chartView: string;
  yAxisTicks: number;
  chartAutoScale: boolean;
  isChartDataReady: boolean = false;

  /** Grid Props */

  gridColumnDefs: any[] = CrossMarketEquitiesSupervisionGridHeader;
  gridTitle: string;
  gridData: CrossMarketEquitiesSupervisionData[] = [];
  gridConfig: CrossMarketSupervisionSummaryGridConfig;
  gridColumnsCount: number;
  gridHelp: string;
  isGridDataReady: boolean = false;

  /** Toolbar Props */

  crossMarketSupervisionToolbarConfig: ToolbarConfig;
  crossMarketSupervisionToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  reportPageMetadataInfo: ReportPageMetadataInfo;
  periodChangeSubscription: Subscription;
  loadedAt: number;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private crossMarketService: CrossMarketService,
    private reportsService: ReportsService,
    private helperService: HelperService,
    private baseReportService: ReportService,
    private toolbarService: ToolbarService,
    private unreadReportsService: UnreadReportsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {
    this.toolbarForm = new FormGroup({
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl()
    });
  }

  ngOnInit() {
    this.clientHeight = window.innerHeight;
    this.category = this.activatedRoute.snapshot.data.category;
    this.activatedRoute.params.subscribe(params => {
      this.handleParamsChange(params);
    });
    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
        reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: '',
        rating: '',
        product: '',
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.reportVersion = parseInt(params.version, 10);
    this.reportTypeId = parseInt(params.type, 10);
    this.reportId = params.reportId;
    this.reportName = params.reportName;
    this.viewName = params.viewName;
    this.getReportConfigurationMetadata().subscribe(
      (pageData) => {
        this.processReportConfigurationMetadata(pageData);
        this.setToolbar();
        this.getSummaryReportData().subscribe((data) => {
          this.handleSummaryData(data);
        });
      }
    );
  }

  openHelp() {
    this.helpLinksService.open(this.reportName);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  setToolbar() {
    const customOptions: Partial<ToolbarConfigOptions> = {
      description: this.description,
      hideViewsCell: true,
      hideDetailsButton: false,
      setHelpClickHandler: () => {
        this.openHelp();
      },
    };

    const results = this.toolbarService.createToolbarConfig(
      this,
      CrossMarketSupervisionToolbarConfig,
      customOptions
    );
    this.crossMarketSupervisionToolbarConfigOptions = results.toolbarConfigOptions;
    this.crossMarketSupervisionToolbarConfig = results.toolbarConfig;
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          throw error;
        })
      );
  }

  handleSummaryData(data) {
    this.summaryResponse = data;
    this.processSummaryChartData(data);
    this.processSummaryGridData(data);
    this.reportLoadedSuccessfully = true;
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
    this.viewTemplateConfiguration = this.reportsService.getViewTemplateConfiguration(
      data.reportInstanceMetadata,
      this.viewName
    );

    this.title = this.viewTemplateConfiguration['title'];
    this.description = `${this.viewTemplateConfiguration['description']}<p>${this.viewTemplateConfiguration['disclaimer']}</p>`;
    this.disclaimer = this.viewTemplateConfiguration['disclaimer'];
    this.chartConfig = this.viewTemplateConfiguration.template.component1;
    this.gridConfig = this.viewTemplateConfiguration.template.component2;
    this.chartTitle = this.chartConfig.options.title;
    this.gridTitle = this.gridConfig.options.title;
  }

  getSummaryReportData(): Observable<CrossMarketEquitiesSupervisionReport> {
    this.isChartDataReady = false;
    this.isGridDataReady = false;
    const requestObs = this.crossMarketService
      .getCrossMarketEquitiesSupervisionReport(this.reportInstanceMetadata)
      .pipe(
        map((data) => {
          const formattedData = this.crossMarketService.processCrossMarketEquitiesSupervisionReport(
            data,
            this.reportInstanceMetadata
          );
          return formattedData;
        }),
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportSummaryDataError(error);
          this.reportLoadedSuccessfully = false;
          throw error;
        })
      );
    return requestObs;
  }

  processSummaryChartData(data: CrossMarketEquitiesSupervisionReport) {
    this.chartData = this.crossMarketService.getCrossMarketAtsReportAsGraphSeries(data);
    this.chartTemplate = 'line-chart';
    const options = this.chartConfig.options;
    this.xValues = options.xValues;
    this.yAxisLabel = options.yAxisLabel;
    this.yAxisTicks = options.yAxisTicks;
    this.chartType = options.chartType;
    this.chartView = options.view;
    this.chartAutoScale = options.autoScale;
    this.isChartDataReady = true;
  }

  processSummaryGridData(data: CrossMarketEquitiesSupervisionReport) {
    const options = this.gridConfig.options;
    this.gridData = data.reportData;
    this.gridHelp = options['help'];
    const clickHandler = (params: any, rowIndex: number) => {
      this.handleClick(rowIndex);
    };
    this.gridColumnDefs[0].cellRendererFrameworkOptions.handleClick = clickHandler;
    this.crossMarketService.formatGridColumnDefsHeaders(
      this.reportInstanceMetadata.reportPeriodDate,
      this.gridColumnDefs
    );
    this.isGridDataReady = true;
  }

  downloadFile(fileName: string, additionalInfo: string = '') {
    const exportUrl: string = this.baseReportService.getReportUrl(
      this.reportId,
      this.viewName,
      'd',
      additionalInfo
    );
    this.helperService.downloadFile(exportUrl);
  }

  handleClick(rowIndex: number) {
    const title = this.gridData[rowIndex].title;
    const fileName = this.viewTemplateConfiguration.downloadFileName.replace(
      '${title}',
      title
    );
    this.downloadFile(fileName, title);
  }

  onReportDetails() {
    const exportUrl: string = this.baseReportService.getReportUrl(
      this.reportId,
      this.viewName,
      'd'
    );
    this.helperService.downloadFile(exportUrl);
  }

  goBack() {
    this.location.back();
  }

  async summaryDataExport() {
    const fileNameResponse = await this.baseReportService.getReportFileName(
      this.reportId,
      this.viewName,
      's'
    ).toPromise();

    const csvString = exportCrossMarketSupervisionSummaryData(
      this.title,
      this.gridData,
      CrossMarketEquitiesSupervisionGridHeader
    );
    saveCsv(csvString, fileNameResponse.fileName);
    this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
  }
}
