import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ReportService } from './report.service';
import { HelperService } from './helper.service';
import {
  CustomerDebitsMonthly,
  parseMonthlyCustomerDebitsSumary,
  UnsecuredCustomerDebitsMonthlySummary,
  MarginCustomerDebitsMonthlySummary,
  CustomerDebitsDaily,
  createEmptyCustomerDebitsMonthSummaryObj
} from 'src/app/configs/model/risk-monitoring/customer-debits.model';
import {
  getPrior12Months,
  getMonthYrDate,
  getPrior12Quarters,
  quarterDateFormat,
  getCompatibleDate,
} from '../shared/utils/utils';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DOWNLOAD_FILE_NAMES, RiskMonitoringReportTypes } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import {
  CanceledAsOfTradesMonthly,
  createEmptyCanceledAsOfTradesMonthSummaryObj,
  parseMonthlyCanceledAsOfTradesSumary,
  AllTradesMonthlySummary,
  AsOfTradesMonthlySummary,
  BeforeSettlementTradesMonthlySummary,
  AfterSettlementTradesMonthlySummary,
  CanceledAsOfTradesDaily,
  parseDailyCanceledAsOfTradesSumary
} from 'src/app/configs/model/risk-monitoring/canceled-as-of-trades.model';
import {
  QuarterlyRegisteredRepsData,
  createEmptyQuarterlyRegisteredRepsObj,
  parseQuartlyRegisteredRepsSummary,
  RegisteredRepsUnion,
  SEVERELY_DISCIPLINED_FIRMS_COLUMN_DEFS
} from 'src/app/configs/model/risk-monitoring/registered-reps-composition.model';
import { MatDialog } from '@angular/material/dialog';
import {
  FIRM_MODEL,
  INDUSTRY_MODEL,
  SalesPracticeComplaintFirmData,
  SalesPracticeComplaintIndustryData,
  SalesPracticeComplaintSummaryResponse
} from 'src/app/configs/model/risk-monitoring/sales-practice-complaint.model';
import {
  transformCustomerComplaintsSummaryDataResponse,
  CustomerComplaintTransformedResponse,
  CustomerComplaintSummary,
  CustomerComplaintSummaryResponse
} from 'src/app/configs/model/risk-monitoring/customer-complaint.model';
import { SeverelyDisciplinedFirmsModalComponent } from '../components/pages/reports/summary/risk-monitoring/registered-reps-composition/firms-modal/firms-modal.component';
import { RegisteredRepsMasterListElement } from '../components/pages/reports/summary/risk-monitoring/registered-reps-composition/registered-reps-composition.component';

@Injectable()
export class RiskMonitoringService {
  constructor(
    private http: HttpClient,
    private baseReportService: ReportService,
    private helperService: HelperService,
    private dialog: MatDialog
  ) {}

  checkSummaryResponse(
    response,
    reportTypeId: RiskMonitoringReportTypes
  ) {
    switch (reportTypeId) {
      case RiskMonitoringReportTypes.REGISTERED_REPRESENTATIVES:
      case RiskMonitoringReportTypes.CANCELED_AS_OF_TRADES:
      case RiskMonitoringReportTypes.CUSTOMER_DEBITS: {
        const isEmptyArray = Array.isArray(response) && response.length === 0;
        if (isEmptyArray) {
          return false;
        }
        return true;
      }

      default: {
        return true;
      }
    }
  }

  /** Customer Debits Methods */

  transformCustomerDebitsMonthsSummaryList(
    customerDebitsMonthly: CustomerDebitsMonthly[]
  ): CustomerDebitsMonthly[] {
    // get the prior twelve months from this report date
    const monthsList = getPrior12Months(
      customerDebitsMonthly[0].rptPeriod,
      true
    );
    const list: CustomerDebitsMonthly[] = [];
    monthsList.forEach(m => {
      const summaryMonth = customerDebitsMonthly.find(
        s => s.rptPeriod === m.dateStr
      );
      if (!!summaryMonth) {
        list.push(summaryMonth);
      } else {
        const gapMonth = createEmptyCustomerDebitsMonthSummaryObj(m.dateStr);
        list.push(gapMonth);
      }
    });
    return list;
  }

  getCustomerDebitsMonthlySummaryReport(
    reportId: string | number,
    viewName
  ): Observable<CustomerDebitsMonthly[]> {
    const summaryMonthlyDataObs = this.baseReportService.getReport(
      reportId,
      viewName,
      's',
      null
    );
    return summaryMonthlyDataObs.pipe(
      map((monthlySummaryResponse: CustomerDebitsMonthly[]) => {
        // modify response here if needed
        return monthlySummaryResponse;
      })
    );
  }

  getAndProcessCustomerDebitsDailySummaryReport(
    summaryData: CustomerDebitsMonthly,
    unsecuredMonthlySummary: UnsecuredCustomerDebitsMonthlySummary,
    marginMonthlySummary: MarginCustomerDebitsMonthlySummary
  ): Observable<void> {
    const summaryDailyData = this.baseReportService.getReport(
      summaryData.rptId,
      'Daily',
      's',
      null
    );
    return summaryDailyData.pipe(
      map((dailyDataResponse: CustomerDebitsDaily[]) => {
        unsecuredMonthlySummary.detailsData = dailyDataResponse;
        marginMonthlySummary.detailsData = dailyDataResponse;
        return null;
      }),
      catchError((error: HttpErrorResponse) => {
        unsecuredMonthlySummary.detailsData = [];
        marginMonthlySummary.detailsData = [];
        return of(null);
      })
    );
  }

  transformMonthlyCustomerDebitsReport(
    summaryData: CustomerDebitsMonthly
  ): {
    unsecuredMonthlySummary: UnsecuredCustomerDebitsMonthlySummary;
    marginMonthlySummary: MarginCustomerDebitsMonthlySummary;
  } {
    // parse the summary
    const parsedDebitsReports = parseMonthlyCustomerDebitsSumary(summaryData);
    const unsecuredMonthlySummary: UnsecuredCustomerDebitsMonthlySummary =
      parsedDebitsReports[0];
    const marginMonthlySummary: MarginCustomerDebitsMonthlySummary =
      parsedDebitsReports[1];

    // create month/year property for display
    unsecuredMonthlySummary.monthYear = getMonthYrDate(summaryData.rptPeriod);
    marginMonthlySummary.monthYear = getMonthYrDate(summaryData.rptPeriod);

    /*
      Get the daily details data for this month.

      this happens asynchronously (AJAX/HTTP call), which is okay because of how scoping works in javascript.
      this lexical scope still has access to the processed data (`unsecuredMonthlySummary` and `marginMonthlySummary`)
      before the request finishes. when this requests succeeds and the callback runs,
      it mutates the objects by reference, which at that time,
      is already rendered to the view; angular will simply re-render with the updates
      p.s: the months and days are already sorted by the backend, which is a plus for the Frontend :)
    */
    if (summaryData.rptId) {
      this.getAndProcessCustomerDebitsDailySummaryReport(
        summaryData,
        unsecuredMonthlySummary,
        marginMonthlySummary
      ).subscribe();
    } else {
      unsecuredMonthlySummary.detailsData = [];
      marginMonthlySummary.detailsData = [];
    }

    return {
      unsecuredMonthlySummary,
      marginMonthlySummary
    };
  }

  processCustomerDebitsSummaryReport(
    monthlySummaryResponse: CustomerDebitsMonthly[]
  ) {
    const newList = this.transformCustomerDebitsMonthsSummaryList(
      monthlySummaryResponse
    );
    const unsecuredDebitsMonthly: UnsecuredCustomerDebitsMonthlySummary[] = [];
    const marginDebitsMonthly: MarginCustomerDebitsMonthlySummary[] = [];

    newList.forEach((summaryData: CustomerDebitsMonthly) => {
      const result = this.transformMonthlyCustomerDebitsReport(summaryData);
      unsecuredDebitsMonthly.push(result.unsecuredMonthlySummary);
      marginDebitsMonthly.push(result.marginMonthlySummary);
    });

    return {
      unsecuredDebitsMonthly,
      marginDebitsMonthly,
      customerDebitsMonthly: newList
    };
  }

  async customerDebitsCanceledAsOfTradesOnReportDetails(
    reportTypeShortName: string,
    reportFirmId: string,
    reportPeriodDate: string,
    reportId: number,
    viewName: 'Monthly' | 'Daily'
  ) {
    const exportUrl: string = this.baseReportService.getReportUrl(
      reportId,
      viewName,
      'd',
      reportPeriodDate
    );

    this.helperService.downloadFile(exportUrl);
  }

  /** Canceled As-Of Trades Methods */

  transformCanceledAsOfTradesMonthsSummaryList(
    customerDebitsMonthly: CanceledAsOfTradesMonthly[]
  ): CanceledAsOfTradesMonthly[] {
    // get the prior twelve months from this report date
    const monthsList = getPrior12Months(
      customerDebitsMonthly[0].rptPeriod,
      true
    );
    const list: CanceledAsOfTradesMonthly[] = [];
    monthsList.forEach(m => {
      const summaryMonth = customerDebitsMonthly.find(
        s => s.rptPeriod === m.dateStr
      );
      if (!!summaryMonth) {
        list.push(summaryMonth);
      } else {
        const gapMonth = createEmptyCanceledAsOfTradesMonthSummaryObj(
          m.dateStr
        );
        list.push(gapMonth);
      }
    });
    return list;
  }

  getCanceledAsOfTradesReport(
    reportId: string | number,
    viewName
  ): Observable<CanceledAsOfTradesMonthly[]> {
    const summaryMonthlyDataObs = this.baseReportService.getReport(
      reportId,
      viewName,
      's',
      null
    );
    return summaryMonthlyDataObs.pipe(
      map((monthlySummaryResponse: CanceledAsOfTradesMonthly[]) => {
        // modify response here if needed
        return monthlySummaryResponse;
      })
    );
  }

  getAndProcessCanceledAsOfTradesDailySummaryReport(
    summaryData: CanceledAsOfTradesMonthly,
    allTradesMonthlySummary: AllTradesMonthlySummary,
    asOfTradesMonthlySummary: AsOfTradesMonthlySummary,
    beforeSettlementTradesMonthlySummary: BeforeSettlementTradesMonthlySummary,
    afterSettlementTradesMonthlySummary: AfterSettlementTradesMonthlySummary
  ): Observable<void> {
    const summaryDailyData = this.baseReportService.getReport(
      summaryData.rptId,
      'Daily',
      's',
      null
    );
    return summaryDailyData.pipe(
      map((dailyDataResponse: CanceledAsOfTradesDaily[]) => {
        allTradesMonthlySummary.detailsData = [];
        asOfTradesMonthlySummary.detailsData = [];
        beforeSettlementTradesMonthlySummary.detailsData = [];
        afterSettlementTradesMonthlySummary.detailsData = [];

        dailyDataResponse.forEach(dailyData => {
          const parsedTradesReportsDaily = parseDailyCanceledAsOfTradesSumary(
            dailyData
          );
          allTradesMonthlySummary.detailsData.push(parsedTradesReportsDaily[0]);
          asOfTradesMonthlySummary.detailsData.push(
            parsedTradesReportsDaily[1]
          );
          beforeSettlementTradesMonthlySummary.detailsData.push(
            parsedTradesReportsDaily[2]
          );
          afterSettlementTradesMonthlySummary.detailsData.push(
            parsedTradesReportsDaily[3]
          );
        });
        return null;
      }),
      catchError((error: HttpErrorResponse) => {
        allTradesMonthlySummary.detailsData = [];
        asOfTradesMonthlySummary.detailsData = [];
        beforeSettlementTradesMonthlySummary.detailsData = [];
        afterSettlementTradesMonthlySummary.detailsData = [];
        return of(null);
      })
    );
  }

  processCanceledAsOfTradesSummaryReport(
    monthlySummaryResponse: CanceledAsOfTradesMonthly[]
  ) {
    const newList = this.transformCanceledAsOfTradesMonthsSummaryList(
      monthlySummaryResponse
    );
    const allTradesMonthly: AllTradesMonthlySummary[] = [];
    const asOfTradesMonthly: AsOfTradesMonthlySummary[] = [];
    const beforeSettlementTradesMonthly: BeforeSettlementTradesMonthlySummary[] = [];
    const afterSettlementTradesMonthly: AfterSettlementTradesMonthlySummary[] = [];

    newList.forEach((summaryData: CanceledAsOfTradesMonthly) => {
      const result = this.transformMonthlyCanceledAsOfTradesReport(summaryData);
      allTradesMonthly.push(result.allTradesMonthlySummary);
      asOfTradesMonthly.push(result.asOfTradesMonthlySummary);
      beforeSettlementTradesMonthly.push(
        result.beforeSettlementTradesMonthlySummary
      );
      afterSettlementTradesMonthly.push(
        result.afterSettlementTradesMonthlySummary
      );
    });

    return {
      allTradesMonthly,
      asOfTradesMonthly,
      beforeSettlementTradesMonthly,
      afterSettlementTradesMonthly,
      canceledAndAsOfTradesMonthly: newList
    };
  }

  transformMonthlyCanceledAsOfTradesReport(
    summaryData: CanceledAsOfTradesMonthly
  ): {
    allTradesMonthlySummary: AllTradesMonthlySummary;
    asOfTradesMonthlySummary: AsOfTradesMonthlySummary;
    beforeSettlementTradesMonthlySummary: BeforeSettlementTradesMonthlySummary;
    afterSettlementTradesMonthlySummary: AfterSettlementTradesMonthlySummary;
  } {
    const parsedTradesReportsMonthly = parseMonthlyCanceledAsOfTradesSumary(
      summaryData
    );
    const allTradesMonthlySummary: AllTradesMonthlySummary =
      parsedTradesReportsMonthly[0];
    const asOfTradesMonthlySummary: AsOfTradesMonthlySummary =
      parsedTradesReportsMonthly[1];
    const beforeSettlementTradesMonthlySummary: BeforeSettlementTradesMonthlySummary =
      parsedTradesReportsMonthly[2];
    const afterSettlementTradesMonthlySummary: AfterSettlementTradesMonthlySummary =
      parsedTradesReportsMonthly[3];

    allTradesMonthlySummary.monthYear = getMonthYrDate(summaryData.rptPeriod);
    asOfTradesMonthlySummary.monthYear = getMonthYrDate(summaryData.rptPeriod);
    beforeSettlementTradesMonthlySummary.monthYear = getMonthYrDate(
      summaryData.rptPeriod
    );
    afterSettlementTradesMonthlySummary.monthYear = getMonthYrDate(
      summaryData.rptPeriod
    );

    if (summaryData.rptId) {
      this.getAndProcessCanceledAsOfTradesDailySummaryReport(
        summaryData,
        allTradesMonthlySummary,
        asOfTradesMonthlySummary,
        beforeSettlementTradesMonthlySummary,
        afterSettlementTradesMonthlySummary
      ).subscribe();
    } else {
      allTradesMonthlySummary.detailsData = [];
      asOfTradesMonthlySummary.detailsData = [];
      beforeSettlementTradesMonthlySummary.detailsData = [];
      afterSettlementTradesMonthlySummary.detailsData = [];
    }

    // push to appropriate list/array
    return {
      allTradesMonthlySummary,
      asOfTradesMonthlySummary,
      beforeSettlementTradesMonthlySummary,
      afterSettlementTradesMonthlySummary
    };
  }

  /** Registered Representatives Composition Methods */

  // prepare the master list container.
  // the template will loop through this to render a child component (reps-grid) for each.
  prepareNewRegisteredRepsMasterList(
    severelyDisciplinedClickHandler: () => void
  ) {
    const masterList: RegisteredRepsMasterListElement[] = [
      // all reps
      {
        title: 'Registered Reps',
        list: [],
        isAllReps: true
      },
      // sub-section reps
      {
        title: 'New to Industry Reps',
        list: [],
        isAllReps: false
      },
      {
        title: 'Reps Transferred from Another Firm',
        list: [],
        isAllReps: false
      },
      {
        title: 'Reps Registered with 3 or More Firms in the Last 2 Years',
        list: [],
        isAllReps: false
      },
      {
        title: 'Terminated Reps',
        list: [],
        isAllReps: false
      },
      {
        title: 'Reps with 1 or More Disclosures',
        list: [],
        isAllReps: false
      },
      {
        title: 'Reps with Customer Complaint Disclosures',
        list: [],
        isAllReps: false
      },
      {
        title: 'Reps with Arbitration / Civil Litigation Disclosures',
        list: [],
        isAllReps: false
      },
      {
        title: 'Reps with Criminal Disclosures',
        list: [],
        isAllReps: false
      },
      {
        title: 'Reps with Financial Disclosures',
        list: [],
        isAllReps: false
      },
      {
        title: 'Reps Ever Previously with a Severely Disciplined Firm',
        list: [],
        isAllReps: false,
        actionBtn: {
          text: 'List',
          handler: severelyDisciplinedClickHandler
        }
      }
    ];

    return masterList;
  }

  transformQuartersSummaryList(
    quarterlySummary: QuarterlyRegisteredRepsData[]
  ): QuarterlyRegisteredRepsData[] {
    // get the prior twelve quarters from this report date
    const quartersList = getPrior12Quarters(quarterlySummary[0].prcsdDt);
    const list: QuarterlyRegisteredRepsData[] = [];

    quartersList.forEach(q => {
      const summaryQuarter = quarterlySummary.find(
        s => s.prcsdDt === q.dateString
      );
      if (!!summaryQuarter) {
        list.push(summaryQuarter);
      } else {
        const gapQuarter = createEmptyQuarterlyRegisteredRepsObj(q.dateString);
        list.push(gapQuarter);
      }
    });
    return list;
  }

  getRegisteredRepsReport(
    reportId: string | number,
    viewName: string
  ): Observable<QuarterlyRegisteredRepsData[]> {
    const summaryMonthlyDataObs = this.baseReportService.getReport(
      reportId,
      viewName,
      's',
      null
    );
    return summaryMonthlyDataObs.pipe(
      map((monthlySummaryResponse: QuarterlyRegisteredRepsData[]) => {
        // modify response here if needed
        return monthlySummaryResponse;
      })
    );
  }

  transformQuarterRegisteredRepsSummaryReport(
    quarterData: QuarterlyRegisteredRepsData,
    masterList: RegisteredRepsMasterListElement[]
  ) {
    /**
     * The `parseQuartlyRegisteredRepsSummary()` function returns the transformed
     * data in a particular order. That order matched the order of
     * the `registeredRepsMasterList` property of this component.
     * The order matches what tables are rendered from the on-prem.
     * For example, first is all registered reps, then new to industry, then transferred, etc.
     */
    const parsedDataList = parseQuartlyRegisteredRepsSummary(quarterData);
    const len = parsedDataList.length;
    for (let i = 0; i < len; i++) {
      const parsedData: RegisteredRepsUnion = parsedDataList[i];
      const section = masterList[i];
      section.list.push(parsedData);
    }
  }

  processQuarterRegisteredRepsSummaryReport(
    quarterlySummaryResponse: QuarterlyRegisteredRepsData[],
    severelyDisciplinedClickHandler: () => void
  ) {
    const newMasterList = this.prepareNewRegisteredRepsMasterList(
      severelyDisciplinedClickHandler
    );
    const newList = this.transformQuartersSummaryList(quarterlySummaryResponse);
    newList.forEach(quarterData => {
      this.transformQuarterRegisteredRepsSummaryReport(
        quarterData,
        newMasterList
      );
    });
    return {
      registeredRepsMasterList: newMasterList
    };
  }

  showSeverelyDisciplinedFirms(
    reportId: string | number,
    viewName: string,
    reportDate: string
  ) {
    const apiURL: string = this.baseReportService.getReportUrl(
      <number>reportId,
      viewName,
      'o'
    );
    this.http.get(apiURL).subscribe(
      (firmsResponse: any) => {
        this.dialog.open(SeverelyDisciplinedFirmsModalComponent, {
          data: {
            rowData: firmsResponse,
            columnDefs: SEVERELY_DISCIPLINED_FIRMS_COLUMN_DEFS,
            reportPeriod: quarterDateFormat(reportDate)
          }
        });
      },
      (error: HttpErrorResponse) => {}
    );
  }

  /** Sales Practice Complain Methods */

  getSalePracticeComplaintReport(
    reportId: string | number,
    viewName: string
  ): Observable<SalesPracticeComplaintSummaryResponse> {
    return this.baseReportService.getReport(reportId, viewName, 's', null).pipe(
      map((response: SalesPracticeComplaintSummaryResponse) => {
        // modify reponse here if needed
        return response;
      })
    );
  }

  processSalePracticeComplaintReport(response, reportDate) {
    const firm: SalesPracticeComplaintFirmData[] = this.salePracticeComplaintFormatByQuarter(
      response.data.firm,
      FIRM_MODEL,
      reportDate
    );
    const industry: SalesPracticeComplaintIndustryData[] = this.salePracticeComplaintFormatByQuarter(
      response.data.industry,
      INDUSTRY_MODEL,
      reportDate
    );
    const rowData = { firm, industry };
    const salesComplaintViews = Object.keys(rowData);
    return {
      rowData,
      salesComplaintViews
    };
  }

  salePracticeComplaintFormatByQuarter(data, model, reportDate) {
    if (!data.length) {
      return;
    }
    const newData = [];
    const formattedDate = getCompatibleDate(reportDate);
    const quarters = getPrior12Quarters(formattedDate);
    quarters.forEach(quarter => {
      const found = data.find((item: any) => {
        return quarter.formattedQuarter === item.Period;
      });
      // If the period matches, add the data object
      if (found) {
        newData.push(found);
      } else {
        // Add empty data object
        const emptyModel = { ...model };
        emptyModel.Period = quarter.formattedQuarter;
        newData.push(emptyModel);
      }
    });
    return newData;
  }

  /** Customer Complaints Methods */

  getCustomerComplaintSummaryReport(
    reportId: string | number,
    viewName: string
  ): Observable<CustomerComplaintSummaryResponse> {
    return this.baseReportService.getReport(reportId, viewName, 's', null).pipe(
      map((response: CustomerComplaintSummaryResponse) => {
        // modify reponse here if needed
        return response;
      })
    );
  }

  processCustomerComplaintSummaryReport(
    response: CustomerComplaintSummary[],
    viewName: string
  ): CustomerComplaintTransformedResponse {
    const results = transformCustomerComplaintsSummaryDataResponse(
      response,
      viewName
    );
    return results;
  }
}
