import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  IconDefinition,
  faCalendarAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'finra-datepicker',
  templateUrl: './finra-datepicker.component.html',
  styleUrls: ['./finra-datepicker.component.scss']
})
export class FinraDatepickerComponent implements OnInit {
  @Input() control: FormControl;
  @Input() name: string;
  @Input() title: string;

  faCalendarAlt: IconDefinition = faCalendarAlt;
  // for managing/controlling the initial view value. changing the date via the picker will update it.
  dateControl = new FormControl();

  constructor(
    private helperService: HelperService,
  ) {}

  ngOnInit() {
    /**
     * expect `this.control.value` to be a string date
     */
    if (this.control && this.control.value) {
      const date = new Date((this.control.value + 'T00:00:00'));
      this.dateControl.setValue(date);
    }
  }

  onDateInput(type: string, event: MatDatepickerInputEvent<Date>) {
    const date = this.helperService.getTransportDate(event.value);
    this.control.setValue(date);
  }
}
