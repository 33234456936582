import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MsrbReportTypes,
  MsrbToolbarDescriptions,
  MsrbFooterMessages,
  MsrbFirmNameLabels,
  MsrbFirmIdLabels,
  ALL_COLUMN_DEFINITIONS,
  TABLE_NAMES_BY_ORDER,
  TABLE_TITLES,
  showViewsDropdownReportTypeIDs,
  showDetailLinkReportTypeIDs,
  MsrbReportNames,
  MSRB_RATINGS,
  MSRB_MATURITIES,
} from 'src/app/configs/model/msrb/msrb.model';
import { Subscription, of } from 'rxjs';
import {
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { ToolbarConfig, ToolbarConfigOptions } from 'src/app/configs/model/finra-toolbar.model';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { MsrbToolbarConfig } from 'src/app/configs/toolbar-config/msrb.toolbar';
import { BeastOtherActions, BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastLeaveReportPageInfo, BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { HelperService } from 'src/app/services/helper.service';
import { MsrbService } from 'src/app/services/msrb.service';
import { ReportService, ReportPageMetadataInfo } from 'src/app/services/report.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';
import { deepSort, makeFileNameFromReport, saveCsv } from 'src/app/shared/utils/utils';



@Component({
  selector: 'report-msrb',
  templateUrl: './msrb.component.html',
  styleUrls: ['./msrb.component.scss']
})
export class MsrbComponent implements OnInit, OnDestroy {
  title: string;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  viewName: string;
  reportId: number;
  reportTypeId: number;
  reportName: string;
  reportDate: string;
  reportVersion: number;
  isMUMD: boolean; // is mark up mark down
  shouldShowViewDropDown: boolean;
  shouldShowReportDetails: boolean;
  shouldShowPeerGroup: boolean;
  shouldShowTierGroup: boolean;
  reportInstanceMetadata: ReportInstanceMetadata;
  msrbFirmNameLabels = MsrbFirmNameLabels;
  msrbFirmIdLabels = MsrbFirmIdLabels;
  reportTypesEnum = MsrbReportTypes;
  toolbarDescriptions = MsrbToolbarDescriptions;
  footerMessages = MsrbFooterMessages;
  tableTitles = TABLE_TITLES;
  isMarkupMarkdownReport: boolean;
  tierCode;
  rowData;
  columnDefs;
  tableNamesByOrder;
  peerGroupData;
  msrbToolbarConfig: ToolbarConfig;
  msrbToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  reportPageMetadataInfo;
  periodChangeSubscription: Subscription;
  incomingRatingIndicator: string;
  incomingMaturityIndicator: string;
  maturities = MSRB_MATURITIES;
  ratings = MSRB_RATINGS;
  reportLoadedSuccessfully: boolean;
  periodFormatter: (arg: string) => string;
  loadedAt: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private baseReportService: ReportService,
    private uiWidgetsService: UiWidgetsService,
    private helperService: HelperService,
    private toolbarService: ToolbarService,
    private titleService: Title,
    private msrbService: MsrbService,
    private unreadReportsService: UnreadReportsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {
    this.toolbarForm = new FormGroup({
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl(),
      maturity: new FormControl(),
      rating: new FormControl()
    });
  }

  ngOnInit() {
    this.titleService.setTitle('MSRB');
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
        reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: this.incomingMaturityIndicator,
        rating: this.incomingRatingIndicator,
        product: '',
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.viewName = params.viewName;
    this.reportId = parseInt(params.reportId, 10);
    this.reportName = params.reportName;
    this.reportVersion = parseInt(params.version, 10);
    this.reportTypeId = parseInt(params.type, 10);
    this.isMarkupMarkdownReport = this.reportName === MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS;
    if (this.isMarkupMarkdownReport) {
      this.incomingMaturityIndicator = params.incomingMaturityIndicator || MSRB_MATURITIES[0].value;
      this.incomingRatingIndicator = params.incomingRatingIndicator || MSRB_RATINGS[0].value;
    }
    this.getReportConfigurationMetadata().subscribe(
      (pageData) => {
        this.processReportConfigurationMetadata(pageData);
        this.getSummaryReportData().subscribe((reportData) => {
          this.processSummaryReportData(reportData);
        });
      }
    );
  }

  setToolbar() {
    this.toolbarForm.get('rating').setValue(this.incomingRatingIndicator);
    this.toolbarForm.get('maturity').setValue(this.incomingMaturityIndicator);
    const customOptions: Partial<ToolbarConfigOptions> = {
      isMarkupMarkdownReport: this.isMarkupMarkdownReport,
      firmNameLabel: this.msrbFirmNameLabels[this.reportName],
      firmIdLabel: this.msrbFirmIdLabels[this.reportName],
      description: this.toolbarDescriptions[this.reportName],
      shouldHideMaturityRatingRow: !this.isMUMD,
      hideViewsCell: !this.shouldShowViewDropDown,
      hideDetailsButton: !this.shouldShowReportDetails,
      hideMarkupMarkdownAllDetailsButton: !this.isMarkupMarkdownReport,
      maturitiesList: MSRB_MATURITIES,
      ratingsList: MSRB_RATINGS,
      ratingFormControl: this.toolbarForm.controls.rating,
      maturityFormControl: this.toolbarForm.controls.maturity,
      setHelpClickHandler: () => {
        this.openHelp();
      },

      detailsAllClickHandler: () => {
        const exportUrl: string = this.baseReportService.getReportUrl(
          this.reportId,
          'All',
          'd',
          this.reportDate
        );
        this.helperService.downloadFile(exportUrl);
      },
    };
    const results = this.toolbarService.createToolbarConfig(
      this,
      MsrbToolbarConfig,
      customOptions
    );
    this.msrbToolbarConfigOptions = results.toolbarConfigOptions;
    this.msrbToolbarConfig = results.toolbarConfig;
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          this.reportLoadedSuccessfully = false;
          throw error;
        })
      );
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    if (!data) {
      return;
    }
    if (this.isMarkupMarkdownReport) {
      // do not show All view for MarkUp MarkDown
      data.views.includes('All') && data.views.splice(data.views.indexOf('All'), 1);
    }
    const isContinuingDisclosure = this.reportTypeId === MsrbReportTypes.CONTINUING_DISCLOSURE;
    if (isContinuingDisclosure) {
      deepSort(
        data.reportInstanceMetadata.reportConfiguration.reportConfigurationViews,
        'viewName',
        false,
        true
      );
    }
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
    this.isMUMD = this.reportTypeId === MsrbReportTypes.MARKUP_MARKDOWN_ANALYSIS;
    this.reportDate = this.reportInstanceMetadata.reportPeriodDate;
    const reportTypeId = this.reportInstanceMetadata.reportConfiguration.reportTypeId;
    // DDWA-4369 Using title from reportInstanceMetadata.
    this.title = this.reportInstanceMetadata.reportConfiguration.reportDisplayName;
    this.shouldShowViewDropDown = showViewsDropdownReportTypeIDs.includes(
      reportTypeId
    );
    this.shouldShowReportDetails = showDetailLinkReportTypeIDs.includes(
      reportTypeId
    );
    this.setToolbar();
  }

  getSummaryReportData() {
    let dataObs$;
    if (this.isMUMD) {
      const params = {
        incomingRatingIndicator: this.incomingRatingIndicator,
        incomingMaturityIndicator: this.incomingMaturityIndicator
      };
      dataObs$ = this.baseReportService.getMsrbMarkUpMarkDownReport(
        this.reportId,
        this.viewName,
        's',
        params
      );
    } else {
      dataObs$ = this.baseReportService.getReport(
        this.reportId,
        this.viewName,
        's'
      );
    }
    return dataObs$.pipe(
      catchError((error: HttpErrorResponse) => {
        this.helperService.handleReportSummaryDataError(error);
        this.rowData = null;
        return of(null);
      })
    );
  }

  processSummaryReportData(responseData) {
    if (!responseData) {
      return;
    }
    const isUnderwriterReport = this.reportTypeId === MsrbReportTypes.UNDERWRITER_FINANCIAL_STATUS;
    if (isUnderwriterReport) {
      this.tierCode = this.msrbService.getUnderWriterTierCode(responseData);
    }
    const formattedResponse = this.msrbService.formatResponse(responseData, this.reportTypeId);
    this.rowData = formattedResponse;
    this.columnDefs = ALL_COLUMN_DEFINITIONS[this.reportName];
    this.tableNamesByOrder = TABLE_NAMES_BY_ORDER[this.reportName];
    this.addPeerGroupLink();
    this.addDetailsLink();
    const isTradesBelowReport = this.reportName === MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION;
    if (isTradesBelowReport) {
      this.addUniqueTradeAlertLink();
    }
    this.reportLoadedSuccessfully = true;
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  addDetailsLink() {
    this.msrbService.addDetailsLink(
      this.reportTypeId,
      this.columnDefs,
      this.viewName,
      this.reportInstanceMetadata.reportFirmId,
      this.reportPageMetadataInfo
    );
  }

  addPeerGroupLink() {
    const printFn = () => {
      const useFileName = makeFileNameFromReport(
        this.reportPageMetadataInfo.reportInstanceMetadata,
        this.viewName
      );
      const pdfFileName = useFileName + '_PeerGroup.pdf';
      this.toolbarService.onExportPdf(pdfFileName, this.msrbToolbarConfig);
    };
    this.msrbService.addPeerGroupLink({
      reportInstanceMetadata: this.reportInstanceMetadata,
      reportName: this.reportName,
      reportId: this.reportId,
      viewName: this.viewName,
      tierCode: this.tierCode,
      columnDefs: this.columnDefs,
      printFn,
      shouldAutoOpenModal: true
    });
  }

  addUniqueTradeAlertLink() {
    this.msrbService.addUniqueTradeAlertLink(
      this.columnDefs,
      this.viewName,
      this.reportInstanceMetadata.reportFirmId,
      this.reportPageMetadataInfo
    );
  }

  async summaryDataExport() {
    const additionalInfo = {
      incomingRatingIndicator: this.incomingRatingIndicator,
      incomingMaturityIndicator: this.incomingMaturityIndicator
    };
    const fileNameResponse = await this.baseReportService.getReportFileNameByAdditionalInfo(
      this.reportId,
      this.viewName,
      's',
      additionalInfo
    ).toPromise();
    const csvString = this.msrbService.MSRB_EXPORT_CONFIG[this.reportName](this.rowData);
    saveCsv(csvString, fileNameResponse.fileName);
    this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
  }

  getViewName() {
    switch (this.reportName) {
      case MsrbReportNames.CONTINUING_DISCLOSURE:
        return 'All';
      default:
        return this.viewName;
    }
  }

  openHelp() {
    this.helpLinksService.open(this.reportName);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  onReportDetails(params) {
    const viewName = this.getViewName();
    const exportUrl: string = this.baseReportService.getReportUrl(
      this.reportId,
      viewName,
      'd'
    );
    this.helperService.downloadFile(exportUrl);
  }

  handleDetailError(error: HttpErrorResponse) {
    let errorMessage: string = null;
    if (error.status === 404) {
      errorMessage = 'No detail data available.';
    } else {
      errorMessage = 'Could not load detail data...';
    }
    this.uiWidgetsService.showSnackbar(errorMessage, ['snackbar-error'], 3000);
  }
}
