import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import {
  quarterDateFormat,
} from '../shared/utils/utils';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DownloadDetailsParams } from '../interfaces/download-details-params.interface';
import { ReportService } from './report.service';
import { DetailDatasetRequestsService } from './detail-dataset-requests.service';
import { UiWidgetsService } from './ui-widgets.service';
import { DetailDatasetDownloadRequest } from '../configs/model/home.model';
import { HttpStatusCode } from '../configs/model/http-status-codes.model';
import { ReportInstanceMetadata } from '../configs/model/reports.model';
import { UserProfile } from '../configs/model/user-profile.model';
import { UserProfileService } from './user-profile.service';

export enum TimeFormat {
  None,
  BeginOfDay,
  EndOfDay
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  pdfExportSubject = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private baseReportService: ReportService,
    private datepipe: DatePipe,
    private userProfileService: UserProfileService,
    private detailDatasetRequestsService: DetailDatasetRequestsService,
    private uiWidgetsService: UiWidgetsService
  ) {}

  getTransportDate(date: Date, timeFormat = TimeFormat.None) {
    let result = this.datepipe.transform(date, 'yyyy-MM-dd');

    if (timeFormat === TimeFormat.BeginOfDay) {
      result = result + 'T00:00:00';
    } else if (timeFormat === TimeFormat.EndOfDay) {
      result = result + 'T23:59:59';
    }

    return result;
  }

  getDownloadFileName(
    // only two properties out of the entire interface was needed
    reportInstanceMetadata:
      | ReportInstanceMetadata
      | {
          reportFirmId: string;
          reportPeriodDate: string;
        },
    fileNameTemplate: string,
    showQuarterDate?: boolean
  ) {
    const firmId = reportInstanceMetadata.reportFirmId;
    // DDWA-2902 Quarter Date in Private Placement downloaded filename
    const reportDate = showQuarterDate
      ? quarterDateFormat(reportInstanceMetadata.reportPeriodDate)
      : reportInstanceMetadata.reportPeriodDate;

    return fileNameTemplate
      .replace('${firmId}', firmId)
      .replace('${reportPeriodDate}', reportDate);
  }

  private async detailsDataDownload(options: {
    url: string;
    useCustomFileName: boolean;
    RETRY_LIMIT: number;
    fileName?: string;
  }) {
    /**
     * The details data for a report can range greatly in file size.
     * Instead of trying to load a large detail dataset and waiting a long time just for it to fail,
     * the download process has been broken up to improve user experience.
     *
     * The front end first calls the details data endpoint, WITHOUT the `content-disposition` query param in the URL;
     * this tells the backend that the UI is not actually requesting the data, just checking the status:
     *
     * - if the detail request status is READY, the UI can start streaming it immediately.
     * - if the detail request status is PROCESSING, the UI will start tracking the status.
     *      NOTE: if detail data has not been requested for this report, a new one will be generated; the initial status of the download request is PROCESSING
     * - if the detail request status is NO_DATA, the UI can display no data; UI can decide to send another request to attempt/trigger a retry.
     * - if the detail request status is ERROR, the UI can display error; UI can decide to send another request to attempt/trigger a retry.
     *
     * when the detail request status is ERROR, the UI will send a second request, one WITH the `content-disposition` query param;
     * this will trigger the backend to retry the processing.
     *
     * the async detail download requests will get a response with the following headers:
     * - detail-download-request-status
     * - detail-download-request-id
     * - detail-download-request-status-message
     *
     * if the response has these headers, it was an async download. else, it was not.
     * UI will handle both cases by checking those headers first then the response status code.
     */

    /**
     * DDWA-5559
     * By default, this method will fetch the file name dynamically.
     * If a custom file name is desired, pass in true boolean for `useCustomFileName` and the custom file name.
     * The endpoint for fetching the file name closely mirrors the report data endpoint (just a difference in one path name).
     * If the endpoint for fetching the file name errors, a generic file name will be used.
     */

    const { url, fileName, useCustomFileName, RETRY_LIMIT } = options;

    // get the file name from the server
    const fileNameUrl = url.replace('reportData', 'reportFileNames');
    const useFileName: string = useCustomFileName
      ? fileName
      : await this.http.get(fileNameUrl).toPromise()
          .then((response: { fileName: string }) => {
            return response.fileName;
          })
          .catch((error: HttpErrorResponse) => {
            // `Could not fetch report file name; defaulting to a generic name...`
            return `export-${Date.now()}.csv`;
          });

    // check if there is data. if there is, continue to the second call, otherwise handle the error
    const firstCheck: boolean = await this.http.get(url, { responseType: 'text', observe: 'response' })
      .toPromise()
      .then((response: HttpResponse<any>) => {
        // there is data
        return true;
      })
      .catch((error: HttpErrorResponse) => {
        // there is no data
        this.handleDownloadFileError(error, { ...options, useFileName });
        return false;
      });
    if (!firstCheck) {
      return false;
    }

    // get the full url
    const urlObj = this.getFullDetailsUrl(url, useFileName);


    /**
     * https://jira.finra.org/browse/DDWA-7289 - Very Large Detail downloads doesn't stream to browser
     *
     * Javascript cannot stream to file due to security protocols; the BROWSER needs to stream and save, not js.
     * So instead of fetching the url to stream via javascript programmatically (fetch/httpClient),
     * create an <a> element that points to that stream url, trigger the click event and have the browser download it.
    */

    const hiddenAnchorDownloadElement = document.createElement('a');
    hiddenAnchorDownloadElement.href = urlObj.url;
    hiddenAnchorDownloadElement.target = '_blank';
    document.body.appendChild(hiddenAnchorDownloadElement);
    hiddenAnchorDownloadElement.click();
    setTimeout(() => {
      document.body.removeChild(hiddenAnchorDownloadElement);
    }, 1000);


    return true;

    /* Deprecated section

    // now check if the data is ready. if it is, stream it asynchronously on this tab. if not, handle error with pop up.
    const secondCheck: string = await this.http.get(urlObj.url, { responseType: 'text', observe: 'response' })
      .toPromise()
      .then((response: HttpResponse<any>) => {
        // the data is ready; streaming is done. return data for download
        return response.body;
      })
      .catch((error: HttpErrorResponse) => {
        // the data is not ready
        this.handleDownloadFileError(error);
        return false;
      });
    if (!secondCheck) {
      return false;
    }

    // we have the data. now download it
    saveCsv(secondCheck, useFileName);
    // the save popup appeared. consider the entire process a success.
    return true;

    */
  }

  downloadFile(url: string, useCustomFileName: boolean = false, fileName?: string) {
    /** New Implementation */

    // add cache disable flag
    let newUrl = '';
    if (url.includes('?')) {
      const strSplit = url.split('?');
      newUrl = strSplit[0] + '?cache=false&' + strSplit[1];
    } else {
      newUrl = url + '?cache=false';
    }

    // attempt to download the data and return the status of the operation/process.
    // on error status, check if it was async via response header. if so, retry.
    // setting retry limit as a backup escape to prevent recursive/infinite retries
    const RETRY_LIMIT = 5;
    return this.detailsDataDownload({ url: newUrl, useCustomFileName, RETRY_LIMIT, fileName });

    /** Old Implementation */

    // // this method calls the backend to check if there is details data.
    // this.http.get(url, { responseType: 'text', observe: 'response' })
    //   .subscribe(
    //     (data: HttpResponse<any>) => {
    //       // if we get a 200 response, then there is details data.
    //       // open the url, with the file name as the contentDisposition appended to it, in a new tab to prompt the download.
    //       // backend will prepare the data for streaming.
    //       this.handleDownloadFile(url, fileName);
    //     },
    //     (error: HttpErrorResponse) => {
    //       this.handleDownloadFileError(error);
    //     }
    //   );
  }

  getFullDetailsUrl(url: string, fileName: string): {
    url: string;
    encodedUrl: string;
  } {
    let newUrl;

    newUrl = url + (url.indexOf('?') === -1 ? '?' : '&');
    newUrl += (`contentDisposition=${fileName}`);
    if (!newUrl.endsWith('.csv')) {
      newUrl += '.csv';
    }
    // if (newUrl.indexOf('additionalInfo=') === -1) {
    //   newUrl += `&additionalInfo=`;
    // }

    const encodedUrl = encodeURI(newUrl);
    return {
      encodedUrl,
      url: newUrl
    };
  }

  private handleDownloadFile(url: string, fileName: string) {
    const urlObj = this.getFullDetailsUrl(url, fileName);
    window.open(urlObj.encodedUrl);
  }

  private handleDownloadFileError(error: HttpErrorResponse, options: {
    url: string;
    useCustomFileName: boolean;
    RETRY_LIMIT: number;
    fileName?: string;
    useFileName: string;
  }) {
    const headerKeys = error.headers.keys();
    const headers = {};
    for(const key of headerKeys) {
      headers[key] = error.headers.get(key);
    }

    switch (error.status) {
      case HttpStatusCode.NOT_FOUND: {
        return this.uiWidgetsService.showErrorSnackbar(
          'No periodic detail data is available for this report for the selected period'
        );
      }
      case HttpStatusCode.PRECONDITION_FAILED: {
        const errorObj = typeof (error.error) === 'string'
          ? JSON.parse(error.error)
          : (error.error || {});
        // check if the message is a JSON string
        const messageIsJsonString = errorObj &&
          typeof (errorObj.message) === 'string' &&
          errorObj.message.startsWith('{') &&
          errorObj.message.endsWith('}');

        const errorJson = messageIsJsonString ? JSON.parse(errorObj.message) : null;
        const errorMessage = errorJson
          ? errorJson.message
          : (
              errorObj.message ||
              error.headers.get('detail-download-request-status-message') ||
              'Download request submitted and will be ready when processing is complete.'
            );

        this.uiWidgetsService.showInfoSnackbar(errorMessage);
        if (errorJson && errorJson.userDetailDatasetDownloadRequest) {
          this.detailDatasetRequestsService.addDetailDatasetRequest(
            (<DetailDatasetDownloadRequest> errorJson.userDetailDatasetDownloadRequest)
          );
        }
        this.detailDatasetRequestsService.startPolling();
        return;
      }
      case HttpStatusCode.ANGULAR_ERROR:
      case HttpStatusCode.INTERNAL_SERVER_ERROR:
      default: {
        // if the download was async, retry via making another call. else, display error
        const isAsyncDownload = !!headers['detail-download-request-status'];
        if (isAsyncDownload) {
          if (options.RETRY_LIMIT > 0) {
            const urlObj = this.getFullDetailsUrl(options.url, options.useFileName);
            options.url = urlObj.url;
            options.useCustomFileName = true;
            options.RETRY_LIMIT = options.RETRY_LIMIT - 1;
            this.detailsDataDownload(options);
          }
        } else {
          this.uiWidgetsService.showErrorSnackbar(
            'Sorry, but the system is currently unavailable. ' +
            'Please try again later. If the issue persists, ' +
            'please contact the Report Center administrator at ' +
            'reportcenter-admin@finra.org or call FINRA at (301) 869-6699.'
          );
        }
      }
    }
  }

  getDetailReport(params: DownloadDetailsParams) {
    const reportDate = new Date(params.reportDate).toISOString().slice(0, 10);

    const exportUrl = this.baseReportService.getReportUrl(
      params.reportId,
      params.viewName,
      'd',
      reportDate
    );

    this.downloadFile(exportUrl);
  }

  handleReportMetadataError(error: HttpErrorResponse) {
    const errorMessage = error.status === 404
      ? 'No report metadata found...'
      : 'Could not load report metadata...';
    return this.uiWidgetsService.showErrorSnackbar(errorMessage);
  }

  handlePeerGroupDataError(error: HttpErrorResponse) {
    const errorMessage = error.status === 404
      ? 'No peer group data to show...'
      : 'Could not load peer group data...';
    return this.uiWidgetsService.showErrorSnackbar(errorMessage);
  }

  handleReportSummaryDataError(error: HttpErrorResponse) {
    let errorMessage: string;
    switch (error.status) {
      // client errors
      case 400:
        errorMessage = 'The request could not be understood; something went wrong...';
        break;
      case 404:
        errorMessage = 'No report data to show...';
        break;
      case 401:
        errorMessage = 'User not authenticated/authorized...';
        break;
      case 403:
        errorMessage = 'You do not have authorization to view this report data...';
        break;
      // server errors
      case 500:
        errorMessage = 'Could not load report data: server ran into a problem...';
        break;
      default:
        errorMessage = 'Could not load report data: something went wrong...';
    }
    return this.uiWidgetsService.showErrorSnackbar(errorMessage);
  }

  getReportFromListByPeriod(
    period: string,
    reportInstanceMetadatas: ReportInstanceMetadata[]
  ) {
    const report = reportInstanceMetadatas.find(
      (r: ReportInstanceMetadata) => r.reportPeriodDate === period
    );
    if (!report) {
      const errorMessage = `Could not find report data for period: ${period}`;
      this.uiWidgetsService.showErrorSnackbar(errorMessage);
      throw new Error(errorMessage);
    }
    return report;
  }

  hasUnreadReportsAccess(userProfile: UserProfile): boolean {
    const isInternalUser = !this.userProfileService.isExternalUser(userProfile);
    if (isInternalUser) {
      // unread reports do not apply to internal/isso users
      return false;
    }

    // DDWA-2835, DDWA-2836, DDWA-2845, Hide Unread Reports for EWS-SRO EWS-SEC FINRA-ADMIN
    // DDWA-4458 - onlw show for EWS/External Users
    const hasUnreadReportsAccess = this.userProfileService.hasUnreadReportsAccess(userProfile);
    return hasUnreadReportsAccess;
  }
}
