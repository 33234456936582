import {
  buildColumnLabelsString,
  buildRowDataString,
  buildExportDataString,
  saveCsv,
  getMonthYrDate,
  trimTrailingChar
} from 'src/app/shared/utils/utils';

/**
 * Interfaces for the canceled and as-of trades.
 * The property names match was is given from the backend.
 * Here is an example of a canceled and as-of trades object from the backend:
 *
 * @example:
  {
    asOfTrdsChngPt: null
    asOfTrdsPt: null
    crdId: "79"
    eqtyTrdsCnclsAfChngPt: null
    eqtyTrdsCnclsAfPt: null
    eqtyTrdsCnclsBfChngPt: null
    eqtyTrdsCnclsBfPt: null
    indAsOfTrdsChngPt: null
    indAsOfTrdsPt: 4.79
    indEqtyTrdsCnclsAfChngPt: null
    indEqtyTrdsCnclsAfPt: 0
    indEqtyTrdsCnclsBfChngPt: null
    indEqtyTrdsCnclsBfPt: 0
    indTrdsChngPt: null
    indTtlAsOfTrdsCt: 22268
    indTtlEqtyTrdsCnclsAfCt: 0
    indTtlEqtyTrdsCnclsBfCt: 0
    indTtlTrdsCt: 464973
    rptFnclOprtnsMonthlyId: 10100799
    rptId: 21142808
    rptPeriod: "07-01-2017"
    trdsChngPt: null
    ttlAsOfTrdsCt: null
    ttlEqtyTrdsCnclsAfCt: null
    ttlEqtyTrdsCnclsBfCt: null
    ttlTrdsCt: null
  }
 */

export interface CanceledAsOfTradesMonthly {
  // report info

  rptFnclOprtnsMonthlyId: number;
  rptPeriod: string; // format = '10-01-2017'
  crdId: string;
  rptId: number;



  // firm

  // all (total) trades
  ttlTrdsCt: number; // all trades: count
  trdsChngPt: number; // all trades: change percentage from prior period

  // as-of trades
  ttlAsOfTrdsCt: number; // as-of trades: count
  asOfTrdsPt: number; // as of trades: percentage of all trades
  asOfTrdsChngPt: number; // as of trades: change percentage from prior period

  // equity trade cancels on or before settlement
  ttlEqtyTrdsCnclsBfCt: number; // equity trades cancels on or before settlement: count
  eqtyTrdsCnclsBfPt: number; // equity trades cancels on or before settlement: percentage of all trades
  eqtyTrdsCnclsBfChngPt: number; // equity trades cancels on or before settlement: change percentage from prior period

  // equity trade cancels after settlement
  ttlEqtyTrdsCnclsAfCt: number; // equity trades cancels after settlement: count
  eqtyTrdsCnclsAfPt: number; // equity trades cancels after settlement: percentage of all trades
  eqtyTrdsCnclsAfChngPt: number; // equity trades cancels after settlement: change percentage from prior period



  // industry

  // all (total) trades
  indTtlTrdsCt: number; // all trades: count
  indTrdsChngPt: number; // all trades: change percentage from prior period

  // as-of trades
  indTtlAsOfTrdsCt: number; // as-of trades: count
  indAsOfTrdsPt: number; // as of trades: percentage of all trades
  indAsOfTrdsChngPt: number; // as of trades: change percentage from prior period

  // equity trade cancels on or before settlement
  indTtlEqtyTrdsCnclsBfCt: number; // equity trades cancels on or before settlement: count
  indEqtyTrdsCnclsBfPt: number; // equity trades cancels on or before settlement: percentage of all trades
  indEqtyTrdsCnclsBfChngPt: number; // equity trades cancels on or before settlement: change percentage from prior period

  // equity trade cancels after settlement
  indTtlEqtyTrdsCnclsAfCt: number; // equity trades cancels after settlement: count
  indEqtyTrdsCnclsAfPt: number; // equity trades cancels after settlement: percentage of all trades
  indEqtyTrdsCnclsAfChngPt: number; // equity trades cancels after settlement: change percentage from prior period
}

export interface AllTradesMonthly {
  // report info

  rptFnclOprtnsMonthlyId: number;
  rptPeriod: string; // format = '10-01-2017'
  crdId: string;
  rptId: number;

  // firm
  ttlTrdsCt: number; // all trades: count
  trdsChngPt: number; // all trades: change percentage from prior period

  // industry
  indTtlTrdsCt: number; // all trades: count
  indTrdsChngPt: number; // all trades: change percentage from prior period
}

export interface AsOfTradesMonthly {
  // report info

  rptFnclOprtnsMonthlyId: number;
  rptPeriod: string; // format = '10-01-2017'
  crdId: string;
  rptId: number;

  // firm
  ttlAsOfTrdsCt: number; // as-of trades: count
  asOfTrdsPt: number; // as of trades: percentage of all trades
  asOfTrdsChngPt: number; // as of trades: change percentage from prior period

  // industry
  indTtlAsOfTrdsCt: number; // as-of trades: count
  indAsOfTrdsPt: number; // as of trades: percentage of all trades
  indAsOfTrdsChngPt: number; // as of trades: change percentage from prior period
}

export interface BeforeSettlementTradesMonthly {
  // report info

  rptFnclOprtnsMonthlyId: number;
  rptPeriod: string; // format = '10-01-2017'
  crdId: string;
  rptId: number;

  // firm
  ttlEqtyTrdsCnclsBfCt: number; // equity trades cancels on or before settlement: count
  eqtyTrdsCnclsBfPt: number; // equity trades cancels on or before settlement: percentage of all trades
  eqtyTrdsCnclsBfChngPt: number; // equity trades cancels on or before settlement: change percentage from prior period

  // industry
  indTtlEqtyTrdsCnclsBfCt: number; // equity trades cancels on or before settlement: count
  indEqtyTrdsCnclsBfPt: number; // equity trades cancels on or before settlement: percentage of all trades
  indEqtyTrdsCnclsBfChngPt: number; // equity trades cancels on or before settlement: change percentage from prior period
}

export interface AfterSettlementTradesMonthly {
  // report info

  rptFnclOprtnsMonthlyId: number;
  rptPeriod: string; // format = '10-01-2017'
  crdId: string;
  rptId: number;

  // firm
  ttlEqtyTrdsCnclsAfCt: number; // equity trades cancels after settlement: count
  eqtyTrdsCnclsAfPt: number; // equity trades cancels after settlement: percentage of all trades
  eqtyTrdsCnclsAfChngPt: number; // equity trades cancels after settlement: change percentage from prior period

  // industry
  indTtlEqtyTrdsCnclsAfCt: number; // equity trades cancels after settlement: count
  indEqtyTrdsCnclsAfPt: number; // equity trades cancels after settlement: percentage of all trades
  indEqtyTrdsCnclsAfChngPt: number; // equity trades cancels after settlement: change percentage from prior period
}

/**
 * Interface for daily trades.
 * Here is an example of a canceled and as-of trades daily object from the backend:
 * @example:
  {
    cnclsAsOfsDailyId: 31177684,
    crdId: "10083",
    nxchngAsOfTrdsCt: 0,
    nxchngEqtyTrdsCnclsAfCt: 0,
    nxchngEqtyTrdsCnclsBfCt: 0,
    nxchngTrdsCt: null,
    prcsdDt: "07-03-2017",
    rptId: 21140734,
    ttlAsOfTrdsCt: 0,
    ttlEqtyTrdsCnclsAfCt: 0,
    ttlEqtyTrdsCnclsBfCt: 0,
    ttlTrdsCt: null,
    xchngAsOfTrdsCt: 0,
    xchngEqtyTrdsCnclsAfCt: 0,
    xchngEqtyTrdsCnclsBfCt: 0,
    xchngTrdsCt: null
  }
 */
export interface CanceledAsOfTradesDaily {
  // report info
  cnclsAsOfsDailyId: number;
  crdId: string;
  prcsdDt: string; // format = '10-01-2017'
  rptId: number;

  // all (total) trades
  ttlTrdsCt: number;
  xchngTrdsCt: number;
  nxchngTrdsCt: number;

  // as-of trades
  ttlAsOfTrdsCt: number;
  xchngAsOfTrdsCt: number;
  nxchngAsOfTrdsCt: number;

  // equity trade cancels on or before settlement
  ttlEqtyTrdsCnclsBfCt: number;
  xchngEqtyTrdsCnclsBfCt: number;
  nxchngEqtyTrdsCnclsBfCt: number;

  // equity trade cancels after settlement
  ttlEqtyTrdsCnclsAfCt: number;
  xchngEqtyTrdsCnclsAfCt: number;
  nxchngEqtyTrdsCnclsAfCt: number;
}


export interface TradesMonthlySummary {
  rptFnclOprtnsMonthlyId: number;
  reportId: number;
  crdId: string;
  reportPeriod: string;
  firm: {
    totalCount: number;
    percentageOfTrades?: number;
    percentageChangeFromPrior: number;
  };
  industry: {
    totalCount: number;
    percentageOfTrades?: number;
    percentageChangeFromPrior: number;
  };
}

export interface TradesDailySummary {
  // report info
  cnclsAsOfsDailyId: number;
  crdId: string;
  processedDate: string; // format = '10-01-2017'
  reportId: number;

  total: number;
  exchange: number;
  nonExchange: number;
}

/**
 * These interfaces are for transforming the canceled and as-of trades report data that comes from the API into an object
 * that makes the data easier to work with; The properties are hard to understand in its raw form.
 * Not only that, both unsecured and margin debits have the same fields, as far as where it is supposed to appear on the UI.
 *
 * A helper method (located below) will be provided to make that easy.
 * Since each month has all the trades data, the helper method below
 * will parse the summary data, transform it and return an array with all;
 * the order of the array items
 *
 * @return: [
 *  - `AllTradesMonthlySummary`
 *  - `AsOfTradesMonthlySummary`
 *  - `BeforeSettlementTradesMonthlySummary`
 *  - `AfterSettlementTradesMonthlySummary`
 * ];
 */

// enum for the types of trades
export enum TradeTypes {
  ALL_TRADE = 1,
  AS_OF_TRADE,
  BEFORE_SETTLEMENT,
  AFTER_SETTLEMENT,
}

export interface AllTradesMonthlySummary extends TradesMonthlySummary {
  tradesType: TradeTypes.ALL_TRADE;
  monthYear?: string;
  detailsData?: AllTradesDailySummary[];
}

export interface AsOfTradesMonthlySummary extends TradesMonthlySummary {
  tradesType: TradeTypes.AS_OF_TRADE;
  monthYear?: string;
  detailsData?: AsOfTradesDailySummary[];
}

export interface BeforeSettlementTradesMonthlySummary extends TradesMonthlySummary {
  tradesType: TradeTypes.BEFORE_SETTLEMENT;
  monthYear?: string;
  detailsData?: BeforeSettlementTradesDailySummary[];
}

export interface AfterSettlementTradesMonthlySummary extends TradesMonthlySummary {
  tradesType: TradeTypes.AFTER_SETTLEMENT;
  monthYear?: string;
  detailsData?: AfterSettlementTradesDailySummary[];
}

export interface AllTradesDailySummary extends TradesDailySummary {
  tradesType: TradeTypes.ALL_TRADE;
}

export interface AsOfTradesDailySummary extends TradesDailySummary {
  tradesType: TradeTypes.AS_OF_TRADE;
}

export interface BeforeSettlementTradesDailySummary extends TradesDailySummary {
  tradesType: TradeTypes.BEFORE_SETTLEMENT;
}

export interface AfterSettlementTradesDailySummary extends TradesDailySummary {
  tradesType: TradeTypes.AFTER_SETTLEMENT;
}

export function parseMonthlyCanceledAsOfTradesSumary(monthlyData: CanceledAsOfTradesMonthly): [
  AllTradesMonthlySummary,
  AsOfTradesMonthlySummary,
  BeforeSettlementTradesMonthlySummary,
  AfterSettlementTradesMonthlySummary
] {
  const allTradesMonthlySummary: AllTradesMonthlySummary = {
    rptFnclOprtnsMonthlyId: monthlyData.rptFnclOprtnsMonthlyId,
    reportId: monthlyData.rptId,
    crdId: monthlyData.crdId,
    reportPeriod: monthlyData.rptPeriod,
    firm: {
      totalCount: monthlyData.ttlTrdsCt,
      percentageChangeFromPrior: monthlyData.trdsChngPt,
    },
    industry: {
      totalCount: monthlyData.indTtlTrdsCt,
      percentageChangeFromPrior: monthlyData.indTrdsChngPt,
    },
    tradesType: TradeTypes.ALL_TRADE,
  };

  const asOfTradesMonthlySummary: AsOfTradesMonthlySummary = {
    rptFnclOprtnsMonthlyId: monthlyData.rptFnclOprtnsMonthlyId,
    reportId: monthlyData.rptId,
    crdId: monthlyData.crdId,
    reportPeriod: monthlyData.rptPeriod,
    firm: {
      totalCount: monthlyData.ttlAsOfTrdsCt,
      percentageOfTrades: monthlyData.asOfTrdsPt,
      percentageChangeFromPrior: monthlyData.asOfTrdsChngPt,
    },
    industry: {
      totalCount: monthlyData.indTtlAsOfTrdsCt,
      percentageOfTrades: monthlyData.indAsOfTrdsPt,
      percentageChangeFromPrior: monthlyData.indAsOfTrdsChngPt,
    },
    tradesType: TradeTypes.AS_OF_TRADE,
  };

  const beforeSettlementTradesMonthlySummary: BeforeSettlementTradesMonthlySummary = {
    rptFnclOprtnsMonthlyId: monthlyData.rptFnclOprtnsMonthlyId,
    reportId: monthlyData.rptId,
    crdId: monthlyData.crdId,
    reportPeriod: monthlyData.rptPeriod,
    firm: {
      totalCount: monthlyData.ttlEqtyTrdsCnclsBfCt,
      percentageOfTrades: monthlyData.eqtyTrdsCnclsBfPt,
      percentageChangeFromPrior: monthlyData.eqtyTrdsCnclsBfChngPt,
    },
    industry: {
      totalCount: monthlyData.indTtlEqtyTrdsCnclsBfCt,
      percentageOfTrades: monthlyData.indEqtyTrdsCnclsBfPt,
      percentageChangeFromPrior: monthlyData.indEqtyTrdsCnclsBfChngPt,
    },
    tradesType: TradeTypes.BEFORE_SETTLEMENT,
  };

  const afterSettlementTradesMonthlySummary: AfterSettlementTradesMonthlySummary = {
    rptFnclOprtnsMonthlyId: monthlyData.rptFnclOprtnsMonthlyId,
    reportId: monthlyData.rptId,
    crdId: monthlyData.crdId,
    reportPeriod: monthlyData.rptPeriod,
    firm: {
      totalCount: monthlyData.ttlEqtyTrdsCnclsAfCt,
      percentageOfTrades: monthlyData.eqtyTrdsCnclsAfPt,
      percentageChangeFromPrior: monthlyData.eqtyTrdsCnclsAfChngPt,
    },
    industry: {
      totalCount: monthlyData.indTtlEqtyTrdsCnclsAfCt,
      percentageOfTrades: monthlyData.indEqtyTrdsCnclsAfPt,
      percentageChangeFromPrior: monthlyData.indEqtyTrdsCnclsAfChngPt,
    },
    tradesType: TradeTypes.AFTER_SETTLEMENT,
  };

  return [
    allTradesMonthlySummary,
    asOfTradesMonthlySummary,
    beforeSettlementTradesMonthlySummary,
    afterSettlementTradesMonthlySummary
  ];
}

export type CanceledAsOfTradesTransformed = [
  AllTradesDailySummary,
  AsOfTradesDailySummary,
  BeforeSettlementTradesDailySummary,
  AfterSettlementTradesDailySummary
];

export function parseDailyCanceledAsOfTradesSumary(
  dailyData: CanceledAsOfTradesDaily
): CanceledAsOfTradesTransformed {
  const allTradesDailySummary: AllTradesDailySummary = {
    cnclsAsOfsDailyId: dailyData.cnclsAsOfsDailyId,
    reportId: dailyData.rptId,
    crdId: dailyData.crdId,
    processedDate: dailyData.prcsdDt,

    total: dailyData.ttlTrdsCt,
    exchange: dailyData.xchngTrdsCt,
    nonExchange: dailyData.nxchngTrdsCt,

    tradesType: TradeTypes.ALL_TRADE,
  };

  const asOfTradesDailySummary: AsOfTradesDailySummary = {
    cnclsAsOfsDailyId: dailyData.cnclsAsOfsDailyId,
    reportId: dailyData.rptId,
    crdId: dailyData.crdId,
    processedDate: dailyData.prcsdDt,

    total: dailyData.ttlAsOfTrdsCt,
    exchange: dailyData.xchngAsOfTrdsCt,
    nonExchange: dailyData.nxchngAsOfTrdsCt,

    tradesType: TradeTypes.AS_OF_TRADE,
  };

  const beforeSettlementTradesDailySummary: BeforeSettlementTradesDailySummary = {
    cnclsAsOfsDailyId: dailyData.cnclsAsOfsDailyId,
    reportId: dailyData.rptId,
    crdId: dailyData.crdId,
    processedDate: dailyData.prcsdDt,

    total: dailyData.ttlEqtyTrdsCnclsBfCt,
    exchange: dailyData.xchngEqtyTrdsCnclsBfCt,
    nonExchange: dailyData.nxchngEqtyTrdsCnclsBfCt,

    tradesType: TradeTypes.BEFORE_SETTLEMENT,
  };

  const afterSettlementTradesDailySummary: AfterSettlementTradesDailySummary = {
    cnclsAsOfsDailyId: dailyData.cnclsAsOfsDailyId,
    reportId: dailyData.rptId,
    crdId: dailyData.crdId,
    processedDate: dailyData.prcsdDt,

    total: dailyData.ttlEqtyTrdsCnclsAfCt,
    exchange: dailyData.xchngEqtyTrdsCnclsAfCt,
    nonExchange: dailyData.nxchngEqtyTrdsCnclsAfCt,

    tradesType: TradeTypes.AFTER_SETTLEMENT,
  };

  return [
    allTradesDailySummary,
    asOfTradesDailySummary,
    beforeSettlementTradesDailySummary,
    afterSettlementTradesDailySummary
  ];
}

export function createEmptyCanceledAsOfTradesMonthSummaryObj(
  period: string
) {
  return {
    rptPeriod: period,
    //
    rptFnclOprtnsMonthlyId: null,
    crdId: null,
    rptId: null,
    ttlTrdsCt: null,
    trdsChngPt: null,
    ttlAsOfTrdsCt: null,
    asOfTrdsPt: null,
    asOfTrdsChngPt: null,
    ttlEqtyTrdsCnclsBfCt: null,
    eqtyTrdsCnclsBfPt: null,
    eqtyTrdsCnclsBfChngPt: null,
    ttlEqtyTrdsCnclsAfCt: null,
    eqtyTrdsCnclsAfPt: null,
    eqtyTrdsCnclsAfChngPt: null,
    indTtlTrdsCt: null,
    indTrdsChngPt: null,
    indTtlAsOfTrdsCt: null,
    indAsOfTrdsPt: null,
    indAsOfTrdsChngPt: null,
    indTtlEqtyTrdsCnclsBfCt: null,
    indEqtyTrdsCnclsBfPt: null,
    indEqtyTrdsCnclsBfChngPt: null,
    indTtlEqtyTrdsCnclsAfCt: null,
    indEqtyTrdsCnclsAfPt: null,
    indEqtyTrdsCnclsAfChngPt: null,
  };
}

export type TradesMonthlyUnion =
  AllTradesMonthlySummary |
  AsOfTradesMonthlySummary |
  BeforeSettlementTradesMonthlySummary |
  AfterSettlementTradesMonthlySummary;

export type TradesMonthlyListUnion =
  AllTradesMonthlySummary[] |
  AsOfTradesMonthlySummary[] |
  BeforeSettlementTradesMonthlySummary[] |
  AfterSettlementTradesMonthlySummary[];

export type TradesDailyUnion =
  AllTradesDailySummary |
  AsOfTradesDailySummary |
  BeforeSettlementTradesDailySummary |
  AfterSettlementTradesDailySummary;

export type TradesDailyListUnion =
  AllTradesDailySummary[] |
  AsOfTradesDailySummary[] |
  BeforeSettlementTradesDailySummary[] |
  AfterSettlementTradesDailySummary[];

/** Export Summary Logic */

export function canceledAsOfTradesSummaryExportData(
  allTradesMonthly,
  asOfTradesMonthly,
  beforeSettlementTradesMonthly,
  afterSettlementTradesMonthly,
) {
  const massDataStringList = [];

  const allTradesMainHeadings = buildColumnLabelsString([
    [
      '',
      'Firm',
      '',
      'Industry',
      '',
    ],
    [
      'Period',
      'Count',
      '% Change from Prior Period',
      'Count',
      '% Change from Prior Period',
    ],
  ]);
  const otherTradesMainHeadings = buildColumnLabelsString([
    [
      '',
      'Firm',
      '',
      '',
      'Industry',
      '',
      '',
    ],
    [
      'Period',
      'Count',
      '% of Trades',
      '% Change from Prior Period',
      'Count',
      '% of Trades',
      '% Change from Prior Period',
    ],
  ]);

  const generateRowDataCsv = (data: TradesMonthlyUnion, isAllTrades: boolean = false) => {
    const dataStringList = [];
    const periodDate = getMonthYrDate(data.reportPeriod);

    const groupStringData = buildRowDataString({
      rowData: [data],
      dataMappings: (isAllTrades
      ? [
          { value: periodDate },
          'firm.totalCount',
          'firm.percentageChangeFromPrior',
          'industry.totalCount',
          'industry.percentageChangeFromPrior',
        ]
      : [
          { value: periodDate },
          'firm.totalCount',
          'firm.percentageOfTrades',
          'firm.percentageChangeFromPrior',
          'industry.totalCount',
          'industry.percentageOfTrades',
          'industry.percentageChangeFromPrior',
        ]
      ),
    });

    const detailsStringData = buildExportDataString([
      {
        title: null,
        columnLabels: [
          '',
          'Total',
          'Exchange',
          'Non-Exchange',
        ],
        rowData: data.detailsData,
        dataMappings: [
          'processedDate',
          'total',
          'exchange',
          'nonExchange',
        ],
      }
    ]);

    dataStringList.push(groupStringData);
    dataStringList.push(detailsStringData);

    const dataCsvString = dataStringList.join('');
    return dataCsvString;
  };

  massDataStringList.push(`All Trades\n`);
  massDataStringList.push(allTradesMainHeadings);
  for (const data of allTradesMonthly) {
    const dataString = generateRowDataCsv(data, true);
    massDataStringList.push(dataString);
  }

  massDataStringList.push(`As-Of Trades\n`);
  massDataStringList.push(otherTradesMainHeadings);
  for (const data of asOfTradesMonthly) {
    const dataString = generateRowDataCsv(data);
    massDataStringList.push(dataString);
  }

  massDataStringList.push(`Trade Cancels on or before Settlement\n`);
  massDataStringList.push(otherTradesMainHeadings);
  for (const data of beforeSettlementTradesMonthly) {
    const dataString = generateRowDataCsv(data);
    massDataStringList.push(dataString);
  }

  massDataStringList.push(`Trade Cancels after Settlements\n`);
  massDataStringList.push(otherTradesMainHeadings);
  for (const data of afterSettlementTradesMonthly) {
    const dataString = generateRowDataCsv(data);
    massDataStringList.push(dataString);
  }

  massDataStringList.push(`\n`);
  const massCsvDataString = massDataStringList.join('');
  const csvFormatted = trimTrailingChar(massCsvDataString, '\n');
  return csvFormatted;
}

export function exportCanceledAsOfTradesSummarySummary(
  allTradesMonthly,
  asOfTradesMonthly,
  beforeSettlementTradesMonthly,
  afterSettlementTradesMonthly,
  useFileName?,
) {
  const exportData = canceledAsOfTradesSummaryExportData(
    allTradesMonthly,
    asOfTradesMonthly,
    beforeSettlementTradesMonthly,
    afterSettlementTradesMonthly,
  );
  saveCsv(exportData, useFileName);
}
