import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  faDownload,
  faPrint,
  faAngleDown,
  faFilePdf,
  faFileExcel,
  faSpinner,
  faQuestionCircle,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { ActivatedRoute, Params } from "@angular/router";
import { DqsService } from "src/app/services/dqs.service";
import { HelperService } from "src/app/services/helper.service";
import {
  GLOBAL_REPORT_DISCLAIMER_2,
  ReportService,
} from "src/app/services/report.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import {
  DQS_STATES,
  FQS_STATES,
  DQS_AUTO_GROUP_COLUMN_DEFS,
  DQS_CREATE_COLUMN_DEFS,
  FQS_COLUMN_DEFS,
  DqsSummaryDataResponse,
  DqsFilterDataResponse,
  DqsSummaryData,
  FqsSummaryData,
  FqsSummaryResponse,
  DqsReportConfigurationResponse,
  DqsFilterData,
  DqsFqsFlag,
  DqsReportConfigurationData,
  DQS_CATEGORY_ID,
} from "src/app/configs/model/dqs.model";
import {
  ToolbarConfigOptions,
  ToolbarConfig,
  ToolbarCell,
  ToolbarSelectControlComponent,
} from "src/app/configs/model/finra-toolbar.model";
import { DqsToolbarConfig } from "src/app/configs/toolbar-config/dqs.toolbar";
import { ToolbarService } from "src/app/services/toolbar.service";
import { HttpErrorResponse } from "@angular/common/http";
import { UiWidgetsService } from "src/app/services/ui-widgets.service";
import { HelpLinksService } from "src/app/services/help-links.service";
import { BeastService } from "src/app/services/beast.service";
import { DqsFqsNames, ReportCategoryNames } from "src/app/configs/model/reports.model";
import { BeastOtherActions, BeastClickActions } from "src/app/enums/beast.enum";
import { BeastLeaveDqsReportPageInfo, BeastClickEventReportHelpLinkInfo, BeastClickEventDqsPdfPrintInfo, BeastClickEventDqsToolbarGoInfo, BeastClickEventFqsDetailExportInfo, BeastClickEventDqsDetailExportInfo } from "src/app/interfaces/beast.interface";
import { FirmInfoService } from "src/app/services/firm-info.service";
import { FirmInfo } from "src/app/configs/model/firm-info.model";
import { weakSort, sort_distinct } from "src/app/shared/utils/utils";

@Component({
  selector: "report-dqs",
  templateUrl: "./dqs.component.html",
  styleUrls: ["./dqs.component.scss"],
})
export class DqsComponent implements OnInit, OnDestroy {
  faDownload: IconDefinition = faDownload;
  faPrint: IconDefinition = faPrint;
  faAngleDown: IconDefinition = faAngleDown;
  faFilePdf: IconDefinition = faFilePdf;
  faFileExcel: IconDefinition = faFileExcel;
  faSpinner: IconDefinition = faSpinner;
  generatingPdf: boolean;
  clientHeight: number;
  footerHeight: number = 220;

  viewName: string;
  firmId: string;

  examYears: any[];
  examIds: any[];
  requestIds: any[];

  examYear: any;
  examId: any;
  requestId: any;
  dqsFilter: DqsFilterData;

  isFiltersLoaded: boolean = false;
  disclaimer: string = GLOBAL_REPORT_DISCLAIMER_2;
  columnDefs: { [key: string]: any }[] = null;
  FQS_COLUMN_DEFS = FQS_COLUMN_DEFS;
  showReportDetails: boolean = true;
  autoGroupColumnDefs = DQS_AUTO_GROUP_COLUMN_DEFS;
  rowData: DqsSummaryData[] = [];
  fqsRowData: { [key: string]: FqsSummaryData[] };
  title: string = "Validation Summary Report";
  firmName: string;
  dqsToolbarConfig: ToolbarConfig;
  dqsToolbarConfigOptions: ToolbarConfigOptions;
  description: any;
  help: any;
  downloadFileName: string = "DQS";
  gridOptions = {
    getRowHeight: function (params) {
      // assuming 50 characters per line, working how how many lines we need
      return (params.data.validationName || "").length > 200
        ? 75 * (Math.floor(params.data.validationName.length / 350) + 1)
        : 50;
    },
  };
  peerGroupData;
  dqsFilterData: DqsFilterDataResponse;
  DqsFqsFlag = DqsFqsFlag;
  asOfDateObj: { asOfDate: string } = { asOfDate: "" };
  toolBarForm: FormGroup;
  summaryData: any;
  FQS_STATES = FQS_STATES;
  states = DQS_STATES;
  dqsReportConfig: DqsReportConfigurationData;
  summaryDataResponse: DqsSummaryDataResponse;
  fileQualityDataResponse: FqsSummaryResponse;
  loadedAt: number;

  get hasPeriodData(): boolean {
    return this.rowData.length === 0;
  }

  constructor(
    private titleService: Title,
    private dqsService: DqsService,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private toolbarService: ToolbarService,
    private firmInfoService: FirmInfoService,
    private reportService: ReportService,
    private uiWidgetsService: UiWidgetsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`File and Data Quality Scorecard`);
    this.clientHeight = window.innerHeight;
    const handleClickFn = this.handleClick.bind(this);
    this.columnDefs = DQS_CREATE_COLUMN_DEFS(handleClickFn);

    this.toolBarForm = this.formBuilder.group({
      examYear: null,
      examId: null,
      requestId: null,
    });

    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });

    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    const moment = Date.now();
    const duration = moment - this.loadedAt;
    const eventInfo: BeastLeaveDqsReportPageInfo = {
      duration,
      requestId: this.requestId,
      examId: this.examId,
      examYear: this.examYear,
      firmId: this.firmId,
      viewName: this.viewName,
      reportCategoryId: DQS_CATEGORY_ID,
    };
    this.beastService.clickStream.postEvent(
      BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
      eventInfo
    );
  }

  handleParamsChange(params: Params) {
    /**
     * Handle params changes.
     * ---
     *
     * The first thing that this page needs is the firm id, which is in the path URL.
     * The firm id is used to load:
     * - firm name
     * - filters
     * - dqs report instance metadata
     *
     * the config and filters only needs to be loaded once,
     * so the `handleParamsChange` method uses the `isFiltersLoaded` flag.
     * This is used to track the initial load. if true, then the filters/config is already loaded;
     * fetch summary data based on selected filter. else, load and parse the config/filters, update the flag,
     * then fetch summary data.
     */
    this.viewName = params.viewName;
    this.firmId = params.firmId;
    this.examYear = params.examYear;
    this.examId = params.examId;
    this.requestId = params.requestId;

    if (this.isFiltersLoaded) {
      const dqsFilter = this.dqsFilterData.find(
        (filter) =>
          filter.examYear === this.examYear &&
          filter.examId === this.examId &&
          filter.requestId === this.requestId
      );
      this.dqsFilter = dqsFilter;
      if (!this.dqsFilter) {
        this.uiWidgetsService.showErrorSnackbar(
          `Could not load data: unknown filter.`
        );
        return;
      }
      this.fetchData(this.dqsFilter);
      return;
    }

    this.isFiltersLoaded = true;

    return this.getConfigData().then(
      (values) => {
        this.handleConfigData(values);
      },
      (error: HttpErrorResponse) => {
        this.uiWidgetsService.showErrorSnackbar(
          `Could not load config data...`
        );
      }
    );
  }

  handleConfigData(values) {
    this.processConfigData(values);
    const dqsFilter = this.dqsFilterData.find(
      (filter) =>
        filter.examYear === this.examYear &&
        filter.examId === this.examId &&
        filter.requestId === this.requestId
    );
    this.dqsFilter = dqsFilter;
    if (!this.dqsFilter) {
      this.uiWidgetsService.showErrorSnackbar(
        `Could not load data: unknown filter.`
      );
      return;
    }
    this.fetchData(this.dqsFilter);
  }

  fetchData(dqsFilter: DqsFilterData) {
    if (dqsFilter.fqsDqsFlg === DqsFqsFlag.FQS_READY) {
      this.getFqsData();
    } else if (dqsFilter.fqsDqsFlg === DqsFqsFlag.DQS_READY) {
      this.getDqsData();
    } else {
      throw new TypeError(`dqsFilter argument: Unknown/missing flag...`);
    }
  }

  getConfigData() {
    const firmInfoPromise = this.firmInfoService
      .getFirmInfo(this.firmId)
      .toPromise();
    const getDataPromise = this.dqsService
      .getDataByFirmId(this.firmId)
      .toPromise();
    const dqsReportConfig = this.dqsService
      .getReportConfiguration()
      .toPromise();
    return Promise.all([
      firmInfoPromise,
      getDataPromise,
      dqsReportConfig,
    ]).catch((error: HttpErrorResponse) => {
      this.helperService.handleReportSummaryDataError(error);
      return null;
    });
  }

  parseControlsValues() {
    /**
     * Take all the filters and create a list of each unique value from each field.
     * loop through the filters list and assign the values as a key to the appropriate object
     * to avoid duplicates keys. onces loop is done,
     * get the keys of each object; this will be the list of unique values.
     *
     * these will be the lists that contain the original data that future filtering
     * will be based on (when user changes toolbar fields).
     *
     * this method should only be called once.
     */
    const examYearsObj = {};
    const examIdsObj = {};
    const requestIdsObj = {};

    this.dqsFilterData.forEach((filter) => {
      examYearsObj[filter.examYear] = filter.examYear;
      examIdsObj[filter.examId] = filter.examId;
      requestIdsObj[filter.requestId] = filter.requestId;
    });

    const examYears = Object.keys(examYearsObj);
    const examIds = Object.keys(examIdsObj);
    const requestIds = Object.keys(requestIdsObj);

    this.examYears = examYears;
    this.examIds = examIds;
    this.requestIds = requestIds;
  }

  processConfigData(
    values: [FirmInfo[], DqsFilterDataResponse, DqsReportConfigurationResponse]
  ) {
    /**
     * set response data to component props.
     * set the toolbar form values to the values from the url params;
     * this happens only once for the initial config/filters load.
     *
     * once initial data is parsed, set the toolbar.
     */
    if (!values) {
      return;
    }
    this.dqsReportConfig = values[2][0];
    const firmInfoList: FirmInfo[] = values[0];
    const dqsFilterData = values[1];
    this.firmName =
      firmInfoList && firmInfoList.length && firmInfoList[0].firmName;
    this.dqsFilterData = dqsFilterData;
    this.parseControlsValues();
    weakSort(this.examYears, "number", true);
    this.toolBarForm.get("examYear").setValue(this.examYear);
    this.onExamYearChange();
    this.toolBarForm.get("examId").setValue(this.examId);
    this.onExamIdChange();
    this.toolBarForm.get("requestId").setValue(this.requestId);
    this.setToolbar();
  }

  processDqsSummaryData(data: DqsSummaryDataResponse) {
    /**
     * loop through data and separate into lists
     * by validation state. each list will be put into
     * object to be referenced by mapping (validation state)
     * in the template.
     */
    if (!data) {
      return;
    }
    this.summaryDataResponse = data;
    this.summaryData = {};
    this.states.forEach((x) => {
      this.summaryData[x["value"]] = [];
    });
    this.asOfDateObj.asOfDate = "";
    this.rowData = data.columnsData;
    this.rowData.forEach((row: DqsSummaryData) => {
      if (this.asOfDateObj.asOfDate.length === 0) {
        this.asOfDateObj.asOfDate = row.asOfDate;
      }
      if (!(row.validationResultState in this.summaryData)) {
        this.summaryData[row.validationResultState] = [];
      }
      this.summaryData[row.validationResultState].push(row);
    });
  }

  setToolbar() {
    const options: ToolbarConfigOptions = {
      // null place holders
      description: null,
      firmNameLabel: null,
      firmIdLabel: null,
      firmIdValue: null,
      firmNameValue: null,
      periods: null,
      versions: null,
      views: null,
      pdfClickHandler: null,
      detailsClickHandler: null,

      // dqs values
      examYears: this.examYears, // object reference
      examIds: this.examIds, // object reference
      requestIds: this.requestIds, // object reference

      examYearControl: this.toolBarForm.controls.examYear,
      examIdControl: this.toolBarForm.controls.examId,
      requestIdControl: this.toolBarForm.controls.requestId,
      asOfDateObj: this.asOfDateObj,
      asOfDateObjProp: "asOfDate",
      pdfIcon: faPrint,
      helpIcon: faQuestionCircle,
      firmName: this.firmName,
      crdId: this.firmId,

      // methods
      onExamYearChange: () => {
        this.onExamYearChange();
      },
      onExamIdChange: () => {
        this.onExamIdChange();
      },
      helpBtnCallback: () => {
        window.open(this.helpLinksService.FQS_DQS, '_blank');
        const eventInfo: BeastClickEventReportHelpLinkInfo = {
          reportType: this.dqsReportConfig.reportConfiguration.reportDisplayName
        };
        this.beastService.clickStream.postEvent(
          BeastClickActions.REPORT_DQS_HELP_LINK_CLICK,
          eventInfo
        );
      },
      changeClickHandler: () => {
        this.onToolbarGo();
      },
      setPdfClickHandler: (cellElement: ElementRef, cell: ToolbarCell) => {
        cellElement.nativeElement.addEventListener("click", () => {
          /** Log BEAST event */
          const dqsFilter = this.dqsFilterData.find(
            (filter) =>
              filter.examYear === this.toolBarForm.value.examYear &&
              filter.examId === this.toolBarForm.value.examId &&
              filter.requestId === this.toolBarForm.value.requestId
          );
          const eventInfo: BeastClickEventDqsPdfPrintInfo = {
            ...dqsFilter,
            firmId: this.firmId,
            reportView: this.viewName,
            reportVersion: this.dqsReportConfig.reportDataVersion,
          };
          this.beastService.clickStream.postEvent(
            BeastClickActions.REPORT_DQS_PDF_PRINT,
            eventInfo
          );

          this.toolbarService.onExportPdf(
            this.downloadFileName,
            this.dqsToolbarConfig,
            null,
            cell
          );
        });
      },
    };

    const toolbarConfig = DqsToolbarConfig(options);
    this.dqsToolbarConfig = toolbarConfig;
    this.dqsToolbarConfigOptions = options;
  }

  onToolbarGo() {
    /**
     * Go button in toolbar was clicked.
     * check if any of the fields changed in the toolbar
     * from what was currently selected.
     * if not, then stop and return;
     * if so, trigger the navigation, which will then trigger the
     * params change subscription in this component.
     */
    const dqsFilter = this.dqsFilterData.find(
      (filter) =>
        filter.examYear === this.toolBarForm.value.examYear &&
        filter.examId === this.toolBarForm.value.examId &&
        filter.requestId === this.toolBarForm.value.requestId
    );
    const noFieldsChangedFromLastPageLoad =
      dqsFilter.examYear === this.examYear &&
      dqsFilter.examId === this.examId &&
      dqsFilter.requestId === this.requestId;
    if (noFieldsChangedFromLastPageLoad) {
      return;
    }

    /** Log BEAST event */
    const eventInfo: BeastClickEventDqsToolbarGoInfo = {
      ...dqsFilter,
      firmId: this.firmId,
      reportView: this.viewName,
      reportVersion: this.dqsReportConfig.reportDataVersion,
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_DQS_TOOLBAR_GO,
      eventInfo
    );

    this.reportService.navigateToReportDetails({
      report: this.dqsReportConfig as any,
      firmId: this.firmId,
      dqsFilter: dqsFilter,
    });
  }

  async fqsDetailDownload() {
    const view = "FqsFileErrors";
    const { examYear, examId, requestId } = this.toolBarForm.value;
    const fileName = `FQS_${this.firmId}_${requestId}_${view}.csv`;
    const url = this.dqsService.getDetailsDataUrl(this.firmId, requestId, view);
    this.helperService.downloadFile(url, true, fileName);

    // log beast event
    const eventInfo: BeastClickEventFqsDetailExportInfo = {
      requestId: this.toolBarForm.value.requestId,
      examId: this.toolBarForm.value.examId,
      examYear: this.toolBarForm.value.examYear,
      viewName: this.viewName,
      firmId: this.firmId,
      reportType: ReportCategoryNames.FQS_DQS,
      type: DqsFqsNames.TYPE_FQS,
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_FQS_DETAIL_DOWNLOAD_FROM_REPORT_PAGE,
      eventInfo
    );
  }

  async handleClick(params: any, rowIndex: number) {
    const validation = params.data.validation.replace(/\W/g, "");
    const validationGroup = params.data.validationGroup.replace(/\W/g, "");
    const view = validationGroup + validation;
    const { examYear, examId, requestId } = this.toolBarForm.value;
    const fileName = `DQS_${this.firmId}_${requestId}_${view}.csv`;
    const url = this.dqsService.getDetailsDataUrl(this.firmId, requestId, view);
    this.helperService.downloadFile(url, true, fileName);

    // log beast event
    const eventInfo: BeastClickEventDqsDetailExportInfo = {
      requestId: this.toolBarForm.value.requestId,
      examId: this.toolBarForm.value.examId,
      examYear: this.toolBarForm.value.examYear,
      viewName: this.viewName,
      firmId: this.firmId,
      reportType: ReportCategoryNames.FQS_DQS,
      validation,
      validationGroup,
      type: DqsFqsNames.TYPE_DQS,
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_DQS_DETAIL_DOWNLOAD_FROM_REPORT_PAGE,
      eventInfo
    );
  }

  onExamYearChange() {
    const examYear = this.toolBarForm.get("examYear").value;
    if (!examYear) {
      // `Exam year has no form value...`
      return;
    }
    const examIdsFiltered = this.dqsFilterData.filter(
      (x) => x.examYear === examYear
    );
    const examIdsObj = examIdsFiltered.reduce((acc, cur) => {
      acc[cur.examId] = cur.examId;
      return acc;
    }, {});
    const examIdsList = Object.keys(examIdsObj);
    this.examIds = examIdsList;
    const useExamId = this.examIds[0];
    this.toolBarForm.get("examId").setValue(useExamId);
    this.onExamIdChange();
  }

  onExamIdChange() {
    const examYear = this.toolBarForm.get("examYear").value;
    const examId = this.toolBarForm.get("examId").value;
    const requestIdsFiltered = this.dqsFilterData
      .filter((x) => x.examYear === examYear && x.examId === examId)
      .map((x) => x.requestId);
    this.requestIds = requestIdsFiltered;
    if (this.dqsToolbarConfig) {
      const examIdsCell: ToolbarCell = this.dqsToolbarConfig.rows[1]
        .components[0].components[1];
      const requestIdsCell: ToolbarCell = this.dqsToolbarConfig.rows[1]
        .components[0].components[2];
      (<ToolbarSelectControlComponent>(
        examIdsCell.component
      )).dataList = this.examIds;
      (<ToolbarSelectControlComponent>(
        requestIdsCell.component
      )).dataList = this.requestIds;
    }
    const useRequestId = this.requestIds[0];
    this.toolBarForm.get("requestId").setValue(useRequestId);
  }

  getDqsData() {
    return this.dqsService
      .getSummaryData(this.firmId, this.requestId, this.viewName)
      .subscribe(
        (data: DqsSummaryDataResponse) => {
          this.processDqsSummaryData(data);
          return null;
        },
        (error: HttpErrorResponse) => {
          this.summaryDataResponse = null;
          this.summaryData = null;
          this.helperService.handleReportSummaryDataError(error);
        }
      );
  }

  getFqsData(dataType: string = "s") {
    return this.dqsService
      .getDqsData<FqsSummaryResponse>(
        this.firmId,
        this.requestId,
        `SRAFileErrorsSummaryReport`,
        dataType
      )
      .subscribe(
        (data) => {
          this.fileQualityDataResponse = data;
          this.asOfDateObj.asOfDate = data.columnsData.length
            ? data.columnsData[0].asOfDate
            : "";
          const distinctStatus = sort_distinct(
            data.columnsData,
            "validationResultStateCode",
            false
          );
          this.fqsRowData = distinctStatus;
          return null;
        },
        (error: HttpErrorResponse) => {
          this.fileQualityDataResponse = null;
          this.fqsRowData = null;
          this.helperService.handleReportSummaryDataError(error);
        }
      );
  }
}
