import { Component, OnInit, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'report-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})

export class CardComponent implements OnInit {
  @Input() date: string;
  @Input() subheading: string;
  @Input() faIcon: IconDefinition;
  @Input() cardSectionUnread: boolean = false;
  @Input() cardSectionViewed: boolean = false;
  @Input() isDQS: boolean = false;
  @Input() isExternalUser: boolean;
  @Input() template: string;
  @Input() reportDate: string;
  @Input() reportVersion: string;
  @Input() reportSubHeading: string;
  @Input() reportPeriod: string;

  constructor() { }


  ngOnInit() {  }

}
