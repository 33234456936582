<section 
  class="row-body"
  *ngFor="let data of rowData; last as isLastData; index as dataIndex"
>
  <div 
    class="row-container grey-border-bottom-1"
    [attr.data-row-number]="dataIndex + 1"
    [ngClass]="{
      'blue-highlight-bg': !!data.expanded
    }"
  >
    <div class="col col-20 grey-border-right-1">
      <div class="box-padding-1 period-flex-row">
        <div class="box-padding-container">
          <span class="icon-space-1">
            <fa-icon 
              *ngIf="(data.products && !!data.products.length) || (data.problems && !!data.problems.length)" 
              [icon]="data.expanded ? faChevronDown : faChevronRight" 
              [attr.title]="(data.expanded ? 'Hide' : 'Show')"
              class="fa-lg cursor-pointer summary-chevron"
              data-html2canvas-ignore
              (click)="data.expanded = !data.expanded"
            ></fa-icon> 
          </span>
          <span class="data-cd-name" id="data-cd-name-{{ dataIndex + 1 }}">{{ data.problemCdDisplay || data.productCdDisplay }}</span>
        </div>
      </div>
    </div>
    <div class="col col-80">
      <div class="row-container col-flex-grow height-full">
        <ng-container *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex">
          <div class="row-container col col-15" [ngSwitch]="!!data['quarter' + (quarterIndex + 1)]">
            <ng-container *ngSwitchCase="true">
              <div class="col col-5 grey-border-right-1">
                <div class="box-padding-1">
                  {{ data['quarter' + (quarterIndex + 1)].totalCount | finraValidValue : 'number' }}
                </div>
              </div>
              <div class="col col-5 grey-border-right-1">
                <div class="box-padding-1">
                  {{ data['quarter' + (quarterIndex + 1)].firmPercentage | finraValidValue : 'percent' }}
                </div>
              </div>
              <div class="col col-5"
                [ngClass]="{ 
                  'grey-border-right-1': !isLastQuarter
                }"
              >
                <div class="box-padding-1">
                  {{ data['quarter' + (quarterIndex + 1)].industryPercentage | finraValidValue : 'percent' }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <div class="col col-15"
                [ngClass]="{ 
                  'grey-border-right-1': !isLastQuarter
                }"
              >
                <div class="box-padding-1">No information available for the period.</div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div> 
  <!-- sub category -->
  <ng-container *ngIf="data.expanded && ((data.products && !!data.products.length) || (data.problems && !!data.problems.length))">
    <div *ngFor="let data of (data.products || data.problems); last as lastData"
      class="row-body row-container grey-border-bottom-1">
      <div class="col col-20 grey-border-right-1 col-processed-date">
        <div class="box-padding-1">
          <span class="icon-space-1"></span>
          <span>{{ data.problemCdDisplay || data.productCdDisplay }}</span>
        </div>
      </div>
      <div class="col col-80">
        <div class="row-container col-flex-grow height-full">
          <!--  -->
          <ng-container 
            *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex"
          >
            <div class="row-container col col-15">
              <div class="col col-15"
                [ngClass]="{ 
                  'grey-border-right-1': !isLastQuarter
                }"
              >
                <div class="box-padding-1">
                  {{ data['quarter' + (quarterIndex + 1)] | finraValidValue : 'number' }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</section>