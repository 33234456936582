import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import {
  CrossMarketSupervisionReportShortNames,
  CrossManipulationApiUrls,
  ChartData,
  CrossMarketEquitiesSupervisionReport,
  Series,
  CrossMarketEquitiesSupervisionData,
  CrossMarketSupervisionPotentialSummaryData,
  CrossMarketSupervisionAtsAutoSummaryData,
  CrossMarketSupervisionSpoofingSummaryData,
} from "../configs/model/cross-manipulation-report.model";
import { GridHeader } from "../configs/model/equities-report.model";
import { ReportInstanceMetadata } from "../configs/model/reports.model";
import { getMonthLabels, getMonthFromPeriod } from "../shared/utils/utils";



@Injectable({
  providedIn: "root",
})
export class CrossMarketService {
  private baseUrl: string;
  readonly PotentialAutoExecutionManipulation: string = CrossMarketSupervisionReportShortNames.ATS;
  readonly PotentialAtsCrossManipulation: string = CrossMarketSupervisionReportShortNames.AUTO;
  readonly PotentialManipulation: string = CrossMarketSupervisionReportShortNames.POTENTIAL;

  constructor(@Inject("environment") environment, private http: HttpClient) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getRelationShips() {
    const api_url = this.baseUrl + CrossManipulationApiUrls.getRelationShips;
    return this.http.get<ChartData[]>(api_url);
  }

  getCrossMarketAtsReportAsGraphSeries(
    report: CrossMarketEquitiesSupervisionReport
  ): Array<ChartData> {
    const result = new Array<ChartData>();
    const monthLabels = getMonthLabels(report.reportPeriodDate, true);
    for (let i = 0; i < report.reportData.length; i++) {
      if (!report.reportData[i]["chartTitle"]) {
        continue;
      }
      const chartData: ChartData = {
        name: report.reportData[i]["chartTitle"],
        series: new Array<Series>(),
      };
      result.push(chartData);
      for (let j = 6; j >= 1; j--) {
        const monthKey = "month0" + j;
        const seriesKey = result.length - 1;
        const seriesData: { name: string; value: number } = {
          name: monthLabels[j - 1],
          value:
            monthKey in report.reportData[i]
              ? report.reportData[i][monthKey]
              : 0,
        };
        result[seriesKey].series.push(seriesData);
      }
    }
    return result;
  }

  private createRow(
    title: string,
    chartTitle?: string,
    showDetailLink: boolean = false
  ): CrossMarketEquitiesSupervisionData {
    const rowData: CrossMarketEquitiesSupervisionData = {
      title: title,
      chartTitle: chartTitle,
      showDetailLink: showDetailLink,
    };
    return rowData;
  }

  formatGridColumnDefsHeaders(period: string, gridColumnDefs: any[]) {
    const monthLabels = getMonthLabels(period);
    const length = gridColumnDefs.length;
    for (let i = 1; i < length; i++) {
      const monthLabel = monthLabels[i - 1];
      const columnDef = gridColumnDefs[i];
      columnDef.headerName = monthLabel;
    }
  }

  getCrossMarketEquitiesSupervisionReportDetailUrl(
    reportInstanceMetadata: ReportInstanceMetadata,
    viewName: string,
    additionalInfo: string = ""
  ): string {
    const reportId = reportInstanceMetadata.reportId;
    const api_url = `${this.baseUrl}reportData/reportId/${reportId}/viewName/${viewName}/dataType/d?additionalInfo=${additionalInfo}`;
    return api_url;
  }

  public getCrossMarketEquitiesSupervisionReport(
    report: ReportInstanceMetadata
  ): Observable<any> {
    const reportId = report.reportId;
    const viewName = this.getViewName(report);
    const api_url = `${this.baseUrl}reportData/reportId/${reportId}/viewName/${viewName}/dataType/s`;
    return this.http.get<any>(api_url).pipe(
      map((data: any) => {
        // if needed, format the response data here
        // although it should be the caller's choice to do what it wants with it
        return data;
      })
    );
  }

  processCrossMarketEquitiesSupervisionReport(
    response: any,
    reportInstanceMetadata: ReportInstanceMetadata
  ): CrossMarketEquitiesSupervisionReport {
    const reportType =
      reportInstanceMetadata.reportConfiguration.reportType.reportTypeShortName;
    const reportHeaders = new Array<GridHeader>();
    reportHeaders[0] = {
      headerName: "All Market Hours",
      field: "title",
      width: 110,
    };
    const report: CrossMarketEquitiesSupervisionReport = {
      firmId: reportInstanceMetadata.reportFirmId,
      reportData: new Array<CrossMarketEquitiesSupervisionData>(),
      reportPeriodDate: reportInstanceMetadata.reportPeriodDate,
    };

    const SUM_ROW_INDEX = 0;
    const TE_R_ROW_INDEX = 1;
    const TE_NR_ROW_INDEX = 2;

    const LAYERING_ROW_INDEX = 1;
    const LAYERING_TE_R_ROW_INDEX = 2;
    const LAYERING_TE_NR_ROW_INDEX = 3;

    const SPOOFING_ROW_INDEX = 4;
    const SPOOFING_TE_R_ROW_INDEX = 5;
    const SPOOFING_TE_NR_ROW_INDEX = 6;

    report.reportData[SUM_ROW_INDEX] = this.createRow(
      this.getSumRowTitle(reportType),
      null,
      reportType !== CrossMarketSupervisionReportShortNames.POTENTIAL
    );

    if (reportType === CrossMarketSupervisionReportShortNames.POTENTIAL) {
      report.reportData[LAYERING_ROW_INDEX] = this.createRow(
        "Layering",
        null,
        response.findIndex(
          (x) =>
            report.reportPeriodDate.indexOf(x["monthStartDate"]) === 0 &&
            x["layeringDetailDataAvailabilityFlag"] === "Y"
        ) > -1
      );

      report.reportData[LAYERING_TE_R_ROW_INDEX] = this.createRow(
        this.getRowTitle(reportType, true, false),
        this.getRowTitle(reportType, true, true)
      );
      report.reportData[LAYERING_TE_NR_ROW_INDEX] = this.createRow(
        this.getRowTitle(reportType, false, false),
        this.getRowTitle(reportType, false, true)
      );

      report.reportData[SPOOFING_ROW_INDEX] = this.createRow(
        "SPOOFING",
        null,
        response.findIndex(
          (x) =>
            report.reportPeriodDate.indexOf(x["monthStartDate"]) === 0 &&
            x["spoofingDetailDataAvailabilityFlag"] === "Y"
        ) > -1
      );
      report.reportData[SPOOFING_TE_R_ROW_INDEX] = this.createRow(
        this.getRowTitle(reportType, true, false, true),
        this.getRowTitle(reportType, true, true, true)
      );
      report.reportData[SPOOFING_TE_NR_ROW_INDEX] = this.createRow(
        this.getRowTitle(reportType, false, false, true),
        this.getRowTitle(reportType, false, true, true)
      );
    }
    else {
      report.reportData[TE_R_ROW_INDEX] = this.createRow(
        this.getRowTitle(reportType, true, false),
        this.getRowTitle(reportType, true, true)
      );
      report.reportData[TE_NR_ROW_INDEX] = this.createRow(
        this.getRowTitle(reportType, false, false),
        this.getRowTitle(reportType, false, true)
      );
    }

    const anchorMonth = getMonthFromPeriod(
      reportInstanceMetadata.reportPeriodDate
    );

    for (let i = 0; i < response.length; i++) {
      const monthOfYearNumber = parseInt(response[i].monthOfYearNumber, 10);
      const month = anchorMonth >= monthOfYearNumber
        ? (anchorMonth - monthOfYearNumber)
        : (anchorMonth + 12 - monthOfYearNumber);
      const monthIndex = month + 1;
      const monthKey = 'month0' + monthIndex;

      report.reportData[SUM_ROW_INDEX][monthKey] = response[i].exceptionCount;

      if (reportType === CrossMarketSupervisionReportShortNames.POTENTIAL) {
        const data = <CrossMarketSupervisionPotentialSummaryData> response[i];
        
        report.reportData[LAYERING_ROW_INDEX][monthKey] = data.exceptionLayeringCount;
        report.reportData[LAYERING_TE_R_ROW_INDEX][monthKey] = data.layeringRelationshipCount;
        report.reportData[LAYERING_TE_NR_ROW_INDEX][monthKey] = data.layeringNonRelationshipCount;
        
        report.reportData[SPOOFING_ROW_INDEX][monthKey] = data.exceptionSpoofingCount;
        report.reportData[SPOOFING_TE_R_ROW_INDEX][monthKey] = data.spoofingRelationshipCount;
        report.reportData[SPOOFING_TE_NR_ROW_INDEX][monthKey] = data.spoofingNonRelationshipCount;
      } 
      else if (reportType === CrossMarketSupervisionReportShortNames.SPOOFING) {
        const data = <CrossMarketSupervisionSpoofingSummaryData> response[i];
        
        report.reportData[TE_R_ROW_INDEX][monthKey] = data.spoofingRelationshipCount;
        report.reportData[TE_NR_ROW_INDEX][monthKey] = data.spoofingNonRelationshipCount;
      }
      else {
        const data = <CrossMarketSupervisionAtsAutoSummaryData> response[i];
        
        report.reportData[TE_R_ROW_INDEX][monthKey] = data.exceptionRelationshipCount;
        report.reportData[TE_NR_ROW_INDEX][monthKey] = data.exceptionNonRelationshipCount;
      }
    }

    return report;
  }

  getViewName(reportInstanceMetadata: ReportInstanceMetadata): string {
    return reportInstanceMetadata.reportConfiguration
      .reportConfigurationViews[0].viewName;
  }

  getSumRowTitle(reportType: string): string {
    switch (reportType) {
      case CrossMarketSupervisionReportShortNames.ATS:
        return 'ATS CROSS';
      case CrossMarketSupervisionReportShortNames.AUTO:
        return 'AUTO EXECUTION';
      case CrossMarketSupervisionReportShortNames.SPOOFING:
        return 'SPOOFING';
      default:
        return "Total Exceptions";
    }
  }

  getRowTitle(
    reportType: string,
    isRelationships: boolean,
    isChartTile?: boolean,
    isSpoofing?: boolean
  ): string {
    let result = "Total Exceptions";
    if (isChartTile) {
      switch (reportType) {
        case CrossMarketSupervisionReportShortNames.ATS:
          result = 'ATS Cross';
          break;

        case CrossMarketSupervisionReportShortNames.AUTO:
          result = 'Auto Execution';
          break;

        case CrossMarketSupervisionReportShortNames.SPOOFING:
          result = 'Spoofing';
          break;

        case CrossMarketSupervisionReportShortNames.POTENTIAL:
          result = isSpoofing ? 'Spoofing' : 'Layering';
          break;
      }
    }

    const title =
      result +
      (isChartTile ? " " : " - ") +
      (isRelationships ? "Relationship" : "Non Relationship");
    return title;
  }
}
