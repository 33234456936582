import {
  integerPercentValueFormatter,
  finraIsInvalidValue,
  FINRA_GRID_NULL_VALUE,
} from 'src/app/shared/utils/utils';

export const TRACE_TIMELINESS_DATA_MAPPINGS = [
  'ats',
  'nonAts',
  'avg',
  'exec',
];

/*
  see backend code - https://bitbucket.finra.org/projects/DDWA/repos/ddwa-services/browse/code/ddwa-services/ddwa-services-service/src/main/java/org/finra/ddwa2/service/handler/impl/trace/TraceTimelinessV1.java
*/
export interface TraceTimelinessSummaryDataValue {
  partition: string;
  data: {
    ATSEarlyExceptionPercent: number;
    ATSExceptionPercent: number;
    ATSLateExceptionPercent: number;
    averageATSEarlyExceptionPercent: number;
    averageATSExceptionPercent: number;
    averageATSLateExceptionPercent: number;
    averageNonATSEarlyExceptionPercent: number;
    averageNonATSExceptionPercent: number;
    averageNonATSLateExceptionPercent: number;
    nonATSEarlyExceptionPercent: number;
    nonATSExceptionPercent: number;
    nonATSLateExceptionPercent: number;
    peerATSEarlyExceptionAveragePercent: number;
    peerATSEarlyExceptionRank: number;
    peerATSExceptionAveragePercent: number;
    peerATSExceptionRank: number;
    peerATSLateExceptionAveragePercent: number;
    peerATSLateExceptionRank: number;
    peerNonATSEarlyExceptionAveragePercent: number;
    peerNonATSEarlyExceptionRank: number;
    peerNonATSExceptionAveragePercent: number;
    peerNonATSExceptionRank: number;
    peerNonATSLateExceptionAveragePercent: number;
    peerNonATSLateExceptionRank: number;
    totalATSEarlyExceptionCount: number;
    totalATSEarlyExceptionCountPrev: number;
    totalATSEarlyExceptionPercentChange: number;
    totalATSExceptionCount: number;
    totalATSExceptionCountPrev: number;
    totalATSExceptionPercentChange: number;
    totalATSLateExceptionCount: number;
    totalATSLateExceptionCountPrev: number;
    totalATSLateExceptionPercentChange: number;
    totalNonATSEarlyExceptionCount: number;
    totalNonATSEarlyExceptionCountPrev: number;
    totalNonATSEarlyExceptionPercentChange: number;
    totalNonATSExceptionCount: number;
    totalNonATSExceptionCountPrev: number;
    totalNonATSExceptionPercentChange: number;
    totalNonATSLateExceptionCount: number;
    totalNonATSLateExceptionCountPrev: number;
    totalNonATSLateExceptionPercentChange: number;
  };
}

export interface TraceTimelinessSummaryData {
  tableName: string;
  values: TraceTimelinessSummaryDataValue[];
}

export type TraceTimelinessSummaryResponse = TraceTimelinessSummaryData[];

export const TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING = {
  ats: 'Firm - ATS Execution Time Difference',
  nonAts: 'Firm - Non ATS Execution Time Difference',
  avg: 'Industry - Average Execution Time Difference (in Seconds)',
  exec: 'Industry - Execution Time Difference',
};

export const TRACE_TIMELINESS_TOOLBAR_DESCRIPTION: string =
  `The TRACE Treasuries Execution Time Difference report is a monthly summary of \
execution time comparisons for inter-dealer trades in U.S. Treasury Securities reported to TRACE. \
Firms are required to report trades in accordance with established FINRA rules and regulations.

For questions regarding the content of this specific report, \
please contact the FINRA Market Regulation Department \
at (800) 321-6273 and ask for the Fixed Income Team.`;

export const timeDiffValueTransformer = (value, mask?: '%') => {
  if (!value) {
    return;
  }
  let timeDiffStr = value.split(':')[2];
  timeDiffStr = timeDiffStr[0] === '0' ? timeDiffStr.substr(1) : timeDiffStr;
  const formatted = integerPercentValueFormatter(timeDiffStr, mask);
  return formatted;
};

export const TraceTimelinessGridTimeDiffValueTransformer = (params, mask?: '%') => {
  const value = params && params.value;
  if (finraIsInvalidValue(value)) {
    return FINRA_GRID_NULL_VALUE;
  }
  const formattedValue = timeDiffValueTransformer(value, mask);
  return formattedValue;
};

export const percentChartAxisFormatting = (value) => {
  return `${value}%`;
};

export const TRACE_TIMELINESS_Y_AXIS_FORMATTING = {
  ats: percentChartAxisFormatting,
  nonAts: percentChartAxisFormatting,
  avg: null,
  exec: percentChartAxisFormatting,
};

const makeTooltipValuePercent = (model) => `${model.value}%`;

export const TRACE_TIMELINESS_LINE_TOOLTIP_VALUE_FORMATTER = {
  ats: makeTooltipValuePercent,
  nonAts: makeTooltipValuePercent,
  avg: null,
  exec: makeTooltipValuePercent,
};

export const TRACE_TIMELINESS_LINE_SERIES_TOOLTIP_VALUE_FORMATTER = {
  ats: makeTooltipValuePercent,
  nonAts: makeTooltipValuePercent,
  avg: null,
  exec: makeTooltipValuePercent,
};

/**
 * TRACE Timeliness Table Mapping Definitions.
 * ---
 * Each `TraceTimelinessTableDataMappingDef` represents a time difference (a row).
 * For each row, there is a display name and data for each period.
 * For each period, there is a list of mappings for that data, represented by `mappingsByPeriod`;
 * The service method that processes these configs will create an object for each time difference
 * and on that object, a property for each of the mapping, which the property name will be in the
 * format "column${i}value", where ${i} is the index of the mapping.
 * See the TraceService.formatTimelinessTableData method for more info.
 */

export interface TraceTimelinessTableDataMappingDef {
  displayName: string;
  mappingsByPeriod: Array<Array<string>>;
}

export const TRACE_TIMELINESS_ATS_TIME_DIFFERENCES_DATA_MAPPINGS: TraceTimelinessTableDataMappingDef[] = [
  {
    displayName: 'Trade Count',
    mappingsByPeriod: [
      [
        'totalATSEarlyExceptionCount',
        'totalATSLateExceptionCount',
        'totalATSExceptionCount'
      ],
      ['totalATSExceptionCount'],
      ['totalATSExceptionCount'],
      ['totalATSExceptionCount'],
      ['totalATSExceptionCount'],
      ['totalATSExceptionCount'],
    ]
  },
  {
    displayName: 'Firm %',
    mappingsByPeriod: [
      [
        'ATSEarlyExceptionPercent',
        'ATSLateExceptionPercent',
        'ATSExceptionPercent'
      ],
      ['ATSExceptionPercent'],
      ['ATSExceptionPercent'],
      ['ATSExceptionPercent'],
      ['ATSExceptionPercent'],
      ['ATSExceptionPercent'],
    ]
  },
  {
    displayName: 'Peer Average %',
    mappingsByPeriod: [
      [
        'peerATSEarlyExceptionAveragePercent',
        'peerATSLateExceptionAveragePercent',
        'peerATSExceptionAveragePercent'
      ],
      ['peerATSExceptionAveragePercent'],
      ['peerATSExceptionAveragePercent'],
      ['peerATSExceptionAveragePercent'],
      ['peerATSExceptionAveragePercent'],
      ['peerATSExceptionAveragePercent'],
    ]
  },
  {
    displayName: 'Industry Average %',
    mappingsByPeriod: [
      [
        'averageATSEarlyExceptionPercent',
        'averageATSLateExceptionPercent',
        'averageATSExceptionPercent'
      ],
      ['averageATSExceptionPercent'],
      ['averageATSExceptionPercent'],
      ['averageATSExceptionPercent'],
      ['averageATSExceptionPercent'],
      ['averageATSExceptionPercent'],
    ]
  },
  {
    displayName: 'Rank In Peer Group',
    mappingsByPeriod: [
      [
        'peerATSEarlyExceptionRank',
        'peerATSLateExceptionRank',
        'peerATSExceptionRank'
      ],
      ['peerATSExceptionRank'],
      ['peerATSExceptionRank'],
      ['peerATSExceptionRank'],
      ['peerATSExceptionRank'],
      ['peerATSExceptionRank'],
    ]
  },
  {
    displayName: 'Prior Month Trade Count',
    mappingsByPeriod: [
      [
        'totalATSEarlyExceptionCountPrev',
        'totalATSLateExceptionCountPrev',
        'totalATSExceptionCountPrev'
      ],
      ['totalATSExceptionCountPrev'],
      ['totalATSExceptionCountPrev'],
      ['totalATSExceptionCountPrev'],
      ['totalATSExceptionCountPrev'],
      ['totalATSExceptionCountPrev'],
    ]
  },
  {
    displayName: '% Change from Prior Period',
    mappingsByPeriod: [
      [
        'totalATSEarlyExceptionPercentChange',
        'totalATSLateExceptionPercentChange',
        'totalATSExceptionPercentChange'
      ],
      ['totalATSExceptionPercentChange'],
      ['totalATSExceptionPercentChange'],
      ['totalATSExceptionPercentChange'],
      ['totalATSExceptionPercentChange'],
      ['totalATSExceptionPercentChange'],
    ]
  },
];

export const TRACE_TIMELINESS_NON_ATS_TIME_DIFFERENCES_DATA_MAPPINGS: TraceTimelinessTableDataMappingDef[]  = [
  {
    displayName: 'Trade Count',
    mappingsByPeriod: [
      [
        'totalNonATSEarlyExceptionCount',
        'totalNonATSLateExceptionCount',
        'totalNonATSExceptionCount'
      ],
      ['totalNonATSExceptionCount'],
      ['totalNonATSExceptionCount'],
      ['totalNonATSExceptionCount'],
      ['totalNonATSExceptionCount'],
      ['totalNonATSExceptionCount'],
    ]
  },
  {
    displayName: 'Firm %',
    mappingsByPeriod: [
      [
        'nonATSEarlyExceptionPercent',
        'nonATSLateExceptionPercent',
        'nonATSExceptionPercent'
      ],
      ['nonATSExceptionPercent'],
      ['nonATSExceptionPercent'],
      ['nonATSExceptionPercent'],
      ['nonATSExceptionPercent'],
      ['nonATSExceptionPercent'],
    ]
  },
  {
    displayName: 'Peer Average %',
    mappingsByPeriod: [
      [
        'peerNonATSEarlyExceptionAveragePercent',
        'peerNonATSLateExceptionAveragePercent',
        'peerNonATSExceptionAveragePercent'
      ],
      ['peerNonATSExceptionAveragePercent'],
      ['peerNonATSExceptionAveragePercent'],
      ['peerNonATSExceptionAveragePercent'],
      ['peerNonATSExceptionAveragePercent'],
      ['peerNonATSExceptionAveragePercent'],
    ]
  },
  {
    displayName: 'Industry Average %',
    mappingsByPeriod: [
      [
        'averageNonATSEarlyExceptionPercent',
        'averageNonATSLateExceptionPercent',
        'averageNonATSExceptionPercent'
      ],
      ['averageNonATSExceptionPercent'],
      ['averageNonATSExceptionPercent'],
      ['averageNonATSExceptionPercent'],
      ['averageNonATSExceptionPercent'],
      ['averageNonATSExceptionPercent'],
    ]
  },
  {
    displayName: 'Rank In Peer Group',
    mappingsByPeriod: [
      [
        'peerNonATSEarlyExceptionRank',
        'peerNonATSLateExceptionRank',
        'peerNonATSExceptionRank'
      ],
      ['peerNonATSExceptionRank'],
      ['peerNonATSExceptionRank'],
      ['peerNonATSExceptionRank'],
      ['peerNonATSExceptionRank'],
      ['peerNonATSExceptionRank'],
    ]
  },
  {
    displayName: 'Prior Month Trade Count',
    mappingsByPeriod: [
      [
        'totalNonATSEarlyExceptionCountPrev',
        'totalNonATSLateExceptionCountPrev',
        'totalNonATSExceptionCountPrev'
      ],
      ['totalNonATSExceptionCountPrev'],
      ['totalNonATSExceptionCountPrev'],
      ['totalNonATSExceptionCountPrev'],
      ['totalNonATSExceptionCountPrev'],
      ['totalNonATSExceptionCountPrev'],
    ]
  },
  {
    displayName: '% Change from Prior Period',
    mappingsByPeriod: [
      [
        'totalNonATSEarlyExceptionPercentChange',
        'totalNonATSLateExceptionPercentChange',
        'totalNonATSExceptionPercentChange'
      ],
      ['totalNonATSExceptionPercentChange'],
      ['totalNonATSExceptionPercentChange'],
      ['totalNonATSExceptionPercentChange'],
      ['totalNonATSExceptionPercentChange'],
      ['totalNonATSExceptionPercentChange'],
    ]
  },
];

export const TRACE_TIMELINESS_AVERAGE_EXECUTION_TIME_DIFFERENCE_MAPPINGS: TraceTimelinessTableDataMappingDef[]  = [
  {
    displayName: 'All Trades Types',
    mappingsByPeriod: [
      ['averageTradeExecutionTimeDiff'],
      ['averageTradeExecutionTimeDiff'],
      ['averageTradeExecutionTimeDiff'],
      ['averageTradeExecutionTimeDiff'],
      ['averageTradeExecutionTimeDiff'],
      ['averageTradeExecutionTimeDiff'],
    ]
  },
  {
    displayName: 'ATS Trades',
    mappingsByPeriod: [
      ['averageATSTradeExecutionTimeDiff'],
      ['averageATSTradeExecutionTimeDiff'],
      ['averageATSTradeExecutionTimeDiff'],
      ['averageATSTradeExecutionTimeDiff'],
      ['averageATSTradeExecutionTimeDiff'],
      ['averageATSTradeExecutionTimeDiff'],
    ]
  },
  {
    displayName: 'Non-ATS Trades',
    mappingsByPeriod: [
      ['averageNonATSTradeExecutionTimeDiff'],
      ['averageNonATSTradeExecutionTimeDiff'],
      ['averageNonATSTradeExecutionTimeDiff'],
      ['averageNonATSTradeExecutionTimeDiff'],
      ['averageNonATSTradeExecutionTimeDiff'],
      ['averageNonATSTradeExecutionTimeDiff'],
    ]
  },
];

export const TRACE_TIMELINESS_EXECUTION_TIME_DIFFERENCE_MAPPINGS: TraceTimelinessTableDataMappingDef[]  = [
  {
    displayName: '>10 min to 15min',
    mappingsByPeriod: [
      [
        'ATSTradeExecutionTimeDifference900Percent',
        'nonATSTradeExecutionTimeDifference900Percent',
        'tradeExecutionTimeDifference900Percent'
      ],
      ['tradeExecutionTimeDifference900Percent'],
      ['tradeExecutionTimeDifference900Percent'],
      ['tradeExecutionTimeDifference900Percent'],
      ['tradeExecutionTimeDifference900Percent'],
      ['tradeExecutionTimeDifference900Percent'],
    ]
  },
  {
    displayName: '>5 min to 10min',
    mappingsByPeriod: [
      [
        'ATSTradeExecutionTimeDifference600Percent',
        'nonATSTradeExecutionTimeDifference600Percent',
        'tradeExecutionTimeDifference600Percent'
      ],
      ['tradeExecutionTimeDifference600Percent'],
      ['tradeExecutionTimeDifference600Percent'],
      ['tradeExecutionTimeDifference600Percent'],
      ['tradeExecutionTimeDifference600Percent'],
      ['tradeExecutionTimeDifference600Percent'],
    ]
  },
  {
    displayName: '>1 min to 5min',
    mappingsByPeriod: [
      [
        'ATSTradeExecutionTimeDifference300Percent',
        'nonATSTradeExecutionTimeDifference300Percent',
        'tradeExecutionTimeDifference300Percent'
      ],
      ['tradeExecutionTimeDifference300Percent'],
      ['tradeExecutionTimeDifference300Percent'],
      ['tradeExecutionTimeDifference300Percent'],
      ['tradeExecutionTimeDifference300Percent'],
      ['tradeExecutionTimeDifference300Percent'],
    ]
  },
  {
    displayName: '>30 to 1min',
    mappingsByPeriod: [
      [
        'ATSTradeExecutionTimeDifference60Percent',
        'nonATSTradeExecutionTimeDifference60Percent',
        'tradeExecutionTimeDifference60Percent'
      ],
      ['tradeExecutionTimeDifference60Percent'],
      ['tradeExecutionTimeDifference60Percent'],
      ['tradeExecutionTimeDifference60Percent'],
      ['tradeExecutionTimeDifference60Percent'],
      ['tradeExecutionTimeDifference60Percent'],
    ]
  },
  {
    displayName: '≤30 seconds',
    mappingsByPeriod: [
      [
        'ATSTradeExecutionTimeDifference30Percent',
        'nonATSTradeExecutionTimeDifference30Percent',
        'tradeExecutionTimeDifference30Percent'
      ],
      ['tradeExecutionTimeDifference30Percent'],
      ['tradeExecutionTimeDifference30Percent'],
      ['tradeExecutionTimeDifference30Percent'],
      ['tradeExecutionTimeDifference30Percent'],
      ['tradeExecutionTimeDifference30Percent'],
    ]
  },
  {
    displayName: 'No Time Difference',
    mappingsByPeriod: [
      [
        'ATSTradeExecutionTimeDifference0Percent',
        'nonATSTradeExecutionTimeDifference0Percent',
        'tradeExecutionTimeDifference0Percent'
      ],
      ['tradeExecutionTimeDifference0Percent'],
      ['tradeExecutionTimeDifference0Percent'],
      ['tradeExecutionTimeDifference0Percent'],
      ['tradeExecutionTimeDifference0Percent'],
      ['tradeExecutionTimeDifference0Percent'],
    ]
  },
  {
    displayName: 'All Matched',
    mappingsByPeriod: [
      [
        'ATSTradeExecutionTimeDifference86400Percent',
        'nonATSTradeExecutionTimeDifference86400Percent',
        'tradeExecutionTimeDifference86400Percent'
      ],
      ['tradeExecutionTimeDifference86400Percent'],
      ['tradeExecutionTimeDifference86400Percent'],
      ['tradeExecutionTimeDifference86400Percent'],
      ['tradeExecutionTimeDifference86400Percent'],
      ['tradeExecutionTimeDifference86400Percent'],
    ]
  },
];

/**
 * TRACE Timeliness Table Mapping Definitions.
 * ---
 * This follows the amount of mappings in a `TraceTimelinessTableDataMappingDef`'s mappingsByPeriod list,
 * not counting/considering the grouping. Should match the column index.
 */

export const TRACE_TIMELINESS_ATS_AND_NON_ATS_COLUMN_MAPPINGS = [
  'Earlier Report',
  'Later Report',
  'Total Execution Time Difference',
  '',
  '',
  '',
  '',
  '',
];

export const TRACE_TIMELINESS_AVERAGE_COLUMN_MAPPINGS = [
  'Average Execution Time',
  '',
  '',
  '',
  '',
  '',
];

export const TRACE_TIMELINESS_EXECUTION_COLUMN_MAPPINGS = [
  'Percent of Matched ATS Trades',
  'Percent of Matched Non-ATS Trades',
  'Percent of All Matched Trades',
  '',
  '',
  '',
  '',
  '',
];
