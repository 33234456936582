import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { FinraInfoModalComponent } from '../components/fragments/finra-info-modal/finra-info-modal.component';
import { FinraInfoModalData } from '../configs/model/finra-info-modal.model';

/**
 * This service is for creating helper methods for using UI features from
 * angular material such as modal, snackbar, etc.
 * Should not depend on any other service except those
 * from the angular material library.
*/

@Injectable({
  providedIn: 'root'
})
export class UiWidgetsService {
  constructor(
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  showInfoModal(
    params: FinraInfoModalData,
    config: { [key: string]: any } = {}
  ) {
    return this.dialog.open(FinraInfoModalComponent, {
      width: '300px',
      height: '200px',
      ...config, // over-write the above defaults
      data: params
    });
  }

  showSnackbar(message: string, panelClass?: string[], duration?: number) {
    // Angular material snackbar to show a quick message for 1.2 seconds
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.duration = duration || 3000;
    config.panelClass = panelClass || ['snackbar-success'];
    return this._snackBar.open(message, '', config);
  }

  showErrorSnackbar(message: string) {
    return this.showSnackbar(message, ['snackbar-error']);
  }

  showSuccessSnackbar(message: string) {
    return this.showSnackbar(message, ['snackbar-success']);
  }

  showInfoSnackbar(message: string) {
    return this.showSnackbar(message, ['snackbar-info']);
  }
}
