<div id="consumer-complaint-report-container">
  <report-products-problems-grid
    class="pdf"
    [quartersHeadings]="quartersHeadings"
    [rowData]="rowData"
    [totalsObj]="totalsObj"
    [allOtherComplaintsObj]="allOtherComplaintsObj"
    [viewName]="viewName"
    [reportInstanceMetadata]="reportInstanceMetadata"
  ></report-products-problems-grid>

  <report-industry-products-problems-grid
    class="pdf"
    [quartersHeadings]="quartersHeadings"
    [rowData]="industryComplaintsRowData"
    [viewName]="viewName"
    [reportInstanceMetadata]="reportInstanceMetadata"
  ></report-industry-products-problems-grid>

  <!-- Complaints per Associated Individual -->
  <section
    class="consumer-complaint-container pdf"
    *ngIf="!!complaintsAsscIndv"
  >
    <div class="scroll-x-box">
      <div class="table-header">
        <div class="table-title">
          Complaints per Associated Individual
        </div>

        <span class="question-icon-container" (click)="openHelp()">
          <fa-icon
            data-html2canvas-ignore
            [icon]="faQuestionCircle"
            class="fa-lg question-icon"
          ></fa-icon>
          <span>Help</span>
        </span>
      </div>

      <div class="row-header row-container grey-border-bottom-1">
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1"></div>
        </div>

        <div class="col col-80">
          <div class="row-container col-flex-grow grey-border-bottom-1">
            <div
              class="col col-15"
              *ngFor="let heading of quartersHeadings; last as isLastHeading; index as headingIndex"
              [ngClass]="{
                'grey-border-right-1': !isLastHeading
              }"
            >
              <div class="box-padding-1">{{ heading }}</div>
            </div>
          </div>

          <div class="row-container col-flex-grow">
            <ng-container *ngFor="let heading of quartersHeadings; last as isLastHeading; index as headingIndex">
              <div class="col col-5 grey-border-right-1 wrap-text">
                <div class="box-padding-1">Associated Individual Count</div>
              </div>
              <div class="col col-5 grey-border-right-1">
                <div class="box-padding-1">Firm</div>
              </div>
              <div class="col col-5"
                [ngClass]="{
                  'grey-border-right-1': !isLastHeading
                }"
              >
                <div class="box-padding-1">Industry</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row-header row-container">
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1"></div>
        </div>

        <div class="col col-80">
          <div class="row-container row-body col-flex-grow">
            <ng-container *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex">
              <div class="row-container col col-15" [ngSwitch]="!!complaintsAsscIndv['quarter' + (quarterIndex + 1)]">
                <ng-container *ngSwitchCase="true">
                  <div class="col col-5 grey-border-right-1">
                    <div class="box-padding-1">
                      {{ complaintsAsscIndv['quarter' + (quarterIndex + 1)].totalCount | finraValidValue : 'number' }}
                    </div>
                  </div>
                  <div class="col col-5 grey-border-right-1">
                    <div class="box-padding-1">
                      {{ complaintsAsscIndv['quarter' + (quarterIndex + 1)].firmPercentage | finraValidValue : 'percent' }}
                    </div>
                  </div>
                  <div class="col col-5"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">
                      {{ complaintsAsscIndv['quarter' + (quarterIndex + 1)].industryPercentage | finraValidValue : 'percent' }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div class="col col-15"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">No information available for the period.</div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Complaints Identified as Related to Representative -->
  <section
    class="consumer-complaint-container pdf"
    *ngIf="!!complaintsRepsRelated"
  >
    <div class="scroll-x-box">
      <div class="table-header">
        <div class="table-title">
          Complaints Identified as Related to Representative
        </div>

        <span class="question-icon-container" (click)="openHelp()">
          <fa-icon
            data-html2canvas-ignore
            [icon]="faQuestionCircle"
            class="fa-lg question-icon"
          ></fa-icon>
          <span>Help</span>
        </span>
      </div>
      <div class="row-header row-container grey-border-bottom-1">
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1"></div>
        </div>

        <div class="col col-80">
          <div class="row-container col-flex-grow grey-border-bottom-1">
            <div
              class="col col-15"
              *ngFor="let heading of quartersHeadings; last as isLastHeading; index as headingIndex"
              [ngClass]="{
                'grey-border-right-1': !isLastHeading
              }"
            >
              <div class="box-padding-1">{{ heading }}</div>
            </div>
          </div>

          <div class="row-container col-flex-grow">
            <ng-container *ngFor="let heading of quartersHeadings; last as isLastHeading; index as headingIndex">
              <div class="col col-5 grey-border-right-1 wrap-text">
                <div class="box-padding-1">Complaint Count</div>
              </div>
              <div class="col col-5 grey-border-right-1">
                <div class="box-padding-1">Firm %</div>
              </div>
              <div class="col col-5"
                [ngClass]="{
                  'grey-border-right-1': !isLastHeading
                }"
              >
                <div class="box-padding-1">Industry %</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row-header row-container">
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1"></div>
        </div>

        <div class="col col-80">
          <div class="row-container row-body col-flex-grow">
            <ng-container *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex">
              <div class="row-container col col-15" [ngSwitch]="!!complaintsRepsRelated['quarter' + (quarterIndex + 1)]">
                <ng-container *ngSwitchCase="true">
                  <div class="col col-5 grey-border-right-1">
                    <div class="box-padding-1">
                      {{ complaintsRepsRelated['quarter' + (quarterIndex + 1)].totalCount | finraValidValue : 'number' }}
                    </div>
                  </div>
                  <div class="col col-5 grey-border-right-1">
                    <div class="box-padding-1">
                      {{ complaintsRepsRelated['quarter' + (quarterIndex + 1)].firmPercentage | finraValidValue : 'percent' }}
                    </div>
                  </div>
                  <div class="col col-5"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">
                      {{ complaintsRepsRelated['quarter' + (quarterIndex + 1)].industryPercentage | finraValidValue : 'percent' }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div class="col col-15"
                    [ngClass]="{
                      'grey-border-right-1': !isLastQuarter
                    }"
                  >
                    <div class="box-padding-1">No information available for the period.</div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
