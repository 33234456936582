export const configdev = {
  user: '',
  password: ''
};


/**
 * ISSO Test Account For Development With Local Backend
 * ---
 * Normally, the front end when developing locally points to the backend
 * deployed on another domain (https://api-isso.ddwa.dev.finra.org for example).
 *
 * If you have the back end codebase on your local machine and want the front end to make calls to it
 * for development, fill in this object with your credentials.
 * Make sure that the local backend is running and that the environment.ddwaBaseUrl
 * is pointing to it.
 *
 * for the `memberOf` property, more about it is found here:
 * - https://wiki.finra.org/display/DDWA/ISSO+Security+Setup
 */
export const localUserTestAccount = {
  isEws: false,

  // required
  username: `admin`, // your finra k account
  password: ``, // your finra password
  memberOf: `CN=ISSO_D_DDWA_READONLY,CN=ISSO_D_DDWA_ADMIN,CN=ISSO_D_DDWA_APIUSER,CN=ISSO_Q_DDWA_READONLY,CN=ISSO_Q_DDWA_ADMIN,CN=ISSO_Q_DDWA_APIUSER`, // the memberOf roles
  userPrincipalName: `admin@corp.root.nasd.com`, // your finra email should work
  ctemail: ``, // your finra email
  ctremoteuser: `rc.admin@finra.org`, // remote user name; example: rcsrotest
  ctfirstname: `Finra`, // your first name
  ctlastname: `Admin`, // your last name
  ewsRoles: [],
  headers: [],
};
