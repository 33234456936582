// import { environment } from 'src/environments/environment';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TimeoutWarningComponent } from 'src/app/components/fragments/timeout-warning/timeout-warning.component';

@Injectable({
  providedIn: 'root'
})
export class EwsService {
  private readonly SLEEP_ONE_MINUTE = 1 * 60 * 1000;
  private readonly TIMEOUT_ALERT = 4 * 60 * 1000;

  private ewsExpirationTimeUrl: string;
  private ewsLoginUrl: string;
  private ewsLogoutUrl: string;
  private baseUrl: string;
  private trackingTimeout: boolean;

  constructor(
    @Inject('environment')
    environment,
    private http: HttpClient
  ) {
    this.ewsExpirationTimeUrl = environment.ewsExpirationTimeUrl;
    this.ewsLoginUrl = environment.ewsLoginUrl;
    this.ewsLogoutUrl = environment.ewsLogoutUrl;
    this.baseUrl = environment.ddwaBaseUrl;
  }

  checkEwsSession(fn: (data) => void = null, err: (e) => void = null) {
    this.http.jsonp(this.ewsExpirationTimeUrl, 'callback').subscribe(
      data => {
        if (fn != null) {
          fn(data);
        }
      },
      e => {
        if (err != null) {
          err(e);
        }
      }
    );
  }

  async assertEwsSession() {
    return await new Promise((resolve, reject) => {
      this.checkEwsSession(
        data => {
          if (data['sessionExists'] === 'N') {
            this.redirectToEwsLogin();
            resolve(true);
          }
        },
        e => {
          reject(false);
        }
      );
    });
  }

  redirectToEwsLogin() {
    window.location.href =
      this.ewsLoginUrl + '&CT_ORIG_URL=' + window.location.href;
  }

  startTrackingTimeout(
    logoutAction: () => Promise<any>,
    timeoutAction: (
      durationMillis,
      logoutAction: () => Promise<any>,
      continueAction: () => Promise<any>
    ) => void
  ) {
    if (!this.trackingTimeout) {
      this.trackingTimeout = true;
      this.trackTimeout(logoutAction, timeoutAction);
    }
  }

  private trackTimeout(
    logoutAction: () => Promise<any>,
    timeoutAction: (
      durationMillis,
      logoutAction: () => Promise<any>,
      continueAction: () => Promise<any>
    ) => void
  ) {
    const fn = sleep => {
      setTimeout(() => {
        this.trackTimeout(logoutAction, timeoutAction);
      }, sleep);
    };

    this.checkEwsSession(data => {
      const showAuthTimeoutWarning =
        data['sessionExists'] === 'Y' &&
        data['expiresInMilliSeconds'] < this.TIMEOUT_ALERT;

      if (showAuthTimeoutWarning) {
        timeoutAction(data['expiresInMilliSeconds'], logoutAction, async () => {
          this.keepAlive();
          fn(this.SLEEP_ONE_MINUTE);
        });
      } else {
        fn(data['expiresInMilliSeconds'] - this.TIMEOUT_ALERT);
      }
    });
  }

  private keepAlive() {
    const timeStamp = Math.ceil(Math.random() * 1000000);
    const url = `${this.baseUrl}userProfile?t=${timeStamp}`;
    this.http.get(url).subscribe(
      () => {},
      e => {}
    );
  }

  logout() {
    window.location.href = this.ewsLogoutUrl;
  }
}
