import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserNotificationResponse } from 'src/app/configs/model/notifications.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('environment') environment
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getUserNotifications(): Observable<UserNotificationResponse> {
    return this.http.get<UserNotificationResponse>(`${this.baseUrl}userNotifications/`);
  }

  updateUserNotifications(
    data: UserNotificationResponse
  ): Observable<UserNotificationResponse> {
    return this.http.put<UserNotificationResponse>(`${this.baseUrl}userNotifications/`, data);
  }
}
