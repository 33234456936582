
export enum ReportCategoryNames {
  CORP_FIN = 'Corp-Fin',
  CROSS_MARKET_SUPERVISION = 'Cross Market Supervision',
  DISCLOSURE = 'Disclosure',
  EQUITY = 'Equity',
  FQS_DQS = 'File and Data Quality Scorecard',
  MSRB = 'MSRB',
  ONLINE_LEARNING = 'Online Learning',
  OPTIONS = 'Options',
  RISK_MONITORING = 'Risk Monitoring',
  TRACE = 'Trace',
}

export enum DqsFqsNames {
  TYPE_DQS = 'DQS',
  TYPE_FQS = 'FQS'
}

export interface Option {
  value: string;
  label: string;
}

export enum ReportStateEnum {
  Published = 'Published',
  Unpublished = 'Unpublished'
}

export const PublishedOptions: Array<Option> = [
  { value: ReportStateEnum.Published, label: 'Published' },
  { value: ReportStateEnum.Unpublished, label: 'Unpublished' }
];

export interface CardFilterArguments {
  categoryId?: string;
  firmId: string;
  state: string;
}

export interface ReportsDailyReports {
  oatsprocessingdat: Date;
  firmforeid: string;
  numlaterecords: number;
}

// Defining Grid Header
export interface ReportDailyTotalsHeader {
  headerName: string;
  field: string;
  width: number;
  filter?: string;
  filterParams?: object;
  cellStyle?: object;
}

export interface ReportInstanceMetadata {
  reportId: number;
  reportPeriodDate?: string;
  reportPeriodDateWithTimePart?: string;
  reportFirmId?: string;
  reportDataVersion?: number;
  sourceDataVersion?: number;
  reportConfigurationId?: number;
  reportStateId?: number;
  sourcePublished?: boolean;
  sourcePublishedDate?: string;
  ddwaPublishedDate?: string;
  reportConfiguration?: ReportConfiguration;
  reportStateLookup?: ReportStateLookup;
  tierCode?: number;
}

export interface ReportInstanceMetadatasResponse {
  reportInstanceMetadatas: ReportInstanceMetadata[];
}

export interface ReportCategory {
  categoryDisplayName: string;
  categoryDisplayOrder: number;
  categoryName: string;
  reportCategoryId: number;
  userAccessible: boolean;
  activeIndicator: boolean;
}

export interface ReportCategoriesResponse {
  reportCategories: ReportCategory[];
}
export interface ReportType {
  firmIdTypeId: number;
  reportDeleted: boolean;
  reportCategoryId: number;
  reportTypeId: number;
  reportTypeName: string;
  reportTypeShortName: string;
  reportPeriodicityId: number;
  userAccessible: boolean;
}

export interface ReportConfiguration {
  effectiveDate: string;
  expirationDate: string;
  otpArmed: boolean;
  reportCardTemplate: string;
  reportConfigurationId: number;
  reportConfigurationViews: Array<ReportConfigurationView>;
  reportDisplayName: string;
  reportEdition: string;
  reportTypeId: number;
  reportType: ReportType;
  rptDesc?: string;
}

export interface ReportConfigurationView {
  detailDatasetConfigurationId: number;
  landingPage: number;
  partOfNavigation: number;
  periodListSize: number;
  periodNumber: number;
  rawDataValue: string;
  reportConfigurationId: number;
  reportConfigurationViewId: number;
  summaryDatasetConfigurationId: number;
  viewFile: string;
  viewName: string;
  viewShortName: string;
  viewTemplateConfiguration: string;
  // optionals
  dataRetrievalStrategy?: string;
  detailFields?: string;
  detailSortFields?: string;
  summaryFields?: string;
  summarySortFields?: string;
}

export interface ReportStateLookup {
  reportStateId: number;
  reportStateCode: number;
  reportStateDescription: string;
}

export interface Reports {
  reportId: number;
  reportPeriodDate: Date;
  reportFirmId: string;
  reportDataVersion: number;
  reportConfiguration: Array<ReportConfiguration>;
  reportStateLookup: Array<ReportStateLookup>;
}

export let ReportDailyTotalsGridHeader: Array<ReportDailyTotalsHeader> = [
  {
    headerName: 'OATS Processing Dat',
    field: 'oatsprocessingdat',
    width: 140
  },
  {
    headerName: 'FIRM FORE ID',
    field: 'firmforeid',
    width: 130
  },
  {
    headerName: 'No. of late records in File',
    field: 'numlaterecords',
    width: 100,
    cellStyle: {
      'text-align': 'right',
      'padding-right': '6%'
    }
  }
];

export interface Periodicities {
  periodicityId: number;
  periodicityName: string;
}
