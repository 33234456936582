<div id="lopr-container">
  <report-loader></report-loader>

  <h1 class="title pdf">{{ title }}</h1>

  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="loprToolbarConfig"
    [toolbarConfig]="loprToolbarConfig"
  ></report-finra-toolbar>

  <div id="data-container" *ngIf="reportLoadedSuccessfully">
    <ng-container *ngIf="hasChart && loprChartRowData">
      <section class="pdf chart-space">
        <report-finra-chart
          [template]="'line-chart'"
          [legendLabel]="'Legend'"
          [legendPosition]="'right'"
          [showLegend]="true"
          [autoScale]="true"
          [rowData]="loprChartRowData"
          [yAxisTickFormatting]="Y_AXIS_FORMATTING"
        ></report-finra-chart>
      </section>
    </ng-container>

    <div
      class="table-container table-{{ reportName }} pdf"
      *ngFor="let tableName of tableNamesByOrder"
    >
      <div class="table-header" *ngIf="tableTitles[tableName]">
        {{ tableTitles[tableName] }}
      </div>
      <report-finra-grid
        #reportfinragrid
        id="id"
        [columnDefs]="columnDefs[tableName]"
        [rowData]="rowData[tableName]"
      ></report-finra-grid>
    </div>
  </div>

  <hr />

  <div
    class="report-main-content-padding report-disclaimer pdf"
    *ngIf="footerMessage"
    [innerHtml]="footerMessage"
  ></div>
</div>
