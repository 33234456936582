<mat-toolbar class="mat-elevation-z0">
  <div
    fxFlex
    fxLayout="row"
    fxLayoutGap="20px"
    fxLayoutAlign="start center"
    fxFlexFill
  >
    <span class="mat-headline">Report Center</span>

    <div fxLayoutAlign="center center" fxFlex fxFill></div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px">
      <!-- <report-finder></report-finder> -->
      <div class="icons-box">
        <fa-icon
          data-html2canvas-ignore
          title="Some detail datasets are now processing"
          [icon]="faHourglass"
          class="fa-lg fa-detail-dataset-state-icon fa-processing"
          *ngIf="requestsProcessing"
        ></fa-icon>

        <fa-icon
          data-html2canvas-ignore
          title="Some detail datasets are now ready for download"
          [icon]="faBell"
          class="fa-lg fa-detail-dataset-state-icon fa-ready"
          *ngIf="readyState"
        ></fa-icon>

        <fa-icon
          data-html2canvas-ignore
          title="Some detail datasets failed to be generated"
          [icon]="faExclamationTriangle"
          class="fa-lg fa-detail-dataset-state-icon fa-error"
          *ngIf="errorState"
        ></fa-icon>

        <fa-icon
          data-html2canvas-ignore
          title="Some detail datasets had no data"
          [icon]="faFileTimes"
          class="fa-lg fa-detail-dataset-state-icon fa-none"
          *ngIf="noDataState"
        ></fa-icon>
      </div>
      <div>
        <report-user-account
          data-automation-id="report-nav-profile-menu-btn"
          (logout)="logout($event)"
        ></report-user-account>
      </div>
    </div>
  </div>
</mat-toolbar>
