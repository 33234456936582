import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UiWidgetsService } from "src/app/services/ui-widgets.service";
import { DmClientService } from "src/app/services/dm-client.service";
import { HttpErrorResponse } from "@angular/common/http";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faUpload, faTimes, faTrash, faCheck } from "@fortawesome/pro-light-svg-icons";
import { finalize } from "rxjs/operators";



const accepted_file_formats_regex_by_object_name: { [key:string]: RegExp } = {
  CRD_LATE_FILING_FEE: /^CrdLateFilingFee_([\d]{4})_([\d]{2}).csv$/,
  CRD_BD_TIMELINESS_DETAIL: /^FormBDTimelinessDetail_([\d]{4})_([\d]{2}).csv$/,
  CRD_BD_TIMELINESS_SUMMARY: /^FormBDTimelinessSummary_([\d]{4})_([\d]{2}).csv$/,
};

const accepted_file_formats_object_names = Object.keys(accepted_file_formats_regex_by_object_name);

interface SelectedFileInfo {
  file: File,
  dm_object_name: string,
  partition: string,
  isNewDataVersion: boolean,
  objectExists: null | boolean,
  loading: boolean,
  uploadingResult: null | boolean,
}



@Component({
  selector: 'report-upload-business-object-page',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadBusinessObjectPageComponent implements OnInit {
  @ViewChild('dropFilesZoneInputElm') dropFilesZoneInputElm: ElementRef<HTMLInputElement>;

  faUpload: IconDefinition = faUpload;
  faTimes: IconDefinition = faTimes;
  faTrash: IconDefinition = faTrash;
  faCheck: IconDefinition = faCheck;

  hasAuthorization: boolean | null = null;
  isNewDataVersion: boolean = true;
  selectedFileBytes: Uint8Array | null = null;
  objectExists: boolean | null = null;
  csvParseResults: any;
  isShowingCsvData: boolean = false;
  isLoading = false;
  selectedFiles: SelectedFileInfo[] = [];
  isDragging = false;

  accepted_file_formats_object_name_examples = [
    { objectName: `CRD_LATE_FILING_FEE`, reportName: `Web Crd Late Filing Fee Report`, format: `CrdLateFilingFee_{year}_{month}.csv`, example: 'CrdLateFilingFee_2022_12.csv' },
    { objectName: `CRD_BD_TIMELINESS_SUMMARY`, reportName: `Web Form BD Timeliness Report - Summary`, format: `FormBDTimelinessSummary_{year}_{month}.csv`, example: 'FormBDTimelinessSummary_2022_12.csv' },
    { objectName: `CRD_BD_TIMELINESS_DETAIL`, reportName: `Web Form BD Timeliness Report - Detail`, format: `FormBDTimelinessDetail_{year}_{month}.csv`, example: 'FormBDTimelinessDetail_2022_12.csv' },
  ];


  get canUpload(): boolean {
    const noneIsLoading = this.selectedFiles.every(s => !s.loading);
    const allAreNewDataVersions = this.selectedFiles.every(s => s.isNewDataVersion || (!s.isNewDataVersion && !s.objectExists));
    const isValid = noneIsLoading && allAreNewDataVersions && !this.isLoading;
    return isValid;
  }

  constructor(
    private uiWidgetsService: UiWidgetsService,
    private dmClientService: DmClientService,
  ) {}

  ngOnInit(): void {
    this.dmClientService.userHasPermissionToUse().subscribe({
      next: (response) => {
        this.hasAuthorization = response.hasAuthorization;
      }
    });
  }

  onDragOver(event, dropFilesZoneElm) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    this.isDragging = true;
  }
  onDragLeave(event, dropFilesZoneElm) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragging = false;
  }

  dropHandler(event) {
    event.stopPropagation();
    event.preventDefault();
    console.log(`drop:`, event);
    this.isDragging = false;

    const files = event.dataTransfer.files;
    for (const file of files) {
      this.handleFileSelection(file);
    }
  }

  onFilesSelected(event) {
    event.stopPropagation();
    event.preventDefault();

    console.log({ event });

    const files = event.target.files;
    for (const file of files) {
      this.handleFileSelection(file);
    }
  }

  handleFileSelection(file: File) {
    if (file.type !== `text/csv`) {
      this.uiWidgetsService.showErrorSnackbar(`${file.name} is not a valid file. Please select a CSV file.`);
      return;
    }

    const dm_object_name = accepted_file_formats_object_names.find((name) => {
      const regex: RegExp = accepted_file_formats_regex_by_object_name[name];
      const match: boolean = regex.test(file.name);
      return match;
    });

    if (!dm_object_name) {
      this.uiWidgetsService.showErrorSnackbar(`${file.name} is not in a valid format. See the table above for examples of accepted files and format.`);
      return;
    }

    const alreadySelectedFile = this.selectedFiles.some(f => f.file.name === file.name);
    if (alreadySelectedFile) {
      this.uiWidgetsService.showErrorSnackbar(`${file.name} has already been selected.`);
      return;
    }

    const matcher = file.name.match(accepted_file_formats_regex_by_object_name[dm_object_name]);
    const partition = `${matcher[1]}-${matcher[2]}-01`;

    const selectedFileInfo: SelectedFileInfo = {
      file,
      dm_object_name,
      partition,
      isNewDataVersion: true,
      objectExists: null,
      loading: true,
      uploadingResult: null,
    };
    this.selectedFiles.push(selectedFileInfo);

    this.dmClientService.objectExists(dm_object_name, partition)
    .pipe(finalize(() => { selectedFileInfo.loading = false; }))
    .subscribe({
      next: (response) => {
        selectedFileInfo.objectExists = response.objectExists;
      }
    });
  }

  clearInput() {
    this.dropFilesZoneInputElm.nativeElement.value = '';
    this.selectedFiles = [];
  }

  uploadFiles() {
    this.isLoading = true;
    const useFiles = this.selectedFiles.filter((s) => {
      return s.isNewDataVersion || (!s.isNewDataVersion && !s.objectExists)
    });
    this.selectedFiles = useFiles;
    for (const selectedFileInfo of useFiles) {
      this.uploadFile(selectedFileInfo);
    }
  }

  uploadFile(selectedFileInfo: SelectedFileInfo) {
    const options = {
      file: selectedFileInfo.file,
      partitionValue: selectedFileInfo.partition,
      isNewDataVersion: this.isNewDataVersion,
    };

    const complete = () => {
      selectedFileInfo.loading = false;

      const index = this.selectedFiles.indexOf(selectedFileInfo);
      if (index) {
        this.selectedFiles.splice(index, 1);
      }
      const allAreDoneLoading = this.selectedFiles.length > 0 && this.selectedFiles.every(s => !s.loading);
      if (allAreDoneLoading) {
        this.isLoading = false;
        this.clearInput();
        this.uiWidgetsService.showInfoSnackbar(`Your request has been sent for processing`);
      }
  };

    selectedFileInfo.loading = true;
    this.dmClientService.registerAndUpload(options)
    .pipe(finalize(complete))
    .subscribe({
      next: (response) => {
        console.log(selectedFileInfo, response);
        selectedFileInfo.uploadingResult = true;
      },
      error: (error: HttpErrorResponse) => {
        console.log(selectedFileInfo, error);
        selectedFileInfo.uploadingResult = false;
      },
    });
  }
}
