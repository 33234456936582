import { RegisteredRepsMasterListElement } from 'src/app/components/pages/reports/summary/risk-monitoring/registered-reps-composition/registered-reps-composition.component';
import {
  addFinraGridColumnId,
  finraGridNumericValueFormatter,
  finraGridQuarterDateFormatter,
  finraGridPercentValueFormatter,
  SummaryExportData
} from 'src/app/shared/utils/utils';

/**
 * Interfaces for the `Registered Representative Composition` reports.
 * The property names match was is given from the backend.
 * Here is an example of an object from the backend:
 *
 * @example:
  {
    cmrCmplntDsclRepCfppPt: null,
    cmrCmplntDsclRepCt: 891,
    cmrCmplntDsclRepPt: 2.82,
    crdId: "79",
    crmnlDsclsrRepCfppPt: null,
    crmnlDsclsrRepCt: 482,
    crmnlDsclsrRepPt: 1.52,
    fnnclDsclsrRepCfppPt: null,
    fnnclDsclsrRepCt: 1845,
    fnnclDsclsrRepPt: 5.83,
    gtreq1DsclRepCfppPt: null,
    gtreq1DsclRepCt: 3371,
    gtreq1DsclRepPt: 10.65,
    gtreq3jbin2yrRepCfppPt: null,
    gtreq3jbin2yrRepCt: 5,
    gtreq3jbin2yrRepPt: 0.02,
    ndCmrCmplntDsclRepCfppPt: null,
    ndCmrCmplntDsclRepCt: 26587,
    ndCmrCmplntDsclRepPt: 4,
    ndCrmnlDsclsrRepCfppPt: null,
    ndCrmnlDsclsrRepCt: 13614,
    ndCrmnlDsclsrRepPt: 2.05,
    ndFnnclDsclsrRepCfppPt: null,
    ndFnnclDsclsrRepCt: 37207,
    ndFnnclDsclsrRepPt: 5.6,
    ndGtreq1DsclRepCfppPt: null,
    ndGtreq1DsclRepCt: 82712,
    ndGtreq1DsclRepPt: 12.46,
    ndGtreq3jbin2yrRepCfppPt: null,
    ndGtreq3jbin2yrRepCt: 4047,
    ndGtreq3jbin2yrRepPt: 0.61,
    ndNuRepCfppPt: null,
    ndNuRepCt: 51,
    ndNuRepPt: 0.01,
    ndRbtnCvlgnDsclRepCfppPt: null,
    ndRbtnCvlgnDsclRepCt: 13429,
    ndRbtnCvlgnDsclRepPt: 2.02,
    ndRepCfppPt: null,
    ndRepCt: 664026,
    ndSvrDscplndFrmRepCfppPt: null,
    ndSvrDscplndFrmRepCt: 5383,
    ndSvrDscplndFrmRepPt: 0.81,
    ndTrmntdRepCfppPt: null,
    ndTrmntdRepCt: 299,
    ndTrmntdRepPt: 0.05,
    ndXfrdRepCfppPt: null,
    ndXfrdRepCt: 336,
    ndXfrdRepPt: 0.05,
    nuRepCfppPt: null,
    nuRepCt: 0,
    nuRepPt: 0,
    orgNtrnlCntrlQtrId: 508298,
    prcsdDt: "07-01-2017",
    rbtnCvlgnDsclRepCfppPt: null,
    rbtnCvlgnDsclRepCt: 333,
    rbtnCvlgnDsclRepPt: 1.05,
    repCfppPt: null,
    repCt: 31641,
    rptId: 21280767,
    svrDscplndFrmRepCfppPt: null,
    svrDscplndFrmRepCt: 181,
    svrDscplndFrmRepPt: 0.57,
    trmntdRepCfppPt: null,
    trmntdRepPt: 0,
    xfrdRepCfppPt: null,
    xfrdRepCt: 0,
    xfrdRepPt: 0
  }

 * Here are mappings to better understand the property names:
 * @see
  suffix meaning
  -
  CfppPt = change from prior periodNumber
  Ct = count
  Pt = percent of total


  prefix
  --
  nu = new
  nd = industry
  ndNu = industry new
  rep = representative(s)

  xfrd = reps transferred to another firm
  gtreq3jbin2yrRepCt = registered with 3 or more firms in last 2 years
  trmntd = terminated
  gtreq1Dscl = with 1 or more disclosures
  cmrCmplntDscl = customer complaints
  rbtnCvlgnDscl = litigation
  crmnlDsclsr = criminal disclosures
  fnnclDsclsr = financial disclosures
  svrDscplndFrm = severely disciplined

 * This is a quarterly report.
 * - january starts the 1st quarter
 * - april starts the 2nd quarter
 * - july starts the 3rd quarter
 * - october starts the 4th quarter

 * These interfaces are for matching only the necessary properties from the object.
 */



export interface QuarterlyRegisteredRepsData {
  /** Report Info */

  crdId: string;
  orgNtrnlCntrlQtrId: number;
  prcsdDt: string; // processed date
  rptId: number;

  /** registered reps */

  // firm
  repCt: number;
  repCfppPt: number;

  // industry
  ndRepCt: number;
  ndRepCfppPt: number;

  /** new to industry reps */

  // firm
  nuRepCt: number;
  nuRepPt: number;
  nuRepCfppPt: number;

  // industry
  ndNuRepCt: number;
  ndNuRepPt: number;
  ndNuRepCfppPt: number;

  /** reps transferred from another firm */

  // firm
  xfrdRepCt: number;
  xfrdRepPt: number;
  xfrdRepCfppPt: number;

  // industry
  ndXfrdRepCt: number;
  ndXfrdRepPt: number;
  ndXfrdRepCfppPt: number;

  /** reps registered with 3 or more firms in the last 2 years */

  // firm
  gtreq3jbin2yrRepCt: number;
  gtreq3jbin2yrRepPt: number;
  gtreq3jbin2yrRepCfppPt: number;

  // industry
  ndGtreq3jbin2yrRepCt: number;
  ndGtreq3jbin2yrRepPt: number;
  ndGtreq3jbin2yrRepCfppPt: number;

  /** terminated reps */

  // firm
  trmntdRepCt: number;
  trmntdRepPt: number;
  trmntdRepCfppPt: number;

  // industry
  ndTrmntdRepCt: number;
  ndTrmntdRepPt: number;
  ndTrmntdRepCfppPt: number;

  /** reps with 1 or more disclosuress */

  // firm
  gtreq1DsclRepCt: number;
  gtreq1DsclRepPt: number;
  gtreq1DsclRepCfppPt: number;

  // industry
  ndGtreq1DsclRepCt: number;
  ndGtreq1DsclRepPt: number;
  ndGtreq1DsclRepCfppPt: number;

  /** reps with customer complaint disclosures */

  // firm
  cmrCmplntDsclRepCt: number;
  cmrCmplntDsclRepPt: number;
  cmrCmplntDsclRepCfppPt: number;


  // industry
  ndCmrCmplntDsclRepCt: number;
  ndCmrCmplntDsclRepPt: number;
  ndCmrCmplntDsclRepCfppPt: number;

  /** reps with arbitration / civil litigation disclusures */

  // firm
  rbtnCvlgnDsclRepCt: number;
  rbtnCvlgnDsclRepPt: number;
  rbtnCvlgnDsclRepCfppPt: number;

  // industry
  ndRbtnCvlgnDsclRepCt: number;
  ndRbtnCvlgnDsclRepPt: number;
  ndRbtnCvlgnDsclRepCfppPt: number;

  /** reps with criminal disclosures */

  // firm
  crmnlDsclsrRepCt: number;
  crmnlDsclsrRepPt: number;
  crmnlDsclsrRepCfppPt: number;

  // industry
  ndCrmnlDsclsrRepCt: number;
  ndCrmnlDsclsrRepPt: number;
  ndCrmnlDsclsrRepCfppPt: number;

  /** reps with financial disclosures */

  // firm
  fnnclDsclsrRepCt: number;
  fnnclDsclsrRepPt: number;
  fnnclDsclsrRepCfppPt: number;

  // industry
  ndFnnclDsclsrRepCt: number;
  ndFnnclDsclsrRepPt: number;
  ndFnnclDsclsrRepCfppPt: number;

  /** reps ever previously with a severely disciplined firm (list) */

  // firm
  svrDscplndFrmRepCt: number;
  svrDscplndFrmRepPt: number;
  svrDscplndFrmRepCfppPt: number;

  // industry
  ndSvrDscplndFrmRepCt: number;
  ndSvrDscplndFrmRepPt: number;
  ndSvrDscplndFrmRepCfppPt: number;
}

export enum RegisteredRepsTypes {
  ALL_REPS = 1,
  NEW_REPS,
  FIRM_TRANSFERRED,
  MULTI_FIRM,
  TERMINATED,
  DISCLOSURE,
  CUSTOMER_COMPLAINT,
  ARBITRATION_LITIGATION,
  CRIMINAL_DISCLOSURE,
  FINANCIAL_DISCLOSURE,
  PRIOR_DISCIPLINED,
}

export interface RepsInfo {
  orgNtrnlCntrlQtrId: number;
  reportId: number;
  crdId: string;
  processDate: string;
  quarter?: string; // the string to display
}

export interface AllRepsQuarter extends RepsInfo {
  firm: {
    totalCount: number;
    percentageChangeFromPrior: number;
  };
  industry: {
    totalCount: number;
    percentageChangeFromPrior: number;
  };
  registeredRepType: RegisteredRepsTypes.ALL_REPS;
}

export interface SubsectionRepsQuarter extends RepsInfo {
  firm: {
    totalCount: number;
    percentageOfReps: number;
    percentageChangeFromPrior: number;
  };
  industry: {
    totalCount: number;
    percentageOfReps: number;
    percentageChangeFromPrior: number;
  };
}

export interface NewToIndustryRepsQuarter extends SubsectionRepsQuarter {
  // reps transferred from another firm
  registeredRepType: RegisteredRepsTypes.NEW_REPS;
}

export interface FirmTransferredRepsQuarter extends SubsectionRepsQuarter {
  // reps transferred from another firm
  registeredRepType: RegisteredRepsTypes.FIRM_TRANSFERRED;
}

export interface MultiFirmRepsQuarter extends SubsectionRepsQuarter {
  // reps registered with 3 or more firms in the last 2 years
  registeredRepType: RegisteredRepsTypes.MULTI_FIRM;
}

export interface TerminatedRepsQuarter extends SubsectionRepsQuarter {
  // terminated reps
  registeredRepType: RegisteredRepsTypes.TERMINATED;
}

export interface DisclosuresRepsQuarter extends SubsectionRepsQuarter {
  // reps with 1 or more disclosures
  registeredRepType: RegisteredRepsTypes.DISCLOSURE;
}

export interface CustomerComplaintsRepsQuarter extends SubsectionRepsQuarter {
  // reps with customer complaint disclosures
  registeredRepType: RegisteredRepsTypes.CUSTOMER_COMPLAINT;
}

export interface ArbitrationLitigationRepsQuarter extends SubsectionRepsQuarter {
  // reps with arbitration / civil litigation disclusures
  registeredRepType: RegisteredRepsTypes.ARBITRATION_LITIGATION;
}

export interface CriminalDisclosureRepsQuarter extends SubsectionRepsQuarter {
  // reps with criminal disclosures
  registeredRepType: RegisteredRepsTypes.CRIMINAL_DISCLOSURE;
}

export interface FinancialDisclosureRepsQuarter extends SubsectionRepsQuarter {
  // reps with financial disclosures
  registeredRepType: RegisteredRepsTypes.FINANCIAL_DISCLOSURE;
}

export interface PriorDisciplinedRepsQuarter extends SubsectionRepsQuarter {
  // reps ever previously with a severely disciplined firm (list)
  registeredRepType: RegisteredRepsTypes.PRIOR_DISCIPLINED;
}


// Union Types

export type RegisteredRepsUnion =
  AllRepsQuarter |
  NewToIndustryRepsQuarter |
  FirmTransferredRepsQuarter |
  MultiFirmRepsQuarter |
  TerminatedRepsQuarter |
  DisclosuresRepsQuarter |
  CustomerComplaintsRepsQuarter |
  ArbitrationLitigationRepsQuarter |
  CriminalDisclosureRepsQuarter |
  FinancialDisclosureRepsQuarter |
  PriorDisciplinedRepsQuarter;

export type RegisteredRepsListUnion =
  AllRepsQuarter[] |
  NewToIndustryRepsQuarter[] |
  FirmTransferredRepsQuarter[] |
  MultiFirmRepsQuarter[] |
  TerminatedRepsQuarter[] |
  DisclosuresRepsQuarter[] |
  CustomerComplaintsRepsQuarter[] |
  ArbitrationLitigationRepsQuarter[] |
  CriminalDisclosureRepsQuarter[] |
  FinancialDisclosureRepsQuarter[] |
  PriorDisciplinedRepsQuarter[];


// transform raw data

export function parseQuartlyRegisteredRepsSummary(quarterData: QuarterlyRegisteredRepsData): [
  AllRepsQuarter,
  NewToIndustryRepsQuarter,
  FirmTransferredRepsQuarter,
  MultiFirmRepsQuarter,
  TerminatedRepsQuarter,
  DisclosuresRepsQuarter,
  CustomerComplaintsRepsQuarter,
  ArbitrationLitigationRepsQuarter,
  CriminalDisclosureRepsQuarter,
  FinancialDisclosureRepsQuarter,
  PriorDisciplinedRepsQuarter
] {
  const allRepsQuarter: AllRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.repCt,
      percentageChangeFromPrior: quarterData.repCfppPt,
    },
    industry: {
      totalCount: quarterData.ndRepCt,
      percentageChangeFromPrior: quarterData.ndRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.ALL_REPS
  };

  const newRepsQuarter: NewToIndustryRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.nuRepCt,
      percentageOfReps: quarterData.nuRepPt,
      percentageChangeFromPrior: quarterData.nuRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndNuRepCt,
      percentageOfReps: quarterData.ndNuRepPt,
      percentageChangeFromPrior: quarterData.ndNuRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.NEW_REPS
  };

  const firmTransferredRepsQuarter: FirmTransferredRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.xfrdRepCt,
      percentageOfReps: quarterData.xfrdRepPt,
      percentageChangeFromPrior: quarterData.xfrdRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndXfrdRepCt,
      percentageOfReps: quarterData.ndXfrdRepPt,
      percentageChangeFromPrior: quarterData.ndXfrdRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.FIRM_TRANSFERRED
  };

  const multiFirmRepsQuarter: MultiFirmRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.gtreq3jbin2yrRepCt,
      percentageOfReps: quarterData.gtreq3jbin2yrRepPt,
      percentageChangeFromPrior: quarterData.gtreq3jbin2yrRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndGtreq3jbin2yrRepCt,
      percentageOfReps: quarterData.ndGtreq3jbin2yrRepPt,
      percentageChangeFromPrior: quarterData.ndGtreq3jbin2yrRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.MULTI_FIRM
  };

  const terminatedRepsQuarter: TerminatedRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.trmntdRepCt,
      percentageOfReps: quarterData.trmntdRepPt,
      percentageChangeFromPrior: quarterData.trmntdRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndTrmntdRepCt,
      percentageOfReps: quarterData.ndTrmntdRepPt,
      percentageChangeFromPrior: quarterData.ndTrmntdRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.TERMINATED
  };

  const disclosuresRepsQuarter: DisclosuresRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.gtreq1DsclRepCt,
      percentageOfReps: quarterData.gtreq1DsclRepPt,
      percentageChangeFromPrior: quarterData.gtreq1DsclRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndGtreq1DsclRepCt,
      percentageOfReps: quarterData.ndGtreq1DsclRepPt,
      percentageChangeFromPrior: quarterData.ndGtreq1DsclRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.DISCLOSURE
  };

  const customerComplaintsRepsQuarter: CustomerComplaintsRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.cmrCmplntDsclRepCt,
      percentageOfReps: quarterData.cmrCmplntDsclRepPt,
      percentageChangeFromPrior: quarterData.cmrCmplntDsclRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndCmrCmplntDsclRepCt,
      percentageOfReps: quarterData.ndCmrCmplntDsclRepPt,
      percentageChangeFromPrior: quarterData.ndCmrCmplntDsclRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.CUSTOMER_COMPLAINT
  };

  const arbitrationLitigationRepsQuarter: ArbitrationLitigationRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.rbtnCvlgnDsclRepCt,
      percentageOfReps: quarterData.rbtnCvlgnDsclRepPt,
      percentageChangeFromPrior: quarterData.rbtnCvlgnDsclRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndRbtnCvlgnDsclRepCt,
      percentageOfReps: quarterData.ndRbtnCvlgnDsclRepPt,
      percentageChangeFromPrior: quarterData.ndRbtnCvlgnDsclRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.ARBITRATION_LITIGATION
  };

  const criminalDisclosureRepsQuarter: CriminalDisclosureRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.crmnlDsclsrRepCt,
      percentageOfReps: quarterData.crmnlDsclsrRepPt,
      percentageChangeFromPrior: quarterData.crmnlDsclsrRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndCrmnlDsclsrRepCt,
      percentageOfReps: quarterData.ndCrmnlDsclsrRepPt,
      percentageChangeFromPrior: quarterData.ndCrmnlDsclsrRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.CRIMINAL_DISCLOSURE
  };

  const financialDisclosureRepsQuarter: FinancialDisclosureRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.fnnclDsclsrRepCt,
      percentageOfReps: quarterData.fnnclDsclsrRepPt,
      percentageChangeFromPrior: quarterData.fnnclDsclsrRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndFnnclDsclsrRepCt,
      percentageOfReps: quarterData.ndFnnclDsclsrRepPt,
      percentageChangeFromPrior: quarterData.ndFnnclDsclsrRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.FINANCIAL_DISCLOSURE
  };

  const priorDisciplinedRepsQuarter: PriorDisciplinedRepsQuarter = {
    orgNtrnlCntrlQtrId: quarterData.orgNtrnlCntrlQtrId,
    reportId: quarterData.rptId,
    crdId: quarterData.crdId,
    processDate: quarterData.prcsdDt,
    firm: {
      totalCount: quarterData.svrDscplndFrmRepCt,
      percentageOfReps: quarterData.svrDscplndFrmRepPt,
      percentageChangeFromPrior: quarterData.svrDscplndFrmRepCfppPt,
    },
    industry: {
      totalCount: quarterData.ndSvrDscplndFrmRepCt,
      percentageOfReps: quarterData.ndSvrDscplndFrmRepPt,
      percentageChangeFromPrior: quarterData.ndSvrDscplndFrmRepCfppPt,
    },
    registeredRepType: RegisteredRepsTypes.PRIOR_DISCIPLINED
  };

  return [
    allRepsQuarter,
    newRepsQuarter,
    firmTransferredRepsQuarter,
    multiFirmRepsQuarter,
    terminatedRepsQuarter,
    disclosuresRepsQuarter,
    customerComplaintsRepsQuarter,
    arbitrationLitigationRepsQuarter,
    criminalDisclosureRepsQuarter,
    financialDisclosureRepsQuarter,
    priorDisciplinedRepsQuarter
  ];
}

export function createEmptyQuarterlyRegisteredRepsObj(prcsdDt: string) {
  return {
    cmrCmplntDsclRepCfppPt: null,
    cmrCmplntDsclRepCt: null,
    cmrCmplntDsclRepPt: null,
    crdId: null,
    crmnlDsclsrRepCfppPt: null,
    crmnlDsclsrRepCt: null,
    crmnlDsclsrRepPt: null,
    fnnclDsclsrRepCfppPt: null,
    fnnclDsclsrRepCt: null,
    fnnclDsclsrRepPt: null,
    gtreq1DsclRepCfppPt: null,
    gtreq1DsclRepCt: null,
    gtreq1DsclRepPt: null,
    gtreq3jbin2yrRepCfppPt: null,
    gtreq3jbin2yrRepCt: null,
    gtreq3jbin2yrRepPt: null,
    ndCmrCmplntDsclRepCfppPt: null,
    ndCmrCmplntDsclRepCt: null,
    ndCmrCmplntDsclRepPt: null,
    ndCrmnlDsclsrRepCfppPt: null,
    ndCrmnlDsclsrRepCt: null,
    ndCrmnlDsclsrRepPt: null,
    ndFnnclDsclsrRepCfppPt: null,
    ndFnnclDsclsrRepCt: null,
    ndFnnclDsclsrRepPt: null,
    ndGtreq1DsclRepCfppPt: null,
    ndGtreq1DsclRepCt: null,
    ndGtreq1DsclRepPt: null,
    ndGtreq3jbin2yrRepCfppPt: null,
    ndGtreq3jbin2yrRepCt: null,
    ndGtreq3jbin2yrRepPt: null,
    ndNuRepCfppPt: null,
    ndNuRepCt: null,
    ndNuRepPt: null,
    ndRbtnCvlgnDsclRepCfppPt: null,
    ndRbtnCvlgnDsclRepCt: null,
    ndRbtnCvlgnDsclRepPt: null,
    ndRepCfppPt: null,
    ndRepCt: null,
    ndSvrDscplndFrmRepCfppPt: null,
    ndSvrDscplndFrmRepCt: null,
    ndSvrDscplndFrmRepPt: null,
    ndTrmntdRepCfppPt: null,
    ndTrmntdRepCt: null,
    ndTrmntdRepPt: null,
    ndXfrdRepCfppPt: null,
    ndXfrdRepCt: null,
    ndXfrdRepPt: null,
    nuRepCfppPt: null,
    nuRepCt: null,
    nuRepPt: null,
    orgNtrnlCntrlQtrId: null,
    prcsdDt: prcsdDt,
    rbtnCvlgnDsclRepCfppPt: null,
    rbtnCvlgnDsclRepCt: null,
    rbtnCvlgnDsclRepPt: null,
    repCfppPt: null,
    repCt: null,
    rptId: null,
    svrDscplndFrmRepCfppPt: null,
    svrDscplndFrmRepCt: null,
    svrDscplndFrmRepPt: null,
    trmntdRepCfppPt: null,
    trmntdRepCt: null,
    trmntdRepPt: null,
    xfrdRepCfppPt: null,
    xfrdRepCt: null,
    xfrdRepPt: null
  };
}

/**
 * The column definitions.
 * this report type will use `AG Grid` since there is no row groups that contain sub-headers.
 */

export const NO_INFO_TEXT = 'No information available for the period.';

export const PERIOD_AUTO_GROUP_COLUMN_DEFS = {
  headerName: 'Period',
  valueGetter: (params) => params.data.processDate,
  valueFormatter: finraGridQuarterDateFormatter,
  // cellRenderer: 'agGroupCellRenderer',
  // cellRendererParams: {
  //   suppressCount: true
  // },
  minWidth: 250,
  maxWidth: 250
};

export function checkQuarterSummaryHasData(quarterData: RegisteredRepsUnion): boolean {
  // check if there is no data for the firm and industry
  // if this function return true, then that means that the quarter has NO data
  // if this function return false, then that means that the quarter HAS data
  const check: boolean = (
    quarterData.firm.totalCount === null &&
    quarterData.firm.percentageChangeFromPrior === null &&
    quarterData.industry.totalCount === null &&
    quarterData.industry.percentageChangeFromPrior === null
  );

  return check;
}

export const ALL_REGISTERED_REPS_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: '',
    field: 'group',
    rowGroup: true,
    hide: true,
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Count',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.firm.totalCount,
        valueFormatter: (params) => {
          const quarterHasData = !checkQuarterSummaryHasData(params.data);
          return quarterHasData
            ? finraGridNumericValueFormatter(params)
            : NO_INFO_TEXT;
        },
        // cellRenderer: aggCellRenderer,
        colSpan: function(params) {
          const quarterHasNoData = checkQuarterSummaryHasData(params.data);
          return quarterHasNoData ? 4 : 1;
        }
      },
      {
        headerName: '% Change from Prior Period',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.firm.percentageChangeFromPrior,
        valueFormatter: finraGridPercentValueFormatter,
        // cellRenderer: aggCellRenderer,
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Count',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.industry.totalCount,
        valueFormatter: finraGridNumericValueFormatter,
        // cellRenderer: aggCellRenderer
      },
      {
        headerName: '% Change from Prior Period',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.industry.percentageChangeFromPrior,
        valueFormatter: finraGridPercentValueFormatter,
        // cellRenderer: aggCellRenderer
      }
    ]
  }
]);

export const SECTION_REGISTERED_REPS_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: '',
    field: 'group',
    rowGroup: true,
    hide: true,
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Count',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.firm.totalCount,
        valueFormatter: (params) => {
          const quarterHasData = !checkQuarterSummaryHasData(params.data);
          return quarterHasData
            ? finraGridNumericValueFormatter(params)
            : NO_INFO_TEXT;
        },
        // cellRenderer: aggCellRenderer,
        colSpan: function(params) {
          const quarterHasNoData = checkQuarterSummaryHasData(params.data);
          return quarterHasNoData ? 6 : 1;
        }
      },
      {
        headerName: '% of Total Reps',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.firm.percentageOfReps,
        valueFormatter: finraGridPercentValueFormatter,
        // cellRenderer: aggCellRenderer,
      },
      {
        headerName: '% Change from Prior Period',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.firm.percentageChangeFromPrior,
        valueFormatter: finraGridPercentValueFormatter,
        // cellRenderer: aggCellRenderer,
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Count',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.industry.totalCount,
        valueFormatter: finraGridNumericValueFormatter,
        // cellRenderer: aggCellRenderer
      },
      {
        headerName: '% of Total Reps',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.industry.percentageOfReps,
        valueFormatter: finraGridPercentValueFormatter,
        // cellRenderer: aggCellRenderer
      },
      {
        headerName: '% Change from Prior Period',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: (params) => params.data.industry.percentageChangeFromPrior,
        valueFormatter: finraGridPercentValueFormatter,
        // cellRenderer: aggCellRenderer
      }
    ]
  }
]);

export const SEVERELY_DISCIPLINED_FIRMS_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Firm Name',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => params.data.firmName,
    // valueFormatter: finraGridNumericValueFormatter,
    // cellRenderer: aggCellRenderer
  },
  {
    headerName: 'CRD #',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => params.data.firmId,
    // valueFormatter: finraGridPercentValueFormatter,
    // cellRenderer: aggCellRenderer
  },
]);

/**
 * Export Data Config
 * ---
 *
 * 
 * */

const SECTION_REGISTERED_REPS_COLUMN_LABELS = [
  [
    '',
    'Firm',
    '',
    '',
    'Industry',
    '',
    '',
  ],
  [
    'Period',
    'Count',
    '% of Total Reps',
    '% Change from Prior Period',
    'Count',
    '% of Total Reps',
    '% Change from Prior Period',
  ],
];
const SECTION_REGISTERED_REPS_DATA_MAPPINGS = [
  'processDate',
  'firm.totalCount',
  'firm.percentageOfReps',
  'firm.percentageChangeFromPrior',
  'industry.totalCount',
  'industry.percentageOfReps',
  'industry.percentageChangeFromPrior',
];

export const getRegisteredRepExportConfig = (
  registeredRepsMasterList: RegisteredRepsMasterListElement[]
): SummaryExportData[] => [
  {
    title: registeredRepsMasterList[0].title,
    rowData: registeredRepsMasterList[0].list,
    columnLabels: [
      [
        '',
        'Firm',
        '',
        'Industry',
        '',
      ],
      [
        'Period',
        'Count',
        '% Change from Prior Period',
        'Count',
        '% Change from Prior Period',
      ],
    ],
    dataMappings: [
      'processDate',
      'firm.totalCount',
      'firm.percentageChangeFromPrior',
      'industry.totalCount',
      'industry.percentageChangeFromPrior',
    ],
  },
  ...registeredRepsMasterList.slice(1).map((registeredRepsData) => ({
    title: registeredRepsData.title,
    rowData: registeredRepsData.list,
    columnLabels: SECTION_REGISTERED_REPS_COLUMN_LABELS,
    dataMappings: SECTION_REGISTERED_REPS_DATA_MAPPINGS,
  }))
];
