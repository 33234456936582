import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { IconDefinition, faBars } from '@fortawesome/pro-regular-svg-icons';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { faThLarge, faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DqsFilterDataResponse } from 'src/app/configs/model/dqs.model';
import { Periodicities, CardFilterArguments, ReportStateEnum, ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { UserProfile } from 'src/app/configs/model/user-profile.model';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickEventDqsReportTypeSelectionFromSearchInfo, BeastClickEventReportTypeSelectionFromSearchInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { DqsService } from 'src/app/services/dqs.service';
import { ReportService } from 'src/app/services/report.service';
import { ReportsService } from 'src/app/services/reports.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { removeSpaces, quarterDateFormat } from 'src/app/shared/utils/utils';


@Component({
  selector: 'report-view',
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.scss']
})
export class ReportsViewComponent implements OnInit {
  @Input() title2: string;

  template: string = 'card';
  faBell: IconDefinition = faBell;
  faBellSolid: IconDefinition = faBellSolid;
  faBars: IconDefinition = faBars;
  faThLarge: IconDefinition = faThLarge;
  isActive: boolean = true;
  isCard: boolean = true;
  isList: boolean = false;
  isVisible: boolean = true;
  clientHeight: number;
  footerHeight: number = 220;
  NO_REPORTS_ERROR_MESSAGE = 'No Reports Found';
  mainContainerWidth: number;
  hasNoData: boolean = false;
  isExternalUser: boolean;
  isEWSUser = false;
  mpidsEWS;
  userAcessible: boolean = true;
  errorMessage;
  isDqs = false;
  hasPublishedOptionsPermission: boolean = false;
  shouldShowPublishedOptions: boolean = false;
  showPublishedOptions: boolean = false;
  firmId: string;
  cardsFilterLabel: string;
  isSROUser: boolean = false;
  isFRBUser: boolean = false;
  dqsFilters: DqsFilterDataResponse;

  private reportsData: any[] = null;
  private reports: string;
  private category: string;
  private periodicities: Periodicities[];
  private cardFilterArguments: CardFilterArguments;

  get reportSummaryPageUrl(): string {
    const url: string = '';
    return url;
  }

  constructor(
    private reportsService: ReportsService,
    private reportService: ReportService,
    private titleService: Title,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private userProfileService: UserProfileService,
    private dqsService: DqsService,
    private unreadReportsService: UnreadReportsService,
    private beastService: BeastService,
  ) {
    this.clientHeight = window.innerHeight;
    this.titleService.setTitle('Reports');
    this.title2 = this.activatedRoute.snapshot.data['title2'];
    this.reports = this.activatedRoute.snapshot.data['reports'];
    this.isDqs = this.reports === 'dqs';
    this.getPeriodicities();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
    this.userProfileService.getUserProfile().subscribe((userProfile: UserProfile) => {
      this.handleUserProfile(userProfile);
    });
    this.unreadReportsService.select('listChanges').subscribe((list: ReportInstanceMetadata[]) => {
      this.handleUnreadReportsChange(list);
    });
  }

  handleUnreadReportsChange(list: ReportInstanceMetadata[]) {
    if (this.reportsData && this.reportsData.length) {
      this.applyUnreadStatus(this.reportsData);
    }
  }

  handleParamsChange(params: Params) {
    this.category = params['category'];
    this.reportsService.getReportCategory(parseInt(this.category, 10))
      .subscribe(reportCategory => {
        this.userAcessible = reportCategory.userAccessible;
      });
  }

  handleUserProfile(userProfile: UserProfile) {
    this.isExternalUser = this.userProfileService.isExternalUser(userProfile);
    this.hasPublishedOptionsPermission = this.userProfileService.checkPublishedOptionsPermission(userProfile);
    this.shouldShowPublishedOptions = this.hasPublishedOptionsPermission && !this.isDqs;
    if (this.isExternalUser) {
      this.isEWSUser = true;
      if (this.isDqs) {
        this.isVisible = false;
        this.getDqsReportsByFirmId(userProfile.orgId);
      } else {
        this.cardFilterArguments = {
          categoryId: this.category,
          firmId: userProfile.orgId,
          state: ReportStateEnum.Published
        };
        // DDWA-2434 - Show Equity MPIDs dropdown filter for Equity reports
        if (this.category === '4') {
          this.mpidsEWS = userProfile.equityMpids;
          this.cardFilterArguments.firmId = userProfile.equityMpids[0];
          this.getReportsByFimrId(this.cardFilterArguments);
          this.isVisible = false;
          // DDWA-3086 - Show Bond MPIDs dropdown filter for TRACE reports
        } else if (this.category === '1') {
          this.mpidsEWS = userProfile.bondMpids;
          if(userProfile.orgClass === 'QUERY') {
            this.isFRBUser = true;
          }
          // DDWA-3776 - Show Msrb MPIDs dropdown filter for MSRB reports
        }else if (this.category === '3') {
          this.mpidsEWS = userProfile.msrbIds;
          // DDWA-3990 Keep Firm ID Text box for SRO User
        } else if (
          (this.category === '12' || this.category === '8') && userProfile.orgClass === 'SRO'
        ) {
          this.isSROUser = true;
        } else {
          // For other reports show the report cards directly
          this.getReportsByFimrId(this.cardFilterArguments);
          this.isVisible = false;
        }
      }
    }
    else if (this.shouldShowPublishedOptions) {
      this.showPublishedOptions = true;
    }
  }

  triggerFirmFilter(filters: CardFilterArguments) {
    if (this.isDqs) {
      this.getDqsReportsByFirmId(removeSpaces(filters.firmId));
    } else {
      this.getReportsByFimrId(filters);
    }
  }

  getDqsReportsByFirmId(firmId) {
    this.dqsService.getDataByFirmId(firmId).subscribe({
      next: (filters) => {
        this.firmId = firmId;
        this.hasNoData = filters.length === 0;
        this.dqsFilters = filters;

        if (this.hasNoData) {
          this.errorMessage = this.NO_REPORTS_ERROR_MESSAGE;
        }
        else {
          this.dqsService.getReportConfiguration().subscribe((data) => {
            this.reportsData = data;
            for (const report of this.reportsData) {
              (report as any).url = this.openReportDetail(report, true);
            }
            this.errorMessage = null;
          });
        }
      }
    });
  }

  getReportsByFimrId(cardFilterArguments: CardFilterArguments) {
    cardFilterArguments.categoryId = this.category;
    this.reportsService.getReportsByFimrId(cardFilterArguments).subscribe({
      next: data => {
        this.firmId = cardFilterArguments.firmId;
        this.reportsData = data.reportInstanceMetadatas;
        for (const report of this.reportsData) {
          (report as any).url = this.openReportDetail(report, true);
        }
        this.applyUnreadStatus(this.reportsData);
        this.hasNoData = false;
        this.errorMessage = null;
      },
      error: error => {
        this.reportsData = null;
        this.hasNoData = true;
        let errorMessage;
        if (error.status === 401 && !this.isFRBUser) {
          this.userAcessible = false;
        } else {
          errorMessage = this.NO_REPORTS_ERROR_MESSAGE;
        }
        this.errorMessage = errorMessage;
      }
    });
  }

  applyUnreadStatus(reportInstanceMetadatas: ReportInstanceMetadata[]) {
    for (const report of reportInstanceMetadatas) {
      const isUnread = this.unreadReportsService.isReportUnread(report.reportId);
      (<any> report).isUnread = isUnread;
    }
  }

  getPeriodicities() {
    this.reportsService.getPeriodicities().subscribe({
      next: data => {
        this.periodicities = data['periodicities'];
      },
      error: error => {}
    });
  }

  getPeriodInText(periodicityId: number) {
    if (periodicityId === null) {
      return '';
    }
    const periodicity =
      this.periodicities.length > 0
        ? this.periodicities.filter(
            period => period.periodicityId === periodicityId
          )
        : {};
    return this.periodicities.length > 0 ? periodicity[0].periodicityName : '';
  }

  openReportDetail(report: ReportInstanceMetadata, getPathUrl?: boolean) {
    // Extracting parameters to pass to Equity Component via routing
    this.reportsService.setCurrentReportInstance(report);
    const dqsFilter = this.dqsFilters && this.dqsFilters[0];

    this.sendReportOpenedBeastEvent(report);

    return this.reportService.navigateToReportDetails({
      report,
      dqsFilter,
      getPathUrl,
      firmId: this.firmId,
    });
  }

  sendReportOpenedBeastEvent(report: ReportInstanceMetadata) {
    const dqsFilter = this.dqsFilters && this.dqsFilters[0];

    /** Log BEAST event */
    const useAction = this.isDqs
      ? BeastClickActions.VIEW_DQS_REPORT_FROM_SEARCH
      : BeastClickActions.VIEW_REPORT_FROM_SEARCH;
    const eventInfo = this.isDqs
      ? {
          ...dqsFilter,
          reportType: report.reportConfiguration.reportDisplayName,
          effectiveDate: report.reportConfiguration.effectiveDate,
          reportDataVersion: report.reportDataVersion,
          reportEdition: report.reportConfiguration.reportEdition,
        } as BeastClickEventDqsReportTypeSelectionFromSearchInfo
      : {
          reportId: report.reportId.toString(),
          reportType: report.reportConfiguration.reportDisplayName,
          reportPeriod: report.reportPeriodDate,
          reportVersion: report.reportDataVersion,
          firmId: report.reportFirmId
        } as BeastClickEventReportTypeSelectionFromSearchInfo;

    this.beastService.clickStream.postEvent(
      useAction,
      eventInfo
    );
  }

  changeView(template) {
    this.template = template;
  }

  getDateLabel(date: string, periodicity: number) {
    let reportDate;
    switch (periodicity) {
      case 3:
        reportDate = this.datePipe.transform(date, 'MMMM yyyy');
        break;
      case 4:
        reportDate = quarterDateFormat(date);
        break;
      case 6:
        reportDate = this.datePipe.transform(date, 'MMMM d, yyyy');
        break;
    }
    return reportDate;
  }
}
