import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ReportCenterUser } from 'src/app/configs/model/report-center-users.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportCenterUsersService {
  private baseUrl: string;

  constructor(
    @Inject('environment')
    environment,
    private http: HttpClient
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getUsersByOrgId(orgId): Observable<Array<ReportCenterUser>> {
    return this.http
      .get(`${this.baseUrl}reportCenterUsers/search/userOrgId/${orgId}`)
      .pipe(map(res => <Array<ReportCenterUser>>res['reportCenterUserLights']));
  }
}
