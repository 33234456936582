import { Injectable } from '@angular/core';
import {
  DEALS_CHART_VALUES,
  VIEW_NAMES,
  DEALS_SORT_ORDER,
  COMMENT_SORT_ORDER,
  DISTRIBUTION_SORT_ORDER,
  DEALS_SORT_KEY,
  COMMENT_SORT_KEY,
  DISTRIBUTION_SORT_KEY
} from 'src/app/configs/model/corp-fin.model';
import { Subject } from 'rxjs';
import { sort_distinct, PlainObject, createDateFromPeriod, sort, quarterDateFormat } from '../shared/utils/utils';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CorpFinService {
  viewTypeSubject = new Subject();
  viewType: string;

  constructor(
    public datepipe: DatePipe,
  ) {}

  getViewType(): string {
    return this.viewType;
  }

  getViewTypeSubject() {
    return this.viewTypeSubject;
  }

  setViewType(viewType) {
    this.viewType = viewType;
    this.viewTypeSubject.next(viewType);
  }

  getCurrentDealsFiledChart(data) {
    const counts = JSON.parse(JSON.stringify(DEALS_CHART_VALUES));
    data.forEach(element => {
      if (!isNaN(element.dealFilingCount)) {
        counts[element.currentReviewProgramDescription]['percent'] += element.dealFilingCount;
      }
    });
    const chartData = this.createSeries(counts);
    return chartData;
  }

  getPriorDealsFiledChart(data) {
    const countsLessThanSix = JSON.parse(JSON.stringify(DEALS_CHART_VALUES));
    const countsMoreThanSix = JSON.parse(JSON.stringify(DEALS_CHART_VALUES));
    data.forEach(element => {
      // Less than 6 months
      countsLessThanSix[element.currentReviewProgramDescription][
        'percent'
      ] += !isNaN(element.dealWithin6MonthsFromInitialFilingPercent)
        ? element.dealWithin6MonthsFromInitialFilingPercent
        : 0;
      countsLessThanSix[element.currentReviewProgramDescription][
        'count'
      ] += !isNaN(element.dealWithin6MonthsFromInitialFilingCount)
        ? element.dealWithin6MonthsFromInitialFilingCount
        : 0;
      // More than 6 months
      countsMoreThanSix[element.currentReviewProgramDescription][
        'percent'
      ] += !isNaN(element.dealOver6MonthsFromInitialFilingPercent)
        ? element.dealOver6MonthsFromInitialFilingPercent
        : 0;
      countsMoreThanSix[element.currentReviewProgramDescription][
        'count'
      ] += !isNaN(element.dealOver6MonthsFromInitialFilingCount)
        ? element.dealOver6MonthsFromInitialFilingCount
        : 0;
    });
    const chartData = [
      {
        name: '< 6 Months Old',
        series: this.createSeries(countsLessThanSix)
      },
      {
        name: '≥ 6 Months Old',
        series: this.createSeries(countsMoreThanSix)
      }
    ];
    return chartData;
  }

  getDealsClearedChart(data) {
    const countsLessThanSix = JSON.parse(JSON.stringify(DEALS_CHART_VALUES));
    const countsLessThanOne = JSON.parse(JSON.stringify(DEALS_CHART_VALUES));
    const countsMoreThanSix = JSON.parse(JSON.stringify(DEALS_CHART_VALUES));
    data.forEach(element => {
      countsLessThanSix[element.currentReviewProgramDescription][
        'percent'
      ] += !isNaN(element.dealClearedBetween1And6MonthsPercent)
        ? element.dealClearedBetween1And6MonthsPercent
        : 0;
      countsMoreThanSix[element.currentReviewProgramDescription][
        'percent'
      ] += !isNaN(element.dealClearedOver6MonthsPercent)
        ? element.dealClearedOver6MonthsPercent
        : 0;
      countsLessThanOne[element.currentReviewProgramDescription][
        'percent'
      ] += !isNaN(element.dealClearedWithin1MonthPercent)
        ? element.dealClearedWithin1MonthPercent
        : 0;

      countsLessThanSix[element.currentReviewProgramDescription][
        'count'
      ] += !isNaN(element.dealClearedBetween1And6MonthsCount)
        ? element.dealClearedBetween1And6MonthsCount
        : 0;
      countsMoreThanSix[element.currentReviewProgramDescription][
        'count'
      ] += !isNaN(element.dealClearedOver6MonthsCount)
        ? element.dealClearedOver6MonthsCount
        : 0;
      countsLessThanOne[element.currentReviewProgramDescription][
        'count'
      ] += !isNaN(element.dealClearedWithin1MonthCount)
        ? element.dealClearedWithin1MonthCount
        : 0;
    });
    const chartData = [
      {
        name: '< 1 Month',
        series: this.createSeries(countsLessThanOne)
      },
      {
        name: '> 1 and < 6 Months',
        series: this.createSeries(countsLessThanSix)
      },
      {
        name: '≥ 6 Months',
        series: this.createSeries(countsMoreThanSix)
      }
    ];
    return chartData;
  }

  getChartByPropertyName(data, name) {
    const greaterThanSixMonth = [];
    const lessThanSixMonth = [];
    const currentPeriod = [];
    data.forEach(element => {
      greaterThanSixMonth.push({
        name: element[name],
        value: element.over6MonthsFromInitialFilingPercent,
        count: element.over6MonthsFromInitialFilingCount || 0
      });
      lessThanSixMonth.push({
        name: element[name],
        value: element.within6MonthsFromInitialFilingPercent,
        count: element.within6MonthsFromInitialFilingCount || 0
      });
      currentPeriod.push({
        name: element[name],
        value: element.currentPeriodPercent,
        count: element.currentPeriodCount || 0
      });
    });
    const chartData = [
      {
        name: 'Selected Reporting Period',
        series: currentPeriod
      },
      {
        name: '>1 and < 6 Months Old',
        series: lessThanSixMonth
      },
      {
        name: '≥ 6 Months Old',
        series: greaterThanSixMonth
      }
    ];
    return chartData;
  }

  createSeries(countObj) {
    const series = [];
    Object.keys(countObj).forEach(prop => {
      series.push({
        name: prop === 'Shelf' ? `${prop} Review` : prop,
        value: countObj[prop]['percent'],
        count: countObj[prop]['count'] ? countObj[prop]['count'] : 0
      });
    });
    return series;
  }

  formatPercentValues(data) {
    return data.map((x, index) => {
      if (
        index < data.length - 1 &&
        x['currentReviewProgramDescription'] ===
          data[index + 1]['currentReviewProgramDescription']
      ) {
        // Setting all percent values for current submission summary as 0 except the last value
        if (x['industryFilingPercent']) {
          x['industryFilingPercent'] = 0;
        }
        if (x['filingPercent']) {
          x['filingPercent'] = 0;
        }

        // Setting all percent values for prior submission summary as 0 except the last value
        if (x['industryWithin6MonthsFromInitialFilingPercent']) {
          x['industryWithin6MonthsFromInitialFilingPercent'] = 0;
        }
        if (x['industryOver6MonthsFromInitialFilingPercent']) {
          x['industryOver6MonthsFromInitialFilingPercent'] = 0;
        }
        if (x['within6MonthsFromInitialFilingPercent']) {
          x['within6MonthsFromInitialFilingPercent'] = 0;
        }
        if (x['over6MonthsFromInitialFilingPercent']) {
          x['over6MonthsFromInitialFilingPercent'] = 0;
        }

        // Setting deals cleared percent values as 0 except the last one
        if (x['clearedWithin1MonthPercent']) {
          x['clearedWithin1MonthPercent'] = 0;
          }
        if (x['clearedBetween1And6MonthsPercent']) {
          x['clearedBetween1And6MonthsPercent'] = 0;
          }
        if (x['clearedOver6MonthsPercent']) {
          x['clearedOver6MonthsPercent'] = 0;
        }
      }
      return x;
    });
  }

  // DDWA-2785 Calculating firmLateFilingPercent from filerLateFilingCount and filerTotalFilingCount
  formatSubmissionsPercent(data) {
    if (!data.submissionTotals) {
      return data;
    }
    data.submissionTotals = data.submissionTotals.map(x => {
      const percent = (x['filerLateFilingCount'] * 100) / x['filerTotalFilingCount'];
      x['firmLateFilingPercent'] = percent;
      return x;
    });
    return data;
  }

  addCustomTotalRow(data) {
    // DDWA-2625 Corp Fin - Percent fields in "Deals Filed Prior to the Selected Period" table are double what they should be
    const groupedData = this.sortDataByGroup(data);
    // Formatting percentages for showing value for ony 1 entry for each group
    const formatted = this.formatPercentValues(groupedData);
    const total = {};

    // Since not all objects will have data for all keys we need
    // to iterating through the keys of all formatted objects
    // DDWA-2847
    formatted.forEach(curFormatted => {
      Object.keys(curFormatted).forEach(key => {
        if (key in total) {
          return;
        }
        // Create total only for numeric values
        if (!isNaN(curFormatted[key])) {
          const agg = this.sum(formatted, key);
          total[key] = Math.round(agg) !== 100 ? agg : 100;
        }
      });
    });

    total['currentReviewProgramDescription'] = 'Total';

    // Add total row at the end of the formatted data
    formatted.push(total);

    return formatted;
  }

  sum(arr, key) {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }

  // DDWA-2625 Corp Fin - Percent fields in "Deals Filed Prior to the Selected Period" table are double what they should be
  sortDataByGroup(data) {
    const tempGroupedData = {
      'Limited Review': [],
      'Full Review': [],
      Shelf: [],
      'Expedited Review': []
    };
    // Separating data into groups so it can be merged into a sorted array
    data.forEach(row => {
      if (!tempGroupedData[row['currentReviewProgramDescription']]) {
        return;
      }
      // Adding each row to its group
      tempGroupedData[row['currentReviewProgramDescription']].push(row);
    });
    data = [];
    // Merging the data back into one array so everything is sorted in order
    data = data
      .concat(tempGroupedData['Limited Review'])
      .concat(tempGroupedData['Full Review'])
      .concat(tempGroupedData['Shelf'])
      .concat(tempGroupedData['Expedited Review']);
    return data;
  }

  addMissingRows(rowData: object[], viewName: any): object[] {
    let keyValues = [];
    let key = '';

    switch (viewName) {
      case VIEW_NAMES.DEALS_FILED_CURRENT:
      case VIEW_NAMES.DEALS_FILED_PRIOR:
      case VIEW_NAMES.DEALS_CLEARED_CURRENT:
        keyValues = Object.keys(DEALS_SORT_ORDER);
        key = DEALS_SORT_KEY;
        break;

      case VIEW_NAMES.COMMENT_LETTERS:
        keyValues = Object.keys(COMMENT_SORT_ORDER);
        key = COMMENT_SORT_KEY;
        break;

      case VIEW_NAMES.DISTRIBUTION_METHOD:
        keyValues = Object.keys(DISTRIBUTION_SORT_ORDER);
        key = DISTRIBUTION_SORT_KEY;
        break;
    }

    keyValues.forEach(keyValue => {
      if (rowData.findIndex(x => x[key] === keyValue) === -1) {
        const dummyRecord = {};

        dummyRecord[key] = keyValue;

        rowData.push(dummyRecord);
      }
    });

    return rowData;
  }

  /** Public Offering Rule 5110 Filing */

  publicOffertingFormatProgramDeals(data: object[], view: string) {
    const distinctProgramDescriptions = sort_distinct(data, DEALS_SORT_KEY);
    Object.keys(distinctProgramDescriptions).forEach((programName) => {
      const programDealsList = distinctProgramDescriptions[programName];
      const programHasNoDeals: boolean = (
        programDealsList.length === 1 &&
        programDealsList[0].dealFilingCount === null &&
        programDealsList[0].filingCount === 0
      );
      if (programHasNoDeals) {
        // set all null count properties to 0 to fix UI aggregation issue.
        programDealsList[0].dealFilingCount = 0;
        if (view === VIEW_NAMES.DEALS_FILED_PRIOR) {
          programDealsList[0].dealWithin6MonthsFromInitialFilingCount = 0;
          programDealsList[0].dealOver6MonthsFromInitialFilingCount = 0;
        } else if (view === VIEW_NAMES.DEALS_CLEARED_CURRENT) {
          programDealsList[0].dealClearedWithin1MonthCount = 0;
          programDealsList[0].dealClearedBetween1And6MonthsCount = 0;
          programDealsList[0].dealClearedOver6MonthsCount = 0;
        }
      }
    });
  }

  getPublicOfferingFormattedChart(rowData, viewName): any {
    if (!rowData) {
      return;
    }
    // Remove custom added total row before trying to calculate chart values
    const dataForChart = rowData.filter(x => {
      return x['currentReviewProgramDescription'] !== 'Total';
    });
    let data;

    switch (viewName) {
      case VIEW_NAMES.DEALS_FILED_CURRENT: {
        data = this.getCurrentDealsFiledChart(dataForChart);
        break;
      }
      case VIEW_NAMES.DEALS_FILED_PRIOR: {
        data = this.getPriorDealsFiledChart(dataForChart);
        break;
      }
      case VIEW_NAMES.DEALS_CLEARED_CURRENT: {
        data = this.getDealsClearedChart(dataForChart);
        break;
      }
      case VIEW_NAMES.COMMENT_LETTERS: {
        data = this.getChartByPropertyName(
          rowData,
          'letterTypeCode'
        );
        break;
      }
      case VIEW_NAMES.DISTRIBUTION_METHOD: {
        data = this.getChartByPropertyName(
          rowData,
          'distributionMethodDescription'
        );
        break;
      }
    }

    return data;
  }

  /** Private Placement Filing Timeliness */

  createRuleCartData(options: {
    reportPeriodDate: string;
    rules: string[];
    rowData: any[];
  }): {
    ruleData: PlainObject;
    chartData: PlainObject;
  } {
    if (!options.reportPeriodDate) {
      return;
    }

    const ruleDataObj = {};
    const chartDataObj = {};

    options.rules.forEach(rule => {
      ruleDataObj[rule] = [];
      const ruleData = options.rowData.filter(x => x['ruleDescription'] === rule);
      // Adding missing quarters to each rule
      for (let i = 3; i >= 0; i--) {
        const reportPeriodDate = createDateFromPeriod(options.reportPeriodDate);
        const quarterDate = new Date(
          reportPeriodDate.setMonth(reportPeriodDate.getMonth() - i * 3)
        );
        const quarterDateFormatted = this.datepipe.transform(
          quarterDate,
          'yyyy-MM-dd'
        );
        const pos = ruleData.findIndex(
          x => x['quarterStartDate'] === quarterDateFormatted
        );
        ruleDataObj[rule].push(
          pos > -1
            ? ruleData[pos]
            : {
                quarterStartDate: quarterDateFormatted
              }
        );
      }
      // Only display at most 4 quarters of data
      // https://jira.finra.org/browse/DDWA-2430
      ruleDataObj[rule] = sort(ruleDataObj[rule], 'quarterStartDate').slice(0, 4);
    });

    options.rules.forEach(rule => {
      chartDataObj[rule] = [];
      sort(ruleDataObj[rule], 'quarterStartDate', true).forEach(row => {
        let chartDataRow = {};
        if (rule === '5123') {
          chartDataRow = {
            name: quarterDateFormat(row['quarterStartDate']),
            series: [
              {
                name: 'Filing Submitted Within 15 Days of Date of First Sale',
                value:
                  row['filingsSubmittedWithin15DaysOfFirstSalePercent'] || 0
              },
              {
                name: 'Filings Submitted >15 Days after Date of First Sale',
                value:
                  row['filingsSubmittedOver15DaysAfterFirstSalePercent'] || 0
              },
              {
                name: 'Filings with Unknown Date of First Sale',
                value:
                  row['filingsSubmittedUnknownDaysAfterFirstSaleDatePercent'] ||
                  0
              }
            ]
          };
        } else if (rule === '5122') {
          chartDataRow = {
            name: quarterDateFormat(row['quarterStartDate']),
            series: [
              {
                name:
                  'Filings Submitted At or Prior to the Date of First Offer',
                value: row['filingsSubmittedAtOrPriorToFirstOfferPercent'] || 0
              },
              {
                name: 'Filings Submitted After the Date of First Offer',
                value: row['filingsSubmittedAfterFirstOfferPercent'] || 0
              },
              {
                name: 'Filings with Unknown Date of First Offer',
                value:
                  row[
                    'filingsSubmittedUnknownDaysAfterFirstOfferDatePercent'
                  ] || 0
              }
            ]
          };
        }
        chartDataObj[rule].push(chartDataRow);
      });
    });

    return {
      ruleData: ruleDataObj,
      chartData: chartDataObj
    };
  }
}
