import { FinraGridClickableCellComponent } from "src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component";
import {
  addFinraGridColumnId,
  getMonthYrDate,
  finraGridNumericValueFormatter,
  finraGridPercentValueFormatter,
  FINRA_GRID_NULL_VALUE,
} from "src/app/shared/utils/utils";



export const FIRM_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: "",
    children: [
      {
        headerName: "Period",
        field: "period",
        valueFormatter: (params) => {
          return getMonthYrDate(params.value);
        },
      },
      {
        headerName: "Total Outstanding Issuances",
        field: "totalOutstandingIssuances",
        valueFormatter: finraGridNumericValueFormatter,
      },
      {
        headerName: "Outstanding Issuances LCF",
        field: "outstandingIssuancesLCF",
        valueFormatter: finraGridNumericValueFormatter,
      },
      {
        headerName: "% of Outstanding Issuances LCF",
        field: "percentageOutstandingIssuancesLCF",
        valueFormatter: finraGridPercentValueFormatter,
      },
      {
        headerName: "Mean Lateness for Outstanding Issuances LCF",
        field: "meanLatenessForOutstandingIssuancesLCF",
        valueFormatter: finraGridNumericValueFormatter,
      },
    ],
  },
  {
    headerName: "Count of Lateness for Issuances LCF (By Days Late)",
    headerClass: "lines",
    cellClass: "lines",
    children: [
      {
        headerName: "0-30",
        headerClass: "lines",
        cellClass: "lines",
        field: "0To30",
        valueFormatter: finraGridNumericValueFormatter,
      },
      {
        headerName: "31-90",
        valueFormatter: finraGridNumericValueFormatter,
        field: "31To90",
      },
      {
        headerName: "91-365",
        valueFormatter: finraGridNumericValueFormatter,
        field: "91To365",
      },
      {
        headerName: ">365",
        valueFormatter: finraGridNumericValueFormatter,
        field: "above365",
      },
    ],
  },
]);

export const PEER_GROUP_COLS = (
  peerGroupNumberClickHandler: (params) => void
) =>
  addFinraGridColumnId([
    ...FIRM_COLUMN_DEFS,
    {
      headerName: "Peer Group",
      cellRendererFramework: FinraGridClickableCellComponent,
      cellRendererFrameworkOptions: {
        isClickable: (params) => !!params.data.peerGroupId,
        showButton: (params) => true,
        buttonClass: (param) => "peer-group-link",
        buttonLabel: (params) =>
          params.data.peerGroupId || FINRA_GRID_NULL_VALUE,
        handleClick: peerGroupNumberClickHandler,
      },
    },
  ]);

export const UNDERWRITER_FIN_COL_DEFS = {
  firm: FIRM_COLUMN_DEFS,
  industry: FIRM_COLUMN_DEFS,
  peerGroup: PEER_GROUP_COLS,
};
