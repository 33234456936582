/**
 * LOPR - Listed Report Model
 */

import {
  addFinraGridColumnId,
  finraGridNumericValueFormatter,
  buildExportDataString,
  saveCsv,
  SummaryExportData,
  trimTrailingChar,
} from 'src/app/shared/utils/utils';

export interface LoprListedGridData {
  positionDate: string;
  exceptionCount: string;
}

export interface LoprListedResponse {
  summary: LoprListedGridData[];
}

export const LOPR_LISTED_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Exception Periods',
    headerClass: 'lines',
    cellClass: 'lines',
    minWidth: 300,
    maxWidth: 300,
    valueGetter: (params) => (<LoprListedGridData>params.data).positionDate,
    valueFormatter: (params) => {
      // format change: yyyy-mm-dd --> mm/dd/yyyy
      const dateArr = params.value.split('-');
      const month = dateArr[1];
      const year = dateArr[0];
      const day = dateArr[2];
      const dateString = `${month}/${day}/${year}`;
      return dateString;
    }
  },
  {
    headerName: 'Total Number of Exceptions',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => (<LoprListedGridData>params.data).exceptionCount,
    valueFormatter: finraGridNumericValueFormatter
  },
]);

/** Export Data */

export const getLoprListedSummaryExportConfig = (rowData, title): SummaryExportData[] => [
  {
    title: title,
    rowData: rowData['listed'],
    columnLabels: [
      'Exception Periods',
      'Total Number of Exceptions',
    ],
    dataMappings: [
      'positionDate',
      'exceptionCount',
    ],
  },
];

export function exportLoprListedSummaryData(rowData, title): string {
  const exportData = getLoprListedSummaryExportConfig(rowData, title);
  const csvData = buildExportDataString(exportData);
  const formatted = trimTrailingChar(csvData, '\n');
  return formatted;
}
