<div id="risk-monitoring-container">
  <report-loader></report-loader>

  <h1 class="title pdf">{{ title }}</h1>
  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="riskMonitoringToolbarConfig"
    [toolbarConfig]="riskMonitoringToolbarConfig"
  ></report-finra-toolbar>

  <div class="margin-bottom-20"></div>

  <div id="data-container" *ngIf="reportLoadedSuccessfully">
    <ng-container
      [ngSwitch]="reportTypeId"
    >
      <!-- Customer Debits -->
      <report-customer-debits
        *ngSwitchCase="reportTypesEnum.CUSTOMER_DEBITS"
        [reportInstanceMetadata]="reportInstanceMetadata"
        [customerDebitsMonthly]="customerDebitsMonthly"
        [unsecuredDebitsMonthly]="unsecuredDebitsMonthly"
        [marginDebitsMonthly]="marginDebitsMonthly"
      ></report-customer-debits>

      <!-- Canceled As-Of Trades -->
      <report-canceled-as-of-trades
        *ngSwitchCase="reportTypesEnum.CANCELED_AS_OF_TRADES"
        [reportInstanceMetadata]="reportInstanceMetadata"
        [canceledAndAsOfTradesMonthly]="canceledAndAsOfTradesMonthly"
        [allTradesMonthly]="allTradesMonthly"
        [asOfTradesMonthly]="asOfTradesMonthly"
        [beforeSettlementTradesMonthly]="beforeSettlementTradesMonthly"
        [afterSettlementTradesMonthly]="afterSettlementTradesMonthly"
      ></report-canceled-as-of-trades>

      <!-- Registered Representative Composition Report -->
      <report-registered-reps-composition
        *ngSwitchCase="reportTypesEnum.REGISTERED_REPRESENTATIVES"
        [reportInstanceMetadata]="reportInstanceMetadata"
        [registeredRepsMasterList]="registeredRepsMasterList"
      ></report-registered-reps-composition>

      <!-- Sales Practice Complaint Report -->
      <report-sales-practice-complaint-report
        *ngSwitchCase="reportTypesEnum.SALES_PRACTICE_COMPLAINT"
        [reportInstanceMetadata]="reportInstanceMetadata"
        [rowData]="salesComplaintRowData"
        [salesComplaintViews]="salesComplaintViews"
      ></report-sales-practice-complaint-report>

      <!-- Customer Complaint -->
      <report-customer-complaint
        *ngSwitchCase="reportTypesEnum.CUSTOMER_COMPLAINT"
        [reportInstanceMetadata]="reportInstanceMetadata"
        [viewName]="viewName"
        [quartersHeadings]="customerComplaintQuartersHeadings"
        [rowData]="customerComplaintRowData"
        [totalsObj]="customerComplaintTotalsObj"
        [allOtherComplaintsObj]="customerComplaintAllOtherComplaintsObj"
        [industryComplaintsRowData]="customerComplaintIndustryComplaintsRowData"
        [complaintsAsscIndv]="complaintsAsscIndv"
        [complaintsRepsRelated]="complaintsRepsRelated"
      ></report-customer-complaint>
    </ng-container>

    <!-- Footer -->
    <hr />
    <div
      class="report-main-content-padding report-disclaimer pdf"
      *ngIf="footerMessages[reportName]"
      [innerHtml]="footerMessages[reportName]"
    ></div>
  </div>
</div>
