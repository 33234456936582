import { Injectable, Inject } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  ReportCategoriesResponse,
  Periodicities,
  ReportCategory,
  Reports,
  ReportInstanceMetadata,
  ReportInstanceMetadatasResponse,
  ReportsDailyReports,
  CardFilterArguments,
  ReportConfigurationView,
} from "../configs/model/reports.model";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  private baseUrl: string;
  private reportInstance: any;
  name;

  private reportCategories: ReportCategoriesResponse;
  private periodicities: { periodicities: Periodicities[] };

  constructor(
    @Inject("environment") environment,
    private http: HttpClient
  ) {
    this.name = "ReportService" + Math.ceil(Math.random() * 100000000);
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getReportCategoriesObj() {
    return this.reportCategories;
  }

  getPeriodicitiesObj() {
    return this.periodicities;
  }

  getReportCategories(): Observable<ReportCategoriesResponse> {
    if (this.reportCategories) {
      return of(this.reportCategories);
    }
    return this.http
      .get<ReportCategoriesResponse>(`${this.baseUrl}reportCategories`)
      .pipe(
        map((response) => {
          // save this for later, synchronous use
          this.reportCategories = response;
          return response;
        })
      );
  }

  getPeriodicities(): Observable<{ periodicities: Periodicities[] }> {
    if (this.periodicities) {
      return of(this.periodicities);
    }
    return this.http
      .get<{ periodicities: Periodicities[] }>(`${this.baseUrl}periodicities`)
      .pipe(
        map((response) => {
          // save this for later, synchronous use
          this.periodicities = response;
          return response;
        })
      );
  }

  getReportCategory(id: number): Observable<ReportCategory> {
    if (this.reportCategories) {
      return of(
        this.reportCategories.reportCategories.find(
          (x) => x.reportCategoryId === id
        )
      );
    }

    const obs = new Observable<ReportCategory>((observer) => {
      this.getReportCategories().subscribe((xs) => {
        const rpt = xs["reportCategories"].find(
          (x) => x.reportCategoryId === id
        );

        observer.next(rpt);
      });
      // When the consumer unsubscribes, clean up data ready for next subscription.
      return { unsubscribe: () => {} };
    });

    return obs;
  }

  getReports(
    categoryId: string,
    isExternalUser?: boolean,
    firmId?: string
  ): Observable<Reports[]> {
    let url = `${this.baseUrl}reportInstanceMetadatas/latest/reportCategoryId/${categoryId}`;

    if (isExternalUser) {
      url += `?firmId=${firmId.toUpperCase()}&isPublished=true`;
    }

    return this.http.get<Reports[]>(url);
  }

  getUnReadReports() {
    const url = `${this.baseUrl}reportInstanceMetadatas/latest/unReadReports`;
    return this.http.get<{ reportInstanceMetadatas: ReportInstanceMetadata[] }>(
      url,
      {
        params: { ignorelist: "true" },
      }
    );
  }

  getReportInstanceMetadataByReportTypeAndFirm(
    reportTypeId: number,
    firmId: string
  ): Observable<ReportInstanceMetadata[]> {
    const url = `${
      this.baseUrl
    }reportInstanceMetadatas/latest/reportType/${reportTypeId}?firmId=${firmId.toUpperCase()}&isPublished=true`;
    return this.http.get<ReportInstanceMetadatasResponse>(url).pipe(
      map((response: ReportInstanceMetadatasResponse) => {
        return response.reportInstanceMetadatas;
      })
    );
  }

  getReportInstanceMetadataByReportTypeAndFirmAndStatus(
    reportTypeId: number,
    firmId: string,
    state: string,
    reportConfigViewId?: string
  ): Observable<ReportInstanceMetadata[]> {
    const url = `${
      this.baseUrl
    }reportInstanceMetadatas/period/versions/reportTypeId/${reportTypeId}/firmId/${firmId.toUpperCase()}/state/${state}`;

    if (reportConfigViewId) {
      // Passing query param reportConfigViewId for EWS Firm User
      const params = new HttpParams().set(
        "reportConfigViewId",
        reportConfigViewId
      );

      return this.http
        .get<ReportInstanceMetadatasResponse>(url, { params: params })
        .pipe(
          map((response: ReportInstanceMetadatasResponse) => {
            return response.reportInstanceMetadatas;
          })
        );
    }

    return this.http.get<ReportInstanceMetadatasResponse>(url).pipe(
      map((response: ReportInstanceMetadatasResponse) => {
        return response.reportInstanceMetadatas;
      })
    );
  }

  getReportsDailyTotals(): Observable<ReportsDailyReports[]> {
    return this.http.get<ReportsDailyReports[]>(
      `${this.baseUrl}report-daily-totals`
    );
  }

  getReportInstanceMetadata(id: number) {
    const api_url = `${this.baseUrl}reportInstanceMetadatas/id/${id}`;
    return this.http.get<ReportInstanceMetadata>(api_url);
  }

  getViewTemplateConfiguration(
    reportInstanceMetadata: ReportInstanceMetadata,
    viewName?: string
  ): any {
    const reportConfigurationView =
      reportInstanceMetadata.reportConfiguration.reportConfigurationViews.find(
        (x) => viewName == null || x.viewName === viewName
      );

    if (reportConfigurationView == null) {
      throw new Error(
        'Cannot find reportConfigurationView: "' + viewName + '"'
      );
    }

    let template = JSON.parse(
      reportConfigurationView.viewTemplateConfiguration
    );

    if (typeof template === "string") {
      template = JSON.parse(template.toString().replace(/'/g, '"'));
    }

    return template;
  }

  getReportsByFimrId(eventArgs: CardFilterArguments) {
    return this.http.get<{ reportInstanceMetadatas: ReportInstanceMetadata[] }>(
      `${this.baseUrl}reportInstanceMetadatas/latest/reportCategoryId/${eventArgs.categoryId}`,
      {
        params: {
          firmId: eventArgs.firmId.replace(/\s/gi, "").toUpperCase(),
          isPublished: (eventArgs.state === "Published").toString(),
        },
      }
    );
  }

  getCurrentReportInstance() {
    return this.reportInstance;
  }

  setCurrentReportInstance(report) {
    this.reportInstance = report;
  }

  updateReportConfigurationView(
    reportConfigurationView: ReportConfigurationView
  ): any {
    return this.http.put(
      `${this.baseUrl}reportConfigurationViews/id/${reportConfigurationView.reportConfigurationViewId}`,
      reportConfigurationView,
      {
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      }
    );
  }
}
