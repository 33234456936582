import { FinraGridClickableCellComponent } from "../../components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component";
import {
  addFinraGridColumnId,
  finraGridNumericValueFormatter,
  SummaryExportData,
  buildExportDataString,
  trimTrailingChar,
} from "../../shared/utils/utils";

export const CrossMarketSupervisionCategoryId = 8;

export enum CrossMarketSupervisionReportTypeIds {
  ATS = 67, // Cross Market Equities Supervision: Potential ATS Cross Manipulation Report
  AUTO = 66, // Cross Market Equities Supervision: Potential Auto Execution Manipulation Report
  POTENTIAL = 40, // Cross Market Equities Supervision: Potential Manipulation Report
  SPOOFING = 207, // Cross Market Equities Supervision: Spoofing Report
}

export enum CrossMarketSupervisionReportShortNames {
  ATS = "xmatsmnpltn",
  AUTO = "xmautoxmnpltn",
  POTENTIAL = "xmmanipulation",
  SPOOFING = "xmspoofing",
}

export enum CrossManipulationApiUrls {
  getRelationShips = "relationships",
  getPotentialAtsCrossManipulationReport = "cross-manipulation-report",
}

// Chart
export interface Series {
  name: string;
  value: number;
}

export interface ChartData {
  name: string;
  series: Array<Series>;
}

// Grid
export interface CrossMarketEquitiesSupervisionData {
  title: string;
  month01?: number;
  month02?: number;
  month03?: number;
  month04?: number;
  month05?: number;
  month06?: number;
  chartTitle?: string;
  showDetailLink: boolean;
}

export interface CrossMarketSupervisionSummaryChartConfig {
  type: string;
  method: string;
  options: {
    title: string;
    xValues: number;
    yAxisLabel: string;
    chartType: string;
    view: string;
    yAxisTicks: number;
    autoScale: boolean;
  };
}

export interface CrossMarketSupervisionSummaryGridConfig {
  type: string;
  method: string;
  options: {
    title: string;
    columns: number;
    help: string;
    helpLink: string;
  };
}

export interface CrossMarketSupervisionViewConfig {
  title: string;
  description: string;
  disclaimer: string;
  downloadFileName: string;
  version: number;
  template: {
    component1: CrossMarketSupervisionSummaryChartConfig;
    component2: CrossMarketSupervisionSummaryGridConfig;
  };
}

export interface CrossMarketSupervisionAtsAutoSummaryData {
  exceptionRelationshipCount: number;
  exceptionCount: number;
  yearNumber: string;
  firmCrdNumber: number;
  firmCrdName: string;
  monthStartDate: string;
  monthOfYearNumber: string;
  exceptionNonRelationshipCount: number;
}

export interface CrossMarketSupervisionPotentialSummaryData {
  exceptionCount: number;
  layeringDetailDataAvailabilityFlag: string;
  yearNumber: string;
  firmCrdNumber: number;
  firmCrdName: string;
  exceptionLayeringCount: number;
  spoofingNonRelationshipCount: number;
  spoofingDetailDataAvailabilityFlag: string;
  layeringNonRelationshipCount: number;
  spoofingRelationshipCount: number;
  layeringRelationshipCount: number;
  exceptionSpoofingCount: number;
  monthStartDate: string;
  monthOfYearNumber: string;
}
export interface CrossMarketSupervisionSpoofingSummaryData {
  exceptionCount: number;
  layeringDetailDataAvailabilityFlag: string;
  yearNumber: string;
  firmCrdNumber: number;
  firmCrdName: string;
  exceptionLayeringCount: number;
  spoofingNonRelationshipCount: number;
  spoofingDetailDataAvailabilityFlag: string;
  layeringNonRelationshipCount: number;
  spoofingRelationshipCount: number;
  layeringRelationshipCount: number;
  exceptionSpoofingCount: number;
  monthStartDate: string;
  monthOfYearNumber: string;
}

export interface CrossMarketEquitiesSupervisionReport {
  firmId: string;
  reportData: Array<CrossMarketEquitiesSupervisionData>;
  reportPeriodDate: string;
}

export interface GridHeader {
  headerName: string;
  field: string;
  width: number;
  filter?: string;
  filterParams?: object;
  cellRendererFramework?: object;
  buttonLabel?: string;
  callback?: Function;
  cellRendererFrameworkOptions?: any;
  valueFormatter?: Function;
  type?: string;
}

export let CrossMarketEquitiesSupervisionGridHeader: Array<object> =
  addFinraGridColumnId([
    {
      headerName: "All Market Hours",
      cellRendererFramework: FinraGridClickableCellComponent,
      width: 200,
      cellRendererFrameworkOptions: {
        isClickable: (params: object) => params["data"].showDetailLink,
        buttonLabel: (params: object) =>
          params["data"].showDetailLink ? "DETAILS" : "",
      },
    },
    {
      headerName: null,
      field: "month01",
      width: 80,
      valueFormatter: finraGridNumericValueFormatter,
      type: "numericColumn",
    },
    {
      headerName: null,
      field: "month02",
      width: 80,
      valueFormatter: finraGridNumericValueFormatter,
      type: "numericColumn",
    },
    {
      headerName: null,
      field: "month03",
      width: 80,
      valueFormatter: finraGridNumericValueFormatter,
      type: "numericColumn",
    },
    {
      headerName: null,
      field: "month04",
      width: 80,
      valueFormatter: finraGridNumericValueFormatter,
      type: "numericColumn",
    },
    {
      headerName: null,
      field: "month05",
      width: 80,
      valueFormatter: finraGridNumericValueFormatter,
      type: "numericColumn",
    },
    {
      headerName: null,
      field: "month06",
      width: 80,
      valueFormatter: finraGridNumericValueFormatter,
      type: "numericColumn",
    },
  ]);

export function exportCrossMarketSupervisionSummaryData(
  title,
  rowData,
  columnDefs
): string {
  const exportData: SummaryExportData[] = [
    {
      title,
      rowData,
      columnLabels: columnDefs.map((i: any) => i.headerName),
      dataMappings: ["title", ...columnDefs.slice(1).map((i: any) => i.field)],
    },
  ];
  const csvData = buildExportDataString(exportData);
  const formatted = trimTrailingChar(csvData, "\n");
  return formatted;
}
