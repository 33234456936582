import { Injectable } from '@angular/core';
import { CorpFinReportShortNames } from 'src/app/configs/model/corp-fin.model';
import { CrossMarketSupervisionReportShortNames } from 'src/app/configs/model/cross-manipulation-report.model';
import { DisclosureReportShortNames } from 'src/app/configs/model/disclosure.model';
import { EquityReportShortNames } from 'src/app/configs/model/equities-report.model';
import { MsrbReportNames } from 'src/app/configs/model/msrb/msrb.model';
import { OnlineLearningReportShortNames } from 'src/app/configs/model/online-learning.model';
import { LoprReportNames } from 'src/app/configs/model/lopr/lopr.model';
import { RiskMonitoringReportNames } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import { TraceReportShortNames } from 'src/app/configs/model/trace.model';

export type ReportTypeNames =
CorpFinReportShortNames |
CrossMarketSupervisionReportShortNames |
DisclosureReportShortNames |
EquityReportShortNames |
MsrbReportNames |
OnlineLearningReportShortNames |
LoprReportNames |
RiskMonitoringReportNames |
TraceReportShortNames;

/**
 * Links found here: https://www.finra.org/compliance-tools/report-center
 */

@Injectable({
  providedIn: 'root'
})
export class HelpLinksService {
  /** Corp Fin */
  [CorpFinReportShortNames.LATE_FILING] = `https://www.finra.org/compliance-tools/report-center/corporate-financing/public-offering-late-filings-report`;
  [CorpFinReportShortNames.RULE_5110] = `https://www.finra.org/compliance-tools/report-center/corporate-financing/public-offering-rule-5110-filing`;
  [CorpFinReportShortNames.PRIVATE_PLACEMENT] = `https://www.finra.org/compliance-tools/report-center/corporate-financing/private-placements-filing-timeliness-report`;
  CORP_FIN_PRIVATE_PLACEMENT_RULES = {
    '5123': `https://www.finra.org/compliance-tools/report-center/corporate-financing/private-placements-filing-timeliness-report#1`,
    '5122': `https://www.finra.org/compliance-tools/report-center/corporate-financing/private-placements-filing-timeliness-report#3`,
  };


  /** Cross Market Supervision */
  [CrossMarketSupervisionReportShortNames.ATS] = `https://www.finra.org/compliance-tools/report-center/cross-market-equities-supervision/ATS-manipulation-report`;
  [CrossMarketSupervisionReportShortNames.AUTO] = `https://www.finra.org/compliance-tools/report-center/cross-market-equities-supervision/auto-execution-manipulation-report`;
  [CrossMarketSupervisionReportShortNames.POTENTIAL] = `https://www.finra.org/compliance-tools/report-center/cross-market-equities-supervision/potential-manipulation-report`;
  [CrossMarketSupervisionReportShortNames.SPOOFING] = `https://www.finra.org/compliance-tools/report-center/cross-market-equities-supervision/potential-manipulation-report`;


  /** Disclosure */
  [DisclosureReportShortNames.LATE_FILING] = `https://www.finra.org/compliance-tools/report-center/disclosure/crd-late-filing-fee-report-card`;
  [DisclosureReportShortNames.TIMELINESS_4530] = `https://www.finra.org/compliance-tools/report-center/disclosure/4530-disclosure-timeliness-report-card`;
  [DisclosureReportShortNames.TIMELINESS_BD] = `https://www.finra.org/compliance-tools/report-center/disclosure/crd-timeliness-report-card`;


  /** Equity */
  [EquityReportShortNames.BEST_EXECUTION_OUTSIDE_OF_THE_INSIDE] = `https://www.finra.org/compliance-tools/report-center/equity/best-execution-outside-inside-report-card`;
  [EquityReportShortNames.CONTRA_EXECUTING_FIRM_20_MINUTE_COMPLIANCE] = `https://www.finra.org/compliance-tools/report-center/equity/contra-executing-firm-20-second-compliance-report-card`;
  [EquityReportShortNames.CONTRA_REPORTING_FIRM_20_MINUTE_COMPLIANCE] = `https://www.finra.org/compliance-tools/report-center/equity/contra-reporting-firm-20-second-compliance-report-card`;
  [EquityReportShortNames.EXECUTING_FIRM_10_SECOND_COMPLIANCE] = `https://www.finra.org/compliance-tools/report-center/equity/executing-firm-10-second-compliance`;
  [EquityReportShortNames.FIRM_SUMMARY_SCORECARD] = `https://www.finra.org/compliance-tools/report-center/equity/firm-summary-scorecard`;
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL] = `https://www.finra.org/compliance-tools/report-center/equity/market-order-timeliness-statistical-report`;
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS] = `https://www.finra.org/compliance-tools/report-center/equity/market-order-timeliness-report-card`;
  [EquityReportShortNames.OATS_COMPLIANCE_MONTHLY] = `https://www.finra.org/compliance-tools/report-center/equity/oats-compliance-monthly-report-card`;
  [EquityReportShortNames.REG_NMS_TRADE_THROUGH] = `https://www.finra.org/compliance-tools/report-center/equity/reg-nms-trade-through-report-card`;
  [EquityReportShortNames.REPORTING_FIRM_10_SECOND_COMPLIANCE] = `https://www.finra.org/compliance-tools/report-center/equity/reporting-firm-10-second-compliance`;


  /** File and Data Quality Scorecard (FQS/DQS) */
  FQS_DQS = `https://www.finra.org/sites/default/files/external_apps/stockrecord.html`;


  /** MSRB */
  [MsrbReportNames.CONTINUING_DISCLOSURE] = `https://www.finra.org/compliance-tools/report-center/msrb/continuing-disclosure-report`;
  [MsrbReportNames.DUE_DILIGENCE_SUMMARY] = `https://www.finra.org/compliance-tools/report-center/msrb/due-diligence-report-card`;
  [MsrbReportNames.G_32_UNDERWRITING] = `https://www.finra.org/compliance-tools/report-center/msrb/g32-report-card`;
  [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS] = `https://www.finra.org/compliance-tools/report-center/msrb/markup-markdown-analysis-report`;
  [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE] = `https://www.finra.org/compliance-tools/report-center/msrb/primary-offering-disclosure-report`;
  [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION] = `https://www.finra.org/compliance-tools/report-center/msrb/municipal-trades-below-minimum-denomination-report`;
  [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS] = `https://www.finra.org/compliance-tools/report-center/msrb/underwriter-financial-status-report`;


  /** Online Learning */
  [OnlineLearningReportShortNames.CE_REGULATORY_ELEMENT] = `https://www.finra.org/compliance-tools/report-center/continuing-education-regulatory-element`;


  /** Options (Large Options Positions Report (LOPR)) */
  [LoprReportNames.INTRUSION_DETECTION] = `https://www.finra.org/compliance-tools/report-center/options/cross-market-options-supervision`;
  [LoprReportNames.LISTED] = `https://www.finra.org/compliance-tools/report-center/options/lopr-listed`;
  [LoprReportNames.OVER_THE_COUNTER] = `https://www.finra.org/compliance-tools/report-center/options/lopr-otc`;


  /** Risk Monitoring */
  [RiskMonitoringReportNames.CANCELED_AS_OF_TRADES] = `https://www.finra.org/compliance-tools/report-center/risk-monitoring/canceled-as-of-trades-reports`;
  [RiskMonitoringReportNames.CUSTOMER_COMPLAINT] = `https://www.finra.org/compliance-tools/report-center/risk-monitoring/customer-compliant-report`;
  [RiskMonitoringReportNames.CUSTOMER_DEBITS] = `https://www.finra.org/compliance-tools/report-center/risk-monitoring/customer-debits-report`;
  [RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES] = `https://www.finra.org/compliance-tools/report-center/risk-monitoring/registered-representative-composition-report`;
  [RiskMonitoringReportNames.SALES_PRACTICE_COMPLAINT] = `https://www.finra.org/compliance-tools/report-center/risk-monitoring/sales-practice-complaint-report`;


  /** TRACE */
  [TraceReportShortNames.AGENCY_DEBT] = `https://www.finra.org/compliance-tools/report-center/trace/quality-of-markets-corporate-bond-agency-debt`;
  [TraceReportShortNames.CORPORATE_BONDS] = `https://www.finra.org/compliance-tools/report-center/trace/quality-of-markets-corporate-bond-agency-debt`;
  [TraceReportShortNames.MARKUP_MARKDOWN] = `https://www.finra.org/compliance-tools/report-center/trace/markup-markdown-analysis-report`;
  [TraceReportShortNames.SECURITIZED_PRODUCTS] = `https://www.finra.org/compliance-tools/report-center/trace/quality-of-markets-securitized-products`;
  [TraceReportShortNames.TIMELINESS] = `https://www.finra.org/compliance-tools/report-center/trace/treasuries-execution-time-difference`;
  [TraceReportShortNames.TREASURIES] = `https://www.finra.org/compliance-tools/report-center/trace/quality-of-markets-treasuries`;
  [TraceReportShortNames.FOREIGN_SOVEREIGN] = `https://www.finra.org/compliance-tools/report-center/trace/foreign-sovereign-debt`;

  TRACE_PEER_GROUP = {
    // https://jira.finra.org/browse/DDWA-7471
    [TraceReportShortNames.AGENCY_DEBT]: `https://www.finra.org/compliance-tools/report-center/trace/quality-of-markets-corporate-bond-agency-debt#agency-debt`,
    [TraceReportShortNames.CORPORATE_BONDS]: `https://www.finra.org/compliance-tools/report-center/trace/quality-of-markets-corporate-bond-agency-debt#trace-reporting-peer-group`,
    [TraceReportShortNames.SECURITIZED_PRODUCTS]: `https://www.finra.org/compliance-tools/report-center/trace/quality-of-markets-securitized-products#securitized-products-peer-groups`,
    [TraceReportShortNames.TREASURIES]: `https://www.finra.org/compliance-tools/report-center/trace/quality-of-markets-treasuries#trace-reporting-peer-group`,
    [TraceReportShortNames.FOREIGN_SOVEREIGN]: `https://www.finra.org/compliance-tools/report-center/trace/foreign-sovereign-debt#trace-reporting-peer-group`,
  };

  constructor() {}

  open(reportName: string) {
    const link = this[reportName];
    if (!link) {
      // `Unknown reportName: ${link}`
      console.log(`Unknown reportName: ${reportName}`, this);
      return;
    }
    window.open(link, '_blank');
  }
}
