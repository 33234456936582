<section id="trades-grid-div-container">
  <div id="trades-grid-div-container-inner" class="scroll-x-box">
    <div class="table-header">
      <div class="table-title">
        {{ title }}
      </div>

      <span class="question-icon-container" (click)="openHelp()">
        <fa-icon
          data-html2canvas-ignore
          [icon]="faQuestionCircle"
          class="fa-lg question-icon"
        ></fa-icon>
        <span>Help</span>
      </span>
    </div>

    <div id="trades-data-container">
      <!-- header -->
      <div class="row-header row-container grey-border-bottom-1">
        <div class="col col-15 grey-border-right-1">
          <div class="box-padding-1">&nbsp;</div>
        </div>
        <div class="col col-45 grey-border-right-1">
          <div class="box-padding-1">Firm</div>
        </div>
        <div class="col col-40">
          <div class="box-padding-1">Industry</div>
        </div>
      </div>

      <div class="row-header row-container grey-border-bottom-2">
        <div class="col col-15 grey-border-right-1">
          <div class="box-padding-1">Period</div>
        </div>
        <!-- firm -->
        <div class="col" [ngClass]="{ 'col-25': isAllTrades, 'col-10': !isAllTrades }">
          <div class="box-padding-1">Count</div>
        </div>
        <div class="col col-15" *ngIf="!isAllTrades">
          <div class="box-padding-1">% of Trades</div>
        </div>
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1">% Change from Prior Period</div>
        </div>
        <!-- industry -->
        <div class="col" [ngClass]="{ 'col-25': isAllTrades, 'col-10': !isAllTrades }">
          <div class="box-padding-1">Count</div>
        </div>
        <div class="col col-15" *ngIf="!isAllTrades">
          <div class="box-padding-1">% of Trades</div>
        </div>
        <div class="col col-15">
          <div class="box-padding-1">% Change from Prior Period</div>
        </div>
      </div>

      <ng-container *ngFor="let summary of summaryData; last as isLast; index as i">
        <div class="month-data-row row-container row-body"
          [attr.data-row-number]="i + 1"
          [ngClass]="{
            'grey-border-bottom-1': !isLast,
            'blue-highlight-bg': !!summary.expanded
          }"
        >
          <!-- period -->
          <div class="col col-15">
            <div class="box-padding-1 period-flex-row">
              <div>
                <span class="icon-space-1">
                  <fa-icon
                    *ngIf="summary.detailsData && !!summary.detailsData.length"
                    [icon]="summary.expanded ? faChevronDown : faChevronRight"
                    [attr.title]="(summary.expanded ? 'Hide' : 'Show')"
                    class="fa-lg cursor-pointer summary-download-chevron"
                    data-html2canvas-ignore
                    (click)="summary.expanded = !summary.expanded"
                  ></fa-icon>
                </span>
                {{ summary.monthYear }}
              </div>
              <div>
                <fa-icon
                  *ngIf="summary.detailsData && !!summary.detailsData.length"
                  [icon]="faDownload"
                  [attr.title]="'Download'"
                  class="fa-lg dialog-tools-icon controls-btn-blue cursor-pointer"
                  data-html2canvas-ignore
                  (click)="exportMonthDetails(summary)"
                ></fa-icon>
              </div>
            </div>
          </div>

          <ng-container [ngSwitch]="!!summary.detailsData">
            <ng-container *ngSwitchCase="true" [ngSwitch]="!!summary.detailsData.length">
              <ng-container *ngSwitchCase="true">
                <!-- firm -->
                <div class="month-data-col col" [ngClass]="{ 'col-25': isAllTrades, 'col-10': !isAllTrades }">
                  <div class="box-padding-1 col-firm-total-count">{{ summary.firm.totalCount !== null && (summary.firm.totalCount | round | number) || '*' }}</div>
                </div>
                <div class="col col-15 month-data-col" *ngIf="!isAllTrades">
                  <div class="box-padding-1 col-firm-percent-of-trades">{{ summary.firm.percentageOfTrades !== null && ((summary.firm.percentageOfTrades | number:'1.2-2') + '%') || '*' }}</div>
                </div>
                <div class="col col-20 month-data-col">
                  <div class="box-padding-1 col-firm-percent-change">{{ summary.firm.percentageChangeFromPrior !== null && ((summary.firm.percentageChangeFromPrior | number:'1.2-2') + '%') || '*' }}</div>
                </div>
                <!-- industry -->
                <div class="col month-data-col" [ngClass]="{ 'col-25': isAllTrades, 'col-10': !isAllTrades }">
                  <div class="box-padding-1 col-ind-total-count">{{ summary.industry.totalCount !== null && (summary.industry.totalCount | number) || '*' }}</div>
                </div>
                <div class="col col-15 month-data-col" *ngIf="!isAllTrades">
                  <div class="box-padding-1 col-ind-percent-of-trades">{{ summary.industry.percentageOfTrades !== null && ((summary.industry.percentageOfTrades | number:'1.2-2') + '%') || '*' }}</div>
                </div>
                <div class="col col-15 month-data-col">
                  <div class="box-padding-1 col-ind-percent-change">{{ summary.industry.percentageChangeFromPrior !== null && ((summary.industry.percentageChangeFromPrior | number:'1.2-2') + '%') || '*' }}</div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <div class="month-data-col" [ngClass]="{ 'col-85': isAllTrades, 'col-90': !isAllTrades }">
                  <div class="box-padding-1">No information available for the period.</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <div class="month-data-col col col-85">
                <div class="box-padding-1">Loading...</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <!-- Details Row -->
        <ng-container *ngIf="summary.expanded && (summary.detailsData && !!summary.detailsData.length)">
          <div class="day-data-row row-container row-header grey-border-bottom-1">
            <div class="col-15 grey-border-right-1">
              <div class="box-padding-1">&nbsp;</div>
            </div>
            <div class="col-15">
              <div class="box-padding-1">Total</div>
            </div>
            <div class="col-15">
              <div class="box-padding-1">Exchange</div>
            </div>
            <div class="col-15">
              <div class="box-padding-1 grey-border-right-1">Non-Exchange</div>
            </div>
          </div>
          <div *ngFor="let detail of summary.detailsData; last as lastDetail"
            class="summary-data-row row-container row-body grey-border-bottom-1">
            <div class="col col-15 grey-border-right-1 day-data-row">
              <div class="box-padding-1 col-processed-date">{{ detail.processedDate }}</div>
            </div>
            <div class="col col-15 day-data-row">
              <div class="box-padding-1 col-total">{{ detail.total !== null && (detail.total | number) || '*' }}</div>
            </div>
            <div class="col col-15 day-data-row">
              <div class="box-padding-1 col-exchange">{{ detail.exchange !== null && (detail.exchange | number) || '*' }}</div>
            </div>
            <div class="col col-15 grey-border-right-1 day-data-row">
              <div class="box-padding-1 col-nonexchange">{{ detail.nonExchange !== null && (detail.nonExchange | number) || '*' }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
