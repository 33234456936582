import { Injectable } from '@angular/core';
import {
  buildExportDataString,
  buildColumnLabelsString,
  buildRowDataString,
  trimTrailingChar,
  finraGridMonthDateFormatter,
  finraGridIntegerPercentValueFormatter,
  SummaryExportData,
  finraGridPercentMultipliedValueFormatter,
  addFinraGridColumnId,
  finraFormatValidValue,
  percentMultipliedValueFormatter,
  ForceNumberType
} from '../shared/utils/utils';
import {
  TraceReportShortNames,
} from 'src/app/configs/model/trace.model';
import {
  TRACE_TIMELINESS_ATS_TIME_DIFFERENCES_DATA_MAPPINGS,
  TRACE_TIMELINESS_NON_ATS_TIME_DIFFERENCES_DATA_MAPPINGS,
  TRACE_TIMELINESS_AVERAGE_EXECUTION_TIME_DIFFERENCE_MAPPINGS,
  TRACE_TIMELINESS_EXECUTION_TIME_DIFFERENCE_MAPPINGS,
  TraceTimelinessTableDataMappingDef,
  TRACE_TIMELINESS_ATS_AND_NON_ATS_COLUMN_MAPPINGS,
  TRACE_TIMELINESS_AVERAGE_COLUMN_MAPPINGS,
  TRACE_TIMELINESS_EXECUTION_COLUMN_MAPPINGS,
  TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING,
  timeDiffValueTransformer,
  TraceTimelinessGridTimeDiffValueTransformer,
  TraceTimelinessSummaryResponse,
} from 'src/app/configs/model/trace-timeliness.model';

@Injectable({
  providedIn: 'root'
})
export class TraceService {
  constructor() {}

  addLateTradeDataCondition(
    data: { [key: string]: object[] },
    reportName: string
  ) {
    const isSecuritizedProducts = (
      reportName === TraceReportShortNames.SECURITIZED_PRODUCTS
    );

    const updateTotalColumn = (x: object) => {
      const noAveragePercent = !('IndicatorAveragePercent' in x);
      if (noAveragePercent) {
        x['isTotalColumn'] = true;
      }
    };
    const updateFootNote = (x: object) => {
      const isLateSameDayAndNotGroupAffiliate = (
        x['displayName'].indexOf('Late Same Day') > -1 &&
        x['group'] !== 'Affiliate'
      );
      if (isLateSameDayAndNotGroupAffiliate) {
        x['hasFootNote'] = true;
      }
    };

    // DDWA-3124 Adding <sup>1</sup> to displayName to refer to disclaimer footnote
    if (isSecuritizedProducts) {
      data['lateTrade'].map((x: object) => {
        updateTotalColumn(x);
        updateFootNote(x);
      });
    } else {
      data['lateTrade'].map((x: object) => {
        updateTotalColumn(x);
      });
    }
    return data;
  }

  /** TRACE Timeliness methods */

  formatTimelinessTableData(
    data: TraceTimelinessSummaryResponse,
    mappingsDef: TraceTimelinessTableDataMappingDef[],
    columnMappings: Array<string>,
    isFirmData: boolean,
    givenValueFormatter?
  ) {
    const periodsLength = data[0].values.length;
    const rowData = [];
    const columnDefs = [];
    const mappingsDefLen = mappingsDef.length;

    for (let m = 0; m < mappingsDefLen; m++) {
      const mappingObj = mappingsDef[m];
      const dataObj: any = {
        displayName: mappingObj.displayName
      };

      const isFirstRowData = m === 0;
      if (isFirstRowData) {
        columnDefs.push({
          headerName: '',
          field: 'displayName'
        });
      }

      let columnIndex = 0;
      for (let i = 0; i < periodsLength; i++) {
        const infoObj = isFirmData ? data[0].values[i] : data[1].values[i];
        const infoData = infoObj.data;
        const propsByPeriod = mappingObj.mappingsByPeriod[i];
        const propsByPeriodLen = propsByPeriod.length;
        const columnDef: any = isFirstRowData
          ? {
              headerName: finraGridMonthDateFormatter({ value: infoObj.partition }),
              children: []
            }
          : null;
        if (columnDef) {
          columnDefs.push(columnDef);
        }
        for (let p = 0; p < propsByPeriodLen; p++) {
          const mappingProp = propsByPeriod[p];
          const isCount = mappingProp.toLowerCase().includes('count');
          const isPercent = mappingProp.toLowerCase().includes('percent');
          const useForceType: ForceNumberType = isCount ? 'int' : isPercent ? 'float' : null;
          const shouldUseNumberFormatter = (isCount || isPercent);
          const useValueFormatter = givenValueFormatter
            ? givenValueFormatter
            : shouldUseNumberFormatter
              ? (
                  isPercent
                    ? finraGridPercentMultipliedValueFormatter
                    : (params) => finraGridIntegerPercentValueFormatter(params, useForceType)
                )
              : null;
          const mappingKey = `column${columnIndex}mapping`;
          const valueKey = `column${columnIndex}value`;
          const value = infoData[mappingProp];
          const useValue = useValueFormatter
            ? useValueFormatter({ value }, useForceType)
            : finraFormatValidValue(value);
          dataObj[mappingKey] = mappingProp;
          dataObj[valueKey] = useValue;
          if (columnDef) {
            const childColumnDef = {
              headerName: columnMappings[columnIndex],
              field: valueKey,
            };
            columnDef.children.push(childColumnDef);
          }
          columnIndex = columnIndex + 1;
        }
      }
      dataObj.columns = columnIndex;
      rowData.push(dataObj);
    }

    return {
      rowData,
      columnDefs: <any[]> addFinraGridColumnId(columnDefs)
    };
  }

  formatTimelinessChartData(data) {
    const createLineChartData = (dataName, dataProp, dataList, transform?: (arg: any) => number) => {
      const chartData = {
        name: dataName,
        series: dataList.map(d => {
          const formattedName = finraGridMonthDateFormatter({ value: d.partition });
          const value = d.data[dataProp];
          const transformedValue = transform
            ? transform(value)
            : (percentMultipliedValueFormatter({ value }, null) || 0.0);
          const useValue = (parseFloat(transformedValue) || 0.0);
          return {
            name: formattedName,
            value: useValue,
          };
        })
      };
      return chartData;
    };

    const atsChartData = [
      createLineChartData(
        'Firm',
        'ATSExceptionPercent',
        data[0].values,
      ),
      createLineChartData(
        'Peer Average',
        'peerATSExceptionAveragePercent',
        data[0].values,
      ),
      createLineChartData(
        'Industry Average',
        'averageATSExceptionPercent',
        data[0].values,
      ),
    ];

    const nonAtsChartData = [
      createLineChartData(
        'Firm',
        'nonATSExceptionPercent',
        data[0].values,
      ),
      createLineChartData(
        'Peer Average',
        'peerNonATSExceptionAveragePercent',
        data[0].values,
      ),
      createLineChartData(
        'Industry Average',
        'averageNonATSExceptionPercent',
        data[0].values,
      ),
    ];

    const averageChartData = [
      createLineChartData(
        'All Trades Types',
        'averageTradeExecutionTimeDiff',
        data[1].values,
        timeDiffValueTransformer
      ),
      createLineChartData(
        'ATS Trades',
        'averageATSTradeExecutionTimeDiff',
        data[1].values,
        timeDiffValueTransformer
      ),
      createLineChartData(
        'Non-ATS Trades',
        'averageNonATSTradeExecutionTimeDiff',
        data[1].values,
        timeDiffValueTransformer
      ),
    ];

    const execChartData = [
      createLineChartData(
        '>10 min to 15min',
        'tradeExecutionTimeDifference900Percent',
        data[1].values,
      ),
      createLineChartData(
        '>5 min to 10min',
        'tradeExecutionTimeDifference600Percent',
        data[1].values,
      ),
      createLineChartData(
        '>1 min to 5min',
        'tradeExecutionTimeDifference300Percent',
        data[1].values,
      ),
      createLineChartData(
        '>30 to 1min',
        'tradeExecutionTimeDifference60Percent',
        data[1].values,
      ),
      createLineChartData(
        '≤30 seconds',
        'tradeExecutionTimeDifference30Percent',
        data[1].values,
      ),
      createLineChartData(
        'No Time Difference',
        'tradeExecutionTimeDifference0Percent',
        data[1].values,
      ),
    ];

    return {
      ats: atsChartData,
      nonAts: nonAtsChartData,
      avg: averageChartData,
      exec: execChartData
    };
  }

  formatTraceTimelinessResponse(data: TraceTimelinessSummaryResponse) {
    const atsData = this.formatTimelinessTableData(
      data,
      TRACE_TIMELINESS_ATS_TIME_DIFFERENCES_DATA_MAPPINGS,
      TRACE_TIMELINESS_ATS_AND_NON_ATS_COLUMN_MAPPINGS,
      true
    );
    const nonAtsData = this.formatTimelinessTableData(
      data,
      TRACE_TIMELINESS_NON_ATS_TIME_DIFFERENCES_DATA_MAPPINGS,
      TRACE_TIMELINESS_ATS_AND_NON_ATS_COLUMN_MAPPINGS,
      true
    );
    const avgAtsData = this.formatTimelinessTableData(
      data,
      TRACE_TIMELINESS_AVERAGE_EXECUTION_TIME_DIFFERENCE_MAPPINGS,
      TRACE_TIMELINESS_AVERAGE_COLUMN_MAPPINGS,
      false,
      TraceTimelinessGridTimeDiffValueTransformer
    );
    const execData = this.formatTimelinessTableData(
      data,
      TRACE_TIMELINESS_EXECUTION_TIME_DIFFERENCE_MAPPINGS,
      TRACE_TIMELINESS_EXECUTION_COLUMN_MAPPINGS,
      false
    );

    // limit the width of the first period colum for exec
    const widthOptions = { minWidth: 238, maxWidth: 238 };
    execData.columnDefs[1].children.forEach(child => { Object.assign(child, widthOptions); });

    Object.assign(atsData.columnDefs[0], { minWidth: 165, maxWidth: 165 });
    Object.assign(atsData.columnDefs[1].children[0], { minWidth: 125, maxWidth: 125 });
    Object.assign(atsData.columnDefs[1].children[1], { minWidth: 125, maxWidth: 125 });
    Object.assign(atsData.columnDefs[1].children[2], { minWidth: 195, maxWidth: 195 });

    Object.assign(nonAtsData.columnDefs[0], { minWidth: 165, maxWidth: 165 });
    Object.assign(nonAtsData.columnDefs[1].children[0], { minWidth: 125, maxWidth: 125 });
    Object.assign(nonAtsData.columnDefs[1].children[1], { minWidth: 125, maxWidth: 125 });
    Object.assign(nonAtsData.columnDefs[1].children[2], { minWidth: 195, maxWidth: 195 });

    // make first period text, with many children, centered
    atsData.columnDefs[1].headerClass = 'ag-header-cell-label-text-center';
    nonAtsData.columnDefs[1].headerClass = 'ag-header-cell-label-text-center';
    avgAtsData.columnDefs[1].headerClass = 'ag-header-cell-label-text-center';
    execData.columnDefs[1].headerClass = 'ag-header-cell-label-text-center';

    const chartsData = this.formatTimelinessChartData(data);

    return {
      rowData: {
        ats: atsData.rowData,
        nonAts: nonAtsData.rowData,
        avg: avgAtsData.rowData,
        exec: execData.rowData,
      },
      chartData: {
        ats: chartsData.ats,
        nonAts: chartsData.nonAts,
        avg: chartsData.avg,
        exec: chartsData.exec,
      },
      columnDefs: {
        ats: atsData.columnDefs,
        nonAts: nonAtsData.columnDefs,
        avg: avgAtsData.columnDefs,
        exec: execData.columnDefs,
      },
    };
  }

  exportTimelinessCsv(rowData, columnDef) {
    const exportConfig: SummaryExportData[] = [
      {
        title: TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING.ats,
        columnLabels: [
          [
            '',
            columnDef.ats[1].headerName,
            '',
            '',
            columnDef.ats[2].headerName,
            columnDef.ats[3].headerName,
            columnDef.ats[4].headerName,
            columnDef.ats[5].headerName,
            columnDef.ats[6].headerName,
          ],
          [
            '',
            ...TRACE_TIMELINESS_ATS_AND_NON_ATS_COLUMN_MAPPINGS
          ],
        ],
        rowData: rowData.ats,
        dataMappings: [
          'displayName',
          ...Array(rowData.ats[0].columns).fill(0).map((k, i) => `column${i}value`)
        ]
      },
      {
        title: TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING.nonAts,
        columnLabels: [
          [
            '',
            columnDef.nonAts[1].headerName,
            '',
            '',
            columnDef.nonAts[2].headerName,
            columnDef.nonAts[3].headerName,
            columnDef.nonAts[4].headerName,
            columnDef.nonAts[5].headerName,
            columnDef.nonAts[6].headerName,
          ],
          [
            '',
            ...TRACE_TIMELINESS_ATS_AND_NON_ATS_COLUMN_MAPPINGS
          ],
        ],
        rowData: rowData.nonAts,
        dataMappings: [
          'displayName',
          ...Array(rowData.nonAts[0].columns).fill(0).map((k, i) => `column${i}value`)
        ]
      },
      {
        title: TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING.avg,
        columnLabels: [
          [
            '',
            columnDef.avg[1].headerName,
            columnDef.avg[2].headerName,
            columnDef.avg[3].headerName,
            columnDef.avg[4].headerName,
            columnDef.avg[5].headerName,
            columnDef.avg[6].headerName,
          ],
          [
            '',
            ...TRACE_TIMELINESS_AVERAGE_COLUMN_MAPPINGS
          ],
        ],
        rowData: rowData.avg,
        dataMappings: [
          'displayName',
          ...Array(rowData.avg[0].columns).fill(0).map((k, i) => `column${i}value`)
        ]
      },
      {
        title: TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING.exec,
        columnLabels: [
          [
            '',
            columnDef.exec[1].headerName,
            '',
            '',
            columnDef.exec[2].headerName,
            columnDef.exec[3].headerName,
            columnDef.exec[4].headerName,
            columnDef.exec[5].headerName,
            columnDef.exec[6].headerName,
          ],
          [
            '',
            ...TRACE_TIMELINESS_EXECUTION_COLUMN_MAPPINGS
          ],
        ],
        rowData: rowData.exec,
        dataMappings: [
          'displayName',
          ...Array(rowData.exec[0].columns).fill(0).map((k, i) => `column${i}value`)
        ]
      },
    ];
    const csvString = buildExportDataString(exportConfig);
    const formattedCsv = trimTrailingChar(csvString, '\n');
    return formattedCsv;
  }

  /** END TRACE timeliness methods */

  /**
   * Export CSV
   * ---
   *
   * This file may seem long but that is because there are a lot of row groups
   * and the CSV string is being build manually since the data structure and the layout is complex.
   *
   * Each of the tables has their own method of building the CSV data. This method
   * simply consolidates all of them together as one and exports the CSV.
   *
   * The `HelperService` has methods to help with building the CSV.
   * The idea is that there are columns and rows (normally).
   * The placement of the columns depends on how many elements are in the array,
   * similar to how <thead> and <th> markup works.
   *
   * @param rowData the row data set in the `trace.component.ts`
   * @param peerGroupData the peer group data that shows up in the modal
   * @return {void}
   */
  exportCsv(reportName, rowData, peerGroupData?, columnDefs?): string {
    if (reportName === TraceReportShortNames.TIMELINESS) {
      return this.exportTimelinessCsv(rowData, columnDefs);
    }

    const activityTotalsCsvString = this.buildActivityTotalsCsvString(rowData);
    const peerGroupCsvString = this.buildPeerGroupCsvData(rowData, peerGroupData);
    const lateTradesCsvString = this.buildLateTradesCsvData(rowData);
    const tradeReportingAnalysisCsvString = this.buildTradeReportingAnalysis(rowData);

    const lineSpace = '\n\n\n';
    const massExportString = [
      activityTotalsCsvString,
      lineSpace,
      peerGroupCsvString,
      lineSpace,
      lateTradesCsvString,
      lineSpace,
      tradeReportingAnalysisCsvString,
    ]
    .join('');

    const formattedCsv = trimTrailingChar(massExportString, '\n');
    return formattedCsv;
  }

  buildActivityTotalsCsvString(rowData) {
    let massExportString = '';
    const activityTotals = rowData['activityTotal'];

    const rowGroupDataMappings = [
      '',
      'displayName',
      'ReportEntryCount',
      'ValidReportCount',
      'PriorMonthReportEntryCount',
      'PriorMonthValidReportCount',
    ];
    const nonRowGroupDataMappings = [
      'displayName',
      '',
      'ReportEntryCount',
      'ValidReportCount',
      'PriorMonthReportEntryCount',
      'PriorMonthValidReportCount',
    ];

    massExportString += `Activity Totals\n`;
    const priorMonthHeadingsRow = buildColumnLabelsString([
      '',
      '',
      '',
      '',
      'Prior Month',
      '',
    ]);
    massExportString += priorMonthHeadingsRow;
    const totalsHeadingsRow = buildColumnLabelsString([
      '',
      '',
      'Total Trade Report Entries',
      'Total Valid Trade Reports',
      'Total Trade Report Entries',
      'Total Valid Trade Reports',
    ]);
    massExportString += totalsHeadingsRow;

    const customerTrade = activityTotals.find(c => c.fieldName === 'CustomerTrade');
    const customerTradeRowDataString = buildRowDataString({
      rowData: [customerTrade],
      dataMappings: nonRowGroupDataMappings,
    });
    massExportString += customerTradeRowDataString;

    const interDealerTradesHeadingsRow = buildColumnLabelsString([
      'Inter-Dealer Trades',
    ]);
    massExportString += interDealerTradesHeadingsRow;
    const interDealerTradesRowDataString = this.getRowGroupCsvData(
      activityTotals,
      'group',
      'interDealer',
      rowGroupDataMappings
    );
    massExportString += interDealerTradesRowDataString;

    const lockedInTradesHeadingsRow = buildColumnLabelsString([
      'Locked-In Trades',
    ]);
    massExportString += lockedInTradesHeadingsRow;
    const lockedInTradesRowDataString = this.getRowGroupCsvData(
      activityTotals,
      'group',
      'lockedIntrade',
      rowGroupDataMappings
    );
    massExportString += lockedInTradesRowDataString;

    const affiliateTrade = activityTotals.find(c => c.fieldName === 'AffiliateTrade');
    const affiliateTradeRowDataString = buildRowDataString({
      rowData: [affiliateTrade],
      dataMappings: nonRowGroupDataMappings,
    });
    massExportString += affiliateTradeRowDataString;

    const totalTrades = activityTotals.find(c => c.fieldName === 'ReportedTrade');
    const totalTradesRowDataString = buildRowDataString({
      rowData: [totalTrades],
      dataMappings: nonRowGroupDataMappings,
    });
    massExportString += totalTradesRowDataString;

    return massExportString;
  }

  buildPeerGroupCsvData(rowData, peerGroupData?) {
    let massExportString = '';

    massExportString += `TRACE Reporting Peer Group (Total Trades)\n`;
    const peerGroupSummaryHeadingsRow = buildColumnLabelsString([
      'Number of Firms in Peer Group',
      'Rank in Peer Group',
    ]);
    massExportString += peerGroupSummaryHeadingsRow;

    const peerGroupTotals = rowData['tradeReportingPeerGroup'][0];
    const peerGroupTotalsRowDataString = buildRowDataString({
      rowData: [peerGroupTotals],
      dataMappings: [
        'TotalNumberFirmsInCount',
        'TotalRankInNumber',
      ],
    });
    massExportString += peerGroupTotalsRowDataString;

    // if no peer group data (the data that pops up in the modal)
    if (!peerGroupData) {
      return massExportString;
    }

    const peerGroupFirmsHeadingsRow = buildColumnLabelsString([
      'Trace MPID',
      'Firm Name',
    ]);
    massExportString += peerGroupFirmsHeadingsRow;

    const peerGroupFirmsRowDataString = buildRowDataString({
      rowData: peerGroupData,
      dataMappings: [
        'equityMpid',
        'firmName',
      ],
    });
    massExportString += peerGroupFirmsRowDataString;

    return massExportString;
  }

  buildLateTradesCsvData(rowData) {
    let massExportString = '';
    const lateTrades = rowData['lateTrade'];

    const rowGroupDataMappings = [
      '',
      'displayName',
      'Count',
      'FirmPercent',
      'IndicatorAveragePercent',
      'PeerAveragePercent',
      'RankInPeerGroupNumber',
      'PriorMonthCount',
      'PriorMonthCountPercentChange',
    ];

    massExportString += `Late Trades\n`;

    const priorMonthHeadingsRow = buildColumnLabelsString([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Prior Month',
      '',
    ]);
    massExportString += priorMonthHeadingsRow;

    const totalsHeadingsRow = buildColumnLabelsString([
      '',
      '',
      'Trade Count',
      'Firm %',
      'Industry Average %',
      'Peer Average %',
      'Rank In Peer Group',
      'Trade Count',
      '% Change',
    ]);
    massExportString += totalsHeadingsRow;

    const customerTradeHeadingsRow = buildColumnLabelsString([
      'Customer Trades',
    ]);
    massExportString += customerTradeHeadingsRow;
    const customerTradesRowDataString = this.getRowGroupCsvData(
      lateTrades,
      'group',
      'Customer',
      rowGroupDataMappings
    );
    massExportString += customerTradesRowDataString;

    const interDealerTradesHeadingsRow = buildColumnLabelsString([
      'Inter-Dealer Trades',
    ]);
    massExportString += interDealerTradesHeadingsRow;
    const interDealerTradesRowDataString = this.getRowGroupCsvData(
      lateTrades,
      'group',
      'InterDealer',
      rowGroupDataMappings
    );
    massExportString += interDealerTradesRowDataString;

    const lockedInTradesHeadingsRow = buildColumnLabelsString([
      'Locked-In Trades',
    ]);
    massExportString += lockedInTradesHeadingsRow;
    const lockedInTradesRowDataString = this.getRowGroupCsvData(
      lateTrades,
      'group',
      'LockedIn',
      rowGroupDataMappings
    );
    massExportString += lockedInTradesRowDataString;

    const affiliateTradesHeadingsRow = buildColumnLabelsString([
      'Affiliate Trades',
    ]);
    massExportString += affiliateTradesHeadingsRow;
    const affiliateTradesRowDataString = this.getRowGroupCsvData(
      lateTrades,
      'group',
      'Affiliate',
      rowGroupDataMappings
    );
    massExportString += affiliateTradesRowDataString;

    const allTradesHeadingsRow = buildColumnLabelsString([
      'All Trades',
    ]);
    massExportString += allTradesHeadingsRow;
    const allTradesRowDataString = this.getRowGroupCsvData(
      lateTrades,
      'group',
      'All',
      rowGroupDataMappings
    );
    massExportString += allTradesRowDataString;

    return massExportString;
  }

  buildTradeReportingAnalysis(rowData) {
    const tradeReportingAnalysis = rowData['tradeReportingAnalysis'];
    const massExportStringsList = [];

    /** Build Header Strings */

    const rowGroupDataMappings = [
      '',
      'displayName',
      'Count',
      'FirmPercent',
      'PeerAveragePercent',
      'RankInPeerGroupNumber',
      'PriorMonthCount',
      'PriorMonthCountPercentChange',
    ];
    const tableTitle = `Trade Reporting Analysis\n`;
    const priorMonthHeadingsRow = buildColumnLabelsString([
      '',
      '',
      '',
      '',
      '',
      '',
      'Prior Month',
      '',
    ]);
    const totalsHeadingsRow = buildColumnLabelsString([
      '',
      '',
      'Trade Count',
      'Firm %',
      'Peer Average %',
      'Rank In Peer Group',
      'Trade Count',
      '% Change',
    ]);

    /** Build Data Strings */

    const executionTimeHeadingsRow = buildColumnLabelsString([
      'Execution Time Reporting',
    ]);
    const executionTimeRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'executionTime',
      rowGroupDataMappings
    );

    const amendedTradeReportsHeadingsRow = buildColumnLabelsString([
      'Amended Trade Reports',
    ]);
    const amendedTradeReportsRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'amendedTradeReports',
      rowGroupDataMappings
    );

    const pricingHeadingsRow = buildColumnLabelsString([
      'Pricing',
    ]);
    const pricingRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'pricing',
      rowGroupDataMappings
    );

    const interDealerHeadingsRow = buildColumnLabelsString([
      'Inter-Dealer Difference',
    ]);
    const interDealerRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'interDealer',
      rowGroupDataMappings
    );

    const executionTimeDifferenceHeadingsRow = buildColumnLabelsString([
      'Execution Time Difference',
    ]);
    const executionTimeDifferenceRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'executionTimeDifference',
      rowGroupDataMappings
    );

    const intraFirmReportHeadingsRow = buildColumnLabelsString([
      'Intra-Firm Trade Reports',
    ]);
    const intraFirmReportRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'intraFirmReport',
      rowGroupDataMappings
    );

    const alternateATSHeadingsRow = buildColumnLabelsString([
      'Alternative Trading Systems',
    ]);
    const alternateATSRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'alternateATS',
      rowGroupDataMappings
    );

    const tradingModifierHeadingsRow = buildColumnLabelsString([
      'Trading Modifier',
    ]);
    const tradingModifierRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'tradingModifier',
      rowGroupDataMappings
    );

    const securityIndicatorHeadingsRow = buildColumnLabelsString([
      'Security Indicator',
    ]);
    const securityIndicatorRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'securityIndicator',
      rowGroupDataMappings
    );

    const factorProvidedHeadingsRow = buildColumnLabelsString([
      'Factor Provided',
    ]);
    const factorProvidedRowDataString = this.getRowGroupCsvData(
      tradeReportingAnalysis,
      'group',
      'factorProvided',
      rowGroupDataMappings
    );


    /** Now, amass the list of strings and join into 1 string */

    massExportStringsList.push(
      tableTitle,
      priorMonthHeadingsRow,
      totalsHeadingsRow,

      //
      executionTimeHeadingsRow,
      executionTimeRowDataString,

      amendedTradeReportsHeadingsRow,
      amendedTradeReportsRowDataString,

      pricingHeadingsRow,
      pricingRowDataString,

      interDealerHeadingsRow,
      interDealerRowDataString,

      executionTimeDifferenceHeadingsRow,
      executionTimeDifferenceRowDataString,

      intraFirmReportHeadingsRow,
      intraFirmReportRowDataString,

      alternateATSHeadingsRow,
      alternateATSRowDataString,

      tradingModifierHeadingsRow,
      tradingModifierRowDataString,

      securityIndicatorHeadingsRow,
      securityIndicatorRowDataString,

      factorProvidedHeadingsRow,
      factorProvidedRowDataString,
    );

    /** remove trailing new lines and return */

    const csvString = massExportStringsList.join('');
    return csvString;
  }

  getRowGroupCsvData(
    list: any[],
    propName: string,
    propValue: string,
    dataMappings
  ): string {
    const filteredList = list.filter(c => c[propName] === propValue);
    const filteredListString = buildRowDataString({
      dataMappings,
      rowData: filteredList,
    });
    return filteredListString;
  }

  /** Export MarkUp/MarkDown */
  exportMarkUpMarkDownSummary(rowData): string {
    const exportData = [
      {
        title: 'TRACE Markup/Markdown Analysis Report',
        rowData: rowData['traceqmrcmumd'],
        columnLabels: [
          [
            '',
            'Firm',
            '',
            '',
            'Industry',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ],
          [
            'Dollar Traded Amount',
            'Number of Trades Evaluated',
            'Mean',
            'Median',
            'Mean',
            'Median',
            'P25',
            'P50',
            'P75',
            'P80',
            'P90',
            'P95',
            'P99',
          ],
        ],
        dataMappings: [
          'dollarTradedAmount',
          'numberOfTradesEvaluated',
          'meanFirm',
          'medianFirm',
          'meanIndustry',
          'medianIndustry',
          'p25',
          'p50',
          'p75',
          'p80',
          'p90',
          'p95',
          'p99',
        ],
      }
    ];

    const exportDataString = buildExportDataString(exportData);
    const formattedCsv = trimTrailingChar(exportDataString, '\n');
    return formattedCsv;
  }
}
