<div class="dqs-container">
  <report-loader></report-loader>

  <h1 fxFlexLayout="row wrap" class="title report-main-content-padding pdf">
    {{ title }}
  </h1>

  <report-finra-toolbar
    *ngIf="dqsToolbarConfig"
    [toolbarConfig]="dqsToolbarConfig">
  </report-finra-toolbar>

  <br/>

  <div *ngIf="fqsRowData && dqsFilter.fqsDqsFlg === DqsFqsFlag.FQS_READY" class="dqs-summary-container">
    <div class="table-header pdf">
      <div class="table-title">
        File Quality Scorecard<span>&nbsp;</span> <fa-icon [icon]="faDownload" class="download-link print-button" (click)="fqsDetailDownload()"></fa-icon>
      </div>
    </div>

    <div class="" *ngFor="let state of FQS_STATES">
      <ng-container *ngIf="fqsRowData[state.value]">
        <div class="table-header {{ state.className }} pdf">
          <div class="table-title">
            {{ state.title }} ({{ fqsRowData[state.value].length }} items)
          </div>
        </div>
        <report-finra-grid
          class="pdf"
          style="xwidth:3000px;display:block;"
          [columnDefs]="FQS_COLUMN_DEFS"
          [rowData]="fqsRowData[state.value]"
          [groupDefaultExpanded]="0"
          [gridOptions]="gridOptions"
          [containerCssStyle]="{ 'margin': '0' }"
        ></report-finra-grid>
      </ng-container>
    </div>
  </div>

  <div *ngIf="summaryData && dqsFilter.fqsDqsFlg === DqsFqsFlag.DQS_READY" class="dqs-summary-container">
    <div class="table-header pdf">
      <div class="table-title">
        Data Quality Scorecard
      </div>
    </div>

    <div *ngFor="let state of states">
      <div class="table-header {{ state.className }} pdf">
        <div class="table-title">
          {{ state.title }} ({{ summaryData[state.value].length }} items)
        </div>
      </div>
      <report-finra-grid
        class="pdf"
        style="xwidth:3000px;display:block;"
        [columnDefs]="columnDefs"
        [rowData]="summaryData[state.value]"
        [groupDefaultExpanded]="0"
        [gridOptions]="gridOptions"
        [containerCssStyle]="{ 'margin': '0' }"
      ></report-finra-grid>
    </div>
  </div>

  <hr />

  <div
    class="report-main-content-padding report-disclaimer pdf"
    [innerHtml]="disclaimer"
  ></div>
</div>
