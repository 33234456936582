import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  IconDefinition,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute, Params } from '@angular/router';
import {
  COLUMN_DEFS,
  TITLES,
  DISCLAIMERS,
  HEADER_TEXTS,
  MRKUP_MRKDN_COL_DEFS,
  MATURITIES,
  RATINGS,
  PRODUCTS,
  TraceReportShortNames,
  TraceMarkupDownReportDataResponse,
  RATINGS as TRACE_RATINGS,
  MATURITIES as TRACE_MATURITIES,
  PRODUCTS as TRACE_PRODUCTS,
  SHOW_TRACE_PEER_GROUP_HELP_BUTTON_BY_REPORT
} from 'src/app/configs/model/trace.model';
import { MatDialog } from '@angular/material/dialog';
import { map, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ToolbarConfig,
  ToolbarConfigOptions
} from 'src/app/configs/model/finra-toolbar.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription, Observable, } from 'rxjs';
import { TraceToolbarConfig } from 'src/app/configs/toolbar-config/trace.toolbar';
import {
  TRACE_TIMELINESS_DATA_MAPPINGS,
  TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING,
  TRACE_TIMELINESS_Y_AXIS_FORMATTING,
  TRACE_TIMELINESS_LINE_SERIES_TOOLTIP_VALUE_FORMATTER,
  TRACE_TIMELINESS_LINE_TOOLTIP_VALUE_FORMATTER
} from 'src/app/configs/model/trace-timeliness.model';
import { BeastClickActions, BeastOtherActions } from '../../../../../enums/beast.enum';
import { PeerGroupComponent } from 'src/app/components/fragments/peer-group/peer-group.component';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { BeastLeaveReportPageInfo, BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { HelperService } from 'src/app/services/helper.service';
import { ReportPageMetadataInfo, ReportService } from 'src/app/services/report.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { TraceService } from 'src/app/services/trace.service';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';
import { saveCsv, getMonthYrDate } from 'src/app/shared/utils/utils';

@Component({
  selector: 'report-trace',
  templateUrl: './trace.component.html',
  styleUrls: ['./trace.component.scss']
})
export class TraceComponent implements OnInit {
  FIELD_FIRM_ID = 'firmId';
  NO_PERIOD_DATA_MESSAGE = 'No information available for the period.';
  title: any;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  viewName: string;
  reportId: number;
  reportName: string;
  reportDate: string;
  reportVersion: number;
  reportInstanceMetadata: ReportInstanceMetadata;
  isMarkupMarkdownReport: boolean;
  traceTableViews: string[];
  tableTitles = TITLES;
  rowData: { [key: string]: object[] };
  columnDefs;
  chartData;
  disclaimers = DISCLAIMERS;
  description;
  headerTexts = HEADER_TEXTS;
  errorStatus: HttpErrorResponse;
  peerGroupData;
  incomingRatingIndicator: string;
  incomingMaturityIndicator: string;
  incomingSubtypeCode: string;
  maturities = MATURITIES;
  ratings = RATINGS;
  products = PRODUCTS;
  tierCode;
  traceToolbarConfig: ToolbarConfig;
  traceToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  reportPageMetadataInfo: ReportPageMetadataInfo;
  periodChangeSubscription: Subscription;
  reportLoadedSuccessfully: boolean = false;
  currentTimelinessDataView = 'table';
  TraceReportShortNames = TraceReportShortNames;
  TRACE_TIMELINESS_DATA_MAPPINGS = TRACE_TIMELINESS_DATA_MAPPINGS;
  TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING = TRACE_TIMELINESS_TABLE_HEADER_BY_DATA_MAPPING;
  TRACE_TIMELINESS_Y_AXIS_FORMATTING = TRACE_TIMELINESS_Y_AXIS_FORMATTING;
  TRACE_TIMELINESS_LINE_TOOLTIP_VALUE_FORMATTER = TRACE_TIMELINESS_LINE_TOOLTIP_VALUE_FORMATTER;
  TRACE_TIMELINESS_LINE_SERIES_TOOLTIP_VALUE_FORMATTER = TRACE_TIMELINESS_LINE_SERIES_TOOLTIP_VALUE_FORMATTER;
  loadedAt: number;
  autoGroupColumnDefs = {
    cellClass: 'rcExpand',
    cellRendererParams: {
      suppressCount: true
    }
  };

  get peerGroupHelpLink(): string {
    const link = this.helpLinksService?.TRACE_PEER_GROUP[this.reportName];
    return link;
  }

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private baseReportService: ReportService,
    private helperService: HelperService,
    private uiWidgetsService: UiWidgetsService,
    private dialog: MatDialog,
    private traceService: TraceService,
    private toolbarService: ToolbarService,
    private unreadReportsService: UnreadReportsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {
    this.toolbarForm = new FormGroup({
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl(),
      maturity: new FormControl(),
      rating: new FormControl(),
      product: new FormControl()
    });
  }

  ngOnInit() {
    this.titleService.setTitle('TRACE');
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
        reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: this.incomingMaturityIndicator,
        rating: this.incomingRatingIndicator,
        product: this.incomingSubtypeCode,
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.viewName = params.viewName;
    this.reportId = params.reportId;
    this.reportName = params.reportName;
    this.reportVersion = parseInt(params.version, 10);
    this.isMarkupMarkdownReport = this.reportName === TraceReportShortNames.MARKUP_MARKDOWN;
    if (this.isMarkupMarkdownReport) {
      this.incomingMaturityIndicator = params.incomingMaturityIndicator || TRACE_MATURITIES[0].value;
      this.incomingRatingIndicator = params.incomingRatingIndicator || TRACE_RATINGS[0].value;
      this.incomingSubtypeCode = params.incomingSubtypeCode || TRACE_PRODUCTS[0].value;
    }

    this.getReportConfigurationMetadata().subscribe(
      (data: ReportPageMetadataInfo) => {
        this.processReportConfigurationMetadata(data);
        const summaryDataObservable = this.isMarkupMarkdownReport
          ? this.getTraceMkUpMkDnReport().pipe(
              map((markupmarkdownReportData) => {
                this.processTraceMkUpMkDnReport(markupmarkdownReportData);
              })
            )
          : this.getTraceReport().pipe(
              map((traceReportData) => {
                this.processTraceReport(traceReportData);
              })
            );
        summaryDataObservable.subscribe(
          () => {
            this.reportLoadedSuccessfully = true;
            // summary report loaded successfully; mark as read
            this.unreadReportsService.markReportAsRead(this.reportId);
            this.setExportButtonsState(false);
          },
          (error: HttpErrorResponse) => {
            this.reportLoadedSuccessfully = false;
            this.setExportButtonsState(true);
          }
        );
      }
    );
  }

  setExportButtonsState(summaryState: boolean, detailState: boolean = null) {
    /**
     * if given only 1 argument, use it for both, otherwise use both arguments.
    */
    const summaryExportBtn = this.traceToolbarConfig.rows[1].components[1].components[0];
    const detailExportBtn = this.traceToolbarConfig.rows[1].components[1].components[1];
    summaryExportBtn.hideCell = summaryState;
    detailExportBtn.hideCell = detailState !== null ? detailState : summaryState;
  }

  setToolbar() {
    this.toolbarForm.get('rating').setValue(this.incomingRatingIndicator);
    this.toolbarForm.get('maturity').setValue(this.incomingMaturityIndicator);
    this.toolbarForm.get('product').setValue(this.incomingSubtypeCode);

    const customOptions: Partial<ToolbarConfigOptions> = {
      firmIdLabel: 'MPID',
      isMarkupMarkdownReport: this.isMarkupMarkdownReport,

      description: this.headerTexts[this.reportName],
      reportName: this.reportName,
      hideViewsCell: false,
      hideDetailsButton: false,
      maturitiesList: MATURITIES,
      ratingsList: RATINGS,
      productsList: PRODUCTS,
      ratingFormControl: this.toolbarForm.controls.rating,
      maturityFormControl: this.toolbarForm.controls.maturity,
      productFormControl: this.toolbarForm.controls.product,
      hidePeerGroupHelpButton: !SHOW_TRACE_PEER_GROUP_HELP_BUTTON_BY_REPORT[this.reportName],
      setHelpClickHandler: () => {
        this.openHelp();
      },
      setPeerGroupDefinitionHelpClickHandler: () => {
        this.openPeerGroupHelp();
      },

      detailsAllClickHandler: () => {
        const exportUrl: string = this.baseReportService.getReportUrl(
          this.reportId,
          'All',
          'd',
          this.reportDate
        );
        this.helperService.downloadFile(exportUrl);
      }
    };
    const results = this.toolbarService.createToolbarConfig(
      this,
      TraceToolbarConfig,
      customOptions
    );

    this.traceToolbarConfigOptions = results.toolbarConfigOptions;
    this.traceToolbarConfig = results.toolbarConfig;
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          throw error;
        })
      );
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    if (this.isMarkupMarkdownReport) {
      // do not show All view for MarkUp MarkDown
      data.views.includes('All') && data.views.splice(data.views.indexOf('All'), 1);
    }
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
    // Storing date for peer group call
    this.reportDate = this.reportInstanceMetadata.reportPeriodDate;
    this.title = this.reportInstanceMetadata.reportConfiguration.reportDisplayName;
    this.setToolbar();
  }

  getTraceReport(): Observable<object> {
    const observable = this.baseReportService.getReport(
      this.reportId,
      this.viewName,
      's'
    );
    return (<Observable<any>>observable).pipe(
      catchError((error: HttpErrorResponse) => {
        this.helperService.handleReportSummaryDataError(error);
        this.reportLoadedSuccessfully = false;
        throw error;
      })
    );
  }

  processTraceReport(data) {
    switch (this.reportName) {
      case TraceReportShortNames.TIMELINESS: {
        const results = this.traceService.formatTraceTimelinessResponse(data);
        this.rowData = results.rowData;
        this.chartData = results.chartData;
        this.columnDefs = results.columnDefs;
        break;
      }
      default: {
        // Load other 3 trace reports different from markup markdown report.
        this.traceTableViews = Object.keys(COLUMN_DEFS);
        this.columnDefs = COLUMN_DEFS;
        // Adding certain text render conditions to late trade data
        this.rowData = this.traceService.addLateTradeDataCondition(
          data,
          this.reportName
        );
        // Adding peer group link in trade reporting peer group table
        this.addPeerGroupLink();
        // DDWA-4266 Setting tradeReportingPeerGroup TotalNumber to send to the service
        this.tierCode = this.rowData['tradeReportingPeerGroup'][0]['TotalNumber'];
      }
    }
  }

  getTraceMkUpMkDnReport(): Observable<TraceMarkupDownReportDataResponse> {
    const additionalInfo = {
      incomingRatingIndicator: this.incomingRatingIndicator,
      incomingMaturityIndicator: this.incomingMaturityIndicator,
      incomingSubtypeCode: this.incomingSubtypeCode
    };
    const observable = this.baseReportService.getReportByAdditionalInfo(
      this.reportId,
      this.viewName,
      's',
      additionalInfo
    );
    return (<Observable<any>>observable).pipe(
      catchError((error: HttpErrorResponse) => {
        this.helperService.handleReportSummaryDataError(error);
        this.reportLoadedSuccessfully = false;
        throw error;
      })
    );
  }

  processTraceMkUpMkDnReport(data: TraceMarkupDownReportDataResponse) {
    this.traceTableViews = [this.reportName];
    this.columnDefs = { traceqmrcmumd: MRKUP_MRKDN_COL_DEFS };
    this.rowData = { traceqmrcmumd: data };
  }

  onReportDetails() {
    const exportUrl: string = this.baseReportService.getReportUrl(
      this.reportId,
      this.viewName,
      'd',
      this.reportDate
    );
    this.helperService.downloadFile(exportUrl);
  }

  openHelp() {
    this.helpLinksService.open(this.reportName);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  openPeerGroupHelp() {
    window.open(this.peerGroupHelpLink);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  addPeerGroupLink() {
    this.columnDefs['tradeReportingPeerGroup'].forEach((col, index) => {
      if (index === 0) {
        col['cellRenderer'] = params => {
          // Create html
          const span = document.createElement('span');
          const label = document.createElement('label');
          const link = document.createElement('a');
          label.innerHTML = params.value;
          link.href = '#';
          link.innerHTML = ' Peer Group';
          link.addEventListener('click', e => {
            e.preventDefault();
            if (this.peerGroupData) {
              this.openPeerGroupDialog(this.peerGroupData);
              return;
            }
            this.getPeerGroupData().subscribe(
              peerGroupData => {
                this.openPeerGroupDialog(peerGroupData);
              },
              (error: HttpErrorResponse) => {
                this.helperService.handlePeerGroupDataError(error);
              }
            );
          });
          // Add label and link to span
          span.appendChild(label);
          span.appendChild(link);
          // Return span as output
          return span;
        };
      }
    });
  }

  async summaryDataExport(shouldNotIncludePeerGroupData: boolean = true) {
    const additionalInfo = {
      incomingRatingIndicator: this.incomingRatingIndicator,
      incomingMaturityIndicator: this.incomingMaturityIndicator,
      incomingSubtypeCode: this.incomingSubtypeCode
    };
    const fileNameResponse = await this.baseReportService.getReportFileNameByAdditionalInfo(
      this.reportId,
      this.viewName,
      's',
      additionalInfo
    ).toPromise();

    const useFileName = fileNameResponse.fileName;

    if (this.reportName === TraceReportShortNames.MARKUP_MARKDOWN) {
      const csvString = this.traceService.exportMarkUpMarkDownSummary(
        this.rowData
      );
      saveCsv(csvString, useFileName);
      this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
      return;
    }

    if (shouldNotIncludePeerGroupData) {
      const csvString = this.traceService.exportCsv(
        this.reportName,
        this.rowData,
        null,
        this.columnDefs
      );
      saveCsv(csvString, useFileName);
      this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
    } else {
      // try to load the peerGroupData to include in the Csv
      this.getPeerGroupData().subscribe(
        (data) => {
          const csvString = this.traceService.exportCsv(
            this.reportName,
            this.rowData,
            this.peerGroupData,
            this.columnDefs
          );
          saveCsv(csvString, useFileName);
          this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
        },
        (error: HttpErrorResponse) => {
          const errorMessage =
            'Couold not load peer group data; it will not be included in the export.';
          this.uiWidgetsService.showSnackbar(errorMessage, ['snackbar-error'], 3000)
            .afterDismissed()
            .subscribe(result => {
              const csvString = this.traceService.exportCsv(
                this.reportName,
                this.rowData,
                null,
                this.columnDefs
              );
              saveCsv(csvString, useFileName);
              this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
            });
        }
      );
    }
  }

  openPeerGroupDialog(peerGroupData) {
    if (!peerGroupData) {
      return;
    }

    const modalData = {
      reportInstanceMetadata: this.reportInstanceMetadata,
      rowData: peerGroupData,
      period: getMonthYrDate(this.reportDate),
      reportName: this.title,
      reportView: this.viewName,
      columnTitle: 'TRACE MPID',
      useField: this.FIELD_FIRM_ID,
      peerGroup: this.tierCode
    };

    const dialogRef = this.dialog.open(PeerGroupComponent, {
      width: '600px',
      height: '650px',
      data: modalData
    });
  }

  getPeerGroupData() {
    return this.baseReportService
      .getPeerGroupData(
        this.reportId,
        this.viewName,
        this.reportDate,
        this.tierCode
      )
      .pipe(
        map(data => {
          this.peerGroupData = data;
          return data;
        })
      );
  }

  addLateTradeDataCondition(data) {
    this.traceService.addLateTradeDataCondition(data, this.reportName);
  }
}
