<div class="archive-container">
  <report-loader></report-loader>
  <report-alert class="report-main-content-padding no-print"></report-alert>
  <h1 class="title pdf">
    {{ title }}
  </h1>
  <div class="archive-grid-container">
    <div class="sections">
      <div
        *ngFor="let state of reportStates; let i = index"
        class="link-btn"
        [ngClass]="{ 'link-inactive': state.value !== selectedState.value }"
        (click)="selectState(state)"
      >
        {{ state.name }}
        <span class="dash" *ngIf="i < reportStates.length - 1">|</span>
      </div>
    </div>
    <hr />
    <div class="current-section-container">
      <div class="section-title-container">
        <div class="section-left">
          <mat-form-field class="category-select" [floatLabel]="'never'" *ngIf="availableCategories">
            <mat-select
              placeholder="{{ selectedCategory.categoryDisplayName }}"
              (selectionChange)="filterByCategory($event)"
            >
              <mat-option
                selected="selected"
                *ngFor="let category of availableCategories"
                [value]="category.reportCategoryId"
              >
                {{ category.categoryDisplayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="section-title">
            {{ selectedState.name }} Reports
            <span class="dash" *ngIf="selectedRows && selectedRows.length">
              |
            </span>
            <span
              class="select-count"
              *ngIf="selectedRows && selectedRows.length"
            >
              {{ selectedRows.length }} selected
            </span>
          </div>
        </div>
        <div class="section-btn-container">
          <button
            [ngClass]="{ spacer: i !== buttons.length - 1 }"
            *ngFor="let button of buttons; let i = index"
            mat-flat-button
            color="accent"
            [disabled]="noRowsSelected"
            (click)="updateReportState(button.value)"
          >
            {{ button.name }}
          </button>
        </div>
      </div>
    </div>

    <report-finra-grid
      *ngIf="rowData"
      class="pdf"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true"
      [pageSize]="20"
      (selectedRows)="setSelectedRows($event)"
    ></report-finra-grid>
    <div class="no-data-msg" *ngIf="!rowData">
      <strong>{{ message }}</strong>
    </div>
  </div>
</div>
