import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AppEnvironment } from '../interfaces/environment.interface';
import { BeastEventDetails } from '../interfaces/beast.interface';
import * as Cookies from 'js-cookie';
import { UserProfile } from '../configs/model/user-profile.model';
import { BeastEvent } from '../enums/beast.enum';
import { UserProfileService } from './user-profile.service';


/**
 * Beast Service
 *
 * This class consolidate other classes.
 * The beast API has many URLs for different functionality/services
 * that they expose through their API;
 * each class encapsulates a particular area.
 * For example, looking at their docs - https://wiki.finra.org/display/DXTUTLTY/12.+BEAST+Ingest+API+Environments
 * we see there is an API for:
 * - click stream
 * - audit
 * - query
 *
 * Each of those APIs is represented/managed by the appropriate class,
 * all of which are consolidated into the main BeastService class.
 */
@Injectable({
  providedIn: "root",
})
export class BeastService {
  clickStream: BeastClickStreamService = null;
  beastEndPointUrl: string;

  constructor(
    @Inject('environment') environment: AppEnvironment,
    private http: HttpClient,
    private userProfileService: UserProfileService
  ) {
    this.beastEndPointUrl = environment.beastClickStreamUrl;
    this.clickStream = new BeastClickStreamService(this.http, this.userProfileService, this.beastEndPointUrl, environment.beastDetailsFormatVersion, environment.beastEnvironment);
  }
}

class BeastClickStreamService {
  private sessionId: string;

  constructor(
    private http: HttpClient,
    private userProfileService: UserProfileService,
    private beastEndPointUrl: string,
    private beastDetailsFormatVersion: string,
    private beastEnvironment: string,
  ) {
    this.sessionId = Cookies.get('AppSession') || 'sessionId';
  }

  postEvent(
    action: BeastEvent,
    details: BeastEventDetails,
  ) {
    const userProfile: UserProfile = this.userProfileService.getProfileObj();
    const isExternalUser = this.userProfileService.isExternalUser(userProfile);
    const useOrgClass = isExternalUser ? userProfile.orgClass : `NASD`;

    const event: any = {
      /** provided/custom params */
      action,
      details,
      /** service handled params (added/standard for all beast events) */
      sessionId: this.sessionId, // need to populated with fip id
      ags: 'DDWA',
      component: 'RC-UI',
      correlationId: '',
      eventId: Date.now().toString(),
      eventTime: (new Date()).toISOString(),
      eventDataVersion: '1.0.0',
      userId: userProfile.userLoginId,
      orgId: userProfile.orgId,
      orgClass: useOrgClass,
      serviceAccountId: '',
      resource: 'rc-ui',
      env: this.beastEnvironment,
      detailsFormatVersion: this.beastDetailsFormatVersion
    };

    const useParams = {
      ignoreHeaders: 'X-XSRF-TOKEN,pragma,cache-control',
      ignorelist: 'true'
    };
    this.http.post(this.beastEndPointUrl, event, { params: useParams })
      .subscribe(
        (response: any) => {
          // nothing to do
        },
        (error: HttpErrorResponse) => {}
      );
  }
}
