import { Component, OnInit } from '@angular/core';
import {
  ARCHIVES_COLUMN_DEFS,
  ACTION_BUTTONS,
  REPORT_STATES,
  ReportConfigurationState,
  ReportConfigurationStatesResponse,
} from 'src/app/configs/model/admin.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportCategoriesResponse } from 'src/app/configs/model/reports.model';
import { AdminService } from 'src/app/services/admin.service';
import { ReportsService } from 'src/app/services/reports.service';
import { sort } from 'src/app/shared/utils/utils';

@Component({
  selector: 'report-admin',
  templateUrl: './archive-view.component.html',
  styleUrls: ['./archive-view.component.scss']
})
export class ArchiveViewComponent implements OnInit {
  title = 'Admin';
  reportStates = REPORT_STATES;
  selectedState = REPORT_STATES[0];
  selectedRows: ReportConfigurationState[] = [];
  selectedCategory = null;
  columnDefs = ARCHIVES_COLUMN_DEFS;
  rowData: ReportConfigurationState[];
  dataCopy;
  buttons;
  availableCategories;
  message = 'Select a category to view reports.';
  get noRowsSelected(): boolean {
    return !this.selectedRows || !this.selectedRows.length;
  }

  constructor(
    private adminService: AdminService,
    private reportsService: ReportsService,
  ) {}

  ngOnInit() {
    this.getAndSetAvailableCategories();
  }

  selectState(state) {
    this.selectedState = state;
    this.selectedRows = [];
    this.setActionButtons();
    this.getReportConfigurationsByState(this.selectedCategory.reportCategoryId);
  }

  setActionButtons() {
    // Set buttons based on the current tab
    this.buttons = ACTION_BUTTONS.filter(btn => {
      return btn.value !== this.selectedState.value;
    });
  }

  setSelectedRows(rows) {
    this.selectedRows = rows;
  }

  getReportConfigurationsByState(categoryId) {
    this.adminService
      .getReportConfigurationsByState(categoryId, this.selectedState)
      .subscribe(
        (response: ReportConfigurationStatesResponse) => {
          this.rowData = response.reportConfigurationStates;
          this.dataCopy = response.reportConfigurationStates;
        },
        (err: HttpErrorResponse) => {
          this.rowData = null;
          this.message = 'No data available for this section.';
        }
      );
  }

  getAndSetAvailableCategories() {
    this.reportsService.getReportCategories().subscribe(
      (data: ReportCategoriesResponse) => {
        this.setAvailableCategories(data);
      },
      (error: HttpErrorResponse) => {}
    );
  }

  setAvailableCategories(data: ReportCategoriesResponse) {
    // Sorting the data alphabetically.
    this.availableCategories = sort(
      data.reportCategories,
      'categoryName',
      false,
      true
    );
    this.selectedCategory = this.availableCategories[0];
    this.setActionButtons();
    this.getReportConfigurationsByState(
      this.selectedCategory.reportCategoryId
    );
  }

  updateReportState(state: string) {
    this.selectedRows.forEach(row => {
      row.reportStateDescription = state;
    });
    this.adminService
      .updateReportState(this.selectedState.value, state, this.selectedRows)
      .subscribe(
        (data) => {
          this.selectedRows = null;
          this.rowData = data['reportConfigurationStates'];
        },
        (err: HttpErrorResponse) => {
          this.rowData = null;
          this.selectedRows = null;
        }
      );
  }

  filterByCategory(category) {
    this.selectedCategory = this.availableCategories
      .find(cat => cat.reportCategoryId === category.value);
    this.getReportConfigurationsByState(this.selectedCategory.reportCategoryId);
    this.selectedRows = null;
  }
}
