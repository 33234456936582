import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  IconDefinition,
  faChevronRight,
  faChevronDown
} from '@fortawesome/pro-regular-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MAIN_HEADERS,
  PARENT_HEADERS,
  PARENT_HEADERS_L2,
  CHILD_HEADERS,
} from 'src/app/configs/model/msrb/due-diligence-summary.model';
import { PeerGroupComponent } from 'src/app/components/fragments/peer-group/peer-group.component';
import { MsrbPeerGroupFirmIdLabels, MsrbPeerGroupFields } from 'src/app/configs/model/msrb/msrb.model';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { ReportService } from 'src/app/services/report.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { getMonthYrDate } from 'src/app/shared/utils/utils';

@Component({
  selector: 'due-diligence',
  templateUrl: './due-diligence.component.html',
  styleUrls: ['./due-diligence.component.scss']
})
export class DueDiligenceComponent implements OnInit {
  faChevronRight: IconDefinition = faChevronRight;
  faChevronDown: IconDefinition = faChevronDown;
  dueDiligencePeerData;

  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  @Input() rowData;
  @Input() reportId;
  @Input() viewName;
  @Input() reportName;
  @Input() title;

  @Output() handleError: EventEmitter<HttpErrorResponse> = new EventEmitter<
    HttpErrorResponse
  >();

  mainHeaders = MAIN_HEADERS;
  parentHeaders = PARENT_HEADERS;
  parentHeadersL2 = PARENT_HEADERS_L2;
  childHeaders = CHILD_HEADERS;

  constructor(
    private reportService: ReportService,
    private toolbarService: ToolbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  /**
   * This method takes in the row, dataType, prop(row property that will receive the expanded data)
   * and additionalInfo(periodStartDate, detailId) params where detailId is optional since it is used for second level expanded row only
   * @param row
   * @param dataType
   * @param prop
   * @param periodStartDate
   * @param detailId
   */
  expandRow(row, dataType, prop, periodStartDate, detailId?) {
    if (row.expanded) {
      //Close the row if it was open already
      row.expanded = false;
      return;
    }
    // Make a call to get expanded row data
    this.reportService
      .getReportByAdditionalInfo(this.reportId, this.viewName, dataType, {
        periodStartDate,
        detailId
      })
      .subscribe(
        data => {
          row[prop] = data;
          // Expand the row in case of success
          row.expanded = true;
        },
        error => {
          // Close the row in case of error
          row.expanded = false;
          this.handleError.emit(error);
        }
      );
  }

  getPeerGroupData(row) {
    if (!row['peer_peerGroupIdentifier']) return;
    // Call API only if peer group identifier exists
    this.reportService
      .getReportByAdditionalInfo(this.reportId, this.viewName, 'peer', {
        periodStartDate: row['peer_periodStartDate'],
        peerGroupId: row['peer_peerGroupIdentifier']
      })
      .subscribe(
        data => {
          this.dueDiligencePeerData = data;
          this.openPeerGroup(row);
        },
        error => {
          this.dueDiligencePeerData = null;
          this.handleError.emit(error);
        }
      );
  }

  openPeerGroup(row) {
    const peerGroupFirmIdLabel = MsrbPeerGroupFirmIdLabels[this.reportName];
    const peerGroupUseField = MsrbPeerGroupFields[this.reportName];
    const dialogRef = this.dialog.open(PeerGroupComponent, {
      width: '600px',
      height: '650px',
      data: {
        reportInstanceMetadata: this.reportInstanceMetadata,
        rowData: this.dueDiligencePeerData,
        period: getMonthYrDate(row['peer_periodStartDate']),
        reportName: this.title,
        reportView: this.viewName,
        columnTitle: peerGroupFirmIdLabel,
        useField: peerGroupUseField,
        tierCode: row['peer_peerGroupIdentifier']
      }
    });
  }
}
