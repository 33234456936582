import {
  buildColumnLabelsString,
  getMonthYrDate,
  buildRowDataString,
  buildExportDataString,
  saveCsv,
  trimTrailingChar
} from 'src/app/shared/utils/utils';



/**
 * Interfaces for the customer debits.
 * The property names match was is given from the backend.
 * Here is an example of a customer debits object from the backend:
 *
 * @example:
   {
    'rptFnclOprtnsMonthlyId: 10007845,
    'rptId: 21005378,
    'crdId: '10083',
    'rptPeriod: '08-01-2017',
    'uscrdCstmrDebtAmHg: 17303,
    'uscrdCstmrDebtAmLw: 0,
    'uscrdCstmrDebtAmAvg: 18.89,
    'mrgnDebtAmHg: 200538,
    'mrgnDebtAmLw: 180717,
    'mrgnDebtAmAvg: 1104.39,
    'uscrdCstmrDebtAvgInd: 13680833943.75,
    'uscrdCstmrDebtChngPtInd: null,
    'firmsUscrdCstmrDebtCt: 172,
    'mrgnDebtAvgInd: 10491244041.29,
    'mrgnDebtChngPtInd: null,
    'firmsMrgnDebtCt: 148
  }

  These interfaces are for matching only the necessary properties from the object.
 */

export interface CustomerDebitsMonthly {
  rptFnclOprtnsMonthlyId: number;
  rptId: number;
  crdId: string;
  rptPeriod: string; // format = '10-01-2017' (mm-dd-yyyy)

  // firm
  uscrdCstmrDebtAmHg: number;
  uscrdCstmrDebtAmLw: number;
  uscrdCstmrDebtAmAvg: number; // float number with 2 decimal places
  mrgnDebtAmHg: number;
  mrgnDebtAmLw: number;
  mrgnDebtAmAvg: number; // float number with 2 decimal places

  // industry
  uscrdCstmrDebtAvgInd: number; // float number with 2 decimal places
  uscrdCstmrDebtChngPtInd: number; // float number with 2 decimal places
  firmsUscrdCstmrDebtCt: number;
  mrgnDebtAvgInd: number; // float number with 2 decimal places
  mrgnDebtChngPtInd: number; // float number with 2 decimal places
  firmsMrgnDebtCt: number;
}

export interface UnsecuredCustomerDebitsMonthly {
  rptFnclOprtnsMonthlyId: number;
  rptId: number;
  crdId: string;
  rptPeriod: string; // format = '10-01-2017'

  // firm
  uscrdCstmrDebtAmHg: number;
  uscrdCstmrDebtAmLw: number;
  uscrdCstmrDebtAmAvg: number; // float number with 2 decimal places

  // industry
  uscrdCstmrDebtAvgInd: number; // float number with 2 decimal places
  uscrdCstmrDebtChngPtInd: number; // float number with 2 decimal places
  firmsUscrdCstmrDebtCt: number;
}

export interface MarginCustomerDebitsMonthly {
  rptFnclOprtnsMonthlyId: number;
  rptId: number;
  crdId: string;
  rptPeriod: string; // format = '10-01-2017' (mm-dd-yyyy)

  // firm
  mrgnDebtAmHg: number;
  mrgnDebtAmLw: number;
  mrgnDebtAmAvg: number; // float number with 2 decimal places

  // industry
  mrgnDebtAvgInd: number; // float number with 2 decimal places
  mrgnDebtChngPtInd: number; // float number with 2 decimal places
  firmsMrgnDebtCt: number;
}

/** Daily */
export interface CustomerDebitsDaily {
  rptFnclOprtnsDailyId: number;
  rptId: number;
  crdId: string;
  cstmrAccntPstnOrBalCt: number;
  uscrdCstmrDebtAm: number;
  mrgnDebtAm: number;
  prcsdDt: string; // format = '10-01-2017' (mm-dd-yyyy)
}




export enum DebitsTypes {
  UNSECURED = 1, // Unsecured Consumer Debits
  MARGIN, // Margin Debits in Consumer Account
}

export interface CustomerDebitsMonthlySummary {
  rptFnclOprtnsMonthlyId: number;
  reportId: number;
  crdId: string;
  reportPeriod: string;
  firm: {
    monthlyHigh: number;
    monthlyLow: number;
    monthlyAverage: number;
  };
  industry: {
    monthlyAverage: number;
    percentageChange: number;
    unsecuredCustomerCount: number;
  };
}

/**
 * These interfaces are for transforming the customer debits report data that comes from the API into an object
 * that makes the data easier to work with; The properties are hard to understand in its raw form.
 * Not only that, both unsecured and margin debits have the same fields, as far as where it is supposed to appear on the UI.
 *
 * A helper method (located below) will be provided to make that easy.
 * Since each month has both the unsecured and margin data, the helper method below
 * will parse the summary data abd return an array with both;
 * the unsecured debits will be first in the array, the margin debits will be second.
 */

export interface UnsecuredCustomerDebitsMonthlySummary extends CustomerDebitsMonthlySummary {
  debitsType: DebitsTypes.UNSECURED;
  monthYear?: string;
  detailsData?: CustomerDebitsDaily[];
}

export interface MarginCustomerDebitsMonthlySummary extends CustomerDebitsMonthlySummary {
  debitsType: DebitsTypes.MARGIN;
  monthYear?: string;
  detailsData?: CustomerDebitsDaily[];
}

export function parseMonthlyCustomerDebitsSumary(summaryData: CustomerDebitsMonthly): [
  UnsecuredCustomerDebitsMonthlySummary,
  MarginCustomerDebitsMonthlySummary
] {
  const unsecuredDebits: UnsecuredCustomerDebitsMonthlySummary = {
    rptFnclOprtnsMonthlyId: summaryData.rptFnclOprtnsMonthlyId,
    reportId: summaryData.rptId,
    crdId: summaryData.crdId,
    reportPeriod: summaryData.rptPeriod,
    firm: {
      monthlyHigh: summaryData.uscrdCstmrDebtAmHg,
      monthlyLow: summaryData.uscrdCstmrDebtAmLw,
      monthlyAverage: summaryData.uscrdCstmrDebtAmAvg,
    },
    industry: {
      monthlyAverage: summaryData.uscrdCstmrDebtAvgInd,
      percentageChange: summaryData.uscrdCstmrDebtChngPtInd,
      unsecuredCustomerCount: summaryData.firmsUscrdCstmrDebtCt,
    },
    debitsType: DebitsTypes.UNSECURED
  };

  const marginDebits: MarginCustomerDebitsMonthlySummary = {
    rptFnclOprtnsMonthlyId: summaryData.rptFnclOprtnsMonthlyId,
    reportId: summaryData.rptId,
    crdId: summaryData.crdId,
    reportPeriod: summaryData.rptPeriod,
    firm: {
      monthlyHigh: summaryData.mrgnDebtAmHg,
      monthlyLow: summaryData.mrgnDebtAmLw,
      monthlyAverage: summaryData.mrgnDebtAmAvg,
    },
    industry: {
      monthlyAverage: summaryData.mrgnDebtAvgInd,
      percentageChange: summaryData.mrgnDebtChngPtInd,
      unsecuredCustomerCount: summaryData.firmsMrgnDebtCt,
    },
    debitsType: DebitsTypes.MARGIN
  };

  return [
    unsecuredDebits,
    marginDebits
  ];
}

export function createEmptyCustomerDebitsMonthSummaryObj(
  period: string
) {
  return {
    rptPeriod: period,
    //
    rptFnclOprtnsMonthlyId: 0,
    rptId: null,
    crdId: '0',
    uscrdCstmrDebtAmHg: null,
    uscrdCstmrDebtAmLw: null,
    uscrdCstmrDebtAmAvg: null,
    mrgnDebtAmHg: null,
    mrgnDebtAmLw: null,
    mrgnDebtAmAvg: null,
    uscrdCstmrDebtAvgInd: null,
    uscrdCstmrDebtChngPtInd: null,
    firmsUscrdCstmrDebtCt: null,
    mrgnDebtAvgInd: null,
    mrgnDebtChngPtInd: null,
    firmsMrgnDebtCt: null,
  };
}

export type DebitsMonthlyUnion =
  UnsecuredCustomerDebitsMonthlySummary |
  MarginCustomerDebitsMonthlySummary;

export type DebitsMonthlyListUnion =
  UnsecuredCustomerDebitsMonthlySummary[] |
  MarginCustomerDebitsMonthlySummary[];

export type DebitsDailyUnion =
  CustomerDebitsDaily |
  CustomerDebitsDaily;

export type DebitsDailyListUnion =
  CustomerDebitsDaily[] |
  CustomerDebitsDaily[];


/** Export Summary Logic */

export function customerDebitsSummaryExportData(
  unsecuredDebitsMonthly: UnsecuredCustomerDebitsMonthlySummary[],
  marginDebitsMonthly: MarginCustomerDebitsMonthlySummary[],
) {
  const massDataStringList = [];

  const debitsMainHeadings = buildColumnLabelsString([
    [
      '',
      'Firm',
      '',
      '',
      'Industry',
      '',
      '',
    ],
    [
      'Period',
      'Monthly High',
      'Monthly Low',
      'Average',
      'Average',
      '% Change from Prior Period',
      'Firms with Unsecured Customer',
    ],
  ]);

  const generateRowDataCsv = (data: DebitsMonthlyUnion) => {
    const dataStringList = [];
    const periodDate = getMonthYrDate(data.reportPeriod);

    const groupStringData = buildRowDataString({
      rowData: [data],
      dataMappings: [
        { value: periodDate },
        'firm.monthlyHigh',
        'firm.monthlyLow',
        'firm.monthlyAverage',
        'industry.monthlyAverage',
        'industry.percentageChange',
        'industry.unsecuredCustomerCount',
      ],
    });

    const detailsStringData = buildExportDataString([
      {
        title: null,
        columnLabels: [
          '',
          'Number of Customer Accounts',
          'Unsecured Customer Debits',
          'Margin Debits in Customer Accounts',
        ],
        rowData: data.detailsData,
        dataMappings: [
          'prcsdDt',
          'cstmrAccntPstnOrBalCt',
          'uscrdCstmrDebtAm',
          'mrgnDebtAm',
        ],
      }
    ]);

    dataStringList.push(groupStringData);
    dataStringList.push(detailsStringData);
    dataStringList.push('\n');

    const dataCsvString = dataStringList.join('');
    return dataCsvString;
  };

  massDataStringList.push(`Unsecured Customer Debits\n`);
  massDataStringList.push(debitsMainHeadings);
  for (const data of unsecuredDebitsMonthly) {
    const dataString = generateRowDataCsv(data);
    massDataStringList.push(dataString);
  }

  massDataStringList.push(`Margin Debits in Customer Accounts\n`);
  massDataStringList.push(debitsMainHeadings);
  for (const data of marginDebitsMonthly) {
    const dataString = generateRowDataCsv(data);
    massDataStringList.push(dataString);
  }

  const massCsvDataString = massDataStringList.join('');
  const csvFormatted = trimTrailingChar(massCsvDataString, '\n');
  return csvFormatted;
}

export function exportCustomerDebitsSummarySummary(
  unsecuredDebitsMonthly: UnsecuredCustomerDebitsMonthlySummary[],
  marginDebitsMonthly: MarginCustomerDebitsMonthlySummary[],
  useFileName: string
) {
  const exportData = customerDebitsSummaryExportData(
    unsecuredDebitsMonthly,
    marginDebitsMonthly,
  );
  saveCsv(exportData, useFileName);
}
