import { DatePipe } from '@angular/common';
import { LoprDailyReportsByTypeId } from './lopr/lopr.model';
import { CorpFinQuarterlyReportsByTypeId } from './corp-fin.model';
import { OnlineLearningQuarterlyReportsByTypeId } from './online-learning.model';
import { RiskMonitoringQuarterlyReportsByTypeId } from './risk-monitoring/risk-monitoring.model';
import { quarterDateFormat } from 'src/app/shared/utils/utils';
import { ValueFormatterParams } from 'ag-grid-community';

export const allDailyReports: number[] = [
  ...LoprDailyReportsByTypeId
];

export const allQuarterlyReports = [
  ...CorpFinQuarterlyReportsByTypeId,
  ...OnlineLearningQuarterlyReportsByTypeId,
  ...RiskMonitoringQuarterlyReportsByTypeId
];

export function checkIsDailyReport(reportTypeId: number): boolean {
  const isDailyReport = allDailyReports.indexOf(reportTypeId) > -1;
  return isDailyReport;
}

export function checkIsQuarterlyReport(reportTypeId: number): boolean {
  const isQuarterlyReport = allQuarterlyReports.indexOf(reportTypeId) > -1;
  return isQuarterlyReport;
}

export function getDateStringFromReportTypeId(
  reportTypeId: number,
  period: string
): string {
  if (checkIsDailyReport(reportTypeId)) {
    return (new DatePipe('en-US')).transform(period, 'MMMM dd, yyyy');
  } else if (checkIsQuarterlyReport(reportTypeId)) {
    return quarterDateFormat(period);
  } else {
    return (new DatePipe('en-US')).transform(period, 'MMMM yyyy');
  }
}

export const ARCHIVES_COLUMN_DEFS = [
  {
    headerName: 'Report Category',
    field: 'reportCategoryDisplayName',
    checkboxSelection: true,
    headerCheckboxSelection: true
  },
  { headerName: 'Report Type', field: 'reportTypeName' },
  {
    headerName: 'Period',
    field: 'reportPeriodDate',
    valueFormatter: (params: ValueFormatterParams) => {
      const report: ReportConfigurationState = params.data;
      const thereIsData = (!!report && !!report.reportTypeId && !!report.reportPeriodDate);
      const value = thereIsData
        ? getDateStringFromReportTypeId(report.reportTypeId, report.reportPeriodDate)
        : params.value;
      return value;
    }
  },
  { headerName: 'Version', field: 'reportDataVersion' }
];

export const ACTION_BUTTONS = [
  { name: 'PUBLISH', value: 'published' },
  { name: 'UNPUBLISH', value: 'unpublished' },
  { name: 'ARCHIVE', value: 'archived' }
];

export const REPORT_STATES = [
  { name: 'Unpublished', value: 'unpublished' },
  { name: 'Published', value: 'published' },
  { name: 'Archived', value: 'archived' }
];

export const REPORT_CATEGORIES_ID = {
  CorpFin: 10,
  CrossMarketSupervision: 8,
  Equity: 4
};

export const REPORT_CATEGORIES = [
  { name: 'Corp-Fin', value: REPORT_CATEGORIES_ID.CorpFin },
  {
    name: 'Cross Market Supervision',
    value: REPORT_CATEGORIES_ID.CrossMarketSupervision
  },
  { name: 'Equity', value: REPORT_CATEGORIES_ID.Equity }
];

// DDWA-2403 / DDWA-3714
// doing away with number/enum behavior in favor of something more readable
export const REPORT_PROBLEM_BEHAVIOR_MAP = {
  0: 'VALID',
  1: 'INVALID_SHOW_DATA',
};

export enum BehaviorsEnum {
  VALID = 'VALID',
  INVALID_SHOW_DATA = 'INVALID_SHOW_DATA',
}

export interface ReportType {
  reportTypeId: number;
  reportCategoryId: number;
  reportTypeName: string;
  reportTypeShortName: string;
  reportDeleted: boolean;
  reportPeriodicityId: number;
  firmIdTypeId: number;
  userAccessible: boolean;
}

export interface ReportTypeEdition {
  rptCfgId: number;
  rptCtgryId: number;
  rptEdtn: string;
  rptTypeId: number;
  rptTypeNm: string;
}

export interface ReportConfigurationState {
  reportCategoryId: number;
  reportCategoryDisplayName: string;
  reportTypeId: number;
  reportTypeName: string;
  reportConfigurationId: number;
  reportPeriodDate: string;
  reportDataVersion: number;
  reportStateDescription: string;
}

export interface ReportConfigurationStatesResponse {
  reportConfigurationStates: ReportConfigurationState[];
}

export interface ReportExplorerPeriodVersion {
  rptCfgId: number;
  rptPrdDt: string;
  rptDataVrsn: number;
}

export interface ReportExplorerPeriod {
  rptCfgId: number;
  rptPrdDt: string;
  reportTypeEditionPeriodVersionList: ReportExplorerPeriodVersion[];
}

export interface ReportStatus {
  rptTypeId: number;
  rptCtgryId: number;
  rptName: string;
  rptPeriod: string;
  publishTime: string;
  frstRptPrdDt: string;
}

export interface ReportStatusesResponse {
  'Trace'?: ReportStatus[];
  'Risk Monitoring'?: ReportStatus[];
  'Corp Fin'?: ReportStatus[];
  'Options'?: ReportStatus[];
  'MSRB'?: ReportStatus[];
  'Cross Market Supervision'?: ReportStatus[];
  'Equity'?: ReportStatus[];
  'Online Learning'?: ReportStatus[];
  'Disclosure'?: ReportStatus[];
}

export const explorerFilterInfoModalMessage =
  `<p>The inputs under the table headers allow you to filter the data based on the text provided. \
  The input query will match for any part of value. <br/>\
  For example: inputting <span style="color: red;">1</span> under CRD # will match 1, 21, 312, 451348, etc.</p> \
  <p>To filter for a result that matches the input exactly, put a dot/period at the end of the input. <br/>\
  For example, For example: inputting <span style="color: red;">25.</span> under CRD # will match only 25</p>`;
