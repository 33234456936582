import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ResourceLoader } from '@angular/compiler';
import {
  buildExportDataString,
  sort_distinct,
  buildColumnLabelsString,
  buildRowDataString,
  trimTrailingChar
} from '../shared/utils/utils';
import { ONLINE_LEARNING_TITLE_2 } from 'src/app/configs/model/online-learning.model';
import {
  S101_EXPORT_CONFIG,
  S201_EXPORT_CONFIG,
  TOTAL_SESSIONS_EXPORT_CONFIG
} from 'src/app/configs/summary-export/online-learning.export';

@Injectable({
  providedIn: 'root'
})
export class OnlineLearningService {
  baseUrl: string;
  NO_PERIOD_DATA_MESSAGE: any;

  constructor(
    @Inject('environment')
    environment,
    private http: HttpClient
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getSummaryData(reportId: number) {
    const url = `${this.baseUrl}reportData/reportId/${reportId}/viewName/CERegElRepSummary/dataType/s?cache=false`;

    return this.http.get<any>(url).pipe(
      map(data => {
        return this.formatSummaryData(data);
      })
    );
  }

  formatSummaryData(data) {
    for (let i = 0; i < data.tabs.length; i++) {
      const modules = [];
      let module = null;

      if (!data.tabs[i].modules) {
        data.tabs[i].modules = [];
      }

      for (let j = 0; j < data.tabs[i].modules.length; j++) {
        const curModule = data.tabs[i].modules[j];

        if (module == null || module.name !== curModule.moduleDisplayName) {
          if (module != null) {
            modules.push(module);
          }

          module = {
            type: curModule.moduleName,
            name: curModule.moduleDisplayName,

            firmSessionAverageMeanScorePercent: 0,
            industrySessionAverageMeanScorePercent: 0,
            firmSessionAverageMeanScorePercentOther: 0,
            industrySessionAverageMeanScorePercentOther: 0,

            firmSessionScoreStandardDeviationPercent: 0,
            industrySessionScoreStandardDeviationPercent: 0,
            firmSessionScoreStandardDeviationPercentOther: 0,
            industrySessionScoreStandardDeviationPercentOther: 0,

            firmSessionAverageDurationTime: '00:00',
            industrySessionAverageDurationTime: '00:00',
            firmSessionAverageDurationTimeOther: '00:00',
            industrySessionAverageDurationTimeOther: '00:00',

            firmSessionDurationStandardDeviationTime: '00:00',
            industrySessionDurationStandardDeviationTime: '00:00',
            firmSessionDurationStandardDeviationTimeOther: '00:00',
            industrySessionDurationStandardDeviationTimeOther: '00:00'
          };
        }

        if (curModule.displayName === 'Average (Mean %)') {
          module.firmSessionAverageMeanScorePercent = curModule.col1;
          module.industrySessionAverageMeanScorePercent = curModule.col2;
          module.firmSessionAverageMeanScorePercentOther = curModule.col3;
          module.industrySessionAverageMeanScorePercentOther =
            curModule.col4;
        } else if (curModule.displayName === 'Average (HH:MM)') {
          module.firmSessionAverageDurationTime = curModule.col1;
          module.industrySessionAverageDurationTime = curModule.col2;
          module.firmSessionAverageDurationTimeOther = curModule.col3;
          module.industrySessionAverageDurationTimeOther = curModule.col4;
        } else if (curModule.displayName === 'Standard Deviation') {
          if (curModule.col1.indexOf(':') === -1) {
            module.firmSessionScoreStandardDeviationPercent =
              curModule.col1;
            module.industrySessionScoreStandardDeviationPercent =
              curModule.col2;
            module.firmSessionScoreStandardDeviationPercentOther =
              curModule.col3;
            module.industrySessionScoreStandardDeviationPercentOther =
              curModule.col4;
          } else {
            module.firmSessionDurationStandardDeviationTime =
              curModule.col1;
            module.industrySessionDurationStandardDeviationTime =
              curModule.col2;
            module.firmSessionDurationStandardDeviationTimeOther =
              curModule.col3;
            module.industrySessionDurationStandardDeviationTimeOther =
              curModule.col4;
          }
        }
      }

      if (module != null) {
        modules.push(module);
      }
      data.tabs[i].modules = modules;
    }

    return data;
  }

  /** Summary Export */

  buildSummaryExportString(tab, config): string {
    const massCsvStringList = [];

    // build sessions table
    const s101NumberOfSessions = buildExportDataString([
      {
        title: `${tab.id}: ${tab.name}`,
        columnLabels: config.numberOfSessions.columnLabels,
        rowData: [tab.numberOfSessionsTable],
        dataMappings: config.numberOfSessions.dataMappings
      }
    ]);
    massCsvStringList.push(s101NumberOfSessions, '\n');

    // sort modules by type and build each
    const modulesObj = sort_distinct(tab.modules, 'type', false);
    const moduleTypesKeys = Object.keys(modulesObj).sort();
    for (const moduleTypeKey of moduleTypesKeys) {
      // module type heading
      const moduleHeading = buildColumnLabelsString(['', moduleTypeKey]);
      massCsvStringList.push(moduleHeading);

      const modulesListByType = modulesObj[moduleTypeKey];
      for (const moduleData of modulesListByType) {
        // module name heading
        const moduleDataHeading = buildColumnLabelsString([
          '',
          '',
          moduleData.name
        ]);
        massCsvStringList.push(moduleDataHeading);

        // score
        const scoreHeading = buildColumnLabelsString(config.score.headingsList);
        massCsvStringList.push(scoreHeading);
        const scoreAverageRowData = buildRowDataString({
          rowData: [moduleData],
          dataMappings: config.score.averageDataMappings
        });
        massCsvStringList.push(scoreAverageRowData);
        const scoreStandardDeviationRowData = buildRowDataString({
          rowData: [moduleData],
          dataMappings: config.score.sdDataMappings
        });
        massCsvStringList.push(scoreStandardDeviationRowData);

        // duration
        const durationHeading = buildColumnLabelsString(
          config.duration.headingsList
        );
        massCsvStringList.push(durationHeading);
        const durationAverageRowData = buildRowDataString({
          rowData: [moduleData],
          dataMappings: config.duration.averageDataMappings
        });
        massCsvStringList.push(durationAverageRowData);
        const durationStandardDeviationRowData = buildRowDataString({
          rowData: [moduleData],
          dataMappings: config.duration.sdDataMappings
        });
        massCsvStringList.push(durationStandardDeviationRowData);
      }
    }

    const massCsvString = massCsvStringList.join('');
    return massCsvString;
  }

  buildSessionsAttendedExport(data): string {
    const exportData = buildExportDataString([
      {
        title: ONLINE_LEARNING_TITLE_2,
        columnLabels: TOTAL_SESSIONS_EXPORT_CONFIG.columnLabels,
        rowData: data.totalSessionsTable,
        dataMappings: TOTAL_SESSIONS_EXPORT_CONFIG.dataMappings
      }
    ]);
    return exportData;
  }

  exportSummaryData(data): string {
    const massCsvStringList = [];

    const sessionsTableStringData = this.buildSessionsAttendedExport(data);
    massCsvStringList.push(sessionsTableStringData, '\n\n');

    data.tabs.forEach((tab) => {
      const isTab101 = tab.id === `S101`;
      const exportConfig = isTab101
        ? S101_EXPORT_CONFIG
        : S201_EXPORT_CONFIG;
      const exportString = this.buildSummaryExportString(
        tab,
        exportConfig
      );
      massCsvStringList.push(exportString, '\n');
    });

    const massCsvString = massCsvStringList.join('');
    const formattedCsvString = trimTrailingChar(massCsvString, '\n');
    return formattedCsvString;
  }
}
