<div class="due-diligence-container">
  <div class="table">
    <div class="table-header">
      <div
        class="table-cell text-width-{{ i }}"
        *ngFor="let header of mainHeaders; let i = index"
      >
        <div class="main-table-header">
          {{ header }}
        </div>
      </div>
    </div>
    <div class="table-header">
      <div
        class="table-cell text-width-{{ i }}"
        *ngFor="let header of parentHeaders; let i = index"
      >
        {{ header }}
      </div>
    </div>
    <div class="table-header">
      <div class="table-cell" *ngFor="let header of parentHeadersL2">
        {{ header }}
      </div>
    </div>
    <ng-container *ngFor="let row of rowData; let i = index">
      <div class="table-row summary-row summary-{{i}}" [attr.row-id]="i">
        <div class="table-cell summary-cell-0">
          <span class="icon-container">
            <fa-icon
              [icon]="row.expanded ? faChevronDown : faChevronRight"
              [attr.title]="row.expanded ? 'Hide' : 'Show'"
              class="fa-xs icon-chevron"
              data-html2canvas-ignore
              (click)="
                expandRow(
                  row,
                  'detailRow',
                  'submissions',
                  row['firm_periodStartDate']
                )
              "
            ></fa-icon>
          </span>
          {{ row['firm_periodStartDate'] | finraValidValue: 'period-avg-text' }}
        </div>
        <div class="table-cell">
          {{ row['firm_totalIssuanceCount'] | finraValidValue: 'number' }}
        </div>
        <div class="table-cell">
          {{
            row['firm_issuancePreviousLackingCurrentFinancialsCount']
              | finraValidValue: 'number'
          }}
        </div>
        <div class="table-cell">
          {{
            row['firm_percentIssuancePreviousLackingCurrentFinancialsCount']
              | finraValidValue: 'percent'
          }}
        </div>
        <div class="table-cell">
          <a
            (click)="getPeerGroupData(row)"
            class="link-inactive"
            [ngClass]="{ 'peer-group-link': row['peer_peerGroupIdentifier'] }"
          >
            {{ row['peer_peerGroupIdentifier'] | finraValidValue: 'number' }}
          </a>
        </div>
        <div class="table-cell">
          {{ row['peer_totalIssuanceCount'] | finraValidValue: 'number' }}
        </div>
        <div class="table-cell">
          {{
            row['peer_issuancePreviousLackingCurrentFinancialsCount']
              | finraValidValue: 'number'
          }}
        </div>
        <div class="table-cell">
          {{
            row['peer_averageIssuancePreviousLackingCurrentFinancialsCount']
              | finraValidValue: 'percent'
          }}
        </div>
        <div class="table-cell">
          {{
            row['peer_medianIssuancePreviousLackingCurrentFinancialsCount']
              | finraValidValue: 'percent'
          }}
        </div>
        <div class="table-cell">
          {{ row['industry_totalIssuanceCount'] | finraValidValue: 'number' }}
        </div>
        <div class="table-cell">
          {{
            row['industry_issuancePreviousLackingCurrentFinancialsCount']
              | finraValidValue: 'number'
          }}
        </div>
        <div class="table-cell">
          {{
            row[
              'industry_averageIssuancePreviousLackingCurrentFinancialsCount'
            ] | finraValidValue: 'percent'
          }}
        </div>
        <div class="table-cell">
          {{
            row['industry_medianIssuancePreviousLackingCurrentFinancialsCount']
              | finraValidValue: 'percent'
          }}
        </div>
      </div>
      <ng-container *ngIf="row.expanded">
        <div class="table-header child">
          <div
            class="table-cell child-cell-{{ i }}"
            *ngFor="let header of childHeaders; let i = index"
          >
            {{ header }}
          </div>
        </div>
        <ng-container *ngFor="let sub of row.submissions; index as i">
          <div class="table-row child details-row details-{{i}}" [attr.row-id]="i">
            <div class="table-cell child-cell-0">
              {{ sub.submissionIdentifier }}
            </div>
            <div class="table-cell child-cell-1">{{ sub.issuerName }}</div>
            <div class="table-cell child-cell-2">
              {{ sub.issueDescription }}
            </div>
            <div class="table-cell child-cell-3">
              {{ sub.cusipIdentifier | slice: 0:6 }}
            </div>
            <div class="table-cell child-cell-4">
              {{ sub.cusipClosingDate | date: 'MM/dd/yyyy' }}
            </div>
            <div
              class="table-cell detail child-cell-5"
              (click)="
                expandRow(
                  sub,
                  'expDetailRow',
                  'underWriterDetails',
                  sub['periodStartDate'],
                  sub['diligenceDetailIdentifier']
                )
              "
            >
              <span class="icon-container">
                <fa-icon
                  [icon]="sub.expanded ? faChevronDown : faChevronRight"
                  [attr.title]="sub.expanded ? 'Hide' : 'Show'"
                  class="fa-xs icon-chevron"
                  data-html2canvas-ignore
                ></fa-icon>
              </span>
              <span class="detail-txt">Detail</span>
            </div>
            <div class="table-cell"></div>
            <div class="table-cell"></div>
          </div>
          <ng-container *ngIf="sub.expanded">
            <div class="table-header child">
              <div class="table-cell"></div>
              <div class="table-cell"></div>
              <div class="table-cell"></div>
              <div class="table-cell"></div>
              <div class="table-cell"></div>
              <div class="table-cell child-cell-6">
                Underwriter of Previously Issued CUSIP
              </div>
              <div class="table-cell child-cell-7">
                Submission Date of Previously Issued CUSIP
              </div>
              <div class="table-cell child-cell-8">Previously Issued CUSIP</div>
            </div>
            <ng-container *ngFor="let detail of sub.underWriterDetails; index as i">
              <div class="table-row child expanded-details-{{i}} expanded-details-row" [attr.row-id]="i">
                <div class="table-cell"></div>
                <div class="table-cell"></div>
                <div class="table-cell"></div>
                <div class="table-cell"></div>
                <div class="table-cell"></div>
                <div class="table-cell child-cell-6">
                  {{ detail.underwriterPreviouslyIssuedCusipIdentifier }}
                </div>
                <div class="table-cell child-cell-7">
                  {{
                    detail.submissionDatePreviouslyIssuedCusipIdentifier
                      | date: 'MM/dd/yyyy'
                  }}
                </div>
                <div
                  class="table-cell child-cell-8 cusip-group"
                  [innerHtml]="detail.prvlCusipIdentifier"
                ></div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
