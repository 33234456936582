<div id="consumer-complaint-industry-container">
  <div class="scroll-x-box">
    <div class="table-header">
      <div class="table-title">
        Industry Percentages for the Top Ten Complaints by {{ viewName === 'Problem' ? 'Problem' : 'Product' }}
      </div>

      <span class="question-icon-container" (click)="openHelp()">
        <fa-icon
          data-html2canvas-ignore
          [icon]="faQuestionCircle"
          class="fa-lg question-icon"
        ></fa-icon>
        <span>Help</span>
      </span>
    </div>

    <!-- header -->
    <div class="row-header row-container grey-border-bottom-1">
      <div class="col col-20 grey-border-right-1">
        <div class="box-padding-1">{{ viewName === 'Problem' ? 'Problems' : 'Products' }} Reported</div>
      </div>

      <div class="col col-80">
        <div class="row-container col-flex-grow">
          <!--  -->
          <div
            class="col col-15"
            *ngFor="let heading of quartersHeadings; last as isLastHeading; index as headingIndex"
            [ngClass]="{
              'grey-border-right-1': !isLastHeading
            }"
          >
            <div class="box-padding-1">{{ heading }}</div>
          </div>
        </div>
      </div>
    </div>

    <section
      class="row-body"
      *ngFor="let data of rowData; last as isLastData; index as i"
    >
      <div
        class="row-container"
        [attr.data-row-number]="i + 1"
        [ngClass]="{
          'grey-border-bottom-1': !isLastData,
          'blue-highlight-bg': !!data.expanded
        }"
      >
        <div class="col col-20 grey-border-right-1">
          <div class="box-padding-1 period-flex-row">
            <div class="box-padding-container text-name-container">
              <span class="icon-space-1"></span>
              <span class="data-cd-name" id="data-cd-name-{{ i + 1 }}">{{ data.problemCdDisplay || data.productCdDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col col-80">
          <div class="row-container col-flex-grow height-full">
            <!--  -->
            <ng-container
              *ngFor="let quarter of quartersHeadings; last as isLastQuarter; index as quarterIndex"
            >
              <div class="row-container col col-15">
                <div class="col col-15"
                  [ngClass]="{
                    'grey-border-right-1': !isLastQuarter
                  }"
                >
                  <div
                    class="box-padding-1"
                    [ngSwitch]="!!data['quarter' + (quarterIndex + 1)]"
                  >
                    <span *ngSwitchCase="true">{{ data['quarter' + (quarterIndex + 1)].indCmplntPt | finraValidValue : 'percent' }}</span>
                    <span *ngSwitchCase="false">No information available for the period.</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
