import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  IconDefinition,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
import {
  COLUMN_DEFS,
  DISCLAIMERS,
  TABLE_TITLES,
  TABLE_NAMES_BY_ORDER,
  TOOLBAR_TEXTS,
} from 'src/app/configs/model/disclosure.model';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToolbarConfig, ToolbarConfigOptions } from 'src/app/configs/model/finra-toolbar.model';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { DisclosureToolbarConfig } from 'src/app/configs/toolbar-config/disclosure.toolbar';
import { BeastOtherActions, BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastLeaveReportPageInfo, BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { DisclosureService } from 'src/app/services/disclosure.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { HelperService } from 'src/app/services/helper.service';
import { ReportPageMetadataInfo, ReportService } from 'src/app/services/report.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';
import { saveCsv } from 'src/app/shared/utils/utils';

@Component({
  selector: 'report-disclosure',
  templateUrl: './disclosure.component.html',
  styleUrls: ['./disclosure.component.scss']
})
export class DisclosureComponent implements OnInit, OnDestroy {
  title: any;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  viewName: string;
  reportId: number;
  reportTypeId: number;
  reportName: string;
  reportDate: string;
  reportVersion: number;
  reportInstanceMetadata: ReportInstanceMetadata;
  rowData;
  columnDefs;
  tableTitles = TABLE_TITLES;
  tableNamesByOrder;
  disclaimers = DISCLAIMERS;
  headerTexts = TOOLBAR_TEXTS;
  peerGroupData;
  gridOptions;
  autoGroupColumnDefs;
  reportPageMetadataInfo: ReportPageMetadataInfo;
  disclosureToolbarConfig: ToolbarConfig;
  disclosureToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  periodChangeSubscription: Subscription;
  reportLoadedSuccessfully: boolean;
  loadedAt: number;

  constructor(
    private disclosureService: DisclosureService,
    private activatedRoute: ActivatedRoute,
    private baseReportService: ReportService,
    private helperService: HelperService,
    private toolbarService: ToolbarService,
    private unreadReportsService: UnreadReportsService,
    private uiWidgetsService: UiWidgetsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {
    this.toolbarForm = new FormGroup({
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl(),
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
        reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: '',
        rating: '',
        product: '',
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.reportVersion = parseInt(params.version, 10);
    this.reportId = parseInt(params.reportId, 10);
    this.reportTypeId = parseInt(params.type, 10);
    this.reportName = params.reportName;
    this.viewName = params.viewName;
    this.getReportConfigurationMetadata().subscribe(
      (pageData) => {
        this.processReportConfigurationMetadata(pageData);
        this.getSummaryReportData().subscribe((reportData) => {
          this.processSummaryReportData(reportData);
        });
      }
    );
  }

  setToolbar() {
    const customOptions: Partial<ToolbarConfigOptions> = {
      description: this.headerTexts[this.reportName],
      hideViewsCell: true,
      hideDetailsButton: this.reportName !== 'bdtime',
      hidePublishedState: false,
      setHelpClickHandler: () => {
        this.openHelp();
      },
    };
    const results = this.toolbarService.createToolbarConfig(
      this,
      DisclosureToolbarConfig,
      customOptions
    );
    this.disclosureToolbarConfigOptions = results.toolbarConfigOptions;
    this.disclosureToolbarConfig = results.toolbarConfig;
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          throw error;
        })
      );
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
    // Storing date for peer group call
    this.reportDate = this.reportInstanceMetadata.reportPeriodDate;
    this.title = this.reportInstanceMetadata.reportConfiguration.reportDisplayName;
    // Get Disclosure report after getting meta data
    this.setToolbar();
  }

  getSummaryReportData() {
    return this.baseReportService
      .getReport(this.reportId, this.viewName, 's')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportSummaryDataError(error);
          this.reportLoadedSuccessfully = false;
          throw error;
        })
      );
  }

  processSummaryReportData(response) {
    if (!response) {
      return;
    }
    try {
      this.rowData = this.formatReportData(response);
      const emptyReport = this.disclosureService.isReportDataEmpty(this.rowData);
      if (emptyReport) {
        this.reportLoadedSuccessfully = false;
        this.uiWidgetsService.showErrorSnackbar(
          `No report data to show...`
        );
        return;
      }
      this.columnDefs = COLUMN_DEFS[this.reportName];
      this.tableNamesByOrder = TABLE_NAMES_BY_ORDER[this.reportName];
      this.reportLoadedSuccessfully = true;
      // summary report loaded successfully; mark as read
      this.unreadReportsService.markReportAsRead(this.reportId);
    } catch (e) {
      this.reportLoadedSuccessfully = false;
      this.uiWidgetsService.showErrorSnackbar(
        `There was an error processing the summary data...`
      );
      throw e;
    }
  }

  onReportDetails() {
    const exportUrl: string = this.baseReportService.getReportUrl(
      this.reportId,
      this.viewName,
      'd'
    );
    this.helperService.downloadFile(exportUrl);
  }

  openHelp() {
    this.helpLinksService.open(this.reportName);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  formatReportData(data) {
    switch (this.reportName) {
      case 'd4530':
        return this.disclosureService.formatD4530Report(data, this.reportDate);
      case 'crd':
        return this.disclosureService.add12MonthsData(data, this.reportDate);
      default:
        return data;
    }
  }

  async summaryDataExport() {
    const fileNameResponse = await this.baseReportService.getReportFileName(
      this.reportId,
      this.viewName,
      's'
    ).toPromise();
    const csvString: string = this.disclosureService
      .DISCLOSURE_EXPORT_CONFIG[this.reportName](this.rowData);
    saveCsv(csvString, fileNameResponse.fileName);
    this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
  }
}
