<div id="msrb-container">
  <report-loader></report-loader>

  <h1 class="title report-main-content-padding pdf">{{ title }}</h1>
  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="msrbToolbarConfig"
    [toolbarConfig]="msrbToolbarConfig"
  ></report-finra-toolbar>

  <div id="data-container" *ngIf="reportLoadedSuccessfully">
    <due-diligence
      class="pdf"
      *ngIf="reportName == 'MDDR'"
      [rowData]="rowData"
      [reportInstanceMetadata]="reportInstanceMetadata"
      [reportId]="reportId"
      [viewName]="viewName"
      [reportName]="reportName"
      [title]="title"
      (handleError)="handleDetailError($event)"
    ></due-diligence>
    <div
      class="table-container table-{{ reportName }} pdf"
      *ngFor="let tableName of tableNamesByOrder"
    >
      <div class="table-header" *ngIf="tableTitles[tableName]">
        {{ tableTitles[tableName] }}
      </div>
      <report-finra-grid
        #reportfinragrid
        id="id"
        [columnDefs]="columnDefs[tableName]"
        [rowData]="rowData[tableName]"
      ></report-finra-grid>
    </div>
  </div>

  <hr />

  <div
    class="report-main-content-padding report-disclaimer pdf"
    *ngIf="footerMessages[reportName]"
    [innerHtml]="footerMessages[reportName]"
  ></div>
</div>
