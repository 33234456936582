import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpStatusCode } from '../configs/model/http-status-codes.model';
import { ReportInstanceMetadata, ReportInstanceMetadatasResponse } from '../configs/model/reports.model';
import { ReportsService } from './reports.service';

/**
 * Unread Reports Service
 * ---
 * This service is behaving almost exactly as the `DetailDatasetRequestsService`.
 * See that file's tsDoc for an explanation.
 *
 * NOTE:
 * - `listChanges` is for sharing the data throughout the app
 * - `newUnreadReports` is for notifying if there are new unread reports different from the initial list
 */
@Injectable({
  providedIn: 'root'
})
export class UnreadReportsService {
  private firstCall: boolean = false;
  private loadedBefore: boolean = false;
  private unreadReports: ReportInstanceMetadata[] = null; // the list of unread reports
  private unreadReportsMap = new Map<number, true>(); // track all unread reports by report id

  private listChanges = new BehaviorSubject<ReportInstanceMetadata[]>(null); // stream events of list changes
  private newUnreadReports = new BehaviorSubject<ReportInstanceMetadata[]>([]); // stream events of new unread reports

  constructor(
    private reportsService: ReportsService,
  ) {}

  isReportUnread(reportId: number): boolean {
    const reportIsUnread = this.unreadReportsMap.has(reportId);
    return reportIsUnread;
  }

  getUnReadReports() {
    if (this.loadedBefore) {
      return;
    }
    this.loadedBefore = true;
    this.reportsService.getUnReadReports().subscribe(
      (data: ReportInstanceMetadatasResponse) => {
        this.setUnreadReports(data.reportInstanceMetadatas);
      },
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpStatusCode.NOT_FOUND: {
            this.setUnreadReports([]);
            break;
          }
          default: {
            this.listChanges.error(null);
          }
        }
      }
    );
  }

  // similar to an ngrx store where you can select a part of the entire app state,
  // select the behavior subject that this service has.
  select(desiredBehaviorSubjectKey: string) {
    // find behavior subject by arg
    const subject = this[desiredBehaviorSubjectKey];
    // check if the behavior subject exists on this service
    const badKey = (
      !subject ||
      subject.constructor !== BehaviorSubject
    );
    if (badKey) {
      throw new ReferenceError(
        `UnreadReportsServiceError - no behavior subject exists on this service with the key "${desiredBehaviorSubjectKey}"`
      );
    }
    // if there is a behavior subject by arg key, return it as observable
    const observable = (<BehaviorSubject<ReportInstanceMetadata[]>> subject).asObservable();
    return observable;
  }

  setUnreadReports(
    unreadReports: ReportInstanceMetadata[]
  ) {
    // set the new data
    this.unreadReports = unreadReports;
    // check if first call;
    // we don't need to notifiy them of the initial list, which they can see
    if (!this.firstCall) {
      this.firstCall = true;
      // set the initial map values
      for (const unreadReport of this.unreadReports) {
        this.unreadReportsMap.set(unreadReport.reportId, true);
      }
      // emit the event for any other service/component that wants the data
      // send a copy of the data in a new array instance to trigger angular change detection(s)
      this.listChanges.next([ ...unreadReports ]);
    } else {
      // initial map already set,
      // emit the event for any other service/component that wants the data
      // send a copy of the data in a new array instance to trigger angular change detection(s)
      this.listChanges.next([ ...unreadReports ]);
      // check for changes
      this.checkChanges();
    }
  }

  markReportAsRead(reportId: number | string) {
    // ensure it is in integer type
    const useReportId = parseInt(reportId.toString(), 10);
    // check if the reportId is in the map
    const reportIsRead = !this.unreadReportsMap.has(useReportId);
    // if report is read, return; there is nothing else to do
    if (reportIsRead) {
      return;
    }
    // the map had that id as a key meaning report was unread;
    // remove from map
    const deleted = this.unreadReportsMap.delete(useReportId);
    // check if the list has a report by that id, there should be.
    // the map and list sizes should match
    const index = this.unreadReports.findIndex(report => report.reportId === useReportId);
    const resultFound = index > -1;
    if (resultFound) {
      this.unreadReports.splice(index, 1);
      // emit an event that the report is now read
      // pass the current list, the event will be a copy to trigger change detection
      this.setUnreadReports(this.unreadReports);
    }
  }

  private checkChanges() {
    // keep list of new reports not in the map,
    // if case we want to identify exactly the new unread reports
    const newUnreadReportsList: ReportInstanceMetadata[] = [];
    // loop through new list
    for (let i = 0; i < this.unreadReports.length; i++) {
      const unreadReport: ReportInstanceMetadata = this.unreadReports[i];
      // check if the report is being tracked
      const entry = this.unreadReportsMap.has(unreadReport.reportId);
      if (!entry) {
        // new unread report found; add to map and list
        this.unreadReportsMap.set(unreadReport.reportId, true);
        newUnreadReportsList.push(unreadReport);
      }
    }

    // if there were new unread reports that was not in the initial list,
    // notify the user by emiting an event of the new unread reports
    if (newUnreadReportsList.length) {
      this.newUnreadReports.next(newUnreadReportsList);
    }
  }
}
