<div class="peer-group-container" [attr.id]="modalRootDivId">
  <div class="close-container">
    <div class="pdf"><strong>Peer Group Firm IDs</strong></div>
    <fa-icon
      [icon]="faTimes"
      class="fa-lg fal close-icon"
      (click)="closeDilog()"
    ></fa-icon>
  </div>
  <div class="controls-container">
    <span data-html2canvas-ignore (click)="htmlPrint()">
      <fa-icon [icon]="faPrint" class="fa-lg dialog-tools-icon"></fa-icon>
      Print
      <fa-icon
        [icon]="faSpinner"
        [spin]="true"
        [hidden]="!isGeneratingPdf()"
      ></fa-icon>
    </span>
  </div>

  <div class="pdf">
    <p>{{ description }}</p>

    <div class="table">
      <div class="table-row" *ngIf="data.reportName">
        <div class="table-cell">Report Name:</div>
        <div class="table-cell">{{ data.reportName }}</div>
      </div>
      <div class="table-row" *ngIf="data.reportView">
        <div class="table-cell">Report View:</div>
        <div class="table-cell">{{ data.reportView }}</div>
      </div>
      <div class="table-row" *ngIf="data.period">
        <div class="table-cell">Period:</div>
        <div class="table-cell">{{ data.period }}</div>
      </div>
      <div class="table-row" *ngIf="data.tierCode">
        <div class="table-cell">{{ data.tierCodeLabel || "Tier" }}:</div>
        <div class="table-cell">{{ data.tierCode }}</div>
      </div>
      <div class="table-row" *ngIf="data.peerGroup">
        <div class="table-cell">Peer Group:</div>
        <div class="table-cell">{{ data.peerGroup }}</div>
      </div>
    </div>

    <report-finra-grid [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [rowData]="data.rowData"
                       [id]="peerPrintGridId"
                       (gridIsReady)="onGridReady($event)"
                       #finraGrid
    ></report-finra-grid>

  </div>
</div>
