// import { environment } from 'src/environments/environment';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChartData } from 'src/app/configs/model/chart-data.model';
import { Observable } from 'rxjs';

enum ChartsApiUrls {
  getRelationShips = 'relationships'
}

@Injectable({
  providedIn: 'root'
})
export class ChartDataService {
  private _getRelationShips: string = ChartsApiUrls.getRelationShips;
  private baseUrl: string;

  constructor(
    @Inject('environment')
    environment,
    private http: HttpClient
  ) {
    this.baseUrl = environment.baseUrl;
  }

  getRelationShips(): Observable<ChartData[]> {
    return this.http.get<ChartData[]>(this.baseUrl + this._getRelationShips);
  }
}
