import {
  Router,
  Event,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject
} from '@angular/core';
import { NavItem, mainNavItems } from 'src/app/configs/model/sidenavigation';
import {
  IconDefinition,
  faArrowLeft
} from '@fortawesome/pro-regular-svg-icons';
import { Location } from '@angular/common';
import { makeStringUrlPath, sort as arraySort } from 'src/app/shared/utils/utils';
import { HttpErrorResponse } from '@angular/common/http';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickMainNavInfo, BeastClickSubNavInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { ReportsService } from 'src/app/services/reports.service';
import { SidenavigationService, SidenavStateChange } from 'src/app/services/sidenavigation.service';

@Component({
  selector: 'report-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  onPremRcUrl: string;
  [x: string]: any;
  showExtended: boolean = false;
  activeParentUrl: string;
  private _navItems: Array<any>;
  private _homeUrl: string = '/home';
  private _logo: string = 'assets/img/finra-logo-short.svg';
  sideNavState: NavItem;
  selectedParent: NavItem;
  selectedChild: NavItem;
  navItemArray: NavItem[] = [];
  faArrowLeft: IconDefinition = faArrowLeft;
  @Output('raiseExtendedSideNavEvt') raiseExtendedSideNavEvt: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();
  @Input() activeLink: string;
  @Input() userAccessType: string;

  @Input()
  public set navItems(input: Array<NavItem>) {
    this._navItems = input;

    if (!this.selectedParent) {
      this.selectedParent = this._navItems[1];
      this.sideNavState = this._navItems[1];
    }
  }

  public get navItems(): Array<NavItem> {
    return this._navItems;
  }

  @Input()
  public set logo(input: string) {
    this._logo = input;
  }

  public get logo(): string {
    return this._logo;
  }

  public get homeUrl(): string {
    return this._homeUrl;
  }

  // Temp report categories that should open old RC OnPrem
  onPremRcReports = [
    7, // NYSE reports
    9, // Cboe reports
    11, // TSP reports
    1000 // test reports
  ];

  constructor(
    @Inject('environment')
    environment,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private reportsService: ReportsService,
    private sidenavService: SidenavigationService,
    private beastService: BeastService,
  ) {
    this.onPremRcUrl = environment.onPremRcUrl;
    this.activeParentUrl = this.router.url.split('/')[1];
  }

  ngOnInit() {
    this.navItemArray = [...this._navItems];

    this.getReportCategories();
    this.refreshExtendedNavStatus();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Navigation ended.
        this.activeParentUrl = event.url.split('/')[1];
      }
    });

    this.sidenavService.subscribe((sidenavStateChange: SidenavStateChange) => {
      this.handleSidenavStateChange(sidenavStateChange);
    });
  }

  setInitialNavState() {
    /**
     * Each `Route` config in `app.routing.ts` should have a `data` object property
     * that contains info on what navigation state/item it belongs to,
     * such as the main nav item and the extended nav name (if there should be. eg: equity).
     *
     * Since this sidenav component is being rendered only once in one other component (`AppComponent`),
     * the `this.activatedRoute` will point to `AppComponent` and `this.activatedRoute.firstChild` will
     * point to whatever current route is active; that route should have the above mentioned `data` info.
     * if not, go to `app.routing.ts` and set it; a `ReferenceError` error will occur otherwise.
     *
     * This method will read that data object to identify what the selected nav item should be
     * and what the extended nav item should be.
     *
     * Instead of using the data info directly, it is used as a point of reference (id, name, etc) because `this.navItemArray`
     * gets mutated by `this.getReportCategories()` and therefore has the actual data to show.
     *
     * Right now, every child in a nav item's `elementChildren` list has a `categoryDisplayOrder` property.
     * That will be used to identify which child to enable the `active` class on.
     *
     * Ideally, this application should be taking more advantage of Angular routing module by using things like
     * `routerLink` directive, `routerLinkActive` directive and etc. However, at this point (10/10/2019),
     * that would mean a major code refactor.
     *
     * For more info on Angular's routing,
     * @see: https://angular.io/guide/router
     */
    const currentActivatedRoute = this.activatedRoute.firstChild;
    const currentRouteNav = currentActivatedRoute.snapshot.data.mainNavItem;
    const currentRouteExtendedNav =
      currentActivatedRoute.snapshot.data.extendedNav;

    const navItem = this.navItemArray.find(i => i.id === currentRouteNav.id);

    if (navItem) {
      this.activeLink = navItem.id;
      this.selectedParent = navItem;
    } else {
      this.activeLink = this.navItemArray[1].id;
      this.selectedParent = this.navItemArray[1];
    }

    if (currentRouteExtendedNav) {
      this.showExtended = true;
      switch (navItem.id) {
        case mainNavItems['reports'].id: {
          this.selectedChild = navItem.elementChildren.find(
            i =>
              makeStringUrlPath(i.categoryDisplayName) ===
              currentRouteExtendedNav
          );
          break;
        }
        case mainNavItems['admin'].id: {
          this.selectedChild = navItem.elementChildren.find(
            i => i.route === currentRouteExtendedNav
          );
          break;
        }
      }
    } else {
      this.showExtended = false;
    }
  }

  handleSidenavStateChange(sidenavStateChange: SidenavStateChange) {
    const navigationItem = sidenavStateChange.navigationItem;
    const child = sidenavStateChange.child;
    if (navigationItem) {
      this.selectedParent = navigationItem;
      this.navItemArray = Object.keys(this.selectedParent).map(
        i => this.selectedParent[i]
      );
      this.activeLink = (this.selectedParent && this.selectedParent.id) || '1';
      this.showExtended = !!this.selectedParent.elementChildren.length
        ? true
        : false;
      if (child) {
        if (this.onPremRcReports.includes(child.reportCategoryId)) {
          return window.open(this.onPremRcUrl, 'On_Prem_RC');
        }
        this.selectedChild = child;
      }
    }
  }

  refreshExtendedNavStatus() {
    const path = this.location.path();
    const shouldHideExtended = (
      path === '/notifications' ||
      path === '/notifications/preferences' ||
      path === '/usage-log' ||
      path === '/home' ||
      path === '/upload' ||
      path === '/status' ||
      path === '/explorer' ||
      path === '' ||
      // DDWA-2835 Hide extended report nav for EWS SEC users
      this.userAccessType === 'EWS_SEC'
    );

    if (shouldHideExtended) {
      this.hideExtendedNav();
    }
    else {
      this.showExtended = true;
      this.raiseExtendedSideNavEvt.emit(this.showExtended);
    }
  }

  showExtendedNav(navItem: NavItem) {
    this.selectedParent = navItem;
    this.navItemArray = Object.keys(this.selectedParent).map(
      i => this.selectedParent[i]
    );
    this.activeLink = this.selectedParent.url ? this.selectedParent.id : '1';
    if (this.selectedParent.elementChildren.length) {
      this.showExtended = true;
      this.goToUrl(this.selectedParent.elementChildren[0]);
    } else {
      this.showExtended = false;
      if (this.selectedParent.url) {
        this.router.navigate(['/', this.selectedParent.url]);
      }
    }
    this.raiseExtendedSideNavEvt.emit(this.showExtended);

    const eventInfo: BeastClickMainNavInfo = { navigateTo: navItem.route };
    this.beastService.clickStream.postEvent(
      BeastClickActions.MAIN_NAVIGATION,
      eventInfo
    );
  }

  hideExtendedNav() {
    this.showExtended = false;
    this.raiseExtendedSideNavEvt.emit(this.showExtended);
  }

  goToUrl(child) {
    this.selectedChild = child;
    if (this.onPremRcReports.includes(child.reportCategoryId)) {
      window.open(this.onPremRcUrl, 'On_Prem_RC');
    } else {
      this.determineChildRoute(child);
    }
  }

  determineChildRoute(child) {
    let route;
    switch (this.activeLink) {
      case mainNavItems['reports'].id: {
        const childDisplayName = makeStringUrlPath(child.categoryDisplayName);
        route = childDisplayName;
        const childUrl = `${this.selectedParent.route}/${childDisplayName}/report-view/${child.reportCategoryId}`;
        this.router.navigateByUrl(childUrl);
        break;
      }
      case mainNavItems['admin'].id: {
        route = child.route;
        const childUrl = `${this.selectedParent.route}/report-view/${child.route}`;
        this.router.navigateByUrl(childUrl);
        break;
      }
      case mainNavItems['notifications'].id: {
        route = child.route;
        const childUrl = `${this.selectedParent.route}/${child.route}`;
        this.router.navigateByUrl(childUrl);
        break;
      }
      default: {

      }
    }

    const eventInfo: BeastClickSubNavInfo = {
      navigateTo: route,
      parentNav: this.selectedParent.route
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.SUB_NAVIGATION,
      eventInfo
    );
  }

  getReportCategories() {
    this._navItems.forEach(item => {
      if (item.route === 'reports' && !item['elementChildren'].length) {
        this.reportsService.getReportCategories().subscribe(
          (data: any) => {
            const reportCategories = arraySort(
              data['reportCategories'],
              'categoryName',
              false,
              true
            );

            item['elementChildren'] = reportCategories;

            this.setInitialNavState();
          },
          (error: HttpErrorResponse) => {
            throw error;
          }
        );
      }
    });
  }
}
