import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  faDownload,
  faPrint,
  faAngleDown,
  faFilePdf,
  faFileExcel,
  faSpinner,
  faQuestionCircle,
  IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToolbarConfig, ToolbarConfigOptions } from 'src/app/configs/model/finra-toolbar.model';
import { ONLINE_LEARNING_TITLE_1, ONLINE_LEARNING_TITLE_2, ONLINE_LEARNING_DISCLAIMER_1, ONLINE_LEARNING_TOOLBAR_HEADER, OnlineLearningReportShortNames } from 'src/app/configs/model/online-learning.model';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { OnlineLearningToolbarConfig } from 'src/app/configs/toolbar-config/online-learning.toolbar';
import { BeastOtherActions, BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastLeaveReportPageInfo, BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { HelperService } from 'src/app/services/helper.service';
import { OnlineLearningService } from 'src/app/services/online-learning.service';
import { GLOBAL_REPORT_DISCLAIMER_2, ReportService, ReportPageMetadataInfo } from 'src/app/services/report.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';
import { FINRA_GRID_NULL_VALUE, roundUptoTwoDecimal, quarterDateFormat, saveCsv } from 'src/app/shared/utils/utils';



@Component({
  selector: 'report-online-learning',
  templateUrl: './online-learning.component.html',
  styleUrls: ['./online-learning.component.scss']
})
export class OnlineLearningComponent implements OnInit, OnDestroy {
  faQuestionCircle: IconDefinition = faQuestionCircle;
  faDownload: IconDefinition = faDownload;
  faPrint: IconDefinition = faPrint;
  faAngleDown: IconDefinition = faAngleDown;
  faFilePdf: IconDefinition = faFilePdf;
  faFileExcel: IconDefinition = faFileExcel;
  faSpinner: IconDefinition = faSpinner;
  nullValue = FINRA_GRID_NULL_VALUE;
  roundUptoTwoDecimal = roundUptoTwoDecimal;
  periodFormatter = quarterDateFormat;
  reportInstanceMetadata: any;
  viewName: string;
  reportId: number;
  reportName: string;
  reportDate: string;
  reportVersion: number;
  firmId: string;
  data: any = {};
  currentSession: string = '';
  currentModuleType: string;
  error;
  errorMessage: string;
  hasPeriodData = false;
  title: string = ONLINE_LEARNING_TITLE_1;
  sessionsHeading: string = ONLINE_LEARNING_TITLE_2;
  sessionList = [];
  disclaimer =
    ONLINE_LEARNING_DISCLAIMER_1 +
    GLOBAL_REPORT_DISCLAIMER_2;
  noPeriodDataMessage = 'Loading Data';
  downloadFileName: string = 'OnlineLearning';
  onlineLearningToolbarConfig: ToolbarConfig;
  onlineLearningToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  reportPageMetadataInfo;
  periodChangeSubscription: Subscription;
  reportLoadedSuccessfully: boolean;
  loadedAt: number;

  get showOtherColumn(): boolean {
    return this.currentSession === 'S101';
  }

  constructor(
    private titleService: Title,
    private onlineLearningService: OnlineLearningService,
    private activatedRoute: ActivatedRoute,
    private baseReportService: ReportService,
    private helperService: HelperService,
    private toolbarService: ToolbarService,
    public dialog: MatDialog,
    private unreadReportsService: UnreadReportsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {
    this.titleService.setTitle(this.title);
    this.toolbarForm = new FormGroup({
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl(),
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
        reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: '',
        rating: '',
        product: '',
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.viewName = params.viewName;
    this.reportId = params.reportId;
    this.reportName = params.reportName;
    this.reportVersion = parseInt(params.version, 10);
    this.getReportConfigurationMetadata().subscribe(
      (pageData: ReportPageMetadataInfo) => {
        this.processReportConfigurationMetadata(pageData);
        this.setToolbar();
        this.getSummaryData(pageData.reportInstanceMetadata).subscribe((reportData) => {
          this.processSummaryData(reportData);
        });
      }
    );
  }

  setToolbar() {
    const customOptions: Partial<ToolbarConfigOptions> = {
      hidePublishedState: false,
      description: ONLINE_LEARNING_TOOLBAR_HEADER,
      setHelpClickHandler: () => {
        this.openHelp();
      },
    };
    const results = this.toolbarService.createToolbarConfig(
      this,
      OnlineLearningToolbarConfig,
      customOptions
    );
    this.onlineLearningToolbarConfigOptions = results.toolbarConfigOptions;
    this.onlineLearningToolbarConfig = results.toolbarConfig;
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          throw error;
        })
      );
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    this.error = null;
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
  }

  getSummaryData(reportInstanceMetadata: ReportInstanceMetadata): Observable<object> {
    return this.onlineLearningService.getSummaryData(reportInstanceMetadata.reportId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportSummaryDataError(error);
          this.reportLoadedSuccessfully = false;
          throw error;
        })
      );
  }

  processSummaryData(data: { [key: string]: any; }) {
    this.error = null;
    this.data = data;
    this.hasPeriodData = (
      data != null &&
      data.totalSessionsTable.length > 0
    );
    if (this.hasPeriodData) {
      this.currentSession = this.data.totalSessionsTable[0].name;
    } else {
      this.noPeriodDataMessage = 'No data available for this period';
    }
    this.reportLoadedSuccessfully = true;
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  showModuleType(type: string) {
    const result = type !== this.currentModuleType;
    this.currentModuleType = type;
    return result;
  }

  switchView(sessionProgramName: string) {
    this.currentSession = sessionProgramName;
  }

  async summaryDataExport() {
    const fileNameResponse = await this.baseReportService.getReportFileName(
      this.reportId,
      this.viewName,
      's'
    ).toPromise();
    const csvString = this.onlineLearningService.exportSummaryData(this.data);
    saveCsv(csvString, fileNameResponse.fileName);
    this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
  }

  openHelp() {
    this.helpLinksService.open(OnlineLearningReportShortNames.CE_REGULATORY_ELEMENT);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}
