import {
  Component,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AdminService } from 'src/app/services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  REPORT_STATUS_COLUMN_DEFS,
  HELP_LINK,
} from 'src/app/configs/model/report-status.model';
import {
  IconDefinition,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
import { HelperService } from 'src/app/services/helper.service';
import { weakSort } from 'src/app/shared/utils/utils';
import { ReportStatusesResponse } from 'src/app/configs/model/admin.model';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';


@Component({
  selector: 'report-admin-report-status',
  templateUrl: './report-status.component.html',
  styleUrls: ['./report-status.component.scss']
})
export class AdminReportStatusComponent implements OnInit {
  title = 'Report Center Report Status';
  columnDef = REPORT_STATUS_COLUMN_DEFS;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  tableNames: string[] = [];
  responseData: ReportStatusesResponse;
  dataIsReady: boolean;

  constructor(
    private adminService: AdminService,
    private helperService: HelperService,
    private titleService: Title,
    private uiWidgetsService: UiWidgetsService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`Admin - Report Status`);
    this.adminService.getReportStatuses().subscribe(
      (response: ReportStatusesResponse) => {
        this.handleReportStatuses(response);
      },
      (error: HttpErrorResponse) => {
        this.handleReportStasusesError(error);
      }
    );
  }

  handleReportStatuses(response: ReportStatusesResponse) {
    this.responseData = response;
    this.tableNames = Object.keys(response);
    weakSort(this.tableNames, 'string');
    this.dataIsReady = true;
  }

  handleReportStasusesError(error: HttpErrorResponse) {
    this.dataIsReady = false;
    const errorMessage = error.status === 404
      ?  'No reports found...'
      : 'Could not load data...';
    this.uiWidgetsService.showErrorSnackbar(errorMessage);
  }

  openHelp() {
    window.open(HELP_LINK, '_blank');
  }
}
