import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  IconDefinition,
  faQuestionCircle
} from '@fortawesome/pro-regular-svg-icons';
import { DOWNLOAD_FILE_NAMES } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import {
  CanceledAsOfTradesMonthly,
  AllTradesMonthlySummary,
  AsOfTradesMonthlySummary,
  BeforeSettlementTradesMonthlySummary,
  AfterSettlementTradesMonthlySummary,
} from 'src/app/configs/model/risk-monitoring/canceled-as-of-trades.model';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';



@Component({
  selector: 'report-canceled-as-of-trades',
  templateUrl: './canceled-as-of-trades.component.html',
  styleUrls: ['./canceled-as-of-trades.component.scss']
})
export class CanceledAsOfTradesComponent implements OnInit, OnChanges {
  faQuestionCircle: IconDefinition = faQuestionCircle;
  downloadFileNames = DOWNLOAD_FILE_NAMES;

  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  @Input() canceledAndAsOfTradesMonthly: CanceledAsOfTradesMonthly[] = [];
  @Input() allTradesMonthly: AllTradesMonthlySummary[] = [];
  @Input() asOfTradesMonthly: AsOfTradesMonthlySummary[] = [];
  @Input() beforeSettlementTradesMonthly: BeforeSettlementTradesMonthlySummary[] = [];
  @Input() afterSettlementTradesMonthly: AfterSettlementTradesMonthlySummary[] = [];

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
  }
}
