/**
 * MSRB - Due Diligence Summary Report
 */

export const FIELD_MAPPINGS = {
  period: 'firm_periodStartDate',
  firmTotalIssuanceCount: 'firm_totalIssuanceCount',
  firmLCFCount: 'firm_issuancePreviousLackingCurrentFinancialsCount',
  firmLCFPercent: 'firm_percentIssuancePreviousLackingCurrentFinancialsCount',
  peerGroupNumber: 'peer_peerGroupIdentifier',
  peerTotalIssuanceCount: 'peer_totalIssuanceCount',
  peerLCFCount: 'peer_issuancePreviousLackingCurrentFinancialsCount',
  peerLCFMeanPercent:
    'peer_averageIssuancePreviousLackingCurrentFinancialsCount',
  peerLCFMedianPercent:
    'peer_medianIssuancePreviousLackingCurrentFinancialsCount',
  industryTotalIssuanceCount: 'industry_totalIssuanceCount',
  industryLCFCount: 'industry_issuancePreviousLackingCurrentFinancialsCount',
  industryLCFMeanPercent:
    'industry_averageIssuancePreviousLackingCurrentFinancialsCount',
  industryLCFMedianPercent:
    'industry_medianIssuancePreviousLackingCurrentFinancialsCount'
};

export const MAIN_HEADERS = [
  '',
  'Firm',
  '',
  '',
  'Peer Group',
  '',
  '',
  '',
  '',
  'Industry',
  '',
  '',
  ''
];
export const PARENT_HEADERS = [
  'Period',
  'Total Issuance',
  'Issuance Where Previous Issues were LCF',
  '',
  'Peer Group',
  'Total Issuance',
  'Issuance Where Previous Issues were LCF',
  '',
  '',
  'Total Issuance',
  'Issuance Where Previous Issues were LCF',
  '',
  ''
];

export const PARENT_HEADERS_L2 = [
  '',
  '',
  'Count',
  '%',
  '',
  '',
  'Count',
  'Mean %',
  'Median %',
  '',
  'Count',
  'Mean %',
  'Median %'
];

export const CHILD_HEADERS = [
  'Submission ID',
  'Issuer',
  'Issue Description',
  'Issuing CUSIP6',
  'Closing Date of Issuing CUSIP',
  '',
  '',
  ''
];
