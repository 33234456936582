import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  IconDefinition,
  faChevronRight,
  faChevronDown,
  faQuestionCircle,
  faDownload,
} from '@fortawesome/pro-light-svg-icons';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { TradesMonthlyListUnion, TradesMonthlyUnion } from 'src/app/configs/model/risk-monitoring/canceled-as-of-trades.model';
import { RiskMonitoringReportNames } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickEventDetailExportInfo, BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { RiskMonitoringService } from 'src/app/services/risk-monitoring.service';



@Component({
  selector: 'report-trades-grid',
  templateUrl: './trades-grid.component.html',
  styleUrls: ['./trades-grid.component.scss']
})
export class TradesGridComponent implements OnInit {
  @Input() title: string;
  @Input() summaryData: TradesMonthlyListUnion;
  @Input() isAllTrades: boolean;
  @Input() reportInstanceMetadata: ReportInstanceMetadata;

  viewName: 'Daily' = `Daily`;
  faChevronRight: IconDefinition = faChevronRight;
  faChevronDown: IconDefinition = faChevronDown;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  faDownload: IconDefinition = faDownload;

  constructor(
    private riskMonitoringService: RiskMonitoringService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {}

  exportMonthDetails(summary: TradesMonthlyUnion) {
    const shortName = this.reportInstanceMetadata.reportConfiguration.reportType.reportTypeShortName;
    const reportFirmId = this.reportInstanceMetadata.reportFirmId;
    this.riskMonitoringService.customerDebitsCanceledAsOfTradesOnReportDetails(
      shortName,
      reportFirmId,
      summary.reportPeriod,
      summary.reportId,
      this.viewName
    );

    // log beast event
    const eventInfo: BeastClickEventDetailExportInfo = {
      reportId: this.reportInstanceMetadata.reportId.toString(),
      reportPeriod: this.datePipe.transform(summary.reportPeriod, 'yyyy-MM-dd'),
      reportView: this.viewName,
      reportVersion: this.reportInstanceMetadata.reportDataVersion,
      reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
      maturity: undefined,
      rating: undefined,
      product: undefined,
      firmId: reportFirmId,
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_DETAIL_DOWNLOAD_FROM_REPORT_PAGE,
      eventInfo
    );
  }

  openHelp() {
    this.helpLinksService.open(RiskMonitoringReportNames.CANCELED_AS_OF_TRADES);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}
