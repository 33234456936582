<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
  <a>
    <div fxLayout="column" fxLayoutAlign="center end">
      <button mat-button [matMenuTriggerFor]="userProfileMenu">
        <span class="mat-caption">{{ getLabel() }} </span>
        <fa-icon [icon]="faUserCircle" class="fa-lg"></fa-icon>
      </button>
    </div>

    <mat-menu #userProfileMenu>
      <button mat-menu-item disabled>
        <span class="mat-caption">Name </span> {{ userProfile.firstName }}
        {{ userProfile.lastName }}
      </button>
      <button
        mat-menu-item
        disabled
        class="mat-caption"
        *ngIf="!isExternalUser()"
      >
        <span class="mat-caption">Type </span> {{ userProfile.userType }}
      </button>
      <button
        mat-menu-item
        disabled
        class="mat-caption"
        *ngIf="!isExternalUser()"
      >
        <span class="mat-caption">Email </span> {{ userProfile.email }}
      </button>
      <button
        mat-menu-item
        disabled
        *ngIf="
          !isExternalUser() &&
          userProfile.equityMpids &&
          userProfile.equityMpids.length > 0
        "
      >
        <span class="mat-caption">MPID </span>
        <span *ngFor="let mpid of userProfile.equityMpids; first as isFirst">
          <span *ngIf="!isFirst">, </span><span>{{ mpid }}</span>
        </span>
      </button>
      <button
        mat-menu-item
        class="mat-caption"
        (click)="fireLogout($event)"
        *ngIf="userProfile.userType === 'EWS'"
      >
        Logout
      </button>
    </mat-menu>
  </a>
</div>
