import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FinraGridClickableCellComponent } from 'src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component';
import { addFinraGridColumnId, getMonthYrDate, finraGridNumericValueFormatter, finraGridPercentValueFormatter } from 'src/app/shared/utils/utils';

export interface G32DataInterface {
  periodStartDate: string;
  sortOrder: number;
}

export interface AllSubmittedFilingsData extends G32DataInterface {
  industryLateTotalFilingsMedianPercent: number;
  industryLateTotalFilingsPercent: number;
  industryLateTotalFilingsWeightedPercent: number;
  industryPopulationCount: number;
  industryTotalFilingsRankNumber: number;
  lateTotalFilingsNumber: number;
  lateTotalFilingsPercent: number;
  peerGroupCount: number;
  peerLateTotalFilingsMedianPercent: number;
  peerLateTotalFilingsPercent: number;
  peerLateTotalFilingsWeightedPercent: number;
  peerPopulationCount: number;
  peerTotalFilingsRankNumber: number;
  totalFilingsNumber: number;
}

export interface OfficialStatementsData extends G32DataInterface {
  industryLateOfficialStatementSubmissionsPercent: number;
  industryLatePreliminaryOfficialStatementSubmissionsPercent: number;
  lateOfficialStatementSubmissionsPercent: number;
  lateOfficialStatementSubmissionstNumber: number;
  latePreliminaryOfficialStatementSubmissionsNumber: number;
  latePreliminaryOfficialStatementSubmissionsPercent: number;
  totalOfficialStatementSubmissionsNumber: number;
  totalPreliminaryOfficialStatementSubmissionsNumber: number;
}

export interface RemarketingData extends G32DataInterface {
  industryLateOfficialStatementRemarketingSupplementsAmendmentPercent: number;
  industryLateRemarketingSupplementsPercent: number;
  lateOfficialStatementRemarketingSupplementsAmendmentNumber: number;
  lateOfficialStatementRemarketingSupplementsAmendmentPercent: number;
  lateRemarketingSupplementsNumber: number;
  lateRemarketingSupplementsPercent: number;
  periodStartDate: string;
  sortOrder: number;
  totalOfficialStatementRemarketingSupplementsAmendmentNumber: number;
  totalRemarketingSupplementsNumber: number;
}

export interface AdvancedRefundingData extends G32DataInterface {
  industryLateAdvanceRefundingDocumentsAmendmentPercent: number;
  industryLateAdvanceRefundingDocumentsPercent: number;
  lateAdvanceRefundingDocumentsAmendmentNumber: number;
  lateAdvanceRefundingDocumentsAmendmentPercent: number;
  lateAdvanceRefundingDocumentsNumber: number;
  lateAdvanceRefundingDocumentsPercent: number;
  totalAdvanceRefundingDocumentsAmendmentNumber: number;
  totalAdvanceRefundingDocumentsNumber: number;
}

export interface MunicipalFundSecutiryData extends G32DataInterface {
  industryLateMunicipalFundSecurityDisclosuresPercent: number;
  industryLateMunicipalFundSecurityDisclosuresSupplementPercent: number;
  lateMunicipalFundSecurityDisclosuresNumber: number;
  lateMunicipalFundSecurityDisclosuresPercent: number;
  lateMunicipalFundSecurityDisclosuresSupplementNumber: number;
  lateMunicipalFundSecurityDisclosuresSupplementPercent: number;
  totalMunicipalFundSecurityDisclosuresNumber: number;
  totalMunicipalFundSecurityDisclosuresSupplementNumber: number;
}

export interface ExemptFilingsData extends G32DataInterface {
  industryLateCancellationNumber: number;
  industryLateExemptFilingPercent: number;
  lateExemptFilingNumber: number;
  lateExemptFilingPercent: number;
  totalCancellationNumber: number;
  totalExemptFilingNumber: number;
}

export interface G32SummaryResponse {
  firmIndustry: AllSubmittedFilingsData[];
  officialStatement: OfficialStatementsData[];
  remarketing: RemarketingData[];
  advanceRefunding: AdvancedRefundingData[];
  municipalFund: MunicipalFundSecutiryData[];
  exemptFilings: ExemptFilingsData[];
}

export const NO_INFO_TEXT = 'No information available for the period.';

export function checkG32DataValid(
  data:
    | AllSubmittedFilingsData
    | OfficialStatementsData
    | RemarketingData
    | AdvancedRefundingData
    | MunicipalFundSecutiryData
    | ExemptFilingsData
) {
  const dataCopy = { ...data };
  delete dataCopy.periodStartDate;
  delete dataCopy.sortOrder;

  const keys = Object.keys(dataCopy);
  const onePropHasValidValue = keys.some(key => dataCopy[key] !== null);
  return onePropHasValidValue;
}

// AG-GRID column definitions //

/**
 * @see: https://reportcenter.qa.finra.org/scrc/view_rpt.do?rptId=5386172&viewId=615&bgurl=show_rpt&backCatID=3
 */

export const ALL_SUBMITTED_FILINGS_COLUMN_DEFS = (
  peerGroupNumberClickHandler: (params) => void
) =>
  addFinraGridColumnId([
    {
      headerName: 'Period',
      children: [
        {
          headerName: '',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data).periodStartDate,
          valueFormatter: params => getMonthYrDate(params.value)
        },
        {
          headerName: '', // download details cell
          isDetailLink: true
        }
      ]
    },
    {
      headerName: 'Firm',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: 'Total',
          headerClass: 'lines',
          cellClass: 'lines',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data).totalFilingsNumber,
          // valueFormatter: finraGridNumericValueFormatter,
          valueFormatter: params => {
            const quarterHasData = checkG32DataValid(params.data);
            return quarterHasData
              ? finraGridNumericValueFormatter(params)
              : NO_INFO_TEXT;
          },
          // cellRenderer: aggCellRenderer,
          colSpan: function(params) {
            const quarterHasNoData = !checkG32DataValid(params.data);
            return quarterHasNoData ? 14 : 1;
          }
        },
        {
          headerName: 'Late',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data).lateTotalFilingsNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: '% Late',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data).lateTotalFilingsPercent,
          valueFormatter: finraGridPercentValueFormatter
        }
      ]
    },
    {
      headerName: 'Industry',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: '% Late',
          headerClass: 'lines',
          cellClass: 'lines',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data)
              .industryLateTotalFilingsPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Weighted % Late',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data)
              .industryLateTotalFilingsWeightedPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Median % Late',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data)
              .industryLateTotalFilingsMedianPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Rank',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data)
              .industryTotalFilingsRankNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: 'Population',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data).industryPopulationCount,
          valueFormatter: finraGridNumericValueFormatter
        }
      ]
    },
    {
      headerName: 'Peer Group',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: 'Peer Group',
          headerClass: 'lines',
          cellClass: 'lines',
          // valueGetter: (params) => (<TransactionsData>params.data).peerGroupCount,
          // valueFormatter: finraGridNumericValueFormatter,
          cellRendererFramework: FinraGridClickableCellComponent,
          cellRendererFrameworkOptions: {
            isClickable: params => true,
            buttonClass: param => 'peer-group-link',
            buttonLabel: params =>
              (<AllSubmittedFilingsData>params.data).peerGroupCount,
            handleClick: peerGroupNumberClickHandler
          }
        },
        {
          headerName: '% Late',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data).peerLateTotalFilingsPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Weighted % Late',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data)
              .peerLateTotalFilingsWeightedPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Median % Late',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data)
              .peerLateTotalFilingsMedianPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Rank',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data).peerTotalFilingsRankNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: 'Population',
          valueGetter: params =>
            (<AllSubmittedFilingsData>params.data).peerPopulationCount,
          valueFormatter: finraGridNumericValueFormatter
        }
      ]
    }
  ]);

export const OFFICIAL_PRELIMINARY_SUBMISSIONS_COLUMN_DEFS = addFinraGridColumnId(
  [
    {
      headerName: 'Period',
      valueGetter: params =>
        (<OfficialStatementsData>params.data).periodStartDate,
      valueFormatter: params => getMonthYrDate(params.value)
    },
    {
      headerName: 'Official Statement (OS) Submissions',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: 'Total',
          headerClass: 'lines',
          cellClass: 'lines',
          valueGetter: params =>
            (<OfficialStatementsData>params.data)
              .totalOfficialStatementSubmissionsNumber,
          // valueFormatter: finraGridNumericValueFormatter,
          valueFormatter: params => {
            const quarterHasData = checkG32DataValid(params.data);
            return quarterHasData
              ? finraGridNumericValueFormatter(params)
              : NO_INFO_TEXT;
          },
          // cellRenderer: aggCellRenderer,
          colSpan: function(params) {
            const quarterHasNoData = !checkG32DataValid(params.data);
            return quarterHasNoData ? 8 : 1;
          }
        },
        {
          headerName: 'Late',
          valueGetter: params =>
            (<OfficialStatementsData>params.data)
              .lateOfficialStatementSubmissionstNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: '% Late',
          valueGetter: params =>
            (<OfficialStatementsData>params.data)
              .lateOfficialStatementSubmissionsPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Industry % Late',
          valueGetter: params =>
            (<OfficialStatementsData>params.data)
              .industryLateOfficialStatementSubmissionsPercent,
          valueFormatter: finraGridPercentValueFormatter
        }
      ]
    },
    {
      headerName: 'Preliminary OS Submissions',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: 'Total',
          headerClass: 'lines',
          cellClass: 'lines',
          valueGetter: params =>
            (<OfficialStatementsData>params.data)
              .totalPreliminaryOfficialStatementSubmissionsNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: 'Late',
          valueGetter: params =>
            (<OfficialStatementsData>params.data)
              .latePreliminaryOfficialStatementSubmissionsNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: '% Late',
          valueGetter: params =>
            (<OfficialStatementsData>params.data)
              .latePreliminaryOfficialStatementSubmissionsPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Industry % Late',
          valueGetter: params =>
            (<OfficialStatementsData>params.data)
              .industryLatePreliminaryOfficialStatementSubmissionsPercent,
          valueFormatter: finraGridPercentValueFormatter
        }
      ]
    }
  ]
);

export const REMARKETING_SUPPLEMENTS_AMENDMENTS_COLUMN_DEFS = addFinraGridColumnId(
  [
    {
      headerName: 'Period',
      valueGetter: params => (<RemarketingData>params.data).periodStartDate,
      valueFormatter: params => getMonthYrDate(params.value)
    },
    {
      headerName: 'Remarketing Supplements',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: 'Total',
          headerClass: 'lines',
          cellClass: 'lines',
          valueGetter: params =>
            (<RemarketingData>params.data).totalRemarketingSupplementsNumber,
          // valueFormatter: finraGridNumericValueFormatter,
          valueFormatter: params => {
            const quarterHasData = checkG32DataValid(params.data);
            return quarterHasData
              ? finraGridNumericValueFormatter(params)
              : NO_INFO_TEXT;
          },
          // cellRenderer: aggCellRenderer,
          colSpan: function(params) {
            const quarterHasNoData = !checkG32DataValid(params.data);
            return quarterHasNoData ? 8 : 1;
          }
        },
        {
          headerName: 'Late',
          valueGetter: params =>
            (<RemarketingData>params.data).lateRemarketingSupplementsNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: '% Late',
          valueGetter: params =>
            (<RemarketingData>params.data).lateRemarketingSupplementsPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Industry % Late',
          valueGetter: params =>
            (<RemarketingData>params.data)
              .industryLateRemarketingSupplementsPercent,
          valueFormatter: finraGridPercentValueFormatter
        }
      ]
    },
    {
      headerName: 'OS and Remarketing Amendments',
      headerClass: 'lines',
      cellClass: 'lines',
      children: [
        {
          headerName: 'Total',
          headerClass: 'lines',
          cellClass: 'lines',
          valueGetter: params =>
            (<RemarketingData>params.data)
              .totalOfficialStatementRemarketingSupplementsAmendmentNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: 'Late',
          valueGetter: params =>
            (<RemarketingData>params.data)
              .lateOfficialStatementRemarketingSupplementsAmendmentNumber,
          valueFormatter: finraGridNumericValueFormatter
        },
        {
          headerName: '% Late',
          valueGetter: params =>
            (<RemarketingData>params.data)
              .lateOfficialStatementRemarketingSupplementsAmendmentPercent,
          valueFormatter: finraGridPercentValueFormatter
        },
        {
          headerName: 'Industry % Late',
          valueGetter: params =>
            (<RemarketingData>params.data)
              .industryLateOfficialStatementRemarketingSupplementsAmendmentPercent,
          valueFormatter: finraGridPercentValueFormatter
        }
      ]
    }
  ]
);

export const ADVANCE_REFUNDING_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Period',
    valueGetter: params => (<AdvancedRefundingData>params.data).periodStartDate,
    valueFormatter: params => getMonthYrDate(params.value)
  },
  {
    headerName: 'Advance Refunding Documents',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Total',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<AdvancedRefundingData>params.data)
            .totalAdvanceRefundingDocumentsNumber,
        // valueFormatter: finraGridNumericValueFormatter,
        valueFormatter: params => {
          const quarterHasData = checkG32DataValid(params.data);
          return quarterHasData
            ? finraGridNumericValueFormatter(params)
            : NO_INFO_TEXT;
        },
        // cellRenderer: aggCellRenderer,
        colSpan: function(params) {
          const quarterHasNoData = !checkG32DataValid(params.data);
          return quarterHasNoData ? 8 : 1;
        }
      },
      {
        headerName: 'Late',
        valueGetter: params =>
          (<AdvancedRefundingData>params.data)
            .lateAdvanceRefundingDocumentsNumber,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '% Late',
        valueGetter: params =>
          (<AdvancedRefundingData>params.data)
            .lateAdvanceRefundingDocumentsPercent,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Industry % Late',
        valueGetter: params =>
          (<AdvancedRefundingData>params.data)
            .industryLateAdvanceRefundingDocumentsPercent,
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  },
  {
    headerName: 'Advance Refunding Amendments',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Total',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<AdvancedRefundingData>params.data)
            .totalAdvanceRefundingDocumentsAmendmentNumber,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Late',
        valueGetter: params =>
          (<AdvancedRefundingData>params.data)
            .lateAdvanceRefundingDocumentsAmendmentNumber,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '% Late',
        valueGetter: params =>
          (<AdvancedRefundingData>params.data)
            .lateAdvanceRefundingDocumentsAmendmentPercent,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Industry % Late',
        valueGetter: params =>
          (<AdvancedRefundingData>params.data)
            .industryLateAdvanceRefundingDocumentsAmendmentPercent,
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  }
]);

export const MUNICIPAL_FUND_SECURITY_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Period',
    valueGetter: params =>
      (<MunicipalFundSecutiryData>params.data).periodStartDate,
    valueFormatter: params => getMonthYrDate(params.value)
  },
  {
    headerName: 'Municipal Fund Security Disclosures',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Total',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<MunicipalFundSecutiryData>params.data)
            .totalMunicipalFundSecurityDisclosuresNumber,
        // valueFormatter: finraGridNumericValueFormatter,
        valueFormatter: params => {
          const quarterHasData = checkG32DataValid(params.data);
          return quarterHasData
            ? finraGridNumericValueFormatter(params)
            : NO_INFO_TEXT;
        },
        // cellRenderer: aggCellRenderer,
        colSpan: function(params) {
          const quarterHasNoData = !checkG32DataValid(params.data);
          return quarterHasNoData ? 8 : 1;
        }
      },
      {
        headerName: 'Late',
        valueGetter: params =>
          (<MunicipalFundSecutiryData>params.data)
            .lateMunicipalFundSecurityDisclosuresNumber,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '% Late',
        valueGetter: params =>
          (<MunicipalFundSecutiryData>params.data)
            .lateMunicipalFundSecurityDisclosuresPercent,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Industry % Late',
        valueGetter: params =>
          (<MunicipalFundSecutiryData>params.data)
            .industryLateMunicipalFundSecurityDisclosuresPercent,
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  },
  {
    headerName: 'Municipal Fund Security Disclosure Supplements',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Total',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<MunicipalFundSecutiryData>params.data)
            .totalMunicipalFundSecurityDisclosuresSupplementNumber,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Late',
        valueGetter: params =>
          (<MunicipalFundSecutiryData>params.data)
            .lateMunicipalFundSecurityDisclosuresSupplementNumber,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '% Late',
        valueGetter: params =>
          (<MunicipalFundSecutiryData>params.data)
            .lateMunicipalFundSecurityDisclosuresSupplementPercent,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Industry % Late',
        valueGetter: params =>
          (<MunicipalFundSecutiryData>params.data)
            .industryLateMunicipalFundSecurityDisclosuresSupplementPercent,
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  }
]);

export const EXEMPT_FILINGS_CANCELLATIONS_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Period',
    valueGetter: params => (<ExemptFilingsData>params.data).periodStartDate,
    valueFormatter: params => getMonthYrDate(params.value)
  },
  {
    headerName: '15c2-12 Exempt Filings with No OS or POS Submitted',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Total',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<ExemptFilingsData>params.data).totalExemptFilingNumber,
        // valueFormatter: finraGridNumericValueFormatter,
        valueFormatter: params => {
          const quarterHasData = checkG32DataValid(params.data);
          return quarterHasData
            ? finraGridNumericValueFormatter(params)
            : NO_INFO_TEXT;
        },
        // cellRenderer: aggCellRenderer,
        colSpan: function(params) {
          const quarterHasNoData = !checkG32DataValid(params.data);
          return quarterHasNoData ? 6 : 1;
        }
      },
      {
        headerName: 'Late',
        valueGetter: params =>
          (<ExemptFilingsData>params.data).lateExemptFilingNumber,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: '% Late',
        valueGetter: params =>
          (<ExemptFilingsData>params.data).lateExemptFilingPercent,
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'Industry % Late',
        valueGetter: params =>
          (<ExemptFilingsData>params.data).industryLateExemptFilingPercent,
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  },
  {
    headerName: 'Cancellations',
    headerClass: 'lines',
    cellClass: 'lines',
    children: [
      {
        headerName: 'Total',
        headerClass: 'lines',
        cellClass: 'lines',
        valueGetter: params =>
          (<ExemptFilingsData>params.data).totalCancellationNumber,
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Industry Total',
        valueGetter: params =>
          (<ExemptFilingsData>params.data).industryLateCancellationNumber,
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  }
]);

// AG-GRID column definition (Submitted View) //

/**
 * @see: https://reportcenter.qa.finra.org/scrc/view_rpt.do?rptId=5386172&viewId=616&sortBy=sbmtrMsrbId
 */

export const SUBMITTED_VIEW_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Filing Status',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Submitter ID',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Submission Identifier',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Offering Type',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Doc Type',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Issue Type',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Security Type',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Issuer',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Issue Description',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'CUSIP',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Final Maturity',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Maturity Principal Amount',
    headerClass: 'lines',
    cellClass: 'lines',
    // valueGetter: (params) => (<any>params.data).null,
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: "Date Rec'd from Issuer",
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Closing Date',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'Submission Date',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  },
  {
    headerName: 'ssuer Time Span',
    headerClass: 'lines',
    cellClass: 'lines',
    // valueGetter: (params) => (<any>params.data).null,
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Closing Time Span',
    headerClass: 'lines',
    cellClass: 'lines',
    // valueGetter: (params) => (<any>params.data).null,
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Details on EMMA',
    headerClass: 'lines',
    cellClass: 'lines'
    // valueGetter: (params) => (<any>params.data).null,
  }
]);

// export all columns definitions

export const G_32_COLUMN_DEFS = {
  firmIndustry: ALL_SUBMITTED_FILINGS_COLUMN_DEFS,
  officialStatement: OFFICIAL_PRELIMINARY_SUBMISSIONS_COLUMN_DEFS,
  remarketing: REMARKETING_SUPPLEMENTS_AMENDMENTS_COLUMN_DEFS,
  advanceRefunding: ADVANCE_REFUNDING_COLUMN_DEFS,
  municipalFund: MUNICIPAL_FUND_SECURITY_COLUMN_DEFS,
  exemptFilings: EXEMPT_FILINGS_CANCELLATIONS_COLUMN_DEFS,
  //
  submitted: SUBMITTED_VIEW_COLUMN_DEFS
};

export const applyG32UnderwritingDetailLink = (columnDefs, callback) => {
  columnDefs.forEach(col => {
    if ((<any>col).isDetailLink) {
      col['cellRendererFramework'] = FinraGridClickableCellComponent;
      col['cellRendererFrameworkOptions'] = {
        icon: faDownload,
        isClickable: (params: any) => true,
        buttonLabel: 'Details',
        handleClick: (params: any) => {
          callback(params);
        }
      };
    }
  });
};
