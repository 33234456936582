import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { deepSort } from 'src/app/shared/utils/utils';
import { UserNotification, UserNotificationResponse } from 'src/app/configs/model/notifications.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';

/**
 * Notifications Preferences Component
 * ---
 *
 * This component shows and manages a user's notifications preferences.
 * There are only two main endpoints for the UI:
 * - GET: getting the user's notifications
 * - PUT: updating the user's notifications
 *
 * The Component starts off by fetching the user's notifications.
 * NOTE: when a `userNotification.userNotificationId` is `-1`,
 * this means that this particular preference does not exist in the database.
 * With that data, the component then initialize the preferences form.
 * Each `userNotification` object is basically a `ReportCategory` with a few extra properties:
 * - a `userNotificationId` to identify a specific preference
 * - a `periodicityId` to indicate how often the notification should occur.
 *
 * The possible values for `periodicityId` are:
 * - 0: no notification
 * - 1: daily
 * - 2: weekly
 * - 3: monthly
 *
 * The form is Angular reactive forms.
 * The form's control names are `ReportCategory.categoryDisplayName`
 * and the value is the current `periodicityId`.
 *
 * When `onSubmit()` is called, the form updates `this.userNotifications` and sends it
 * to the backend. The backend sends back a new list. For every `userNotification` that
 * had a `userNotificationId` of `-1`, it will get an actual/official id; the response
 * replaces `this.userNotifications` entirely NORMALLY.
 *
 * The backend will send back only the `userNotification`s that were passed in the payload;
 * this is why it is important to send back the entire list in the payload, just to be on the safe side
 * and having to write more logic of keeping track of what preference(s) changed.
 */
@Component({
  selector: 'report-notifications-preferences',
  templateUrl: './notifications-preferences.component.html',
  styleUrls: ['./notifications-preferences.component.scss']
})
export class NotificationsPreferencesComponent implements OnInit {
  title = 'Notifications Preferences';
  controlNames: string[] = [];
  userNotifications: UserNotification[];
  preferencesForm: FormGroup;
  successMessage = 'Notification preferences updated successfully!';
  errorMessage = 'Could not process the request. Please try again.';

  constructor(
    private titleService: Title,
    private notificationsService: NotificationsService,
    private uiWidgetsService: UiWidgetsService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Report Center - Notifications Preferences');
    this.notificationsService.getUserNotifications().subscribe(
      (response: UserNotificationResponse) => {
        this.handleUserNotifications(response);
      },
      (error: HttpErrorResponse) => {
        const message =
          'Could not load notifications preferences';
        this.uiWidgetsService.showSuccessSnackbar(message);
      }
    );
  }

  handleUserNotifications(response: UserNotificationResponse) {
    deepSort(response.userNotifications, 'reportCategory.categoryDisplayName', false, true);
    this.userNotifications = response.userNotifications;
    this.initPreferencesForm();
  }

  initPreferencesForm() {
    this.controlNames = this.userNotifications.map(i => i.reportCategory.categoryDisplayName);
    const newFormGroupOptions: { [key: string]: FormControl } = {};
    this.userNotifications.forEach((n) => {
      const controlName = n.reportCategory.categoryDisplayName;
      const newControl = new FormControl(n.periodicityId, [Validators.required]);
      newFormGroupOptions[controlName] = newControl;
    });
    this.preferencesForm = new FormGroup(newFormGroupOptions);
  }

  onSubmitForm() {
    // update the userNotifications with the new form data.
    const formData = this.preferencesForm.value;
    Object.keys(formData).forEach((controlName: string) => {
      const newPeriodicityValue: number = formData[controlName];
      const findFn = (n) => n.reportCategory.categoryDisplayName === controlName;
      const userNotification = this.userNotifications.find(findFn);
      userNotification.periodicityId = newPeriodicityValue;
    });
    // now send the request to update
    const data = { userNotifications: this.userNotifications };
    const request$ = this.notificationsService.updateUserNotifications(data);
    request$.subscribe(
      (response: UserNotificationResponse) => {
        this.userNotifications = response.userNotifications;
        this.uiWidgetsService.showSuccessSnackbar(this.successMessage);
      },
      (error: HttpErrorResponse) => {
        this.uiWidgetsService.showErrorSnackbar(this.errorMessage);
      }
    );
  }
}
