import { Component, OnInit, Input } from '@angular/core';
import {
  IconDefinition,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { RiskMonitoringReportNames } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';

@Component({
  selector: 'report-industry-products-problems-grid',
  templateUrl: './industry-products-problems-grid.component.html',
  styleUrls: ['./industry-products-problems-grid.component.scss']
})
export class IndustryProductsProblemsGridComponent implements OnInit {
  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  @Input() viewName: string;
  @Input() quartersHeadings: string[] = [];
  @Input() rowData = [];

  faQuestionCircle: IconDefinition = faQuestionCircle;

  constructor(
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) { }

  ngOnInit() {
  }

  openHelp() {
    this.helpLinksService.open(RiskMonitoringReportNames.CUSTOMER_COMPLAINT);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

}
