import {
  IconDefinition,
  faClipboardList,
  faClipboardCheck,
  faList,
  faUsersCog,
  faSearch,
  faBell,
  faCloudUpload
} from '@fortawesome/pro-regular-svg-icons';
import { UserAccessTypes } from './user-profile.model';

/**
 * NavItem.accessTypes - Information
 * ---
 *
 * The `accessTypes` property on the `NavItem` interface explicity lists all of the user access types
 * that have access to that nav item; the types are defined in the `UserAccessTypes` enum.
 * If `accessTypes` is `null`, it means there is no restriction on that nav item, which is the same as
 * putting all of the enums in the list.
 *
 * When the front-end makes a call to get the user profile, the `accessType` of the user object will be
 * one of the enum values.
 */

export declare interface NavItem {
  id?: string;
  parentId?: string;
  label?: string;
  hideLabel?: boolean,
  icon?: string;
  logo?: string;
  faIcon?: IconDefinition;
  elementChildren?: Array<NavItem>;
  route?: string;
  showInView?: boolean;
  showInViewId?: string;
  callback?: Function;
  permission?: string;
  target?: string;
  url?: string;
  showSeparation?: boolean;
  enabled?: boolean;
  categoryDisplayName?: string;
  categoryDisplayOrder?: number;
  reportCategoryId?: any;
  accessTypes: Array<string>;
}

export let toolBarItems: Array<NavItem> = [
  {
    id: 'T1',
    label: 'Notifications',
    icon: 'fas fa-bell fa-3x icon',
    route: 'Notifications',
    showInView: false,
    elementChildren: [],
    showSeparation: true,
    accessTypes: null
  },
  {
    id: 'T2',
    label: 'Profile',
    icon: 'fas fa-user-circle fa-3x',
    route: 'UserProfile',
    showInView: false,
    elementChildren: [],
    showSeparation: true,
    accessTypes: null
  },
  {
    id: 'T3',
    label: 'Settings',
    icon: 'fas fa-cog fa-3x icon',
    route: 'Settings',
    showInView: false,
    elementChildren: [],
    showSeparation: true,
    accessTypes: null
  }
];

export let sideMenus: Array<NavItem> = [
  {
    id: '0',
    label: 'Home',
    hideLabel: true,
    logo: 'assets/img/finra-logo-short.svg',
    route: 'home',
    showInView: false,
    showSeparation: false,
    elementChildren: [],
    url: 'home',
    accessTypes: [
      UserAccessTypes.FINRA,
      UserAccessTypes.FINRA_ADMIN,
      UserAccessTypes.EWS,
      UserAccessTypes.EWS_SRO,
      UserAccessTypes.EWS_SEC,
      UserAccessTypes.EWS_OTHER,
      UserAccessTypes.EWS_FRB,
    ]
  },
  {
    id: '1',
    label: 'Reports',
    faIcon: faClipboardList,
    route: 'reports',
    showInView: false,
    elementChildren: [],
    showSeparation: false,
    url: null,
    accessTypes: [
      UserAccessTypes.FINRA,
      UserAccessTypes.FINRA_ADMIN,
      UserAccessTypes.EWS,
      UserAccessTypes.EWS_SRO,
      UserAccessTypes.EWS_OTHER,
      UserAccessTypes.EWS_FRB,
    ]
  },
  {
    id: '2',
    label: 'Status',
    faIcon: faClipboardCheck,
    route: 'status',
    showInView: false,
    showSeparation: false,
    elementChildren: [],
    url: 'status',
    accessTypes: [
      UserAccessTypes.FINRA,
      UserAccessTypes.FINRA_ADMIN,
      UserAccessTypes.EWS,
      UserAccessTypes.EWS_SRO,
      UserAccessTypes.EWS_OTHER,
      UserAccessTypes.EWS_FRB,
    ]
  },
  {
    id: '3',
    label: 'Explorer',
    faIcon: faSearch,
    route: 'explorer',
    showInView: false,
    showSeparation: false,
    url: 'explorer',
    elementChildren: [],
    accessTypes: [
      UserAccessTypes.FINRA,
      UserAccessTypes.FINRA_ADMIN,
      UserAccessTypes.EWS_SRO
    ]
  },
  {
    id: '4',
    label: 'Usage Log',
    faIcon: faList,
    route: 'usage-log',
    showInView: false,
    elementChildren: [],
    showSeparation: false,
    url: 'usage-log',
    accessTypes: [
      UserAccessTypes.FINRA,
      UserAccessTypes.FINRA_ADMIN,
      UserAccessTypes.EWS,
      UserAccessTypes.EWS_SRO,
      UserAccessTypes.EWS_SEC,
      UserAccessTypes.EWS_OTHER
    ]
  },
  {
    id: '5',
    label: 'Admin',
    faIcon: faUsersCog,
    route: 'admin',
    showInView: false,
    showSeparation: false,
    url: 'admin',
    accessTypes: [UserAccessTypes.FINRA_ADMIN],
    elementChildren: [
      {
        categoryDisplayName: 'Publish/Unpublish/Archive',
        categoryDisplayOrder: 1,
        reportCategoryId: '00',
        route: 'archive',
        accessTypes: null
      },
      {
        categoryDisplayName: 'Report Problems',
        categoryDisplayOrder: 2,
        reportCategoryId: '00',
        route: 'report-problems',
        accessTypes: null
      }
    ]
  },
  {
    id: '6',
    label: 'Notifications',
    faIcon: faBell,
    route: 'notifications',
    showInView: false,
    elementChildren: [],
    showSeparation: false,
    url: 'notifications',
    accessTypes: [
      UserAccessTypes.FINRA,
      UserAccessTypes.FINRA_ADMIN,
      UserAccessTypes.EWS,
      UserAccessTypes.EWS_SRO,
      UserAccessTypes.EWS_OTHER
    ]
  },
  {
    id: '7',
    label: 'Upload',
    faIcon: faCloudUpload,
    route: 'upload',
    showInView: false,
    elementChildren: [],
    showSeparation: false,
    url: 'upload',
    accessTypes: [
      UserAccessTypes.FINRA,
      UserAccessTypes.FINRA_ADMIN,
    ]
  }
];

export const mainNavItems = {
  home: sideMenus[0],
  reports: sideMenus[1],
  status: sideMenus[2],
  explorer: sideMenus[3],
  usage: sideMenus[4],
  admin: sideMenus[5],
  notifications: sideMenus[6],
  upload: sideMenus[7],
};
