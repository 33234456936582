import { faDownload, faTrash, faInfo } from '@fortawesome/pro-light-svg-icons';
import { DatePipe } from '@angular/common';
import { FinraGridClickableCellComponent } from 'src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component';
import {
  capitalize,
  addFinraGridColumnId,
  finraGridMonthDateFormatter,
  formatBytes,
  finraGridNumericValueFormatter
} from 'src/app/shared/utils/utils';

// enum matches `DetailDatasetRequestStatusEnum` in the ddwa-services repo.
export enum DetailDatasetDownloadRequestStatus {
  READY = 'READY',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  NO_DATA = 'NO_DATA',
}

export enum UsageLogExportRequestStatus {
  PROCESSING = 1,
  READY = 2,
  ERROR = 3,
  NO_DATA = 4,
}

export const UsageLogExportRequestStatusNameByCode = Object.freeze({
  [UsageLogExportRequestStatus.PROCESSING]: 'PROCESSING',
  [UsageLogExportRequestStatus.READY]: 'READY',
  [UsageLogExportRequestStatus.ERROR]: 'ERROR',
  [UsageLogExportRequestStatus.NO_DATA]: 'NO_DATA',
});

export const cellStatusColorByCode = Object.freeze({
  [UsageLogExportRequestStatus.PROCESSING]: { color: 'blue' },
  [UsageLogExportRequestStatus.READY]: { color: 'green' },
  [UsageLogExportRequestStatus.ERROR]: { color: 'red' },
  [UsageLogExportRequestStatus.NO_DATA]: { color: 'gray' },
});

const viewFormatter = (params) => {
  const viewNameSplit = params.value.split('_');
  const stringsCapitalized = viewNameSplit.map(i => capitalize(i));
  const viewNameFormatted = stringsCapitalized.join(' ');
  return viewNameFormatted;
};

// interface matches `UserDetailDatasetDownloadRequest` in the ddwa-services repo.
export interface DetailDatasetDownloadRequest {
  detailDatasetRequestId: number;
  fileName: string;
  firmId: string;
  periodicity: string;
  reportConfigurationViewId: number;
  reportId: number;
  reportPeriodDate: string;
  requestDate: string;
  requestId: number;
  requestUserName: string;
  status: DetailDatasetDownloadRequestStatus;
  viewName: string;

  reportDisplayName?: string;
}

export interface UsageLogExportRequest {
  exportRequestId: number,
  userName: string;
  requestStartTs: string;
  requestEndTs: string;
  s3Url: string;
  status: number;
  statusMsg: number;
  metadata: string;
  bytesSize: number;
  resultsCount: number;
}

/**
 * Example Payload from /userDetailDatasetDownloadRequests
 * ---

  {
    "userDetailDatasetDownloadRequests": [
      {
        "requestUserName": "newcomec",
        "detailDatasetRequestId": 13,
        "requestDate": "2020-03-26T10:00:18.545-0400",
        "periodicity": "Monthly",
        "reportPeriodDate": "2020-02-01",
        "status": "ERROR",
        "firmId": "JPMS",
        "fileName": "someFile.csv",
        "reportId": "12345678",
        "viewName": "All"
      },
    ]
  }

*/
export interface DetailDatasetDownloadRequestResponse {
  userDetailDatasetDownloadRequests: DetailDatasetDownloadRequest[];
}

export const GetReportsDowloadColumnDefs = (
  downloadClickHandler,
  viewReportClickHandler
) => addFinraGridColumnId([
  {
    headerName: 'Download',
    headerClass: 'lines',
    cellClass: 'lines',
    minWidth: 100,
    maxWidth: 100,
    tooltipValueGetter: (params) => {
      return params.data.status === DetailDatasetDownloadRequestStatus.READY
        ? 'Click icon to start download'
        : (
            params.data.status === DetailDatasetDownloadRequestStatus.NO_DATA ||
            params.data.status === DetailDatasetDownloadRequestStatus.ERROR
          )
          ? 'Click icon to remove'
          : '';
    },
    cellRendererFramework: FinraGridClickableCellComponent,
    cellRendererFrameworkOptions: {
      handleClick: downloadClickHandler,
      icon: (params) => {
        return params.data.status === DetailDatasetDownloadRequestStatus.READY
        ? faDownload
        : (
            params.data.status === DetailDatasetDownloadRequestStatus.NO_DATA ||
            params.data.status === DetailDatasetDownloadRequestStatus.ERROR
          )
          ? faTrash
          : null;
      },
      showButton: (params) => (
        params.data.status === DetailDatasetDownloadRequestStatus.READY ||
        params.data.status === DetailDatasetDownloadRequestStatus.NO_DATA ||
        params.data.status === DetailDatasetDownloadRequestStatus.ERROR
      ),
      buttonClass: (params) => {
        return params.data.status === DetailDatasetDownloadRequestStatus.READY
          ? 'fa-download-ready'
          : (
              params.data.status === DetailDatasetDownloadRequestStatus.NO_DATA ||
              params.data.status === DetailDatasetDownloadRequestStatus.ERROR
            )
            ? 'fa-no-data'
            : '';
      },
      isClickable: (params) => true,
      buttonLabel: (params) => '',
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    // minWidth: 125,
    // maxWidth: 125,
  },
  {
    headerName: 'Report',
    field: 'fileName',
    minWidth: 450,
    maxWidth: 450,
    valueFormatter: (params) => params.value.split('/')[5],
    tooltipValueGetter: (params) => params.value.split('/')[5],
  },
  {
    headerName: 'Firm Identifier',
    field: 'firmId',
    // minWidth: 125,
    // maxWidth: 125,
  },
  {
    headerName: 'Period',
    field: 'reportPeriodDate',
    valueFormatter: finraGridMonthDateFormatter,
    // minWidth: 125,
    // maxWidth: 125,
  },
  {
    headerName: 'DateTime Requested',
    field: 'requestDate',
    // minWidth: 175,
    // maxWidth: 175,
    valueFormatter: (params) => {
      const date = (new DatePipe('en-US')).transform(params.value, 'MM/dd/yyyy - hh:mm a');
      return date;
    },
  },
  {
    headerName: 'Type',
    field: 'periodicity',
    // minWidth: 100,
    // maxWidth: 100,
  },
  {
    headerName: 'View',
    field: 'viewName',
    // minWidth: 200,
    // maxWidth: 200,
    tooltipValueGetter: viewFormatter,
    valueFormatter: viewFormatter,
  },
]);

export const GetUsageLogExportColumnDefs = (params: {
  downloadClickHandler,
  deleteClickHandler,
  infoClickHandler,
}) => addFinraGridColumnId([
  {
    headerName: 'Download',
    headerClass: 'lines',
    cellClass: 'lines',
    minWidth: 100,
    maxWidth: 100,
    tooltipValueGetter: (params) => {
      return params.data.status === UsageLogExportRequestStatus.READY ? 'Click icon to start download' : '';
    },
    cellRendererFramework: FinraGridClickableCellComponent,
    cellRendererFrameworkOptions: {
      handleClick: params.downloadClickHandler,
      icon: (params) => {
        return params.data.status === UsageLogExportRequestStatus.READY ? faDownload : null;
      },
      showButton: (params) => (
        params.data.status === UsageLogExportRequestStatus.READY
      ),
      buttonClass: (params) => {
        return params.data.status === DetailDatasetDownloadRequestStatus.READY ? 'fa-download-ready' : '';
      },
      isClickable: (params) => true,
      buttonLabel: (params) => '',
    },
  },
  {
    headerName: 'Info',
    headerClass: 'lines',
    cellClass: 'lines',
    minWidth: 100,
    maxWidth: 100,
    tooltipValueGetter: (params) => 'Click for more info',
    cellRendererFramework: FinraGridClickableCellComponent,
    cellRendererFrameworkOptions: {
      handleClick: params.infoClickHandler,
      icon: (params) => faInfo,
      showButton: (params) => true,
      buttonClass: (params) => 'fa-download-ready',
      isClickable: (params) => true,
      buttonLabel: (params) => '',
    },
  },
  {
    headerName: 'Request ID',
    field: 'exportRequestId',
    minWidth: 150,
    maxWidth: 150,
  },
  {
    headerName: 'Results Count',
    field: 'resultsCount',
    minWidth: 175,
    maxWidth: 175,
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Status',
    field: 'status',
    valueFormatter: (params) => UsageLogExportRequestStatusNameByCode[params.value],
    cellStyle: (params) => cellStatusColorByCode[params.value],
    minWidth: 150,
    maxWidth: 150,
  },
  {
    headerName: 'DateTime Requested',
    field: 'requestStartTs',
    minWidth: 200,
    maxWidth: 200,
    valueFormatter: (params) => {
      const date = (new DatePipe('en-US')).transform(params.value, 'MM/dd/yyyy - hh:mm a');
      return date;
    },
  },
  {
    headerName: 'DateTime Finished',
    field: 'requestEndTs',
    minWidth: 200,
    maxWidth: 200,
    valueFormatter: (params) => {
      const date = !params.value ? null : (new DatePipe('en-US')).transform(params.value, 'MM/dd/yyyy - hh:mm a');
      return date;
    },
  },
  {
    headerName: 'File Name',
    field: 'fileName',
    // minWidth: 500,
    // maxWidth: 500,
  },
  {
    headerName: 'File Size',
    field: 'bytesSize',
    valueFormatter: (params) => !params.value ? null : formatBytes(params.value),
    minWidth: 150,
    maxWidth: 150,
  },
  {
    headerName: 'Delete',
    headerClass: 'lines',
    cellClass: 'lines',
    minWidth: 100,
    maxWidth: 100,
    tooltipValueGetter: (params) => 'Click icon to delete',
    cellRendererFramework: FinraGridClickableCellComponent,
    cellRendererFrameworkOptions: {
      handleClick: params.deleteClickHandler,
      icon: (params) => faTrash,
      showButton: (params) => true,
      buttonClass: (params) => 'fa-no-data',
      isClickable: (params) => true,
      buttonLabel: (params) => '',
    },
  },
]);
