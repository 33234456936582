import { Component, OnInit, Inject } from '@angular/core';
import { navAlertMessage } from 'src/app/configs/model/nav.model';
import { IconDefinition, faBell, faExclamationTriangle, faHourglass, faFileTimes } from '@fortawesome/pro-solid-svg-icons';
import { DetailDatasetRequestsService } from 'src/app/services/detail-dataset-requests.service';
import { SessionService } from 'src/app/services/session.service';
import { UsageLogExportRequestsService } from 'src/app/services/usage-log-export-requests.service';

@Component({
  selector: 'report-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  navAlertMessage: string = navAlertMessage;
  name: string;
  onPremRcUrl;

  faBell: IconDefinition = faBell;
  faExclamationTriangle: IconDefinition = faExclamationTriangle;
  faHourglass: IconDefinition = faHourglass;
  faFileTimes: IconDefinition = faFileTimes;

  // the state of detail dataset requests
  readyStateDetailDownload: boolean = false;
  errorStateDetailDownload: boolean = false;
  noDataStateDetailDownload: boolean = false;
  requestsProcessingDetailDownload: boolean = false;

  // the state of usage log exports
  readyStateUsageLogExport: boolean = false;
  errorStateUsageLogExport: boolean = false;
  noDataStateUsageLogExport: boolean = false;
  requestsProcessingUsageLogExport: boolean = false;

  get requestsProcessing(): boolean { return this.requestsProcessingDetailDownload || this.requestsProcessingUsageLogExport; }
  get readyState(): boolean { return this.readyStateDetailDownload || this.readyStateUsageLogExport; }
  get errorState(): boolean { return this.errorStateDetailDownload || this.errorStateUsageLogExport; }
  get noDataState(): boolean { return this.noDataStateDetailDownload || this.noDataStateUsageLogExport; }

  constructor(
    @Inject('environment') private environment,
    private sessionService: SessionService,
    private detailDatasetRequestsService: DetailDatasetRequestsService,
    private usageLogExportRequestsService: UsageLogExportRequestsService,
  ) {
    this.onPremRcUrl = environment.onPremRcUrl;
  }

  ngOnInit() {
    // detail downloads
    this.detailDatasetRequestsService.select<boolean>('detailDatasetRequestReady').subscribe((state) => {
      this.readyStateDetailDownload = state;
    });
    this.detailDatasetRequestsService.select<boolean>('detailDatasetRequestError').subscribe((state) => {
      this.errorStateDetailDownload = state;
    });
    this.detailDatasetRequestsService.select<boolean>('detailDatasetRequestNoData').subscribe((state) => {
      this.noDataStateDetailDownload = state;
    });
    this.detailDatasetRequestsService.select<boolean>('detailDatasetRequestProcessing').subscribe((state) => {
      this.requestsProcessingDetailDownload = state;
    });


    // usage log exports
    this.usageLogExportRequestsService.select<boolean>('usageLogExportRequestReady').subscribe((state) => {
      this.readyStateUsageLogExport = state;
    });
    this.usageLogExportRequestsService.select<boolean>('usageLogExportRequestError').subscribe((state) => {
      this.errorStateUsageLogExport = state;
    });
    this.usageLogExportRequestsService.select<boolean>('usageLogExportRequestNoData').subscribe((state) => {
      this.noDataStateUsageLogExport = state;
    });
    this.usageLogExportRequestsService.select<boolean>('usageLogExportRequestProcessing').subscribe((state) => {
      this.requestsProcessingUsageLogExport = state;
    });
  }

  openOnPremRC() {
    window.open(this.onPremRcUrl, '_self');
  }

  logout($event: any) {
    this.sessionService.logout();
  }
}
