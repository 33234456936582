import {
  Component,
  OnInit,
} from '@angular/core';
import { ReportProblemData } from 'src/app/configs/model/report-problems.model';
import {
  Params,
  ActivatedRoute,
  Router
} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminService } from 'src/app/services/admin.service';
import { deepSort } from 'src/app/shared/utils/utils';
import { ReportProblemFormData } from 'src/app/configs/model/report-problem-form-data.model';
import { HelperService } from 'src/app/services/helper.service';
import { Title } from '@angular/platform-browser';
import { ReportTypeEdition } from 'src/app/configs/model/admin.model';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';


@Component({
  selector: 'report-admin-report-problems-form-container',
  templateUrl: './problems-form-container.component.html',
  styleUrls: ['./problems-form-container.component.scss']
})
export class AdminReportProblemsFormContainerComponent implements OnInit {
  isEditing: boolean;
  reportProblemId;
  error;
  errorMessage;
  title;
  reportProblem: ReportProblemData;
  reportTypesList;

  constructor(
    public activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    private uiWidgetsService: UiWidgetsService,
    private router: Router,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
  }

  handleParamsChange(params: Params) {
    this.reportProblemId = params.reportProblemId;
    this.isEditing = !!params.reportProblemId;
    if (!this.isEditing) {
      this.title = 'Create Report Problem';
      this.fetchReportTypesEditions();
    } else {
      this.title = 'Edit Report Problem';
      this.fetchReportProblemById(this.reportProblemId);
    }
    this.titleService.setTitle(`Admin - ${this.title}`);
  }

  fetchReportTypesEditions() {
    this.adminService.getAdminReportTypesEditions().subscribe(
      (response: ReportTypeEdition[]) => {
        this.reportTypesList = response;
        deepSort(this.reportTypesList, 'rptTypeNm', false, true);
      }
    );
  }

  fetchReportProblemById(id: string) {
    this.adminService.getAdminReportProblemById(id).subscribe(
      (response: ReportProblemData) => {
        this.reportProblem = response;
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = 'Could not load data...';
      }
    );
  }

  onFormSubmit(event: ReportProblemFormData) {
    if (!this.isEditing) {
      this.createReportProblem(event);
    } else {
      this.updateReportProblem(event);
    }
  }

  createReportProblem(data: ReportProblemFormData) {
    this.adminService.createReportProblem(data).subscribe(
      (response: ReportProblemData) => {
        this.uiWidgetsService.showInfoModal({
          header: 'Success',
          body: 'Report Problem Created Successfully.'
        }).afterClosed().subscribe(() => {
          // navigate to the new report problem
          const navigatePath = ['/', 'admin', 'report-view', 'report-problems', response.reportProblemId];
          this.router.navigate(navigatePath);
        });
      },
      (error: HttpErrorResponse) => {
        this.uiWidgetsService.showInfoModal({
          header: 'Error',
          body: 'Could not create report problem. Try again later.'
        });
      }
    );
  }

  updateReportProblem(data: ReportProblemFormData) {
    this.adminService.updateReportProblem(data).subscribe(
      (response: ReportProblemData) => {
        this.uiWidgetsService.showInfoModal({
          header: 'Success',
          body: 'Edits Saved Successfully.'
        }).afterClosed().subscribe(() => {
          // navigate to the updated report problem
          const navigatePath = ['/', 'admin', 'report-view', 'report-problems', response.reportProblemId];
          this.router.navigate(navigatePath);
        });
      },
      (error: HttpErrorResponse) => {
        this.uiWidgetsService.showInfoModal({
          header: 'Error',
          body: 'Could not save edits. Try again later.'
        });
      }
    );
  }
}
