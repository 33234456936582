export const TOTAL_SESSIONS_EXPORT_CONFIG = {
  columnLabels: [
    'Session Type',
    'Total Sessions',
    'Complete Sessions',
    'Incomplete Sessions'
  ],
  dataMappings: [
    'name',
    'firmTotalSessionCount',
    'firmCompleteSessionCount',
    'firmIncompleteSessionCount'
  ]
};

export const S101_EXPORT_CONFIG = {
  numberOfSessions: {
    columnLabels: [
      ['', '', '', '', 'Series 7', '', 'Other', ''],
      ['', '', '', '', 'Firm', 'Industry', 'Firm', 'Industry']
    ],
    dataMappings: [
      'displayName',
      '',
      '',
      '',
      'firmTotalSessionCount',
      'industryTotalSessionCount',
      'firmTotalSessionCountOther',
      'industryTotalSessionCountOther'
    ]
  },
  score: {
    headingsList: [
      '',
      '',
      '',
      'Average (Mean %)',
      'Firm (%)',
      'Industry (%)',
      'Firm (%)',
      'Industry (%)'
    ],
    averageDataMappings: [
      '',
      '',
      '',
      'Average (Mean %)',
      'firmSessionAverageMeanScorePercent',
      'industrySessionAverageMeanScorePercent',
      'firmSessionAverageMeanScorePercentOther',
      'industrySessionAverageMeanScorePercentOther'
    ],
    sdDataMappings: [
      '',
      '',
      '',
      'Standard Deviation',
      'firmSessionScoreStandardDeviationPercent',
      'industrySessionScoreStandardDeviationPercent',
      'firmSessionScoreStandardDeviationPercentOther',
      'industrySessionScoreStandardDeviationPercentOther'
    ]
  },
  duration: {
    headingsList: [
      '',
      '',
      '',
      'Duration',
      'Firm (HH:MM)',
      'Industry (HH:MM)',
      'Firm (HH:MM)',
      'Industry (HH:MM)'
    ],
    averageDataMappings: [
      '',
      '',
      '',
      'Average (Mean %)',
      'firmSessionAverageDurationTime',
      'industrySessionAverageDurationTime',
      'firmSessionAverageDurationTimeOther',
      'industrySessionAverageDurationTimeOther'
    ],
    sdDataMappings: [
      '',
      '',
      '',
      'Standard Deviation',
      'firmSessionDurationStandardDeviationTime',
      'industrySessionDurationStandardDeviationTime',
      'firmSessionDurationStandardDeviationTimeOther',
      'industrySessionDurationStandardDeviationTimeOther'
    ]
  }
};

export const S201_EXPORT_CONFIG = {
  numberOfSessions: {
    columnLabels: [
      ['', '', '', '', 'Firm', 'Industry']
    ],
    dataMappings: [
      'displayName',
      '',
      '',
      '',
      'firmTotalSessionCount',
      'industryTotalSessionCount'
    ]
  },
  score: {
    headingsList: ['', '', '', 'Average (Mean %)', 'Firm (%)', 'Industry (%)'],
    averageDataMappings: [
      '',
      '',
      '',
      'Average (Mean %)',
      'firmSessionAverageMeanScorePercent',
      'industrySessionAverageMeanScorePercent'
    ],
    sdDataMappings: [
      '',
      '',
      '',
      'Standard Deviation',
      'firmSessionScoreStandardDeviationPercent',
      'industrySessionScoreStandardDeviationPercent'
    ]
  },
  duration: {
    headingsList: ['', '', '', 'Duration', 'Firm (HH:MM)', 'Industry (HH:MM)'],
    averageDataMappings: [
      '',
      '',
      '',
      'Average (Mean %)',
      'firmSessionAverageDurationTime',
      'industrySessionAverageDurationTime'
    ],
    sdDataMappings: [
      '',
      '',
      '',
      'Standard Deviation',
      'firmSessionDurationStandardDeviationTime',
      'industrySessionDurationStandardDeviationTime'
    ]
  }
};
