import { Inject, Injectable } from '@angular/core';
import {
  UsageLogExportRequest,
  UsageLogExportRequestStatus
} from '../configs/model/home.model';
import { UiWidgetsService } from './ui-widgets.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'src/app/configs/model/http-status-codes.model';
import { UsageLogService } from './usage-log.service';
import { UsageLogSearch } from '../configs/model/usage-log.model';
import { HelperService } from './helper.service';



@Injectable({
  providedIn: 'root'
})
export class UsageLogExportRequestsService  {
  private baseUrl: string;

  private usageLogExportRequests: UsageLogExportRequest[] = null; // the list of usage log exports requests
  private errorRequests: UsageLogExportRequest[] = []; // list of errored usage log export requests
  private readyRequests: UsageLogExportRequest[] = []; // list of ready usage log export requests
  private noDataRequests: UsageLogExportRequest[] = []; // list of no data usage log export requests

  private isLoading: boolean = false;
  private currentPollingState: boolean = false;
  private usageLogExportRequestReadyPreviousState = false;
  private usageLogExportRequestErrorPreviousState = false;
  private usageLogExportRequestNoDataPreviousState = false;
  private usageLogExportRequestProcessingPreviousState = false;

  private REFRESH_RATE = 1000 * 10;

  private processingRequestsMap = new Map<number, UsageLogExportRequestStatus>(); // map to track processing state

  private pollingState = new BehaviorSubject<boolean>(false); // stream events of polling state
  private listChanges = new BehaviorSubject<UsageLogExportRequest[]>(null); // stream events of list changes
  private isLoadingState = new BehaviorSubject<boolean>(false); // stream events when a status changes to ready
  private usageLogExportRequestReady = new BehaviorSubject<boolean>(false); // stream events when a status changes to ready
  private usageLogExportRequestError = new BehaviorSubject<boolean>(false); // stream events when a status changes to error
  private usageLogExportRequestNoData = new BehaviorSubject<boolean>(false); // stream events when a status changes to error
  private usageLogExportRequestProcessing = new BehaviorSubject<boolean>(false); // stream events when a status is processing

  constructor(
    @Inject('environment') environment,
    private http: HttpClient,
    private uiWidgetsService: UiWidgetsService,
    private helperService: HelperService,
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  startPolling() {
    if (this.currentPollingState) {
      // already polling
      return;
    }

    // get usage log export requests
    this.getUserUsageLogExportRequestsAndProcess();

    // set polling state and emit event
    this.currentPollingState = true;
    this.pollingState.next(this.currentPollingState);
  }

  getLoadingState() {
    return this.isLoadingState.asObservable();
  }

  getPollingState() {
    return this.pollingState.asObservable();
  }

  setUsageLogExportRequests(usageLogExportRequests: UsageLogExportRequest[]) {
    if (!usageLogExportRequests) {
      return;
    }
    // set the usageLogExportRequests
    this.usageLogExportRequests = usageLogExportRequests;
    // emit the event for any other service/component that wants the data
    // send a copy of the data in a new array instance to trigger angular change detection(s)
    this.listChanges.next([ ...usageLogExportRequests ]);
    // check the status of the new list
    this.checkChanges();
  }

  addUsageLogExportRequest(usageLogExportRequest: UsageLogExportRequest) {
    if (!usageLogExportRequest) {
      return;
    }
    // add the usageLogExportRequest to front of list
    this.usageLogExportRequests.unshift(usageLogExportRequest);
    // emit the event for any other service/component that wants the data
    this.listChanges.next([ ...this.usageLogExportRequests ]);
    // there is a new item, start polling if not already
    if (!this.currentPollingState) {
      this.startPolling();
    }
  }

  // TODO: backend has not implemented a delete capability
  removeUsageLogExportRequest(exportRequestId: number) {
    // if (!exportRequestId) {
    //   return;
    // }
    // const index = this.usageLogExportRequests.findIndex(usageLogExportRequest => {
    //   const match = usageLogExportRequest.exportRequestId === exportRequestId;
    //   return match;
    // });

    // const noResultsFound = index === -1;
    // if (noResultsFound) {
    //   return;
    // }

    // const usageLogExportRequest = this.usageLogExportRequests[index];

    // this.reportService.deleteUserReportDetailDownloadStatus({
    //   reportId: usageLogExportRequest.reportId,
    //   exportRequestId: usageLogExportRequest.exportRequestId,
    //   reportConfigurationViewId: usageLogExportRequest.reportConfigurationViewId,
    // }).subscribe({
    //   next: (response) => {
    //     if (response.recordsDeleted === 0) {
    //       this.uiWidgetsService.showErrorSnackbar(
    //         `Could not delete detail data request; something went wrong...`
    //       );
    //     }
    //     else {
    //       console.log(`detail Dataset Request deleted`);
    //       this.usageLogExportRequests.splice(index, 1);
    //       // emit the event for any other service/component that wants the data
    //       this.listChanges.next([ ...this.usageLogExportRequests ]);
    //     }
    //   },
    //   error: (error: HttpErrorResponse) => {
    //     const message = `Could not delete detail data request; something went wrong...`;
    //     this.uiWidgetsService.showErrorSnackbar(message);
    //   }
    // });
  }

  // similar to an ngrx store where you can select a part of the entire app state,
  // select the behavior subject that this service has.
  // adding generic option which SHOULD/MUST match the behavior subject's generic type
  select<T>(desiredBehaviorSubjectKey: string) {
    // find behavior subject by arg
    const subject = this[desiredBehaviorSubjectKey];
    // check if the behavior subject exists on this service
    const badKey = (
      !subject ||
      subject.constructor !== BehaviorSubject
    );
    if (badKey) {
      throw new ReferenceError(
        `no behavior subject exists on this service with the key "${desiredBehaviorSubjectKey}"`
      );
    }
    // if there is a behavior subject by arg key, return it as observable
    const observable = (<BehaviorSubject<T>> subject).asObservable();
    return observable;
  }

  getUserUsageLogExportRequests() {
    return this.http.get<UsageLogExportRequest[]>(`${this.baseUrl}auditActions/exportAuditActionRequests?cache=false`);
  }

  getUserUsageLogExportRequestsAndProcess() {
    this.isLoading = true;
    this.isLoadingState.next(true);
    this.getUserUsageLogExportRequests().subscribe({
      next: (response: UsageLogExportRequest[]) => {
        this.setUsageLogExportRequests(response);
        this.isLoading = false;
        this.isLoadingState.next(false);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);

        this.isLoading = false;
        this.isLoadingState.next(false);
        this.currentPollingState = false;
        this.pollingState.next(this.currentPollingState);
      }
    });
  }

  private checkChanges() {
    const readyList = [];
    const errorList = [];
    const noDataList = [];

    let processingRequestsCount = 0;
    let readyChanges = 0;
    let errorChanges = 0;
    let noDataChanges = 0;

    // loop through the list of usage log export requests
    for (let i = 0; i < this.usageLogExportRequests.length; i++) {
      const usageLogExportRequest: UsageLogExportRequest = this.usageLogExportRequests[i];
      const detailFileName = usageLogExportRequest.s3Url.split('/').pop();

      // get tracking status, if there is any
      const entry = this.processingRequestsMap.has(usageLogExportRequest.exportRequestId);
      // check the status
      switch (usageLogExportRequest.status) {
        case UsageLogExportRequestStatus.PROCESSING: {
          // if status is pending, check if it is being tracked. if not, add it
          if (!entry) {
            this.processingRequestsMap.set(usageLogExportRequest.exportRequestId, UsageLogExportRequestStatus.PROCESSING);
          }
          processingRequestsCount = processingRequestsCount + 1;
          break;
        }
        case UsageLogExportRequestStatus.READY: {
          // if status is ready, add to the ready list. check if it is being tracked.
          // if not, ignore; it was probably been ready since the last time the user was on RC. no need to notify again.
          // if it WAS being tracked, then it used to be processing/pending; notify the user and remove from processing map.
          if (entry) {
            readyChanges = readyChanges + 1;
            this.processingRequestsMap.delete(usageLogExportRequest.exportRequestId);

            const successMessage = `${detailFileName} is now ready for download.`;
            this.uiWidgetsService.showSuccessSnackbar(successMessage);
          }
          readyList.push(usageLogExportRequest);
          break;
        }
        case UsageLogExportRequestStatus.ERROR: {
          // if status is error, add to the error list. check if it is being tracked.
          // if not, ignore; it was probably been errored since the last time the user was on RC. no need to notify again.
          // if it WAS being tracked, then it used to be processing/pending; notify the user and remove from processing map.
          if (entry) {
            errorChanges = errorChanges + 1;
            const errorMessage = `${detailFileName} cannot be downloaded, an error occurred...`;
            this.uiWidgetsService.showErrorSnackbar(errorMessage);
            this.processingRequestsMap.delete(usageLogExportRequest.exportRequestId);
          }
          errorList.push(usageLogExportRequest);
          break;
        }
        case UsageLogExportRequestStatus.NO_DATA: {
          // if status is no data, add to the no data list. check if it is being tracked.
          // if not, ignore; it was probably been there since the last time the user was on RC. no need to notify again.
          // if it WAS being tracked, then it used to be processing/pending; notify the user and remove from processing map.
          if (entry) {
            noDataChanges = noDataChanges + 1;
            const infoMessage = `${detailFileName} has no data.`;
            this.uiWidgetsService.showInfoSnackbar(infoMessage);
            this.processingRequestsMap.delete(usageLogExportRequest.exportRequestId);
          }
          noDataList.push(usageLogExportRequest);
          break;
        }
      }
    }

    // update the lists on the service
    this.readyRequests = readyList;
    this.errorRequests = errorList;
    this.noDataRequests = noDataList;

    // emit events if there were changes

    const readyChangesState: boolean = readyChanges > 0;
    const readyChanged = readyChangesState !== this.usageLogExportRequestReadyPreviousState;
    if (readyChanged) {
      this.usageLogExportRequestReady.next(readyChangesState);
      // once this.usageLogExportRequestReadyPreviousState is true, no need to hide it again later,
      // it will be hidden again when the page reloads or closes the tab/window
    }
    // else if (this.readyRequests.length) {
    //   // if there are ready downloads, notify user
    //   this.usageLogExportRequestReady.next(true);
    // }

    const errorChangesState: boolean = errorChanges > 0;
    const errorChanged = errorChangesState !== this.usageLogExportRequestErrorPreviousState;
    if (errorChanged) {
      this.usageLogExportRequestError.next(errorChangesState);
      // once this.usageLogExportRequestErrorPreviousState is true, no need to hide it again later,
      // it will be hidden again when the page reloads or closes the tab/window
    }
    // else if (this.errorRequests.length) {
    //   // if there are failed downloads, notify user
    //   this.usageLogExportRequestError.next(true);
    // }

    const noDataChangesState: boolean = noDataChanges > 0;
    const noDataChanged = noDataChangesState !== this.usageLogExportRequestNoDataPreviousState;
    if (noDataChanged) {
      this.usageLogExportRequestNoData.next(noDataChangesState);
      // once this.usageLogExportRequestNoDataPreviousState is true, no need to hide it again later,
      // it will be hidden again when the page reloads or closes the tab/window
    }
    // else if (this.noDataRequests.length) {
    //   // if there are failed downloads, notify user
    //   this.usageLogExportRequestNoData.next(true);
    // }

    const processingRequestsCountState: boolean = processingRequestsCount > 0;
    const processingChanged = processingRequestsCountState !== this.usageLogExportRequestProcessingPreviousState;
    if (processingChanged) {
      this.usageLogExportRequestProcessing.next(processingRequestsCountState);
      this.usageLogExportRequestProcessingPreviousState = processingRequestsCountState;
    }

    // stop polling IF none have status PROCESSING; else continue polling via recursive calling getUserUsageLogExportRequests()
    const someRequestsAreProcessing = processingRequestsCount > 0;
    if (someRequestsAreProcessing) {
      console.log(`Pending usage log export request(s) found: ${someRequestsAreProcessing} requests still pending. Calling again in ${this.REFRESH_RATE} ms...`);
      setTimeout(() => {
        this.getUserUsageLogExportRequestsAndProcess();
      }, this.REFRESH_RATE);
    }
    else {
      console.log(`No usage log export request(s) pending; done polling for now...`);
      this.currentPollingState = false;
      this.pollingState.next(this.currentPollingState);
    }
  }

  exportUsageLogDataFull(searchParams: UsageLogSearch) {
    if (searchParams.startDate) {
      searchParams.startDate += 'T00:00:00';
    }
    if (searchParams.endDate) {
      searchParams.endDate += 'T23:59:59';
    }

    this.http.post<UsageLogExportRequest>(`${this.baseUrl}auditActions/exportAuditActionRequests`, searchParams)
    .subscribe({
      next: (response: UsageLogExportRequest) => {
        this.uiWidgetsService.showSuccessSnackbar(`Export request has been submitted for processing.`);
        if (!!response && !!response.exportRequestId) {
          // response has new request entity
          this.addUsageLogExportRequest(response);
        }
        this.startPolling();
      }
    });
  }

  downloadReadyUsageLogExport(exportRequestId: number) {
    const endpoint = `${this.baseUrl}auditActions/downloadExportAuditActionRequests/${exportRequestId}`;

    const hiddenAnchorDownloadElement = document.createElement('a');
    hiddenAnchorDownloadElement.href = endpoint;
    hiddenAnchorDownloadElement.target = '_blank';
    document.body.appendChild(hiddenAnchorDownloadElement);
    hiddenAnchorDownloadElement.click();
    setTimeout(() => {
      document.body.removeChild(hiddenAnchorDownloadElement);
    }, 1000);
  }

  deleteUsageLogExport(exportRequestId) {
    const endpoint = `${this.baseUrl}auditActions/deleteExportAuditActionRequests/${exportRequestId}`;
    return this.http.post(endpoint, {});
  }
}
