import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { from } from "rxjs";
import * as Cookies from 'js-cookie';



@Injectable({
  providedIn: 'root'
})
export class DmClientService {
  private baseUrl: string;

  constructor(
    @Inject('environment') environment,
    private http: HttpClient
  ) {
    this.baseUrl = environment.ddwaBaseUrl + 'dmClient';
  }

  registerAndUpload(options: {
    file: File,
    partitionValue: string,
    isNewDataVersion: boolean
  }) {

    const endpoint = `${this.baseUrl}/registerObjectAndUploadCsvFile`;
    const formData = new FormData();
    formData.append('csv_data_file', options.file);

    let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(endpoint, formData, {
      headers,
      withCredentials: true,
      params: { isNewDataVersion: options.isNewDataVersion, partitionValue: options.partitionValue, bypassheaders: true }
    });

    // const xsrfCookie = Cookies.get(`xsrf-token`) || Cookies.get(`XSRF-TOKEN`);
    // return from(
    //   fetch(`${endpoint}?isNewDataVersion=${options.isNewDataVersion}&partitionValue=${options.partitionValue}`, {
    //     credentials: 'include',
    //     body: formData,
    //     method: 'POST',
    //     headers: {
    //       'X-XSRF-TOKEN': xsrfCookie,

    //     }
    //   })
    //   .then(r => {
    //     const isError = r.status.toString().startsWith('4') || r.status.toString().startsWith('5');
    //     if (isError) {
    //       throw r;
    //     }
    //     return r.json();
    //   })
    // )
  }

  objectExists(objectName: string, partitionValue: string) {
    const endpoint = `${this.baseUrl}/objectExists/objectName/${objectName}/partitionValue/${partitionValue}`;
    return this.http.get<{ objectExists: boolean }>(endpoint);
  }

  userHasPermissionToUse() {
    const endpoint = `${this.baseUrl}/isAuthorizedToRegisterAndUpload`;
    return this.http.get<{ hasAuthorization: boolean }>(endpoint);
  }
}
