<div id="problems-form-container">
  <form
    [formGroup]="reportProblemForm" 
    class="form-container"
    (ngSubmit)="reportProblemForm.valid && onSubmitForm()"
  >
    <h3>Report Type</h3>
    <p *ngIf="isEditing && reportProblem">
      <span [innerHTML]="reportProblem.reportDisplayName + ' - Edition ' + reportProblem.reportEdition"></span>
    </p>
    <mat-form-field class="form-field-block" *ngIf="!isEditing">
      <mat-select formControlName="reportType">
        <mat-option
          *ngFor="let report of reportTypesList"
          [value]="report.rptCfgId"
        >
          <span [innerHTML]="report.rptTypeNm + ' - Edition ' + report.rptEdtn"></span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <section *ngIf="!!reportProblemForm.value.reportType">
      <h3>Period</h3>
      <p *ngIf="isEditing">
        {{ reportProblem && reportProblem.reportPeriodDate | finraValidValue : usePeriodFormat }}
      </p>
      <mat-form-field class="form-field-block" *ngIf="!isEditing">
        <mat-select
          formControlName="reportPeriodDate"
          (selectionChange)="onPeriodSelection($event)"
        >
          <!--
            Making the value of this form an object instead of a string
            since each object has the versions associated with the period;
            it's easier to find
          -->
          <mat-option
            *ngFor="let period of periodsList"
            [value]="period"
          >
            {{ period.rptPrdDt | finraValidValue : usePeriodFormat }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section *ngIf="!!reportProblemForm.value.reportPeriodDate">
      <h3>Version</h3>
      <p *ngIf="isEditing">
        {{ reportProblem && (reportProblem.reportVersion === 0 && 'All' || reportProblem.reportVersion) }}
      </p>
      <mat-form-field class="form-field-block" *ngIf="!isEditing">
        <mat-select formControlName="reportVersion" >
          <mat-option
            *ngFor="let version of versionsList"
            [value]="version.rptDataVrsn"
          >
            {{ version.rptDataVrsn === 0 && 'All' || version.rptDataVrsn }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <ng-container 
      *ngIf="reportProblemForm.value.reportVersion !== null && reportProblemForm.value.reportVersion !== undefined">
      <section>
        <h3>
          Message 
          <!-- Character Count -->
          <span 
            [ngClass]="{ 'text-red': reportProblemForm.controls.message.invalid }" 
            class="char-count-text"
          >
            ({{ reportProblemForm.value.message && reportProblemForm.value.message.length || 0 }} / {{ maxMessageLength }})
          </span>
        </h3> 
        <mat-form-field class="form-field-block">
          <textarea
            matInput
            cdkTextareaAutosize
            formControlName="message"
          ></textarea>
        </mat-form-field>
      </section>
  
      <section class="section-space">
        <h3>Behavior</h3>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="invalidFlag"
        >
          <mat-radio-button [value]="0">Visible</mat-radio-button>
          <mat-radio-button [value]="1">Not Visible</mat-radio-button>
        </mat-radio-group>
      </section>

      <button
        mat-flat-button
        color="accent"
        class="filter-button"
        type="submit"
        [disabled]="reportProblemForm.invalid"
      >
        Submit
      </button>
    </ng-container>
  </form>
</div>
