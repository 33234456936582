import { AdminReportExplorerComponent } from 'src/app/components/pages/report-explorer/report-explorer.component';
import { AdminReportProblemsComponent } from 'src/app/components/pages/admin/report-problems/report-problems.component';
import { AdminReportProblemsFormContainerComponent } from 'src/app/components/pages/admin/report-problems-form/problems-form-container.component';
import { AdminReportStatusComponent } from 'src/app/components/pages/report-status/report-status.component';
import { ArchiveViewComponent } from 'src/app/components/pages/admin/archive-view/archive-view.component';
import { CorpFinComponent } from 'src/app/components/pages/reports/summary/corp-fin/corp-fin.component';
import { CrossMarketSupervisionComponent } from './components/pages/reports/summary/cross-market-supervision/cross-market-supervision.component';
import { DisclosureComponent } from './components/pages/reports/summary/disclosure/disclosure.component';
import { DqsComponent } from './components/pages/reports/summary/dqs/dqs.component';
import { EquityComponent } from './components/pages/reports/summary/equity/equity.component';
import { HomeComponent } from './components/pages/home/home.component';
import { LoprComponent } from './components/pages/reports/summary/lopr/lopr.component';
import { mainNavItems } from 'src/app/configs/model/sidenavigation';
import { MsrbComponent } from './components/pages/reports/summary/msrb/msrb.component';
import { NgModule } from '@angular/core';
import { NotificationsPreferencesComponent } from './components/pages/notifications/notifications-preferences/notifications-preferences.component';
import { OnlineLearningComponent } from './components/pages/reports/summary/online-learning/online-learning.component';
import { ReportsViewComponent } from 'src/app/components/pages/reports/search/reports-view.component';
import { RiskMonitoringComponent } from './components/pages/reports/summary/risk-monitoring/risk-monitoring.component';
import { RouterModule, Routes } from '@angular/router';
import { TraceComponent } from 'src/app/components/pages/reports/summary/trace/trace.component';
import { UsageLogComponent } from './components/pages/usage-log/usage-log.component';
import { UserAccessGuard } from 'src/app/guards/user-access.guard';
import { UploadBusinessObjectPageComponent } from './components/pages/upload/upload.component';

/**
 * Routes Config
 * ---
 *
 * Each `route` object should have a `data` object that contains info about the nav menu it belongs to,
 * which is signified by the `mainNavItem` property (the value comes from `sidenavigation.ts`) and
 * the `extendedNav` property, if it exists/is needed. Extended nav is a means of identifying what
 * child that route is and if it is/should be active/selected. Typically, it matches some part
 * of the `path` value of the `route` object.
 *
 *
 * Here is the convention for the path a route: {main-nav-item-route}/{child-nav-item-route}/some-continued-path...
 *
 *
 * @example:
 * - { path: 'reports/equity/some-path-afterwards...', ...otherRouteProps };
 */



const routes: Routes = [
  /**
   * Home Routes
   */
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      mainNavItem: mainNavItems['home']
    }
  },

  /**
   * Report Category Routes
   */

  // unread reports
  // {
  //   path: 'reports/unread-reports',
  //   pathMatch: 'full',
  //   redirectTo: 'reports/unread-reports/report-view'
  // },
  // {
  //   path: 'reports/unread-reports/report-view',
  //   component: ReportsViewComponent,
  //   data: {
  //     title2: 'Unread Reports',
  //     reports: 'unread-reports',
  //     mainNavItem: mainNavItems['reports']
  //   },
  //   canActivate: [UserAccessGuard]
  // },
  // {
  //   path: 'reports/unread-reports/report-card-view/:id',
  //   component: ReportCardViewComponent,
  //   data: {
  //     category: null,
  //     categoryId: null,
  //     mainNavItem: mainNavItems['reports']
  //   },
  //   canActivate: [UserAccessGuard]
  // },

  // cross market supervision
  {
    path: 'reports/cross-market-supervision',
    pathMatch: 'full',
    redirectTo: 'reports/cross-market-supervision/report-view'
  },
  {
    path: 'reports/cross-market-supervision/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'Cross Market Supervision',
      reports: 'cross-market-supervision',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'cross-market-supervision'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/cross-market-supervision/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: CrossMarketSupervisionComponent,
    data: {
      category: 'cross-market-supervision',
      categoryId: 8,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'cross-market-supervision'
    },
    canActivate: [UserAccessGuard]
  },

  // equity
  {
    path: 'reports/equity',
    pathMatch: 'full',
    redirectTo: 'reports/equity/report-view'
  },
  {
    path: 'reports/equity/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'Equity',
      reports: 'equity',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'equity'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/equity/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: EquityComponent,
    data: {
      category: 'equity',
      categoryId: 4,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'equity'
    },
    canActivate: [UserAccessGuard]
  },

  // disclosure
  {
    path: 'reports/disclosure',
    pathMatch: 'full',
    redirectTo: 'reports/disclosure/report-view'
  },
  {
    path: 'reports/disclosure/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'Disclosure',
      reports: 'disclosure',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'disclosure'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/disclosure/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: DisclosureComponent,
    data: {
      category: 'disclosure',
      categoryId: 2,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'disclosure'
    },
    canActivate: [UserAccessGuard]
  },

  // data quality scorecard
  {
    path: 'reports/data-quality-scorecard',
    pathMatch: 'full',
    redirectTo: 'reports/file-and-data-quality-scorecard'
  },
  {
    path: 'reports/file-and-data-quality-scorecard',
    pathMatch: 'full',
    redirectTo: 'reports/file-and-data-quality-scorecard/report-view'
  },
  {
    path: 'reports/file-and-data-quality-scorecard/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'File and Data Quality Scorecard',
      reports: 'dqs',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'file-and-data-quality-scorecard'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path: 'reports/file-and-data-quality-scorecard/report-card-view/:firmId/:viewName',
    component: DqsComponent,
    data: {
      category: 'file-and-data-quality-scorecard',
      categoryId: 13,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'file-and-data-quality-scorecard'
    },
    canActivate: [UserAccessGuard]
  },
  {
    // another way of accessing the page, allows for selecting exactly the toolbar state
    path: 'reports/file-and-data-quality-scorecard/report-card-view/firm/:firmId/view/:viewName/exam-year/:examYear/exam-id/:examId/request-id/:requestId',
    component: DqsComponent,
    data: {
      category: 'file-and-data-quality-scorecard',
      categoryId: 13,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'file-and-data-quality-scorecard'
    },
    canActivate: [UserAccessGuard]
  },

  // corp fin
  {
    path: 'reports/corp-fin',
    pathMatch: 'full',
    redirectTo: 'reports/corp-fin/report-view'
  },
  {
    path: 'reports/corp-fin/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'Corp Fin',
      reports: 'corp-fin',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'corp-fin'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/corp-fin/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: CorpFinComponent,
    data: {
      category: 'corp-fin',
      categoryId: 10,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'corp-fin'
    },
    canActivate: [UserAccessGuard]
  },

  // online learning
  {
    path: 'reports/online-learning',
    pathMatch: 'full',
    redirectTo: 'reports/online-learning/report-view'
  },
  {
    path: 'reports/online-learning/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'Online Learning',
      reports: 'online-learning',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'online-learning'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/online-learning/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: OnlineLearningComponent,
    data: {
      category: 'online-learning',
      categoryId: 5,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'online-learning'
    },
    canActivate: [UserAccessGuard]
  },

  // trace
  {
    path: 'reports/trace',
    pathMatch: 'full',
    redirectTo: 'reports/trace/report-view'
  },
  {
    path: 'reports/trace/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'TRACE',
      reports: 'trace',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'trace'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/trace/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: TraceComponent,
    data: {
      category: 'trace',
      categoryId: 1,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'trace'
    },
    canActivate: [UserAccessGuard]
  },
  {
    // DDWA-3091 Route with additionalInfo params for trace markup markdown report
    path:
      'reports/trace/report-card-view/:reportId/:version/:type/:viewName/:reportName/:incomingRatingIndicator/:incomingMaturityIndicator/:incomingSubtypeCode',
    component: TraceComponent,
    data: {
      category: 'trace',
      categoryId: 1,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'trace'
    },
    canActivate: [UserAccessGuard]
  },

  // msrb
  {
    path: 'reports/msrb',
    pathMatch: 'full',
    redirectTo: 'reports/msrb/report-view'
  },
  {
    path: 'reports/msrb/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'MSRB',
      reports: 'msrb',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'msrb'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/msrb/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: MsrbComponent,
    data: {
      category: 'msrb',
      categoryId: 3,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'msrb'
    },
    canActivate: [UserAccessGuard]
  },
  {
    // DDWA-3719 Route with additionalInfo params for msrb markup markdown report
    path:
      'reports/msrb/report-card-view/:reportId/:version/:type/:viewName/:reportName/:incomingRatingIndicator/:incomingMaturityIndicator',
    component: MsrbComponent,
    data: {
      category: 'msrb',
      categoryId: 3,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'msrb'
    },
    canActivate: [UserAccessGuard]
  },

  // options
  {
    path: 'reports/options',
    pathMatch: 'full',
    redirectTo: 'reports/options/report-view'
  },
  {
    path: 'reports/options/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'Options',
      reports: 'options',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'options'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/options/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: LoprComponent,
    data: {
      category: 'options',
      categoryId: 12,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'options'
    },
    canActivate: [UserAccessGuard]
  },

  // risk monitoirng
  {
    path: 'reports/risk-monitoring',
    pathMatch: 'full',
    redirectTo: 'reports/risk-monitoring/report-view'
  },
  {
    path: 'reports/risk-monitoring/report-view/:category',
    component: ReportsViewComponent,
    data: {
      title2: 'Risk Monitoring',
      reports: 'risk-monitoring',
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'risk-monitoring'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path:
      'reports/risk-monitoring/report-card-view/:reportId/:version/:type/:viewName/:reportName',
    component: RiskMonitoringComponent,
    data: {
      category: 'risk-monitoring',
      categoryId: 6,
      mainNavItem: mainNavItems['reports'],
      extendedNav: 'risk-monitoring'
    },
    canActivate: [UserAccessGuard]
  },

  /**
   * Report Status Route
   */
  {
    path: 'status',
    component: AdminReportStatusComponent,
    data: {
      mainNavItem: mainNavItems['status']
    },
    canActivate: [UserAccessGuard]
  },

  /**
   * Report Explorer Route
   */
  {
    path: 'explorer',
    component: AdminReportExplorerComponent,
    data: {
      mainNavItem: mainNavItems['explorer']
    },
    canActivate: [UserAccessGuard]
  },

  /**
   * Usage Log Routes
   */
  {
    path: 'usage-log',
    component: UsageLogComponent,
    data: {
      mainNavItem: mainNavItems['usage']
    },
    canActivate: [UserAccessGuard]
  },

  /**
   * Admin Routes
   */

  // archive
  {
    path: 'admin',
    pathMatch: 'full',
    redirectTo: 'admin/report-view/archive'
  },
  {
    path: 'admin/report-view',
    pathMatch: 'full',
    redirectTo: 'admin/report-view/archive'
  },
  {
    path: 'admin/report-view/archive',
    component: ArchiveViewComponent,
    data: {
      mainNavItem: mainNavItems['admin'],
      extendedNav: 'archive'
    },
    canActivate: [UserAccessGuard]
  },

  // report problems
  {
    path: 'admin/report-view/report-problems',
    component: AdminReportProblemsComponent,
    data: {
      mainNavItem: mainNavItems['admin'],
      extendedNav: 'report-problems'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path: 'admin/report-view/report-problems/create',
    component: AdminReportProblemsFormContainerComponent,
    data: {
      mainNavItem: mainNavItems['admin'],
      extendedNav: 'report-problems'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path: 'admin/report-view/report-problems/:reportProblemId',
    component: AdminReportProblemsComponent,
    data: {
      mainNavItem: mainNavItems['admin'],
      extendedNav: 'report-problems'
    },
    canActivate: [UserAccessGuard]
  },
  {
    path: 'admin/report-view/report-problems/:reportProblemId/edit',
    component: AdminReportProblemsFormContainerComponent,
    data: {
      mainNavItem: mainNavItems['admin'],
      extendedNav: 'report-problems'
    },
    canActivate: [UserAccessGuard]
  },

  /**
   * Notification Routes
   */
  {
    path: 'notifications',
    pathMatch: 'full',
    redirectTo: 'notifications/preferences',
  },
  {
    path: 'notifications/preferences',
    component: NotificationsPreferencesComponent,
    data: {
      mainNavItem: mainNavItems['notifications'],
    },
    canActivate: [UserAccessGuard]
  },

  {
    path: 'upload',
    component: UploadBusinessObjectPageComponent,
    data: {
      mainNavItem: mainNavItems['upload']
    },
    canActivate: [UserAccessGuard]
  },



  /** Unknown/Invalid Route Redirect */
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
