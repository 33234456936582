import { Injectable, ElementRef } from "@angular/core";
import { ReportService, ReportPageMetadataInfo } from "./report.service";
import { DatePipe } from "@angular/common";
import { FormGroup } from "@angular/forms";
import {
  weakSort,
  detect,
  html2canvasPrintConfigByBrowser,
  loadingPopupString,
} from "../shared/utils/utils";
import html2canvas from "html2canvas";
import {
  faDownload,
  faPrint,
  faSpinner,
  faQuestionCircle
} from "@fortawesome/pro-regular-svg-icons";
import {
  BeastClickEventDetailExportInfo,
  BeastClickEventPdfPrintInfo,
  BeastClickEventSummaryExportInfo,
  BeastClickEventToolbarGoInfo,
} from "../interfaces/beast.interface";
import { BeastService } from "./beast.service";
import {
  ToolbarConfigOptions,
  ToolbarConfig,
  ToolbarSelectControlComponent,
  ToolbarCell,
  ToolbarButtonComponent,
  ToolbarRow,
  COMMON_PDF_PRINT_BUTTON_CELL_TAG,
} from "../configs/model/finra-toolbar.model";
import { BeastClickActions } from "../enums/beast.enum";
import { ReportInstanceMetadata } from "../configs/model/reports.model";
import { PdfService } from "./pdf.service";

/**
 * FINRA - Report Center Toolbar Service
 *
 * This service is for providing methods for common toolbar actions.
 * Normally, every component that renders will have
 * complete control over the configuration and behavior of the toolbar.
 * However many instances of the toolbar will be very similar in implementation and behavior.
 * In those cases, this service can provide methods for those common behavior.
 * In cases of specific logic, the component will handle that.
 *
 * When a component implements a toolbar, the convention is that:
 * - the component creates a reactive form with at least 3 controls: period, views, versions
 * - the component creates a config options object,
 *   which gets passed to the config method
 *   that generates the config object for the toolbar component.
 * - the component sets a value changes listener on the 'period' control to change the versions
 * - implement the change behavior logic when there is a change.
 *
 * This service will provide those common functionalities in cases where it can be used and nothing specific is required.
 */
@Injectable({
  providedIn: "root",
})
export class ToolbarService {
  constructor(
    private baseReportService: ReportService,
    private pdfService: PdfService,
    private datePipe: DatePipe,
    private beastService: BeastService
  ) {}

  /**
   * Create Toolbar Common Config Options
   * -----
   *
   * This method is for creating an options object that can be passed to a finra-toolbar component.
   * It simply takes a component and creates an object with common properties used across all
   * report components (component must have required properties as defined in the argument interface).
   * Anything component specific will be passed as a separate object,
   * which will be merged with the common config object and overriding any defaults.
   *
   * @param component The component object instance
   * @param overrideOptions the override options
   * @returns {ToolbarConfigOptions} ToolbarConfigOptions object
   */
  createToolbarCommonConfigOptions(
    component: {
      reportPageMetadataInfo: ReportPageMetadataInfo;
      reportInstanceMetadata: ReportInstanceMetadata;
      toolbarForm: FormGroup;
      onReportDetails?: Function;
      summaryDataExport: Function;
      periodChangeSubscription?;
      reportVersion;
      viewName;
      reportId;
    },
    overrideOptions?: Partial<ToolbarConfigOptions>
  ): ToolbarConfigOptions {
    // check all arguments for required data
    if (!component) {
      const errorMessage = `A component was not passed to the function. \
        Please pass a component object for this function to work.`;
      const error = new Error(errorMessage);
      throw error;
    } else if (!component.reportPageMetadataInfo) {
      const errorMessage = `The required "reportPageMetadataInfo" property \
        was not found on the given component object`;
      const error = new Error(errorMessage);
      throw error;
    } else if (!component.toolbarForm) {
      const errorMessage = `The required "toolbarForm" property \
        was not found on the given component object`;
      const error = new Error(errorMessage);
      throw error;
    }

    const report = component.reportPageMetadataInfo.reportInstanceMetadata;
    const period = component.toolbarForm.get("period").value;
    const version = component.toolbarForm.get("version").value;
    const viewName = component.toolbarForm.get("view").value;
    const rating =
      component.toolbarForm.get("rating") &&
      component.toolbarForm.get("rating").value;
    const maturity =
      component.toolbarForm.get("maturity") &&
      component.toolbarForm.get("maturity").value;
    const product =
      component.toolbarForm.get("product") &&
      component.toolbarForm.get("product").value;

    const reportPublishedState =
      component.reportPageMetadataInfo.reportInstanceMetadata.reportStateLookup
        .reportStateDescription;

    // create an options object with the common/default configs.
    const configOptions: ToolbarConfigOptions = {
      description: null,
      pdfIcon: faPrint,
      helpIcon: faQuestionCircle,
      detailsIcon: faDownload,
      summaryIcon: faDownload,
      periods: component.reportPageMetadataInfo.periods,
      versions: weakSort(
        component.reportPageMetadataInfo.versions,
        "number",
        true
      ),
      views: component.reportPageMetadataInfo.views,
      firmIdLabel: "CRD ID",
      firmIdValue: component.reportPageMetadataInfo.firmId,
      firmNameLabel: "Firm",
      firmNameValue: component.reportPageMetadataInfo.firmName,
      publishedState: reportPublishedState,
      pdfClickHandler: null,
      hidePublishedState: true,
      // each toolbar form should have the following 3 controls: period, version, view
      periodFormControl: component.toolbarForm.controls.period,
      versionFormControl: component.toolbarForm.controls.version,
      viewFormControl: component.toolbarForm.controls.view,
      // if there is a details/summary export function defined, use it
      // the method assumes that the given component has those methods defined.
      detailsClickHandler: component.onReportDetails
        ? () => {
            const fn = component.onReportDetails.bind(component);
            fn();

            /* log beast event */
            const eventInfo: BeastClickEventDetailExportInfo = {
              reportId: report.reportId.toString(),
              reportPeriod: period,
              reportView: viewName,
              reportVersion: version,
              reportCategoryId:
                report.reportConfiguration.reportType.reportCategoryId,
              reportType: report.reportConfiguration.reportDisplayName,
              maturity: maturity,
              rating: rating,
              product: product,
              firmId: report.reportFirmId,
            };
            this.beastService.clickStream.postEvent(
              BeastClickActions.REPORT_DETAIL_DOWNLOAD_FROM_REPORT_PAGE,
              eventInfo
            );
          }
        : null,
      summaryExportClickHandler: component.summaryDataExport
        ? () => {
            const fn = component.summaryDataExport.bind(component);
            fn();

            /* log beast event */
            const eventInfo: BeastClickEventSummaryExportInfo = {
              reportId: report.reportId.toString(),
              reportPeriod: period,
              reportView: viewName,
              reportVersion: version.toString(),
              reportCategoryId:
                report.reportConfiguration.reportType.reportCategoryId,
              reportType: report.reportConfiguration.reportDisplayName,
              maturity: maturity,
              rating: rating,
              product: product,
              firmId: report.reportFirmId,
            };
            this.beastService.clickStream.postEvent(
              BeastClickActions.REPORT_SUMMARY_DOWNLOAD_FROM_REPORT_PAGE,
              eventInfo
            );
          }
        : null,
      changeClickHandler: () => {
        this.onToolbarChange(component);
      },
    };

    // if there is an override options object, merge it into the common options object
    if (overrideOptions) {
      Object.assign(configOptions, overrideOptions);
    }

    return configOptions;
  }

  /**
   * Create Toolbar Config
   * -----
   *
   * This method is for creating a toolbar config object.
   * First, it uses the `createToolbarCommonConfigObject` method defined in this service
   * to get the toolbar config options. Then it uses the given config function and passes the
   * config options to it. It also does a few extra things:
   * - it sets up a period change listener on the component to handle changing report periods.
   * - it sets up the on change function callback (clicking the "Go" button)
   * - it sets up the pdf button click handler
   * Doing so abstracts all of the common processes from each of the components;
   * now each component will only have to worry about what is specific to it.
   * The method returns an object containing the config options and the toolbar config
   * @param component The component instance
   * @param configFn the function that creates the toolbar config object
   * @param overrideOptions the custom options that overrides any of the default
   * @returns {object} an object containing the config options and the toolbar config
   */
  createToolbarConfig(
    component: {
      reportPageMetadataInfo: ReportPageMetadataInfo;
      reportInstanceMetadata: ReportInstanceMetadata;
      toolbarForm: FormGroup;
      onReportDetails?: Function;
      summaryDataExport: Function;
      periodChangeSubscription?;
      reportVersion;
      viewName;
      reportId;
    },
    configFn,
    overrideOptions?: Partial<ToolbarConfigOptions>
  ) {
    // first, set the form initial values.
    // A call to this method should mean a report page has been loaded (including clicking "Go" in the toolbar);
    // there isn't another known time where it would be needed to be called again.
    const report = component.reportPageMetadataInfo.reportInstanceMetadata;
    component.toolbarForm.get("period").setValue(report.reportPeriodDate);
    component.toolbarForm.get("version").setValue(component.reportVersion);
    component.toolbarForm.get("view").setValue(component.viewName);

    // create the config options with the given component and override options
    const toolbarConfigOptions: ToolbarConfigOptions =
      this.createToolbarCommonConfigOptions(component, overrideOptions);
    // create the toolbar config with the config options
    const toolbarConfig: ToolbarConfig = configFn(toolbarConfigOptions);

    // if a finra toolbar cell component has a `cellReadyCallback` defined,
    // it will call it and pass back its `ElementRef` in the ngAfterViewInit lifecycle (should only be called once).
    // For the pdf cell, we use that to defined an internal/direct action (click listener)
    // so we can spawn a popup without being stopped by any adblock browser extension.
    const pdf_cell = this.findCellInToolbarConfigByTag(toolbarConfig.rows, COMMON_PDF_PRINT_BUTTON_CELL_TAG);

    const pdfCellReadyCallback = (cellElm: ElementRef) => {
      cellElm.nativeElement.addEventListener("click", async () => {
        const fileNameResponse = await this.baseReportService
          .getReportFileName(component.reportId, component.viewName, "s")
          .toPromise();
        const pdfFileName = fileNameResponse.fileName.replace(".csv", ".pdf");
        // we can pass the toolbar config object here because the component instance
        // did not get the toolbarConfig object back yet from this method,
        // which it needs so it can pass it to the finra-toolbar component in its template.
        this.onExportPdf(pdfFileName, toolbarConfig, undefined, pdf_cell);

        /** Log BEAST event */
        const period = component.toolbarForm.get("period").value;
        const version = component.toolbarForm.get("version").value;
        const viewName = component.toolbarForm.get("view").value;
        const rating =
          component.toolbarForm.get("rating") &&
          component.toolbarForm.get("rating").value;
        const maturity =
          component.toolbarForm.get("maturity") &&
          component.toolbarForm.get("maturity").value;
        const product =
          component.toolbarForm.get("product") &&
          component.toolbarForm.get("product").value;
        const eventInfo: BeastClickEventPdfPrintInfo = {
          reportId: report.reportId.toString(),
          reportPeriod: period,
          reportView: viewName,
          reportVersion: version.toString(),
          reportCategoryId:
            report.reportConfiguration.reportType.reportCategoryId,
          reportType: report.reportConfiguration.reportDisplayName,
          maturity: maturity,
          rating: rating,
          product: product,
          firmId: report.reportFirmId,
        };
        this.beastService.clickStream.postEvent(
          BeastClickActions.REPORT_PDF_PRINT,
          eventInfo
        );
      });
    };

    // set the cell ready callback on the pdf cell component
    // doing it through a function to allow the custom config to place the print button anywhere in the config
    if (pdf_cell) {
      pdf_cell.cellReadyCallback = pdfCellReadyCallback;
    }

    // set the period change listeners
    if (component.periodChangeSubscription) {
      component.periodChangeSubscription.unsubscribe();
    }
    component.periodChangeSubscription = this.setPeriodChangeListener(
      component.toolbarForm,
      component.reportPageMetadataInfo.reportInstanceMetadatas,
      toolbarConfig
    );

    // return an object containing the config options and the toolbar config.
    return {
      toolbarConfigOptions,
      toolbarConfig,
    };
  }

  findCellInToolbarConfigByTag(rows: ToolbarRow[], tag: string): ToolbarCell | null {
    let results: ToolbarCell | null = null;

    const findFn = (cells: ToolbarCell[]) => {
      for (const cell of cells) {
        const found = !!cell.cellTag && cell.cellTag === tag;
        if (found) {
          results = cell;
        }

        if (cell.components && cell.components.length) {
          findFn(cell.components);
        }
      }
    };

    for (const row of rows) {
      findFn(row.components);
    }

    return results;
  };

  setPeriodChangeListener(
    form: FormGroup,
    reportInstanceMetadatas: ReportInstanceMetadata[],
    config: ToolbarConfig
  ) {
    return form.get("period").valueChanges.subscribe((period: string) => {
      const dateFormat = "yyyy-MM-dd";
      const dateString = this.datePipe.transform(period, dateFormat);
      const reportInstanceMetadata = reportInstanceMetadatas.find(
        (r: ReportInstanceMetadata) => {
          const dateStr = this.datePipe.transform(
            r.reportPeriodDate,
            dateFormat
          );
          const match = dateStr === dateString;
          return match;
        }
      );

      if (!reportInstanceMetadata) {
        // 'no report instance found by selected date input'
        return;
      }
      const versions = this.baseReportService.getReportVersions(
        reportInstanceMetadata.reportPeriodDate,
        reportInstanceMetadatas
      );
      weakSort(versions, "number", true);
      // this method assumes that the versions select control
      // is in the second row, the second item in the first component's list of components.
      (<ToolbarSelectControlComponent>(
        config.rows[1].components[0].components[1].component
      )).dataList = versions;
      form.get("version").setValue(versions[0]);
    });
  }

  onToolbarChange(component: {
    reportPageMetadataInfo: ReportPageMetadataInfo;
    reportInstanceMetadata: ReportInstanceMetadata;
    toolbarForm: FormGroup;
    onReportDetails?: Function;
    summaryDataExport: Function;
    periodChangeSubscription?;
    reportVersion;
    viewName;
    reportId;
  }) {
    const form = component.toolbarForm;
    const reportInstanceMetadatas =
      component.reportPageMetadataInfo.reportInstanceMetadatas;
    const period = form.get("period").value;
    const version = form.get("version").value;
    const viewName = form.get("view").value;
    const rating = form.get("rating") && form.get("rating").value;
    const maturity = form.get("maturity") && form.get("maturity").value;
    const product = form.get("product") && form.get("product").value;

    const reportInstanceMetadata =
      this.baseReportService.getSelectedReportInstanceMetadata(
        period,
        version,
        reportInstanceMetadatas
      );
    if (!reportInstanceMetadata) {
      return;
    }

    this.baseReportService.navigateToReportDetails({
      report: reportInstanceMetadata,
      viewName,
      rating,
      maturity,
      product,
    });

    /** Log BEAST event */
    const eventInfo: BeastClickEventToolbarGoInfo = {
      reportId: reportInstanceMetadata.reportId.toString(),
      reportPeriod: period,
      reportView: viewName,
      reportVersion: version.toString(),
      reportCategoryId:
        reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
      reportType: reportInstanceMetadata.reportConfiguration.reportDisplayName,
      maturity: maturity,
      rating: rating,
      product: product,
      firmId: reportInstanceMetadata.reportFirmId,
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_TOOLBAR_GO,
      eventInfo
    );
  }

  setPdfIconState(cell: ToolbarCell, setLoading: boolean) {
    if (cell) {
      if (setLoading) {
        (<ToolbarButtonComponent>cell.component).buttonIcon = faSpinner;
        (<ToolbarButtonComponent>cell.component).spin = true;
      } else {
        (<ToolbarButtonComponent>cell.component).buttonIcon = faPrint;
        (<ToolbarButtonComponent>cell.component).spin = false;
      }
    }
  }

  onExportPdf(
    fileName: string,
    config?: ToolbarConfig,
    customElementsList?: HTMLElement[],
    pdfCell?: ToolbarCell
  ) {
    // this method assumes that the pdf cell is in the first row and is the second component.
    const usePdfCell: ToolbarCell =
      pdfCell || (config && config.rows[0].components[1]);
    this.setPdfIconState(usePdfCell, true);

    this.pdfService.processing.next(true);
    const popupWin = window.open("", "_blank");
    popupWin.document.body.innerHTML = loadingPopupString;

    return this.getPdfElements(4000, 2, customElementsList)
      .then((results) => {
        const printContent = this.pdfService.getPrintContents(results);
        this.printPdfFromBrowser(printContent, fileName, popupWin);
        this.pdfService.processing.next(false);
        this.setPdfIconState(usePdfCell, false);
      })
      .catch((error) => {
        this.pdfService.processing.next(false);
        this.setPdfIconState(usePdfCell, false);
        if (popupWin) {
          popupWin.close();
        }
        const errorMessage = `There was an error generating the view for printing. \
          Please try again later or try another browser.`;
        window.alert(errorMessage);
      });
  }

  printPdfFromBrowser(
    printContents,
    fileName,
    printWindow?: Window,
    autoClose: boolean = true
  ) {
    const popupWin = printWindow || window.open("", "_blank");
    const onloadFn = autoClose
      ? "setTimeout(() => { window.print(); window.close(); }, 1000);"
      : "setTimeout(() => { window.print(); }, 1000);";
    popupWin.document.open();
    popupWin.focus();
    popupWin.document.write(`
      <html>
        <head>
          <title>${fileName}</title>
        </head>
        <body onload="${onloadFn}">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  getPdfElements(
    width: number = 4000,
    scale: number = 2,
    customElementsList?: HTMLElement[]
  ) {
    /**
     * (DDWA-4630, DDWA-4631)
     * ---
     * The HTML canvas tag will become `tainted` when some/all of its contents
     * were created from external resources (css, images, svg, etc).
     * In the event that a canvas is tainted, trying to call `canvas.toDataURL()`
     * will throw this --> SecruityError: the operation is unsecure.
     *
     * Safari is most sensitive to this security than the other common browsers.
     *
     * html2canvas, according to their documentation, creates a copy of the DOM
     * and load the resources to render the view so it is
     * susceptible to the SecurityError due to cross-origin policies.
     *
     * In our case, the only external resources (that we know of) that are causing this error are the base64 svg resources
     * used by `ag-grid` for its ag-icons (chevron arrow, menu icon, checkbox, etc).
     *
     * In order to prevent that SecurityError, we hide the bad elements during the print action.
     * This should not be a problem since those icons are not imperative/important.
     */

    const pdfElements =
      customElementsList || Array.from(document.getElementsByClassName("pdf"));
    const promises = [];

    const setAgIconsDisplay = (displayValue: string) => {
      pdfElements.forEach((pdfElm) => {
        Array.from(pdfElm.querySelectorAll(".ag-icon")).forEach(
          (iconElement) => {
            // iconElement.parentElement.removeChild(iconElement);
            (<HTMLElement>iconElement).style.display = displayValue;
          }
        );

        Array.from(pdfElm.querySelectorAll("svg")).forEach((element) => {
          // iconElement.parentElement.removeChild(iconElement);
          const isNgxChart = element.classList.contains(`ngx-charts`);
          if (isNgxChart) {
            return true;
          }
          (<SVGElement>element).style.display = displayValue;
        });
      });
    };

    setAgIconsDisplay("none");
    this.pdfService.setWhiteBackgroundPdf();

    const agent = detect();
    const browserPrintConfig =
      (html2canvasPrintConfigByBrowser[agent.name] &&
        html2canvasPrintConfigByBrowser[agent.name](scale, width)) ||
      {};
    const html2canvasOptions = {
      logging: false,
      /**
       * html2canvas configuration: https://html2canvas.hertzen.com/configuration
       * ---
       * So far, it seems like the defaults for scale, width and windowWidth are best for `Firefox`.
       *
       * The following properties are what is common across all browsers.
       * The browser specific props will be merged in from the `browserPrintConfig` object.
       */
      backgroundColor: "#FFFFFF",
      allowTaint: true,
      useCORS: true,

      // add browser print config
      ...browserPrintConfig,
    };

    pdfElements.forEach((element: any) => {
      let canvas;
      promises.push(
        html2canvas(element, html2canvasOptions)
          .then((canvasResult) => {
            // Add image only if its data url is not empty
            canvas = canvasResult;
            const result = {
              canvas,
              node: element,
              image: canvasResult.toDataURL(),
              width: width / 2,
              margin: [0, 0, 0, 10],
              background: "#FFFFFF",
            };
            return result;
          })
          .catch((error) => {
            throw error;
          })
      );
    });

    return Promise.all(promises).then((results) => {
      setAgIconsDisplay("inline-block");
      return results;
    });
  }
  htmlPrint(printEl: HTMLElement, fileName: string) {
    let popupWin;
    try {
      const htmlEL: HTMLElement =
        window.document.getElementsByTagName("html")[0];
      const print: HTMLElement = printEl || htmlEL.querySelector("#print");
      popupWin = this.openNewPage(fileName);
      popupWin.document.querySelector("html").innerHTML = htmlEL.innerHTML;
      const htmlBodyEl = popupWin.document.querySelector("body");
      htmlBodyEl.innerHTML = print.innerHTML;
      popupWin.document.querySelector("title").innerHTML = fileName;
      this.hideNonPrintEl(htmlBodyEl);
      this.prepareAgGridPrint(popupWin);
    } catch (error) {
      const errorMessage = `There was an error generating the view for printing. \
        Please try again later or try another browser.`;
      window.alert(errorMessage);
    } finally {
      if (popupWin) {
        popupWin.document.close();
      }
    }
  }

  private hideNonPrintEl(htmlBodyEl: HTMLBodyElement) {
    const controlsEl = htmlBodyEl.querySelector(".controls-container");
    this.styleDisplayNone(<HTMLElement>controlsEl);
    const spinnerEl = htmlBodyEl.querySelector(".spinner-container");
    this.styleDisplayNone(<HTMLElement>spinnerEl);
    this.setAgIconDisplay(htmlBodyEl, "none");
  }

  private styleDisplayNone(el: HTMLElement) {
    el ? (el.style.display = "none") : null;
  }

  private openNewPage(fileName: string) {
    const popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.focus();
    popupWin.document.write(`
      <html>
      <title>${fileName}</title>
      </html>`);
    popupWin.onload = () => {
      setTimeout(() => {
        popupWin.print();
        popupWin.close();
      }, 1000);
    };
    return popupWin;
  }

  private setAgIconDisplay(
    htmlBodyEl: HTMLBodyElement,
    displayValue: string = "none"
  ) {
    Array.from(htmlBodyEl.querySelectorAll(".ag-icon")).forEach(
      (iconElement) => {
        // iconElement.parentElement.removeChild(iconElement);
        (<HTMLElement>iconElement).style.display = displayValue;
      }
    );

    Array.from(htmlBodyEl.querySelectorAll("svg")).forEach((element) => {
      // iconElement.parentElement.removeChild(iconElement);
      const isNgxChart = element.classList.contains(`ngx-charts`);
      if (isNgxChart) {
        return true;
      }
      (<SVGElement>element).style.display = displayValue;
    });
  }

  prepareAgGridPrint(popupWin: any) {
    const agGrid: HTMLElement =
      popupWin.document.querySelector(".ag-body-viewport");
    if (agGrid) {
      //chrome fix to show all firms
      let noOfRows = agGrid.querySelectorAll(
        ".ag-center-cols-container .ag-row"
      ).length;
      agGrid
        .querySelector(".ag-center-cols-clipper")
        .setAttribute("style", "height:" + noOfRows * 52 + "px;");
      //fix for mozilla and firefox, this is fixed in ag-grid 25.0.1
      agGrid.setAttribute("style", "height:auto !important");
      var els: NodeListOf<HTMLElement> =
        popupWin.document.querySelectorAll("div.ag-row");
      els.forEach((el) => {
        el.style.transform = "none";
      });
      this.updateAgGridFlex(popupWin, "div.ag-root"); // ".ag-root"
      this.updateAgGridFlex(popupWin, "div.ag-root-wrapper-body"); // ".ag-root-wrapper-body"
      this.updateAgGridFlex(popupWin, "div.ag-root-wrapper"); // ".ag-root-wrapper"
    }
  }

  private updateAgGridFlex(popupWin: any, selector: string) {
    var agRootel: HTMLElement = popupWin.document.querySelector(selector);
    agRootel.style.display = "block";
  }
}
