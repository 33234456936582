import { Component, OnInit, Input } from '@angular/core';
import {
  IconDefinition,
  faChevronRight,
  faChevronDown,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'report-products-problems-grid-body',
  templateUrl: './grid-body.component.html',
  styleUrls: ['./grid-body.component.scss']
})
export class ProductsProblemsGridBodyComponent implements OnInit {
  @Input() viewName: string;
  @Input() rowData = [];
  @Input() quartersHeadings: string[] = [];

  faChevronRight: IconDefinition = faChevronRight;
  faChevronDown: IconDefinition = faChevronDown;
  faQuestionCircle: IconDefinition = faQuestionCircle;

  constructor() { }

  ngOnInit() {
  }
}
