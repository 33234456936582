<div id="admin-report-status-container">
  <report-loader></report-loader>

  <div class="flex flex-row justify-between items-center mb-5">
    <h1 class="page-title title-color pdf font-bold">
      {{ title }}
    </h1>

    <span class="question-icon-container" (click)="openHelp()">
      <fa-icon
        data-html2canvas-ignore
        [icon]="faQuestionCircle"
        class="fa-lg question-icon"
      ></fa-icon>
      <span>Help</span>
    </span>
  </div>

  <section class="description-section pdf">
    <p>
      The <em>Report Center Report Status</em> is a real-time report that keeps you informed on the availability
      and the internal review progress of all report on the Report Center.  Since the Report Center does not
      generate empty or blank report cards, this report provides an indication when no data was provided to the
      Report Center, for your firm, for a period.
    </p>
    <p>
      If you see a report category that you wish to view but do not have permission to. Please contact your
      firm's Super Account Administrator and request access. To identify your firm's Super Account Administrator
      and/or your firm's other Report Center administrator(s) click on the "My Account" link at the top of the
      page and view information from the "Applications &amp; Administrators" link. For more information about
      entitlement, access the FINRA Entitlement Program page on the <a href="https://www.finra.org/entitlement">FINRA Web site</a>.
    </p>
    <p>
      For questions regarding the content of this report,
      please contact the FINRA Gateway Call Center at: (800) 321-6273 or the
      Report Center at <a title="Email Report Center Admin" href="mailto:reportcenter-admin@finra.org">reportcenter-admin@finra.org</a>.</p>
  </section>


  <section *ngIf="dataIsReady">
    <div
      class="table-container pdf"
      *ngFor="let tableName of tableNames"
    >
      <div class="table-header">
        {{ tableName }}
      </div>
      <report-finra-grid
        #reportfinragrid
        id="id"
        [columnDefs]="columnDef"
        [rowData]="responseData[tableName]"
      ></report-finra-grid>
    </div>
  </section>

  <hr />

  <section class="report-main-content-padding report-disclaimer pdf">
    <p>
      * If this symbol appears on your report, this indicates that no data has been provided to FINRA
      for this value for the specified period. This is an expected behavior for weekday holidays but may also
      indicate information that was not sent, incorrectly formatted, or late.
    </p>
    <p>
      The information provided by
      the Report Center is a compliance aid and does not reflect a determination, by FINRA, that your firm
      has or has not violated applicable member rules governing these areas.
    </p>
  </section>
</div>
