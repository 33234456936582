// packages
import { AgGridModule } from 'ag-grid-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// custom
import { AdminReportExplorerComponent } from './components/pages/report-explorer/report-explorer.component';
import { AdminReportProblemsComponent } from './components/pages/admin/report-problems/report-problems.component';
import { AdminReportProblemsFormComponent } from './components/pages/admin/report-problems-form/form/problems-form.component';
import { AdminReportProblemsFormContainerComponent } from './components/pages/admin/report-problems-form/problems-form-container.component';
import { AdminReportStatusComponent } from './components/pages/report-status/report-status.component';
import { AlertComponent } from './components/fragments/alert/alert.component';
import { AnnouncementsService } from './services/announcements.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ArchiveViewComponent } from './components/pages/admin/archive-view/archive-view.component';
import { BytesFormatterPipe } from './pipes/bytes-formatter.pipe';
import { CanceledAsOfTradesComponent } from './components/pages/reports/summary/risk-monitoring/canceled-as-of-trades/canceled-as-of-trades.component';
import { CardComponent } from './components/fragments/card/card.component';
import { CardsFilterComponent } from './components/fragments/cards-filter/cards-filter.component';
import { configdev, localUserTestAccount } from '@env/config';
import { CorpFinComponent } from './components/pages/reports/summary/corp-fin/corp-fin.component';
import { CrossMarketSupervisionComponent } from './components/pages/reports/summary/cross-market-supervision/cross-market-supervision.component';
import { CustomerComplaintReportComponent } from './components/pages/reports/summary/risk-monitoring/customer-complaint-report/customer-complaint-report.component';
import { CustomerDebitsReportComponent } from './components/pages/reports/summary/risk-monitoring/customer-debits-report/customer-debits-report.component';
import { DebitsGridComponent } from './components/pages/reports/summary/risk-monitoring/customer-debits-report/debits-grid/debits-grid.component';
import { DisclosureComponent } from './components/pages/reports/summary/disclosure/disclosure.component';
import { DisclosureTimelinessComponent } from './components/pages/reports/summary/disclosure/disclosure-timeliness/disclosure-timeliness.component';
import { DqsComponent } from './components/pages/reports/summary/dqs/dqs.component';
import { DueDiligenceComponent } from './components/pages/reports/summary/msrb/due-diligence/due-diligence.component';
import { environment } from '@env/environment';
import { EquityComponent } from './components/pages/reports/summary/equity/equity.component';
import { ErrorMessageComponent } from './components/fragments/error-message/error-message.component';
import { ErrorMessagesService } from './services/error-messages/error-messages.service';
import { FinderComponent } from './components/fragments/finder/finder.component';
import { FinraChartComponent } from './components/fragments/finra-chart/finra-chart.component';
import { FinraDatePickerComponent } from './components/fragments/finra-date-picker/finra-date-picker.component';
import { FinraDatepickerComponent } from './components/fragments/finra-datepicker/finra-datepicker.component';
import { FinraGridClickableCellComponent } from './components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component';
import { FinraGridComponent } from './components/fragments/finra-grid/finra-grid.component';
import { FinraGridHeaderComponent } from './components/fragments/finra-grid-header/finra-grid-header.component';
import { FinraGridHtmlCellComponent } from './components/fragments/finra-html-grid-cell/finra-html-grid-cell.component';
import { FinraInfoModalComponent } from './components/fragments/finra-info-modal/finra-info-modal.component';
import { FinraLoadingSpinnerComponent } from './components/fragments/finra-loading-spinner/finra-loading-spinner.component';
import { FinraPercentagePipe } from './pipes/finra-percentage.pipe';
import { FinraToolbarCellComponent } from './components/fragments/finra-toolbar/finra-toolbar-cell/finra-toolbar-cell.component';
import { FinraToolbarComponent } from './components/fragments/finra-toolbar/finra-toolbar.component';
import { FinraValidValuePipe } from './pipes/finra-valid-value.pipe';
import { FirmInfoService } from './services/firm-info.service';
import { GridFiltersComponent } from './components/fragments/grid-filters/grid-filters.component';
import { HeaderComponent } from './components/fragments/header/header.component';
import { HomeComponent } from './components/pages/home/home.component';
import { IndustryProductsProblemsGridComponent } from './components/pages/reports/summary/risk-monitoring/customer-complaint-report/industry-products-problems-grid/industry-products-problems-grid.component';
import { LateFilingReportComponent } from './components/pages/reports/summary/corp-fin/late-filing-report/late-filing-report.component';
import { LoaderComponent } from './components/fragments/loader/loader.component';
import { LoaderInterceptorService } from './services/loader-interceptor.service';
import { LoprComponent } from './components/pages/reports/summary/lopr/lopr.component';
import { MsrbComponent } from './components/pages/reports/summary/msrb/msrb.component';
import { MsrbService } from './services/msrb.service';
import { NavComponent } from './components/fragments/nav/nav.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NotificationsPreferencesComponent } from './components/pages/notifications/notifications-preferences/notifications-preferences.component';
import { NotificationsService } from './services/notifications.service';
import { OnlineLearningComponent } from './components/pages/reports/summary/online-learning/online-learning.component';
import { PeerGroupComponent } from './components/fragments/peer-group/peer-group.component';
import { PrivatePlacementFilingTimelinessReportComponent } from './components/pages/reports/summary/corp-fin/private-placement-filing-timeliness-report/private-placement-filing-timeliness-report.component';
import { ProblemMessageComponent } from './components/fragments/problem-message/problem-message.component';
import { ProductsProblemsGridBodyComponent } from './components/pages/reports/summary/risk-monitoring/customer-complaint-report/products-problems-grid/grid-body/grid-body.component';
import { ProductsProblemsGridComponent } from './components/pages/reports/summary/risk-monitoring/customer-complaint-report/products-problems-grid/products-problems-grid.component';
import { PublicOfferingReportComponent } from './components/pages/reports/summary/corp-fin/public-offering-report/public-offering-report.component';
import { RegisteredRepresentativeCompositionComponent } from './components/pages/reports/summary/risk-monitoring/registered-reps-composition/registered-reps-composition.component';
import { ReportCardComponent } from './components/fragments/report-card/report-card.component';
import { ReportsDialogComponent } from './components/fragments/reports-dialog/reports-dialog.component';
import { ReportsService } from './services/reports.service';
import { ReportsViewComponent } from './components/pages/reports/search/reports-view.component';
import { RepsGridComponent } from './components/pages/reports/summary/risk-monitoring/registered-reps-composition/reps-grid/reps-grid.component';
import { RiskMonitoringComponent } from './components/pages/reports/summary/risk-monitoring/risk-monitoring.component';
import { RiskMonitoringService } from './services/risk-monitoring.service';
import { RoundPipe } from './pipes/round.pipe';
import { SalesPracticeComplaintReportComponent } from './components/pages/reports/summary/risk-monitoring/sales-practice-complaint-report/sales-practice-complaint-report.component';
import { SeverelyDisciplinedFirmsModalComponent } from './components/pages/reports/summary/risk-monitoring/registered-reps-composition/firms-modal/firms-modal.component';
import { SidenavComponent } from './components/fragments/sidenav/sidenav.component';
import { SidenavigationService } from './services/sidenavigation.service';
import { TimeoutWarningComponent } from './components/fragments/timeout-warning/timeout-warning.component';
import { ToolbarService } from './services/toolbar.service';
import { TraceComponent } from './components/pages/reports/summary/trace/trace.component';
import { TradesGridComponent } from './components/pages/reports/summary/risk-monitoring/canceled-as-of-trades/trades-grid/trades-grid.component';
import { UploadBusinessObjectPageComponent } from './components/pages/upload/upload.component';
import { UsageLogComponent } from './components/pages/usage-log/usage-log.component';
import { UserAccessGuard } from './guards/user-access.guard';
import { UserAccountComponent } from './components/fragments/user-account/user-account.component';



@NgModule({
  declarations: [
    AdminReportExplorerComponent,
    AdminReportProblemsComponent,
    AdminReportProblemsFormComponent,
    AdminReportProblemsFormContainerComponent,
    AdminReportStatusComponent,
    AlertComponent,
    AppComponent,
    ArchiveViewComponent,
    BytesFormatterPipe,
    CanceledAsOfTradesComponent,
    CardComponent,
    CardsFilterComponent,
    CorpFinComponent,
    CrossMarketSupervisionComponent,
    CustomerComplaintReportComponent,
    CustomerDebitsReportComponent,
    DebitsGridComponent,
    DisclosureComponent,
    DisclosureTimelinessComponent,
    DqsComponent,
    DueDiligenceComponent,
    EquityComponent,
    ErrorMessageComponent,
    FinderComponent,
    FinraChartComponent,
    FinraDatepickerComponent,
    FinraDatePickerComponent,
    FinraGridClickableCellComponent,
    FinraGridComponent,
    FinraGridHeaderComponent,
    FinraGridHtmlCellComponent,
    FinraInfoModalComponent,
    FinraLoadingSpinnerComponent,
    FinraPercentagePipe,
    FinraToolbarCellComponent,
    FinraToolbarComponent,
    FinraValidValuePipe,
    GridFiltersComponent,
    HeaderComponent,
    HomeComponent,
    IndustryProductsProblemsGridComponent,
    LateFilingReportComponent,
    LoaderComponent,
    LoprComponent,
    MsrbComponent,
    NavComponent,
    NotificationsPreferencesComponent,
    OnlineLearningComponent,
    PeerGroupComponent,
    PrivatePlacementFilingTimelinessReportComponent,
    ProblemMessageComponent,
    ProductsProblemsGridBodyComponent,
    ProductsProblemsGridComponent,
    PublicOfferingReportComponent,
    RegisteredRepresentativeCompositionComponent,
    ReportCardComponent,
    ReportsDialogComponent,
    ReportsViewComponent,
    RepsGridComponent,
    RiskMonitoringComponent,
    RoundPipe,
    SalesPracticeComplaintReportComponent,
    SeverelyDisciplinedFirmsModalComponent,
    SidenavComponent,
    TimeoutWarningComponent,
    TraceComponent,
    TradesGridComponent,
    UploadBusinessObjectPageComponent,
    UsageLogComponent,
    UserAccountComponent,
  ],
  imports: [
    // Import relevant http modules
    AgGridModule.withComponents([
      GridFiltersComponent,
      FinraGridClickableCellComponent,
      FinraGridHeaderComponent,
      FinraGridHtmlCellComponent,
    ]),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),

    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxChartsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  entryComponents: [
    FinraChartComponent,
    FinraGridClickableCellComponent,
    FinraGridComponent,
    FinraGridHeaderComponent,
    FinraGridHtmlCellComponent,
    FinraInfoModalComponent,
    PeerGroupComponent,
    SeverelyDisciplinedFirmsModalComponent,
    TimeoutWarningComponent,
  ],
  providers: [
    AnnouncementsService,
    DatePipe,
    ErrorMessagesService,
    FirmInfoService,
    MatIconRegistry,
    MsrbService,
    NotificationsService,
    ReportsService,
    RiskMonitoringService,
    SidenavigationService,
    ToolbarService,
    UserAccessGuard,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    { provide: 'environment', useValue: environment },
    { provide: 'configdev', useValue: configdev },
    { provide: 'localUserTestAccount', useValue: localUserTestAccount },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
