import {
  ICellRendererParams,
  ColDef
} from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { DATE_PIPE, makeHtmlContent } from '../../shared/utils/utils';

export interface AuditActionLookup {
  auditActionDescription: string;
  auditActionLookupId: number;
  examUserFlag: boolean;
  externalUserFlag: boolean;
  internalUserFlag: boolean;
  sroUserFlag: boolean;
}

export interface UsageLogSearch {
  auditActionLookupIds?: Array<number>;
  endDate?: string;
  examCrdNumber?: string;
  examNumber?: string;
  firmCrdNumber?: string;
  rcUserIds?: Array<number>;
  reportTypes?: Array<string>;
  sortField?: string;
  sortFields?: object;
  sortOrder?: string;
  sortOrders?: object;
  startDate?: string;
}

export interface UsageLog {
  auditActionId?: number;
  auditActionTimestamp?: string;
  auditActionLookupId?: number;
  reportCenterUserId?: number;
  reportCenterUser?: {
    reportCenterUserId: number,
    userCreatedTimestamp: string,
    userEmail: string,
    userName: string,
    userOrganizationClass: string | null,
    userOrganizationId: string,
  };
  httpUserCreateTimestamp?: string;
  httpUserOrgId?: number;
  auditActionDetail?: string;
  auditActionCreateTimestamp?: string;
  auditActionUpdateTimestamp?: string;
  logicalDelete?: boolean;
  auditActionLookup?: AuditActionLookup;
}

export interface UsageLogExtended extends UsageLog {
  reportInfo: {
    reportName: string,
    firmId?: string,
    traceMpid?: string,
    msrbId?: string,
    period: string,
    version: string,
    view: string,
  }
}

export const matchSummaryDetailReportActionRegex = (/Report Name: '(.+)',? (?:(Trace MPID|Equity MPID|MSRB ID|Firm ID|Firm Id)): ([a-zA-Z0-9]+),? Period: ([a-zA-Z0-9-\s,]+),? Version: ([\d]+)(,? View: ([a-zA-Z0-9-\s]+))?/);

export function extendUsageLogData(data: UsageLog): UsageLogExtended {
  const isSummaryOrDetailReportAction = (
    data.auditActionLookup.auditActionDescription === `Summary Report View` ||
    data.auditActionLookup.auditActionDescription === `Summary Report Export` ||
    data.auditActionLookup.auditActionDescription === `Detailed Report Download`
  );

  let newData: UsageLogExtended;

  if (isSummaryOrDetailReportAction) {
    let matcher: string[] = data.auditActionDetail.match(matchSummaryDetailReportActionRegex);
    if (!matcher) {
      console.log(`regex did not match against data:`, data);
      matcher = [];
    }

    const reportName = matcher[1];
    const idType = matcher[2];
    const firmId = matcher[3];
    const period = matcher[4] && (matcher[4].endsWith(',') ? matcher[4].substring(0, matcher[4].length - 1) : matcher[4]);
    const version = matcher[5];
    const view = matcher[7] || '';
    newData = {
      ...data,
      // new columns
      reportInfo: {
        reportName,
        firmId: '',
        traceMpid: '',
        msrbId: '',
        period,
        version,
        view,
      }
    };
    switch (idType) {
      case `Trace MPID`: {
        newData.reportInfo.traceMpid = firmId;
        break;
      }
      case `MSRB ID`: {
        newData.reportInfo.msrbId = firmId;
        break;
      }
      default: {
        newData.reportInfo.firmId = firmId;
      }
    }
  }
  else {
    newData = {
      ...data,
      // new columns
      reportInfo: {
        reportName: '',
        firmId: '',
        traceMpid: '',
        msrbId: '',
        period: '',
        version: '',
        view: '',
      }
    };
  }

  return newData;
}

export let UsageLogGridHeader: Array<ColDef> = [
  {
    headerName: 'Date',
    field: 'auditActionTimestamp',
    valueFormatter: params => DATE_PIPE.transform(params.value, 'MM/dd/yyyy'),
    width: 120,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'TIME'
    }
  },
  {
    headerName: 'Time',
    field: 'auditActionTimestamp',
    valueFormatter: params => DATE_PIPE.transform(params.value, 'hh:mm a'),
    width: 120,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'TIME'
    }
  },
  {
    headerName: 'Firm CRD',
    field: 'httpUserOrgId',
    width: 100,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'FIRMCRD'
    }
  },
  {
    headerName: 'User',
    field: 'reportCenterUser.userName',
    width: 200,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'USER'
    },
    cellRenderer: (params: ICellRendererParams) => {
      return params.data.reportCenterUser &&
        params.data.reportCenterUser.userName
        ? params.data.reportCenterUser.userName
        : null;
    }
  },
  {
    headerName: 'Action',
    field: 'auditActionLookup.auditActionDescription',
    width: 175,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'ACTION'
    }
  },
  // {
  //   /**
  //    * DDWA-4267: Report Center - Usage Log
  //    * Export excel/csv is missing details column.
  //    * according to AG-Grid, cell renderer will not get exported
  //    *
  //    * ticket - https://jira.finra.org/browse/DDWA-4267
  //    * @see - https://www.ag-grid.com/javascript-grid-excel/
  //    */
  //   headerName: 'Details',
  //   field: 'auditActionDetail',
  //   valueFormatter: params => makeHtmlContent(params.value),
  //   width: 500,
  //   headerComponent: 'finraGridHeaderComponent',
  //   headerComponentParams: {
  //     enableSorting: true,
  //     sortField: 'DETAIL'
  //   }
  // },

  // new columns
  {
    headerName: 'Report Name',
    field: 'reportInfo.reportName',
    width: 400,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: false,
      sortField: 'REPORT_NAME'
    }
  },
  {
    headerName: 'Firm ID',
    field: 'reportInfo.firmId',
    width: 100,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: false,
      sortField: 'REPORT_FIRM'
    }
  },
  {
    headerName: 'TRACE MPID',
    field: 'reportInfo.traceMpid',
    width: 125,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: false,
      sortField: 'REPORT_FIRM'
    }
  },
  {
    headerName: 'MSRB ID',
    field: 'reportInfo.msrbId',
    width: 125,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: false,
      sortField: 'REPORT_FIRM'
    }
  },
  {
    headerName: 'Period',
    field: 'reportInfo.period',
    width: 100,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: false,
      sortField: 'REPORT_PERIOD'
    }
  },
  {
    headerName: 'Version',
    field: 'reportInfo.version',
    width: 100,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: false,
      sortField: 'REPORT_VERSION'
    }
  },
  {
    headerName: 'View',
    field: 'reportInfo.view',
    width: 150,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: false,
      sortField: 'REPORT_VIEW'
    }
  },
];

export const actionsContainerTitle = `Click on any of the audit actions to toggle selection.`;
