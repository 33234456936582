<div *ngIf="rowData">
  <div class="private-placement-container" *ngFor="let rule of rules">
    <div class="table-header">
      <div class="table-title">
        <span
          class="link-btn pdf"
          (click)="switchView('table')"
          [ngClass]="{ 'link-inactive': viewType == 'chart' }"
        >
          Filings Made Under FINRA Rule {{ rule }}
        </span>
        <span class="link-inactive">|</span>
        <span
          class="link-btn"
          (click)="switchView('chart')"
          [ngClass]="{ 'link-inactive': viewType == 'table' }"
        >
          Charts
        </span>
      </div>
      <div>
        <span (click)="onReportDetails(rule)" class="details">
          <fa-icon
            [icon]="faDownload"
            class="fa-lg dialog-tools-icon"
          ></fa-icon>
          Details
        </span>

        <span class="question-icon-container" (click)="openHelp(rule)">
          <fa-icon
            data-html2canvas-ignore
            [icon]="faQuestionCircle"
            class="fa-lg question-icon"
          ></fa-icon>
          <span>Help</span>
        </span>
      </div>
    </div>
    <div *ngIf="hasRuleData(rule)">
      <div *ngIf="showView('table')" style="overflow-x: scroll">
        <report-finra-grid
          class="pdf"
          style="xwidth:3000px;display:block;"
          #reportfinragrid
          id="id"
          [columnDefs]="columnDefs[rule]"
          [rowData]="ruleData[rule]"
          [groupDefaultExpanded]="0"
          [gridOptions]="gridOptions"
        ></report-finra-grid>
      </div>
      <div *ngIf="showView('chart')" class="chart-container">
        <report-finra-chart
          class="pdf"
          *ngIf="chartRuleData[rule]"
          [template]="'bar-group-stacked'"
          [rowData]="chartRuleData[rule]"
          [yAxisTicks]="[0, 20, 40, 60, 80, 100]"
          [barPadding]="80"
          [yAxisTickFormatting]="addPercent"
        ></report-finra-chart>
      </div>
    </div>
    <div *ngIf="!hasRuleData(rule)" class="no-data pdf">
      No data available for this section.
    </div>
  </div>
</div>
