import { DatePipe } from '@angular/common';
import * as Cookies from 'js-cookie';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { sideMenus } from 'src/app/configs/model/sidenavigation';

/** Static Variables */

export const DATE_PIPE = new DatePipe('en-US');
export const VIEW_NAME_WILDCARD = '*';
export const MONTH_NAMES = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const MONTH_NAMES_SHORT = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const primitivesTypes = ['boolean', 'string', 'number', 'bigint', 'symbol'];
export const FINRA_GRID_NULL_VALUE = '*';
export const ROW_DATA_NO_INFO_TEXT = 'No information available for the period.';
export const supportedBrowsers = ['chrome', 'safari', 'ie', 'firefox', 'edge'];
export const mobileOsList = ['iOS', 'Android OS'];
export const userAgentRegex =
  /alexa|bot|crawl(er|ing)|facebookexternalhit|feedburner|google web preview|nagios|postrank|pingdom|slurp|spider|yahoo!|yandex/i;

export const loadingPopupString = `
  <style>
    .spinner-icon {
      margin: 10px;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: #29d;
      border-left-color: #29d;
      border-radius: 50%;

      animation: nprogress-spinner 400ms linear infinite;
      -webkit-animation: nprogress-spinner 400ms linear infinite;
      -moz-animation: nprogress-spinner 400ms linear infinite;
      -ms-animation: nprogress-spinner 400ms linear infinite;
      -o-animation: nprogress-spinner 400ms linear infinite;
      animation: nprogress-spinner 400ms linear infinite;
    }

    @-webkit-keyframes nprogress-spinner {
      0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    }
    @-moz-keyframes nprogress-spinner {
      0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
      100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
    }
    @-o-keyframes nprogress-spinner {
      0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
      100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
    }
    @-ms-keyframes nprogress-spinner {
      0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
      100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
    }
    @keyframes nprogress-spinner {
      0%   { transform: rotate(0deg);   transform: rotate(0deg); }
      100% { transform: rotate(360deg); transform: rotate(360deg); }
    }

    .middle {
      display: block;
      margin: auto;
    }
  </style>

  <div id="loading-info-box" style="margin: 0 0 20px 0">
  <h1 style="text-align: center;">The print contents are generating. Please wait...</h1>
  <div class="spinner-icon middle"></div>
  </div>
  <div id="print-contents-container"></div>
  `;

export const crdRegex = /^[\d]+$/;
export const mpidRegex = /^[A-Z]{4}$/;
export const msrbRegex = /^A[\d]{4}$/;

/** Types, Interfaces, Enums */

export enum FileExtensions {
  pdf = 'pdf',
  csv = 'csv',
}

export interface PlainObject {
  [key: string]: any;
}

/** Methods */

export function removeSpaces(str: string): string {
  const formatted = str.replace(/\s/gi, '');
  return formatted;
}

export const convertDateToString = (
  dateObj: Date | string,
  format?: string
): string => {
  let dateString;
  const isDateObj = typeof (dateObj) === 'object';
  if (isDateObj) {
    // it's a date object
    const dateIsoString = (<Date> dateObj).toISOString();
    dateString = dateIsoString;
  } else {
    // it's a string
    dateString = (<string> dateObj);
  }
  const dateFormat = format || 'yyyy-MM-dd';
  const dateStringFormatted = (new DatePipe(`en-US`)).transform(dateString, dateFormat);
  return dateStringFormatted;
};

export const html2canvasPrintConfigByBrowser = {
  firefox: (scale, width) => ({
    // for firefox, use defaults (in this case, an empty object)
  }),
  chrome: (scale, width) => ({
    scale: scale,
    width: width,
    windowWidth: width / 2,
  }),
  safari: (scale, width) => ({
    scale: scale,
    width: width,
    windowWidth: width / 2,
  }),
  ie: (scale, width) => ({
    scale: scale,
    width: width,
    windowWidth: width / 2,
  }),
  edge: (scale, width) => ({
    scale: scale,
    width: width,
    windowWidth: width / 2,
  }),
};

export const createDateFromPeriod = function(reportPeriodDate: string): Date {
  if (!reportPeriodDate) {
    // `'reportPeriodDate' argument had no value...`
    return;
  }
  return new Date(reportPeriodDate.replace(/-/g, '/'));
};

export const padLeft = function(value, len = 2, chr = '0') {
  value = value.toString();

  while (value.length < len) {
    value = chr + value;
  }

  return value;
};

export const getMonthFromPeriod = function(reportPeriodDate: string): number {
  return createDateFromPeriod(reportPeriodDate).getMonth() + 1;
};

export const getMonthLabels = function(
  reportPeriodDate: string,
  short?: boolean
): Array<string> {
  const anchorMonth = getMonthFromPeriod(reportPeriodDate);
  const result: Array<string> = [];
  const year = createDateFromPeriod(reportPeriodDate).getFullYear();
  const monthsList = MONTH_NAMES.slice(1);
  for (let i = 1; i <= 6; i++) {
    let monthIndex = anchorMonth - i;
    let labelYear = year;

    if (monthIndex < 0) {
      monthIndex += 12;
      labelYear--;
    }

    const monthString = monthsList[monthIndex].substring(0, short ? 3 : undefined);
    const monthLabel = monthString + ' ' + labelYear;

    result.push(monthLabel);
  }
  return result;
};

export const appendSuffix = function(value: string, suffix: string): string {
  // if suffix is missing the dot, add it
  if (!suffix || suffix.length < 2) {
    // `'suffix' argument invalid: must be 2 or more characters...`
    return;
  }
  const extenstion = suffix.startsWith('.') ? suffix : `.${suffix}`;
  return value.endsWith(extenstion) ? value : value + extenstion;
};

export const saveCsv = function(data: string, fileName?: string) {
  /**
   * DDWA-4379
   * For some reason, the Google Analytics scripts causes problems for Blobs.
   * We normally convert CSV strings to a Blob and trigger the browser to download it.
   * However, since the Google Analytics causes a break for Blobs,
   * the download approach must change.
   *
   * Rather than creating a Blob data and creating an object URL for it,
   * we create the raw data uri string itself. It follow this format:
   * - The word `data`, then a colon, then the type. examples:
   * -- data:text/csv
   * -- data:text/plain
   * -- data:application/json
   * -- data:application/pdf
   *
   * - Then, prefixed by a semi-colon, add the encoding. examples of encoding:
   * -- ;charset=utf-8 (text)
   * -- ;base64
   *
   * - Last, prefixed by a comma, add the URI encoded data (too long to put example here).
   *
   * This makes up the Data URI that we can download from.
   *
   * UPDATE (1/16/2020):
   * ---
   * Microsoft Edge has a problem with downloading via base64 and google analytics causes errors with a Blob.
   * Now, we will try to download the details via a new popup window;
   * that context/environment should be able to download blobs fine.
   */

  const useFileName = appendSuffix(fileName || `export-data-${Date.now()}`, '.csv');

  // found: https://stackoverflow.com/questions/37203771/download-base64-data-using-javascript-ie11
  if ((window as any).navigator.msSaveBlob) {
    // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    const blob = new Blob([data], { type: 'text/csv' });
    (window as any).navigator.msSaveOrOpenBlob(blob, useFileName);
    return;
  }

  // if data is really large (string length is greater than 1 million)
  const dataIsVeryLarge = data.length > 1000000;
  if (dataIsVeryLarge) {
    const blob = new Blob([data], { type: 'text/csv' });
    const hiddenElement = document.createElement('a');
    const encodedData = URL.createObjectURL(blob);
    const href = `${encodedData}`;
    hiddenElement.href = href;
    hiddenElement.target = '_blank';
    hiddenElement.download = useFileName;
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    setTimeout(() => {
      document.body.removeChild(hiddenElement);
    }, 1000);
    return;
  }

  // found here: https://code-maven.com/create-and-download-csv-with-javascript
  const hiddenElement = document.createElement('a');
  const encodedData = encodeURIComponent(data);
  const href = `data:text/csv;charset=utf-8,${encodedData}`;
  hiddenElement.href = href;
  hiddenElement.target = '_blank';
  hiddenElement.download = useFileName;
  document.body.appendChild(hiddenElement);
  hiddenElement.click();
  setTimeout(() => {
    document.body.removeChild(hiddenElement);
  }, 1000);
};

export const toFriendlyName = function(value: string) {
  if (value == null || value.length === 0) {
    return value;
  }

  value = value.replace(/([a-z])([A-Z])/g, '$1 $2');

  return value.charAt(0).toUpperCase() + value.substr(1);
};

// simply return the value if it is a valid FINRA value; return '*' otherwise
export const finraFormatValidValue = (value, allowEmptyString: boolean = false) => {
  const isInvalidValue = (
    (value === '' && !allowEmptyString) ||
    value === null ||
    value === undefined
  );
  return isInvalidValue ? FINRA_GRID_NULL_VALUE : value;
};

export const finraIsInvalidValue = (value, allowEmptyString: boolean = false) => {
  const isInvalidValue = (
    (value === '' && !allowEmptyString) ||
    value === null ||
    value === undefined
  );
  return isInvalidValue;
};

export const finraIsInvalidNumber = (value) => {
  const isInvalidValue = (
    finraIsInvalidValue(value) ||
    isNaN(value)
  );
  return isInvalidValue;
};

export const finraGridDateFormatter = function(cell) {
  let value = cell && cell.value;

  if (finraIsInvalidValue(value)) {
    return FINRA_GRID_NULL_VALUE;
  }

  value = new Date(value);

  return (
    padLeft(value.getMonth() + 1) +
    '/' +
    padLeft(value.getDate()) +
    '/' +
    value
      .getFullYear()
      .toString()
  );
};

export const finraGridDateTimeFormatter = function(cell) {
  let value = cell && cell.value;

  if (finraIsInvalidValue(value)) {
    return FINRA_GRID_NULL_VALUE;
  }

  value = new Date(value);

  return (
    padLeft(value.getMonth() + 1) +
    '/' +
    padLeft(value.getDate()) +
    '/' +
    value
      .getFullYear()
      .toString()
      .substr(2) +
    ' ' +
    padLeft(value.getHours()) +
    ':' +
    padLeft(value.getMinutes())
  );
};

// https://www.jacklmoore.com/notes/rounding-in-javascript/
// https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
export function round(value: string | number, decimals: number): number {
  const isNumber: boolean = typeof (value) === 'number';
  const useValue: number = isNumber && (<number> value) || parseFloat((<string> value));
  const roundValue: string = useValue + 'e' + decimals;
  const newValue = Number(Math.round((<any> roundValue)) + 'e-' + decimals);
  return newValue;
}

export const roundUptoTwoDecimal = function(value, mask?: string) {
  const isInvalidNumber = finraIsInvalidNumber(value);
  const roundedValue = isInvalidNumber
    ? FINRA_GRID_NULL_VALUE
    : round(value, 2).toFixed(2);
  return mask ? roundedValue + mask : roundedValue;
};

export const numericValueFormatter = function(value, mask) {
  const isInvalidNumber = finraIsInvalidNumber(value);
  if (isInvalidNumber) {
    return FINRA_GRID_NULL_VALUE;
  }

  const absValue = Math.abs(value);

  if (absValue < 1000 && absValue !== Math.floor(absValue)) {
    value = roundUptoTwoDecimal(value);
    return mask === '%' ? value + '%' : value;
  }

  const intValue = Math.floor(value)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  const decimals = Math.round((value - Math.floor(value)) * 100) / 100;
  const result =
    decimals === 0 && mask !== '%'
      ? intValue
      : intValue +
        '.' +
        decimals
          .toFixed(2)
          .toString()
          .substr(2, 2);

  return mask === '%' ? result + '%' : result;
};

export const getMonthYrDate = function(value, isDaily?: boolean) {
  if (!value) {
    return null;
  }
  const dateArr = value.split('-');
  let month, day, year;
  if (dateArr[0].length > 2) {
    // format must be yyyy-mm-dd
    month = parseInt(dateArr[1], 10);
    day = dateArr[2];
    year = dateArr[0];
  } else {
    // format must be mm-dd-yyyy
    month = parseInt(dateArr[0], 10);
    day = dateArr[1]
    year = dateArr[2];
  }

  const formatted_string = isDaily
    ? `${MONTH_NAMES[month]} ${day}, ${year}`
    : `${MONTH_NAMES[month]} ${year}`;
  return formatted_string;
};

export const finraGridNumericValueFormatter = function(cell) {
  const value = cell && cell.value && parseInt(cell.value, 10);
  const isInvalidNumber = finraIsInvalidNumber(value);
  if (isInvalidNumber) {
    return FINRA_GRID_NULL_VALUE;
  }

  return numericValueFormatter(value, cell.data && cell.data.mask);
};

export const internationalNumericFormatter = function(params) {
  const value = params && params.value;
  const isInvalidNumber = finraIsInvalidNumber(value);
  if (isInvalidNumber) {
    return FINRA_GRID_NULL_VALUE;
  }
  const numFormat = new Intl.NumberFormat('en-US');
  return numFormat.format(params.value);
};

export const finraGridAmountValueFormatter = function(params) {
  const value = params.value;
  const isInvalidNumber = finraIsInvalidNumber(value);
  if (isInvalidNumber) {
    return FINRA_GRID_NULL_VALUE;
  }
  return `$${roundUptoTwoDecimal(value)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const finraGridNumericValueFormatterNoRound = function(params) {
  const value = params && params.value;
  const isInvalidNumber = finraIsInvalidNumber(value);
  if (isInvalidNumber) {
    return FINRA_GRID_NULL_VALUE;
  }
  return value;
};

/*
  explicitly indicate the number type of a value to determine how to process it.
*/
export type ForceNumberType = 'int' | 'float';

export const integerPercentValueFormatter = (
  value,
  mask?,
  forceType?: ForceNumberType
) => {
  if (finraIsInvalidValue(value)) {
    return;
  }
  // if should explicitly process the value as the forceType
  if (forceType === 'int') {
    return numericValueFormatter(value, null);
  } else if (forceType === 'float') {
    return numericValueFormatter(value, mask)
  } else {
    // forceType is unknown; infer via value
    const isFloat = value.toString().includes('.');
    const formattedValue = isFloat
      ? numericValueFormatter(value, mask)
      : numericValueFormatter(value, null);
    return formattedValue;
  }
};

export const finraGridIntegerPercentValueFormatter = function(
  params,
  forceType?: ForceNumberType
) {
  /**
   * simple layer on top of another formatter utility method.
   * correctly identifies if value is an integer or float/percentage
   * and passes it to the appropriate formatter function
  */
  const value = params && params.value;
  if (finraIsInvalidValue(value)) {
    return FINRA_GRID_NULL_VALUE;
  }
  const formattedValue = integerPercentValueFormatter(value, '%', forceType);
  return formattedValue;
};

export const finraGridPercentValueFormatter = function(params) {
  const value = params && params.value;
  const isInvalidNumber = finraIsInvalidNumber(value);
  if (isInvalidNumber) {
    return FINRA_GRID_NULL_VALUE;
  }
  return roundUptoTwoDecimal(params.value, '%');
};

export const percentMultipliedValueFormatter = (
  params,
  mask,
  forceType?: ForceNumberType
) => {
  const multiplied = params.value * 100;
  const formattedValue = integerPercentValueFormatter(multiplied, mask, forceType);
  return formattedValue;
};

export const finraGridPercentMultipliedValueFormatter = (
  params,
  forceType?: ForceNumberType
) => {
  const value = params && params.value;
  if (finraIsInvalidValue(value)) {
    return FINRA_GRID_NULL_VALUE;
  }
  return percentMultipliedValueFormatter(params, '%', forceType);
};

export const quarterDateFormat = function(date: string) {
  const dt = createDateFromPeriod(date);
  const result = Math.ceil((dt.getMonth() + 1) / 3) + 'Q' + dt.getFullYear();
  return result;
};

export const finraGridMonthDateFormatter = function(params) {
  const value = params && params.value;
  return finraIsInvalidValue(value)
    ? FINRA_GRID_NULL_VALUE
    : getMonthYrDate(params.value);
};

export const finraGridQuarterDateFormatter = function(params) {
  const value = params && params.value;
  return finraIsInvalidValue(value)
    ? FINRA_GRID_NULL_VALUE
    : quarterDateFormat(params.value);
};

export const quarterDateFormatFullText = function(date: string) {
  const dt = createDateFromPeriod(date);
  const quarter = Math.ceil((dt.getMonth() + 1) / 3);
  const year = dt.getFullYear();
  switch (quarter) {
    case 1:
      return `1st Quarter ${year}`;
    case 2:
      return `2nd Quarter ${year}`;
    case 3:
      return `3rd Quarter ${year}`;
    case 4:
      return `4th Quarter ${year}`;
  }
};

export const quarterEndDateFormat = function(date: string) {
  const dt = createDateFromPeriod(date);
  const year = dt.getFullYear();

  switch (Math.ceil((dt.getMonth() + 1) / 3)) {
    case 1:
      return year + '-03-31';

    case 2:
      return year + '-06-30';

    case 3:
      return year + '-09-30';

    default:
      return year + '-12-31';
  }
};

/**
 * Create Line Chart Data: Helper Method
 *
 * @example
 * const chartRowData = [
    createLineData(
      dataList
      'Potential Profit',
      'profitExceptionCount',
      'some date' // or (data) => { ...code that returns string }
    ),
   ];
*/
export const createLineChartData = (
  dataList: any[],
  name: string,
  prop: string,
  seriesName: string | ((obj: any) => string),
) => {
  return {
    name: name,
    series: dataList.map(data => ({
      name: (typeof (seriesName) === 'function') ? seriesName(data) : seriesName,
      value: parseInt(data[prop], 10)
    })),
  };
};

export const sort = function(
  items: Array<object>,
  property: string,
  desc: boolean = false,
  caseInsensitive: boolean = false
) {
  return items
    .map(a => ({ ...a }))
    .sort((a, b) => {
      let result = 0;

      a = a[property];
      b = b[property];

      if (caseInsensitive) {
        a = (a || '').toString().toUpperCase();
        b = (b || '').toString().toUpperCase();
      }

      if (a < b) {
        result = 1;
      } else if (a > b) {
        result = -1;
      }
      return result * (desc ? 1 : -1);
    });
};

/**
 * Array Sort
 * ===
 *
 * Sorts an array of PRIMITIVE data, NOT objects.
 * The `Date` object is an exception here because it can be conpare (<, >, ==, ===, etc.) in a similar way as numbers.
 *
 * @param list the array of items
 * @param type the type of items/data in `list`
 * @param desc the direction of the sort. true = `Z-A`; false = `A-Z`
 * @param caseInsensitive if tyoe is `string` and should sort case insensitive
 * @return {list} the given list to allow method chaining.
 */
export const weakSort = (
  list: any[],
  type: 'string' | 'number' | 'date',
  desc: boolean = false,
  caseInsensitive: boolean = true
) => {
  const callback = (a, b) => {
    let result = 0;
    let x, y;

    if (type === 'string' && caseInsensitive) {
      x = (a || '').toString().toLowerCase();
      y = (b || '').toString().toLowerCase();
    } else if (type === 'date') {
      x = new Date(a);
      y = new Date(b);
    } else {
      x = a;
      y = b;
    }

    if (x < y) {
      result = 1;
    } else if (x > y) {
      result = -1;
    }
    return result * (desc ? 1 : -1);
  };

  list.sort(callback);
  return list;
};

export function getDataByMappingName(
  data: any[],
  prop: string
) {
  // create object map of list data by column name for easy/dynamic access
  // in the loops below
  const dataByMappingName = {};
  data.forEach((obj) => {
    dataByMappingName[obj[prop]] = obj;
  });
  return dataByMappingName;
}

export function flattenList(listObj: any[]) {
  const newList = [];
  for (const item of listObj) {
    const shouldFlatten = Array.isArray(item);
    if (shouldFlatten) {
      const flattenedItem = flattenList(item);
      newList.push(...flattenedItem);
    } else {
      newList.push(item);
    }
  }
  return newList;
}

/**
 * Check Primitive
 * ---
 *
 * Check if argument is a primitive by evaluating via `typeOf`.
 *
 * @param {*} obj
 * @returns {boolean}
 */

export const checkPrimitive = (obj) => {
  if (obj === undefined) {
    // `'obj' argument was undefined; returning true...`
    return true;
  }
  const objType = typeof(obj);
  const isPrimitive = (
    obj === null ||
    objType === 'boolean' ||
    objType === 'number' ||
    objType === 'bigint' ||
    objType === 'string' ||
    objType === 'symbol'
  );
  return isPrimitive;
};

/**
 * Copy Object
 * ---
 *
 * Deep copy object via recursion call.
 * - for primitives, just return the given argument.
 * - for Dates, call new Date instance with argument and return it
 * - for arrays, create new array and push recursive call for each item
 * - for object, create new object and set each key to recursive call
 *
 * @param {*} obj
 * @returns {object}
 */
export const copyObj = (obj) => {
  const isPrimitive = checkPrimitive(obj);
  if (isPrimitive) {
    return obj;
  }
  if (obj.constructor === Date) {
    return new Date(obj);
  }
  let copy;
  if (obj.constructor === Array) {
    copy = [];
    for (const item of obj) {
      const copyItem = copyObj(item);
      copy.push(copyItem);
    }
  } else if (obj.constructor === Object) {
    copy = {};
    const keys = Object.keys(obj);
    for (const key of keys) {
      const copyItem = copyObj(obj[key]);
      copy[key] = copyItem;
    }
  }

  return copy;
};

/**
 * Sorts a list with deeply nested objects by a given path string.
 * This method modifies the given array object; it does not return a modified copy.
 * This allows for method chaining.
 *
 * @param list = array to sort. should be a list of bjects
 * @param propertyString = a string representing the property path. it should be property names separated by periods:
 * - example 1: `'path.to.objectProperty'`
 * - example 2: `'prop'`
 *    this still works because the `split()` method will still return an array with the full string if there is no split
 *    see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split
 * @param desc = determines the order of the sort.
 * - desc (when true): Z - A | asc (when false): A - Z
 * @param caseInsensitive = determine if the comparison should be case insensitive.
 * @param type = specify the `dataType` that the values are.
 *
 * @return {array} the given array object.
 *
 * @example:
 *  const list =  [
      { person: { name: 'c' } },
      { person: { name: 'a' } },
      { person: { name: 'd' } },
      { person: { name: 'b' } }
    ];

    deepSort(list, 'person.name');
 */
export const deepSort = function<T = any>(
  list: Array<T>,
  propertyString: string,
  desc: boolean = false,
  caseInsensitive: boolean = false,
  type?: 'string' | 'number' | 'date',
): T[] {
  list.sort((a, b) => {
    let x, y;
    let result = 0;

    const path = propertyString.split('.');
    while (path.length > 0) {
      const prop = path.shift();
      a = a[prop];
      b = b[prop];
    }

    if (type === 'string' && caseInsensitive) {
      x = (a || '').toString().toLowerCase();
      y = (b || '').toString().toLowerCase();
    } else if (type === 'date') {
      x = new Date((<any> a));
      y = new Date((<any> b));
    } else {
      x = a;
      y = b;
    }

    if (x < y) {
      result = 1;
    } else if (x > y) {
      result = -1;
    }
    return result * (desc ? 1 : -1);
  });
  return list;
};

export const aggCellRenderer = function(params) {
  return params.node.group ? params.valueFormatted : '';
};

export const customSumAggFunc = function(nodes) {
  return nodes && nodes.length ? nodes[0] : FINRA_GRID_NULL_VALUE;
};

export const customCellClassFunc = function(params) {
  return params.node.group ? 'lines' : '';
};

export const addFinraGridColumnId = function(
  columns: Array<object>,
  counter: { start: number } = { start: 0 }
) {
  for (let i = 0; i < columns.length; i++) {
    columns[i]['colId'] = null;

    if ('children' in columns[i]) {
      addFinraGridColumnId(columns[i]['children'], counter);
    } else if (
      !columns[i]['isDetailLink'] &&
      !columns[i]['rowGroup']
    ) {
      columns[i]['colId'] = 'column' + counter.start++;
    }
  }

  return columns;
};

export const isSupportedBrowser = function() {
  const agent = detect();
  const isNotSupported = supportedBrowsers.indexOf(agent.name) === -1;
  if (isNotSupported) {
    return false;
  }

  // No IE support
  if (agent.name === 'ie') {
    return false;
  }
  const isMobile = mobileOsList.indexOf(agent.os) !== -1;
  if (isMobile) {
    return false;
  }
  return true;
};

export function detect() {
  return parseUserAgent(navigator.userAgent);
}

export function detectOS(userAgentString) {
  const rules = getOperatingSystemRules();
  const detected = rules.filter(function(os) {
    return os.rule && os.rule.test(userAgentString);
  })[0];

  return detected ? detected.name : null;
}

export function parseUserAgent(userAgentString) {
  const browsers = getBrowserRules();
  if (!userAgentString) {
    return null;
  }

  let detected =
    browsers
      .map(function(browser) {
        const match = browser.rule.exec(userAgentString);
        let version = match && match[1].split(/[._]/).slice(0, 3);

        if (version && version.length < 3) {
          version = version.concat(version.length === 1 ? [0, 0] : [0]);
        }

        return (
          match && {
            name: browser.name,
            version: version.join('.')
          }
        );
      })
      .filter(Boolean)[0] || null;

  if (detected) {
    detected.os = detectOS(userAgentString);
  }

  if (userAgentRegex.test(userAgentString)) {
    detected = detected || {};
    detected.bot = true;
  }

  return detected;
}

export function getBrowserRules() {
  return buildRules([
    ['aol', /AOLShield\/([0-9\._]+)/],
    ['edge', /Edge\/([0-9\._]+)/],
    ['yandexbrowser', /YaBrowser\/([0-9\._]+)/],
    ['vivaldi', /Vivaldi\/([0-9\.]+)/],
    ['kakaotalk', /KAKAOTALK\s([0-9\.]+)/],
    ['samsung', /SamsungBrowser\/([0-9\.]+)/],
    ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
    ['phantomjs', /PhantomJS\/([0-9\.]+)(:?\s|$)/],
    ['crios', /CriOS\/([0-9\.]+)(:?\s|$)/],
    ['firefox', /Firefox\/([0-9\.]+)(?:;|\s|$)/],
    ['fxios', /FxiOS\/([0-9\.]+)/],
    ['opera', /Opera\/([0-9\.]+)(?:\s|$)/],
    ['opera', /OPR\/([0-9\.]+)(:?\s|$)$/],
    ['ie', /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko$/],
    ['ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
    ['ie', /MSIE\s(7\.0)/],
    ['bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/],
    ['android', /Android\s([0-9\.]+)/],
    ['ios', /Version\/([0-9\._]+).*Mobile.*Safari.*/],
    ['safari', /Version\/([0-9\._]+).*Safari/],
    ['facebook', /FBAV\/([0-9\.]+)/],
    ['instagram', /Instagram\s([0-9\.]+)/],
    ['ios-webview', /AppleWebKit\/([0-9\.]+).*Mobile/]
  ]);
}

export function getOperatingSystemRules() {
  return buildRules([
    ['iOS', /iP(hone|od|ad)/],
    ['Android OS', /Android/],
    ['BlackBerry OS', /BlackBerry|BB10/],
    ['Windows Mobile', /IEMobile/],
    ['Amazon OS', /Kindle/],
    ['Windows 3.11', /Win16/],
    ['Windows 95', /(Windows 95)|(Win95)|(Windows_95)/],
    ['Windows 98', /(Windows 98)|(Win98)/],
    ['Windows 2000', /(Windows NT 5.0)|(Windows 2000)/],
    ['Windows XP', /(Windows NT 5.1)|(Windows XP)/],
    ['Windows Server 2003', /(Windows NT 5.2)/],
    ['Windows Vista', /(Windows NT 6.0)/],
    ['Windows 7', /(Windows NT 6.1)/],
    ['Windows 8', /(Windows NT 6.2)/],
    ['Windows 8.1', /(Windows NT 6.3)/],
    ['Windows 10', /(Windows NT 10.0)/],
    ['Windows ME', /Windows ME/],
    ['Open BSD', /OpenBSD/],
    ['Sun OS', /SunOS/],
    ['Linux', /(Linux)|(X11)/],
    ['Mac OS', /(Mac_PowerPC)|(Macintosh)/],
    ['QNX', /QNX/],
    ['BeOS', /BeOS/],
    ['OS/2', /OS\/2/],
    [
      'Search Bot',
      /(nuhk)|(Googlebot)|(Yammybot)|(Openbot)|(Slurp)|(MSNBot)|(Ask Jeeves\/Teoma)|(ia_archiver)/
    ]
  ]);
}

export const capitalize = (str: string) => {
  if (!str) {
    return '';
  } else if (str.length < 2) {
    return str.toUpperCase();
  }
  const formatted = str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  return formatted;
};

export function buildRules(ruleTuples) {
  return ruleTuples.map(function(tuple) {
    return {
      name: tuple[0],
      rule: tuple[1]
    };
  });
}
// DDWA-2780 Remove * from firm summary group agg headers
export const groupEmptyCellRenderer = function(params) {
  return params.node.group ? '' : params.valueFormatted;
};

export const submissionTotalPercentCellRenderer = function(params) {
  // When there is no aggData use firmLateFilingPercent value from data
  if (!params.node.aggData) {
    params.value = params.data.firmLateFilingPercent;
  } else {
    // Calculate firmLateFilingPercent by dividing lateFilingCount by totalFilingCount
    params.value =
      (params.node.aggData.column1 * 100) / params.node.aggData.column0;
  }
  return params.value != null
    ? `${roundUptoTwoDecimal(params.value)}%`
    : FINRA_GRID_NULL_VALUE;
};

export interface PriorMonth {
  dateStr: string;
  date: Date;
}
export type PriorMonthsList = PriorMonth[];

export const getPrior12Months = (
  dateString: string,
  includeStartDateInList: boolean = false
): PriorMonthsList => {
  const dateArr = dateString.split('-');
  // dateString should be in this format: mm-dd-yyyy
  const createStartDate = () => {
    const date = new Date();

    let month, day, year;
    if (dateArr[0].length > 2) {
      // format must be yyyy-mm-dd
      month = parseInt(dateArr[1], 10) - 1;
      year = dateArr[0];
      day = dateArr[2];
    } else {
      // format must be mm-dd-yyyy
      month = parseInt(dateArr[0], 10) - 1;
      year = dateArr[2];
      day = dateArr[1];
    }

    date.setMonth(month);
    date.setDate(day);
    date.setFullYear(year);

    return date;
  };

  const dateList: PriorMonthsList = [];
  for (let i = includeStartDateInList ? 0 : 1; i < 12; i++) {
    const startDate = createStartDate();
    const priorDate = new Date(startDate.setMonth(startDate.getMonth() - i));

    const priorDateMonthNum = priorDate.getMonth() + 1;
    const priorDateMonth = priorDateMonthNum < 10 ? `0${priorDateMonthNum}` : priorDateMonthNum;
    const priorDateDateNum = priorDate.getDate();
    const priorDateDate = priorDateDateNum < 10 ? `0${priorDateDateNum}` : priorDateDateNum;
    const priorDateYear = priorDate.getFullYear();
    const newDateString =
      dateArr[0].length > 2
        ? `${priorDateYear}-${priorDateMonth}-${priorDateDate}`
        : `${priorDateMonth}-${priorDateDate}-${priorDateYear}`;
    dateList.push({ date: priorDate, dateStr: newDateString });
  }
  return dateList;
};

export interface PriorQuarter {
  dateObj: Date;
  dateString: string;
  formattedQuarter: string;
  formattedQuarterFull: string;
}
export type PriorQuartersList = PriorQuarter[];

export const getPrior12Quarters = (
  dateString: string
): PriorQuartersList => {
  // dateString should be in this format: mm-dd-yyyy
  const dateArr = dateString.split('-');

  const createStartDate = () => {
    const date = new Date();

    let month, day, year;
    if (dateArr[0].length > 2) {
      // format must be yyyy-mm-dd
      month = parseInt(dateArr[1], 10) - 1;
      year = dateArr[0];
      day = dateArr[2];
    } else {
      // format must be mm-dd-yyyy
      month = parseInt(dateArr[0], 10) - 1;
      year = dateArr[2];
      day = dateArr[1];
    }

    date.setMonth(month);
    date.setDate(day);
    date.setFullYear(year);

    return date;
  };

  const quartersList: PriorQuartersList = [];
  quartersList.push({
    dateObj: createStartDate(),
    dateString: dateString,
    formattedQuarter: quarterDateFormat(dateString),
    formattedQuarterFull: quarterDateFormatFullText(dateString)
  });
  for (let i = 1; i < 12; i++) {
    const startDate = createStartDate();
    const priorDate = new Date(
      startDate.setMonth(startDate.getMonth() - 3 * i)
    );

    const priorDateMonthNum = priorDate.getMonth() + 1;
    const priorDateMonth = priorDateMonthNum < 10 ? `0${priorDateMonthNum}` : priorDateMonthNum;
    const priorDateDateNum = priorDate.getDate();
    const priorDateDate = priorDateDateNum < 10 ? `0${priorDateDateNum}` : priorDateDateNum;
    const priorDateYear = priorDate.getFullYear();
    const newDateString =
      dateArr[0].length > 2
        ? `${priorDateYear}-${priorDateMonth}-${priorDateDate}`
        : `${priorDateMonth}-${priorDateDate}-${priorDateYear}`;
    quartersList.push({
      dateObj: priorDate,
      dateString: newDateString,
      formattedQuarter: quarterDateFormat(newDateString),
      formattedQuarterFull: quarterDateFormatFullText(newDateString)
    });
  }
  return quartersList;
};

/**
 * This function simply takes a string and does the following:
 * - make it lowercase.
 * - replace all spaces with a dash.
 * - returns the new modified string
 * @example:
 * - 1. `'Corp Fin'` --> `'corp-fin'`
 * - 2. `'Risk Monitoring'` --> `'risk-monitoring'`
 * - 3. `'Trace'` --> `'trace'`
 * @param str
 * @returns {string}
 */
export function makeStringUrlPath(str: string): string {
  return str.toLowerCase().replace(/\s/g, '-');
}

/**
 * Sort Distinct
 * ---
 *
 * Filter/Sort an array of objects by a given key.
 * This function returns an object with a property for every
 * distinct value by the given key in the array of objects.
 * Each property will be an array of all the objects with the same
 * value by the given key.
 *
 * @example:
 * - let list = [
 *    { brand: 'a' },
 *    { brand: 'b' },
 *    { brand: 'c' },
 *   ];
 *
 * let sorted_brands = sort_distinct(list, 'brand');
 *
 * output:
 * {
 *  'a': [{brand: 'a'}],
 *  'b': [{brand: 'b'}],
 *  'c': [{brand: 'c'}],
 * }
 *
 * @param {array} = array of objects
 * @param {key} = the key to sort by
 * @param {includeStray} = boolean indicating if stray objects should be kept
 *
 * @returns {object} = object tree of all the distinct values by the given key
 */
export function sort_distinct(
  array: object[],
  key: string,
  includeStray?: boolean,
  includeStrayPropName?: string // in case another prop name is desire instead of the default
): { _stray?: any[], [key: string]: any[] } {
  if (!array || Array.isArray(array) === false) {
    throw new Error('First parameter must be an array.');
  }
  if (!key || key.constructor !== String) {
    throw new Error('Second parameter must be a string.');
  }

  const obj = {};
  const strayPropName = includeStray && includeStrayPropName || '_stray';
  if (includeStray) {
    obj[strayPropName] = []; // a list for objects that don't have the given key.
  }

  array.forEach(function(item) {
    const value = item[key];
    const isValueValid = value !== undefined && value !== null;
    if (isValueValid) {
      if (obj[value] === undefined) {
        obj[value] = [item];
      } else {
        obj[value].push(item);
      }
    } else {
      if (includeStray) {
        obj[strayPropName].push(item);
      }
    }
  });

  return obj;
}

/* Method to convert the date string from yyyy-mm-dd to mm-dd-yyyy */
export function getCompatibleDate(date) {
  const dateArr = date.split('-');
  return `${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`;
}

/**
 * Check If Object Is Empty
 * ---
 * This method will check all of the properties on `data`.
 * If EVERY property is either `null` or `undefined`, it is considered empty/invalid.
 *
 * @param data: the object that will be checked
 * @param ignorePropList: the list of properties to ignore
 * @return {boolean}: boolean indicating if the object is empty
 * - returning `true` means the object is valid; at least 1 property has value
 * - returning `false` means the object is NOT valid; no property has value
 */
export function checkObjectEmpty(
  data: object,
  ignorePropList?: string[]
): boolean {
  const dataCopy = { ...data };
  if (ignorePropList && !!ignorePropList.length) {
    for (const prop of ignorePropList) {
      delete dataCopy[prop];
    }
  }

  const keys = Object.keys(dataCopy);
  const checkIfPropertyHasValue = (key) => {
    const value = dataCopy[key];
    const isValidValue = !finraIsInvalidValue(value);
    return isValidValue;
  };
  const onePropHasValidValue = keys.some(checkIfPropertyHasValue);
  return onePropHasValidValue;
}

export function makeFileNameSafe(fileName: string, makeLower: boolean = false): string {
  const badCharacters = /[^a-z0-9\-]/gi;
  const formatted = fileName.replace(/\s/gi, '-').replace(badCharacters, '_');
  return makeLower ? formatted.toLowerCase() : formatted;
}

export function makeHtmlContent(str: string): string {
  const p = window.document.createElement('p');
  p.innerHTML = str;
  return p.innerHTML;
}

/** Export CSV Helper Methods */

// this is to allow multiple level of columns.
// typically only 2 dimensions are needed.
export type MultiDimensionalArray =
  string[] |
  string[][] |
  string[][][] |
  string[][][][];

export interface SummaryExportRowData {
  dataMappings: (string | { value?: string, prop?: string, formatter?: (arg: any) => any })[];
  rowData: any[];
}

export interface SummaryExportData extends SummaryExportRowData {
  title: string;
  columnLabels: MultiDimensionalArray;
}

export function buildColumnLabelsString(columnLabelsList: MultiDimensionalArray | any[]): string {
  let massColumnString = '';

  for (const columnLabels of columnLabelsList) {
    if (Array.isArray(columnLabels)) {
      massColumnString += buildColumnLabelsString(columnLabels);
    } else {
      massColumnString += `"${columnLabels || ''}",`;
    }
  }

  massColumnString = massColumnString.substring(
    0,
    massColumnString.length - 1
  );
  massColumnString += '\n';

  return massColumnString;
}

export function buildRowDataString(exportData: SummaryExportRowData, indentAmount: number = 0): string {
  let massDataString = '';
  let indentString = '';

  const validDataValue = (value) => {
    return value !== null && value !== undefined;
  };

  if (indentAmount > 0) {
    const stringList = [];
    for (let i = 0; i < indentAmount; i++) {
      stringList.push(`"",`);
    }
    indentString = stringList.join('');
  }

  for (const data of exportData.rowData) {
    let dataRowString = '';

    for (const mapping of exportData.dataMappings) {
      // if it is a property path, split it and follow through
      // example: 'name.first'
      let value;

      if (typeof(mapping) === 'object') {
        // if object, this is a special kind of mapping
        if (mapping.value) {
          value = mapping.value;
        } else if (mapping.prop) {
          value = mapping.formatter
            ? mapping.formatter(data[mapping.prop])
            : data[mapping.prop];
        }
      } else if (mapping.indexOf('.') > 0) {
        const path = mapping.split('.');
        value = data;
        while (path.length > 0) {
          const prop = path.shift();
          value = value && value[prop];
        }
      } else {
        value = data[mapping];
      }

      dataRowString += `${indentString}"${validDataValue(value) ? value : ''}",`;
    }

    // chop of trailing comma then add to massString
    dataRowString = dataRowString.substring(0, dataRowString.length - 1);
    massDataString += `${dataRowString}\n`;
  }

  return massDataString;
}

export function buildExportDataString(exportList: SummaryExportData[]): string {
  // create an empty string. all of export data will be amassed here.
  let massExportString = '';

  // loop through each export data.
  for (const exportData of exportList) {
    // if there is a table title, add it.
    if (exportData.title) {
      massExportString += `${exportData.title}\n`;
    }

    const headingsRowString = buildColumnLabelsString(exportData.columnLabels);
    massExportString += headingsRowString;

    const dataString = buildRowDataString(exportData);
    massExportString += dataString;

    massExportString += '\n';
  }

  const csvFormatted = trimTrailingChar(massExportString, '\n');
  return csvFormatted;
}

export function getCsvExportData(exportList: SummaryExportData[], fileName?: string) {
  const csvString = buildExportDataString(exportList);
  saveCsv(csvString, fileName);
}

export function makeSummaryExportFileName(
  prefix,
  period,
  makeLower: boolean = false
) {
  const date = makeFileNameSafe(getMonthYrDate(period));
  const safeFileName = makeFileNameSafe(prefix);
  const useFileName = `${safeFileName}-${date}-Summary-Export-${Date.now()}`;
  return makeLower ? useFileName.toLowerCase() : useFileName;
}

/*

DEPRECATED
---
*/
export function makeFileNameFromReport(
  report: ReportInstanceMetadata,
  viewName: string = null,
  periodFormatter: (arg: any) => string = getMonthYrDate,
  makeLower: boolean = false,
  ext?: FileExtensions
) {
  const firmId = report.reportFirmId;
  const reportId = report.reportId;
  const version = report.reportDataVersion;
  const publishState = report.reportStateLookup.reportStateDescription;
  const useViewName = viewName ? `-view-${viewName}` : '';
  const date = makeFileNameSafe(periodFormatter(report.reportPeriodDate));
  const safeReportDisplayName = makeFileNameSafe(report.reportConfiguration.reportDisplayName);
  const prefix = `${firmId}-${safeReportDisplayName}`;
  const additional = `report-id-${reportId}-version-${version}${useViewName}-${publishState}`;
  const dateNow = Date.now();
  const fileName = `${prefix}-${additional}-${date}-Summary-Export-${dateNow}`;
  const formattedFileName = makeLower ? fileName.toLowerCase() : fileName;

  const useFileName = ext ? appendSuffix(formattedFileName, `.${ext}`) : formattedFileName;

  return useFileName;
}


export function generateFileName(params: {
  dataType: 'Summary' | 'Detail' | 'PeerGroup'
  reportDisplayName: string,
  firmId: string,
  viewName: string,
  version: number | string,
  period: string,
  periodicity: 'Monthly' | 'Quarterly' | 'Daily',
  ext: FileExtensions
}) {
  // destruct the params object.
  const { dataType, reportDisplayName, firmId, viewName, period, version, periodicity, ext } = params;
  const reportName = makeFileNameSafe(reportDisplayName);
  // format taken from - https://wiki.finra.org/display/DDWA/Detail+Dataset+Download+Enhancement
  const useFileName = `${dataType}_${reportName}_${firmId}_${period}_${periodicity}_${viewName}_${version}.${ext}`;
  return useFileName;
}

export function trimTrailingChar(string: string, char: string) {
  let str = string;
  while (str.endsWith(char)) {
    str = str.slice(0, -1);
  }
  return str;
}

export function getMainNavItems(userProfile: { accessType: string }) {
  const mainNavItems = sideMenus.filter((navItem) => {
    const hasAccess =
      navItem.accessTypes === null ||
      navItem.accessTypes.indexOf(userProfile.accessType) !== -1;

    return hasAccess;
  });
  return mainNavItems;
}

/**
 * Multi Deep Sort
 * ---
 *
 * Sorts a list.
 * The comparator recursively sorts based on a list of sort definitions.
 * The order of the sort definitions matter; it is what gets compared first, next, etc.
 *
 * @example
 * multiSort(
    [
      { name: 'def', age: new Date('2015-05-18') },
      { name: 'xyz', age: new Date('2019-05-18') },
      { name: 'def', age: new Date('2013-05-18') },
      { name: 'abc', age: new Date('2021-05-18') },
      { name: 'def', age: new Date('2020-05-18') },
      { name: 'abc', age: new Date('2018-05-18') },
    ],
    [
      { prop: 'name', type: 'string', desc: false, caseInsensitive: true },
      { prop: 'age', type: 'date', desc: true },
    ]
  );

  // returns
  [
    0: {name: "abc", age: Mon May 17 2021 20:00:00 GMT-0400 (Eastern Daylight Time)}
    1: {name: "abc", age: Thu May 17 2018 20:00:00 GMT-0400 (Eastern Daylight Time)}
    2: {name: "def", age: Sun May 17 2020 20:00:00 GMT-0400 (Eastern Daylight Time)}
    3: {name: "def", age: Sun May 17 2015 20:00:00 GMT-0400 (Eastern Daylight Time)}
    4: {name: "def", age: Fri May 17 2013 20:00:00 GMT-0400 (Eastern Daylight Time)}
    5: {name: "xyz", age: Fri May 17 2019 20:00:00 GMT-0400 (Eastern Daylight Time)}
  ]
 *
 * @param list The list to sort
 * @param sortDefinitions the list of sort definitions
 * @returns {Array}
 */
export const multiDeepSort = (
  list: any[],
  sortDefinitions: Array<{
    prop: string;
    desc?: boolean,
    caseInsensitive?: boolean,
    type?: 'string' | 'number' | 'date',
  }>
) => {
  // the comparator function
  const compareFn = (a, b, sortDefinitionsCopy) => {
    let sortDefinition = sortDefinitionsCopy.shift(); // get the sort definition
    let { prop, desc, caseInsensitive, type } = sortDefinition;
    let x, y; // initialize compare variables
    let result = 0;

    const path = prop.split('.'); // in case the property is a path like so: name.first or etc
    let tempA = a;
    let tempB = b;
    while (path.length > 0) {
      const prop = path.shift();
      tempA = tempA[prop];
      tempB = tempB[prop];
    }

    // set compare variables based in sort definition's type
    if (type === 'string' && caseInsensitive) {
      x = (tempA || '').toString().toLowerCase();
      y = (tempB || '').toString().toLowerCase();
    } else if (type === 'date') {
      x = new Date(tempA);
      y = new Date(tempB);
    } else {
      x = tempA;
      y = tempB;
    }

    if (x < y) {
      result = 1;
    } else if (x > y) {
      result = -1;
    }
    const value = result * (desc ? 1 : -1);
    const doRecursive = value == 0 && sortDefinitionsCopy.length;
    // if both items are equal, sort based on the next sort definition in the list
    // recursively call until either result is not 0 or sort definition list is empty
    if (doRecursive) {
      debugger;
      const recursiveValue = compareFn(a, b, sortDefinitionsCopy);
      debugger;
      return recursiveValue;
    }
    debugger;
    return value;
  };

  // the sort callback
  const sortFn = (a: any, b: any) => {
    // pass a copy of the sort definitions for each compare operation
    let sortDefinitionsCopy = [...sortDefinitions];
    const result = compareFn(a, b, sortDefinitionsCopy);
    return result;
  };

  // apply sort
  list.sort(sortFn);

  // return list for optional method chaining by caller
  return list;
};

export const getFipCookie = () => {
  switch (window.location.origin) {
    // dev
    case `https://ews-assets-int.dev.finra.org`:
    case `https://internalreportcenter.dev.finra.org`:
    case `https://isso-assets-int.dev.finra.org`: {
      return Cookies.get(`__fipid-devint`);
    }

    // qa
    case `https://newreportcenter.qa.finra.org`:
    case `https://internalreportcenter.qa.finra.org`:
    case `https://isso-assets-int.qa.finra.org`: {
      return Cookies.get(`__fipid-qaint`);
    }

    // prod
    case `https://newreportcenter.finra.org`:
    case `https://isso-assets.finra.org`: {
      return Cookies.get(`__fipid`);
    }
  }
};

export const isWholeNumber = (value) => {
  return (parseFloat(value) % 1 === 0);
};

/**
 * Hide labels that are not whole numbers by returning empty string
 * @param value axis value
 * @returns {String}
 */
export const yAxisTickFormattingHideDecimals = (value) => {
  return isWholeNumber(value) ? value : '';
};

export function wait (ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function createDate(dt: string | number | Date) {
  if (typeof dt === 'string' && dt.length > 0 && dt.indexOf('T') === -1) {
    // expecting dt to be: yyyy-mm-dd
    dt = dt + 'T00:00:00';
  }

  // console.log('createDate', dt);
  return new Date(dt || '');
}

export function makeDateStartOfDay(dt: string | number | Date) {
  const date = createDate(dt);
  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  return date;
}

export function createMapFromObjectProps(params: {
  list: any[],
  keyProp: string,
  valueProp: string
}) {
  const map: any = {};
  for (const data of params.list) {
    map[data[params.keyProp]] = data[params.valueProp];
  }
  return map;
}
