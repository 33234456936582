<div [class.banner-hidden]="!showBanner">
  <div class="banner-overlay" [innerHtml]="bannerMessage"></div>
</div>
<div class="report-center-main-container" *ngIf="appLoaded">
  <mat-sidenav-container class="report-sidenav-container" autosize>
    <mat-sidenav
      class="report-sidenav no-print"
      #sidenav
      mode="side"
      opened
      fixedInViewport
    >
      <report-sidenav
        *ngIf="sideNavItems"
        [navItems]="sideNavItems"
        [userAccessType]="userAccessType"
      ></report-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <report-nav></report-nav>

      <!-- DDWA-4966: Error message for unauthorized FINRA users -->
      <report-error-message
        id="isso-error-message"
        *ngIf="issoUserIsNotAuthorized"
        [errorMessage]="issoUnauthorizedMessage"
      ></report-error-message>

      <router-outlet></router-outlet>

      <div class="footer pdf">
        © {{ currentYear }} FINRA. All rights reserved. FINRA is a registered
        trademark of the Financial Industry Regulatory Authority, Inc.
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
