import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  UsageLogSearch,
  AuditActionLookup,
  UsageLog,
  UsageLogGridHeader,
  UsageLogExtended,
  extendUsageLogData
} from 'src/app/configs/model/usage-log.model';
import {
  buildExportDataString,
  makeHtmlContent,
  makeFileNameSafe,
  saveCsv,
  DATE_PIPE
} from '../shared/utils/utils';
import { UiWidgetsService } from './ui-widgets.service';
import { UsageLogExportRequest } from '../configs/model/home.model';

@Injectable({
  providedIn: 'root'
})
export class UsageLogService {
  private baseUrl: string;

  constructor(
    @Inject('environment') environment,
    private http: HttpClient,
    private uiWidgetsService: UiWidgetsService
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  public recordLogin(): Observable<any> {
    return this.http.post(`${this.baseUrl}auditActions/auditActionType/LOGIN_ACTION`, {});
  }

  public recordLogout(): Observable<any> {
    return this.http.post(`${this.baseUrl}auditActions/auditActionType/LOGOFF_ACTION`, {});
  }

  public getAuditActionLookups(): Observable<AuditActionLookup[]> {
    return this.http.get(`${this.baseUrl}auditActionLookups/userViewableAuditActions`)
      .pipe(
        map(res => {
          return res['auditActionLookups'];
        })
      );
  }

  searchUsageLogs(searchParams: UsageLogSearch): Observable<UsageLogExtended[]> {
    if (searchParams.startDate) {
      searchParams.startDate += 'T00:00:00';
    }
    if (searchParams.endDate) {
      searchParams.endDate += 'T23:59:59';
    }

    return this.http.post(`${this.baseUrl}auditActions/searchAuditAction`, searchParams)
      .pipe(
        map(res => {
          return res == null ? [] : res['auditActions'].map(extendUsageLogData);
        })
      );
  }

  exportUsageLogData(rowData: UsageLogExtended[]) {
    const exportDataString = buildExportDataString([
      {
        title: 'Usage Log',
        columnLabels: UsageLogGridHeader.map((i: any) => i.headerName),
        rowData: rowData,
        dataMappings: [
          { prop: 'auditActionTimestamp', formatter: (str: string) => DATE_PIPE.transform(str, 'MM/dd/yyyy') },
          { prop: 'auditActionTimestamp', formatter: (str: string) => DATE_PIPE.transform(str, 'hh:mm a') },
          'httpUserOrgId',
          'reportCenterUser.userName',
          'auditActionLookup.auditActionDescription',
          // { prop: 'auditActionDetail', formatter: makeHtmlContent },
          // new columns
          'reportInfo.reportName',
          'reportInfo.firmId',
          'reportInfo.traceMpid',
          'reportInfo.msrbId',
          'reportInfo.period',
          'reportInfo.version',
          'reportInfo.view',
        ]
      }
    ]);
    const currentDateTime = new Date();
    const date = makeFileNameSafe(currentDateTime.toDateString());
    const time = makeFileNameSafe(currentDateTime.toLocaleTimeString());
    const useFileName = `Usage-Log-Export-${date}-${time}-${Date.now()}`;
    saveCsv(exportDataString, useFileName);
  }
}
