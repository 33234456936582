<div id="canceled-as-of-trades-container">
  <!-- All trades -->
  <report-trades-grid
    class="pdf"
    [isAllTrades]="true"
    [title]="'All Trades'"
    [summaryData]="allTradesMonthly"
    [reportInstanceMetadata]="reportInstanceMetadata"
  ></report-trades-grid>

  <!-- As-Of trades -->
  <report-trades-grid
    class="pdf"
    [isAllTrades]="false"
    [title]="'As-Of Trades'"
    [summaryData]="asOfTradesMonthly"
    [reportInstanceMetadata]="reportInstanceMetadata"
  ></report-trades-grid>

  <!-- On or Before Settlements trades -->
  <report-trades-grid
    class="pdf"
    [isAllTrades]="false"
    [title]="'Trade Cancels on or before Settlement'"
    [summaryData]="beforeSettlementTradesMonthly"
    [reportInstanceMetadata]="reportInstanceMetadata"
  ></report-trades-grid>

  <!-- After Settlements trades -->
  <report-trades-grid
    class="pdf"
    [isAllTrades]="false"
    [title]="'Trade Cancels after Settlement'"
    [summaryData]="afterSettlementTradesMonthly"
    [reportInstanceMetadata]="reportInstanceMetadata"
  ></report-trades-grid>
</div>