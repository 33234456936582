import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  IconDefinition,
  faStepBackward,
  faCaretLeft,
  faStepForward,
  faCaretRight,
  faCalendarAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {
  IconDefinition as IconDefinitionLight,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faLock, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe, Location } from '@angular/common';
import { of, forkJoin, combineLatest } from 'rxjs';
import {
  MSRB_REPORT_CATEGORY_ID,
  msrbNumberReportsByTypeId
} from 'src/app/configs/model/msrb/msrb.model';
import { catchError, map, take } from 'rxjs/operators';
import { FirmInfo } from 'src/app/configs/model/firm-info.model';
import { ReportCategoriesResponse, ReportCategory, ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickEventViewReportFromExplorerInfo } from 'src/app/interfaces/beast.interface';
import { AdminService } from 'src/app/services/admin.service';
import { BeastService } from 'src/app/services/beast.service';
import { ReportService } from 'src/app/services/report.service';
import { ReportsService } from 'src/app/services/reports.service';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';
import { ArrayPaginator, ArrayPaginatorState } from 'src/app/shared/array-pagination';
import { deepSort, createDateFromPeriod, sort_distinct } from 'src/app/shared/utils/utils';
import { explorerFilterInfoModalMessage, ReportExplorerPeriod, ReportTypeEdition } from 'src/app/configs/model/admin.model';

@Component({
  selector: 'report-admin-report-explorer',
  templateUrl: './report-explorer.component.html',
  styleUrls: ['./report-explorer.component.scss']
})
export class AdminReportExplorerComponent implements AfterViewInit {
  @ViewChild('explorerContainer', { static: false }) explorerContainer: ElementRef;
  title: string = 'Report Explorer Tool';
  errorMessage: string;
  currentSort: string;
  currentSortDirection: boolean = false;
  faLockIcon: IconDefinition = faLock;
  faFilterIcon: IconDefinition = faFilter;
  faCalendarAlt: IconDefinition = faCalendarAlt;
  // prev
  faStepBackward: IconDefinition = faStepBackward;
  faCaretLeft: IconDefinition = faCaretLeft;
  // next
  faStepForward: IconDefinition = faStepForward;
  faCaretRight: IconDefinition = faCaretRight;
  faQuestionCircle: IconDefinitionLight = faQuestionCircle;
  reportCategories = [];
  reportTypesList: ReportTypeEdition[] = [];
  reportTypesListFiltered: ReportTypeEdition[] = [];
  periodsList = [];
  firmsList: FirmInfo[];
  firmsListFiltered: FirmInfo[] = [];
  pageNumbers: Array<number> = [];
  isMsrbReportAndIsMpidBased: boolean;
  isDailyReport: boolean;
  isCrdReport: boolean = false; // determine if mpid column should show
  paginator: ArrayPaginator;
  matDatepickerFilter: (date: Date) => boolean;
  searchFirmByReportTypeForm: FormGroup;
  filterForm: FormGroup;
  reportTab: Window;
  currentCategoryId: number;
  currentReportTypeId: number;
  isMsrbBasedReport: boolean;

  crdReportByReportCategory: number[] = [
    10, // corp fin
    8, // cross market supervision
    2, // disclosure
    5, // online learning
    12, // options
    6, // risk monitoring
  ];
  crdReportByReportConfigId: number[] = [
    250, // Equity - Contra Reporting Firm 20 Minute Compliance Report Card
    249, // Equity - Contra Executing Firm 20 Minute Compliance Report Card
    247, // Equity - Reg NMS Trade Through Report Card
  ];

  get paginatorState(): ArrayPaginatorState {
    return this.paginator && this.paginator.getState() || (<ArrayPaginatorState> {});
  }

  get firmIdColumnName(): string {
    let columnName: string;
    const isMpidBasedReport = this.isMsrbReportAndIsMpidBased || !this.isCrdReport;
    if (isMpidBasedReport) {
      // check if is MSRB Number based report.
      // if so, then the firm id would be an MSRB Number
      columnName = this.isMsrbBasedReport
        ? 'MSRB Number'
        : 'MPID';
    }
    return columnName;
  }

  constructor(
    private adminService: AdminService,
    private uiWidgetsService: UiWidgetsService,
    private reportsService: ReportsService,
    private reportService: ReportService,
    private titleService: Title,
    private datePipe: DatePipe,
    private beastService: BeastService,
    private location: Location,
  ) {
    this.titleService.setTitle(`Admin - Report Explorer`);
    this.matDatepickerFilter = (d: Date): boolean => {
      // we need this date list, which comes from this.reportInstanceMetadatas.
      // thankfully, closures/scopes in JS is magic.
      const dateList = this.getDateList();
      const day = d.getDay();
      const dateString = this.datePipe.transform(d, 'yyyy-MM-dd');
      const isNotWeekend = day !== 0 && day !== 6;
      const isInList = dateList.indexOf(dateString) !== -1;
      const isValidDateOption = isNotWeekend && isInList;
      return isValidDateOption;
    };
    this.searchFirmByReportTypeForm = new FormGroup({
      categoryId: new FormControl({ value: null, disabled: false }, []),
      reportType: new FormControl({ value: null, disabled: true }, []),
      reportPeriod: new FormControl({ value: null, disabled: true }, []),
      datepickerperiod: new FormControl({ value: null, disabled: true }, []),
    });
    this.filterForm = new FormGroup({
      firmName: new FormControl('', []),
      crdid: new FormControl('', []),
      mpId: new FormControl('', []),
      msrbNumber: new FormControl('', []),
    });

    this.getSearchInfo().subscribe(
      (values: [ReportCategoriesResponse, ReportTypeEdition[]]) => {
        // this does not get called on page refresh when called inside the ngOnInit for some odd reason
        this.handleSearchInfo(values);
        // start the listeners for value changes
        this.startValueChangeListeners();
      }
    );
  }

  ngAfterViewInit() {
  }

  getDateList(): string[] {
    const list = this.periodsList.map(p => p.rptPrdDt);
    return list;
  }

  onDateInput(type: string, event: MatDatepickerInputEvent<Date>) {
    if (!event.value) {
      return;
    }
    const dateFormat = 'yyyy-MM-dd';
    const dateString = this.datePipe.transform(event.value, dateFormat);
    const period = this.periodsList.find((p) => {
      const dateStr = this.datePipe.transform(p.rptPrdDt, dateFormat);
      const match = dateStr === dateString;
      return match;
    });
    if (!period) {
      // 'no report period found by selected date input'
      return;
    }
    this.searchFirmByReportTypeForm.get('reportPeriod').setValue(period);
  }

  getSearchInfo() {
    // get search data for the form
    const requestsObs = [
      this.reportsService.getReportCategories(),
      this.adminService.getAdminReportTypesEditions()
    ];

    return combineLatest(requestsObs).pipe(
      take(1),
      map((values) => {
        return values;
      }),
      catchError((error: HttpErrorResponse) => {
        this.uiWidgetsService.showErrorSnackbar('Could not load search criteria data...');
        throw error;
      })
    );
  }

  handleSearchInfo(values: [ReportCategoriesResponse, ReportTypeEdition[]]) {
    if (!values) {
      return;
    }
    deepSort(values[0].reportCategories, 'categoryDisplayName', false, true);
    this.reportCategories = values[0].reportCategories
      .filter((r: ReportCategory) => {
        const match = r.reportCategoryId !== 13 && r.userAccessible;
        return match;
      });
    this.reportTypesList = values[1];
  }

  startValueChangeListeners() {
    this.searchFirmByReportTypeForm.get('categoryId').valueChanges.subscribe(
      (categoryId: number) => {
        this.handleCategoryIdChange(categoryId);
      }
    );
    this.searchFirmByReportTypeForm.get('reportType').valueChanges.subscribe(
      (reportType: ReportTypeEdition) => {
        this.handleReportTypeChange(reportType);
      }
    );
    this.searchFirmByReportTypeForm.get('reportPeriod').valueChanges.subscribe(
      (reportPeriod: ReportExplorerPeriod) => {
        this.handleReportPeriodChange(reportPeriod);
      }
    );
    this.filterForm.valueChanges.subscribe(
      (event: FirmInfo) => {
        this.handleFilterFormChanges(event);
      }
    );
  }

  handleCategoryIdChange(categoryId: number) {
    this.setReportTypesByCategoryId(categoryId);
    this.searchFirmByReportTypeForm.get('reportType').setValue(null);
    this.searchFirmByReportTypeForm.get('reportType').enable({ emitEvent: false });
    this.searchFirmByReportTypeForm.get('reportPeriod').setValue(null);
    this.searchFirmByReportTypeForm.get('reportPeriod').disable();
    this.filterForm.reset();
  }

  handleReportTypeChange(reportType: ReportTypeEdition) {
    if (!reportType) {
      return;
    }
    this.searchFirmByReportTypeForm.get('reportPeriod').enable({ emitEvent: false });

    /** DDWA-6048: get all the periods from each report config by report type */
    const reportTypesByTypeId = this.reportTypesList.filter(r => r.rptTypeId === reportType.rptTypeId);
    const dataObservablesList = reportTypesByTypeId.map((r: ReportTypeEdition) => {
      return this.fetchPeriodsAndVerionsByReportConfigId(r.rptCfgId);
    });
    forkJoin(dataObservablesList).subscribe({
      next: (values) => {
        const periods = values.reduce((acc, cur) => acc.concat(cur));
        deepSort(periods, 'rptPrdDt', true, false, 'date'); // Sort by Date DESC
        this.handlePeriodsAndVerionsByReportProblemId(periods);
      },
      error: (error) => {
        if (error.status === 404) {
          this.errorMessage = 'No firms found...';
        } else {
          this.errorMessage = 'Could not load data...';
        }
      }
    });
  }

  handleReportPeriodChange(reportPeriod) {
    if (reportPeriod) {
      this.getAndHandleFirmsByReportTypeAndPeriod();
      this.filterForm.reset();
    }
  }

  handleFilterFormChanges(event: FirmInfo) {
    if (!this.firmsList) {
      return;
    }
    const crdid = (event.crdid || '').toString();
    const form_mpId = (event.mpId || '').toLowerCase();
    const firmName = (event.firmName || '').toLowerCase();
    const firmMsrbNumber = (event.msrbNumber || '').toLowerCase();
    const filtered = this.firmsList.filter((firm: FirmInfo) => {
      /** If the filter ends with a dot, match value exactly. otherwise, match by includes */
      const crd = (firm.crdid || '').toString().toLowerCase();
      const mpid = (firm.mpId || '').toString().toLowerCase();
      const msrbNumber = (firm.msrbNumber || '').toString().toLowerCase();
      const name = (firm.firmName || '').toString().toLowerCase();

      const matchCrdid = crdid.endsWith('.')
        ? crd === crdid.slice(0, -1)
        : crd.includes(crdid);
      const matchMpId = form_mpId.endsWith('.')
        ? mpid === form_mpId.slice(0, -1)
        : mpid.includes(form_mpId);
      const matchMsrbNumber = firmMsrbNumber.endsWith('.')
        ? msrbNumber === firmMsrbNumber.slice(0, -1)
        : msrbNumber.includes(firmMsrbNumber);
      const matchFirmName = firmName.endsWith('.')
        ? name === firmName.slice(0, -1)
        : name.includes(firmName);

      const match = (matchCrdid && matchMpId && matchMsrbNumber && matchFirmName);
      return match;
    });
    this.setPaginator(filtered);
    this.setSort('firmName');
  }

  handlePeriodsAndVerionsByReportProblemId(response: ReportExplorerPeriod[]) {
    // determine the order of the response; dates should be in descending order.
    // start by checking the list length
    if (!response || !response.length) {
      this.errorMessage = 'No periods found...';
      this.searchFirmByReportTypeForm.get('reportPeriod').setValue(null);
      this.searchFirmByReportTypeForm.get('datepickerperiod').setValue(null);
      return;
    }
    this.errorMessage = null;
    this.periodsList = response;
    const firstPeriod = this.periodsList[0];
    const firstPeriodDate = createDateFromPeriod(firstPeriod.rptPrdDt);
    this.searchFirmByReportTypeForm.get('reportPeriod').setValue(firstPeriod);
    this.searchFirmByReportTypeForm.get('datepickerperiod').setValue(firstPeriodDate);
    this.filterForm.reset();
  }

  getAndHandleFirmsByReportTypeAndPeriod() {
    const categoryId: number = this.searchFirmByReportTypeForm.get('categoryId').value;
    const typeId: number = this.searchFirmByReportTypeForm.get('reportType').value.rptTypeId;
    const period: string = this.searchFirmByReportTypeForm.get('reportPeriod').value.rptPrdDt;
    this.adminService.getFirmsByReportTypeAndPeriod(typeId, period).subscribe(
      (response: FirmInfo[]) => {
        this.handleFirmsByReportTypeAndPeriod(response, categoryId, typeId);
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.errorMessage = 'No firms found...';
        } else {
          this.errorMessage = 'Could not load data...';
        }
      }
    );
  }

  handleFirmsByReportTypeAndPeriod(
    response: FirmInfo[],
    reportCategoryId: number,
    reportTypeId: number
  ) {
    /**
     * https://jira.finra.org/browse/DDWA-7322, https://jira.finra.org/browse/DDWA-7323
     *
     * Some equity reports have changed firm identifier from using MPID to CRD.
     * The change has been reflected as a new report edition (report configuration).
     * In order to handle the equity reports that have changed firm identifier from old and new editions,
     * the code has to know what report config was selected and the format of the firm info:
     * if the firmId is in the mpid format, it must be referring to the old edition; show the mpid column, otherwise hide it.
     */
    this.errorMessage = null;
    this.currentCategoryId = reportCategoryId;
    this.currentReportTypeId = reportTypeId;
    const isMsrbCategory = reportCategoryId === MSRB_REPORT_CATEGORY_ID;
    const isMsrbBasedReport = msrbNumberReportsByTypeId.includes(reportTypeId);
    this.isMsrbBasedReport = isMsrbBasedReport;
    this.isMsrbReportAndIsMpidBased = isMsrbCategory && !isMsrbBasedReport;
    this.isDailyReport = reportCategoryId === 12;
    const formattedResponse = response;
    this.firmsList = formattedResponse;
    const firmId = response.length && response[0].firmId;

    const reportType = this.reportTypesListFiltered.find(r => r.rptTypeId === reportTypeId);
    const reportConfigId = reportType && reportType.rptCfgId;
    this.isCrdReport = this.crdReportByReportCategory.includes(reportCategoryId);


    this.setPaginator(this.firmsList);
    this.currentSort = '';
    this.currentSortDirection = false;
    this.setSort('firmName');
  }

  setSort(prop: string) {
    const list = this.paginator.getList();
    const direction = this.currentSort === prop
      ? !this.currentSortDirection
      : false;
    switch (prop) {
      case 'crdid': {
        deepSort(list, prop, direction);
        this.setPaginator(list);
        this.currentSort = prop;
        this.currentSortDirection = !this.currentSortDirection;
        return;
      }
      case 'msrbNumber':
      case 'firmId':
      case 'firmName': {
        deepSort(list, prop, direction, true, 'string');
        this.setPaginator(list);
        this.currentSort = prop;
        this.currentSortDirection = !this.currentSortDirection;
        return;
      }
      default: {

      }
    }
  }

  setPaginator(list: FirmInfo[]) {
    this.paginator = new ArrayPaginator(list);
    this.firmsListFiltered = this.paginator.getCurrentSection();
    const numbers = Array(this.paginatorState.pages).fill(0).map((v, i) => i + 1);
    this.pageNumbers = numbers;
  }

  getAndHandleReportByFirmInfo(id) {
    const typeId: number = this.searchFirmByReportTypeForm.get('reportType').value.rptTypeId;
    const period: string = this.searchFirmByReportTypeForm.get('reportPeriod').value.rptPrdDt;
    return this.adminService.getReportByFirmId(typeId, period, id).subscribe(
      (response: ReportInstanceMetadata) => {
        this.handleReportByFirmInfo(response);
      },
      (error: HttpErrorResponse) => {
        this.uiWidgetsService.showErrorSnackbar('Cound not load report...');
        return of(null);
      }
    );
  }

  handleReportByFirmInfo(report: ReportInstanceMetadata) {
    const path: string = this.reportService.navigateToReportDetails({
      report,
      getPathUrl: true
    });
    const origin = window.location.origin;
    const baseHref = (this.location as any)._baseHref || `/`;
    const report_summary_url = origin + baseHref + path;
    console.log({ origin, path, baseHref, report_summary_url });
    this.reportTab = window.open(report_summary_url, '_blank');
  }

  setReportTypesByCategoryId(categoryId: number) {
    if (!categoryId) {
      this.reportTypesListFiltered = [];
      return;
    }

    const reportTypesByCategoryId = this.reportTypesList.filter(r => r.rptCtgryId === categoryId);
    const distinctReportTypesObj = sort_distinct(reportTypesByCategoryId, 'rptTypeId');
    const distinctReportTypes = [];
    Object.keys(distinctReportTypesObj).forEach((key) => {
      const reportTypesList = distinctReportTypesObj[key];
      deepSort(reportTypesList, 'rptCfgId', true);
      distinctReportTypes.push(reportTypesList[0]);
    });
    deepSort(distinctReportTypes, 'rptTypeNm', false, true);
    this.reportTypesListFiltered = distinctReportTypes;
  }

  fetchPeriodsAndVerionsByReportConfigId(rptCfgId: number) {
    return this.adminService.getPeriodsAndVerionsByReportConfigId(rptCfgId);
  }

  openReportPage(firm: FirmInfo) {
    const { firmId } = firm;
    this.getAndHandleReportByFirmInfo(firmId);
  }

  onPageNumber(event) {
    const numberValue = parseInt(event.target.value, 10);
    this.setPage(numberValue);
  }

  onSubmitForm() {
  }

  openHelp() {
  }

  // paginator methods

  setPage(num: number) {
    this.paginator.setPage(num);
    this.firmsListFiltered = this.paginator.getCurrentSection();
  }

  setPrev() {
    this.paginator.setPrev();
    this.firmsListFiltered = this.paginator.getCurrentSection();
  }

  setNext() {
    this.paginator.setNext();
    this.firmsListFiltered = this.paginator.getCurrentSection();
  }

  showFilterInfoModal() {
    this.uiWidgetsService.showInfoModal({
      header: 'Using the filter',
      body: explorerFilterInfoModalMessage,
    }, {
      width: '1000px',
      height: 'auto',
    });
  }
}
