<div
  fxLayout="row"
  fxLayoutAlign="start center"
  [ngClass]="alertClass"
  *ngIf="show"
>
  <div fxFlex="2">
    <fa-icon [icon]="faExclamationTriangle" class="fa-lg"></fa-icon>
  </div>
  <div fxFlex="97">
    <span fxFlex *ngIf="alertItem">{{ alertItem.message }}</span>
  </div>
  <div fxFlex="1" (click)="closeAlert()">
    <fa-icon [icon]="faTimes" class="fa-lg"></fa-icon>
  </div>
</div>
