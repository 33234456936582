<div id="consumer-debits-report-container">
  <!-- Unsecured Customer Debits -->
  <report-debits-grid
    class="pdf"
    [reportInstanceMetadata]="reportInstanceMetadata"
    [title]="'Unsecured Customer Debits'"
    [summaryData]="unsecuredDebitsMonthly"
  ></report-debits-grid>

  <!-- Margin Debits in Customer Accounts -->
  <report-debits-grid
    class="pdf"
    [reportInstanceMetadata]="reportInstanceMetadata"
    [title]="'Margin Debits in Customer Accounts'"
    [summaryData]="marginDebitsMonthly"
  ></report-debits-grid>
</div>