<div class="finra-grid-container" [ngClass]="containerCssClass" [ngStyle]="containerCssStyle">
  <div class="table-header" [ngClass]="tableHeaderCssClass">
    <div class="table-title" [ngClass]="tableTitleCssClass">
      {{ title }}
    </div>
    <div *ngIf="helpLink" class="no-print help-link">
      <fa-icon
        data-html2canvas-ignore
        [icon]="faQuestionCircle"
        class="fa-lg question-icon"
        (click)="openHelp()"
        [title]="help"
      ></fa-icon>
    </div>
  </div>
  <ag-grid-angular
    [domLayout]="'autoHeight'"
    class="ag-theme-material print {{ agGridClass }}"
    [id]="id"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [context]="context"
    [groupMultiAutoColumn]="true"
    [groupDefaultExpanded]="groupDefaultExpanded"
    (gridReady)="onGridReady($event)"
    (displayedColumnsChanged)="displayedColsChanged($event)"
    (rowGroupOpened)="rowGroupOpened($event)"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [suppressAggFuncInHeader]="true"
    [gridOptions]="gridOptions"
    (gridSizeChanged)="autoSizeAllColumns()"
    [rowSelection]="rowSelection"
    [suppressRowClickSelection]="suppressRowClickSelection"
    (selectionChanged)="onSelectionChanged($event)"
    [frameworkComponents]="frameworkComponents"
    [statusBar]="statusBar"
  ></ag-grid-angular>
</div>
