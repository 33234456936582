import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  IconDefinition,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons';
import { DOWNLOAD_FILE_NAMES } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';

import {
  RegisteredRepsUnion,
  PERIOD_AUTO_GROUP_COLUMN_DEFS,
  ALL_REGISTERED_REPS_COLUMN_DEFS,
  SECTION_REGISTERED_REPS_COLUMN_DEFS,
} from 'src/app/configs/model/risk-monitoring/registered-reps-composition.model';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';

export interface RegisteredRepsMasterListElement {
  title: string;
  list: RegisteredRepsUnion[];
  isAllReps: boolean;
  actionBtn?: {
    text: string;
    handler: () => void;
  };
}

@Component({
  selector: 'report-registered-reps-composition',
  templateUrl: './registered-reps-composition.component.html',
  styleUrls: ['./registered-reps-composition.component.scss']
})
export class RegisteredRepresentativeCompositionComponent implements OnInit, OnChanges {
  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  @Input() registeredRepsMasterList: RegisteredRepsMasterListElement[];

  faQuestionCircle: IconDefinition = faQuestionCircle;
  downloadFileNames = DOWNLOAD_FILE_NAMES;

  allRepsColDefs = ALL_REGISTERED_REPS_COLUMN_DEFS;
  sectionRepsColDefs = SECTION_REGISTERED_REPS_COLUMN_DEFS;
  autoGroupColDef = PERIOD_AUTO_GROUP_COLUMN_DEFS;

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
  }
}
