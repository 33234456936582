import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'report-finra-html-grid-cell',
  templateUrl: './finra-html-grid-cell.component.html',
  styleUrls: ['./finra-html-grid-cell.component.scss']
})
export class FinraGridHtmlCellComponent implements ICellRendererAngularComp {
  public params: any;
  htmlContent: string;

  agInit(params: any): void {
    this.params = params;
    const options = params['colDef']['cellRendererFrameworkOptions'];

    let htmlContent;

    if (typeof (options['htmlContent']) === 'function') {
      htmlContent = options['htmlContent'](params);
    } else {
      htmlContent = options['htmlContent'];
    }

    this.htmlContent = htmlContent;
  }

  refresh(): boolean {
    return false;
  }
}
