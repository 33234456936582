import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from "@angular/core";

import {
  IconDefinition,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { ReportInstanceMetadata } from "src/app/configs/model/reports.model";
import {
  CustomerDebitsMonthly,
  UnsecuredCustomerDebitsMonthlySummary,
  MarginCustomerDebitsMonthlySummary,
} from "src/app/configs/model/risk-monitoring/customer-debits.model";

@Component({
  selector: "report-customer-debits",
  templateUrl: "./customer-debits-report.component.html",
  styleUrls: ["./customer-debits-report.component.scss"],
})
export class CustomerDebitsReportComponent implements OnInit, OnChanges {
  faQuestionCircle: IconDefinition = faQuestionCircle;

  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  @Input() customerDebitsMonthly: CustomerDebitsMonthly[] = [];
  @Input() unsecuredDebitsMonthly: UnsecuredCustomerDebitsMonthlySummary[] = [];
  @Input() marginDebitsMonthly: MarginCustomerDebitsMonthlySummary[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(simpleChanges: SimpleChanges) {}
}
