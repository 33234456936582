<mat-form-field>
    <input matInput [matDatepicker]="dpstart" placeholder="Start">
    <!-- <mat-icon fontSet="fa" fontIcon="fa-user-circle-o" class="fa-lg"></mat-icon> -->
    <mat-datepicker-toggle matSuffix [for]="dpstart">
        <mat-icon matDatepickerToggleIcon><fa-icon [icon]="faCalendarAlt" class="fa-lg"></fa-icon></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #dpstart></mat-datepicker>
</mat-form-field>
<mat-form-field>
    <input matInput [matDatepicker]="dpend" placeholder="End">
    <mat-datepicker-toggle matSuffix [for]="dpend">
        <mat-icon matDatepickerToggleIcon><fa-icon [icon]="faCalendarAlt" class="fa-lg"></fa-icon></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #dpend></mat-datepicker>
</mat-form-field>
<mat-form-field class="grid-filter-text" floatLabel="never">
    <input matInput placeholder="FMPID Number" #input (ngModelChange)="onChange($event)" [ngModel]="text">
</mat-form-field>
<!-- <mat-form-field floatLabel="never">
    <mat-select formControlName="type" placeholder="Users">
        <mat-option>
            Lorem ipsum
        </mat-option>
    </mat-select>
</mat-form-field> -->
<!-- <mat-accordion>
    <mat-expansion-panel> -->
        <!-- <mat-expansion-panel-header>
            <mat-panel-title>
            Report Details
            </mat-panel-title>
            <mat-panel-description>
                (#)
            </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-checkbox *ngFor="let detail of details; let i = index;">{{ details[i] }}</mat-checkbox> -->
        <!-- <mat-checkbox>Explore Export</mat-checkbox>
        <mat-checkbox>Import</mat-checkbox> -->
    <!-- </mat-expansion-panel>
</mat-accordion> -->
<button (click)="onDate()">Date</button>
<button (click)="onBtExport()">Export to CSV</button>
<button (click)="onBtPrint()">Print</button>
