import { Injectable } from '@angular/core';
import { UserProfile } from '../configs/model/user-profile.model';
import { EwsService } from './ews.service';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  readonly KEY_PREFIX = 'session.service.';
  readonly SESSION_DURATION = 30 * 60 * 1000; // 30mins

  userProfile: UserProfile;
  logoutAction: () => Promise<any>;
  name: string;

  constructor(
    private userProfileService: UserProfileService,
    private ewsService: EwsService
  ) {
    this.name = Math.floor(Math.random() * 1000).toString();
  }

  startSession(
    userProfile: UserProfile,
    loginAction: () => Promise<any>,
    logoutAction: () => Promise<any>,
    timeoutAction: (
      durationMillis,
      logoutAction: () => Promise<any>,
      continueAction: () => Promise<any>
    ) => void
  ) {
    this.userProfile = userProfile;

    const lastUserLogin = localStorage.getItem(
      this.KEY_PREFIX + userProfile.userLoginId
    );

    if (
      lastUserLogin == null ||
      new Date().getTime() - new Date(lastUserLogin).getTime() >
        this.SESSION_DURATION
    ) {
      loginAction();
    }

    this.ewsService.startTrackingTimeout(async () => {
      this.logout();
    }, timeoutAction);

    this.logoutAction = logoutAction;
    this.keepAlive();
  }

  keepAlive() {
    if (this.userProfile) {
      localStorage.setItem(
        this.KEY_PREFIX + this.userProfile.userLoginId,
        new Date().toJSON()
      );
    }
  }

  async logout() {
    const userProfile = this.userProfile;

    this.userProfile = null;

    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(this.KEY_PREFIX)) {
        localStorage.removeItem(key);
      }
    });

    if (userProfile && this.logoutAction != null) {
      await this.logoutAction();

      this.ewsService.logout();
    }
  }
}
