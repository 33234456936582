import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { formatBytes } from '../shared/utils/utils';

@Pipe({name: 'bytes'})
export class BytesFormatterPipe implements PipeTransform {
  /**
   * Rounds a number and returns it.
   * @see: http://blog.bastien-donjon.fr/round-number-angular-2-pipe/
   *
   * @param value
   * @returns {number}
   */
  transform(value: number | string, decimals: number = 2): string {
    return formatBytes(value, decimals);
  }
}
