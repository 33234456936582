<div [class.loader-hidden]="!show">
  <div class="loader-overlay">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="show"></mat-progress-bar> -->
    <div class="spinner-container" *ngIf="show">
      <div class="spinner-icon-container middle">
        <div class="spinner-icon"></div>
      </div>
    </div>
  </div>
</div>
