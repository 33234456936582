import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridFiltersService {
  // private gridApi;

  constructor() {}

  dateTime(params) {
    const dateFilterComponent = params.getFilterInstance('time');
    dateFilterComponent.setModel({
      type: 'equals',
      dateFrom: '2018-06-18',
      dateTo: null
    });
    params.onFilterChanged();
  }

  user(params, event) {
    const userFilterComponent = params.getFilterInstance('user');
    userFilterComponent.setModel({
      type: 'contains',
      filter: event
    });
    params.onFilterChanged();
  }

  export(params, event) {
    params.exportDataAsCsv(event);
  }

  print(params) {
    this.setPrinterFriendly(params);
    const self = this;
    setTimeout(function() {
      print();
      self.setNormal(params);
    }, 2000);
  }

  setPrinterFriendly(api) {
    const eGridDiv = <HTMLElement>document.querySelector('.print');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
  }

  setNormal(api) {
    const eGridDiv = <HTMLElement>document.querySelector('.print');
    eGridDiv.style.width = '100%';
    eGridDiv.style.height = '100%';
    api.setDomLayout(null);
  }
}
