import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  IconDefinition,
  faBell,
} from '@fortawesome/pro-regular-svg-icons';
import {
  REPORT_IDENTIFIERS,
  PUB_OFFER_VIEW_ORDER,
  PUBLIC_OFFER_COLUMN_DEFS,
  PUBLIC_FILING_TITLES,
  PRV_PLC_FLG_TM_COLUMN_DEFS,
  VIEW_NAMES,
  LATE_FILING_COLUMN_DEFS,
  LATE_FILING_VIEW_ORDER_BY_REPORT_TYPE_AND_EDITION,
  LATE_FILING_TITLES,
  PREFER_LOCAL_CORPFIN_HEADERS,
  CORP_FIN_HEADER_TEXT_BY_REPORT_TYPE_AND_EDITION,
  VIEW_NAMES_SORT_FUNCTIONS,
  PUBLIC_OFFER_AUTO_GROUP_COLUMN_DEFS,
  PUB_OFFER_HELP_LINKS,
  LATE_FILING_AUTO_GROUP_COL_DEFS,
  DEFAULT_TABLE_VIEWS,
  exportPublicOfferingLateFilingSummaryData,
  exportpublicOfferingRuleSummaryData,
  exportPrivatePlacementFilingSummaryData,
  CorpFinReportShortNames,
  CorpFinShowDetailsByReportTypeAndEdition,
  CorpFinAddPeriodAsAdditionalDetailsInfoByReportTypeAndEdition,
} from 'src/app/configs/model/corp-fin.model';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ToolbarConfig,
  ToolbarConfigOptions,
} from 'src/app/configs/model/finra-toolbar.model';
import { FormGroup, FormControl } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { BeastClickActions, BeastOtherActions } from 'src/app/enums/beast.enum';
import { BeastClickEventReportHelpLinkInfo, BeastLeaveReportPageInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { CorpFinService } from 'src/app/services/corp-fin.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { HelperService } from 'src/app/services/helper.service';
import { GLOBAL_REPORT_DISCLAIMER, ReportPageMetadataInfo, ReportService } from 'src/app/services/report.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';
import { quarterDateFormat, saveCsv } from 'src/app/shared/utils/utils';
import { CorpFinToolbarConfig } from 'src/app/configs/toolbar-config/corp-fin.toolbar';



@Component({
  selector: 'report-corp-fin',
  templateUrl: './corp-fin.component.html',
  styleUrls: ['./corp-fin.component.scss']
})
export class CorpFinComponent implements OnInit, OnDestroy {
  firstTime = true;
  reportPeriodDate: string;
  faBell: IconDefinition = faBell;
  reportInstanceMetadata: ReportInstanceMetadata;
  title: string;
  disclaimer = GLOBAL_REPORT_DISCLAIMER;
  description: string;
  help: any;
  helpLink: string;
  viewName: string;
  reportId: number;
  reportTypeId: number;
  reportName: string;
  reportVersion: number;
  publicOfferingReportViews = PUB_OFFER_VIEW_ORDER;
  publicOfferingColumnDefs = PUBLIC_OFFER_COLUMN_DEFS;
  autoGroupColumnDefs = PUBLIC_OFFER_AUTO_GROUP_COLUMN_DEFS;
  rowData = [];
  reportData = {};
  viewTitles = PUBLIC_FILING_TITLES;
  privatePlacementColumnDefs = PRV_PLC_FLG_TM_COLUMN_DEFS;
  lateFilingColumnDefs = LATE_FILING_COLUMN_DEFS;
  lateFilingViews = LATE_FILING_VIEW_ORDER_BY_REPORT_TYPE_AND_EDITION;
  lateFilingTitles = LATE_FILING_TITLES;
  lateFilingAutoGroupColDefs = LATE_FILING_AUTO_GROUP_COL_DEFS;
  loading = {
    firmCorpFin5110ReviewCurrentPeriodSubmissionSummary: true,
    firmCorpFin5110ReviewPriorPeriodSubmissionSummary: true,
    firmCorpFin5110ReviewCurrentPeriodClearanceSummary: true,
    firmCorpFin5110ReviewCommentLetterSummary: true,
    firmCorpFin5110ReviewDistributionMethodSummary: true
  };
  rules = ['5123', '5122'];
  ruleData: object = {};
  chartRuleData: object = {};
  clientHeight: number;
  periodFormatter: any;
  pubOfferHelpLinks = PUB_OFFER_HELP_LINKS;
  defaultTableViews = DEFAULT_TABLE_VIEWS;
  corpFinToolbarConfig: ToolbarConfig;
  corpFinToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  reportPageMetadataInfo: ReportPageMetadataInfo;
  periodChangeSubscription: Subscription;
  shouldShowPublishedState: boolean;
  shouldShowViewDropDown: boolean;
  shouldShowReportDetails: boolean;
  reportLoadedSuccessfully: boolean;
  loadedAt: number;

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private reportsService: ReportsService,
    private baseReportService: ReportService,
    private helperService: HelperService,
    private corpFinService: CorpFinService,
    private toolbarService: ToolbarService,
    private unreadReportsService: UnreadReportsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {
    this.toolbarForm = new FormGroup({
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl()
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Corp Fin');
    this.clientHeight = window.innerHeight;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
        reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: '',
        rating: '',
        product: '',
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.viewName = params.viewName;
    this.reportId = params.reportId;
    this.reportName = params.reportName;
    this.reportVersion = parseInt(params.version, 10);
    this.reportTypeId = parseInt(params.type, 10);
    this.periodFormatter = this.reportName === 'corpfinppftr'
      ? quarterDateFormat
      : null;
    this.getReportConfigurationMetadata().subscribe(
      (pageData: ReportPageMetadataInfo) => {
        this.processReportConfigurationMetadata(pageData);
        // Get Corp Fin report after getting meta data
        this.getCorpFinReport();
      }
    );
  }

  setToolbar() {
    const customOptions: Partial<ToolbarConfigOptions> = {
      // common
      description: this.description,
      hideViewsCell: !this.shouldShowViewDropDown,
      hideDetailsButton: !this.shouldShowReportDetails,
      isQuarterReport: this.reportName === REPORT_IDENTIFIERS.PRV_PLC_FLG_TM,
      setHelpClickHandler: () => {
        this.openHelp(this.helpLinksService[this.reportName]);
      },

      // private placement
      isNotPrivatePlacement: this.reportName !== CorpFinReportShortNames.PRIVATE_PLACEMENT,
      setRule5123HelpClickHandler: () => {
        this.openHelp(this.helpLinksService.CORP_FIN_PRIVATE_PLACEMENT_RULES[5123]);
      },
      setRule5122HelpClickHandler: () => {
        this.openHelp(this.helpLinksService.CORP_FIN_PRIVATE_PLACEMENT_RULES[5122]);
      },
    };

    const results = this.toolbarService.createToolbarConfig(
      this,
      CorpFinToolbarConfig,
      customOptions
    );
    this.corpFinToolbarConfigOptions = results.toolbarConfigOptions;
    this.corpFinToolbarConfig = results.toolbarConfig;
  }

  openHelp(link: string) {
    window.open(link, '_blank');
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          throw error;
        })
      );
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
    this.reportPeriodDate = this.reportInstanceMetadata.reportPeriodDate;
    const viewTemplateConfiguration = this.reportsService.getViewTemplateConfiguration(
      this.reportInstanceMetadata,
      this.viewName
    );
    // Getting the report title from reportInstanceMetadata.reportConfiguration.
    // reportDisplayName since viewTemplateConfiguration returns incorrect title for Contra Reporting Firm 20 Minute Report
    this.title = this.reportInstanceMetadata.reportConfiguration.reportDisplayName;
    this.help = viewTemplateConfiguration['help'];
    this.helpLink = this.helpLinksService[this.reportName];
    const noDisclaimerFound = !this.disclaimer.includes(
      viewTemplateConfiguration['disclaimer']
    );
    if (noDisclaimerFound) {
      this.disclaimer += viewTemplateConfiguration['disclaimer'] || '';
    }
    this.shouldShowPublishedState = this.showViewDropDown();
    this.shouldShowViewDropDown = this.showViewDropDown();
    const shouldShowReportDetails = (
      this.reportName === CorpFinReportShortNames.RULE_5110 ||
      CorpFinShowDetailsByReportTypeAndEdition[CorpFinReportShortNames.LATE_FILING][this.reportInstanceMetadata.reportConfiguration.reportEdition]
    )
    this.shouldShowReportDetails = shouldShowReportDetails;
    this.setToolbar();
  }

  getCorpFinReport() {
    switch (this.reportName) {
      case REPORT_IDENTIFIERS.PUB_OFFER: {
        this.getPublicOfferingRule5110Report()
          .then((values) => {
            this.reportData = {};
            this.handlePublicOfferingRule5110Report(values);
            this.reportLoadedSuccessfully = true;
          })
          .catch((error: HttpErrorResponse) => {
            this.helperService.handleReportSummaryDataError(error);
            this.reportLoadedSuccessfully = false;
            return [];
          });
        return;
      }

      case REPORT_IDENTIFIERS.LATE_FILING: {
        this.getLateFilingsReport()
          .subscribe(
            (response: any) => {
              this.handleLateFilingsReport(response);
              this.reportLoadedSuccessfully = true;
            },
            (error: HttpErrorResponse) => {
              this.helperService.handleReportSummaryDataError(error);
              this.reportLoadedSuccessfully = false;
            }
          );
        return;
      }

      case REPORT_IDENTIFIERS.PRV_PLC_FLG_TM: {
        this.getPrivatePlacementFilingTimelinessReport()
          .subscribe(
            (response: any) => {
              this.handlePrivatePlacementFilingTimelinessReport(response);
              this.reportLoadedSuccessfully = true;
            },
            (error: HttpErrorResponse) => {
              this.helperService.handleReportSummaryDataError(error);
              this.reportLoadedSuccessfully = false;
            }
          );
        return;
      }
    }
  }

  getLateFilingsReport() {
    return this.baseReportService.getReport(this.reportId, this.viewName, 's');
  }

  getPrivatePlacementFilingTimelinessReport() {
    return this.baseReportService.getReport(this.reportId, this.viewName, 's');
  }

  getPublicOfferingRule5110Report(): Promise<
    { data: object[]; view: string }[]
  > {
    const promiseList = this.publicOfferingReportViews.map((view: string) =>
      this.getPublicOfferingRule5110ReportView(view)
    );
    const allPromises = Promise.all(promiseList);
    return allPromises;
  }

  getPublicOfferingRule5110ReportView(
    view: string
  ): Promise<{ data: object[]; view: string }> {
    this.loading[view] = true;
    const promise = this.baseReportService.getReport(this.reportId, view, 's')
      .toPromise()
      .then((data: any) => {
        return this.handlePublicOfferingRule5110ReportView(data, view);
      })
      .catch((error: HttpErrorResponse) => {
        this.reportData[view] = null;
        this.loading[view] = false;
        return { data: null, view };
      });
    return <Promise<{ data: object[]; view: string }>>promise;
  }

  handlePrivatePlacementFilingTimelinessReport(response) {
    this.rowData = response;
    const results = this.corpFinService.createRuleCartData({
      reportPeriodDate: this.reportPeriodDate,
      rules: this.rules,
      rowData: this.rowData,
    });
    this.ruleData = results.ruleData;
    this.chartRuleData = results.chartData;
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  handlePublicOfferingRule5110ReportView(
    data: { [key: string]: any; },
    view: string
  ) {
    this.loading[view] = false;
    if (data.message) {
      // Handling errors when no data returned.
      this.reportData[view] = null;
      this.loading[view] = false;
      return {
        data: null,
        view
      };
    }
    return {
      data,
      view
    };
  }

  handlePublicOfferingRule5110Report(
    values: { data: object[], view: string; }[]
  ) {
    values.forEach((obj) => {
      const { data, view } = obj;
      if (!data) {
        return;
      }
      const isDealsView =
        view === VIEW_NAMES.DEALS_FILED_CURRENT ||
        view === VIEW_NAMES.DEALS_FILED_PRIOR ||
        view === VIEW_NAMES.DEALS_CLEARED_CURRENT;
      if (isDealsView) {
        this.corpFinService.publicOffertingFormatProgramDeals(data, view);
      }
      this.corpFinService.addMissingRows(data, view);
      const reportData = isDealsView
        ? this.corpFinService.addCustomTotalRow(data)
        : data;
      reportData.sort(VIEW_NAMES_SORT_FUNCTIONS[view]);
      this.reportData[view] = reportData;
      this.loading[view] = false;
    });
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  handleLateFilingsReport(response) {
    // Set Title, disclaimer, helplink & details file name
    this.setMetaData(response.metaData);
    this.reportData = this.corpFinService.formatSubmissionsPercent(response);
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  showViewDropDown() {
    switch (this.reportName) {
      case 'corpfinpublicoffer':
      case 'corpfinppftr':
        return false;
      case 'corpfinlatefilings':
        return true;
      default:
        return true;
    }
  }

  onReportDetails(additionalInfo) {
    const reportType = CorpFinReportShortNames.LATE_FILING;
    const reportEdition = this.reportInstanceMetadata.reportConfiguration.reportEdition;
    const addPeriodAsAdditionalInfo = CorpFinAddPeriodAsAdditionalDetailsInfoByReportTypeAndEdition[reportType][reportEdition];

    const useAdditionalInfo = addPeriodAsAdditionalInfo
      ? this.reportInstanceMetadata.reportPeriodDate
      : (!(additionalInfo instanceof MouseEvent) ? additionalInfo : null)

    const exportUrl: string = this.baseReportService.getReportUrl(
      this.reportId,
      this.viewName,
      'd',
      useAdditionalInfo
    );
    // file name will be fetched in helperService.downloadFile call
    this.helperService.downloadFile(exportUrl);
  }

  changeGo() {
    // Set the viewType again when report period/version changes
    const view = this.defaultTableViews[this.reportName];
    this.corpFinService.setViewType(view);
  }

  setMetaData(metaData) {
    if (!metaData) {
      return;
    }
    // Getting title, description, help link, etc from metadata.
    this.title = metaData['title'];

    const local_header_description = (
      CORP_FIN_HEADER_TEXT_BY_REPORT_TYPE_AND_EDITION[this.reportName][this.reportInstanceMetadata.reportConfiguration.reportEdition] ||
      CORP_FIN_HEADER_TEXT_BY_REPORT_TYPE_AND_EDITION[this.reportName].default
    );
    const description: string = PREFER_LOCAL_CORPFIN_HEADERS[this.reportName]
      ? local_header_description || metaData['desc']
      : metaData['desc'] || local_header_description;

    this.description = description;
    this.corpFinToolbarConfig.description = this.description;

    if (this.disclaimer.indexOf(metaData['disclaimer']) === -1) {
      this.disclaimer += metaData['disclaimer'];
    }
    this.help = metaData['help'];
  }

  async summaryDataExport() {
    const fileNameResponse = await this.baseReportService.getReportFileName(
      this.reportId,
      this.viewName,
      's'
    ).toPromise();

    let csvString;
    switch (this.reportName) {
      case CorpFinReportShortNames.LATE_FILING: {
        csvString = exportPublicOfferingLateFilingSummaryData(this.reportData, this.reportInstanceMetadata.reportConfiguration.reportEdition);
        break;
      }
      case CorpFinReportShortNames.RULE_5110: {
        csvString = exportpublicOfferingRuleSummaryData(this.reportData);
        break;
      }
      case CorpFinReportShortNames.PRIVATE_PLACEMENT: {
        csvString = exportPrivatePlacementFilingSummaryData(this.ruleData);
        break;
      }
    }
    saveCsv(csvString, fileNameResponse.fileName);
    this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
  }
}
