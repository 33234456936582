<div class="online-learning-container">
  <report-loader></report-loader>
  <report-alert class="no-print"></report-alert>
  <h1 class="title pdf">
    {{ title }}
  </h1>
  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="onlineLearningToolbarConfig"
    [toolbarConfig]="onlineLearningToolbarConfig"
  ></report-finra-toolbar>

  <div class="margin-bottom-20"></div>

  <div id="data-container" *ngIf="reportLoadedSuccessfully">
    <div class="report-main-content-padding pdf" >
      <table class="rc-grid-summary">
        <caption>
          <h3>{{ sessionsHeading }}</h3>
        </caption>
        <tr>
          <th>Session Type</th>
          <th>Total Sessions</th>
          <th>Complete Sessions</th>
          <th>Incomplete Sessions</th>
        </tr>
        <tr *ngFor="let sessionProgram of data.totalSessionsTable">
          <td>{{ sessionProgram.name }}</td>
          <td>{{ sessionProgram.firmTotalSessionCount }}</td>
          <td>{{ sessionProgram.firmCompleteSessionCount }}</td>
          <td>{{ sessionProgram.firmIncompleteSessionCount }}</td>
        </tr>
      </table>
    </div>

    <div class="report-main-content-padding" *ngIf="hasPeriodData">
      <div *ngIf="!hasPeriodData">
        {{ noPeriodDataMessage }}
      </div>

      <div class="table-header toggler">
        <div class="table-title">
          <span
            *ngFor="let sessionProgram of data.tabs"
            class="link-btn"
            (click)="switchView(sessionProgram.id)"
            [ngClass]="{ 'link-inactive': currentSession != sessionProgram.id }"
          >
            {{ sessionProgram.id }}
            <span class="link-inactive">|</span>
          </span>
        </div>
      </div>

      <div
        *ngFor="let sessionProgram of data.tabs"
        class="pdf"
        [ngClass]="{ hidden: currentSession != sessionProgram.id }"
      >
        <h3>{{ sessionProgram.id }}: {{ sessionProgram.name }}</h3>
        <table class="rc-grid">
          <tbody class="sessionProgramSummary">
            <tr *ngIf="showOtherColumn" class="b">
              <th></th>
              <th colspan="2">
                Series 7
              </th>
              <th colspan="2">Other</th>
            </tr>
            <tr class="b">
              <th></th>
              <th>Firm</th>
              <th>Industry</th>
              <th *ngIf="showOtherColumn">Firm</th>
              <th *ngIf="showOtherColumn">Industry</th>
            </tr>
            <tr>
              <td class="b">Number of Sessions</td>
              <td>
                {{ sessionProgram.numberOfSessionsTable.firmTotalSessionCount }}
              </td>
              <td>
                {{
                  sessionProgram.numberOfSessionsTable.industryTotalSessionCount
                }}
              </td>
              <td *ngIf="showOtherColumn">
                {{
                  sessionProgram.numberOfSessionsTable.firmTotalSessionCountOther
                }}
              </td>
              <td *ngIf="showOtherColumn">
                {{
                  sessionProgram.numberOfSessionsTable
                    .industryTotalSessionCountOther
                }}
              </td>
            </tr>
          </tbody>
          <tbody
            *ngFor="let module of sessionProgram.modules"
            class="sessionProgram"
          >
            <tr *ngIf="showModuleType(module.type)">
              <td colspan="5" class="x-tab b">{{ module.type }}</td>
            </tr>

            <tr>
              <td colspan="5" class="xx-tab b">{{ module.name }}</td>
            </tr>
            <tr class="b">
              <th>Score</th>
              <th>Firm (%)</th>
              <th>Industry (%)</th>
              <th *ngIf="showOtherColumn">Firm (%)</th>
              <th *ngIf="showOtherColumn">Industry (%)</th>
            </tr>
            <tr class="average-mean">
              <td>Average (Mean %)</td>
              <td>
                {{
                  roundUptoTwoDecimal(
                    module.firmSessionAverageMeanScorePercent,
                    '%'
                  )
                }}
              </td>
              <td>
                {{
                  roundUptoTwoDecimal(
                    module.industrySessionAverageMeanScorePercent,
                    '%'
                  )
                }}
              </td>
              <td *ngIf="showOtherColumn">
                {{
                  roundUptoTwoDecimal(
                    module.firmSessionAverageMeanScorePercentOther,
                    '%'
                  )
                }}
              </td>
              <td *ngIf="showOtherColumn">
                {{
                  roundUptoTwoDecimal(
                    module.industrySessionAverageMeanScorePercentOther,
                    '%'
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Standard Deviation</td>
              <td>
                {{
                  roundUptoTwoDecimal(
                    module.firmSessionScoreStandardDeviationPercent,
                    '%'
                  )
                }}
              </td>
              <td>
                {{
                  roundUptoTwoDecimal(
                    module.industrySessionScoreStandardDeviationPercent,
                    '%'
                  )
                }}
              </td>
              <td *ngIf="showOtherColumn">
                {{
                  roundUptoTwoDecimal(
                    module.firmSessionScoreStandardDeviationPercentOther,
                    '%'
                  )
                }}
              </td>
              <td *ngIf="showOtherColumn">
                {{
                  roundUptoTwoDecimal(
                    module.industrySessionScoreStandardDeviationPercentOther,
                    '%'
                  )
                }}
              </td>
            </tr>
            <tr class="b">
              <th>Duration</th>
              <th>Firm (HH:MM)</th>
              <th>Industry (HH:MM)</th>
              <th *ngIf="showOtherColumn">Firm (HH:MM)</th>
              <th *ngIf="showOtherColumn">Industry (HH:MM)</th>
            </tr>
            <tr class="average-mean">
              <td>Average (Mean %)</td>
              <td>
                {{ module.firmSessionAverageDurationTime || nullValue }}
              </td>
              <td>
                {{ module.industrySessionAverageDurationTime || nullValue }}
              </td>
              <td *ngIf="showOtherColumn">
                {{ module.firmSessionAverageDurationTimeOther || nullValue }}
              </td>
              <td *ngIf="showOtherColumn">
                {{ module.industrySessionAverageDurationTimeOther || nullValue }}
              </td>
            </tr>
            <tr>
              <td>Standard Deviation</td>
              <td>
                {{ module.firmSessionDurationStandardDeviationTime || nullValue }}
              </td>
              <td>
                {{
                  module.industrySessionDurationStandardDeviationTime || nullValue
                }}
              </td>
              <td *ngIf="showOtherColumn">
                {{
                  module.firmSessionDurationStandardDeviationTimeOther ||
                    nullValue
                }}
              </td>
              <td *ngIf="showOtherColumn">
                {{
                  module.industrySessionDurationStandardDeviationTimeOther ||
                    nullValue
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <hr />
  <div
    class="report-disclaimer pdf"
    *ngIf="disclaimer"
    [innerHtml]="disclaimer"
  ></div>
</div>
