import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import {
  SALES_PRACTICE_COL_DEFS,
  SALES_PRACTICE_TABLE_TITLES,
} from 'src/app/configs/model/risk-monitoring/sales-practice-complaint.model';
import {
  IconDefinition,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { RiskMonitoringReportNames } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import { BeastService } from 'src/app/services/beast.service';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';

@Component({
  selector: 'report-sales-practice-complaint-report',
  templateUrl: './sales-practice-complaint-report.component.html',
  styleUrls: ['./sales-practice-complaint-report.component.scss']
})
export class SalesPracticeComplaintReportComponent implements OnInit, OnChanges {
  @Input() reportInstanceMetadata;
  @Input() rowData;
  @Input() salesComplaintViews;
  title = 'Sales Practice Complaint Report';
  columnDefs = SALES_PRACTICE_COL_DEFS;
  tableTitles = SALES_PRACTICE_TABLE_TITLES;
  faQuestionCircle: IconDefinition = faQuestionCircle;

  constructor(
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {}

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
  }

  openHelp() {
    this.helpLinksService.open(RiskMonitoringReportNames.SALES_PRACTICE_COMPLAINT);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}
