import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { Subject } from 'rxjs';

export interface Html2canvasResult {
  canvas?: any;
  image: any;
  width: number;
  margin: number[];
  background: string;
}

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  processing = new Subject<boolean>();
  fileName;

  constructor() {}

  setWhiteBackgroundPdf() {
    // Cleanup black background for rect elements
    const rectElements = Array.from(document.getElementsByTagName('rect'));

    if (rectElements.length > 0) {
      rectElements.forEach(rect => {
        rect.setAttribute('fill', 'transparent');
      });
    }
  }

  getPdfElements(width: number = 4000, scale: number = 4) {
    const pdfElements = (<any> Array.from(document.getElementsByClassName('pdf')) as HTMLElement[]);
    const promises = [];

    this.setWhiteBackgroundPdf();

    pdfElements.forEach((element) => {
      promises.push(
        html2canvas(element, {
          logging: false,
          scale: scale,
          width: width,
          windowWidth: width / 2,
          backgroundColor: '#FFFFFF'
        }).then((canvas: HTMLCanvasElement) => {
          // Add image only if its data url is not empty
          const result: Html2canvasResult = {
            canvas,
            image: canvas.toDataURL(),
            width: width / 2,
            margin: [0, 0, 0, 10],
            background: '#FFFFFF'
          };
          return result;
        })
      );
    });

    return Promise.all(promises);
  }

  getPrintContent(element: { image: string; }) {
    // Add all the images created from html2canvas to the div
    const image = document.createElement('img');
    image.setAttribute('src', element.image);
    image.style.maxWidth = this.getWidthByBrowser();
    image.style.paddingRight = 10 + 'px';
    image.style.paddingLeft = 10 + 'px';
    image.style.marginBottom = 30 + 'px';

    return image;
  }

  getPrintContents(results: { image: string; }[]) {
    // Create a div
    const div = document.createElement('div');

    // Add all the images created from html2canvas to the div
    results.forEach(element => {
      const image = this.getPrintContent(element);
      div.appendChild(image);
    });

    return div.innerHTML;
  }

  exportPdf(fileName: string = null, wd?: number, sc?: number, pdfElementsPromise?: Promise<any[]>) {
    // Setting print attributes eg. width, scale, filename
    this.fileName = fileName;
    const width = wd || 4000;
    const scale = sc || 4;
    const usePdfElementsPromise = pdfElementsPromise || this.getPdfElements(width, scale);

    return usePdfElementsPromise.then((results: any) => {
      this.printPdfFromBrowser(results);
    });
  }

  printPdfFromBrowser(results) {
    const printContents = this.getPrintContents(results);
    const popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${this.fileName}</title>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.focus();
    popupWin.document.close();
  }

  printPdfContent(printContent: string, fileName: string = '', afterPrintCallback?: (arg?: any) => void) {
    let printFrame = document.getElementById('printf');
    if (!printFrame) {
      // if there is no current print frame, create it.
      printFrame = document.createElement('iframe');
      printFrame.setAttribute('id', 'printf');
      printFrame.setAttribute('name', 'printf');
      // hide the frame by moving it out of view;
      printFrame.style.position = 'fixed';
      printFrame.style.left = '99999px';
      document.body.appendChild(printFrame);
    }

    const printFrameRef = window.frames['printf'];
    const bodyContent = `<body>${printContent}</body>`;
    printFrameRef.document.write(bodyContent);
    printFrameRef.focus();
    printFrameRef.print();
    printFrameRef.document.close();

    if (afterPrintCallback) {
      printFrameRef.onafterprint = () => {
        afterPrintCallback(printFrameRef);
      };
    }
  }

  getWidthByBrowser() {
    const ua = window.navigator.userAgent;
    // For edge reduce the width to avoid cut-off
    if (ua.indexOf('Edge/') > 0) {
      return 2850 + 'px';
    }
    // Return 3450 px by default
    return 3300 + 'px';
  }
}
