import { Injectable } from '@angular/core';
import {
  MsrbReportNames,
  MsrbReportTypes,
  MsrbPeerGroupParams,
  MsrbPeerGroupFirmIdLabels,
  MsrbPeerGroupFields
} from 'src/app/configs/model/msrb/msrb.model';
import {
  SummaryExportData,
  buildColumnLabelsString,
  buildRowDataString,
  trimTrailingChar,
  buildExportDataString,
  deepSort,
  getMonthYrDate
} from '../shared/utils/utils';
import {
  transactionsWithNoOSOnEmmaColumnDefsMappings,
  lackingCurrentFinancialsColumnDefsMappings,
  transactionDuringTradeMonthColumnDefsMappings,
  transactionDuring1To3OColumnDefsMappings,
  transactionDuring4To6ColumnDefsMappings,
  ContinuingDisclosureResponseData,
  GET_MSRB_CD_ALL_TRANSACTIONS_COLUMN_DEFS,
  AllMunicipalTransactionsData
} from 'src/app/configs/model/msrb/continuing-disclosure.model';
import {
  PRIMARY_OFFERING_DISCLOSURE_COLUMN_DEFS,
  applyPrimaryOfferingsDetailLink,
  PrimaryOfferingDisclosureData
} from 'src/app/configs/model/msrb/primary-offering-disclosure.model';
import {
  PARENT_HEADERS,
  PARENT_HEADERS_L2,
  CHILD_HEADERS
} from 'src/app/configs/model/msrb/due-diligence-summary.model';
import {
  FIRM_COLUMN_DEFS,
  PEER_GROUP_COLS
} from 'src/app/configs/model/msrb/underwriter-financial-status.model';
import { ReportService, ReportPageMetadataInfo } from './report.service';
import { MatDialog } from '@angular/material/dialog';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HelperService } from './helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ALL_SUBMITTED_FILINGS_COLUMN_DEFS,
  AllSubmittedFilingsData,
  applyG32UnderwritingDetailLink
} from 'src/app/configs/model/msrb/g32-underwriting.model';
import {
  GET_TRANSACTIONS_COLUMN_DEFS,
  TransactionsData,
  TRADES_BELOW_MINIMUM_DENOMINATION_COLUMN_DEFS,
  TradesBelowData
} from 'src/app/configs/model/msrb/trades-below.model';
import { PeerGroupComponent } from '../components/fragments/peer-group/peer-group.component';
import { ReportInstanceMetadata } from '../configs/model/reports.model';

@Injectable({
  providedIn: 'root'
})
export class MsrbService {
  MSRB_EXPORT_CONFIG = {
    [MsrbReportNames.CONTINUING_DISCLOSURE]: this.exportContinuingDisclosureSummaryData,
    [MsrbReportNames.MARKUP_MARKDOWN_ANALYSIS]: this.exportMsrbMarkupMarkdownSummaryData,
    [MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS]: this.exportMsrbUnderwriterFinancialStatusSummaryData,
    [MsrbReportNames.G_32_UNDERWRITING]: this.exportMsrbG32ReportSummaryData,
    [MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION]: this.exportTradesBelowSummaryData,
    [MsrbReportNames.PRIMARY_OFFERING_DISCLOSURE]: this.exportPrimaryOfferingSummaryData,
    [MsrbReportNames.DUE_DILIGENCE_SUMMARY]: this.exportDueDiligenceSummaryData,
  };

  constructor(
    private baseReportService: ReportService,
    private helperService: HelperService,
    private dialog: MatDialog,
  ) {}

  formatResponse(response, reportTypeId) {
    switch (reportTypeId) {
      case MsrbReportTypes.TRADES_BELOW_MINIMUM_DENOMINATION:
      case MsrbReportTypes.G_32_UNDERWRITING: {
        // for each data list (array) in the response, fill in the gap periods
        Object.keys(response).forEach(key => {
          response[key] = this.baseReportService.fillGapMonths(
            response[key],
            'periodStartDate'
          );
        });
        return response;
      }
      case MsrbReportTypes.PRIMARY_OFFERING_DISCLOSURE: {
        // DDWA-4341 (backend should be sorting it but isn't)
        deepSort(response.data, 'period', true, null, 'date');
        const newList = this.baseReportService.fillGapMonths(
          response.data,
          'period'
        );
        response.data = newList;
        return response;
      }
      case MsrbReportTypes.UNDERWRITER_FINANCIAL_STATUS: {
        Object.keys(response.data).forEach(key => {
          response.data[key] = this.baseReportService.fillGapMonths(
            response.data[key],
            'period'
          );
        });
        return response.data;
      }
      case MsrbReportTypes.MARKUP_MARKDOWN_ANALYSIS: {
        // this summary data comes back as a list/array.
        // return an object with one prop, "mumd", to work with the render logic in the template (this.rowData)
        // "mumd" will alsb be used as the mappings in the msrb.model.ts for the tablenames and other.
        return { mumd: response };
      }
      case MsrbReportTypes.CONTINUING_DISCLOSURE: {
        const data: ContinuingDisclosureResponseData = response;

        // fill gaps for each
        (<any>(
          data.summary
        )).allMunicipalTransaction = this.baseReportService.fillGapMonths(
          data.summary.allMunicipalTransaction,
          'period'
        );
        (<any>(
          data.summary
        )).transactionsWithNoOSOnEmma = this.baseReportService.fillGapMonths(
          data.summary.transactionsWithNoOSOnEmma,
          'osEMMAPeriod'
        );
        (<any>(
          data.summary
        )).lackingCurrentFinancials = this.baseReportService.fillGapMonths(
          data.summary.lackingCurrentFinancials,
          'period'
        );
        (<any>(
          data.summary
        )).transactionDuringTradeMonth = this.baseReportService.fillGapMonths(
          data.summary.transactionDuringTradeMonth,
          'period'
        );
        (<any>(
          data.summary
        )).transactionDuring1To3O = this.baseReportService.fillGapMonths(
          data.summary.transactionDuring1To3O,
          'period'
        );
        (<any>(
          data.summary
        )).transactionDuring4To6 = this.baseReportService.fillGapMonths(
          data.summary.transactionDuring4To6,
          'period'
        );

        return data.summary;
      }
      default:
        return response;
    }
  }

  getUnderWriterTierCode(response) {
    if (!response) {
      return;
    }
    const tierCode = response.data['firm'][0]['peerGroupSummaryIdentifier'];
    return tierCode;
  }

  getPeerGroupData(
    params: MsrbPeerGroupParams,
    shouldAutoOpenModal: boolean = true
  ) {
    const obs = this.baseReportService
      .getPeerGroupData(
        params.reportId,
        params.viewName,
        params.reportDate,
        params.tierCode
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          this.helperService.handlePeerGroupDataError(error);
          return of(null);
        })
      );

      if (!shouldAutoOpenModal) return obs;

      obs.subscribe((data) => {
        params.rowData = data;
        this.openPeerGroupDilog(params);
      });
  }

  openPeerGroupDilog(
    params: MsrbPeerGroupParams
  ) {
    const dialogRef = this.dialog.open(PeerGroupComponent, {
      width: '600px',
      height: '650px',
      data: {
        reportInstanceMetadata: params.reportInstanceMetadata,
        rowData: params.rowData,
        period: getMonthYrDate(params.reportDate),
        reportName: params.reportName,
        reportView: params.viewName,
        columnTitle: params.columnTitle || 'MPID',
        useField: params.useField,
        tierCode: params.tierCode,
        print: params.printFn
      }
    });
    return dialogRef;
  }

  async onReportDetails(
    params: {
      viewName;
      reportId;
      reportFirmId;
      reportDate;
    }
  ) {
    // get the details requests url
    const exportUrl = this.baseReportService.getReportUrl(
      params.reportId,
      params.viewName,
      'd',
      params.reportDate
    );
    // trigger download
    this.helperService.downloadFile(exportUrl);
  }

  /** AG-Grid Link Methods */

  addUniqueTradeAlertLink(
    columnDefs,
    viewName,
    reportFirmId,
    reportPageMetadataInfo: ReportPageMetadataInfo,
  ) {
    columnDefs.tradesBelowMinDenomination = TRADES_BELOW_MINIMUM_DENOMINATION_COLUMN_DEFS(
      params => {
        const report = this.helperService.getReportFromListByPeriod(
          (<TradesBelowData> params.data).periodStartDate,
          reportPageMetadataInfo.reportInstanceMetadatas
        );
        this.onReportDetails({
          viewName,
          reportId: report.reportId,
          reportFirmId,
          reportDate: (<TradesBelowData> params.data).periodStartDate,
        });
      }
    );
  }

  addDetailsLink(
    reportTypeId: MsrbReportTypes,
    columnDefs,
    viewName,
    reportFirmId,
    reportPageMetadataInfo: ReportPageMetadataInfo,
  ) {
    switch (reportTypeId) {
      case MsrbReportTypes.PRIMARY_OFFERING_DISCLOSURE: {
        applyPrimaryOfferingsDetailLink(
          columnDefs.data,
          params => {
            const report = this.helperService.getReportFromListByPeriod(
              (<PrimaryOfferingDisclosureData> params.data).period,
              reportPageMetadataInfo.reportInstanceMetadatas
            );
            this.onReportDetails({
              viewName,
              reportId: report.reportId,
              reportFirmId,
              reportDate: (<PrimaryOfferingDisclosureData> params.data).period,
            });
          }
        );
        break;
      }
      case MsrbReportTypes.G_32_UNDERWRITING: {
        applyG32UnderwritingDetailLink(
          columnDefs.firmIndustry[0].children,
          params => {
            const report = this.helperService.getReportFromListByPeriod(
              (<AllSubmittedFilingsData>params.data).periodStartDate,
              reportPageMetadataInfo.reportInstanceMetadatas
            );
            this.onReportDetails({
              viewName,
              reportId: report.reportId,
              reportFirmId,
              reportDate: (<AllSubmittedFilingsData>params.data).periodStartDate,
            });
          }
        );
        break;
      }
    }
  }

  addPeerGroupLink(options: {
    reportName: string,
    reportId: number,
    viewName: string,
    tierCode: string,
    columnDefs,
    shouldAutoOpenModal: boolean,
    reportInstanceMetadata: ReportInstanceMetadata,
    printFn: () => void,
  }) {
    const {
      reportName,
      reportId,
      viewName,
      columnDefs,
      tierCode,
      reportInstanceMetadata,
      printFn,
    } = options;

    const peerGroupFirmIdLabel = MsrbPeerGroupFirmIdLabels[reportName];
    const peerGroupUseField = MsrbPeerGroupFields[reportName];

    const baseObj: MsrbPeerGroupParams = {
      reportInstanceMetadata,
      reportName,
      reportId,
      viewName,
      columnTitle: peerGroupFirmIdLabel,
      useField: peerGroupUseField,
      printFn,
      rowData: null,
      reportDate: null,
      tierCode: null,
    };

    switch (reportName) {
      case MsrbReportNames.G_32_UNDERWRITING: {
        columnDefs.firmIndustry = ALL_SUBMITTED_FILINGS_COLUMN_DEFS(params => {
          this.getPeerGroupData({
            ...baseObj,
            tierCode: (<AllSubmittedFilingsData>params.data).peerGroupCount.toString(),
            reportDate: (<AllSubmittedFilingsData>params.data).periodStartDate,
          });
        });
        break;
      }
      case MsrbReportNames.TRADES_BELOW_MINIMUM_DENOMINATION: {
        columnDefs.transactions = GET_TRANSACTIONS_COLUMN_DEFS(params => {
          this.getPeerGroupData({
            ...baseObj,
            tierCode: (<TransactionsData>params.data).peerGroupNumber.toString(),
            reportDate: (<TransactionsData>params.data).periodStartDate,
          });
        });
        break;
      }
      case MsrbReportNames.UNDERWRITER_FINANCIAL_STATUS: {
        columnDefs.peerGroup = PEER_GROUP_COLS(params => {
          this.getPeerGroupData({
            ...baseObj,
            tierCode: tierCode,
            reportDate: params.data.period,
          });
        });
        break;
      }
      case MsrbReportNames.CONTINUING_DISCLOSURE: {
        columnDefs.allMunicipalTransaction = GET_MSRB_CD_ALL_TRANSACTIONS_COLUMN_DEFS(params => {
          this.getPeerGroupData({
            ...baseObj,
            reportDate: (<AllMunicipalTransactionsData>params.data).period,
            tierCode: (<AllMunicipalTransactionsData>params.data).peerGroup,
          });
        });
        break;
      }
    }
  }

  /** Summary Export */

  exportContinuingDisclosureSummaryData(rowData): string {
    const columnLabels = [
      'Period',
      'Count',
      'Firm %',
      'Peer Mean',
      'Peer Median',
      'Peer Rank',
      'Industry Mean',
      'Industry Median',
      'Industry Rank'
    ];
    const exportData: SummaryExportData[] = [
      {
        title: 'All Municipal Transactions',
        rowData: rowData['allMunicipalTransaction'],
        columnLabels: [
          'Period',
          'Total Transaction Count',
          'Customer Transaction Count',
          'Peer Group',
          'Peer Rank',
          'Peer Population',
          'Industry Rank',
          'Industry Population'
        ],
        dataMappings: [
          'period',
          'totalTransactionCount',
          'totalCustomerCount',
          'peerGroup',
          'peerRank',
          'peerPopulation',
          'industryRank',
          'industryPopulation'
        ],
      },
      {
        title: 'Transactions for Issues with No OS on EMMA**',
        rowData: rowData['transactionsWithNoOSOnEmma'],
        columnLabels: columnLabels,
        dataMappings: transactionsWithNoOSOnEmmaColumnDefsMappings
      },
      {
        title: 'Transactions for Issues Lacking Current Financials',
        rowData: rowData['lackingCurrentFinancials'],
        columnLabels: columnLabels,
        dataMappings: lackingCurrentFinancialsColumnDefsMappings
      },
      {
        title:
          'Transactions for Issues with 1 or More Events Filed During Trade Month',
        rowData: rowData['transactionDuringTradeMonth'],
        columnLabels: columnLabels,
        dataMappings: transactionDuringTradeMonthColumnDefsMappings
      },
      {
        title:
          'Transactions for Issues with 1 or More Events Filed in 1st - 3rd Months Prior to the Trade Month',
        rowData: rowData['transactionDuring1To3O'],
        columnLabels: columnLabels,
        dataMappings: transactionDuring1To3OColumnDefsMappings
      },
      {
        title:
          'Transactions for Issues with 1 or More Events Filed in 4th - 6th Months Prior to the Trade Month',
        rowData: rowData['transactionDuring4To6'],
        columnLabels: columnLabels,
        dataMappings: transactionDuring4To6ColumnDefsMappings
      }
    ];

    const csvString = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvString, '\n');
    return formatted;
  }

  exportMsrbMarkupMarkdownSummaryData(rowData): string {
    const exportData: SummaryExportData[] = [
      {
        title: 'MSRB Markup Markdown Analysis Report',
        rowData: rowData['mumd'],
        columnLabels: [
          [
            '',
            'Firm',
            '',
            '',
            'Industry',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ],
          [
            'Dollar Traded Amount',
            'Number of Trades Evaluated',
            'Mean',
            'Median',
            'Mean',
            'Median',
            'P25',
            'P50',
            'P75',
            'P80',
            'P90',
            'P95',
            'P99',
          ],
        ],
        dataMappings: [
          'dollarTradedAmount',
          'numberOfTradesEvaluated',
          'meanFirm',
          'medianFirm',
          'meanIndustry',
          'medianIndustry',
          'p25',
          'p50',
          'p75',
          'p80',
          'p90',
          'p95',
          'p99',
        ],
      }
    ];

    const csvString = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvString, '\n');
    return formatted;
  }

  exportMsrbUnderwriterFinancialStatusSummaryData(rowData): string {
    const firstRowHeadings = [
      '',
      '',
      '',
      '',
      '',
      'Count of Lateness for Issuances LCF (By Days Late)',
    ];
    const secondRowHeadings = [
      ...(<any> FIRM_COLUMN_DEFS[0]).children.map((i: any) => i.headerName),
      ...(<any> FIRM_COLUMN_DEFS[1]).children.map((i: any) => i.headerName),
    ];
    const mappings = [
      ...(<any> FIRM_COLUMN_DEFS[0]).children.map((i: any) => i.field),
      ...(<any> FIRM_COLUMN_DEFS[1]).children.map((i: any) => i.field),
    ];

    const exportData: SummaryExportData[] = [
      {
        title: 'Firm',
        rowData: rowData.firm,
        columnLabels: [
          firstRowHeadings,
          secondRowHeadings,
        ],
        dataMappings: mappings,
      },
      {
        title: 'Peer Group',
        rowData: rowData.peerGroup,
        columnLabels: [
          firstRowHeadings,
          [...secondRowHeadings, 'Peer Group'],
        ],
        dataMappings: [...mappings, 'peerGroupId'],
      },
      {
        title: 'Industry',
        rowData: rowData.industry,
        columnLabels: [
          firstRowHeadings,
          secondRowHeadings,
        ],
        dataMappings: mappings,
      },
    ];

    const csvString = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvString, '\n');
    return formatted;
  }

  exportMsrbG32ReportSummaryData(rowData): string {
    const commonSecondColumns = [
      'Period',
      'Total',
      'Late',
      '% Late',
      'Industry % Late',
      'Total',
      'Late',
      '% Late',
      'Industry % Late',
    ];

    const exportData: SummaryExportData[] = [
      {
        title: 'Transactions',
        columnLabels: [
          [
            'Period',
            'Firm',
            '',
            '',
            'Industry',
            '',
            '',
            '',
            '',
            'Peer Group',
          ],
          [
            '',
            'Total',
            'Late',
            '% Late',
            '% Late',
            'Weighted % Late',
            'Median % Late',
            'Rank',
            'Population',
            'Peer Group',
            '% Late',
            'Weighted % Late',
            'Median % Late',
            'Rank',
            'Population',
          ],
        ],
        rowData: rowData.firmIndustry,
        dataMappings: [
          'periodStartDate',
          'totalFilingsNumber',
          'lateTotalFilingsNumber',
          'lateTotalFilingsPercent',
          'industryLateTotalFilingsPercent',
          'industryLateTotalFilingsWeightedPercent',
          'industryLateTotalFilingsMedianPercent',
          'industryTotalFilingsRankNumber',
          'industryPopulationCount',
          'peerGroupCount',
          'peerLateTotalFilingsPercent',
          'peerLateTotalFilingsWeightedPercent',
          'peerLateTotalFilingsMedianPercent',
          'peerTotalFilingsRankNumber',
          'peerPopulationCount',
        ]
      },
      {
        title: null,
        columnLabels: [
          [
            '',
            'Official Statement (OS) Submissions',
            '',
            '',
            '',
            'Preliminary OS Submissions',
          ],
          commonSecondColumns,
        ],
        rowData: rowData.officialStatement,
        dataMappings: [
          'periodStartDate',
          'totalOfficialStatementSubmissionsNumber',
          'lateOfficialStatementSubmissionstNumber',
          'lateOfficialStatementSubmissionsPercent',
          'industryLateOfficialStatementSubmissionsPercent',
          'totalPreliminaryOfficialStatementSubmissionsNumber',
          'latePreliminaryOfficialStatementSubmissionsNumber',
          'latePreliminaryOfficialStatementSubmissionsPercent',
          'industryLatePreliminaryOfficialStatementSubmissionsPercent',
        ]
      },
      {
        title: null,
        columnLabels: [
          [
            '',
            'Remarketing Supplements',
            '',
            '',
            '',
            'OS and Remarketing Amendments',
          ],
          commonSecondColumns,
        ],
        rowData: rowData.remarketing,
        dataMappings: [
          'periodStartDate',
          'totalRemarketingSupplementsNumber',
          'lateRemarketingSupplementsNumber',
          'lateRemarketingSupplementsPercent',
          'industryLateRemarketingSupplementsPercent',
          'totalOfficialStatementRemarketingSupplementsAmendmentNumber',
          'lateOfficialStatementRemarketingSupplementsAmendmentNumber',
          'lateOfficialStatementRemarketingSupplementsAmendmentPercent',
          'industryLateOfficialStatementRemarketingSupplementsAmendmentPercent',
        ]
      },
      {
        title: null,
        columnLabels: [
          [
            '',
            'Advance Refunding Documents',
            '',
            '',
            '',
            'Advance Refunding Amendments',
          ],
          commonSecondColumns,
        ],
        rowData: rowData.advanceRefunding,
        dataMappings: [
          'periodStartDate',
          'totalAdvanceRefundingDocumentsNumber',
          'lateAdvanceRefundingDocumentsNumber',
          'lateAdvanceRefundingDocumentsPercent',
          'industryLateAdvanceRefundingDocumentsPercent',
          'totalAdvanceRefundingDocumentsAmendmentNumber',
          'lateAdvanceRefundingDocumentsAmendmentNumber',
          'lateAdvanceRefundingDocumentsAmendmentPercent',
          'industryLateAdvanceRefundingDocumentsAmendmentPercent',
        ]
      },
      {
        title: null,
        columnLabels: [
          [
            '',
            'Municipal Fund Security Disclosures',
            '',
            '',
            '',
            'Municipal Fund Security Disclosure Supplements',
          ],
          commonSecondColumns,
        ],
        rowData: rowData.municipalFund,
        dataMappings: [
          'periodStartDate',
          'totalMunicipalFundSecurityDisclosuresNumber',
          'lateMunicipalFundSecurityDisclosuresNumber',
          'lateMunicipalFundSecurityDisclosuresPercent',
          'industryLateMunicipalFundSecurityDisclosuresPercent',
          'totalMunicipalFundSecurityDisclosuresSupplementNumber',
          'lateMunicipalFundSecurityDisclosuresSupplementNumber',
          'lateMunicipalFundSecurityDisclosuresSupplementPercent',
          'industryLateMunicipalFundSecurityDisclosuresSupplementPercent',
        ]
      },
      {
        title: null,
        columnLabels: [
          [
            '',
            '15c2-12 Exempt Filings with No OS or POS Submitted',
            '',
            '',
            '',
            'Cancellations',
          ],
          [
            'Period',
            'Total',
            'Late',
            '% Late',
            'Industry % Late',
            'Total',
            'Industry Total',
          ]
        ],
        rowData: rowData.exemptFilings,
        dataMappings: [
          'periodStartDate',
          'totalExemptFilingNumber',
          'lateExemptFilingNumber',
          'lateExemptFilingPercent',
          'industryLateExemptFilingPercent',
          'totalCancellationNumber',
          'industryLateCancellationNumber',
        ]
      },
    ];

    const csvString = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvString, '\n');
    return formatted;
  }

  exportTradesBelowSummaryData(rowData): string {
    const exportData: SummaryExportData[] = [
      {
        title: 'Transactions',
        rowData: rowData.transactions,
        columnLabels: [
          [
            '',
            'Firm',
            '',
            'Peer Group',
            '',
            '',
            'Industry',
          ],
          [
            'Period',
            'Total Transactions Count',
            'Customer Transactions Count',
            'Peer Group',
            'Peer Rank',
            'Peer Population',
            'Industry Rank',
            'Industry Population',
          ],
        ],
        dataMappings: [
          'periodStartDate',
          'totalTransactionCount',
          'customerTransactionCount',
          'peerGroupNumber',
          'peerRankNumber',
          'peerPopulationCount',
          'industryRankNumber',
          'industryPopulationCount',
        ]
      },
      {
        title: 'Trades Below Minimum Denomination',
        rowData: rowData.tradesBelowMinDenomination,
        columnLabels: [
          [
            '',
            'Firm',
            '',
            '',
            '',
            'Peer Group',
            '',
            '',
            'Industry',
          ],
          [
            'Period',
            'NIDS Count',
            'TR Count',
            'Unique Trade Alert Count',
            'Firm %',
            'Mean',
            'Median',
            'Rank',
            'Mean',
            'Median',
            'Rank',
          ],
        ],
        dataMappings: [
          'periodStartDate',
          'belowMinimumDenominationCount',
          'belowMinimumDenominationThomsonReutersCount',
          'belowMinimumDenominationUniqueCount',
          'belowMinimumDenominationPercent',
          'belowMinimumDenominationPeerMeanPercent',
          'belowMinimumDenominationPeerMedianPercent',
          'belowMinimumDenominationPeerRankNumber',
          'belowMinimumDenominationIndustryMeanPercent',
          'belowMinimumDenominationIndustryMedianPercent',
          'belowMinimumDenominationIndustryRankNumber',
        ]
      },
    ];

    const csvString = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvString, '\n');
    return formatted;
  }

  exportPrimaryOfferingSummaryData(rowData): string {
    const exportData: SummaryExportData[] = [
      {
        title: null,
        rowData: rowData.data,
        columnLabels: PRIMARY_OFFERING_DISCLOSURE_COLUMN_DEFS.map((i: any) => i.headerName),
        dataMappings: [
          'period',
          'totalSalesToCustomer',
          'salesInPrimaryOfferingDisclosurePeriod',
          'percentSalesInPrimaryOfferingDisclosurePeriod',
          'salesInPrimaryOfferingPeriodPriorToOSAvailabilityOnEMMA',
          'percentSalesInPrimaryOfferingPeriodPriorToOSAvailabilityOn',
        ],
      }
    ];

    const csvString = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvString, '\n');
    return formatted;
  }

  exportDueDiligenceSummaryData(rowData): string {
    // first, setup the master list where the csv string will be kept
    const massCsvStringList = [];

    // add the main headings
    const mainColumnsString = buildColumnLabelsString([
      PARENT_HEADERS,
      PARENT_HEADERS_L2,
    ]);
    massCsvStringList.push(mainColumnsString);

    // now loop through the first level of data
    for (const data of rowData) {
      // build the main row data csv string
      const mainRowDataString = buildRowDataString({
        rowData: [data],
        dataMappings: [
          'firm_periodStartDate',
          'firm_totalIssuanceCount',
          'firm_issuancePreviousLackingCurrentFinancialsCount',
          'firm_percentIssuancePreviousLackingCurrentFinancialsCount',
          'peer_peerGroupIdentifier',
          'peer_totalIssuanceCount',
          'peer_issuancePreviousLackingCurrentFinancialsCount',
          'peer_averageIssuancePreviousLackingCurrentFinancialsCount',
          'peer_medianIssuancePreviousLackingCurrentFinancialsCount',
          'industry_totalIssuanceCount',
          'industry_issuancePreviousLackingCurrentFinancialsCount',
          'industry_averageIssuancePreviousLackingCurrentFinancialsCount',
          'industry_medianIssuancePreviousLackingCurrentFinancialsCount',
        ]
      });
      massCsvStringList.push(mainRowDataString);

      // now, if there are submissions data, build it
      if (data.submissions) {
        const submissionsColumnsString = buildColumnLabelsString([
          '', // moving over 1 cell for visual aid; submissions under specific period
          ...CHILD_HEADERS
        ]);
        massCsvStringList.push(submissionsColumnsString);
        for (const submission of data.submissions) {
          const submissionDataString = buildRowDataString({
            rowData: [submission],
            dataMappings: [
              '',
              'submissionIdentifier',
              'issuerName',
              'issueDescription',
              'cusipIdentifier',
              'cusipClosingDate',
            ]
          });
          massCsvStringList.push(submissionDataString);

          // now, if there are underwriter details data, build it
          if (submission.underWriterDetails) {
            const underWriterDetailsColumnsString = buildColumnLabelsString([
              // moving over 6 cell for visual aid; underwriter details under specific submission ID
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              'Underwriter of Previously Issued CUSIP',
              'Submission Date of Previously Issued CUSIP',
              'Previously Issued CUSIP',
            ]);
            massCsvStringList.push(underWriterDetailsColumnsString);
            for (const underWriterDetail of submission.underWriterDetails) {
              // create copy
              const underWriterDetailCopy = { ...underWriterDetail };

              // prvlCusipIdentifier is a list of data that is in a single string,
              // separated by the new line character: \n
              // split it
              const cusipList = underWriterDetailCopy.prvlCusipIdentifier.split('\n');

              // the first one will go on the same line
              const firstCusip = cusipList.shift();
              underWriterDetailCopy.prvlCusipIdentifier = firstCusip;

              const underWriterDetailDataString = buildRowDataString({
                rowData: [underWriterDetailCopy],
                dataMappings: [
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  'underwriterPreviouslyIssuedCusipIdentifier',
                  'submissionDatePreviouslyIssuedCusipIdentifier',
                  'prvlCusipIdentifier',
                ]
              });
              massCsvStringList.push(underWriterDetailDataString);

              // now for the rest of the cusip
              const cusipsDataString = buildRowDataString({
                rowData: cusipList.map((cusip: string) => ({ prvlCusipIdentifier: cusip.trim() })),
                dataMappings: [
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  'prvlCusipIdentifier',
                ]
              });
              massCsvStringList.push(cusipsDataString);
            }
          }
        }
      }
    }

    const massCsvString = massCsvStringList.join('');
    const formatted = trimTrailingChar(massCsvString, '\n');
    return formatted;
  }
}
