import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AdminService } from 'src/app/services/admin.service';
import {
  ReportProblemData,
  REPORT_PROBLEMS_COL_DEFS,
  ReportProblemDataResponse
} from 'src/app/configs/model/report-problems.model';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { HelperService } from 'src/app/services/helper.service';
import { ReportProblemFormData } from 'src/app/configs/model/report-problem-form-data.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  IconDefinition,
  faStepBackward,
  faCaretLeft,
  faStepForward,
  faCaretRight
} from '@fortawesome/pro-solid-svg-icons';
import { ArrayPaginator } from 'src/app/shared/array-pagination';
import { ReportTypeEdition } from 'src/app/configs/model/admin.model';
import { UiWidgetsService } from 'src/app/services/ui-widgets.service';

@Component({
  selector: 'report-admin-report-problems',
  templateUrl: './report-problems.component.html',
  styleUrls: ['./report-problems.component.scss']
})
export class AdminReportProblemsComponent implements OnInit {
  title = 'Report Problems';

  // prev
  faStepBackward: IconDefinition = faStepBackward;
  faCaretLeft: IconDefinition = faCaretLeft;
  // next
  faStepForward: IconDefinition = faStepForward;
  faCaretRight: IconDefinition = faCaretRight;

  reportProblemId;
  rowData: ReportProblemData[];
  columnDefs = REPORT_PROBLEMS_COL_DEFS;
  error;

  reportTypesList;
  priorEditingProblem: ReportProblemData;

  paginator;
  get paginatorState() {
    return (this.paginator && this.paginator.getState()) || {};
  }

  constructor(
    public activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    private uiWidgetsService: UiWidgetsService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.fetchReportTypesEditions();
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
  }

  handleParamsChange(params: Params) {
    this.reportProblemId = params.reportProblemId;
    // if the route has a specific report problem id, load that one only
    // else, load all.
    if (this.reportProblemId) {
      this.title = 'Report Problem';
      this.fetchReportProblem();
    } else {
      this.title = 'Report Problems';
      this.fetchReportProblems();
    }
    this.titleService.setTitle(`Admin - ${this.title}`);
  }

  setIsVisible(data: ReportProblemData | ReportProblemData[]) {
    // this is for the checkbox in the UI
    const isArray = Array.isArray(data);
    if (isArray) {
      (<Array<ReportProblemData>>data).forEach(problem => {
        problem.isVisible = !problem.invalidFlag;
      });
    } else {
      (<ReportProblemData>data).isVisible = !(<ReportProblemData>data).invalidFlag;
    }
  }

  fetchReportProblems() {
    this.adminService.getAdminReportProblems().subscribe(
      (response: ReportProblemDataResponse) => {
        this.setIsVisible(response.reportProblemDetails);
        this.setPaginator(response.reportProblemDetails);
      },
      (error: HttpErrorResponse) => {
        this.error = error;
        if (error.status === 404) {
          this.uiWidgetsService.showErrorSnackbar('No report problems found...');
        } else {
          this.uiWidgetsService.showErrorSnackbar('Could not load data...');
        }
      }
    );
  }

  fetchReportProblem() {
    this.adminService.getAdminReportProblemById(this.reportProblemId).subscribe(
      (response: ReportProblemData) => {
        this.setIsVisible(response);
        this.setPaginator([response]);
      },
      (error: HttpErrorResponse) => {
        this.error = error;
        if (error.status === 404) {
          this.uiWidgetsService.showErrorSnackbar('No report problem found...');
        } else {
          this.uiWidgetsService.showErrorSnackbar('Could not load data...');
        }
      }
    );
  }

  setPaginator(list: ReportProblemData[]) {
    this.rowData = list;
    this.paginator = new ArrayPaginator(list);
    this.paginator.setSize(20);
  }

  fetchReportTypesEditions() {
    this.adminService.getAdminReportTypesEditions().subscribe(
      (response: ReportTypeEdition[]) => {
        this.reportTypesList = response;
      }
    );
  }

  onHideToggle(event: MatCheckboxChange, problem: ReportProblemData) {
    const checkBox = event.source;
    const priorState = problem.isVisible;
    // keep the checkbox state the same until the update is complete
    checkBox.checked = priorState;
    // switch the invalid flag value
    const newFlagValue = (problem.invalidFlag === 1 || problem.invalidFlag === 2)
      ? 0
      : 1;
    const updates: ReportProblemFormData = {
      reportType: problem.reportDisplayName,
      reportPeriodDate: problem.reportPeriodDate,
      reportProblemId: problem.reportProblemId,
      reportVersion: problem.reportVersion,
      message: problem.message,
      invalidFlag: newFlagValue // the only change
    };
    this.adminService.updateReportProblem(updates).subscribe(
      (response: ReportProblemData) => {
        this.handleReportProblemUpdateResponse(problem, response);
        checkBox.checked = problem.isVisible;
      },
      (error: HttpErrorResponse) => {
        this.uiWidgetsService.showErrorSnackbar(
          'Could not save edits. Try again later.'
        );
      }
    );
  }

  handleReportProblemUpdateResponse(
    problem: ReportProblemData,
    response: ReportProblemData
  ) {
    problem.isEditing = false;
    problem.isVisible = !response.invalidFlag;
    problem.lastUpdated = response.lastUpdated;
    problem.message = response.message;
    problem.invalidFlag = response.invalidFlag;
    problem.userUpdated = response.userUpdated;
    this.priorEditingProblem = null; // just in case the user had a form open during this action
    this.uiWidgetsService.showSuccessSnackbar('Edits Saved Successfully!');
  }

  setEditReport(problem: ReportProblemData) {
    if (problem === this.priorEditingProblem) {
      this.priorEditingProblem.isEditing = false;
      this.priorEditingProblem = null;
      return;
    } else {
      if (this.priorEditingProblem) {
        this.priorEditingProblem.isEditing = false;
        this.priorEditingProblem = null;
      }
      problem.isEditing = true;
      this.priorEditingProblem = problem;
    }
  }

  onFormSubmitForProblemUpdate(event: ReportProblemFormData) {
    this.adminService.updateReportProblem(event).subscribe(
      (response: ReportProblemData) => {
        this.handleReportProblemUpdateResponse(this.priorEditingProblem, response);
      },
      (error: HttpErrorResponse) => {
        this.uiWidgetsService.showErrorSnackbar(
          'Could not save edits. Try again later.'
        );
      }
    );
  }
}
