import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faQuestionCircle,
  IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { FinraGridClickableCellComponent } from 'src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelperService } from 'src/app/services/helper.service';
import { ReportService } from 'src/app/services/report.service';
import { BeastClickActions } from '../../../../../../enums/beast.enum';

@Component({
  selector: 'late-filing-report',
  templateUrl: './late-filing-report.component.html',
  styleUrls: ['./late-filing-report.component.scss']
})
export class LateFilingReportComponent implements OnInit {
  @Input() rowData;
  @Input() columnDefs;
  @Input() title;
  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  @Input() downloadFileName;
  @Input() reportId;
  @Input() viewName;
  @Input() tableName;
  @Input() autoGroupColumnDef;
  @Input() helpLink;
  @Output() reportDetails: EventEmitter<any> = new EventEmitter();

  faQuestionCircle: IconDefinition = faQuestionCircle;

  constructor(
    private helperService: HelperService,
    private baseReportService: ReportService,
    private beastService: BeastService,
  ) {}
  ngOnInit() {
    if (this.tableName === 'lateFilingAnalysis') {
      this.addDetailLink();
    }
  }

  addDetailLink() {
    this.columnDefs.forEach(col => {
      if (col.isDetailLink) {
        col['cellRendererFramework'] = FinraGridClickableCellComponent;
        col['cellRendererFrameworkOptions'] = {
          isClickable: (params: any) => {
            return true;
          },
          buttonLabel: 'Details',
          handleClick: (params: any) => {
            this.getDetailReport(params);
          }
        };
      }
    });
  }

  getDetailReport(params) {
    const reportDate = new Date(params.value + ' 01')
      .toISOString()
      .slice(0, 10);

    this.reportDetails.emit(reportDate);
  }

  openHelp() {
    window.open(this.helpLink, '_blank');
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}
