import { Component, OnInit, Input } from '@angular/core';
import {
  IconDefinition,
  faChevronRight,
  faChevronDown
} from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'disclosure-timeliness',
  templateUrl: './disclosure-timeliness.component.html',
  styleUrls: ['./disclosure-timeliness.component.scss']
})
export class DisclosureTimelinessComponent implements OnInit {
  @Input() reportDate;
  @Input() rowData;

  title = 'FIRM TOTALS - 4530 DISCLOSURE FILINGS';
  parentHeaders = [
    'Period',
    'Associated Individuals',
    'Initial Filings Submitted',
    'Late Filings Submitted',
    '% Filings Late',
    'Amended Filings Submitted'
  ];
  childHeaders = [
    'Filing Number',
    'Discovery Date',
    'Submission Date',
    'Disclosure Event Type'
  ];

  faChevronRight: IconDefinition = faChevronRight;
  faChevronDown: IconDefinition = faChevronDown;

  constructor() {}

  ngOnInit() {}
}
