import { FinraGridClickableCellComponent } from 'src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component';
import { addFinraGridColumnId, getMonthYrDate, finraGridQuarterDateFormatter } from 'src/app/shared/utils/utils';
import { LoprDailyReportsByTypeId } from './lopr/lopr.model';
import { quarterlyReportTypeIds } from './report-status.model';
import { Periodicities, ReportInstanceMetadata } from './reports.model';

export const getUnreadReportsColumnDefs = (
  options: {
    readReportClickHandler: (arg) => void,
    periodicities: Periodicities[]
  }
) => addFinraGridColumnId([
  {
    headerName: '',
    width: 30,
    field: 'reportConfiguration.reportDisplayName',
    isDetailLink: true,
    cellRendererFramework: FinraGridClickableCellComponent,
    cellRendererFrameworkOptions: {
      buttonLabel: 'Read Now',
      isClickable: (params) => true,
      handleClick: options.readReportClickHandler
    }
  },
  {
    headerName: 'Report',
    field: 'reportConfiguration.reportDisplayName',
    width: 200,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'REPORT'
    }
  },
  {
    headerName: 'Firm Identifier',
    field: 'reportFirmId',
    width: 100,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'FIRMID'
    }
  },
  {
    headerName: 'Period',
    field: 'reportPeriodDate',
    width: 100,
    valueFormatter: (params) => {
      const typeId = (<ReportInstanceMetadata> params.data).reportConfiguration.reportType.reportTypeId;
      const isDaily = LoprDailyReportsByTypeId.includes(typeId);
      const isQuarterly = quarterlyReportTypeIds.includes(typeId);
      return isDaily
        ? getMonthYrDate(params.value, true)
        : isQuarterly
          ? finraGridQuarterDateFormatter(params)
          : getMonthYrDate(params.value, false);
    },
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'PERIOD'
    }
  },
  {
    headerName: 'Type',
    field: 'reportConfiguration.reportType.reportPeriodicityId',
    valueFormatter: (params: any) => {
      const typeId = (<ReportInstanceMetadata> params.data).reportConfiguration.reportType.reportTypeId;
      const isDaily = LoprDailyReportsByTypeId.includes(typeId);
      const isQuarterly = quarterlyReportTypeIds.includes(typeId);
      const periodType = isDaily ? 'Daily' : isQuarterly ? 'Quarterly' : 'Monthly';
      return periodType;
    },
    width: 100,
    headerComponent: 'finraGridHeaderComponent',
    headerComponentParams: {
      enableSorting: true,
      sortField: 'TYPE'
    }
  }
]);
