<div>
  <div
    [hidden]="!params.enableMenu"
    class="customHeaderMenuButton"
    (click)="onMenuClick()"
  >
    <i class="{{ 'fa ' + params['menuIcon'] }}"></i>
  </div>
  <div class="customHeaderLabel" (click)="toggleSorting()">
    {{ params.displayName }}
  </div>
  <div
    [hidden]="!params.enableSorting || this.sorted !== 'desc'"
    class="customSortDownLabel active"
  >
    <i class="fa fa-arrow-down"></i>
  </div>
  <div
    [hidden]="!params.enableSorting || this.sorted !== 'asc'"
    class="customSortUpLabel active"
  >
    <i class="fa fa-arrow-up"></i>
  </div>
</div>
