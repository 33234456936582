import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public show = new BehaviorSubject(false);
  constructor() {}
  // private loaderSubject = new Subject<LoaderState>();
  // loaderState = this.loaderSubject.asObservable();

  // constructor() { }

  // show() {
  //   this.loaderSubject.next(<LoaderState>{show: true});
  // }
  // hide() {
  //   this.loaderSubject.next(<LoaderState>{show: false});
  // }
}
