import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { LoaderService } from './loader.service';
import { Observable, of } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import {
  RequestCacheService,
  RequestCacheServiceEntry
} from './request-cache.service';
import { EwsService } from './ews.service';
import { SessionService } from './session.service';
import * as Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  private baseUrl: string;
  private isEwsReportCenter: boolean;

  constructor(
    private loaderService: LoaderService,
    private cache: RequestCacheService,
    private ewsService: EwsService,
    private sessionService: SessionService,
    @Inject('localUserTestAccount') private localUserTestAccount,
    @Inject('environment') private environment
  ) {
    this.baseUrl = this.environment.ddwaBaseUrl;
    this.isEwsReportCenter =
      'true' ===
      this.environment.isEwsReportCenter
        .toString()
        .toLowerCase()
        .trim();
  }

  addRequest(req: HttpRequest<any>) {
    // this query params is for ignoring the request from being in the list.
    // the request will still be sent but it won't be counted in the list
    this.requests.push(req);
    this.loaderService.show.next(this.requests.length > 0);
  }

  removeRequest(req1: HttpRequest<any>) {
    const i = this.requests.indexOf(req1);
    if (i >= 0) {
      this.requests.splice(i, 1); // This removes the request from our array
    }
    // Let's tell our service of the updated status
    this.loaderService.show.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.sessionService.keepAlive();

    // Modify request
    // e.g. 'Content-Type':  'application/json',
    req = req.clone({
      withCredentials: true
    });

    if (
      !req.urlWithParams.toLowerCase().includes('//sso.') &&
      req.responseType !== 'text'
    ) {
      if (!req.urlWithParams.toLowerCase().includes('bypassheaders')) {
        if (!req.headers.has('Content-Type')) {
          req = req.clone({
            headers: req.headers.append('Content-Type', 'application/json')
          });
        }
        if (!req.headers.has('Accept')) {
          req = req.clone({
            headers: req.headers.append('Accept', 'application/json')
          });
        }
      }
    }

    req = this.addFinraAdminToRequest(req);

    const shouldNotCache = req.urlWithParams.includes('cache=false') || req.method !== 'GET';
    if (shouldNotCache) {
      return this.sendRequest(req, next, this.cache, false);
    }

    const cachedResponse = this.cache.get(req);

    if (cachedResponse == null) {
      return this.sendRequest(req, next, this.cache, true);
    } else if (cachedResponse.result === undefined) {
      const obs = new Observable<HttpEvent<any>>(observer => {
        // When the consumer unsubscribes, clean up data ready for next subscription.
        cachedResponse.push(observer);
        return { unsubscribe: () => {} };
      });

      return obs;
    } else {
      return of(cachedResponse.result);
    }
  }

  private sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCacheService,
    shouldCache: boolean
  ): Observable<HttpEvent<any>> {
    const cacheEntry = new RequestCacheServiceEntry();

    const xsrfCookie = Cookies.get(`xsrf-token`) || Cookies.get(`XSRF-TOKEN`);
    if (xsrfCookie) {
      req = req.clone({
        headers: req.headers.set(`X-XSRF-TOKEN`, xsrfCookie)
      });
    }

    /**
     * for announcements use
     * dxt notifications api does not allow certain headers for certain endpoints.
     * remove headers based on ignoreHeaders url query param; should be comma separated headers.
     * used to override the defaults when needed
     *
     * example: ignoreHeaders=Accept,Content-Type,etc
     */
    const ignoreHeaders: boolean = req.urlWithParams.includes(`ignoreHeaders=`);
    if (ignoreHeaders) {
      // remove the headers
      const headersList = req.params.get('ignoreHeaders').split(`,`);
      for (const header of headersList) {
        req = req.clone({
          headers: req.headers.delete(header)
        });
      }
      // remove the query param; it is unknown to the api and it will give an error response
      req = req.clone({
        params: req.params.delete(`ignoreHeaders`)
      });
    }

    if (shouldCache) {
      cache.put(req, cacheEntry);
    }

    const ignoreList = req.params.has('ignorelist');
    if (ignoreList) {
      req = req.clone({
        params: req.params.delete('ignorelist')
      });
    } else {
      this.addRequest(req);
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            if (shouldCache) {
              cacheEntry.result = event;
            }
          }
        },
        (err: HttpErrorResponse) => {
          // If call is to ddwa API and it fails
          // we should call ews to see if the session is alive
          // if its not we should redirect to ews
          if (req.url.startsWith(this.baseUrl)) {
            if (this.isEwsReportCenter) {
              const x = this.ewsService.assertEwsSession();
            } else {
              // The following is causing the browser to reload when the server returns 504
              //if (err.status === 0) {
              //window.location.reload();
              //}
            }
          }

          this.removeRequest(req);

          if (shouldCache) {
            cacheEntry.error = err;
            cache.remove(req);
          }

          /*
            every component will now be responsible for
            rendering their own alert banner in their view,
            including the message to display.
          */
          // this.alertService.show.next({ state: true, message: errMsg });

          return of(err);
        }
      ),
      finalize(() => {})
    );
  }

  /**
   * This method is only for local development only.
   * If the front end is pointing to a local back end server that is running,
   * you will need to authenicate with a test account since the FIP redirect
   * will not happen locally.
   * @param req
   */
  private addFinraAdminToRequest(req: HttpRequest<any>) {
    const shouldDecorateHeaders = (
      this.environment.isEwsReportCenter === false &&
      this.baseUrl.startsWith('http://localhost:8080')
    );
    if (!shouldDecorateHeaders) {
      return req;
    }

    const user = this.localUserTestAccount;
    const testIssoCredentialsBase64 = btoa(`${user.username}:${user.password}`);
    const authorization = `Basic ${testIssoCredentialsBase64}`;

    let newHeaders = new HttpHeaders()
      .set(`Authorization`, authorization)
      .append(`memberOf`, user.memberOf)
      .append(`userPrincipalName`, user.userPrincipalName)
      .append(`ct-remote-user`, user.ctremoteuser)
      .append(`ctemail`, user.ctemail)
      .append(`ctfirstname`, user.ctfirstname)
      .append(`ctlastname`, user.ctlastname);

    const shouldAddEwsRoles = user.ewsRoles && user.ewsRoles.length;
    if (shouldAddEwsRoles) {
      for (const role of user.ewsRoles) {
        newHeaders = newHeaders.append(role, `use`);
      }
    }

    const hasExtraHeaders = user.headers && user.headers.length;
    const extraHeaders = (<{ name: string; value: any; }[]> user.headers);
    if (hasExtraHeaders) {
      for (const header of extraHeaders) {
        newHeaders = newHeaders.append(header.name, header.value);
      }
    }

    const requestClone = req.clone({
      withCredentials: true,
      headers: newHeaders
    });
    return requestClone;
  }
}
