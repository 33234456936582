import {
  buildColumnLabelsString,
  sort_distinct,
  buildRowDataString,
  saveCsv
} from 'src/app/shared/utils/utils';
import {
  GROUP_NAMES,
  EquityReportShortNames
} from 'src/app/configs/model/equities-report.model';



/** Summary Export Config */

export const commonGroupKeysOrder = ['_stray', 'peer', 'industry'];

export const marketTypes1 = ['_stray', 'marked', 'unmarked', 'improperlyMarked'];
export const marketTypes2 = ['_stray', 'tradeThroughStatistics', 'tradeWithoutException', 'tradeWithException'];
export const marketTypes3 = ['_stray', '0_20', '21_45', '46_60', '61'];
export const marketTypes4 = ['_stray', 'all', 'normal', 'preopen', 'infoAlert'];

export const MarketTypesConfig = {
  [EquityReportShortNames.REPORTING_FIRM_10_SECOND_COMPLIANCE]: marketTypes1,
  [EquityReportShortNames.REG_NMS_TRADE_THROUGH]: marketTypes2,
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS]: null,
  [EquityReportShortNames.EXECUTING_FIRM_10_SECOND_COMPLIANCE]: marketTypes1,
  [EquityReportShortNames.CONTRA_REPORTING_FIRM_20_MINUTE_COMPLIANCE]: marketTypes3,
  [EquityReportShortNames.CONTRA_EXECUTING_FIRM_20_MINUTE_COMPLIANCE]: marketTypes3,
  [EquityReportShortNames.BEST_EXECUTION_OUTSIDE_OF_THE_INSIDE]: marketTypes4,
  [EquityReportShortNames.FIRM_SUMMARY_SCORECARD]: null,
  [EquityReportShortNames.OATS_COMPLIANCE_MONTHLY]: null,
};

export const regNmsExceptionTypes = [
  '_stray',
  'unspecified',
  'nonStandard',
  'selfHelp',
  'inboundISO',
  'outboundISO',
  'qualifiedContingent',
  'subpenny',
  'stoppedStock',
  'benchmark',
  'errorCorrection',
  'printProtection',
  'unknown',
];

export function marketTypeExport(
  rowData,
  columnDefs,
  marketTypes,
  isBestExec: boolean = false
): string {
  const massCsvDataStringList = [];

  const mainColumnsString = buildColumnLabelsString([
    'Market Type',
    'Group',
    ...columnDefs.slice(2).map((i: any) => i.headerName)
  ]);
  massCsvDataStringList.push(mainColumnsString);

  const marketTypesObj = sort_distinct(rowData, 'marketType', true);
  const marketTypesList = marketTypes || Object.keys(marketTypesObj).sort();

  for (const marketType of marketTypesList) {
    const marketTypeDataList = marketTypesObj[marketType];
    if (!marketTypeDataList || !marketTypeDataList.length) {
      continue;
    }
    if (GROUP_NAMES[marketType]) {
      const marketTypeHeading = buildColumnLabelsString([
        GROUP_NAMES[marketType]
      ]);
      massCsvDataStringList.push(marketTypeHeading);
    }

    const groupsObj = sort_distinct(marketTypeDataList, 'group', true);
    for (const groupsKey of commonGroupKeysOrder) {
      const groupsList = groupsObj[groupsKey];
      if (!groupsList || !groupsList.length) {
        continue;
      }
      if (GROUP_NAMES[groupsKey]) {
        const groupHeading = buildColumnLabelsString([
          '',
          GROUP_NAMES[groupsKey]
        ]);
        massCsvDataStringList.push(groupHeading);
      }
      if (groupsList.length) {
        const marketTypeWithGroupDataString = buildRowDataString({
          rowData: groupsList,
          dataMappings: isBestExec
            ? [
              '',
              '',
              'displayName',
              'column0',
              'column1',
              'column2',
              'column3',
              'column4',
              'column5',
            ]
            : [
              '',
              '',
              'displayName',
              'column1',
              'column2',
              'column3',
              'column4',
              'column5',
              'column0',
            ]
        });
        massCsvDataStringList.push(marketTypeWithGroupDataString);
      }
    }
  }

  const massCsvString = massCsvDataStringList.join('');
  return massCsvString;
}

export function executionTypeExport(
  rowData,
  columnDefs,
  marketTypes,
  exceptionsTypes,
): string {
  const massCsvDataStringList = [];

  const mainColumnsString = buildColumnLabelsString([
    'Market Type',
    'Exception Type',
    'Group',
    ...columnDefs.slice(3).map((i: any) => i.headerName)
  ]);
  massCsvDataStringList.push(mainColumnsString);

  const marketTypesObj = sort_distinct(rowData, 'marketType', true);
  const marketTypesList = marketTypes || Object.keys(marketTypesObj).sort();

  for (const marketType of marketTypesList) {
    const marketTypeDataList = marketTypesObj[marketType];
    if (!marketTypeDataList || !marketTypeDataList.length) {
      continue;
    }
    if (GROUP_NAMES[marketType]) {
      const marketTypeHeading = buildColumnLabelsString([
        GROUP_NAMES[marketType]
      ]);
      massCsvDataStringList.push(marketTypeHeading);
    }

    const exceptionsObj = sort_distinct(marketTypeDataList, 'exceptionType', true);
    const exceptionsTypesList = exceptionsTypes || Object.keys(exceptionsObj).sort();

    for (const exceptionKey of exceptionsTypesList) {
      const exceptionTypeDataList = exceptionsObj[exceptionKey];
      if (!exceptionTypeDataList || !exceptionTypeDataList.length) {
        continue;
      }
      if (GROUP_NAMES[exceptionKey]) {
        const exceptionTypeHeading = buildColumnLabelsString([
          '',
          GROUP_NAMES[exceptionKey]
        ]);
        massCsvDataStringList.push(exceptionTypeHeading);
      }

      const groupsObj = sort_distinct(exceptionTypeDataList, 'group', true);
      for (const groupsKey of commonGroupKeysOrder) {
        const groupsList = groupsObj[groupsKey];
        if (!groupsList || !groupsList.length) {
          continue;
        }
        if (GROUP_NAMES[groupsKey]) {
          const groupHeading = buildColumnLabelsString([
            '',
            '',
            GROUP_NAMES[groupsKey]
          ]);
          massCsvDataStringList.push(groupHeading);
        }
        if (groupsList.length) {
          const marketTypeWithGroupDataString = buildRowDataString({
            rowData: groupsList,
            dataMappings: [
              '',
              '',
              '',
              'displayName',
              'column0',
              'column1',
              'column2',
              'column3',
              'column4',
              'column5',
            ],
          });
          massCsvDataStringList.push(marketTypeWithGroupDataString);
        }
      }
    }
  }

  const massCsvString = massCsvDataStringList.join('');
  return massCsvString;
}

export function groupTypeExport(
  rowData,
  columnDefs,
  sortProp,
  groupKeys = null,
): string {
  const massCsvDataStringList = [];

  const mainColumnsString = buildColumnLabelsString(
    columnDefs.map((i: any) => i.headerName)
  );
  massCsvDataStringList.push(mainColumnsString);

  const groupsObj = sort_distinct(rowData, sortProp, true);
  const groupKeysList = groupKeys || Object.keys(groupsObj).sort();

  for (const groupsKey of groupKeysList) {
    const groupsList = groupsObj[groupsKey];
    const noGroupListData = !groupsList || !groupsList.length;
    if (noGroupListData) {
      continue;
    }

    const useGroupName = GROUP_NAMES[groupsKey] || groupsKey;
    const groupHeading = buildColumnLabelsString([ useGroupName ]);
    massCsvDataStringList.push(groupHeading);

    const groupDataString = buildRowDataString({
      rowData: groupsList,
      dataMappings: [
        '',
        ...columnDefs.slice(1).map((i: any) => i.field)
      ],
    });
    massCsvDataStringList.push(groupDataString);
  }

  const massCsvString = massCsvDataStringList.join('');
  return massCsvString;
}
