import { Injectable } from '@angular/core';
import { LoprListedResponse } from 'src/app/configs/model/lopr/lopr-listed.model';
import { createLineChartData, sort } from '../shared/utils/utils';
import { DatePipe } from '@angular/common';
import {
  LoprReportTypes,
  ALL_COLUMN_DEFINITIONS,
} from 'src/app/configs/model/lopr/lopr.model';
import { GET_OTC_COL_DEFS } from 'src/app/configs/model/lopr/lopr-otc.model';
import { ReportPageMetadataInfo, ReportService } from './report.service';
import { HelperService } from './helper.service';



@Injectable({
  providedIn: 'root'
})
export class LoprService {
  constructor(
    private datePipe: DatePipe,
    private baseReportService: ReportService,
    private helperService: HelperService,
  ) {}

  getColumnDefs(
    reportTypeId: LoprReportTypes,
    reportName: string,
    reportPageMetadataInfo: ReportPageMetadataInfo,
    onReportDetails: (arg: any) => void
  ) {
    switch (reportTypeId) {
      case LoprReportTypes.INTRUSION_DETECTION:
      case LoprReportTypes.LISTED: {
        return ALL_COLUMN_DEFINITIONS[reportName];
      }

      // this case requires code to be dynamic since there are buttons that triggers downloads
      case LoprReportTypes.OVER_THE_COUNTER: {
        const getDetailDataCallback = async (params, viewName) => {
          // get the report
          const report = this.helperService.getReportFromListByPeriod(
            params.data.positionDate,
            reportPageMetadataInfo.reportInstanceMetadatas
          );
          const reportId = report.reportId;
          // trigger the download
          onReportDetails({
            report,
            reportId,
            viewName,
            reportDate: params.data.positionDate
          });
        };

        const otcColDefs = GET_OTC_COL_DEFS(getDetailDataCallback);

        const columnDefs = {
          otc: otcColDefs
        };
        return columnDefs;
      }
    }
  }

  formatResponse(response, reportTypeId: LoprReportTypes) {
    switch (reportTypeId) {
      case LoprReportTypes.LISTED: {
        return this.formatLoprListedResponse(response);
      }
      case LoprReportTypes.OVER_THE_COUNTER: {
        return this.formatLoprOtcResponse(response);
      }
      case LoprReportTypes.INTRUSION_DETECTION: {
        return this.formatLoprIntrusionDetectionResponse(response);
      }
    }
  }

  /** listed report methods */

  formatLoprListedResponse(response: LoprListedResponse) {
    return {
      listed: response.summary
    };
  }

  /** Over the counter methods */

  formatLoprOtcResponse(response) {
    // Sort chart data ASC by positionDate
    const dataList =
      response.summary.length > 1
        ? sort(response.summary, 'positionDate', false, false)
        : [...response.summary];

    // then, transform the response to work with the chart
    const createLineData = (dataName, dataProp) => {
      return {
        name: dataName,
        series: dataList.map(data => ({
          name: this.datePipe.transform(data.positionDate, 'MM/dd/yyyy'),
          value: parseInt(data[dataProp], 10)
        }))
      };
    };

    const loprChartRowData = [
      createLineData(
        'Total Exceptions Count',
        'exceptionCount'
      ),
      //
      createLineData(
        'Accounts Acting In Concert',
        'accountsActingConcertCount'
      ),
      createLineData(
        'Unequal Long and Short Positions',
        'unequalShortLongPositionsCount'
      ),
      createLineData(
        'Address without Numbers',
        'addressWithOutNumberCount'
      ),
      createLineData(
        'Incorrect City or State',
        'incorrectCityStateCount'
      )
    ];

    // Sort table data DESC by positionDate
    const tableData = dataList.length > 1
        ? sort(dataList, 'positionDate', true, false)
        : dataList;

    // now return an object for the tables
    return {
      chartData: loprChartRowData,
      otc: tableData,
    };
  }

  /** New Intrusion Detection methods */

  formatLoprIntrusionDetectionResponse(response) {
    // Sort chart data ASC by exceptionReportDate for the chart data
    const dataList = response.summary.length > 1
      ? sort(response.summary, 'exceptionReportDate', false, false)
      : [...response.summary];

    const formatSeriesName = (data) => this.datePipe.transform(data.exceptionReportDate, 'MM/dd/yyyy');

    const loprChartRowData = [
      createLineChartData(
        dataList,
        'Potential Profit',
        'profitExceptionCount',
        formatSeriesName
      ),
      createLineChartData(
        dataList,
        'Potential Victim',
        'harmExceptionCount',
        formatSeriesName
      )
    ];

    // Sort table data DESC by exceptionReportDate for the table
    const tableData = dataList.length > 1
      ? sort(dataList, 'exceptionReportDate', true, false)
      : dataList;

    // now return an object for the tables
    return {
      chartData: loprChartRowData,
      intrusion_detection: tableData,
    };
  }
}
