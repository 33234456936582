import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'problem-message',
  templateUrl: './problem-message.component.html',
  styleUrls: ['./problem-message.component.scss']
})
export class ProblemMessageComponent implements OnChanges {
  messages;
  errorMessage;

  @Input() reportId;

  constructor(private baseReportService: ReportService) {}

  ngOnChanges(changes: SimpleChanges) {
    // Use switch case to monitor changes on multiple @Input() properties.
    this.getProblemMessage(this.reportId);
  }

  getProblemMessage(reportId) {
    this.baseReportService.getProblemMessage(reportId).subscribe(
      data => {
        this.messages = data;
        this.errorMessage = null;
      },
      error => {
        this.messages = null;
        if (error.status !== 500) {
          this.errorMessage = null;
        } else {
          this.errorMessage =
            'Something went wrong while loading the report message...';
        }
      }
    );
  }
}
