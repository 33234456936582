import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinraInfoModalData } from 'src/app/configs/model/finra-info-modal.model';


@Component({
  selector: 'finra-info-modal',
  templateUrl: './finra-info-modal.component.html',
  styleUrls: ['./finra-info-modal.component.scss']
})
export class FinraInfoModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FinraInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FinraInfoModalData
  ) {}

  ngOnInit() {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
