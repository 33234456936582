import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faDownload,
  faQuestionCircle,
  IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { CorpFinService } from 'src/app/services/corp-fin.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { BeastClickActions } from '../../../../../../enums/beast.enum';

@Component({
  selector: 'private-placement-filing-timeliness-report',
  templateUrl: './private-placement-filing-timeliness-report.component.html',
  styleUrls: ['./private-placement-filing-timeliness-report.component.scss']
})
export class PrivatePlacementFilingTimelinessReportComponent implements OnInit {
  isBuildingPdf: boolean = false;

  // Icons
  faQuestionCircle: IconDefinition = faQuestionCircle;
  faDownload: IconDefinition = faDownload;

  @Input()
  reportInstanceMetadata: ReportInstanceMetadata;

  @Input()
  reportPeriodDate: string;

  _columnDefs = {};

  @Input()
  get columnDefs(): object {
    return this._columnDefs;
  }

  set columnDefs(columnDefs: object) {
    this._columnDefs = columnDefs;
  }

  @Input() title;

  @Input() ruleData: object;
  @Input() chartRuleData: object;

  _rowData: object[];

  @Input()
  get rowData(): object[] {
    return this._rowData;
  }

  set rowData(rowData: object[]) {
    this._rowData = rowData;
  }

  @Input() rules;
  viewType = 'table';

  gridOptions = {
    groupIncludeTotalFooter: false,
    headerHeight: 48,
    groupHeaderHeight: 120
  };

  @Output() reportDetails: EventEmitter<any> = new EventEmitter();

  constructor(
    private corpFinService: CorpFinService,
    private helpLinksService: HelpLinksService,
    public datepipe: DatePipe,
    private beastService: BeastService,
  ) {
    this.corpFinService.setViewType('table');
  }

  ngOnInit() {
    this.corpFinService.getViewTypeSubject().subscribe((value: string) => {
      this.viewType = value;
    });
  }

  onReportDetails(rule) {
    this.reportDetails.emit(rule);
  }

  hasRuleData(rule) {
    const hasRule = (
      this.ruleData[rule] &&
      this.ruleData[rule].findIndex(x => !!x['ruleDescription']) > -1
    );
    return hasRule;
  }

  switchView(viewType) {
    this.corpFinService.setViewType(viewType);
  }

  showView(viewType) {
    const currentViewType = this.corpFinService.getViewType();
    return ['all', viewType].includes(currentViewType);
  }

  addPercent(value) {
    return value + '%';
  }

  openHelp(rule) {
    window.open(this.helpLinksService.CORP_FIN_PRIVATE_PLACEMENT_RULES[rule], '_blank');
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}