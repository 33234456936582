import { Component, OnInit, Input } from '@angular/core';

export enum FinraLoadingSpinnerSizes {
  SMALL = 25,
  MEDIUM = 50,
  LARGE = 75,
  X_LARGE = 100,
}

export enum FinraLoadingSpinnerPadding {
  SMALL = 10,
  MEDIUM = 15,
  LARGE = 20,
  X_LARGE = 25,
}

@Component({
  selector: 'finra-loading-spinner',
  templateUrl: './finra-loading-spinner.component.html',
  styleUrls: ['./finra-loading-spinner.component.scss']
})
export class FinraLoadingSpinnerComponent implements OnInit {
  @Input() size: FinraLoadingSpinnerSizes | number = FinraLoadingSpinnerSizes.SMALL;
  @Input() padding: FinraLoadingSpinnerPadding | number = FinraLoadingSpinnerPadding.SMALL;

  constructor() {}

  ngOnInit() {}
}