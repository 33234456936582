import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { NavItem } from 'src/app/configs/model/sidenavigation';

/**
 * This service helps control the state of the side navigation
 * by implementing a subject which allows any component
 * at any level of the entire application to tell the sidenav
 * what state it should be in.
 *
 * The sidenav will listen/subscribe to the subject and listen for
 * data that tells it what to render.
 */

export interface SidenavStateChange {
  navigationItem: NavItem;
  child: any;
}


@Injectable({
  providedIn: 'root'
})
export class SidenavigationService {
  private baseUrl: string;
  private subject: Subject<SidenavStateChange> = new Subject<SidenavStateChange>();

  constructor(
    @Inject('environment') environment,
    private http: HttpClient
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getCategories() {
    return this.http.get(`${this.baseUrl}reportCategories`);
  }

  setNavState(sidenavStateChange: SidenavStateChange) {
    this.subject.next(sidenavStateChange);
  }

  subscribe(callback: (val?: any) => void): Subscription {
    return this.subject.subscribe(callback);
  }
}
