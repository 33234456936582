import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  IconDefinition,
  faBell,
  faSpinner,
  faQuestionCircle,
  faChevronRight,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';
import {
  Router,
  ActivatedRoute,
  Params
} from '@angular/router';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';

import {
  OATSComplianceMonthlyReportSections,
  marketOrderTimelinessStatisticalMappings,
  marketOrderTimelinessStatisticalTitles,
  marketOrderTimelinessStatisticalCollapsedState,
  FIRM_SUMMARY_SCORECARD_GRID_HEADERS,
  EQUITY_REPORT_IDENTIFIERS,
  EQUITY_TIME_INTERVAL_GRID_HEADERS,
  OATS_RPT_CFG_VW_BLACKLIST,
  HIDE_PEER_GROUP_BY_EQUITY_REPORT_TYPE,
  HIDE_TIER_LABEL_BY_EQUITY_REPORT_TYPE,
  EQUITY_TOOLBAR_PEERGROUP_LABEL_BY_REPORT_TYPE,
  EQUITIES_HEADERS,
  EQUITIES_FOOTERS,
  PREFER_LOCAL_EQUITIES_HEADERS,
} from 'src/app/configs/model/equities-report.model';
import {
  addFinraGridColumnId,
  saveCsv,
  crdRegex,
  getMonthYrDate,
  finraGridIntegerPercentValueFormatter
} from 'src/app/shared/utils/utils';
import { HelperService } from 'src/app/services/helper.service';
import {
  ReportService,
  GLOBAL_REPORT_DISCLAIMER,
  ReportPageMetadataInfo
} from 'src/app/services/report.service';
import { FinraGridClickableCellComponent } from 'src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component';
import { PeerGroupComponent } from 'src/app/components/fragments/peer-group/peer-group.component';
import { ToolbarConfig, ToolbarConfigOptions } from 'src/app/configs/model/finra-toolbar.model';
import { ReportInstanceMetadata, ReportConfigurationView } from 'src/app/configs/model/reports.model';
import { EquityToolbarConfig } from 'src/app/configs/toolbar-config/equity.toolbar';
import { BeastOtherActions, BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastLeaveReportPageInfo, BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { DetailDatasetRequestsService } from 'src/app/services/detail-dataset-requests.service';
import { EquitiesService } from 'src/app/services/equities.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';



@Component({
  selector: 'report-equity',
  templateUrl: './equity.component.html',
  styleUrls: ['./equity.component.scss']
})
export class EquityComponent implements OnInit, OnDestroy {
  faBell: IconDefinition = faBell;
  faSpinner: IconDefinition = faSpinner;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  faChevronRight: IconDefinition = faChevronRight;
  faChevronDown: IconDefinition = faChevronDown;
  clientHeight: number;
  footerHeight: number = 220;
  reportInstanceMetadata: ReportInstanceMetadata;
  viewName: string;
  reportVersion: number;
  reportId: number;
  reportName: string;
  tierCode: number;
  reportDate: string;
  columnDefs: any = [];
  showReportDetails: boolean = true;
  shouldShowViewsDropdown: boolean;
  isOatsReport: boolean;
  autoGroupColumnDefs = {
    cellClass: 'rcExpand',
    cellRendererParams: {
      suppressCount: true
    }
  };
  EQUITY_REPORT_IDENTIFIERS = EQUITY_REPORT_IDENTIFIERS;
  equitiesExportConfig: any;
  rowData: any = [];
  title: any;
  description: any;
  disclaimer: string;
  help: any;
  downloadFileName: string;
  gridOptions: any = {};
  peerGroupData;
  errorMessage: string;
  viewFilterCriteria = null;
  oatsSections = OATSComplianceMonthlyReportSections;
  motsDataMapping = marketOrderTimelinessStatisticalMappings;
  marketOrderTimelinessStatisticalTitles = marketOrderTimelinessStatisticalTitles;
  marketOrderTimelinessStatisticalCollapsedState = {
    // get a copy to avoid modifying original
    ...marketOrderTimelinessStatisticalCollapsedState
  };
  reportLoadedSuccessfully: boolean;
  reportPageMetadataInfo: ReportPageMetadataInfo;
  equityToolbarConfig: ToolbarConfig;
  equityToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  periodChangeSubscription: Subscription;
  loadedAt: number;

  get hasPeriodData(): boolean {
    if (!this.rowData) {
      return false;
    }
    switch (this.reportName) {
      case EQUITY_REPORT_IDENTIFIERS.MOTS: {
        return this.rowData && this.rowData.firm && !!this.rowData.firm.length;
      }
      default: {
        const isArray = Array.isArray(this.rowData);
        const hasData = isArray
          ? (
              this.rowData.length > 0 &&
              this.rowData[0]['column0'] !== this.equityService.NO_PERIOD_DATA_MESSAGE
            )
          : (
              this.rowData['total'].length > 0 &&
              this.rowData['total']['column0'] !== this.equityService.NO_PERIOD_DATA_MESSAGE
            );
        return hasData;
      }
    }
  }

  constructor(
    private titleService: Title,
    private _router: Router,
    private baseReportService: ReportService,
    private equityService: EquitiesService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    private helperService: HelperService,
    private detailDatasetRequestsService: DetailDatasetRequestsService,
    public dialog: MatDialog,
    private unreadReportsService: UnreadReportsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {
    this.toolbarForm = new FormGroup({
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl()
    });
    this.loadedAt = Date.now();
  }

  ngOnInit() {
    this.titleService.setTitle('Equity');
    this.clientHeight = window.innerHeight;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
        reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: '',
        rating: '',
        product: '',
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.reportVersion = parseInt(params.version, 10);
    this.reportId = params.reportId;
    this.reportName = params.reportName;
    this.viewName = params.viewName;
    this.isOatsReport = (<Array<string>>[
      EQUITY_REPORT_IDENTIFIERS.OATSM,
      EQUITY_REPORT_IDENTIFIERS.OATSD
    ]).includes(this.reportName);
    this.showReportDetails =
      this.reportName !== EQUITY_REPORT_IDENTIFIERS.FIRM_SUM &&
      this.reportName !== EQUITY_REPORT_IDENTIFIERS.OATSM &&
      this.reportName !== EQUITY_REPORT_IDENTIFIERS.OATSD;
    if (this.isOatsReport) {
      this.gridOptions['headerHeight'] = 0;
      this.viewFilterCriteria = (x: ReportConfigurationView) => {
        const isNotBlacklisted = !OATS_RPT_CFG_VW_BLACKLIST.includes(
          x.reportConfigurationViewId
        );
        return isNotBlacklisted;
      };
    }
    this.getReportConfigurationMetadata().subscribe(
      (data: ReportPageMetadataInfo) => {
        this.processReportConfigurationMetadata(data);
        // Get Equity report after getting meta data
        this.getEquitySummaryReport().subscribe(
          (reportData: any) => {
            this.processEquitySummaryReport(reportData);
          },
          (error: HttpErrorResponse) => {
            // Clearing out data in case of error
            this.helperService.handleReportSummaryDataError(error);
            this.reportLoadedSuccessfully = false;
          }
        );
      }
    );
  }

  setToolbar() {
    const shouldHidePeerGroupButton = HIDE_PEER_GROUP_BY_EQUITY_REPORT_TYPE[this.reportName];
    const shouldHideTierLabel = HIDE_TIER_LABEL_BY_EQUITY_REPORT_TYPE[this.reportName];
    const peerGroupButtonLabel: string = EQUITY_TOOLBAR_PEERGROUP_LABEL_BY_REPORT_TYPE[this.reportName]
      ? EQUITY_TOOLBAR_PEERGROUP_LABEL_BY_REPORT_TYPE[this.reportName](this.tierCode)
      : null;
    const useViews = this.equityService.transformViewsList(
      this.reportPageMetadataInfo.views,
      this.reportName
    );
    const useFirmIdLabel = crdRegex.test(this.reportInstanceMetadata.reportFirmId)
      ? 'CRD ID'
      : 'MPID';
    const customOptions: Partial<ToolbarConfigOptions> = {
      description: this.description,
      firmIdLabel: useFirmIdLabel,
      tierCode: this.tierCode,
      views: useViews,
      hideViewsCell: !this.shouldShowViewsDropdown,
      hideDetailsButton: !this.showReportDetails,
      hidePublishedState: false,
      hidePeerGroupButton: shouldHidePeerGroupButton,
      hideTierLabel: shouldHideTierLabel,
      peerGroupButtonLabel,
      openPeerGroupPopup: () => {
        this.getPeerGroupData().subscribe(
          data => {
            this.openPeerGroupPopup(data);
          },
          (error: HttpErrorResponse) => {
            this.helperService.handlePeerGroupDataError(error);
          }
        );
      },
      setHelpClickHandler: () => {
        this.openHelp();
      },
    };
    const results = this.toolbarService.createToolbarConfig(
      this,
      EquityToolbarConfig,
      customOptions
    );
    this.equityToolbarConfigOptions = results.toolbarConfigOptions;
    this.equityToolbarConfig = results.toolbarConfig;
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          throw error;
        })
      );
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    this.errorMessage = null;
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
    // Storing date for peer group call
    this.reportDate = this.reportInstanceMetadata.reportPeriodDate;
    this.shouldShowViewsDropdown = this.showViewDropDown();
    // set the toolbar for the first time, in case getting the summary data fails
    // users can switch report period
    this.setToolbar();
  }

  showViewDropDown(): boolean {
    switch (this.reportName) {
      case 'eqrcmot':
      case 'eqoats':
        return true;
      case 'eqsc':
      case 'eqrnmstt':
      case 'eqrcmots':
        return false;
      default:
        return true;
    }
  }

  getEquitySummaryReport() {
    // DDWA-2779 Clearing out Peer group when calling for new report data
    this.peerGroupData = null;
    // Send a report date to backend to derive month Year time periods for some reports
    const reportDate = this.getReportDate(this.reportName);
    // Using view name to switch between different views.
    // When dataType = 's' we can get summary data to display on UI.
    return this.baseReportService.getReport(
      this.reportId,
      this.viewName,
      's',
      reportDate
    );
  }

  processEquitySummaryReport(data: any) {
    this.errorMessage = null;
    // Set Title, disclaimer, helplink & details file name
    this.setMetaData(data.metaData);
    // Adding tier code for display in toolbar
    this.reportInstanceMetadata.tierCode = data.tierCode;
    this.tierCode = data.tierCode;
    const formatted = this.getRowDataAndColumnDefs(this.reportName, data);
    // Set rowData for Ag-Grid
    this.rowData = formatted.rowData;
    // DDWA-2121 Check if all values for a column are empty then add No Data message
    this.rowData = this.addNoDataMessage(this.rowData);
    // Set columnDefs for Ag-Grid
    this.columnDefs = formatted.columnDefs;
    this.setGridOptionsState(this.reportName);
    this.setToolbar();
    this.reportLoadedSuccessfully = true;
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  setGridOptionsState(reportName) {
    switch (reportName) {
      case EQUITY_REPORT_IDENTIFIERS.MOTS: {
        this.gridOptions = {
          rowHeight: 30,
          headerHeight: 30,
          // groupHeaderHeight: 60,
          rowClassRules: {
            'lines-bottom-bold': (params) => {
              return params.data.shouldAddRowBorderBottomClass;
            }
          }
        };
        return;
      }
      default: {
        this.gridOptions = {};
      }
    }
  }

  getReportDate(reportName) {
    switch (reportName) {
      case EQUITY_REPORT_IDENTIFIERS.BEST_EXE:
      case EQUITY_REPORT_IDENTIFIERS.MKT_ORDER:
      case EQUITY_REPORT_IDENTIFIERS.REG_NMS:
      case EQUITY_REPORT_IDENTIFIERS.FIRM_SUM:
        return this.reportInstanceMetadata.reportPeriodDate;
      default:
        return null;
    }
  }

  getRowDataAndColumnDefs(reportName, data) {
    // Based on the selected report, generate columnDefs and formatted rowData
    switch (reportName) {
      case EQUITY_REPORT_IDENTIFIERS.EXE_FIRM:
      case EQUITY_REPORT_IDENTIFIERS.REP_FIRM:
      case EQUITY_REPORT_IDENTIFIERS.CONTRA_REP:
      case EQUITY_REPORT_IDENTIFIERS.CONTRA_EXE:
        return {
          rowData: this.equityService.getContraFirmReport(data.columnsData),
          columnDefs: EQUITY_TIME_INTERVAL_GRID_HEADERS
        };
      case EQUITY_REPORT_IDENTIFIERS.MKT_ORDER:
      case EQUITY_REPORT_IDENTIFIERS.BEST_EXE:
      case EQUITY_REPORT_IDENTIFIERS.REG_NMS:
        return {
          rowData: this.equityService.getBestExecutionReport(
            data.columnsData,
            reportName
          ),
          columnDefs: addFinraGridColumnId(
            this.equityService.createMonthYearColumnDefs(
              data.columnsData,
              reportName
            )
          )
        };
      case EQUITY_REPORT_IDENTIFIERS.MOTS: {
        this.marketOrderTimelinessStatisticalCollapsedState = {
          // get a copy to avoid modifying original
          ...marketOrderTimelinessStatisticalCollapsedState
        };
        return {
          rowData: this.equityService.parseMarketOrderTimelinessStatisticalResponse(
            data.columnsData
          ),
          columnDefs: this.equityService.createMonthYearColumnDefsStatistical(
            data.columnsData,
            reportName
          )
        };
      }
      case EQUITY_REPORT_IDENTIFIERS.FIRM_SUM:
        return {
          rowData: this.equityService.getFirmSummaryScoreCard(data.columnsData),
          columnDefs: FIRM_SUMMARY_SCORECARD_GRID_HEADERS
        };
      case EQUITY_REPORT_IDENTIFIERS.OATSM:
      case EQUITY_REPORT_IDENTIFIERS.OATSD:
        return {
          /**
           * If second argument is true, the result will be a object literal and not an array
           */
          rowData: this.equityService.getOATSComplianceMonthlyReportCard(
            data,
            true
          ),
          columnDefs: addFinraGridColumnId([
            {
              headerName: '',
              field: 'label',
              colSpan: params => {
                return ['h1', 'h2'].includes(params.data.type) ? 3 : 1;
              },
              cellClass: function(params) {
                return params.data.type || '';
              },
              cellRendererFramework: FinraGridClickableCellComponent,
              cellRendererFrameworkOptions: {
                isClickable: (params: object) => params['data'].clickable,
                buttonLabel: 'View Daily Totals',
                buttonClass: 'mat-flat-button mat-accent button',
                handleClick: (params: any, rowIndex: number) => {
                  const useViewName = this.equityService.getOATSComplianceDetailViewName(params.data.label);
                  const detailsUrl = this.equityService.getOATSComplianceDetailUrls(
                    this.reportInstanceMetadata.reportId,
                    params.data.label
                  );
                  this.helperService.downloadFile(detailsUrl)
                    .then((successful) => {
                      this.handleDownloadFile(successful, useViewName);
                    });
                }
              }
            },
            {
              headerName: '',
              field: 'value',
              valueFormatter: finraGridIntegerPercentValueFormatter,
              type: 'numericColumn'
            },
            {
              headerName: '',
              field: '',
              minWidth: 300
            }
          ])
        };
    }
  }

  handleDownloadFile(
    successful: boolean,
    viewName: string
  ) {
    if (!successful) {
      return;
    }
    const datasetRequest = this.detailDatasetRequestsService.getDetailDatasetRequestByReportIdAndViewName(
      this.reportId,
      viewName
    );
    if (!datasetRequest) {
      return;
    }
    this.detailDatasetRequestsService.removeDetailDatasetRequest(datasetRequest.detailDatasetRequestId);
  }

  openReportCard(firmId) {
    this._router.navigate(['/equity/report-card-view']);
  }

  async summaryDataExport() {
    const fileNameResponse = await this.baseReportService.getReportFileName(
      this.reportId,
      this.viewName,
      's'
    ).toPromise();
    const csvString = this.equityService.exportEquitySummaryData(
      this.reportName,
      this.rowData,
      this.columnDefs
    );
    if (csvString) {
      saveCsv(csvString, fileNameResponse.fileName);
      this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
    }
  }

  onReportDetails() {
    const exportUrl: string = this.baseReportService.getReportUrl(
      this.reportId,
      this.viewName,
      'd'
    );
    this.helperService.downloadFile(exportUrl);
  }

  addNoDataMessage(data) {
    switch (this.reportName) {
      case EQUITY_REPORT_IDENTIFIERS.FIRM_SUM:
        return this.equityService.addNoDataMessageFirmSum(data);
      case EQUITY_REPORT_IDENTIFIERS.OATSD:
      case EQUITY_REPORT_IDENTIFIERS.OATSM:
      case EQUITY_REPORT_IDENTIFIERS.MKT_ORDER:
      case EQUITY_REPORT_IDENTIFIERS.MOTS:
        return data;
      default:
        return this.equityService.addNoDataMessage(data);
    }
  }

  setMetaData(metaData) {
    if (!metaData) {
      return;
    }
    // Getting title, description, help link, etc from metadata.
    this.title = metaData['title'];
    // if report is preferred to use local config, check model first; else check response first.
    const description: string = PREFER_LOCAL_EQUITIES_HEADERS[this.reportName]
      ? EQUITIES_HEADERS[this.reportName] || metaData['desc']
      : metaData['desc'] || EQUITIES_HEADERS[this.reportName];
    this.description = description;
    // use the disclaimer in the response IF it exists. if not, look up headers map. if not in map, use generic.
    this.disclaimer = metaData['disclaimer'] || EQUITIES_FOOTERS[this.reportName] || GLOBAL_REPORT_DISCLAIMER;
    this.help = metaData['help'];
    this.downloadFileName = metaData['downloadFileName'];
  }

  openHelp() {
    this.helpLinksService.open(this.reportName);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  openPeerGroupPopup(peerGroupData) {
    this.errorMessage = null;
    const dialogRef = this.dialog.open(PeerGroupComponent, {
      width: '600px',
      height: '650px',
      data: {
        reportInstanceMetadata: this.reportInstanceMetadata,
        rowData: peerGroupData,
        tierCode: this.tierCode,
        period: getMonthYrDate(this.reportDate),
        reportName: this.title,
        reportView: this.viewName,
        columnTitle: 'Equity Firm ID',
        useField: 'firmId',
      }
    });
  }

  getPeerGroupData() {
    return this.baseReportService.getPeerGroupData(
      this.reportId,
      this.viewName,
      this.reportDate,
      this.tierCode
    );
  }
}
