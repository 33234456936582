import { addFinraGridColumnId } from 'src/app/shared/utils/utils';
import * as Utils from 'src/app/shared/utils/utils';
import { ICellRendererParams } from 'ag-grid-community';

export enum EquityReportTypeIDs {
  BEST_EXECUTION_OUTSIDE_OF_THE_INSIDE = 6,
  OATS_COMPLIANCE_MONTHLY = 7,
  CONTRA_REPORTING_FIRM_20_MINUTE_COMPLIANCE = 8,
  OATS_COMPLIANCE_DAILY = 10,
  CONTRA_EXECUTING_FIRM_20_MINUTE_COMPLIANCE = 11,
  EXECUTING_FIRM_10_SECOND_COMPLIANCE = 13,
  FIRM_SUMMARY_SCORECARD = 14,
  REPORTING_FIRM_10_SECOND_COMPLIANCE = 15,
  MARKET_ORDER_TIMELINESS = 16,
  MARKET_ORDER_TIMELINESS_STATISTICAL = 205,
  REG_NMS_TRADE_THROUGH = 25
}

export enum EquityReportShortNames {
  BEST_EXECUTION_OUTSIDE_OF_THE_INSIDE = 'eqbeosi',
  OATS_COMPLIANCE_MONTHLY = 'eqoats',
  CONTRA_REPORTING_FIRM_20_MINUTE_COMPLIANCE = 'eqnmc',
  OATS_COMPLIANCE_DAILY = 'eqprdaily',
  CONTRA_EXECUTING_FIRM_20_MINUTE_COMPLIANCE = 'eqrcnmc',
  EXECUTING_FIRM_10_SECOND_COMPLIANCE = 'eqrctrd',
  FIRM_SUMMARY_SCORECARD = 'eqsc',
  REPORTING_FIRM_10_SECOND_COMPLIANCE = 'eqtrr',
  MARKET_ORDER_TIMELINESS = 'eqrcmot',
  MARKET_ORDER_TIMELINESS_STATISTICAL = 'eqrcmots',
  REG_NMS_TRADE_THROUGH = 'eqrnmstt'
}

export const OATS_RPT_CFG_VW_BLACKLIST = [
  1004,
  1005,
  1006,
  1009,
  1052,
  1008,
  1007,
  1010
];

export const HIDE_VIEWS_BY_EQUITY_REPORT_TYPE = {
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL]: true,
};

export const HIDE_PEER_GROUP_BY_EQUITY_REPORT_TYPE = {
  [EquityReportShortNames.FIRM_SUMMARY_SCORECARD]: true,
};

export const HIDE_TIER_LABEL_BY_EQUITY_REPORT_TYPE = {
  [EquityReportShortNames.FIRM_SUMMARY_SCORECARD]: true,
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL]: true,
};

export const EQUITY_TOOLBAR_PEERGROUP_LABEL_BY_REPORT_TYPE = {
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL]: (tierCode) => `Peer Group: ${tierCode}`,
};

export const EQUITY_REPORT_FIRM_ID_LABEL_BY_REPORT_TYPE = {
  [EquityReportShortNames.REG_NMS_TRADE_THROUGH]: 'CRD ID',
};

export interface GridHeader {
  headerName: string;
  field: string;
  width: number;
  filter?: string;
  filterParams?: object;
  rowGroup?: boolean;
  hide?: boolean;
}

export const EQUITY_REPORT_IDENTIFIERS = {
  OATSM: EquityReportShortNames.OATS_COMPLIANCE_MONTHLY,
  OATSD: EquityReportShortNames.OATS_COMPLIANCE_DAILY,
  FIRM_SUM: EquityReportShortNames.FIRM_SUMMARY_SCORECARD,
  BEST_EXE: EquityReportShortNames.BEST_EXECUTION_OUTSIDE_OF_THE_INSIDE,
  CONTRA_REP: EquityReportShortNames.CONTRA_REPORTING_FIRM_20_MINUTE_COMPLIANCE,
  CONTRA_EXE: EquityReportShortNames.CONTRA_EXECUTING_FIRM_20_MINUTE_COMPLIANCE,
  EXE_FIRM: EquityReportShortNames.EXECUTING_FIRM_10_SECOND_COMPLIANCE,
  REP_FIRM: EquityReportShortNames.REPORTING_FIRM_10_SECOND_COMPLIANCE,
  REG_NMS: EquityReportShortNames.REG_NMS_TRADE_THROUGH,
  MKT_ORDER: EquityReportShortNames.MARKET_ORDER_TIMELINESS,
  MOTS: EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL,
};

export const TIME_INTERVALS = [
  'All Time Intervals',
  '8:00 - 9:30',
  '9:30 - 9:50',
  '9:50 - 3:30',
  '3:30 - 4:00',
  '4:00 - End of Day'
];

export const BEST_EXE_COL_NAMES = {
  TRR:
    'Reporting Firm 10 Second Compliance (formerly Reporting Firm Trade Reporting)',
  ACTR: 'Reporting Firm 20 Minute Compliance (formerly NMC Compliance)',
  BENNM: 'Best Execution (NASDAQ Only)',
  BECQS: 'Best Execution (CQS Only)',
  OC: 'OATS Compliance',
  MOT: 'Market Order Timeliness - MOT (NASDAQ Only)',
  TT: 'Reg NMS TT Compliance'
};

export const GROUP_NAMES = {
  all: 'All Time Intervals',
  general: 'Total',
  peer: 'Peer Group',
  industry: 'Industry Group',
  preopen: 'Pre-Open And After Market Hours',
  normal: 'Normal Market Hours',
  infoAlert: 'Informational Alerts',
  marked: 'PROPERLY MODIFIED LATE TRADES',
  unmarked: 'LATE TRADES THAT WERE NOT MODIFIED',
  improperlyMarked: 'IMPROPERLY MODIFIED TRADES',
  '0_20': 'Accepted/Declined/Compared within 20 minutes of execution(1)',
  '21_45': 'Accepted/Declined/Compared between 21 minutes & 45 minutes(2)',
  '46_60': 'Accepted/Declined/Compared between 46 minutes & 60 minutes(2)',
  '61': 'Accepted/Declined/Compared 61 minutes or more(2)',
  tradeThroughStatistics: 'TOTAL TRADE THROUGH STATISTICS',
  tradeWithoutException: 'TRADE THROUGH WITHOUT ANY EXCEPTION STATISTICS',
  tradeWithException: 'TRADE THROUGH WITH EXCEPTION STATISTICS',
  unspecified: 'Unspecified Exceptions',
  nonStandard: 'Non-Standard Settlement Exceptions',
  selfHelp: 'Self Help Exceptions',
  inboundISO: 'Inbound ISO Exceptions',
  outboundISO: 'Outbound ISO Exceptions',
  qualifiedContingent: 'Qualified Contingent Trade Exemptions',
  subpenny: 'Sub-Penny Exemptions',
  stoppedStock: 'Stopped Stock Exceptions',
  benchmark: 'Benchmark Exceptions',
  errorCorrection: 'Error Correction Exemptions',
  printProtection: 'Print Protection Exemptions',
  unknown: 'Unknown Exceptions'
};

export const OATSComplianceMonthlyReportSections = [
  'total',
  'lateReporting',
  'outOfSequenceEvents',
  'unmatchedExecutionReports',
  'exchangeUnmatchedRouteReports',
  'unmatchedInterFirmRouteReports',
  'unmatchedInterFirmRoutesReceived',
  'exceptionSummary',
  'rejectedData',
  'reportingExceptionCode'
];

export const OATSComplianceMonthlyAcceptedViewsList = [
  'CQS',
  'All',
  'NASDAQ',
  'Other'
];

/** Market Order Timeliness Statistical */

export interface MarketOrderTimelinessStatisticalData {
  columnName: string;
  displayName: string;
  group: string;
  values: any[];
}

export type MarketOrderTimelinessStatisticalResponse = [
  {
    mpid: string;
    marketCategory: string;
    tierCode: string;
    metadata: {
      help: string;
      downloadFileName: string;
      helpLink: string;
      title: string;
      version?: number;
      disclaimer: string;
      desc: string;
    };
    columnsData: MarketOrderTimelinessStatisticalData[];
  }
];

export const marketOrderTimelinessStatisticalTimebands = [
  '1To3',
  '',
  '1To4',
  '',
  '1To5',
  '',
];

// this number represents how many time distributions to aqqregate
// for example, 2 = for every 2 timeband distributions, aggregate them to create a totals.
// this is based on the current requirement
// see https://jira.finra.org/browse/DDWA-5689 and the mock on there for more info
export const marketOrderTimelinessStatisticalTimebandsAggNum = 2;

export const marketOrderTimelinessStatisticalMappings = [
  'firm',
  'firmTimeband',
  'peer',
  'peerTimeband',
  'industry',
  'industryTimeband',
];

export const marketOrderTimelinessStatisticalTitles = {
  firm: 'Firm Statistics',
  firmTimeband: 'Firm Timeband Distribution',
  peer: 'Peer Group Statistics',
  peerTimeband: 'Peer Timeband Distribution',
  industry: 'Industry Statistics',
  industryTimeband: 'Industry Timeband Distribution',
};

export const marketOrderTimelinessStatisticalCollapsedState = {
  firm: false,
  firmTimeband: false,
  peer: false,
  peerTimeband: false,
  industry: false,
  industryTimeband: false,
};

/** END Market Order Timeliness Statistical */

export const OATSComplianceMonthlyAcceptedViewsObj = {
  CQS: 'CQS',
  All: 'All',
  NASDAQ: 'NASDAQ',
  Other: 'Other'
};

export const EQUITY_HEADERS = addFinraGridColumnId([
  {
    field: 'marketType',
    rowGroup: true,
    hide: true,
    valueFormatter: params => {
      return GROUP_NAMES[params.value];
    }
  },
  {
    field: 'exceptionType',
    rowGroup: true,
    hide: true,
    valueFormatter: params => {
      return GROUP_NAMES[params.value];
    }
  },
  {
    field: 'group',
    rowGroup: true,
    hide: true,
    valueFormatter: params => {
      return GROUP_NAMES[params.value];
    }
  },
  {
    headerName: 'Field Name',
    field: 'displayName',
    cellRenderer: (params: ICellRendererParams) => {
      if (params.value === 'Average') {
        return `Average <sup>1</sub>`;
      }
      return params.value;
    }
  }
]);

export const EQUITY_TIME_INTERVAL_GRID_HEADERS = addFinraGridColumnId([
  {
    field: 'marketType',
    rowGroup: true,
    hide: true,
    valueFormatter: params => {
      return GROUP_NAMES[params.value];
    }
  },
  {
    field: 'group',
    rowGroup: true,
    hide: true,
    valueFormatter: params => {
      return GROUP_NAMES[params.value];
    }
  },
  {
    headerName: 'Field Name',
    field: 'displayName'
  },
  {
    headerName: TIME_INTERVALS[1],
    field: 'column1'
  },
  {
    headerName: TIME_INTERVALS[2],
    field: 'column2'
  },
  {
    headerName: TIME_INTERVALS[3],
    field: 'column3'
  },
  {
    headerName: TIME_INTERVALS[4],
    field: 'column4'
  },
  {
    headerName: TIME_INTERVALS[5],
    field: 'column5'
  },
  {
    headerName: TIME_INTERVALS[0],
    field: 'column0'
  }
]);

export const FIRM_SUMMARY_SCORECARD_GRID_HEADERS: Array<
  any
> = addFinraGridColumnId([
  {
    field: 'reportType',
    rowGroup: true,
    hide: true
  },
  {
    headerName: 'Period',
    field: 'monthYear'
  },
  {
    headerName: 'Tier',
    field: 'tierCode',
    valueFormatter: Utils.finraGridNumericValueFormatter,
    cellRenderer: Utils.groupEmptyCellRenderer
  },
  {
    headerName: 'Total Alerts',
    field: 'totalAlertCount',
    valueFormatter: Utils.internationalNumericFormatter,
    cellRenderer: Utils.groupEmptyCellRenderer
  },
  {
    headerName: 'Firm %',
    field: 'firmPercent',
    type: 'numericColumn',
    valueFormatter: Utils.finraGridPercentValueFormatter,
    cellRenderer: Utils.groupEmptyCellRenderer
  },
  {
    headerName: 'Peer Group Average %',
    field: 'peerAveragePercent',
    type: 'numericColumn',
    valueFormatter: Utils.finraGridPercentValueFormatter,
    cellRenderer: Utils.groupEmptyCellRenderer
  },
  {
    headerName: 'Industry Average %',
    field: 'industryAveragePercent',
    type: 'numericColumn',
    valueFormatter: Utils.finraGridPercentValueFormatter,
    cellRenderer: Utils.groupEmptyCellRenderer
  },
  {
    headerName: 'Number in Peer Group',
    field: 'peerPopulationCount',
    valueFormatter: Utils.finraGridNumericValueFormatter,
    cellRenderer: Utils.groupEmptyCellRenderer
  },
  {
    headerName: 'Rank(Peer)',
    field: 'peerRankNumber',
    valueFormatter: Utils.finraGridNumericValueFormatter,
    cellRenderer: Utils.groupEmptyCellRenderer
  },
  {
    headerName: 'Percentile(Peer)',
    field: 'peerPercentile',
    valueFormatter: Utils.finraGridPercentValueFormatter,
    cellRenderer: Utils.groupEmptyCellRenderer
  }
]);

export const COL_HEADERS_BY_REPORT_NAME = {
  eqbeosi: [...EQUITY_HEADERS].filter(el => el['field'] !== 'exceptionType'),
  // For Market Order Timeliness remove marketType grouping
  eqrcmot: [...EQUITY_HEADERS].filter(
    el => el['field'] !== 'marketType' && el['field'] !== 'exceptionType'
  ),
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL]: [...EQUITY_HEADERS].filter(
    el => el['field'] !== 'marketType' && el['field'] !== 'exceptionType'
  ),
  eqrnmstt: EQUITY_HEADERS
};

export const PEER_GROUP_COL_DEF = [
  { headerName: 'MPID', field: 'equityMpid', width: 300 },
  { headerName: 'Firm Name', field: 'firmName', width: 400 }
];

export const PEER_GROUP_DESC =
  'The following list identifies alphabetically the firm name and MPID of each firm within the peer group for the specified report card and time period.';

/** Headers */

const MOTS_HEADER =
`The Market Order Timeliness (MOT) Statistical Report displays monthly \
execution duration statistics to help member firms maintain compliance with \
Supplementary Material .01 of FINRA Rule 5310 which states that a member \
must make every effort to execute a marketable customer order that it receives fully and promptly. \
For questions regarding this specific report, \
please call the FINRA Market Regulation Department \
at (240) 386-6032 and ask for the Equity Best Execution Group.
<p>This report card contains CAT confidential data of the recipient or of another CAT reporter for which the recipient \
must make a reasonable effort to protect confidentiality.</p>`;

export const EQUITIES_HEADERS = {
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL]: MOTS_HEADER
};

export const PREFER_LOCAL_EQUITIES_HEADERS = {
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL]: true
};



/** Footers */

const MOTS_FOOTER =
`* If this symbol appears on your report, this indicates that no \
data has been provided to FINRA for this value for the specified period.

This is an expected behavior for weekday holidays but may \
also indicate information that was not sent, incorrectly formatted, or late.

This information is provided as a compliance aid to assist the firm in \
ensuring that it is executing marketable customer orders in compliance with \
FINRA Rule 5310. This report does not reflect a determination by FINRA \
that the firm has, or has not, violated applicable member rules governing this area.`;

export const EQUITIES_FOOTERS = {
  [EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL]: MOTS_FOOTER
};
