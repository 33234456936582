import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'report-timeout-warning',
  templateUrl: './timeout-warning.component.html',
  styleUrls: ['./timeout-warning.component.scss']
})
export class TimeoutWarningComponent implements OnInit {
  minutesRemaining: number;
  interval: any;

  constructor(
    public dialogRef: MatDialogRef<TimeoutWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // Round down to the closest minute
    this.minutesRemaining =
      this.data.durationMillis && this.data.durationMillis > 0
        ? Math.floor(this.data.durationMillis / 1000 / 60)
        : 0;

    this.interval = setInterval(() => {
      if (this.minutesRemaining <= 0) {
        this.logout();
      } else {
        this.minutesRemaining--;
      }
    }, 60000);
  }

  public get timeRemaining(): string {
    return this.minutesRemaining > 0
      ? `in ${this.minutesRemaining} minutes`
      : 'soon';
  }

  continue() {
    clearTimeout(this.interval);
    this.dialogRef.close(true);
  }

  logout() {
    clearTimeout(this.interval);
    this.dialogRef.close(false);
  }
}
