import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import {
  IconDefinition,
  faSpinner,
  faPrint,
  faTimesCircle
} from '@fortawesome/pro-light-svg-icons';
import { PdfService } from 'src/app/services/pdf.service';


@Component({
  selector: 'report-firms-modal',
  templateUrl: './firms-modal.component.html',
  styleUrls: ['./firms-modal.component.scss']
})
export class SeverelyDisciplinedFirmsModalComponent implements OnInit {
  faPrint: IconDefinition = faPrint;
  faSpinner: IconDefinition = faSpinner;
  faTimesCircle: IconDefinition = faTimesCircle;
  isReady: boolean = false;
  isPdfProcessing: boolean = false;
  columnDefs: any;
  rowData: any;

  constructor(
    public dialogRef: MatDialogRef<SeverelyDisciplinedFirmsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private pdfService: PdfService,
  ) {}

  ngOnInit() {
    this.columnDefs = this.data.columnDefs;
    this.rowData = this.data.rowData || [];
    this.pdfService.processing.subscribe((processingState: boolean) => {
      this.isPdfProcessing = processingState;
    });
    this.isReady = true;
  }

  onExportPdf() {
    if (!this.isPdfProcessing) {
      const fileName =
        'Reps-Ever-Previously-with-a-Severely-Disciplined-Firm' +
        '_' + this.data.reportPeriod + '_' + Date.now();
      this.pdfService.processing.next(true);
      this.pdfService
        .exportPdf(fileName, 3900, 2)
        .then(() => {
          this.pdfService.processing.next(false);
        });
    }
  }

  closeDilog() {
    this.dialogRef.close();
  }
}
