<div id="firms-modal-container" class="mat-typography pdf">
  <span
    title="Close Modal"
    (click)="closeDilog()">
    <fa-icon 
      [icon]="faTimesCircle" 
      class="fa-lg dialog-tools-icon close-icon controls-container"
    ></fa-icon>
  </span>

  <h1 mat-dialog-title>
    Severely Disciplined Firms
  </h1>
  <p>
    The following is an alphabetical list of all firms that have ever been identified as severely disciplined.
  </p>
  
  <div class="margin-space-1">
    <div
      class="controls-container no-print"
      data-html2canvas-ignore
    >
      <span (click)="onExportPdf()">
        <fa-icon [icon]="faPrint" class="fa-lg dialog-tools-icon"></fa-icon>
        Print
        <fa-icon
          [icon]="faSpinner"
          [spin]="true"
          [hidden]="!isPdfProcessing"
        ></fa-icon>
      </span>
    </div>
  </div>
  
  <div class="margin-space-1" *ngIf="isReady">
    <mat-dialog-content>
      <report-finra-grid
        #reportfinragrid
        class="firms-grid pdf"
        [columnDefs]="columnDefs"
        [rowData]="rowData"
        [groupDefaultExpanded]="0"
      ></report-finra-grid>
    </mat-dialog-content>
  </div>
</div>
