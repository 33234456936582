import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IconDefinition, faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UserProfile } from 'src/app/configs/model/user-profile.model';

@Component({
  selector: 'report-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {
  @ViewChild('userProfileMenu', { static: false }) userProfileMenu;

  @Input() name: string;
  faUserCircle: IconDefinition = faUserCircle;

  @Output()
  logout: EventEmitter<any> = new EventEmitter();

  userProfile: UserProfile;
  showLogout: boolean = false;
  isLoggedIn: boolean = false;

  constructor(private userProfileService: UserProfileService) {
    this.userProfile = {} as UserProfile;

    userProfileService.getUserProfile(false).subscribe((userProfile) => {
      this.userProfile = userProfile;
      this.isLoggedIn = !!userProfile;
    });
  }

  ngOnInit() {}

  getLabel() {
    if (this.userProfile.displayName) {
      return this.userProfile.displayName;
    }

    if (this.userProfile.firstName || this.userProfile.lastName) {
      return (
        (this.userProfile.firstName || '') +
        ' ' +
        (this.userProfile.lastName || '')
      );
    }

    return 'No User';
  }

  fireLogout($event: any) {
    this.logout.emit($event);
  }

  isExternalUser() {
    return this.userProfileService.isExternalUser(this.userProfile);
  }
}
