import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges, OnInit,
} from '@angular/core';
import { IconDefinition, faAngleDown } from '@fortawesome/pro-light-svg-icons';
import {
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { CardFilterArguments, PublishedOptions, ReportStateEnum } from 'src/app/configs/model/reports.model';
import { FilterStoreService } from 'src/app/services/filter-store.service';


@Component({
  selector: 'report-cards-filter',
  templateUrl: './cards-filter.component.html',
  styleUrls: ['./cards-filter.component.scss']
})
export class CardsFilterComponent implements OnChanges {
  @Input() mpidsEWS;
  @Input() isEWSUser;
  @Input() showPublishedOptions = true;
  @Input() categoryId: string;
  @Input() isSROUser: boolean;
  @Input() isFRBUser: boolean;
  @Output() firmFilter = new EventEmitter<CardFilterArguments>();
  faAngleDown: IconDefinition = faAngleDown;
  options: FormGroup;
  publishedOptions = PublishedOptions;
  cardsFilterLabel: string = '';
  text: string = '';
  filterForm: FormGroup = new FormGroup({
    firmId: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required])
  });


  constructor(
    private filterStoreService: FilterStoreService
  ) {}

  ngOnChanges(changes) {
    const label = this.getCardsFilterLabel(this.categoryId);
    this.cardsFilterLabel = label;
    this.setFilterForm();
  }
  setFilterForm() {
    const filters = this.filterStoreService.getFilters();
    const isCategoryIdDifferentOrEmpty = (
      !filters.categoryId || filters.categoryId !== this.categoryId
    );

    if (isCategoryIdDifferentOrEmpty) {
      // DDWA-5024: default state should be 'Published'
      this.filterForm.setValue({ firmId: '', state: ReportStateEnum.Published });
    } else {
      this.filterForm.setValue({ firmId: filters.firmId, state: filters.state });
      this.firmFilter.emit(filters);
    }
  }

  onFirmFilter(): void {
    if (this.filterForm.invalid) {
      return;
    }
    const firmIdControl = this.filterForm.get('firmId');
    const firmId = firmIdControl.value;
    const firmIdFormatted = firmId.replace(/[^0-9a-zA-Z]+/g, '');
    firmIdControl.setValue(firmIdFormatted);
    const publishedState = this.filterForm.get('state').value;

    this.filterStoreService.setCategoryId(this.categoryId);
    this.filterStoreService.setFirmId(firmIdFormatted);
    this.filterStoreService.setState(publishedState);

    const filters = this.filterStoreService.getFilters();
    this.firmFilter.emit(filters);
  }

  private getCardsFilterLabel (categoryId: string): string {
    const category = parseInt(categoryId, 10);
    switch (category) {
      case 3: {
        // msrb
        return 'Search By MSRB/MPID';
      }
      case 1:
        // trace
        return 'Search By MPID';
      case 4: {
        // equity
        return 'Search By MPID/CRD ID';
      }
      default: {
        return 'Search By CRD/Firm ID';
      }
    }
  }
}
