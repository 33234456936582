<div id="problems-form-container">
  <report-loader></report-loader>
  <report-alert class="report-main-content-padding no-print"></report-alert>
  <h1 class="title pdf">
    {{ title }}
  </h1>

  <div class="section-space">
    <a [routerLink]="['/', 'admin', 'report-view', 'report-problems']">
      <button
          mat-flat-button
          color="accent"
          class="filter-button"
        >
          Cancel
        </button>
    </a>
  </div>

  <report-error-message [errorMessage]="errorMessage"></report-error-message>

  <br/>

  <report-admin-report-problems-form
    [isEditing]="isEditing"
    [reportProblem]="reportProblem"
    [reportTypesList]="reportTypesList"
    (formSubmitted)="onFormSubmit($event)"
  ></report-admin-report-problems-form>
</div>
