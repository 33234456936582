import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FinraGridClickableCellComponent } from "src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component";
import {
  addFinraGridColumnId,
  getMonthYrDate,
  checkObjectEmpty,
  finraGridNumericValueFormatter,
  ROW_DATA_NO_INFO_TEXT,
  finraGridPercentValueFormatter,
} from "src/app/shared/utils/utils";



export interface PrimaryOfferingDisclosureData {
  period: string;
  firmId: string;
  salesInPrimaryOfferingPeriodPriorToOSAvailabilityOnEMMA: number;
  firmName: string;
  totalSalesToCustomer: number;
  percentSalesInPrimaryOfferingPeriodPriorToOSAvailabilityOn: number; // percent/float
  percentSalesInPrimaryOfferingDisclosurePeriod: number; // percent/float
  salesInPrimaryOfferingDisclosurePeriod: number;
}

// AG-GRID column definition //

/**
 * @see: https://reportcenter.qa.finra.org/scrc/view_rpt.do?rptId=5280679&viewId=510&bgurl=show_rpt&backCatID=3&auditAction=2
 */

export const PRIMARY_OFFERING_DISCLOSURE_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: "Period",
    valueGetter: (params) =>
      (<PrimaryOfferingDisclosureData>params.data).period,
    valueFormatter: (params) => getMonthYrDate(params.value),
  },
  {
    headerName: "Total Sales to Customers",
    headerClass: "lines",
    cellClass: "lines",
    valueGetter: (params) =>
      (<PrimaryOfferingDisclosureData>params.data).totalSalesToCustomer,
    // valueFormatter: finraGridNumericValueFormatter,
    valueFormatter: (params) => {
      const quarterHasData = checkObjectEmpty(params.data, [
        "period",
        "firmId",
        "firmName",
      ]);
      return quarterHasData
        ? finraGridNumericValueFormatter(params)
        : ROW_DATA_NO_INFO_TEXT;
    },
    // cellRenderer: aggCellRenderer,
    colSpan: function (params) {
      const quarterHasNoData = !checkObjectEmpty(params.data, [
        "period",
        "firmId",
        "firmName",
      ]);
      return quarterHasNoData ? 7 : 1;
    },
  },
  {
    headerName: "Sales in Primary Offering Disclosure Period",
    headerClass: "lines",
    cellClass: "lines",
    valueGetter: (params) =>
      (<PrimaryOfferingDisclosureData>params.data)
        .salesInPrimaryOfferingDisclosurePeriod,
    valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: "% of Sales in Primary Offering Disclosure Period",
    headerClass: "lines",
    cellClass: "lines",
    valueGetter: (params) =>
      (<PrimaryOfferingDisclosureData>params.data)
        .percentSalesInPrimaryOfferingDisclosurePeriod,
    valueFormatter: finraGridPercentValueFormatter,
  },
  {
    headerName:
      "Sales in Primary Offering Period Prior to OS Availability on EMMA",
    headerClass: "lines",
    cellClass: "lines",
    valueGetter: (params) =>
      (<PrimaryOfferingDisclosureData>params.data)
        .salesInPrimaryOfferingPeriodPriorToOSAvailabilityOnEMMA,
    valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName:
      "% of Sales in Primary Offering Period Prior to OS Availability on EMMA",
    headerClass: "lines",
    cellClass: "lines",
    valueGetter: (params) =>
      (<PrimaryOfferingDisclosureData>params.data)
        .percentSalesInPrimaryOfferingPeriodPriorToOSAvailabilityOn,
    valueFormatter: finraGridPercentValueFormatter,
  },
  {
    headerName: "", // download details cell
    headerClass: "lines",
    cellClass: "lines",
    isDetailLink: true,
  },
]);

export const applyPrimaryOfferingsDetailLink = (columnDefs, callback) => {
  columnDefs.forEach((col) => {
    if ((<any>col).isDetailLink) {
      col["cellRendererFramework"] = FinraGridClickableCellComponent;
      col["cellRendererFrameworkOptions"] = {
        icon: faDownload,
        isClickable: (params: any) => true,
        buttonLabel: "Details",
        handleClick: (params: any) => {
          callback(params);
        },
      };
    }
  });
};
