import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { SidenavigationService } from 'src/app/services/sidenavigation.service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { NavItem, mainNavItems } from '../configs/model/sidenavigation';
import { Observable } from 'rxjs';
import { UserProfile } from '../configs/model/user-profile.model';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UiWidgetsService } from '../services/ui-widgets.service';

export type CanActivateReturnValue =
  Observable<boolean>
  | Promise<boolean>
  | boolean;

/**
 * User Access Guard
 * ---
 *
 * This guard prevents users from navigating routes that they do not have access to, based on the user's `accessType`.
 *
 * First, the guard fetches the user's profile via the `UserProfileService` service.
 * Then, it looks at the route's `data` and looks for `mainNavItem`.
 * The guard then checks to see if `mainNavItem.accessTypes` is `null` (null means the route is not restricted) or
 * if `userProfile.accessType` is in `mainNavItem.accessTypes`;
 * if it is, the user has access to that route, otherwise the user does not have access and will be redirected to `/home`.
 *
 * NOTE - Each `route` configuration (in app.routing.ts) should have a `data` object as part of the config
 * and in that data object, `mainNavItem` (from sidenavigation.ts) must be added.
 * It makes things explicit, simple and easy.
 *
 * For more info on Angular route guards,
 * @see: https://angular.io/api/router/CanActivate
 * @see: https://angular.io/guide/router#milestone-5-route-guards
 */
@Injectable()
export class UserAccessGuard implements CanActivate {
  private issoBaseUrl;
  private isEwsReportCenter;
  constructor(
    @Inject('environment')
    environment,
    private userProfileService: UserProfileService,
    private router: Router,
    private sidenavService: SidenavigationService,
    private uiWidgetsService: UiWidgetsService,
  ) {
    this.issoBaseUrl = environment.issoBaseUrl;
    this.isEwsReportCenter = environment.isEwsReportCenter;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): CanActivateReturnValue {
    return this.userProfileService.getUserProfile().pipe(
      map((userProfile: UserProfile) => {
        return this.handleActivation({ route, userProfile });
      })
    );
  }

  handleActivation(params: {
    route: ActivatedRouteSnapshot;
    userProfile: UserProfile;
  }) {
    const mainNavItem: NavItem = params.route.data.mainNavItem;
    const hasAccess = this.checkAccess({
      mainNavItem,
      userProfile: params.userProfile
    });
    return hasAccess;
  }

  checkAccess(params: {
    mainNavItem: NavItem;
    userProfile: UserProfile;
  }) {
    const hasAccess =
      params.mainNavItem.accessTypes === null || params.mainNavItem.accessTypes.includes(params.userProfile.accessType);
    if (!hasAccess) {
      const errorMsg = `You do not have access to view ${params.mainNavItem.label || 'this'} page`;
      this.uiWidgetsService.showErrorSnackbar(errorMsg);
      const navigationItem = mainNavItems['home'];
      this.sidenavService.setNavState({ navigationItem, child: null });
      this.router.navigate(['/home']);
    }
    return hasAccess;
  }
}
