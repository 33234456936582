<div class="cross-market-main-container">
  <report-loader></report-loader>
  <report-alert class="report-main-content-padding no-print"></report-alert>
  <h1 class="title report-main-content-padding pdf">{{ title }}</h1>

  <problem-message [reportId]="reportId"></problem-message>

  <report-finra-toolbar
    *ngIf="crossMarketSupervisionToolbarConfig"
    [toolbarConfig]="crossMarketSupervisionToolbarConfig"
  ></report-finra-toolbar>

  <div class="margin-bottom-20"></div>

  <div id="data-container" *ngIf="reportLoadedSuccessfully">
    <div class="pdf margin-bottom bg-white" *ngIf="isChartDataReady">
      <div class="chart-title data-title">
        <div>
          {{ chartTitle }}
        </div>
      </div>
      <div class="report-main-content-bgcolor content">
        <report-finra-chart
          class="cross-market-chart"
          [rowData]="chartData"
          [view]="chartView"
          [template]="chartTemplate"
          [yAxisLabel]="yAxisLabel"
          [yAxisTicks]="yAxisTicks"
          [autoScale]="chartAutoScale"
        ></report-finra-chart>
      </div>
    </div>

    <div class="pdf bg-white" *ngIf="isGridDataReady">
      <div class="grid-title data-title table-header">
        <div>
          {{ gridTitle }}
        </div>
        <div>
          <span class="question-icon-container" (click)="openHelp()">
            <fa-icon
              data-html2canvas-ignore
              [icon]="faQuestionCircle"
              class="fa-lg question-icon"
            ></fa-icon>
            <span>Help</span>
          </span>
        </div>
      </div>
      <div>
        <report-finra-grid
          class="cross-market-grid"
          [rowData]="gridData"
          [columnDefs]="gridColumnDefs"
        ></report-finra-grid>
      </div>
    </div>
  </div>

  <hr />
  <div
    class="report-main-content-padding report-disclaimer pdf"
    *ngIf="disclaimer"
    [innerHtml]="disclaimer"
  ></div>
</div>
