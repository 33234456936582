import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirmInfo } from '../configs/model/firm-info.model';

@Injectable({
  providedIn: 'root'
})
export class FirmInfoService {
  private baseUrl: string;

  constructor(
    @Inject('environment')
    environment,
    private http: HttpClient
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getFirmInfo(firmId: string) {
    const api_url = `${this.baseUrl}firmInformation/firmId/${firmId}`;
    return this.http.get<FirmInfo[]>(api_url);
  }
}
