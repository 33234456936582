import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReportProblemData } from 'src/app/configs/model/report-problems.model';
import { ReportProblemFormData } from 'src/app/configs/model/report-problem-form-data.model';
import { AdminService } from 'src/app/services/admin.service';
import { ReportTypeEdition } from 'src/app/configs/model/admin.model';
import { checkIsLoprByConfigId } from 'src/app/configs/model/lopr/lopr.model';


@Component({
  selector: 'report-admin-report-problems-form',
  templateUrl: './problems-form.component.html',
  styleUrls: ['./problems-form.component.scss']
})
export class AdminReportProblemsFormComponent implements OnInit, OnChanges {
  @Input() isEditing: boolean;
  @Input() reportProblem: ReportProblemData;
  @Input() reportTypesList;
  @Output() formSubmitted = new EventEmitter<ReportProblemFormData>();
  periodsList;
  versionsList;
  maxMessageLength = 2000;
  reportProblemForm: FormGroup = new FormGroup({
    reportProblemId: new FormControl(null, []),
    reportType: new FormControl(null, [Validators.required]),
    reportPeriodDate: new FormControl(null, [Validators.required]),
    reportVersion: new FormControl(null, [Validators.required]),
    message: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(this.maxMessageLength)
    ]),
    invalidFlag: new FormControl(null, [Validators.required]),
  });

  isLoprReport: boolean;
  usePeriodFormat: string;

  constructor(
    private adminService: AdminService,
  ) {}

  ngOnInit() {
    this.reportProblemForm.get('reportType').valueChanges.subscribe((reportConfigId) => {
      this.isLoprReport = checkIsLoprByConfigId(reportConfigId);
      this.usePeriodFormat = this.isLoprReport
        ? 'date-month-day-year-full'
        : 'date-month-year';
      this.handleReportTypeChange(reportConfigId);
    });
  }

  handleReportTypeChange(reportConfigId) {
    if (!this.isEditing) {
      this.fetchPeriodsAndVerionsByReportConfigId(reportConfigId);
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.reportProblem) {
      this.reportProblemForm.get('reportProblemId').setValue(this.reportProblem.reportProblemId);
      this.reportProblemForm.get('reportType').setValue(this.reportProblem.reportDisplayName);
      this.reportProblemForm.get('reportPeriodDate').setValue(this.reportProblem.reportPeriodDate);
      this.reportProblemForm.get('reportVersion').setValue(this.reportProblem.reportVersion);
      this.reportProblemForm.get('message').setValue(this.reportProblem.message);
      this.reportProblemForm.get('invalidFlag').setValue(this.reportProblem.invalidFlag);
    }
  }

  clearForm() {
    this.reportProblemForm.get('reportPeriodDate').setValue(null);
    this.reportProblemForm.get('reportVersion').setValue(null);
    this.reportProblemForm.get('message').setValue('');
    this.reportProblemForm.get('invalidFlag').setValue(null);
  }

  fetchReportTypesEditions() {
    this.adminService.getAdminReportTypesEditions().subscribe(
      (response: ReportTypeEdition[]) => {
        this.reportTypesList = response;
      }
    );
  }

  fetchPeriodsAndVerionsByReportConfigId(reportConfigId) {
    this.clearForm();
    this.adminService.getPeriodsAndVerionsByReportConfigId(reportConfigId).subscribe(
      (response) => {
        this.periodsList = response;
      }
    );
  }

  onPeriodSelection(evt) {
    this.reportProblemForm.get('reportVersion').setValue(null);
    this.versionsList = [...evt.value.reportTypeEditionPeriodVersionList];
    if (!this.versionsList.find(v => v.rptDataVrsn === 0)) {
      this.versionsList.unshift({ rptDataVrsn: 0 });
    }
  }

  onSubmitForm() {
    // if the form is in edit mode, the date would of been set on the 'reportPeriodDate' form control
    // in the ngOnChanges method. if the form is in create mode, the 'reportPeriodDate' form control
    // will be an object, which came from the fetchPeriodsAndVerionsByReportProblemId(id) method;
    // the templates sets the value of the form control to that object since it will be easier to get
    // the versions with that specific period.
    const date = this.isEditing
      ? this.reportProblemForm.get('reportPeriodDate').value
      : (<any>this.reportProblemForm.get('reportPeriodDate').value).rptPrdDt;

    const data: ReportProblemFormData = {
      ...this.reportProblemForm.value,
      reportPeriodDate: date,
    };

    this.formSubmitted.emit(data);
  }
}
