import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Ag-grid enterprise
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(
  'CompanyName=Financial Industry Regulatory Authority Inc,LicensedApplication=Finra,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=6,AssetReference=AG-011537,ExpiryDate=30_October_2022_[v2]_MTY2NzA4NDQwMDAwMA==d6ef47761fd558c9eaea55b9888552e4'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(error => {
    throw error;
  });
