<div id="finra-info-modal-container">
  <h1 mat-dialog-title [innerHtml]="data.header"></h1>

  <div mat-dialog-content>
    <p [innerHtml]="data.body"></p>
  </div>

  <div mat-dialog-actions>
    <button
      mat-button
      cdkFocusInitial
      (click)="closeModal()"
    >
      {{ data.closeBtnText || 'OK' }}
    </button>
  </div>
</div>
