<div id="notifications-preferences-container">
  <report-loader></report-loader>

  <h1 class="title pdf">{{ title }}</h1>

  <section class="description-section">
    <p>
      Email notifications are sent to Report Center users when reports become available.
    </p>
    <p>
      To change your notification preferences, please choose the appropriate option below.
    </p>
  </section>

  <section *ngIf="!!preferencesForm">
    <form
      [formGroup]="preferencesForm" 
      class="form-container"
      (ngSubmit)="preferencesForm.valid && onSubmitForm()"
    >
      <div class="header-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="60" class="box-pad-1">
          Report Category
        </div>
        <div fxFlex="40" class="box-pad-1">
          <div fxFlex="25" class="box-pad-1">
            Off
          </div>
          <div fxFlex="25" class="box-pad-1">
            Daily
          </div>
          <div fxFlex="25" class="box-pad-1">
            Weekly
          </div>
          <div fxFlex="25" class="box-pad-1">
            Monthly
          </div>
        </div>
      </div>

      <div 
        *ngFor="let controlName of controlNames"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div fxFlex="60" class="box-pad-1">
          {{ controlName }}
        </div>
        <div fxFlex="40" class="box-pad-1">
          <mat-radio-group
            aria-label="Select an option"
            [formControlName]="controlName"
          >
            <!-- The value of the radio buttons are supposed to match the id of a periodicity -->
            <div fxFlex="25" class="box-pad-1" [attr.data-periodicity-value]="0">
              <mat-radio-button [value]="0"></mat-radio-button>
            </div>
            <div fxFlex="25" class="box-pad-1" [attr.data-periodicity-value]="1">
              <mat-radio-button [value]="1"></mat-radio-button>
            </div>
            <div fxFlex="25" class="box-pad-1" [attr.data-periodicity-value]="2">
              <mat-radio-button [value]="2"></mat-radio-button>
            </div>
            <div fxFlex="25" class="box-pad-1" [attr.data-periodicity-value]="3">
              <mat-radio-button [value]="3"></mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>

      <hr />

      <button
        mat-flat-button
        color="accent"
        type="submit"
        [disabled]="preferencesForm.invalid"
      >
        Submit
      </button>
    </form>
  </section>
</div>
