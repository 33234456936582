import { ToolbarConfigOptions, ToolbarConfig, ToolbarComponentTypes, ToolbarControlComponentTypes, COMMON_PDF_PRINT_BUTTON_CELL_TAG } from "../model/finra-toolbar.model";


export const OnlineLearningToolbarConfig = (
  config: ToolbarConfigOptions
): ToolbarConfig => ({
  description: config.description,
  rows: [
    // first row
    {
      rowClass: 'display-flex flex-space-between',
      components: [
        {
          cellClass: 'display-flex',
          components: [
            {
              cellClass: 'margin-right-30',
              component: {
                type: ToolbarComponentTypes.LABEL,
                label: config.firmNameLabel,
                value: config.firmNameValue,
                labelId: 'firmName',
              }
            },
            {
              cellClass: 'margin-right-30',
              component: {
                type: ToolbarComponentTypes.LABEL,
                label: config.firmIdLabel,
                value: config.firmIdValue,
                labelId: 'firmId'
              }
            }
          ]
        },
        {
          cellClass: 'display-flex',
          components: [
            {
              cellClass: 'help-btn margin-right-30',
              component: {
                type: ToolbarComponentTypes.BUTTON,
                buttonLabel: 'Help',
                buttonIcon: config.helpIcon,
                buttonIconClass: '',
                buttonClass: '',
                clickHandler: config.setHelpClickHandler,
              }
            },
            {
              cellClass: 'pdf-btn',
              cellTag: COMMON_PDF_PRINT_BUTTON_CELL_TAG,
              component: {
                type: ToolbarComponentTypes.BUTTON,
                buttonLabel: 'Print',
                buttonIcon: config.pdfIcon,
                buttonIconClass: '',
                buttonClass: '',
                clickHandler: null,
              }
            }
          ]
        },
      ]
    },
    // second row
    {
      rowClass: 'display-flex flex-space-between',
      components: [
        {
          cellClass: 'display-flex field-row-group',
          components: [
            {
              cellClass: 'margin-right-30 field-period-container',
              component: {
                type: ToolbarComponentTypes.CONTROL,
                controlType: ToolbarControlComponentTypes.SELECT,
                controlLabel: 'Period:',
                dataList: config.periods,
                formControl: config.periodFormControl,
                formControlClass: '',
                displayPipeFilter: 'date-quarter',
                formControlName: 'period',
              }
            },
            {
              cellClass: 'margin-right-30 field-version-container',
              component: {
                type: ToolbarComponentTypes.CONTROL,
                controlType: ToolbarControlComponentTypes.SELECT,
                controlLabel: 'Version:',
                dataList: config.versions,
                formControl: config.versionFormControl,
                formControlClass: '',
                formControlStyle: { width: '50px' },
                formControlName: 'version',
              }
            },
            {
              cellClass: 'margin-right-30',
              hideCell: config.hidePublishedState,
              component: {
                type: ToolbarComponentTypes.LABEL,
                label: 'Show',
                value: config.publishedState,
                labelId: 'showPublishedLabel'
              }
            },
            {
              cellClass: '',
              component: {
                type: ToolbarComponentTypes.BUTTON,
                buttonLabel: 'Go',
                buttonIcon: null,
                buttonIconClass: '',
                buttonClass: '',
                clickHandler: config.changeClickHandler,
              }
            }
          ]
        },
        {
          cellClass: 'display-flex',
          components: [
            {
              cellClass: '',
              component: {
                type: ToolbarComponentTypes.BUTTON,
                buttonLabel: 'Summary',
                buttonIcon: config.summaryIcon,
                buttonIconClass: '',
                buttonClass: '',
                clickHandler: config.summaryExportClickHandler,
                buttonId: 'summaryDataLink',
              }
            },
          ],
        }
      ]
    },
  ]
});
