import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  IconDefinition,
  faChevronRight,
  faChevronDown,
  faQuestionCircle,
  faDownload,
} from '@fortawesome/pro-light-svg-icons';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { UnsecuredCustomerDebitsMonthlySummary, MarginCustomerDebitsMonthlySummary } from 'src/app/configs/model/risk-monitoring/customer-debits.model';
import { RiskMonitoringReportNames } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickEventDetailExportInfo, BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { RiskMonitoringService } from 'src/app/services/risk-monitoring.service';

/**
 * Help link
 * @see: https://tools.finra.org/reportcenterhelp/#Risk_Monitoring_Reports.htm#Customer_Debits_Report
 */

@Component({
  selector: 'report-debits-grid',
  templateUrl: './debits-grid.component.html',
  styleUrls: ['./debits-grid.component.scss']
})
export class DebitsGridComponent implements OnInit {
  @Input() title: string;
  @Input() summaryData: UnsecuredCustomerDebitsMonthlySummary[] | MarginCustomerDebitsMonthlySummary[];
  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  faChevronRight: IconDefinition = faChevronRight;
  faChevronDown: IconDefinition = faChevronDown;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  faDownload: IconDefinition = faDownload;
  viewName: 'Daily' = 'Daily';

  constructor(
    private riskMonitoringService: RiskMonitoringService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {}

  exportSummaryMonth(
    summary: UnsecuredCustomerDebitsMonthlySummary | MarginCustomerDebitsMonthlySummary
  ) {
    const shortName = this.reportInstanceMetadata.reportConfiguration.reportType.reportTypeShortName;
    const reportFirmId = this.reportInstanceMetadata.reportFirmId;
    this.riskMonitoringService.customerDebitsCanceledAsOfTradesOnReportDetails(
      shortName,
      reportFirmId,
      summary.reportPeriod,
      summary.reportId,
      this.viewName
    );

    // log beast event
    const eventInfo: BeastClickEventDetailExportInfo = {
      reportId: this.reportInstanceMetadata.reportId.toString(),
      reportPeriod: this.datePipe.transform(summary.reportPeriod, 'yyyy-MM-dd'),
      reportView: this.viewName,
      reportVersion: this.reportInstanceMetadata.reportDataVersion,
      reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
      maturity: undefined,
      rating: undefined,
      product: undefined,
      firmId: reportFirmId,
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_DETAIL_DOWNLOAD_FROM_REPORT_PAGE,
      eventInfo
    );
  }

  openHelp() {
    this.helpLinksService.open(RiskMonitoringReportNames.CUSTOMER_DEBITS);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}
