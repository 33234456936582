export enum BeastClickActions {
  VIEW_UNREAD_REPORT = 'VIEW_UNREAD_REPORT',
  VIEW_REPORT_FROM_EXPLORER = 'VIEW_REPORT_FROM_EXPLORER',
  VIEW_REPORT_FROM_SEARCH = 'VIEW_REPORT_FROM_SEARCH',
  VIEW_DQS_REPORT_FROM_SEARCH = 'VIEW_DQS_REPORT_FROM_SEARCH',
  MAIN_NAVIGATION = 'MAIN_NAVIGATION',
  SUB_NAVIGATION = 'SUB_NAVIGATION',
  REPORT_SUMMARY_DOWNLOAD_FROM_REPORT_PAGE = 'REPORT_SUMMARY_DOWNLOAD_FROM_REPORT_PAGE',
  REPORT_DETAIL_DOWNLOAD_FROM_REPORT_PAGE = 'REPORT_DETAIL_DOWNLOAD_FROM_REPORT_PAGE',
  REPORT_DQS_DETAIL_DOWNLOAD_FROM_REPORT_PAGE = 'REPORT_DQS_DETAIL_DOWNLOAD_FROM_REPORT_PAGE',
  REPORT_FQS_DETAIL_DOWNLOAD_FROM_REPORT_PAGE = 'REPORT_FQS_DETAIL_DOWNLOAD_FROM_REPORT_PAGE',
  REPORT_DETAIL_DOWNLOAD_FROM_HOME_PAGE = 'REPORT_DETAIL_DOWNLOAD_FROM_HOME_PAGE',
  REPORT_DQS_DETAIL_DOWNLOAD_FROM_HOME_PAGE = 'REPORT_DQS_DETAIL_DOWNLOAD_FROM_HOME_PAGE',
  REPORT_FQS_DETAIL_DOWNLOAD_FROM_HOME_PAGE = 'REPORT_FQS_DETAIL_DOWNLOAD_FROM_HOME_PAGE',
  // DQS_DETAIL_EXPORT = 'DQS_DETAIL_EXPORT',
  // FQS_DETAIL_EXPORT = 'FQS_DETAIL_EXPORT',
  REPORT_TOOLBAR_GO = 'REPORT_TOOLBAR_GO',
  REPORT_DQS_TOOLBAR_GO = 'REPORT_DQS_TOOLBAR_GO',
  REPORT_PDF_PRINT = 'REPORT_PDF_PRINT',
  REPORT_DQS_PDF_PRINT = 'REPORT_DQS_PDF_PRINT',
  REPORT_HELP_LINK_CLICK = 'REPORT_HELP_LINK_CLICK',
  REPORT_DQS_HELP_LINK_CLICK = 'REPORT_DQS_HELP_LINK_CLICK',
  USAGE_LOG_HELP = 'USAGE_LOG_HELP',
}

export enum BeastOtherActions {
  SITE_REFERRER = 'SITE_REFERRER',
  SITE_REFERRER_UNKNOWN = 'SITE_REFERRER_UNKNOWN',
  ANNOUNCEMENTS_SCROLL = 'ANNOUNCEMENTS_SCROLL',
  LEAVE_SUMMARY_REPORT_PAGE = 'LEAVE_SUMMARY_REPORT_PAGE',
  TIME_ON_SUMMARY_REPORT_PAGE = 'TIME_ON_SUMMARY_REPORT_PAGE',
  SUMMARY_REPORT_PERIOD_SELECTION = 'SUMMARY_REPORT_PERIOD_SELECTION',
  SUMMARY_REPORT_VERSION_SELECTION = 'SUMMARY_REPORT_VERSION_SELECTION',
  SUMMARY_REPORT_VIEW_SELECTION = 'SUMMARY_REPORT_VIEW_SELECTION',
  SUMMARY_REPORT_MATURITY_SELECTION = 'SUMMARY_REPORT_MATURITY_SELECTION',
  SUMMARY_REPORT_RATING_SELECTION = 'SUMMARY_REPORT_RATING_SELECTION',
  SUMMARY_REPORT_PRODUCT_SELECTION = 'SUMMARY_REPORT_PRODUCT_SELECTION',
}

export type BeastEvent =
  BeastClickActions |
  BeastOtherActions
  ;
