<div class="public-offer-container">
  <div class="table-header">
    <div class="table-title">
      <span
        class="link-btn pdf"
        (click)="switchView('table')"
        [ngClass]="{ 'link-inactive': viewType == 'chart' }"
      >
        {{ title }}
      </span>
      <span class="link-inactive">|</span>
      <span
        class="link-btn"
        (click)="switchView('chart')"
        [ngClass]="{ 'link-inactive': viewType == 'table' }"
      >
        Charts
      </span>
    </div>
    <div>
      <span class="question-icon-container" (click)="openHelp()">
        <fa-icon
          data-html2canvas-ignore
          [icon]="faQuestionCircle"
          class="fa-lg question-icon"
        ></fa-icon>
        <span>Help</span>
      </span>
    </div>
  </div>
  <div *ngIf="rowData && !loading" class="pub-offer-grid">
    <div *ngIf="showView('table')">
      <report-finra-grid
        class="pdf"
        #reportfinragrid
        id="id"
        [columnDefs]="columnDefs"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [rowData]="rowData"
        [groupDefaultExpanded]="0"
        [gridOptions]="gridOptions"
        [hasNoRowGroup]="
          viewName == 'firmCorpFin5110ReviewCommentLetterSummary' ||
          viewName == 'firmCorpFin5110ReviewDistributionMethodSummary'
            ? true
            : false
        "
      ></report-finra-grid>
    </div>
    <div class="chart-container" *ngIf="showView('chart')">
      <report-finra-chart
        class="pdf"
        [template]="
          viewName == 'firmCorpFin5110ReviewCurrentPeriodSubmissionSummary'
            ? 'advanced-pie'
            : 'bar-group'
        "
        [rowData]="chartData"
        [pieLabel]="'Total for Firm'"
        [yAxisTickFormatting]="yAxisTickFormatting"
      ></report-finra-chart>
    </div>
  </div>
  <div *ngIf="!rowData && !loading" class="no-data-msg pdf">
    No data available for this section.
  </div>
  <div *ngIf="loading && !rowData" class="no-data-msg">Loading...</div>
</div>
