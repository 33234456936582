import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UsageLogService } from './services/usage-log.service';
import {
  MatDialog
} from '@angular/material/dialog';
import {
  IconDefinition,
  faCommentsAlt
} from '@fortawesome/pro-light-svg-icons';
import {
  isSupportedBrowser,
  getMainNavItems
} from './shared/utils/utils';
import { DetailDatasetRequestsService } from './services/detail-dataset-requests.service';
import { UnreadReportsService } from './services/unread-reports.service';
import { HelperService } from './services/helper.service';
import { BeastService } from './services/beast.service';
import { BeastSiteReferrerInfo } from './interfaces/beast.interface';
import { BeastOtherActions } from './enums/beast.enum';
import { catchError, map } from 'rxjs/operators';
import { UiWidgetsService } from './services/ui-widgets.service';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { TimeoutWarningComponent } from './components/fragments/timeout-warning/timeout-warning.component';
import { BROWSER_NOT_SUPPORTED_MESSAGE } from './configs/model/app.model';
import { HttpStatusCode } from './configs/model/http-status-codes.model';
import { ReportInstanceMetadatasResponse } from './configs/model/reports.model';
import { UserProfile, UserAccessTypes } from './configs/model/user-profile.model';
import { ReportsService } from './services/reports.service';
import { SessionService } from './services/session.service';
import { UserProfileService } from './services/user-profile.service';
import { NavItem } from './configs/model/sidenavigation';
import { MatIconRegistry } from '@angular/material/icon';
import { UsageLogExportRequestsService } from './services/usage-log-export-requests.service';

@Component({
  selector: 'report-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _navItems: Array<NavItem>;
  public sideNavItems: Array<NavItem>;
  faCommentsAlt: IconDefinition = faCommentsAlt;
  showBanner: boolean = false;
  bannerMessage: string = '';
  userAccessType: string = '';
  // DDWA-4673 Add current year to footer trademark message
  currentYear = (new Date()).getFullYear().toString();
  isLocationISSO: boolean;
  isLocalHost: boolean;
  issoUserIsNotAuthorized: boolean;
  issoRedirectMessage = 'You will be redirected to ISSO site.';
  issoUnauthorizedMessage: string = 'You are not entitled to access Report Center. Please contact your Access Manager to gain access.';
  userProfile: UserProfile;
  appLoaded: boolean = false;

  constructor(
    @Inject('environment') private environment,
    private matIconRegistry: MatIconRegistry,
    private usageLogService: UsageLogService,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private userProfileService: UserProfileService,
    private reportsService: ReportsService,
    private helperService: HelperService,
    private detailDatasetRequestsService: DetailDatasetRequestsService,
    private usageLogExportRequestsService: UsageLogExportRequestsService,
    private unreadReportsService: UnreadReportsService,
    private beastService: BeastService,
    private uiWidgetsService: UiWidgetsService,
    private location: Location,
  ) {}

  ngOnInit() {
    const baseHref = (<any> this.location)._baseHref;
    console.log(this, JSON.stringify(this.environment), { baseHref });
    this.matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    const checkSupportedBrowser = isSupportedBrowser();
    const isLocalHost = (
      window.location.href.startsWith('http://localhost:4200') ||
      window.location.href.startsWith('http://localhost.finra.org:4200')
    );
    this.isLocalHost = isLocalHost;
    const isLocationISSO = (
      this.userProfileService.isLocationISSO() ||
      !this.environment.isEwsReportCenter // for local development
    );
    this.isLocationISSO = isLocationISSO;
    if (!checkSupportedBrowser) {
      this.bannerMessage = BROWSER_NOT_SUPPORTED_MESSAGE;
      this.showBanner = true;
    }

    /*
      check if is in cloudfront (set via deploy step in build pipeline)

      NOTE: Cookies sent by the back-end will only work on *.finra.org domains.
      make sure the UI has a finra cname/domain alias for the cloudfront distribution it is linked to
      and is being accessed/load via that alias; the cookie will not be set otherwise and the app will not load/work.
    */
    if (this.userProfileService.isCloudFront()) {
      /*
        if UI is in cloudfront, fip does not automatically authenticate via intercepting the request;
        a manual check is needed.

        Check if user is authenticated via fip endpoint.
      */
      this.userProfileService.checkFipSessionCookie().toPromise()
        .then((isFipSessionValid: boolean) => {
          // session is valid,
          console.log({ isFipSessionValid });
          if (isFipSessionValid) {
            this.loadPage();
          }
          else {
            // session is not valid, go authenticate
            return this.userProfileService.authenticateWithFips();
          }
        })
        .catch((error) => {
          console.log({ error });
          this.uiWidgetsService.showErrorSnackbar(`Could not authenticate; something went wrong...`);
        });
    }
    else {
      // not cloudfront; proceed as usual
      this.loadPage();
    }
  }

  loadPage() {
    console.log(`app loading...`);
    Promise.resolve()
      .then(() => {
        return this.reportsService.getReportCategories().toPromise();
      })
      .then(() => {
        return this.reportsService.getPeriodicities().toPromise();
      })
      .then(() => {
        return this.userProfileService.getUserProfile().pipe(
          map((userProfile: UserProfile) => {
            this.handleUserProfile(userProfile);
          }),
          catchError((error: HttpErrorResponse) => {
            this.handleUserProfileError(error);
            throw error;
          })
        ).toPromise();
      })
      .then(() => {
        this.appLoaded = true;
        console.log(`app loaded...`);
      });
  }

  ngOnDestroy() {}

  handleUserProfile(userProfile: UserProfile) {
    console.log({ userProfile });

    this.userProfile = userProfile;
    // don't redirect when developing locally
    const shouldUserRedirect = this.userProfileService.shouldRedirect(userProfile);
    const shouldRedirect = !this.isLocalHost && shouldUserRedirect;
    if (shouldRedirect) {
      console.log(`redirecting user...`);
      return this.userProfileService.redirectToIssoUrl();
    }
    this._navItems = getMainNavItems({ accessType: userProfile.accessType });
    this.sideNavItems = this._navItems;
    // We only track session for EWS users
    if (userProfile.userType === UserAccessTypes.EWS) {
      if (!this.isLocalHost) {
        this.startSession(userProfile);
      }
      this.hideReportsForSECUser(userProfile);
    }

    // After user is authenticated, load detail dataset requests.
    // As long as users are on report center, query the data every 5 seconds
    // to check for updates
    this.startPollingServices();

    /**
      Log BEAST event for site referer

      for blank referer, log as separate event action to avoid clutter/skewing of other values.
    */
    const useAction: BeastOtherActions = !!window.document.referrer
      ? BeastOtherActions.SITE_REFERRER
      : BeastOtherActions.SITE_REFERRER_UNKNOWN;

    const eventInfo: BeastSiteReferrerInfo = {
      referrer: window.document.referrer,
      origin: window.location.origin,
      href: window.location.href,
    };
    console.log({ useAction, eventInfo });
    this.beastService.clickStream.postEvent(useAction, eventInfo);
  }

  startPollingServices() {
    // tell services to start polling for status
    this.detailDatasetRequestsService.startPolling();
    this.usageLogExportRequestsService.startPolling();

    // get unread reports
    const hasUnreadReportsAccess = this.helperService.hasUnreadReportsAccess(this.userProfile);
    if (hasUnreadReportsAccess) {
      this.getUnReadReports();
    }
  }

  handleUserProfileError(error: HttpErrorResponse) {
    const notAuthorized = (
      error.status === HttpStatusCode.CORS_ERROR ||
      error.status === HttpStatusCode.UNAUTHORIZED ||
      error.status === HttpStatusCode.FORBIDDEN
    );
    if (this.isLocationISSO) {
      this.issoUserIsNotAuthorized = notAuthorized;
    }
  }

  private hideReportsForSECUser(userProfile) {
    // DDWA-2835 Hide Reports For SEC Users
    this.userAccessType = userProfile.accessType;
    if (this.userAccessType === 'EWS_SEC') {
      this.sideNavItems = this.sideNavItems.filter(item => {
        return item.route !== 'reports';
      });
    }
  }

  private startSession(userProfile: UserProfile) {
    this.sessionService.startSession(
      userProfile,
      (): Promise<any> => {
        return this.usageLogService.recordLogin().toPromise();
      },
      (): Promise<any> => {
        return this.usageLogService.recordLogout().toPromise();
      },
      (
        durationMillis,
        logoutAction: () => Promise<any>,
        continueAction: () => Promise<any>
      ) => {
        const dialogRef = this.dialog.open(TimeoutWarningComponent, {
          height: '200px',
          width: '600px',
          data: { durationMillis: durationMillis }
        });
        dialogRef.beforeClosed().subscribe((continueSessionFlag: boolean) => {
          if (continueSessionFlag) {
            continueAction();
          } else {
            logoutAction();
          }
        });
      }
    );
  }

  getUnReadReports() {
    this.reportsService.getUnReadReports().subscribe(
      (data: ReportInstanceMetadatasResponse) => {
        this.unreadReportsService.setUnreadReports(data.reportInstanceMetadatas);
      },
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpStatusCode.ANGULAR_ERROR:
          case HttpStatusCode.NOT_FOUND: {}
        }
      }
    );
  }
}
