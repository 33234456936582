import {
  finraGridPercentValueFormatter,
  finraGridNumericValueFormatter,
  finraGridNumericValueFormatterNoRound,
  SummaryExportData,
  getCsvExportData
} from 'src/app/shared/utils/utils';

export interface SalesPracticeComplaintFirmData {
  SalesPracticeComplaintsPer$MSales: number;
  SalesPracticeComplaintCount: number;
  Crdid: string;
  SalesPracticeComplaintsPer$MSalesPercentageChange: number;
  TotalRep: number;
  ReportId: number;
  Period: string;
  SalesRevenue: number;
  SalesPracticeComplaintsPer100Reps: number;
  SalesPracticeComplaintsPer100RepsPercentageChange: number;
  SalesPracticeComplaintPercentageChange: number;
}

export interface SalesPracticeComplaintIndustryData {
  IndSalesPracticeComplaintsPer$MSales: number;
  IndSalesPracticeComplaintsPer$MSalesPercentageChange: number;
  Period: string;
  IndSalesPracticeComplaintsPer100RepsPercentageChange: number;
  IndSalesPracticeComplaintsPer100Reps: number;
  IndSalesPracticeComplaintCount: number;
  IndSalesPracticeComplaintCountPercentageChange: number;
}

export interface SalesPracticeComplaintSummaryResponse {
  data: {
    firm: SalesPracticeComplaintFirmData[];
    industry: SalesPracticeComplaintIndustryData[];
  };
}

export const FIRM_COL_DEFS = [
  {
    headerName: 'Period',
    field: 'Period',
    minWidth: 70
  },
  {
    headerName: 'Sales Practice Complaints',
    field: 'SalesPracticeComplaintCount',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '% Change from Prior Period',
    field: 'SalesPracticeComplaintPercentageChange',
    valueFormatter: finraGridPercentValueFormatter
  },
  {
    headerName: 'Total Reps',
    field: 'TotalRep',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Sales Practice Complaints per 100 Reps',
    field: 'SalesPracticeComplaintsPer100Reps',
    valueFormatter: finraGridNumericValueFormatterNoRound
  },
  {
    headerName: '% Change from Prior Period',
    field: 'SalesPracticeComplaintsPer100RepsPercentageChange',
    valueFormatter: finraGridPercentValueFormatter
  },
  {
    headerName: 'Sales Revenue($)',
    field: 'SalesRevenue',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Sales Practice Complaints per $M Sales',
    field: 'SalesPracticeComplaintsPer$MSales',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '% Change from Prior Period',
    field: 'SalesPracticeComplaintsPer$MSalesPercentageChange',
    valueFormatter: finraGridPercentValueFormatter
  }
];

export const INDUSTRY_COL_DEFS = [
  {
    headerName: 'Period',
    field: 'Period',
    minWidth: 70
  },
  {
    headerName: 'Sales Practice Complaints',
    field: 'IndSalesPracticeComplaintCount',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '% Change from Prior Period',
    field: 'IndSalesPracticeComplaintCountPercentageChange',
    valueFormatter: finraGridPercentValueFormatter
  },
  {
    headerName: 'Average Sales Practice complaints per 100 Reps',
    field: 'IndSalesPracticeComplaintsPer100Reps',
    valueFormatter: finraGridNumericValueFormatterNoRound
  },
  {
    headerName: '% Change from Prior Period',
    field: 'IndSalesPracticeComplaintsPer100RepsPercentageChange',
    valueFormatter: finraGridPercentValueFormatter
  },
  {
    headerName: 'Sales Practice Complaints per $M Sales',
    field: 'IndSalesPracticeComplaintsPer$MSales',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '% Change from Prior Period',
    field: 'IndSalesPracticeComplaintsPer$MSalesPercentageChange',
    valueFormatter: finraGridPercentValueFormatter
  }
];

export const FIRM_MODEL: SalesPracticeComplaintFirmData = {
  Crdid: null,
  ReportId: null,
  Period: null,
  SalesPracticeComplaintCount: null,
  SalesPracticeComplaintPercentageChange: null,
  TotalRep: null,
  SalesPracticeComplaintsPer100Reps: null,
  SalesPracticeComplaintsPer100RepsPercentageChange: null,
  SalesRevenue: null,
  SalesPracticeComplaintsPer$MSales: null,
  SalesPracticeComplaintsPer$MSalesPercentageChange: null
};

export const INDUSTRY_MODEL: SalesPracticeComplaintIndustryData = {
  Period: null,
  IndSalesPracticeComplaintCount: null,
  IndSalesPracticeComplaintCountPercentageChange: null,
  IndSalesPracticeComplaintsPer100Reps: null,
  IndSalesPracticeComplaintsPer100RepsPercentageChange: null,
  IndSalesPracticeComplaintsPer$MSales: null,
  IndSalesPracticeComplaintsPer$MSalesPercentageChange: null
};

export const SALES_PRACTICE_COL_DEFS = {
  firm: FIRM_COL_DEFS,
  industry: INDUSTRY_COL_DEFS
};

export const SALES_PRACTICE_TABLE_TITLES = {
  firm: 'Firm',
  industry: 'Industry'
};

export function salesPracticeComplaintSummaryExportData(
  rowData: {
    firm: SalesPracticeComplaintFirmData[],
    industry: SalesPracticeComplaintIndustryData[]
  }
): SummaryExportData[] {
  const firmColumnLabels = FIRM_COL_DEFS.map(i => i.headerName);
  const firmDataMappings = FIRM_COL_DEFS.map(i => i.field);

  const industryColumnLabels = INDUSTRY_COL_DEFS.map(i => i.headerName);
  const industryDataMappings = INDUSTRY_COL_DEFS.map(i => i.field);

  return [
    {
      title: 'Firm',
      rowData: rowData.firm,
      columnLabels: firmColumnLabels,
      dataMappings: firmDataMappings,
    },
    {
      title: 'Industry',
      rowData: rowData.industry,
      columnLabels: industryColumnLabels,
      dataMappings: industryDataMappings,
    }
  ];
}

export function exportSalesPracticeComplaintSummary(
  rowData: {
    firm: SalesPracticeComplaintFirmData[],
    industry: SalesPracticeComplaintIndustryData[]
  },
  useFileName?: string
) {
  const exportData = salesPracticeComplaintSummaryExportData(rowData);
  getCsvExportData(exportData, useFileName);
}
