<ng-container [ngSwitch]="template">
  <!-- Card Template -->
  <div
    *ngSwitchCase="'card'"
    class="card-main-container"
    [ngClass]="{
      'card-reports-unread': cardSectionUnread == true,
      'card-reports-viewed': cardSectionViewed == true
    }"
    fxLayout="column"
    fxLayoutAlign="start none"
  >
    <div class="card-text-container" fxFlex="100" fxFlexFill>
      <div
        class="date-alert-container"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div class="card-date" fxFlex="95" *ngIf="reportDate">
          {{ reportDate }} - Version {{ reportVersion }}
        </div>
        <!-- <fa-icon [icon]="faLockAlt" class="card-icon"></fa-icon> -->
        <fa-icon *ngIf="isExternalUser" [icon]="faIcon" class="fa-lg card-icon"></fa-icon>
        <!-- <mat-icon fontSet="fa" [fontIcon]="faIcon" class="fa-lg card-icon" fxFlex="5"></mat-icon> -->
      </div>
      <div class="card-subheading">
        {{ reportSubHeading }}
      </div>
    </div>
    <div class="period">
      {{ reportPeriod }}
    </div>
  </div>
  <!-- List Template -->
  <div
    *ngSwitchCase="'list'"
    class="list-main-container"
    [ngClass]="{
      'list-reports-unread': cardSectionUnread == true,
      'list-reports-viewed': cardSectionViewed == true
    }"
    fxLayout="row"
    fxLayoutAlign="start none"
  >
    <!-- <div class="card-text-container"> -->
    <div class="list-subheading" fxFlex="60">
      {{ reportSubHeading }}
    </div>
    <div class="list-date" fxFlex="23">
      <span *ngIf="!isDQS">{{ reportDate }} - Version {{ reportVersion }}</span>
    </div>
    <div class="period" fxFlex="15">
      {{ reportPeriod }}
    </div>
    <div fxFlex="2">
      <fa-icon [icon]="faIcon" class="fa-lg card-icon"></fa-icon>
    </div>
    <!-- </div> -->
  </div>
</ng-container>
