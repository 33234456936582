<ng-container>
  <div class="cards-filter-container" fxLayout="row wrap">
    <form fxFlex="100" [formGroup]="filterForm" (ngSubmit)="onFirmFilter()">
      <!-- id -->
      <div fxFlex="1 1 20">
        <mat-form-field [floatLabel]="'never'">
          <input
            *ngIf="!isEWSUser || isSROUser || isFRBUser"
            type="text"
            matInput
            [placeholder]="cardsFilterLabel"
            formControlName="firmId"
          />
          <mat-select
            *ngIf="isEWSUser && !isFRBUser"
            [placeholder]="cardsFilterLabel"
            formControlName="firmId"
          >
            <mat-option *ngFor="let mpid of mpidsEWS" [value]="mpid">
              {{ mpid }}
            </mat-option>
          </mat-select>
          <fa-icon
            *ngIf="isEWSUser && !isSROUser && !isFRBUser"
            [icon]="faAngleDown"
            class="fa-lg filter-icon"
          ></fa-icon>
          <mat-error *ngIf="filterForm.controls.firmId.errors && filterForm.controls.firmId.errors.required">
            *Required.
          </mat-error>
          <!-- <mat-error *ngIf="filterForm.controls.firmId.errors && filterForm.controls.firmId.errors.pattern">
            *Alphanumeric characters only
          </mat-error> -->
        </mat-form-field>
      </div>
      <!-- state -->
      <div fxFlex="1 1 35" *ngIf="showPublishedOptions">
        <mat-form-field [floatLabel]="'never'">
          <mat-select placeholder="Is Published?" formControlName="state">
            <mat-option
              *ngFor="let published of publishedOptions"
              [value]="published.value"
            >
              {{ published.label }}
            </mat-option>
          </mat-select>
          <fa-icon [icon]="faAngleDown" class="fa-lg filter-icon"></fa-icon>
          <mat-error *ngIf="filterForm.controls.state.errors && filterForm.controls.state.errors.required">
            *Required.
          </mat-error>
          <!-- <mat-error *ngIf="filterForm.controls.state.errors && filterForm.controls.state.errors.pattern">
            *Alphanumeric characters only
          </mat-error> -->
        </mat-form-field>
      </div>
      <div fxFlex="1 1 45">
        <button mat-flat-button color="accent" class="filter-button">
          FILTER
        </button>
      </div>
    </form>
  </div>
</ng-container>
