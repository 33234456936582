import {
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'report-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() errorMessage: string;

  constructor() {}

  ngOnInit() {}
}
