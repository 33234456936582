import {
  finraGridNumericValueFormatter,
  finraGridPercentValueFormatter,
  finraGridAmountValueFormatter,
  finraGridIntegerPercentValueFormatter,
  getMonthYrDate,
} from 'src/app/shared/utils/utils';

export const DisclosureReportCategoryId = 2;

export enum DisclosureReportTypeIds {
  TIMELINESS_4530 = 18,
  LATE_FILING = 3,
  TIMELINESS_BD = 63,
}

export enum DisclosureReportShortNames {
  TIMELINESS_4530 = 'd4530',
  LATE_FILING = 'crd',
  TIMELINESS_BD = 'bdtime',
}

/** Interfaces */

// 4530 Disclosure Timeliness Report

export interface Firm4530DisclosureFiling {
  flngNumber: number;
  dsclrEventDs: string;
  subDate: string;
  discDate: string;
}

export interface Firm4530DisclosureFilingSummaryData {
  period: string;
  associateIndividualCount: number;
  firmInitialFilingsCount: number;
  firmLateFilingsCount: number;
  firmLateFilingsPrct: number;
  firmAmndFilingsCount: number;
  expanded: boolean;
  filings: Firm4530DisclosureFiling[];
}

export interface Industry4530DisclosureFiling {
  period: string;
  industryInitialFilingsCount: number;
  industryLateFilingsCount: number;
  industryLateFilingsPrct: number;
  industryAmndFilingsCount: number;
}

export interface Firm4530LateDisclosureFiling {
  period: string;
  firmLateFilingsLT5Count: number;
  firmLateFilingsGT6LT10Count: number;
  firmLateFilingsGT11LT20Count: number;
  getFirmLateFilingsGT21LT30Count: number;
  getFirmLateFilingsGT31Count: number;
}

export interface Firm4530DisclosureFilingAverages {
  associateIndividualCountAverage: string;
  firmInitialFilingsCountAverage: string;
  firmLateFilingsCountAverage: string;
  firmLateFilingsPrctAverage: string;
  firmAmndFilingsCountAverage: string;
}

export interface Industry4530DisclosureFilingAverages {
  industryInitialFilingsCountAverage: string;
  industryLateFilingsCountAverage: string;
  industryLateFilingsPrctAverage: string;
  industryAmndFilingsCountAverage: string;
}

export interface Disclosure4530TimelinessSummaryResponse {
  firm4530DisclosureFilings: Firm4530DisclosureFilingSummaryData[];
  industry4530DisclosureFilings: Industry4530DisclosureFiling[];
  firm4530LateDisclosureFilings: Firm4530LateDisclosureFiling[];
  firm4530DisclosureFilingAverages: Firm4530DisclosureFilingAverages;
  industry4530DisclosureFilingAverages: Industry4530DisclosureFilingAverages;
}

// Web CRD® Late Filing Fee Report

export interface IndustryTotalsLateDisclosureFeeData {
  period: string;
  industryU5FilingLateCount: number;
  industryPercentU4FilingsIncurringLateFee: number;
  industryU4FilingCount: number;
  industryPercentU5FilingsIncurringLateFee: number;
  industryU5FilingCount: number;
  industryU4FilingLateCount: number;
}

export interface FirmTotalsFormU4U5FilingData {
  period: string;
  repsCount: number;
  totalAmendmentU5FilingCount: number;
  totalInitialU4FilingCount: number;
  totalFullU5FilingCount: number;
  totalAmendmentU4FilingCount: number;
  totalPartialU5FilingCount: number;
}

export interface FirmTotalsFormU5LateDisclosureFilingDetail {
  period: string;
  u5FilingDisclosureP1Count: number;
  u5FilingDisclosureP2Count: number;
  u5FilingDisclosureP3Count: number;
  u5FilingDisclosureP4Count: number;
  u5FilingDisclosureP5Count: number;
}

export interface FirmTotalsLateDisclosureFeeData {
  period: string;
  potentiallyChargeableU5FilingCount: number;
  potentiallyChargeableU4FilingCount: number;
  lateChargeU4FilingCount: number;
  firmPercentU4FilingsIncurringLateFee: number;
  totalFeeAmount: number;
  lateChargeU5FilingCount: number;
  firmPercentU5FilingsIncurringLateFee: number;
}

export interface IndustryTotalsLateTerminationFeeData {
  period: string;
  industryExpectedU5FilingCount: number;
  industryLateTerminationCount: number;
}

export interface FirmTotalsFormU4LateDisclosureFilingData {
  period: string;
  u4FilingDisclosureP1Count: number;
  u4FilingDisclosureP2Count: number;
  u4FilingDisclosureP3Count: number;
  u4FilingDisclosureP4Count: number;
  u4FilingDisclosureP5Count: number;
}

export interface FirmTotalsLateTerminationFeeData {
  period: string;
  lateTerminationSubmittedCount: number;
  lateTerminationLateFeeAmount: number;
  lateTerminationExpectedCount: number;
}

export interface DisclosureLateFilingSummaryResponse {
  industryTotalsLateDisclosureFeeSummary: IndustryTotalsLateDisclosureFeeData[];
  firmTotalsFormU4U5FilingSummary: FirmTotalsFormU4U5FilingData[];
  firmTotalsFormU5LateDisclosureFilingDetails: FirmTotalsFormU5LateDisclosureFilingDetail[];
  firmTotalsLateDisclosureFeeSummary: FirmTotalsLateDisclosureFeeData[];
  industryTotalsLateTerminationFeeSummary: IndustryTotalsLateTerminationFeeData[];
  firmTotalsFormU4LateDisclosureFilingDetails: FirmTotalsFormU4LateDisclosureFilingData[];
  firmTotalsLateTerminationFeeSummary: FirmTotalsLateTerminationFeeData[];
}

// Web CRD® Form BD Timeliness Report

export interface BdNonDisclosureEventSummaryData {
  reportDate: string;
  firmUpdatesLate: number;
  industryNonDisclosureTotal: number;
  totalNonDisclosureCount: number;
  totalNonDisclosureLateCount: number;
  sortOrder: number;
  industryNonDisclosureLate: number;
  industryUpdatesLate: number;
}

export interface BdLateDisclosureEventSummaryData {
  reportDate: string;
  disclosureP1Count: number;
  disclosureP3Count: number;
  sortOrder: number;
  disclosureP2Count: number;
  disclosureP5Count: number;
  disclosureP4Count: number;
}

export interface BdLateNonDisclosureEventSummaryData {
  reportDate: string;
  nonDisclosureP5Count: number;
  nonDisclosureP1Count: number;
  nonDisclosureP2Count: number;
  sortOrder: number;
  nonDisclosureP3Count: number;
  nonDisclosureP4Count: number;
}

export interface BdDisclosureEventSummaryData {
  reportDate: string;
  firmUpdatesLate: number;
  industryDisclosureTotal: number;
  sortOrder: number;
  totalDisclosureCount: number;
  industryUpdatesLate: number;
  totalDisclosureLateCount: number;
  industryDisclosureLate: number;
}

export interface DisclosureBdTimelinessSummaryResponse {
  bdNonDisclosureEvents: BdNonDisclosureEventSummaryData[];
  bdLateDisclosureEvents: BdLateDisclosureEventSummaryData[];
  bdLateNonDisclosureEvents: BdLateNonDisclosureEventSummaryData[];
  bdDisclosureEvents: BdDisclosureEventSummaryData[];
}

/** Constants */

export const TABLE_TITLES = {
  bdDisclosureEvents: 'FORM BD DISCLOSURE EVENTS',
  bdLateDisclosureEvents: 'FORM BD LATE DISCLOSURE EVENTS FIRM DETAILS',
  bdNonDisclosureEvents: 'FORM BD NON-DISCLOSURE EVENTS',
  bdLateNonDisclosureEvents: 'FORM BD LATE NON-DISCLOSURE EVENT FIRM DETAILS',
  firmTotalsFormU4U5FilingSummary: 'Firm Totals - Form U4/U5 Filing Summary',
  firmTotalsLateDisclosureFeeSummary:
    'Firm Totals - Late Disclosure Fee Summary',
  firmTotalsFormU4LateDisclosureFilingDetails:
    'Firm Totals - Form U4 Late Disclosure Filing Details',
  firmTotalsFormU5LateDisclosureFilingDetails:
    'Firm Totals - Form U5 Late Disclosure Filing Details',
  industryTotalsLateDisclosureFeeSummary:
    'Industry Totals - Late Disclosure Fee Summary',
  firmTotalsLateTerminationFeeSummary:
    'Firm Totals - Late Termination Fee Summary',
  industryTotalsLateTerminationFeeSummary:
    'Industry Totals - Late Termination Fee Summary',
  industry4530DisclosureFilings: 'INDUSTRY TOTALS - 4530 DISCLOSURE FILINGS',
  firm4530LateDisclosureFilings:
    'FIRM TOTALS - 4530 LATE DISCLOSURE FILING DETAILS'
};

export const TABLE_NAMES_BY_ORDER = {
  [DisclosureReportShortNames.TIMELINESS_BD]: [
    'bdDisclosureEvents',
    'bdLateDisclosureEvents',
    'bdNonDisclosureEvents',
    'bdLateNonDisclosureEvents'
  ],
  [DisclosureReportShortNames.LATE_FILING]: [
    'firmTotalsFormU4U5FilingSummary',
    'firmTotalsLateDisclosureFeeSummary',
    'firmTotalsFormU4LateDisclosureFilingDetails',
    'firmTotalsFormU5LateDisclosureFilingDetails',
    'industryTotalsLateDisclosureFeeSummary',
    'firmTotalsLateTerminationFeeSummary',
    'industryTotalsLateTerminationFeeSummary'
  ],
  [DisclosureReportShortNames.TIMELINESS_4530]: ['industry4530DisclosureFilings', 'firm4530LateDisclosureFilings']
};

const averageValueFormatter = (params) => {
  const value = finraGridIntegerPercentValueFormatter(params);
  return params.data.reportDate === 'Average'
    ? (value || '').toString().replace('%', '')
    : value;
};

const useAverageValueFormatter = (params) => {
  return params.data['reportDate'] === 'Average'
    ? averageValueFormatter(params)
    : finraGridNumericValueFormatter(params);
};


export const BD_DISCLOSURE_COLS = [
  {
    headerName: 'Period',
    field: 'reportDate',
    valueFormatter: params => {
      return params.value !== 'Average'
        ? getMonthYrDate(params.value)
        : 'Average';
    }
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    children: [
      {
        headerName: 'Form BD Disclosure Updates',
        field: 'totalDisclosureCount',
        headerClass: 'lines',
        cellClass: 'lines',
        valueFormatter: useAverageValueFormatter,
      },
      {
        headerName: 'Disclosure Updates Filed Late',
        field: 'totalDisclosureLateCount',
        valueFormatter: useAverageValueFormatter
      },
      {
        headerName: '% Updates Late',
        field: 'firmUpdatesLate',
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    children: [
      {
        headerName: 'Form BD Disclosure Updates',
        field: 'industryDisclosureTotal',
        valueFormatter: useAverageValueFormatter,
        headerClass: 'lines',
        cellClass: 'lines'
      },
      {
        headerName: 'Disclosure Updates Filed Late',
        field: 'industryDisclosureLate',
        valueFormatter: useAverageValueFormatter
      },
      {
        headerName: '% Updates Late',
        field: 'industryUpdatesLate',
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  }
];
export const BD_LATE_DISCLOSURE_COLS = [
  {
    headerName: 'Period',
    field: 'reportDate',
    valueFormatter: params => {
      return getMonthYrDate(params.value);
    }
  },
  {
    headerName: '1 - 10 Days Late',
    field: 'disclosureP1Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '11 - 20 Days Late',
    field: 'disclosureP2Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '21 - 30 Days Late',
    field: 'disclosureP3Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '31 - 60 Days Late',
    field: 'disclosureP4Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '> 60 Days Late',
    field: 'disclosureP5Count',
    valueFormatter: finraGridNumericValueFormatter
  }
];

export const BD_NON_DISCLOSURE_COLS = [
  {
    headerName: 'Period',
    field: 'reportDate',
    valueFormatter: params => {
      return params.value !== 'Average'
        ? getMonthYrDate(params.value)
        : 'Average';
    }
  },
  {
    headerName: 'Firm',
    headerClass: 'lines',
    children: [
      {
        headerName: 'Form BD Non-Disclosure Updates',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'totalNonDisclosureCount',
        valueFormatter: useAverageValueFormatter
      },
      {
        headerName: 'Non-Disclosure Updates Filed Late',
        field: 'totalNonDisclosureLateCount',
        valueFormatter: useAverageValueFormatter
      },
      {
        headerName: '% Updates Late',
        field: 'firmUpdatesLate',
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  },
  {
    headerName: 'Industry',
    headerClass: 'lines',
    children: [
      {
        headerName: 'Form BD Non-Disclosure Updates',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'industryNonDisclosureTotal',
        valueFormatter: useAverageValueFormatter
      },
      {
        headerName: 'Non-Disclosure Updates Filed Late',
        field: 'industryNonDisclosureLate',
        valueFormatter: useAverageValueFormatter
      },
      {
        headerName: '% Updates Late',
        field: 'industryUpdatesLate',
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  }
];

export const BD_LATE_NON_DISCLOSURE_COLS = [
  {
    headerName: 'Period',
    field: 'reportDate',
    valueFormatter: params => {
      return getMonthYrDate(params.value);
    }
  },
  {
    headerName: '1 - 10 Days Late',
    field: 'nonDisclosureP1Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '11 - 20 Days Late',
    field: 'nonDisclosureP2Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '21 - 30 Days Late',
    field: 'nonDisclosureP3Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '31 - 60 Days Late',
    field: 'nonDisclosureP4Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '> 60 Days Late',
    field: 'nonDisclosureP5Count',
    valueFormatter: finraGridNumericValueFormatter
  }
];

export const WEB_CRD_TIMELINESS_COLS = {
  bdDisclosureEvents: BD_DISCLOSURE_COLS,
  bdLateDisclosureEvents: BD_LATE_DISCLOSURE_COLS,
  bdNonDisclosureEvents: BD_NON_DISCLOSURE_COLS,
  bdLateNonDisclosureEvents: BD_LATE_NON_DISCLOSURE_COLS
};

export const FIRM_FORM_U4U5_FILING_COLS = [
  {
    headerName: '',
    children: [
      {
        headerName: 'Month',
        field: 'period',
        valueFormatter: params => {
          return getMonthYrDate(params.value);
        }
      },
      {
        headerName: 'Registered Reps',
        field: 'repsCount',
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  },
  {
    headerName: 'Total Filings Submitted',
    headerClass: 'lines',
    children: [
      {
        headerName: 'Initial-Dual-Relicense U4',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'totalInitialU4FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'U4 Amendment',
        field: 'totalAmendmentU4FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'Full U5',
        field: 'totalFullU5FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'U5 Amendment',
        field: 'totalAmendmentU5FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'U5 Partial',
        field: 'totalPartialU5FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  }
];
export const FIRM_LATE_DISCLOSURE_FEE_COLS = [
  {
    headerName: '',
    children: [
      {
        headerName: 'Month',
        field: 'period',
        valueFormatter: params => {
          return getMonthYrDate(params.value);
        }
      }
    ]
  },
  {
    headerName: 'Total Disclosure Filings Eligible',
    headerClass: 'lines',
    children: [
      {
        headerName: 'U4',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'potentiallyChargeableU4FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'U5',
        field: 'potentiallyChargeableU5FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  },
  {
    headerName: 'Number of Filings Incurring a Late Fee',
    headerClass: 'lines',
    children: [
      {
        headerName: 'U4',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'lateChargeU4FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'U5',
        field: 'lateChargeU5FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  },
  {
    headerName: '% Filings Incurring Late Fee',
    headerClass: 'lines',
    children: [
      {
        headerName: 'U4',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'firmPercentU4FilingsIncurringLateFee',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'U5',
        field: 'firmPercentU5FilingsIncurringLateFee',
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  },
  {
    headerName: '',
    headerClass: 'lines',
    children: [
      {
        headerName: 'Total Late Fees Assessed $',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'totalFeeAmount',
        valueFormatter: finraGridAmountValueFormatter
      }
    ]
  }
];
export const FIRM_FORM_U4_LATE_DISCLOSURE_COLS = [
  {
    headerName: 'Month',
    field: 'period',
    valueFormatter: params => {
      return getMonthYrDate(params.value);
    }
  },
  {
    headerName: '>=1 Day',
    field: 'u4FilingDisclosureP1Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '>=11 Days',
    field: 'u4FilingDisclosureP2Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '>=21 Days',
    field: 'u4FilingDisclosureP3Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '>=31 Days',
    field: 'u4FilingDisclosureP4Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '>=61 Days',
    field: 'u4FilingDisclosureP5Count',
    valueFormatter: finraGridNumericValueFormatter
  }
];
export const FIRM_FORM_U5_LATE_DISCLOSURE_COLS = [
  {
    headerName: 'Month',
    field: 'period',
    valueFormatter: params => {
      return getMonthYrDate(params.value);
    }
  },
  {
    headerName: '>=1 Day',
    field: 'u5FilingDisclosureP1Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '>=11 Days',
    field: 'u5FilingDisclosureP2Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '>=21 Days',
    field: 'u5FilingDisclosureP3Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '>=31 Days',
    field: 'u5FilingDisclosureP4Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '>=61 Days',
    field: 'u5FilingDisclosureP5Count',
    valueFormatter: finraGridNumericValueFormatter
  }
];
export const INDUSTRY_LATE_DISCLOSURE_FEE_COLS = [
  {
    headerName: '',
    children: [
      {
        headerName: 'Month',
        field: 'period',
        valueFormatter: params => {
          return getMonthYrDate(params.value);
        }
      }
    ]
  },
  {
    headerName: 'Total Disclosure Filings Eligible',
    headerClass: 'lines',
    children: [
      {
        headerName: 'U4',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'industryU4FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'U5',
        field: 'industryU5FilingCount',
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  },
  {
    headerName: 'Number of Filings Incurring a Late Fee',
    headerClass: 'lines',
    children: [
      {
        headerName: 'U4',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'industryU4FilingLateCount',
        valueFormatter: finraGridNumericValueFormatter
      },
      {
        headerName: 'U5',
        field: 'industryU5FilingLateCount',
        valueFormatter: finraGridNumericValueFormatter
      }
    ]
  },
  {
    headerName: '% Filings Incurring Late Fee',
    headerClass: 'lines',
    children: [
      {
        headerName: 'U4',
        headerClass: 'lines',
        cellClass: 'lines',
        field: 'industryPercentU4FilingsIncurringLateFee',
        valueFormatter: finraGridPercentValueFormatter
      },
      {
        headerName: 'U5',
        field: 'industryPercentU5FilingsIncurringLateFee',
        valueFormatter: finraGridPercentValueFormatter
      }
    ]
  }
];
export const FIRM_LATE_TERMINATION_COLS = [
  {
    headerName: 'Month',
    field: 'period',
    valueFormatter: params => {
      return getMonthYrDate(params.value);
    }
  },
  {
    headerName: 'Submitted U5 Late Termination Fee Count',
    field: 'lateTerminationSubmittedCount',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Expected U5 Late Termination Fee Count',
    field: 'lateTerminationExpectedCount',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Total Late Fees Assessed $',
    field: 'lateTerminationLateFeeAmount',
    valueFormatter: finraGridAmountValueFormatter
  }
];
export const INDUSTRY_LATE_TERMINATION_COLS = [
  {
    headerName: 'Month',
    field: 'period',
    valueFormatter: params => {
      return getMonthYrDate(params.value);
    }
  },
  {
    headerName: 'Submitted U5 Late Termination Fee Count',
    field: 'industryLateTerminationCount',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Expected U5 Late Termination Fee Count',
    field: 'industryExpectedU5FilingCount',
    valueFormatter: finraGridNumericValueFormatter
  }
];

export const CRD_LATE_FILING_COLS = {
  firmTotalsFormU4U5FilingSummary: FIRM_FORM_U4U5_FILING_COLS,
  firmTotalsLateDisclosureFeeSummary: FIRM_LATE_DISCLOSURE_FEE_COLS,
  firmTotalsFormU4LateDisclosureFilingDetails: FIRM_FORM_U4_LATE_DISCLOSURE_COLS,
  firmTotalsFormU5LateDisclosureFilingDetails: FIRM_FORM_U5_LATE_DISCLOSURE_COLS,
  industryTotalsLateDisclosureFeeSummary: INDUSTRY_LATE_DISCLOSURE_FEE_COLS,
  firmTotalsLateTerminationFeeSummary: FIRM_LATE_TERMINATION_COLS,
  industryTotalsLateTerminationFeeSummary: INDUSTRY_LATE_TERMINATION_COLS
};

export const INDUSTRY_4530_COLS = [
  {
    headerName: 'Period',
    field: 'period',
    valueFormatter: params => {
      return params.value !== 'Average'
        ? getMonthYrDate(params.value)
        : 'Average';
    }
  },
  {
    headerName: 'Initial Filings Submitted',
    field: 'industryInitialFilingsCount',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: 'Late Filings Submitted',
    field: 'industryLateFilingsCount',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '% Filings Late',
    field: 'industryLateFilingsPrct',
    valueFormatter: finraGridPercentValueFormatter
  },
  {
    headerName: 'Amended Filings Submitted',
    field: 'industryAmndFilingsCount',
    valueFormatter: finraGridNumericValueFormatter
  }
];
export const FIRM_4530_LATE_COLS = [
  {
    headerName: 'Period',
    field: 'period',
    valueFormatter: params => getMonthYrDate(params.value)
  },
  {
    headerName: '1 - 5 Days Late',
    field: 'firmLateFilingsLT5Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '6 - 10 Days Late',
    field: 'firmLateFilingsGT6LT10Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '11 - 20 Days Late',
    field: 'firmLateFilingsGT11LT20Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '21 - 30 Days Late',
    field: 'getFirmLateFilingsGT21LT30Count',
    valueFormatter: finraGridNumericValueFormatter
  },
  {
    headerName: '> 30 Days Late',
    field: 'getFirmLateFilingsGT31Count',
    valueFormatter: finraGridNumericValueFormatter
  }
];

export const DISCLOSURE_TIMELINESS_4530_COLS = {
  industry4530DisclosureFilings: INDUSTRY_4530_COLS,
  firm4530LateDisclosureFilings: FIRM_4530_LATE_COLS
};

export const COLUMN_DEFS = {
  [DisclosureReportShortNames.TIMELINESS_BD]: WEB_CRD_TIMELINESS_COLS,
  [DisclosureReportShortNames.LATE_FILING]: CRD_LATE_FILING_COLS,
  [DisclosureReportShortNames.TIMELINESS_4530]: DISCLOSURE_TIMELINESS_4530_COLS
};

const TOOLBAR_CONTACT_LINE: string =
  '\n\n' +
  'For questions regarding the content of this specific report, please call FINRA at (301) 869-6699.';

export const TOOLBAR_TEXTS = {
  [DisclosureReportShortNames.TIMELINESS_BD]:
    `This report card shows a firm's performance in timely reporting of disclosure and non-disclosure events as required by Article IV, Section 1(c) of the <a href="https://www.finra.org/rules-guidance/rulebooks/finra-rules/4530" target="_blank">FINRA By-laws</a>.
    This report includes events submitted to FINRA in Web CRD via the Form BD.` +
    TOOLBAR_CONTACT_LINE,
  [DisclosureReportShortNames.LATE_FILING]:
    `This report provides monthly statistics for up to a twelve-month period on late Form U5 filing fees, as well as late Form U4 amendment, Form U5 and Form U5 amendment disclosure filing fees assessed by FINRA against a member firm.
  Additional information on Late Filing Fees can be found in the following <a href="https://www.finra.org/registration-exams-ce/classic-crd/faq/late-disclosure-fee" target="_blank">FAQ</a> on FINRA.org and instructions for accessing information on specific filings incurring fees can be found in the Report Center Help.` +
    TOOLBAR_CONTACT_LINE,
  [DisclosureReportShortNames.TIMELINESS_4530]: `This report card shows a firm's performance in timely reporting of disclosure events as required by <a href="https://www.finra.org/rules-guidance/rulebooks/finra-rules/4530" target="_blank">FINRA Rule 4530</a>. This report includes disclosures submitted to FINRA's Rule 4530 Application; it does not include disclosures filed via Forms U4 or U5.

    For questions regarding the content of this specific report, please contact your firm's Coordinator or reportcenter-admin@finra.org.`
};

const DISCLAIMER_FIRST_LINE = `* This symbol indicates that no data is available for this value. Asterisks appear for modules that do not apply and will also appear if your firm did not have reps of each type attend sessions during the quarter.`;
export const DISCLAIMERS = {
  [DisclosureReportShortNames.TIMELINESS_BD]: `${DISCLAIMER_FIRST_LINE}

  The Form BD Timeliness Report Card is a monthly analysis of FINRA firms' ability to meet certain filing obligations. In accordance with FINRA rules, firms are required to submit information related to applicant member and/or affiliate(s). This report displays a firm's performance in submitting certain BD amendment filings in the required time frame.

  The information in this report is provided to assist FINRA members in assessing and monitoring compliance with certain reporting obligations under FINRA By-Laws and rules. Provision of this report does not relieve a firm from complying with obligations imposed under FINRA By-Laws and rules and does not limit or prevent FINRA from taking appropriate regulatory or disciplinary action against a firm. Firms should continue to rely on Web CRD for filing detail.`,
  [DisclosureReportShortNames.LATE_FILING]: `${DISCLAIMER_FIRST_LINE}

  This information is provided to assist FINRA members in assessing and monitoring compliance with certain reporting obligations under FINRA By-Laws and member rules and does not limit or prevent FINRA from taking appropriate regulatory or disciplinary actions against a member or associated person. FINRA members should continue to rely on Web CRD for actual charges and assessments.`,
  [DisclosureReportShortNames.TIMELINESS_4530]: `${DISCLAIMER_FIRST_LINE}

  This report is provided as a compliance aid to assist firms in assessing and monitoring compliance with certain reporting obligations under member rules. This report does not reflect a determination by FINRA that the firm has or has not violated applicable rules governing these areas.`
};
