import { addFinraGridColumnId } from 'src/app/shared/utils/utils';

// Response from: GET /rptProblems/details
export interface ReportProblemData {
  // raw server response props
  invalidFlag: number;
  lastUpdated: string;
  message: string;
  reportDisplayName: string;
  reportEdition: string;
  reportPeriodDate: string;
  reportPriodicityName: string;
  reportProblemId: number;
  reportVersion: number;
  userUpdated: string;

  // UI generated/specific props
  isVisible?: boolean;
  isEditing?: boolean;
}

export interface ReportProblemDataResponse {
  reportProblemDetails: ReportProblemData[];
}

export interface ReportProblemDataCreateUpdateResponse {
  reportProblemId: number;
  message: string;
  invalidFlag: number;
  reportVersion: number;
  lastUpdated: string;
  userUpdated: string;
}

export const REPORT_PROBLEMS_COL_DEFS = addFinraGridColumnId([
  {
    headerName: 'Report Type',
    headerClass: 'lines',
    cellClass: 'lines cell-wrap-text',
    valueGetter: (params) => (<ReportProblemData>params.data).reportDisplayName,
    // valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: 'Period',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => (<ReportProblemData>params.data).reportPeriodDate,
    // valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: 'Version',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => (<ReportProblemData>params.data).reportVersion,
    // valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: 'Message',
    headerClass: 'lines',
    cellClass: 'lines cell-wrap-text',
    minWidth: 575,
    autoHeight: true,
    valueGetter: (params) => (<ReportProblemData>params.data).message,
    // valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: 'Last Updated',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => (<ReportProblemData>params.data).lastUpdated,
    // valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: 'User Updated',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => (<ReportProblemData>params.data).userUpdated,
    // valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: 'Hide',
    field: '',
    checkboxSelection: true,
    headerCheckboxSelection: true
  },
]);
