<mat-dialog-content class="mat-dialog-content">
  <h2>Do you want to continue your session?</h2>
  <p>
    For security reasons, your session will time out {{ timeRemaining }} unless
    you select the "Continue Session" button below.
  </p>
</mat-dialog-content>
<mat-dialog-actions layout="row">
  <button mat-button (click)="logout()">Logout</button>
  <button mat-button (click)="continue()">Continue Session</button>
</mat-dialog-actions>
