<section id="debits-grid-div-container">
  <div id="debits-grid-div-container-inner" class="scroll-x-box">
    <div class="table-header">
      <div class="table-title">
        {{ title }}
      </div>

      <span class="question-icon-container" (click)="openHelp()">
        <fa-icon
          data-html2canvas-ignore
          [icon]="faQuestionCircle"
          class="fa-lg question-icon"
        ></fa-icon>
        <span>Help</span>
      </span>
    </div>

    <div id="debits-data-container">
      <!-- header -->
      <div class="row-header row-container grey-border-bottom-1">
        <div class="col col-15 grey-border-right-1">
          <div class="box-padding-1">&nbsp;</div>
        </div>
        <div class="col col-40 grey-border-right-1">
          <div class="box-padding-1">Firm</div>
        </div>
        <div class="col col-45" >
          <div class="box-padding-1">Industry</div>
        </div>
      </div>

      <div class="row-header row-container grey-border-bottom-2">
        <div class="col col-15 grey-border-right-1">
          <div class="box-padding-1">Period</div>
        </div>
        <!-- firm -->
        <div class="col col-15" >
          <div class="box-padding-1">Monthly High</div>
        </div>
        <div class="col col-15">
          <div class="box-padding-1">Monthly Low</div>
        </div>
        <div class="col col-10 grey-border-right-1">
          <div class="box-padding-1">Average</div>
        </div>
        <!-- industry -->
        <div class="col col-10" >
          <div class="box-padding-1">Average</div>
        </div>
        <div class="col col-15">
          <div class="box-padding-1">% Change from Prior Period</div>
        </div>
        <div class="col col-20">
          <div class="box-padding-1">Firms with Unsecured Customer</div>
        </div>
      </div>

      <ng-container *ngFor="let summary of summaryData; last as isLast; index as i">
        <div class="month-data-row row-container row-body"
          [attr.data-row-number]="i + 1"
          [ngClass]="{
            'grey-border-bottom-1': !isLast,
            'blue-highlight-bg': !!summary.expanded
          }"
        >
          <!-- period -->
          <div class="col col-15" id="col-period">
            <div class="box-padding-1 period-flex-row">
              <div>
                <span class="icon-space-1">
                  <fa-icon
                    *ngIf="summary.detailsData && !!summary.detailsData.length"
                    [icon]="summary.expanded ? faChevronDown : faChevronRight"
                    [attr.title]="(summary.expanded ? 'Hide' : 'Show')"
                    class="fa-lg cursor-pointer summary-download-chevron"
                    data-html2canvas-ignore
                    (click)="summary.expanded = !summary.expanded"
                  ></fa-icon>
                </span>
                <span class="summary-month-year" id="summary-month-year-{{ i + 1 }}">{{ summary.monthYear }}</span>
              </div>
              <div>
                <fa-icon
                  *ngIf="summary.detailsData && !!summary.detailsData.length"
                  [icon]="faDownload"
                  [attr.title]="'Download'"
                  class="fa-lg dialog-tools-icon controls-btn-blue cursor-pointer"
                  data-html2canvas-ignore
                  (click)="exportSummaryMonth(summary)"
                ></fa-icon>
              </div>
            </div>
          </div>

          <ng-container [ngSwitch]="!!summary.detailsData">
            <ng-container *ngSwitchCase="true" [ngSwitch]="!!summary.detailsData.length">
              <ng-container *ngSwitchCase="true">
                <!-- firm -->
                <div class="month-data-col col col-15 col-monthly-high">
                  <div class="box-padding-1">{{ summary.firm.monthlyHigh !== null && '$' + (summary.firm.monthlyHigh | round | number) || '*' }}</div>
                </div>
                <div class="month-data-col col col-15 col-monthly-low">
                  <div class="box-padding-1">{{ summary.firm.monthlyLow !== null && '$' + (summary.firm.monthlyLow | round | number) || '*' }}</div>
                </div>
                <div class="month-data-col col col-10 col-monthly-average-firm">
                  <div class="box-padding-1">{{ summary.firm.monthlyAverage !== null && '$' + (summary.firm.monthlyAverage | round | number) || '*' }}</div>
                </div>
                <!-- industry -->
                <div class="month-data-col col col-10 col-monthly-average-industry" >
                  <div class="box-padding-1">{{ summary.industry.monthlyAverage !== null && '$' + (summary.industry.monthlyAverage | round | number) || '*' }}</div>
                </div>
                <div class="month-data-col col col-15 col-percent-change">
                  <div class="box-padding-1">{{ summary.industry.percentageChange !== null && ((summary.industry.percentageChange | number:'1.2-2') + '%') || '*' }}</div>
                </div>
                <div class="month-data-col col col-20 col-unsecured-debits">
                  <div class="box-padding-1">{{ summary.industry.unsecuredCustomerCount !== null && (summary.industry.unsecuredCustomerCount | round | number) || '*' }}</div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <div class="summary-data-col col col-85">
                  <div class="box-padding-1">No information available for the period.</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <div class="summary-data-col col col-85">
                <div class="box-padding-1">Loading...</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <!-- Details Row -->
        <ng-container *ngIf="summary.expanded && (summary.detailsData && !!summary.detailsData.length)">
          <div class="day-data-row row-container row-header grey-border-bottom-1">
            <div class="col col-15 grey-border-right-1">
              <div class="box-padding-1">&nbsp;</div>
            </div>
            <div class="col col-25">
              <div class="box-padding-1">Number of Customer Accounts</div>
            </div>
            <div class="col col-30">
              <div class="box-padding-1">Unsecured Customer Debits</div>
            </div>
            <div class="col col-30">
              <div class="box-padding-1">Margin Debits in Customer Accounts</div>
            </div>
          </div>
          <div *ngFor="let detail of summary.detailsData; last as lastDetail"
            class="day-data-row row-container row-body grey-border-bottom-1">
            <div class="col col-15 grey-border-right-1 col-processed-date">
              <div class="box-padding-1">{{ detail.prcsdDt }}</div>
            </div>
            <div class="col col-25 col-balance-count">
              <div class="box-padding-1">{{ detail.cstmrAccntPstnOrBalCt !== null && (detail.cstmrAccntPstnOrBalCt | number) || '*' }}</div>
            </div>
            <div class="col col-30 col-margin-count">
              <div class="box-padding-1">{{ detail.uscrdCstmrDebtAm !== null && '$' + (detail.uscrdCstmrDebtAm | round | number) || '*' }}</div>
            </div>
            <div class="col col-30 col-unsecured-count">
              <div class="box-padding-1">{{ detail.mrgnDebtAm !== null && '$' + (detail.mrgnDebtAm | round | number) || '*' }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
