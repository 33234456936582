<div class="sales-complaint-container" *ngIf="rowData">
  <div class="help-icon-container pdf">
    <span class="question-icon-container" (click)="openHelp()">
      <fa-icon
        data-html2canvas-ignore
        [icon]="faQuestionCircle"
        class="fa-lg question-icon"
      ></fa-icon>
      <span>Help</span>
    </span>
  </div>
  <div class="table-container pdf" *ngFor="let view of salesComplaintViews">
    <div class="table-header" *ngIf="tableTitles[view]">
      <div class="table-title">{{ tableTitles[view] }}</div>
    </div>
    <report-finra-grid
      *ngIf="rowData[view] && rowData[view].length"
      #reportfinragrid
      id="id"
      [columnDefs]="columnDefs[view]"
      [rowData]="rowData[view]"
    ></report-finra-grid>
    <div *ngIf="!(rowData[view] && rowData[view].length)" class="white-box">
      No data available for this section.
    </div>
  </div>
</div>
