/**
 * enum is based on the `UserAccess.java` on the backend
 * @see: https://bitbucket.finra.org/projects/DDWA/repos/ddwa-services/browse/code/ddwa-services/ddwa-services-util/src/main/java/org/finra/ddwa/util/enums/UserAccess.java
 */
export enum UserOrgClass {
  FIRM = 'FIRM',
  SEC = 'SEC',
  SRO = 'SRO',
  OTH = 'OTH',
  QUERY = 'QUERY'
}

export enum UserTypes {
  EWS = 'EWS',
  ISSO = 'ISSO',
}

export enum UserAccessTypes {
  FINRA = 'FINRA',
  FINRA_ADMIN = 'FINRA_ADMIN',
  EWS = 'EWS',
  EWS_SRO = 'EWS_SRO',
  EWS_SEC = 'EWS_SEC',
  EWS_OTHER = 'EWS_OTHER',
  EWS_FRB = 'EWS_FRB'
}

export interface UserProfile {
  bondMpids: Array<string>;
  displayName: string;
  email: string;
  equityMpids: Array<string>;
  firstName: string;
  lastName: string;
  msrbIds: Array<string>;
  orgClass: string;
  orgId: string;
  userLoginId: string;
  userType: string;
  accessType: string;
}

export const ExternalUserOrgClasses: string[] = [
  UserOrgClass.FIRM,
  UserOrgClass.SEC,
  UserOrgClass.SRO,
  UserOrgClass.OTH,
  UserOrgClass.QUERY
];

export const AdminRoles: string[] = [
  UserAccessTypes.FINRA_ADMIN
];

export const ViewUnpublishedOptionsRoles: string[] = [
  UserAccessTypes.FINRA,
  UserAccessTypes.FINRA_ADMIN
];

export const UnreadReportsAccessTypes: string[] = [
  UserAccessTypes.EWS,
  UserAccessTypes.EWS_SEC,
  UserAccessTypes.EWS_SRO,
  UserAccessTypes.EWS_OTHER,
  UserAccessTypes.EWS_FRB,
  UserAccessTypes.FINRA_ADMIN,
];
