import { FinraGridClickableCellComponent } from "src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component";
import {
  addFinraGridColumnId,
  finraGridNumericValueFormatter,
  finraGridPercentValueFormatter,
  SummaryExportData,
  buildExportDataString,
  trimTrailingChar,
} from "src/app/shared/utils/utils";

export interface LoprOtcGridData {
  firmId: string;
  firmName: string;

  positionDate: string;
  exceptionCount: string;
  exceptionPercentage: string;
  transactionCount: string;

  unequalShortLongPositionsCount: string;
  unequalShortLongPositionsPercentage: string;
  addressWithOutNumberCount: string;
  addressWithOutNumberPercentage: string;
  incorrectCityStateCount: string;
  incorrectCityStatePercentage: string;
  accountsActingConcertCount: string;
  accountsActingConcertPercentage: string;
}

export interface LoprOtcResponse {
  summary: LoprOtcGridData[];
}

export enum LoprOtcDetailViews {
  LONG_SHORT_POSITIONS = "LongShortPositions",
  ADDRESS_WITHOUT_NUMBER = "AddressWithOutNumber",
  ADDRESS_CITY_STATE = "AddressCityState",
  ACCOUNTS_ACTING_CONCERT = "AccountsActingConcert",
}

export const GET_OTC_COL_DEFS = (
  getDetailDataCallback: (params, viewName) => void
) =>
  addFinraGridColumnId([
    {
      headerName: "Exception Periods",
      valueGetter: (params) => (<LoprOtcGridData>params.data).positionDate,
      valueFormatter: (params) => {
        // format change: yyyy-mm-dd --> mm/dd/yyyy
        const dateArr = params.value.split("-");
        const month = dateArr[1];
        const year = dateArr[0];
        const day = dateArr[2];
        const dateString = `${month}/${day}/${year}`;
        return dateString;
      },
    },
    {
      headerName: "Total Exceptions",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Count",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) =>
            (<LoprOtcGridData>params.data).exceptionCount,
          valueFormatter: finraGridNumericValueFormatter,
        },
        {
          headerName: "%",
          valueGetter: (params) =>
            (<LoprOtcGridData>params.data).exceptionPercentage,
          valueFormatter: finraGridPercentValueFormatter,
        },
      ],
    },
    {
      headerName: "Total Transactions Reported",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) => (<LoprOtcGridData>params.data).transactionCount,
      valueFormatter: finraGridNumericValueFormatter,
    },
    //
    {
      headerName: "Unequal Long and Short Positions",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Count",
          headerClass: "lines",
          cellClass: "lines",
          cellRendererFramework: FinraGridClickableCellComponent,
          cellRendererFrameworkOptions: {
            showButton: true,
            handleClick: (params) => {
              getDetailDataCallback(
                params,
                LoprOtcDetailViews.LONG_SHORT_POSITIONS
              );
            },
            buttonClass: (params) =>
              (parseInt(
                (<LoprOtcGridData>params.data).unequalShortLongPositionsCount,
                10
              ) > 0 &&
                "details-link-btn") ||
              "",
            isClickable: (params) =>
              parseInt(
                (<LoprOtcGridData>params.data).unequalShortLongPositionsCount,
                10
              ) > 0,
            buttonLabel: (params) =>
              (<LoprOtcGridData>params.data).unequalShortLongPositionsCount,
          },
        },
        {
          headerName: "%",
          valueGetter: (params) =>
            (<LoprOtcGridData>params.data).unequalShortLongPositionsPercentage,
          valueFormatter: finraGridPercentValueFormatter,
        },
      ],
    },
    {
      headerName: "Address Without Numbers",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Count",
          headerClass: "lines",
          cellClass: "lines",
          cellRendererFramework: FinraGridClickableCellComponent,
          cellRendererFrameworkOptions: {
            showButton: true,
            handleClick: (params) => {
              getDetailDataCallback(
                params,
                LoprOtcDetailViews.ADDRESS_WITHOUT_NUMBER
              );
            },
            buttonClass: (params) =>
              (parseInt(
                (<LoprOtcGridData>params.data).addressWithOutNumberCount,
                10
              ) > 0 &&
                "details-link-btn") ||
              "",
            isClickable: (params) =>
              parseInt(
                (<LoprOtcGridData>params.data).addressWithOutNumberCount,
                10
              ) > 0,
            buttonLabel: (params) =>
              (<LoprOtcGridData>params.data).addressWithOutNumberCount,
          },
        },
        {
          headerName: "%",
          valueGetter: (params) =>
            (<LoprOtcGridData>params.data).addressWithOutNumberPercentage,
          valueFormatter: finraGridPercentValueFormatter,
        },
      ],
    },
    {
      headerName: "Incorrect City or State",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Count",
          headerClass: "lines",
          cellClass: "lines",
          cellRendererFramework: FinraGridClickableCellComponent,
          cellRendererFrameworkOptions: {
            showButton: true,
            handleClick: (params) => {
              getDetailDataCallback(
                params,
                LoprOtcDetailViews.ADDRESS_CITY_STATE
              );
            },
            buttonClass: (params) =>
              (parseInt(
                (<LoprOtcGridData>params.data).incorrectCityStateCount,
                10
              ) > 0 &&
                "details-link-btn") ||
              "",
            isClickable: (params) =>
              parseInt(
                (<LoprOtcGridData>params.data).incorrectCityStateCount,
                10
              ) > 0,
            buttonLabel: (params) =>
              (<LoprOtcGridData>params.data).incorrectCityStateCount,
          },
        },
        {
          headerName: "%",
          valueGetter: (params) =>
            (<LoprOtcGridData>params.data).incorrectCityStatePercentage,
          valueFormatter: finraGridPercentValueFormatter,
        },
      ],
    },
    {
      headerName: "Accounts Acting In Concert",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Count",
          headerClass: "lines",
          cellClass: "lines",
          cellRendererFramework: FinraGridClickableCellComponent,
          cellRendererFrameworkOptions: {
            showButton: true,
            handleClick: (params) => {
              getDetailDataCallback(
                params,
                LoprOtcDetailViews.ACCOUNTS_ACTING_CONCERT
              );
            },
            buttonClass: (params) =>
              (parseInt(
                (<LoprOtcGridData>params.data).accountsActingConcertCount,
                10
              ) > 0 &&
                "details-link-btn") ||
              "",
            isClickable: (params) =>
              parseInt(
                (<LoprOtcGridData>params.data).accountsActingConcertCount,
                10
              ) > 0,
            buttonLabel: (params) =>
              (<LoprOtcGridData>params.data).accountsActingConcertCount,
          },
        },
        {
          headerName: "%",
          valueGetter: (params) =>
            (<LoprOtcGridData>params.data).accountsActingConcertPercentage,
          valueFormatter: finraGridPercentValueFormatter,
        },
      ],
    },
  ]);

/** Export Data */

export const getLoprOtcSummaryExportConfig = (
  rowData,
  title
): SummaryExportData[] => {
  const exportConfig: SummaryExportData[] = [
    {
      title: title,
      rowData: rowData["otc"],
      columnLabels: [
        [
          "",
          "Total Exceptions",
          "",
          "",
          "Unequal Long and Short Positions",
          "",
          "Address Without Numbers",
          "",
          "Incorrect City or State",
          "",
          "Accounts Acting in Concert",
          "",
        ],
        [
          "Exception Periods",
          "Count",
          "%",
          "Total Transactions Reported",
          "Count",
          "%",
          "Count",
          "%",
          "Count",
          "%",
          "Count",
          "%",
        ],
      ],
      dataMappings: [
        "positionDate",
        "exceptionCount",
        "exceptionPercentage",
        "transactionCount",

        "unequalShortLongPositionsCount",
        "unequalShortLongPositionsPercentage",
        "addressWithOutNumberCount",
        "addressWithOutNumberPercentage",
        "incorrectCityStateCount",
        "incorrectCityStatePercentage",
        "accountsActingConcertCount",
        "accountsActingConcertPercentage",
      ],
    },
  ];
  return exportConfig;
};

export function exportLoprOtcSummaryData(rowData, title): string {
  const exportData = getLoprOtcSummaryExportConfig(rowData, title);
  const csvData = buildExportDataString(exportData);
  const formatted = trimTrailingChar(csvData, "\n");
  return formatted;
}
