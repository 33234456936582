import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IconDefinition,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute, Params } from '@angular/router';
import {
  LoprReportTypes,
  LoprReportNames,
  LoprToolbarDescriptions,
  LoprFooterMessages,
  showViewsDropdownReportTypeIDs,
  showDetailLinkReportTypeIDs,
  TABLE_NAMES_BY_ORDER,
  TABLE_TITLES,
  LOPR_EXPORT_CONFIG,
  LOPR_CHART_Y_AXIS_FORMATTING,
} from 'src/app/configs/model/lopr/lopr.model';
import { LoprService } from 'src/app/services/lopr.service';
import { DatePipe } from '@angular/common';
import {
  ToolbarConfigOptions,
  ToolbarConfig
} from 'src/app/configs/model/finra-toolbar.model';
import { FormGroup, FormControl } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { LoprToolbarConfig } from 'src/app/configs/toolbar-config/lopr.toolbar';
import { BeastOtherActions, BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastLeaveReportPageInfo, BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';
import { HelperService } from 'src/app/services/helper.service';
import { ReportPageMetadataInfo, ReportService } from 'src/app/services/report.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { UnreadReportsService } from 'src/app/services/unread-reports.service';
import { createDateFromPeriod, convertDateToString, saveCsv } from 'src/app/shared/utils/utils';



@Component({
  selector: 'report-lopr',
  templateUrl: './lopr.component.html',
  styleUrls: ['./lopr.component.scss']
})
export class LoprComponent implements OnInit, OnDestroy {
  title: string;
  viewName: string;
  reportId: number;
  reportName: string;
  reportDate: string;
  reportVersion: number;
  reportTypeId: number;
  shouldShowViewDropDown: boolean;
  shouldShowReportDetails: boolean;
  shouldShowPeerGroup: boolean;
  shouldShowTierGroup: boolean;
  reportInstanceMetadata: ReportInstanceMetadata;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  loprToolbarConfig: ToolbarConfig;
  loprToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  reportPageMetadataInfo: ReportPageMetadataInfo;
  LoprReportTypes = LoprReportTypes;
  LoprReportNames = LoprReportNames;
  toolbarDescriptions = LoprToolbarDescriptions;
  footerMessages = LoprFooterMessages;
  footerMessage: string;
  tableTitles = TABLE_TITLES;
  hasChart: boolean;
  rowData;
  loprChartRowData;
  columnDefs;
  tableNamesByOrder;
  peerGroupData;
  reportLoadedSuccessfully: boolean;
  Y_AXIS_FORMATTING;
  periodFormatter: (arg: string) => string;
  loadedAt: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private baseReportService: ReportService,
    private helperService: HelperService,
    private toolbarService: ToolbarService,
    private titleService: Title,
    private loprService: LoprService,
    private datePipe: DatePipe,
    private unreadReportsService: UnreadReportsService,
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {
    this.toolbarForm = new FormGroup({
      datepicker: new FormControl(),
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl(),
    });
  }

  ngOnInit() {
    this.periodFormatter = (dateString: string) => {
      return this.datePipe.transform(dateString, 'MMMM d, yyyy');
    };
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId: this.reportInstanceMetadata.reportConfiguration.reportType.reportCategoryId,
        reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: '',
        rating: '',
        product: '',
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.viewName = params.viewName;
    this.reportId = params.reportId;
    this.reportName = params.reportName;
    this.reportVersion = parseInt(params.version, 10);
    this.reportTypeId = parseInt(params.type, 10);
    this.getReportConfigurationMetadata().subscribe(
      (pageData: ReportPageMetadataInfo) => {
        this.processReportConfigurationMetadata(pageData);
        this.getSummaryData().subscribe((reportData) => {
          this.processSummaryData(reportData);
        });
      }
    );
  }

  setToolbar(report: ReportInstanceMetadata) {
    const datepickerStartDate = createDateFromPeriod(report.reportPeriodDate);
    this.toolbarForm.get('datepicker').setValue(datepickerStartDate);
    this.toolbarForm.get('datepicker').valueChanges.subscribe((value) => {
      const dateString = convertDateToString(value);
      this.toolbarForm.get('period').setValue(dateString);
    });
    const customOptions: Partial<ToolbarConfigOptions> = {
      description: this.toolbarDescriptions[this.reportName],
      datepickerFormControl: this.toolbarForm.controls.datepicker,
      hideViewsCell: !this.shouldShowViewDropDown,
      hideDetailsButton: !this.shouldShowReportDetails,
      matDatepickerFilter: (d: Date): boolean => {
        const dateList = this.getDateList();
        const day = d.getDay();
        const dateString = this.datePipe.transform(d, 'yyyy-MM-dd');
        const isNotWeekend = day !== 0 && day !== 6;
        const isInList = dateList.indexOf(dateString) !== -1;
        const isValidDateOption = isNotWeekend && isInList;
        return isValidDateOption;
      },
      setHelpClickHandler: () => {
        this.openHelp();
      },
    };
    const results = this.toolbarService.createToolbarConfig(
      this,
      LoprToolbarConfig,
      customOptions
    );
    this.loprToolbarConfigOptions = results.toolbarConfigOptions;
    this.loprToolbarConfig = results.toolbarConfig;
  }

  getDateList(): string[] {
    const list = this.reportPageMetadataInfo.reportInstanceMetadatas;
    const filtered = list.map((r) => r.reportPeriodDate);
    return filtered;
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          throw error;
        })
      );
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
    this.reportDate = this.reportInstanceMetadata.reportPeriodDate;
    const reportTypeId = data.reportInstanceMetadata.reportConfiguration.reportTypeId;
    this.title = this.reportInstanceMetadata.reportConfiguration.reportDisplayName;
    this.titleService.setTitle(this.reportInstanceMetadata.reportConfiguration.reportDisplayName);
    this.shouldShowViewDropDown = showViewsDropdownReportTypeIDs.includes(reportTypeId);
    this.shouldShowReportDetails = showDetailLinkReportTypeIDs.includes(reportTypeId);
    this.Y_AXIS_FORMATTING = LOPR_CHART_Y_AXIS_FORMATTING[this.reportName];
    this.hasChart = (
      reportTypeId === LoprReportTypes.OVER_THE_COUNTER ||
      reportTypeId === LoprReportTypes.INTRUSION_DETECTION
    );
    // in case there is an error parsing the JSON string
    try {
      const viewTemplateConfig = JSON.parse(
        data.reportInstanceMetadata.reportConfiguration.reportConfigurationViews[0].viewTemplateConfiguration
      );
      this.footerMessage = viewTemplateConfig && viewTemplateConfig.disclaimer || this.footerMessages[this.reportName];
    } catch (e) {
      console.log(`Cannot parse LOPR viewTemplateConfig:`, e);
    }
    this.setToolbar(data.reportInstanceMetadata);
  }

  getSummaryData() {
    return this.baseReportService
      .getReport(this.reportId, this.viewName, 's')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportSummaryDataError(error);
          this.reportLoadedSuccessfully = false;
          this.rowData = null;
          throw error;
        })
      );
  }

  processSummaryData(response) {
    if (!response) {
      return;
    }
    const results = this.loprService.formatResponse(response, this.reportTypeId);
    this.rowData = results;
    this.loprChartRowData = (<any> results).chartData;
    this.tableNamesByOrder = TABLE_NAMES_BY_ORDER[this.reportName];
    const onReportDetails = this.onReportDetails.bind(this);
    this.columnDefs = this.loprService.getColumnDefs(
      this.reportTypeId,
      this.reportName,
      this.reportPageMetadataInfo,
      onReportDetails
    );
    this.reportLoadedSuccessfully = true;
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  onReportDetails(params?: {
    reportId: number;
    viewName: string;
    reportDate: string;
  }) {
    const reportId = params && params.reportId || this.reportId;
    const viewName = params && params.viewName || this.viewName;
    const reportDate = params && params.reportDate || this.reportDate;

    const exportUrl: string = this.baseReportService.getReportUrl(
      reportId,
      viewName,
      'd',
      reportDate
    );
    this.helperService.downloadFile(exportUrl);
  }

  openHelp() {
    this.helpLinksService.open(this.reportName);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  async summaryDataExport() {
    const fileNameResponse = await this.baseReportService.getReportFileName(
      this.reportId,
      this.viewName,
      's'
    ).toPromise();
    const csvFn = LOPR_EXPORT_CONFIG[this.reportName];
    const csvString: string = csvFn(
      this.rowData,
      this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    );
    saveCsv(csvString, fileNameResponse.fileName);
    this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
  }
}
