import { Injectable, Inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import {
  UserProfile,
  ExternalUserOrgClasses,
  UserTypes,
  AdminRoles,
  ViewUnpublishedOptionsRoles,
  UnreadReportsAccessTypes,
  UserAccessTypes,
  UserOrgClass,
} from "../configs/model/user-profile.model";

enum UserProfileApiUrls {
  getUserProfile = "userProfile",
}

export interface FipSessionApiResponse {
  expiresInMilliSeconds: string,
  expirationTime: string,
  sessionExists: 'Y' | 'N',
}

@Injectable({
  providedIn: "root",
})
export class UserProfileService {
  private baseUrl: string;
  private issoBaseUrl;
  private isEwsReportCenter;
  private ewsExpirationTimeUrl: string;
  private userProfile: UserProfile = null;

  constructor(
    @Inject('environment') private environment,
    private http: HttpClient
  ) {
    this.baseUrl = this.environment.ddwaBaseUrl;
    this.ewsExpirationTimeUrl = this.environment.ewsExpirationTimeUrl;
    this.issoBaseUrl = this.environment.issoBaseUrl;
    this.isEwsReportCenter = this.environment.isEwsReportCenter;
  }

  getProfileObj(): UserProfile {
    return this.userProfile;
  }

  getUserProfile(shouldCache: boolean = true): Observable<UserProfile> {
    if (this.userProfile) {
      return of(this.userProfile);
    }
    const api =
      this.baseUrl +
      UserProfileApiUrls.getUserProfile +
      (!shouldCache ? "?cache=false" : "");
    return this.http.get<UserProfile>(api).pipe(
      map((response) => {
        this.userProfile = response;
        return response;
      })
    );
  }

  isExternalUser(userProfile: UserProfile): boolean {
    const hasExternalOrgClass = ExternalUserOrgClasses.includes(userProfile.orgClass);
    const isEWS = userProfile.userType === UserTypes.EWS;
    const isExternal = hasExternalOrgClass && isEWS;
    return isExternal;
  }

  isAdminUser(userProfile: UserProfile): boolean {
    return AdminRoles.includes(userProfile.accessType);
  }

  checkPublishedOptionsPermission(userProfile: UserProfile): boolean {
    return ViewUnpublishedOptionsRoles.includes(userProfile.accessType);
  }

  // DDWA-2835, DDWA-2836, DDWA-2845, Hide Unread Reports for EWS-SRO EWS-SEC FINRA-ADMIN
  hasUnreadReportsAccess(userProfile: UserProfile): boolean {
    return UnreadReportsAccessTypes.includes(userProfile.accessType);
  }

  /**
   * Redirect FINRA users to Reports Center ISSO Home
   */
  redirectToIssoUrl() {
    // Do not redirect for localhost, dev, qa url for testing purpose.
    window.location.href = this.issoBaseUrl;
    return;
  }

  checkFipSessionCookie() {
    const endpoint = `${this.environment.fipApiUrl}`;
    console.log(`checkFipSessionCookie:`, { endpoint });
    return this.http.get(endpoint)
      .pipe(
        map((response: FipSessionApiResponse) => {
          try {
            console.log(response, { response }, !!response && JSON.stringify({ response }));
          }
          catch (e) {
            console.log(e);
          }
          if (!response || !response.sessionExists) {
            throw new Error(`response body empty...`);
          }
          return !!response && response.sessionExists === 'Y';
        })
      );
  }

  authenticateWithFips() {
    const realm = this.isLocationISSO() ? 'isso' : 'ews';
    const auth_url = `${this.environment.fipAuthPageUrl}?goto=${window.location.href}`;
    console.log({ auth_url });
    window.location.href = auth_url;
  }

  isCloudFront() {
    return (
      ('isCloudfront' in this.environment) &&
      this.environment.isCloudfront === 'true'
    );
  }

  isEnvIsso() {
    return (
      ('env' in this.environment) &&
      this.environment.env.includes('isso')
    );
  }

  /**
   * Check if user should be redirected
   * @param userProfile
   */

  isLocationISSO(): boolean {
    const isISSO = this.isEnvIsso() || window.location.href.indexOf('reportcenter-isso') !== -1;
    return isISSO;
  }

  shouldRedirect(userProfile: UserProfile): boolean {
    const isFinraUser = userProfile.orgId === "1";
    const isLocationISSO = this.isLocationISSO();
    const isCloudfront = this.isCloudFront();
    const shouldRedirect = isFinraUser && (!isLocationISSO && !isCloudfront);
    console.log({ isFinraUser, isLocationISSO, isCloudfront, shouldRedirect });
    return shouldRedirect;
  }
}
