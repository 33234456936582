import {
  LOPR_LISTED_COLUMN_DEFS,
  exportLoprListedSummaryData
} from "./lopr-listed.model";
import {
  LOPR_INTRUSION_DETECTION_COLUMN_DEFS,
  exportLoprIntrusionDetectionSummaryData
} from './lopr-intrusion-detection.model';
import { exportLoprOtcSummaryData } from './lopr-otc.model';
import { yAxisTickFormattingHideDecimals } from "src/app/shared/utils/utils";

/**
 * Large Options Positions Report (LOPR) - Master Model File
 */

export enum LoprReportTypes {
  OVER_THE_COUNTER = 69,
  LISTED = 68,
  INTRUSION_DETECTION = 206,
}

export enum LoprReportNames {
  OVER_THE_COUNTER = 'loprotc',
  LISTED = 'loprlisted',
  INTRUSION_DETECTION = 'cmospi'
}

export const showDetailLinkReportTypeIDs = [
  LoprReportTypes.LISTED,
  LoprReportTypes.INTRUSION_DETECTION,
];

export const LoprDailyReportsByTypeId = [
  LoprReportTypes.LISTED,
  LoprReportTypes.OVER_THE_COUNTER,
  LoprReportTypes.INTRUSION_DETECTION,
];

export const showViewsDropdownReportTypeIDs = [

];

/** Toolbar Descriptions */

const TOOLBAR_CONTACT_LINE: string =
  '\n\n' +
  'If you have questions regarding the content of this report, ' +
  'please contact Mike Simon at (212) 457-5364 or James Turnbull at (212) 457-5367.';

const otcToolbarDescription: string =
  'FINRA Rule 2360(b)(5) requires a firm to report (or have reported on its behalf) ' +
  'any options position in an account in which the firm has an interest and each customer, non-member ' +
  'broker, or non-member dealer account that has established, acting alone or in concert, an aggregate ' +
  'option positions of 200 or more option contracts (whether long or short) of the put class and the ' +
  'call class on the same side of the market covering the same underlying security or index. These ' +
  'positions must be reported to the LOPR system no later than the close of business on the next day ' +
  'following the day on which the transaction or transactions requiring the filing of such report ' +
  'occurred. For aggregate positions that meet the 200 contract reporting threshold, the option ' +
  'position for each individual account must be reported to the LOPR system. ' +
  'FINRA Notice 16-17 provides an overview of the options reporting requirements and consolidates and ' +
  'summarizes previously issued guidance. ' +
  '(<a href="http://www.finra.org/industry/notices/16-17">http://www.finra.org/industry/notices/16-17</a>).' +
  TOOLBAR_CONTACT_LINE;

/**
 the "\" means to escape the following character.
 in this template string, we are escape new lines
 so the string won't include it but the code can be more readable
*/
const listedToolbarDescription: string =
  `In an effort to assist firms in identifying potential deficiencies with their Large Options Positions Report ("LOPR"), \
  FINRA Market Regulation is providing listed LOPR exceptions from FINRA's Listed LOPR Audit Exception Report. \
  Please note these exceptions may overlap with Staff's annual LOPR review of the firm. The LOPR Listed report contains \
  three types of exceptions for exchange-traded options: Effective Date, LOPR Reporting, and In-Concert. \
  The Effective Date and LOPR Reporting Exceptions identify when there is a change in a firm's OCC Positions \
  for the customer or firm account range in an option series from one day to the next that is not reflected in that firm's LOPR. \
  These exceptions will show the prior day and current day OCC positions and the size of the position reported to the LOPR system. \
  If reported by the firm, the Exceptions may contain positions that are held in the firm account range at the OCC. \
  Firms should ensure the accuracy of all positions reported to the LOPR system. If you have questions regarding the content of this report, \
  please contact Mike Simon at (212) 457-5364 or James Turnbull at (212) 457-5367.`;

const intrusionDetectionToolbarDescription: string =
  `In an effort to assist firms in identifying potentially fraudulent options transactions being facilitated by account takeover schemes \
  (also referred to as account intrusion schemes), FINRA Market Regulation is providing the firm a list of trades that may be related to such fraudulent activity. \
  The fraudulent options trading scenario, which is outlined in FINRA Regulatory Notice 20-32, involves a bad actor improperly accessing a customer’s brokerage account \
  to purchase or sell securities at inferior prices. Simultaneously, the bad actor uses a separate account, often at another broker-dealer, to trade against these accounts in order \
  to profit in this separate account. The list of trades provided may involve instances where an account at the firm was victimized in a potential account intrusion scheme or it may also involve \
  instances where the account at the firm was the profiting account (the account that traded against a victimized account). The firm should consider commencing reviews of the activity and determine whether \
  further action is appropriate. If you have questions regarding the content of this report, please contact FINRA Market Regulation Department at (800) 321-6273 and ask for the Options Regulation group. \
  This report card contains CAT confidential data of the recipient or of another CAT reporter for which the recipient must also make a reasonable effort to protect confidentiality.`;

export const LoprToolbarDescriptions = {
  [LoprReportNames.OVER_THE_COUNTER]: otcToolbarDescription,
  [LoprReportNames.LISTED]: listedToolbarDescription,
  [LoprReportNames.INTRUSION_DETECTION]: intrusionDetectionToolbarDescription,
};

/** Footer Messages */

const otcFooterMessage: string =
  '* - If this symbol appears on your report, this indicates that no data has ' +
  'been provided to the Report Center for this value field and for the specified period. ' +
  'For example, this is may be an expected behavior for weekday holidays but may also ' +
  'indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'These exceptions are being provided strictly as a compliance aid in an effort to assist ' +
  'the firm in identifying potential deficiencies, and these exceptions may not represent ' +
  'violations of FINRA or exchange rules. Staff is not seeking a response from the firm detailing ' +
  'whether the positions were correctly reported. Additionally, these exceptions are not meant to cover ' +
  'every potential reporting deficiency. The firm should continue to review its rejects, in-concert ' +
  'relationships and LOPR snapshot against its books and records to ensure the accuracy ' +
  'of LOPR and to identify potential formatting and reporting issues.' +
  '\n\n' +
  'This report is provided as a tool to help firms confirm the accuracy of data submitted to FINRA. ' +
  'Information provided does not necessarily indicate a rule violation or issue. Member firms should make ' +
  'no inference that the staff of FINRA has or has not determined that the information contained on this report ' +
  'does or does not constitute rule violations or that the data has been accurately reported to FINRA.';

const listedFooterMessage: string =
  '* - If this symbol appears on your report, this indicates that no data has ' +
  'been provided to the Report Center for this value field and for the specified period. ' +
  'For example, this is may be an expected behavior for weekday holidays but may also ' +
  'indicate information that was not sent, incorrectly formatted, or late.' +
  '\n\n' +
  'These exceptions are being provided strictly as a compliance aid in an effort to assist ' +
  'the firm in identifying potential deficiencies, and these exceptions may not represent ' +
  'violations of FINRA or exchange rules. Staff is not seeking a response from the firm detailing ' +
  'whether the positions were correctly reported. Additionally, these exceptions are not meant to cover ' +
  'every potential reporting deficiency. The firm should continue to review its rejects, in-concert ' +
  'relationships and LOPR snapshot against its books and records to ensure the accuracy ' +
  'of LOPR and to identify potential formatting and reporting issues.';

const intrusionDetectionFooterMessage: string =
  ``;
export const LoprFooterMessages = {
  [LoprReportNames.OVER_THE_COUNTER]: otcFooterMessage,
  [LoprReportNames.LISTED]: listedFooterMessage,
  [LoprReportNames.INTRUSION_DETECTION]: intrusionDetectionFooterMessage,
};

/* Table Titles */

export const TABLE_TITLES = {
  [LoprReportNames.LISTED]: null,
  [LoprReportNames.OVER_THE_COUNTER]: null,
  [LoprReportNames.INTRUSION_DETECTION]: null,
};

/* Table Names by Order */

export const TABLE_NAMES_BY_ORDER = {
  [LoprReportNames.OVER_THE_COUNTER]: [
    'otc',
  ],
  [LoprReportNames.LISTED]: [
    'listed',
  ],
  [LoprReportNames.INTRUSION_DETECTION]: [
    'intrusion_detection',
  ],
};

/* Column Definitions Tree */

export const ALL_COLUMN_DEFINITIONS = {
  otc: null, // this is null on purpose; otc column defs are functions, which must be set in the component
  [LoprReportNames.LISTED]: { listed: LOPR_LISTED_COLUMN_DEFS },
  [LoprReportNames.INTRUSION_DETECTION]: { intrusion_detection: LOPR_INTRUSION_DETECTION_COLUMN_DEFS },
};

/** Export Methods */

export const LOPR_EXPORT_CONFIG: { [key:string]: (data: string, title: string) => string; } = {
  [LoprReportNames.LISTED]: exportLoprListedSummaryData,
  [LoprReportNames.OVER_THE_COUNTER]: exportLoprOtcSummaryData,
  [LoprReportNames.INTRUSION_DETECTION]: exportLoprIntrusionDetectionSummaryData,
};

export const LOPR_CHART_Y_AXIS_FORMATTING: { [key:string]: (value: string | number) => string; } = {
  [LoprReportNames.LISTED]: null,
  [LoprReportNames.OVER_THE_COUNTER]: null,
  [LoprReportNames.INTRUSION_DETECTION]: yAxisTickFormattingHideDecimals
};

export const checkIsLoprByTypeId = (num: number) => {
  // check 
  return (
    num == LoprReportTypes.INTRUSION_DETECTION ||
    num == LoprReportTypes.OVER_THE_COUNTER ||
    num == LoprReportTypes.LISTED
  );
}

export const checkIsLoprByConfigId = (num: number) => {
  // check 
  return (
    num == 248 || // intrusion_detection edition 1
    num == 237 || // otc edition 2
    num == 243 || // listed edition 3
    num == 238 // listed edition 2
  );
}