<div fxLayout="row" class="sidenav-container">
  <div class="sidenav-first-level">
    <mat-nav-list
      [attr.data-selected-nav-id]="selectedParent.id"
      [attr.data-selected-nav-route]="selectedParent.route"
    >
      <mat-list-item
        *ngFor="let item of navItems"
        (click)="showExtendedNav(item)"
        [attr.data-nav-id]="item.id"
        [attr.data-nav-name]="item.label"
        [ngClass]="{
          selected: (selectedParent.id == item.id || activeLink == item.id)
        }"
      >
        <div fxFill fxLayout="column" fxLayoutAlign="center center">
          <img
            *ngIf="item.id == 0"
            [src]="item.logo"
            alt="Home"
            data-html2canvas-ignore
          />
          <fa-icon
            *ngIf="item.id > 0"
            [icon]="item.faIcon"
            class="fa-2x"
          ></fa-icon>
          <div
            class="mat-caption sidenav-first-level-title"
            [hidden]="showExtended == true && selectedParent.id == item.id"
          >
            {{ item.label }}
          </div>
        </div>
        <span
          class="sidenav-active-item-indicator"
          *ngIf="showExtended == true && selectedParent.id == item.id"
        ></span>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div
    fxLayout="column"
    *ngIf="showExtended == true"
    class="sidenav-second-level"
    fxLayoutAlign="start left"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="left center"
      class="sidenav-second-level-title"
    >
      <div class="title-header" fxFlex>{{ selectedParent.label }}</div>
      <fa-icon
        [icon]="faArrowLeft"
        class="fa-lg"
        (click)="hideExtendedNav()"
      ></fa-icon>
    </div>
    <mat-nav-list [attr.data-selected-child-name]="selectedChild && selectedChild.categoryDisplayName">
      <mat-list-item
        *ngFor="let child of selectedParent.elementChildren"
        (click)="goToUrl(child)"
        [class.active-link]="selectedChild && child.categoryDisplayOrder === selectedChild.categoryDisplayOrder"
        [attr.data-child-name]="child.categoryDisplayName"
      >
        <a class="sub-title">{{ child.categoryDisplayName }}</a>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>
