<div
  class="home-main-container"
  [ngStyle]="{ 'min-height': clientHeight - footerHeight + 'px' }"
>
  <report-loader></report-loader>
  <report-alert class="report-main-content-padding no-print"></report-alert>

  <div class="announcements-container">
    <div *ngIf="announcementsLoading">
      <h1 class="title">Announcements</h1>
      <finra-loading-spinner [padding]="0"></finra-loading-spinner>
      <p *ngIf="announcementsLoadingDelay">Taking longer than usual, please wait...</p>
    </div>

    <h1 class="title" *ngIf="(announcements && announcements.length) || announcementsError">
      Announcements <span *ngIf="announcements && announcements.length">({{ announcements.length }})</span>
    </h1>

    <div *ngIf="announcementsError" class="announcements-error-box snackbar-error">
      {{ announcementsErrorMessage }}
    </div>
    <div *ngIf="announcements && announcements.length">
      <div class="announcements-items-container" (scroll)="onAnnouncementsScroll($event)">
        <div
          *ngFor="let announcement of announcements"
          class="announcement-item"
          [attr.data-announcement-id]="announcement.id"
        >
          <!-- Hidden markup. adding for QA automation to pull and compare -->
          <span style="display: none;">{{ announcement.id }}</span>
          <div class="flex-container">
            <div>
              <h3 class="announcement-title" [attr.data-announcement-title]="announcement.title">{{ announcement.title }}</h3>
            </div>
            <div *ngIf="announcement.urgencyIndicator">
              <fa-icon
                title="Urgent Announcement"
                [icon]="faExclamationCircle"
                class="announcement-urgent-icon"
              ></fa-icon>
            </div>
          </div>
          <p
            class="announcement-date"
            [attr.data-announcement-activedate]="announcement.activeDate"
          >
            <em>{{ announcement.activeDate.split('T')[0] | date : 'MM/dd/yyyy' }}</em>
          </p>
          <div
            *ngIf="announcement.body && announcement.showBody"
            class="announcement-body"
            [innerHTML]="announcement.body"
            [attr.data-announcement-body]="announcement.body"
          ></div>
          <p class="announcement-loading-indicator" *ngIf="announcement.loading">
            <finra-loading-spinner [padding]="5"></finra-loading-spinner>
          </p>
          <p class="announcement-loading-error-text" *ngIf="announcement.loadingError">
            Could not load details for this announcement. Please contact support.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!unReadReportsData && isExternalUser && errorMessage"
    class="has-no-data"
    [innerHTML]="errorMessage"
  ></div>

  <div *ngIf="detailDatasetRequestsInitialLoading" class="reports-main-container">
    <h1 class="title">Reports Ready for Download</h1>
    <finra-loading-spinner [padding]="0"></finra-loading-spinner>
  </div>
  <div
    *ngIf="reportsDownloadsData && reportsDownloadsData.length"
    class="reports-main-container"
    id="detail-data-status"
    fxFlexLayout="row wrap"
    fxlayoutAlign="center stretch"
    fxLayoutGap="13px"
  >
    <h1 class="title">
      Reports Ready for Download
    </h1>
    <report-finra-grid
      class="report-main-content-bgcolor"
      [columnDefs]="reportsDownloadsColumnDefs"
      [rowData]="reportsDownloadsData"
      [autoResize]="false"
      [pageSize]="10"
      [gridOptions]="gridOptions"
    ></report-finra-grid>
  </div>

  <div *ngIf="usageLogExportRequestsInitialLoading" class="reports-main-container">
    <h1 class="title">Usage Log Exports Ready for Download</h1>
    <finra-loading-spinner [padding]="0"></finra-loading-spinner>
  </div>
  <div
    *ngIf="!!usageLogExportRequests && !!usageLogExportRequests.length && !!usageLogExportRequestsColumnDefs"
    class="reports-main-container"
    id="usage-log-exports-container"
    fxFlexLayout="row wrap"
    fxlayoutAlign="center stretch"
    fxLayoutGap="13px"
  >
    <h1 class="title">
      Usage Log Exports Ready for Download
    </h1>
    <report-finra-grid
      class="report-main-content-bgcolor"
      [columnDefs]="usageLogExportRequestsColumnDefs"
      [rowData]="usageLogExportRequests"
      [autoResize]="false"
      [pageSize]="10"
      [gridOptions]="gridOptions"
    ></report-finra-grid>
  </div>

  <div *ngIf="unreadReportsInitialLoading" class="reports-main-container">
    <h1 class="title">Unread Reports</h1>
    <finra-loading-spinner [padding]="0"></finra-loading-spinner>
  </div>
  <div
    *ngIf="unReadReportsData && unReadReportsData.length"
    class="reports-main-container"
    id="unread-reports"
    fxFlexLayout="row wrap"
    fxlayoutAlign="center stretch"
    fxLayoutGap="13px"
  >
    <h1 class="title">
      Unread Reports
    </h1>
    <report-finra-grid
      class="report-main-content-bgcolor"
      [id]="id"
      [columnDefs]="unreadReportsColumnDefs"
      [rowData]="unReadReportsData"
      [autoResize]="false"
      [pageSize]="20"
      [gridOptions]="gridOptions"
    ></report-finra-grid>
  </div>
</div>
