<div class="registered-representatives-composition-grid-container">
  <div class="table-header">
    <div class="table-title">
      <span class="title-text">{{ title }}</span>
      <button
        *ngIf="actionBtn"
        data-html2canvas-ignore
        mat-flat-button
        color="accent"
        class="action-button"
        (click)="actionBtn.handler()"
      >
        {{ actionBtn.text }}
      </button>
    </div>

    <span class="question-icon-container" (click)="openHelp()">
      <fa-icon
        data-html2canvas-ignore
        [icon]="faQuestionCircle"
        class="fa-lg question-icon"
      ></fa-icon>
      <span>Help</span>
    </span>
  </div>

  <report-finra-grid
    #reportfinragrid
    class="reps-grid"
    [columnDefs]="columnDefs"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [rowData]="rowData"
    [groupDefaultExpanded]="0"
    [gridOptions]="gridOptions"
    [statusBar]="statusBar"
  ></report-finra-grid>
</div>
