import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import {
  IconDefinition,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { RegisteredRepsListUnion } from 'src/app/configs/model/risk-monitoring/registered-reps-composition.model';
import { RiskMonitoringReportNames } from 'src/app/configs/model/risk-monitoring/risk-monitoring.model';
import { BeastClickActions } from 'src/app/enums/beast.enum';
import { BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { HelpLinksService } from 'src/app/services/help-links.service';



@Component({
  selector: 'report-reps-grid',
  templateUrl: './reps-grid.component.html',
  styleUrls: ['./reps-grid.component.scss']
})
export class RepsGridComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() rowData: RegisteredRepsListUnion;
  @Input() columnDefs: any;
  @Input() autoGroupColumnDef: any;
  @Input() gridOptions: any;
  @Input() actionBtn;
  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  statusBar = null;

  constructor(
    private helpLinksService: HelpLinksService,
    private beastService: BeastService,
  ) {}

  ngOnInit() {}

  ngOnChanges(simpleChanges: SimpleChanges) {}

  openHelp() {
    this.helpLinksService.open(RiskMonitoringReportNames.REGISTERED_REPRESENTATIVES);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}
