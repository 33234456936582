/**
 * Admin - Report Status Model
 */

import {
  addFinraGridColumnId, quarterDateFormat,
} from 'src/app/shared/utils/utils';
import { FinraValidValuePipe } from 'src/app/pipes/finra-valid-value.pipe';
import { ReportStatus } from './admin.model';
import { LoprDailyReportsByTypeId } from './lopr/lopr.model';
import { CorpFinQuarterlyReportsByTypeId } from './corp-fin.model';
import { OnlineLearningQuarterlyReportsByTypeId } from './online-learning.model';
import { RiskMonitoringQuarterlyReportsByTypeId } from './risk-monitoring/risk-monitoring.model';

export const HELP_LINK = 'https://tools.finra.org/reportcenterhelp/';

export const dailyReportTypeIds = [
  ...LoprDailyReportsByTypeId,
];

export const quarterlyReportTypeIds = [
  // corp fin
  ...CorpFinQuarterlyReportsByTypeId,
  // online learning
  ...OnlineLearningQuarterlyReportsByTypeId,
  // risk monitoring
  ...RiskMonitoringQuarterlyReportsByTypeId,
];

// column definition

export const REPORT_STATUS_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Report Name',
    headerClass: '',
    cellClass: '',
    valueGetter: (params) => (<ReportStatus>params.data).rptName,
    valueFormatter: (params) => (new FinraValidValuePipe(null)).transform(params.value, null),
    minWidth: 900,
    maxWidth: 900,
    // width: 800,
    resizable: true,
  },
  {
    headerName: 'Most Recent Report Period Published',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => (<ReportStatus>params.data).rptPeriod,
    valueFormatter: (params) => {
      const reportStatus = (<ReportStatus>params.data);
      if (quarterlyReportTypeIds.includes(reportStatus.rptTypeId)) {
        const periodSplitter = reportStatus.rptPeriod.split(' ');
        const period = `${periodSplitter[0]} ${periodSplitter[1]}`;
        const version = `${periodSplitter[2]} ${periodSplitter[3]}`;
        const formattedPeriod = quarterDateFormat(period);
        const formattedReportPeriod = `${formattedPeriod} ${version}`;
        return formattedReportPeriod;
      } else {
        return params.value;
      }
    },
    minWidth: 300,
    maxWidth: 300,
    resizable: true,
  },
  {
    headerName: 'Published Date',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => (<ReportStatus>params.data).publishTime,
    valueFormatter: (params) => (new FinraValidValuePipe(null)).transform(params.value, null),
    minWidth: 200,
    maxWidth: 200,
    resizable: true,
  },
  {
    headerName: 'Report First Available',
    headerClass: 'lines',
    cellClass: 'lines',
    valueGetter: (params) => (<ReportStatus>params.data).frstRptPrdDt,
    valueFormatter: (params) => {
      const reportStatus = (<ReportStatus>params.data);
      if (quarterlyReportTypeIds.includes(reportStatus.rptTypeId)) {
        const formattedReportPeriod = quarterDateFormat(params.value);
        return formattedReportPeriod;
      } else {
        return params.value;
      }
    },
    minWidth: 175,
    resizable: true,
  },
]);
