import {
  IconDefinition,
  faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { Subscription, Observable } from "rxjs";
import { FormGroup, FormControl } from "@angular/forms";
import { catchError } from "rxjs/operators";
import {
  ToolbarConfig,
  ToolbarConfigOptions,
} from "src/app/configs/model/finra-toolbar.model";
import { ReportInstanceMetadata } from "src/app/configs/model/reports.model";
import {
  CanceledAsOfTradesMonthly,
  AllTradesMonthlySummary,
  AsOfTradesMonthlySummary,
  BeforeSettlementTradesMonthlySummary,
  AfterSettlementTradesMonthlySummary,
  canceledAsOfTradesSummaryExportData,
} from "src/app/configs/model/risk-monitoring/canceled-as-of-trades.model";
import {
  CustomerComplaintTransformedResponse,
  CustomerComplaintSummary,
  customerComplaintSummaryExportData,
} from "src/app/configs/model/risk-monitoring/customer-complaint.model";
import {
  CustomerDebitsMonthly,
  UnsecuredCustomerDebitsMonthlySummary,
  MarginCustomerDebitsMonthlySummary,
  customerDebitsSummaryExportData,
} from "src/app/configs/model/risk-monitoring/customer-debits.model";
import { getRegisteredRepExportConfig } from "src/app/configs/model/risk-monitoring/registered-reps-composition.model";
import {
  RiskMonitoringReportTypes,
  riskMonitoringToolbarDescriptions,
  riskMonitoringFooterMessages,
  RiskMonitoringQuarterlyReportsByTypeId,
  RiskMonitoringReportPageTitles,
  RiskMonitoringReportWebTitles,
  showViewsDropdownReportTypeIDs,
  showDetailLinkReportTypeIDs,
} from "src/app/configs/model/risk-monitoring/risk-monitoring.model";
import { salesPracticeComplaintSummaryExportData } from "src/app/configs/model/risk-monitoring/sales-practice-complaint.model";
import { RiskMonitoringToolbarConfig } from "src/app/configs/toolbar-config/risk-monitoring.toolbar";
import { BeastClickActions, BeastOtherActions } from "src/app/enums/beast.enum";
import { BeastClickEventReportHelpLinkInfo, BeastLeaveReportPageInfo } from "src/app/interfaces/beast.interface";
import { AlertService } from "src/app/services/alert.service";
import { BeastService } from "src/app/services/beast.service";
import { HelperService } from "src/app/services/helper.service";
import { PdfService } from "src/app/services/pdf.service";
import {
  ReportPageMetadataInfo,
  ReportService,
} from "src/app/services/report.service";
import { ReportsService } from "src/app/services/reports.service";
import { RiskMonitoringService } from "src/app/services/risk-monitoring.service";
import { ToolbarService } from "src/app/services/toolbar.service";
import { UnreadReportsService } from "src/app/services/unread-reports.service";
import {
  quarterDateFormat,
  buildExportDataString,
  saveCsv,
} from "src/app/shared/utils/utils";
import { RegisteredRepsMasterListElement } from "./registered-reps-composition/registered-reps-composition.component";
import { HelpLinksService } from "src/app/services/help-links.service";

/**
 * This component is just a container for all of the different types of risk monitoring reports.
 * It will show the correct commponent by looking at `reportInstanceMetadata.reportConfiguration.reportTypeId`.
 * For example, if `reportInstanceMetadata.reportConfiguration.reportTypeId` = 20,
 * this component will know that 20 is Customer Debits Reports and will render that child component.
 * This component is responsible for passing the instance metadata to the correct component, rendering the toolbar
 * and managing what report view to show. Each child is responsible for rendering the table data.
 */

@Component({
  selector: "report-risk-monitoring",
  templateUrl: "./risk-monitoring.component.html",
  styleUrls: ["./risk-monitoring.component.scss"],
})
export class RiskMonitoringComponent implements OnInit {
  title: string;
  viewName: string;
  reportId: number;
  reportName: string;
  reportShortName: string;
  reportDate: string;
  reportVersion: number;
  reportTypeId: number;
  shouldShowViewDropDown: boolean;
  shouldShowReportDetails: boolean;
  isQuarterReport: boolean;
  reportInstanceMetadata: ReportInstanceMetadata;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  reportTypesEnum = RiskMonitoringReportTypes;
  toolbarDescriptions = riskMonitoringToolbarDescriptions;
  footerMessages = riskMonitoringFooterMessages;
  reportPageMetadataInfo: ReportPageMetadataInfo;
  riskMonitoringToolbarConfig: ToolbarConfig;
  riskMonitoringToolbarConfigOptions: ToolbarConfigOptions;
  toolbarForm: FormGroup;
  periodChangeSubscription: Subscription;
  onSummaryExportFn: (args?: any) => void;
  periodFormatter: (arg: string) => string;
  reportLoadedSuccessfully: boolean = false;
  loadedAt: number;

  /** Customer Debits Props */

  customerDebitsMonthly: CustomerDebitsMonthly[] = [];
  unsecuredDebitsMonthly: UnsecuredCustomerDebitsMonthlySummary[] = [];
  marginDebitsMonthly: MarginCustomerDebitsMonthlySummary[] = [];

  /** Canceled As-Of Trades Props */

  canceledAndAsOfTradesMonthly: CanceledAsOfTradesMonthly[] = [];
  allTradesMonthly: AllTradesMonthlySummary[] = [];
  asOfTradesMonthly: AsOfTradesMonthlySummary[] = [];
  beforeSettlementTradesMonthly: BeforeSettlementTradesMonthlySummary[] = [];
  afterSettlementTradesMonthly: AfterSettlementTradesMonthlySummary[] = [];

  /** Registered Representatives Composition Props */

  registeredRepsMasterList: RegisteredRepsMasterListElement[] = [];

  /** Sales Practice Complain Props */

  salesComplaintRowData: {
    firm: any[];
    industry: any[];
  };
  salesComplaintViews: string[];

  /** Customer Complaints Props */

  customerComplaintResults: CustomerComplaintTransformedResponse;
  customerComplaintQuartersHeadings = [];
  customerComplaintRowData = [];
  customerComplaintTotalsObj = {};
  customerComplaintAllOtherComplaintsObj = {};
  customerComplaintIndustryComplaintsRowData = [];
  complaintsAsscIndv = {};
  complaintsRepsRelated = {};

  constructor(
    public activatedRoute: ActivatedRoute,
    public alertService: AlertService,
    public baseReportService: ReportService,
    public helperService: HelperService,
    private helpLinksService: HelpLinksService,
    public pdfService: PdfService,
    public titleService: Title,
    public reportsService: ReportsService,
    private toolbarService: ToolbarService,
    private riskMonitoringService: RiskMonitoringService,
    private unreadReportsService: UnreadReportsService,
    private beastService: BeastService
  ) {
    this.toolbarForm = new FormGroup({
      period: new FormControl(),
      version: new FormControl(),
      view: new FormControl(),
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.handleParamsChange(params);
    });
    this.loadedAt = Date.now();
  }

  ngOnDestroy() {
    if (this.reportInstanceMetadata) {
      const moment = Date.now();
      const duration = moment - this.loadedAt;
      const eventInfo: BeastLeaveReportPageInfo = {
        duration,
        reportId: this.reportId.toString(),
        reportPeriod: this.reportInstanceMetadata.reportPeriodDate,
        reportView: this.viewName,
        reportVersion: this.reportVersion,
        firmId: this.reportInstanceMetadata.reportFirmId,
        reportCategoryId:
          this.reportInstanceMetadata.reportConfiguration.reportType
            .reportCategoryId,
        reportType:
          this.reportInstanceMetadata.reportConfiguration.reportDisplayName,
        maturity: "",
        rating: "",
        product: "",
      };
      this.beastService.clickStream.postEvent(
        BeastOtherActions.LEAVE_SUMMARY_REPORT_PAGE,
        eventInfo
      );
    }
  }

  handleParamsChange(params: Params) {
    this.reportVersion = parseInt(params.version, 10);
    this.reportId = params.reportId;
    this.reportName = params.reportName;
    this.viewName = params.viewName;
    this.getReportConfigurationMetadata().subscribe(
      (pageData: ReportPageMetadataInfo) => {
        this.processReportConfigurationMetadata(pageData);
        this.getSummaryReportData().subscribe(
          (reportData) => {
            const responseIsValid =
              this.riskMonitoringService.checkSummaryResponse(
                reportData,
                this.reportTypeId
              );
            if (responseIsValid) {
              this.processSummaryReportData(reportData);
            } else {
              const httpError = new HttpErrorResponse({
                status: 404,
              });
              this.reportLoadedSuccessfully = true;
              this.helperService.handleReportSummaryDataError(httpError);
            }
          },
          (error: HttpErrorResponse) => {
            this.helperService.handleReportSummaryDataError(error);
            this.reportLoadedSuccessfully = false;
          }
        );
      }
    );
  }

  setToolbar() {
    const customOptions: Partial<ToolbarConfigOptions> = {
      description: this.toolbarDescriptions[this.reportName],
      hideViewsCell: !this.shouldShowViewDropDown,
      hideDetailsButton: !this.shouldShowReportDetails,
      isQuarterReport: this.isQuarterReport,
      hidePublishedState: true,
      setHelpClickHandler: () => {
        this.openHelp();
      },
    };
    const results = this.toolbarService.createToolbarConfig(
      this,
      RiskMonitoringToolbarConfig,
      customOptions
    );
    this.riskMonitoringToolbarConfigOptions = results.toolbarConfigOptions;
    this.riskMonitoringToolbarConfig = results.toolbarConfig;
  }

  openHelp() {
    this.helpLinksService.open(this.reportName);
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }

  getReportConfigurationMetadata() {
    return this.baseReportService
      .getReportPageMetadataInfo(this.reportId, this.viewName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.helperService.handleReportMetadataError(error);
          throw error;
        })
      );
  }

  processReportConfigurationMetadata(data: ReportPageMetadataInfo) {
    this.reportPageMetadataInfo = data;
    this.reportInstanceMetadata = data.reportInstanceMetadata;
    this.reportDate = this.reportInstanceMetadata.reportPeriodDate;
    this.reportTypeId = data.reportInstanceMetadata.reportConfiguration.reportTypeId;
    this.reportShortName = data.reportInstanceMetadata.reportConfiguration.reportType.reportTypeShortName;
    this.isQuarterReport = RiskMonitoringQuarterlyReportsByTypeId.includes(this.reportTypeId);
    this.title = RiskMonitoringReportPageTitles[this.reportShortName];
    this.titleService.setTitle(RiskMonitoringReportWebTitles[this.reportShortName]);
    this.shouldShowViewDropDown = showViewsDropdownReportTypeIDs.includes(this.reportTypeId);
    this.shouldShowReportDetails = showDetailLinkReportTypeIDs.includes(this.reportTypeId);
    this.periodFormatter = this.isQuarterReport ? quarterDateFormat : null;

    this.setToolbar();
  }

  getSummaryReportData(): Observable<any> {
    switch (this.reportTypeId) {
      case RiskMonitoringReportTypes.CUSTOMER_DEBITS: {
        return this.riskMonitoringService.getCustomerDebitsMonthlySummaryReport(
          this.reportId,
          this.viewName
        );
      }
      case RiskMonitoringReportTypes.CANCELED_AS_OF_TRADES: {
        return this.riskMonitoringService.getCustomerDebitsMonthlySummaryReport(
          this.reportId,
          this.viewName
        );
      }
      case RiskMonitoringReportTypes.REGISTERED_REPRESENTATIVES: {
        return this.riskMonitoringService.getRegisteredRepsReport(
          this.reportId,
          this.viewName
        );
      }
      case RiskMonitoringReportTypes.SALES_PRACTICE_COMPLAINT: {
        return this.riskMonitoringService.getSalePracticeComplaintReport(
          this.reportId,
          this.viewName
        );
      }
      case RiskMonitoringReportTypes.CUSTOMER_COMPLAINT: {
        return this.riskMonitoringService.getCustomerComplaintSummaryReport(
          this.reportId,
          this.viewName
        );
      }
    }
  }

  processSummaryReportData(response) {
    switch (this.reportTypeId) {
      case RiskMonitoringReportTypes.CUSTOMER_DEBITS: {
        const results =
          this.riskMonitoringService.processCustomerDebitsSummaryReport(
            response
          );
        this.customerDebitsMonthly = results.customerDebitsMonthly;
        this.unsecuredDebitsMonthly = results.unsecuredDebitsMonthly;
        this.marginDebitsMonthly = results.marginDebitsMonthly;
        break;
      }
      case RiskMonitoringReportTypes.CANCELED_AS_OF_TRADES: {
        const results =
          this.riskMonitoringService.processCanceledAsOfTradesSummaryReport(
            response
          );
        this.canceledAndAsOfTradesMonthly =
          results.canceledAndAsOfTradesMonthly;
        this.allTradesMonthly = results.allTradesMonthly;
        this.asOfTradesMonthly = results.asOfTradesMonthly;
        this.beforeSettlementTradesMonthly =
          results.beforeSettlementTradesMonthly;
        this.afterSettlementTradesMonthly =
          results.afterSettlementTradesMonthly;
        break;
      }
      case RiskMonitoringReportTypes.REGISTERED_REPRESENTATIVES: {
        const severelyDisciplinedClickHandler = () => {
          this.riskMonitoringService.showSeverelyDisciplinedFirms(
            this.reportId,
            this.viewName,
            this.reportDate
          );
        };
        const results =
          this.riskMonitoringService.processQuarterRegisteredRepsSummaryReport(
            response,
            severelyDisciplinedClickHandler
          );
        this.registeredRepsMasterList = results.registeredRepsMasterList;
        break;
      }
      case RiskMonitoringReportTypes.SALES_PRACTICE_COMPLAINT: {
        const results =
          this.riskMonitoringService.processSalePracticeComplaintReport(
            response,
            this.reportDate
          );
        this.salesComplaintRowData = results.rowData;
        this.salesComplaintViews = results.salesComplaintViews;
        break;
      }
      case RiskMonitoringReportTypes.CUSTOMER_COMPLAINT: {
        const summaryData =
          response.reportCustomerComplaintSummaries as CustomerComplaintSummary[];
        const results =
          this.riskMonitoringService.processCustomerComplaintSummaryReport(
            summaryData,
            this.viewName
          );
        this.customerComplaintResults = results;
        this.customerComplaintQuartersHeadings = results.headings;
        this.customerComplaintRowData = results.rowData;
        this.customerComplaintTotalsObj = results.totalsObj;
        this.customerComplaintIndustryComplaintsRowData =
          results.topTenIndustryComplaintsRowData;
        this.complaintsAsscIndv = results.complaintsAsscIndv;
        this.complaintsRepsRelated = results.complaintsRepsRelated;
        this.customerComplaintAllOtherComplaintsObj =
          results.allOtherComplaintsObj;
        break;
      }
    }
    this.reportLoadedSuccessfully = true;
    // summary report loaded successfully; mark as read
    this.unreadReportsService.markReportAsRead(this.reportId);
  }

  async summaryDataExport() {
    let csvString: string;
    let jsonObj: object;
    const fileNameResponse = await this.baseReportService
      .getReportFileName(this.reportId, this.viewName, "s")
      .toPromise();

    switch (this.reportTypeId) {
      case RiskMonitoringReportTypes.CUSTOMER_DEBITS: {
        csvString = customerDebitsSummaryExportData(
          this.unsecuredDebitsMonthly,
          this.marginDebitsMonthly
        );
        jsonObj = {
          unsecuredDebitsMonthly: this.unsecuredDebitsMonthly,
          marginDebitsMonthly: this.marginDebitsMonthly,
        };
        break;
      }
      case RiskMonitoringReportTypes.CANCELED_AS_OF_TRADES: {
        csvString = canceledAsOfTradesSummaryExportData(
          this.allTradesMonthly,
          this.asOfTradesMonthly,
          this.beforeSettlementTradesMonthly,
          this.afterSettlementTradesMonthly
        );
        jsonObj = {
          allTradesMonthly: this.allTradesMonthly,
          asOfTradesMonthly: this.asOfTradesMonthly,
          beforeSettlementTradesMonthly: this.beforeSettlementTradesMonthly,
          afterSettlementTradesMonthly: this.afterSettlementTradesMonthly,
        };
        break;
      }
      case RiskMonitoringReportTypes.REGISTERED_REPRESENTATIVES: {
        const config = getRegisteredRepExportConfig(
          this.registeredRepsMasterList
        );
        csvString = buildExportDataString(config);
        jsonObj = {
          registeredRepsMasterList: this.registeredRepsMasterList,
        };
        break;
      }
      case RiskMonitoringReportTypes.SALES_PRACTICE_COMPLAINT: {
        const config = salesPracticeComplaintSummaryExportData(
          this.salesComplaintRowData
        );
        csvString = buildExportDataString(config);
        jsonObj = {
          salesComplaintRowData: this.salesComplaintRowData,
          salesComplaintViews: this.salesComplaintViews,
        };
        break;
      }
      case RiskMonitoringReportTypes.CUSTOMER_COMPLAINT: {
        csvString = customerComplaintSummaryExportData(
          this.customerComplaintResults,
          this.viewName
        );
        jsonObj = this.customerComplaintResults;
        break;
      }
    }

    saveCsv(csvString, fileNameResponse.fileName);
    this.baseReportService.createSummaryReportExportAudit(this.reportId, this.viewName).subscribe({
      next: (response) => {
        console.log(`logged summary export action`, response);
      }
    });
  }

  onReportDetails() {
    const exportUrl: string = this.baseReportService.getReportUrl(
      this.reportId,
      this.viewName,
      "d"
    );
    this.helperService.downloadFile(exportUrl);
  }
}
