import { FinraGridClickableCellComponent } from "src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component";
import {
  addFinraGridColumnId,
  getMonthYrDate,
  finraGridNumericValueFormatter,
  finraGridPercentValueFormatter,
} from "src/app/shared/utils/utils";

export interface MsrbTradesData {
  periodStartDate: string;
  sortOrder: number;
}

export interface TransactionsData extends MsrbTradesData {
  // firm
  totalTransactionCount: number;
  customerTransactionCount: number;
  // peer group
  peerGroupNumber: number;
  peerRankNumber: number;
  peerPopulationCount: number;
  // industry
  industryRankNumber: number;
  industryPopulationCount: number;
}

export interface TradesBelowData extends MsrbTradesData {
  // firm
  belowMinimumDenominationCount: number;
  belowMinimumDenominationThomsonReutersCount: number;
  belowMinimumDenominationUniqueCount: number;
  belowMinimumDenominationPercent: number;
  // peer group
  belowMinimumDenominationPeerMeanPercent: number;
  belowMinimumDenominationPeerMedianPercent: number;
  belowMinimumDenominationPeerRankNumber: number;
  // industry
  belowMinimumDenominationIndustryMeanPercent: number;
  belowMinimumDenominationIndustryMedianPercent: number;
  belowMinimumDenominationIndustryRankNumber: number;
}

export interface TradesTransactionsResponseData {
  peerGroup: TransactionsData[];
  tradesBelowMinDenomitaion: TradesBelowData[];
}

export const NO_INFO_TEXT = "No information available for the period.";

export function checkTradesDataValid(data: TransactionsData | TradesBelowData) {
  const dataCopy = { ...data };
  delete dataCopy.periodStartDate;
  delete dataCopy.sortOrder;

  const keys = Object.keys(dataCopy);
  const onePropHasValidValue = keys.some((key) => dataCopy[key] !== null);
  return onePropHasValidValue;
}

// AG-GRID column definitions //

/**
 * @see: https://reportcenter.qa.finra.org/scrc/view_rpt.do?rptId=5462904&viewId=610&bgurl=show_rpt&backCatID=3&auditAction=2
 */

export const GET_TRANSACTIONS_COLUMN_DEFS = (
  peerGroupNumberClickHandler: (params) => void
) =>
  addFinraGridColumnId([
    {
      headerName: "Period",
      valueGetter: (params) => (<TransactionsData>params.data).periodStartDate,
      valueFormatter: (params) => getMonthYrDate(params.value),
    },
    {
      headerName: "Firm",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Total Transactions Count",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) =>
            (<TransactionsData>params.data).totalTransactionCount,
          // valueFormatter: finraGridNumericValueFormatter,
          valueFormatter: (params) => {
            const quarterHasData = checkTradesDataValid(params.data);
            return quarterHasData
              ? finraGridNumericValueFormatter(params)
              : NO_INFO_TEXT;
          },
          // cellRenderer: aggCellRenderer,
          colSpan: function (params) {
            const quarterHasNoData = !checkTradesDataValid(params.data);
            return quarterHasNoData ? 7 : 1;
          },
        },
        {
          headerName: "Customer Transactions Count",
          valueGetter: (params) =>
            (<TransactionsData>params.data).customerTransactionCount,
          valueFormatter: finraGridNumericValueFormatter,
        },
      ],
    },
    {
      headerName: "Peer Group",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Peer Group",
          headerClass: "lines",
          cellClass: "lines",
          // valueGetter: (params) => (<TransactionsData>params.data).peerGroupNumber,
          // valueFormatter: finraGridNumericValueFormatter,
          cellRendererFramework: FinraGridClickableCellComponent,
          cellRendererFrameworkOptions: {
            isClickable: (params) => true,
            buttonClass: (param) => "peer-group-link",
            buttonLabel: (params) =>
              (<TransactionsData>params.data).peerGroupNumber,
            handleClick: peerGroupNumberClickHandler,
          },
        },
        {
          headerName: "Peer Rank",
          valueGetter: (params) =>
            (<TransactionsData>params.data).peerRankNumber,
          valueFormatter: finraGridNumericValueFormatter,
        },
        {
          headerName: "Peer Population",
          valueGetter: (params) =>
            (<TransactionsData>params.data).peerPopulationCount,
          valueFormatter: finraGridNumericValueFormatter,
        },
      ],
    },
    {
      headerName: "Industry",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Industry Rank",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) =>
            (<TransactionsData>params.data).industryRankNumber,
          valueFormatter: finraGridNumericValueFormatter,
        },
        {
          headerName: "Industry Population",
          valueGetter: (params) =>
            (<TransactionsData>params.data).industryPopulationCount,
          valueFormatter: finraGridNumericValueFormatter,
        },
      ],
    },
  ]);

export const TRADES_BELOW_MINIMUM_DENOMINATION_COLUMN_DEFS = (
  uniqueTradeAlertClickHandler: (params) => void
) =>
  addFinraGridColumnId([
    {
      headerName: "Period",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) => (<TradesBelowData>params.data).periodStartDate,
      valueFormatter: (params) => getMonthYrDate(params.value),
    },
    {
      headerName: "Firm",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "NIDS Count",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) =>
            (<TradesBelowData>params.data).belowMinimumDenominationCount,
          // valueFormatter: finraGridNumericValueFormatter,
          valueFormatter: (params) => {
            const quarterHasData = checkTradesDataValid(params.data);
            return quarterHasData
              ? finraGridNumericValueFormatter(params)
              : NO_INFO_TEXT;
          },
          // cellRenderer: aggCellRenderer,
          colSpan: function (params) {
            const quarterHasNoData = !checkTradesDataValid(params.data);
            return quarterHasNoData ? 10 : 1;
          },
        },
        {
          headerName: "TR Count",
          valueGetter: (params) =>
            (<TradesBelowData>params.data)
              .belowMinimumDenominationThomsonReutersCount,
          valueFormatter: finraGridNumericValueFormatter,
        },
        {
          headerName: "Unique Trade Alert Count",
          // valueGetter: (params) => (<TradesBelowData>params.data).peerGroupNumber,
          // valueFormatter: finraGridNumericValueFormatter,
          cellRendererFramework: FinraGridClickableCellComponent,
          cellRendererFrameworkOptions: {
            isClickable: (params) =>
              !!(<TradesBelowData>params.data)
                .belowMinimumDenominationUniqueCount,
            buttonClass: (param) => "peer-group-link",
            buttonLabel: (params) =>
              (<TradesBelowData>params.data)
                .belowMinimumDenominationUniqueCount,
            handleClick: uniqueTradeAlertClickHandler,
          },
        },
        {
          headerName: "Firm %",
          valueGetter: (params) =>
            (<TradesBelowData>params.data).belowMinimumDenominationPercent,
          valueFormatter: finraGridPercentValueFormatter,
        },
      ],
    },
    {
      headerName: "Peer Group",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Mean",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) =>
            (<TradesBelowData>params.data)
              .belowMinimumDenominationPeerMeanPercent,
          valueFormatter: finraGridPercentValueFormatter,
        },
        {
          headerName: "Median",
          valueGetter: (params) =>
            (<TradesBelowData>params.data)
              .belowMinimumDenominationPeerMedianPercent,
          valueFormatter: finraGridPercentValueFormatter,
        },
        {
          headerName: "Rank",
          valueGetter: (params) =>
            (<TradesBelowData>params.data)
              .belowMinimumDenominationPeerRankNumber,
          valueFormatter: finraGridNumericValueFormatter,
        },
      ],
    },
    {
      headerName: "Industry",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Mean",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) =>
            (<TradesBelowData>params.data)
              .belowMinimumDenominationIndustryMeanPercent,
          valueFormatter: finraGridPercentValueFormatter,
        },
        {
          headerName: "Median",
          valueGetter: (params) =>
            (<TradesBelowData>params.data)
              .belowMinimumDenominationIndustryMedianPercent,
          valueFormatter: finraGridPercentValueFormatter,
        },
        {
          headerName: "Rank",
          valueGetter: (params) =>
            (<TradesBelowData>params.data)
              .belowMinimumDenominationIndustryRankNumber,
          valueFormatter: finraGridNumericValueFormatter,
        },
      ],
    },
  ]);

export const TRADES_BELOW_COLUMS_DEFS = {
  transactions: GET_TRANSACTIONS_COLUMN_DEFS,
  tradesBelowMinDenomination: TRADES_BELOW_MINIMUM_DENOMINATION_COLUMN_DEFS,
};

/** Export Summary Logic */
