import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  ReportConfigurationStatesResponse,
  ReportConfigurationState,
  ReportTypeEdition,
  ReportExplorerPeriod,
  REPORT_PROBLEM_BEHAVIOR_MAP,
  ReportStatusesResponse,
} from "../configs/model/admin.model";
import { FirmInfo } from "../configs/model/firm-info.model";
import { ReportProblemFormData } from "../configs/model/report-problem-form-data.model";
import {
  ReportProblemDataResponse,
  ReportProblemData,
  ReportProblemDataCreateUpdateResponse,
} from "../configs/model/report-problems.model";
import { ReportInstanceMetadata, ReportType } from "../configs/model/reports.model";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  baseUrl: string;
  constructor(@Inject("environment") environment, private http: HttpClient) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  getReportConfigurationsByState(categoryId, state) {
    // Avoid caching to fetch latest data.
    const cacheKill = Math.ceil(Math.random() * 1000000);
    const url = `${this.baseUrl}findReportConfigurationsByState/reportCategoryId/${categoryId}/state/${state.value}?t=${cacheKill}`;

    return this.http.get<ReportConfigurationStatesResponse>(url);
  }

  updateReportState(
    oldState: string,
    newState: string,
    reportConfigurationStates: ReportConfigurationState[]
  ) {
    const body = {
      reportConfigurationStates,
    };
    const url = `${this.baseUrl}updateReportStates/oldState/${oldState}/newState/${newState}`;
    return this.http.put<ReportConfigurationStatesResponse>(url, body);
  }

  /**
   * Get Admin Report Problems.
   * This is what shows up in the main UI view at: `/reportcenter/admin/report-view/report-problems`
   * @see: https://api-isso.ddwa.dev.finra.org/swagger-ui.html#!/Report_Problems/getReportProblemsDetailsUsingGET
   *
   * Disabling caching for this call
   */
  getAdminReportProblems(): Observable<ReportProblemDataResponse> {
    const url = `${this.baseUrl}rptProblems/details?cache=false`;
    return this.http.get<ReportProblemDataResponse>(url);
  }

  /**
   * Get Admin Report Problem by ID.
   * @see: https://api-isso.ddwa.dev.finra.org/swagger-ui.html#!/Report_Problems/getReportProblemsDetailsUsingGET
   */
  getAdminReportProblemById(id): Observable<ReportProblemData> {
    const url = `${this.baseUrl}rptProblems/detail/id/${id}?cache=false`;
    return this.http.get<ReportProblemData>(url);
  }

  /**
   * Get Report Types Editions.
   * This is all of the report types that fills the first dropdown in the form.
   * @see: https://api-isso.ddwa.dev.finra.org/swagger-ui.html#!/Report_Type/getAllReportTypeEditionsUsingGET
   */
  getAdminReportTypesEditions(
    shouldFilterOld: boolean = true
  ): Observable<ReportTypeEdition[]> {
    // DDWA-4113: filtering out all editions with a config ID below 200
    return this.http
      .get<ReportTypeEdition[]>(`${this.baseUrl}reportTypes/editions`)
      .pipe(
        map((response) => {
          const list = shouldFilterOld
            ? response.filter((r) => r.rptCfgId >= 200)
            : response;
          return list;
        })
      );
  }

  getReportTypes(): Observable<ReportType[]> {
    return this.http.get<{ reportTypes: ReportType[] }>(`${this.baseUrl}reportTypes`).pipe(
      map((response) => response.reportTypes)
    );
  }

  /**
   * Get Periods and Versions by Report Config Id.
   * When the first field is populated in the form, take that report type's `rptCfgId` and call this endpoint
   * to get the available periods and versions for each period.
   * @see: https://api-isso.ddwa.dev.finra.org/swagger-ui.html#!/Report_Instance_Metadata/getReportTypeEditionPeriodsUsingGET
   */
  getPeriodsAndVerionsByReportConfigId(
    rptCfgId: number
  ): Observable<ReportExplorerPeriod[]> {
    const url = `${this.baseUrl}reportInstanceMetadatas/periods/rptCfgId/${rptCfgId}?isPublished=true`;
    return this.http.get<ReportExplorerPeriod[]>(url);
  }

  createReportProblem(formData: ReportProblemFormData) {
    const id = formData.reportType;
    const date = formData.reportPeriodDate;
    const version = formData.reportVersion;
    const behavior = REPORT_PROBLEM_BEHAVIOR_MAP[formData.invalidFlag];
    const api = `${this.baseUrl}rptProblems/reportConfigId/${id}/reportPeriodDate/${date}/version/${version}/behavior/${behavior}`;
    const httpObservable =
      this.http.post<ReportProblemDataCreateUpdateResponse>(
        api,
        formData.message
      );
    return httpObservable;
  }

  updateReportProblem(formData: ReportProblemFormData) {
    const behavior = REPORT_PROBLEM_BEHAVIOR_MAP[formData.invalidFlag];
    const api = `${this.baseUrl}rptProblems/id/${formData.reportProblemId}/behavior/${behavior}`;
    const httpObservable = this.http.put<ReportProblemDataCreateUpdateResponse>(
      api,
      formData.message
    );
    return httpObservable;
  }

  // report status methods

  getReportStatuses(): Observable<ReportStatusesResponse> {
    const api = `${this.baseUrl}reportInstanceMetadatas/reportStatus`;
    const httpObservable = this.http.get<ReportStatusesResponse>(api);
    return httpObservable;
  }

  // report explorer

  getFirmsByReportTypeAndPeriod(typeId: number, period: string) {
    const api = `${this.baseUrl}reportExplorer/rptTypeId/${typeId}/period/${period}`;
    const httpObservable = this.http.get<FirmInfo[]>(api);
    return httpObservable;
  }

  getReportByFirmId(typeId: number, period: string, firmId: string) {
    const api = `${this.baseUrl}reportExplorer/rptId/rptTypeId/${typeId}/period/${period}/firmId/${firmId}`;
    const httpObservable = this.http.get<ReportInstanceMetadata>(api);
    return httpObservable;
  }
}
