import { Injectable } from '@angular/core';
import { getPrior12Months, SummaryExportData, saveCsv, buildExportDataString, trimTrailingChar, round } from '../shared/utils/utils';
import {
  TABLE_TITLES,
  INDUSTRY_4530_COLS,
  FIRM_4530_LATE_COLS,
  FIRM_FORM_U4U5_FILING_COLS,
  FIRM_LATE_DISCLOSURE_FEE_COLS,
  FIRM_FORM_U4_LATE_DISCLOSURE_COLS,
  FIRM_FORM_U5_LATE_DISCLOSURE_COLS,
  INDUSTRY_LATE_DISCLOSURE_FEE_COLS,
  INDUSTRY_LATE_TERMINATION_COLS,
  FIRM_LATE_TERMINATION_COLS,
  BD_DISCLOSURE_COLS,
  BD_LATE_DISCLOSURE_COLS,
  BD_NON_DISCLOSURE_COLS,
  BD_LATE_NON_DISCLOSURE_COLS,
  Disclosure4530TimelinessSummaryResponse
} from 'src/app/configs/model/disclosure.model';

@Injectable({
  providedIn: 'root'
})
export class DisclosureService {
  DISCLOSURE_EXPORT_CONFIG: {
    [key: string]: (arg: any) => string;
  } = {
    bdtime: this.exportWebCrdFormBdTimelinessSummary,
    crd: this.exportWebCrdLateFilingFeeSummary,
    d4530: this.export4530DisclosureTimelinessSummary
  };

  constructor() {}

  formatD4530Report(
    data: Disclosure4530TimelinessSummaryResponse,
    reportDate: string
  ): {
    firm4530DisclosureFilings: any[],
    industry4530DisclosureFilings: any[],
    firm4530LateDisclosureFilings: any[]
  } {
    // Converting backend string values to integers and also removing 'Average' word from fieldNames for mapping purpose.
    const firmAvgRow = this.convertToInt(
      data.firm4530DisclosureFilingAverages
    );
    const industryAvgRow = this.convertToInt(
      data.industry4530DisclosureFilingAverages
    );

    firmAvgRow['associateIndividualCount'] = round(parseFloat(data.firm4530DisclosureFilingAverages.associateIndividualCountAverage), 0);
    firmAvgRow['firmInitialFilingsCount'] = round(parseFloat(data.firm4530DisclosureFilingAverages.firmInitialFilingsCountAverage), 0);
    firmAvgRow['firmLateFilingsCount'] = round(parseFloat(data.firm4530DisclosureFilingAverages.firmLateFilingsCountAverage), 0);
    firmAvgRow['firmAmndFilingsCount'] = round(parseFloat(data.firm4530DisclosureFilingAverages.firmAmndFilingsCountAverage), 0);

    // Adding period value as 'Average' for last Average row.
    firmAvgRow['period'] = 'Average';
    industryAvgRow['period'] = 'Average';

    // Adding Periods for pevious 12 months in case of missing data.
    const temp = {
      firm4530DisclosureFilings: data.firm4530DisclosureFilings,
      industry4530DisclosureFilings: data.industry4530DisclosureFilings,
      firm4530LateDisclosureFilings: data.firm4530LateDisclosureFilings
    };
    const formattedData = this.add12MonthsData(temp, reportDate);

    // Adding average row

    const firmDataExists =
      formattedData.firm4530DisclosureFilings &&
      formattedData.firm4530DisclosureFilings.length;
    if (firmDataExists) {
      formattedData.firm4530DisclosureFilings = this.addAverageRow(
        formattedData.firm4530DisclosureFilings,
        firmAvgRow
      );
    }

    const industryDataExists =
      formattedData.industry4530DisclosureFilings &&
      formattedData.industry4530DisclosureFilings.length;
    if (industryDataExists) {
      formattedData.industry4530DisclosureFilings = this.addAverageRow(
        formattedData.industry4530DisclosureFilings,
        industryAvgRow
      );
    }

    return formattedData;
  }

  isReportDataEmpty(data: {
    firm4530DisclosureFilings: any[],
    industry4530DisclosureFilings: any[],
    firm4530LateDisclosureFilings: any[]
  }) {
    /**
     * The `data` argument should be in the same format as what is returned by
     * DisclosureService.formatD4530Report
     */
    const keys = Object.keys(data);
    const isEmpty = keys.every((key) => {
      const value = data[key];
      const match = !value || !value.length;
      return match;
    });
    return isEmpty;
  }

  addAverageRow(data, row) {
    // Adding the Average row at bottom if it doesn't exist
    const hasAverage = data.some(item => {
      return item.period === 'Average';
    });
    if (!hasAverage) data.push(row);
    return data;
  }

  convertToInt(data) {
    Object.keys(data).forEach(key => {
      // Removing Average keyword from fields for uniform field names
      const newKey = key.replace('Average', '');
      data[newKey] = data[key] * 1;
    });
    return data;
  }

  add12MonthsData(data, reportDate) {
    Object.keys(data).forEach(key => {
      // Get an empty model to add based on current data object
      const model = this.getEmptyModel(data[key][0]);
      data[key] = this.set12MonthsData(data[key], reportDate, model);
    });
    return data;
  }

  set12MonthsData(data, reportDate, model) {
    if (!data.length) return;
    const newData = [];
    // Creating 12 months data for each data array
    const prior12Months = getPrior12Months(reportDate, true);
    prior12Months.forEach(month => {
      const found = data.find(item => {
        return month.dateStr == item.period;
      });
      // If date matches between new data object and response data object then add it to the newData array
      if (found) {
        newData.push(found);
      } else {
        // Else add empty model to the newData array
        const emptyModel = { ...model };
        emptyModel['period'] = month.dateStr;
        newData.push(emptyModel);
      }
    });
    return newData;
  }

  getEmptyModel(data) {
    // Creating an empty model to be used to populate empty rows for 12 months data
    const model = { ...data };
    Object.keys(model).forEach(key => {
      model[key] = null;
    });
    return model;
  }

  export4530DisclosureTimelinessSummary(
    rowData, // the trandformed data object
  ): string {
    const exportData: SummaryExportData[] = [
      {
        title: 'FIRM TOTALS - 4530 DISCLOSURE FILINGS',
        rowData: rowData.firm4530DisclosureFilings,
        columnLabels: [
          'Period',
          'Associated Individuals',
          'Initial Filings Submitted',
          'Late Filings Submitted',
          '% Filings Late',
          'Amended Filings Submitted'
        ],
        dataMappings: [
          'period',
          'associateIndividualCount',
          'firmInitialFilingsCount',
          'firmLateFilingsCount',
          'firmLateFilingsPrct',
          'firmAmndFilingsCount'
        ],
      },
      {
        title: TABLE_TITLES.industry4530DisclosureFilings,
        rowData: rowData.industry4530DisclosureFilings,
        columnLabels: INDUSTRY_4530_COLS.map((i: any) => i.headerName),
        dataMappings: INDUSTRY_4530_COLS.map((i: any) => i.field),
      },
      {
        title: TABLE_TITLES.firm4530LateDisclosureFilings,
        rowData: rowData.firm4530LateDisclosureFilings,
        columnLabels: FIRM_4530_LATE_COLS.map((i: any) => i.headerName),
        dataMappings: FIRM_4530_LATE_COLS.map((i: any) => i.field),
      },
    ];

    const csvData = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvData, '\n');
    return formatted;
  }

  exportWebCrdLateFilingFeeSummary(
    rowData, // the trandformed data object
  ): string {
    const exportData: SummaryExportData[] = [
      {
        title: TABLE_TITLES.firmTotalsFormU4U5FilingSummary,
        rowData: rowData.firmTotalsFormU4U5FilingSummary,
        columnLabels: [
          [
            '',
            '',
            FIRM_FORM_U4U5_FILING_COLS[1].headerName
          ],
          [
            ...(<any[]> FIRM_FORM_U4U5_FILING_COLS[0].children).map((i: any) => i.headerName),
            ...(<any[]> FIRM_FORM_U4U5_FILING_COLS[1].children).map((i: any) => i.headerName),
          ],
        ],
        dataMappings: [
          ...(<any[]> FIRM_FORM_U4U5_FILING_COLS[0].children).map((i: any) => i.field),
          ...(<any[]> FIRM_FORM_U4U5_FILING_COLS[1].children).map((i: any) => i.field),
        ],
      },
      {
        title: TABLE_TITLES.firmTotalsLateDisclosureFeeSummary,
        rowData: rowData.firmTotalsLateDisclosureFeeSummary,
        columnLabels: [
          [
            '',
            FIRM_LATE_DISCLOSURE_FEE_COLS[1].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[2].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[3].headerName,
          ],
          [
            FIRM_LATE_DISCLOSURE_FEE_COLS[0].children[0].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[1].children[0].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[1].children[1].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[2].children[0].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[2].children[1].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[3].children[0].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[3].children[1].headerName,
            FIRM_LATE_DISCLOSURE_FEE_COLS[4].children[0].headerName,
          ],
        ],
        dataMappings: [
          FIRM_LATE_DISCLOSURE_FEE_COLS[0].children[0].field,
          FIRM_LATE_DISCLOSURE_FEE_COLS[1].children[0].field,
          FIRM_LATE_DISCLOSURE_FEE_COLS[1].children[1].field,
          FIRM_LATE_DISCLOSURE_FEE_COLS[2].children[0].field,
          FIRM_LATE_DISCLOSURE_FEE_COLS[2].children[1].field,
          FIRM_LATE_DISCLOSURE_FEE_COLS[3].children[0].field,
          FIRM_LATE_DISCLOSURE_FEE_COLS[3].children[1].field,
          FIRM_LATE_DISCLOSURE_FEE_COLS[4].children[0].field,
        ],
      },
      {
        title: TABLE_TITLES.firmTotalsFormU4LateDisclosureFilingDetails,
        rowData: rowData.firmTotalsFormU4LateDisclosureFilingDetails,
        columnLabels: FIRM_FORM_U4_LATE_DISCLOSURE_COLS.map((i: any) => i.headerName),
        dataMappings: FIRM_FORM_U4_LATE_DISCLOSURE_COLS.map((i: any) => i.field),
      },
      {
        title: TABLE_TITLES.firmTotalsFormU5LateDisclosureFilingDetails,
        rowData: rowData.firmTotalsFormU5LateDisclosureFilingDetails,
        columnLabels: FIRM_FORM_U5_LATE_DISCLOSURE_COLS.map((i: any) => i.headerName),
        dataMappings: FIRM_FORM_U5_LATE_DISCLOSURE_COLS.map((i: any) => i.field),
      },
      {
        title: TABLE_TITLES.industryTotalsLateDisclosureFeeSummary,
        rowData: rowData.industryTotalsLateDisclosureFeeSummary,
        columnLabels: INDUSTRY_LATE_DISCLOSURE_FEE_COLS.map((i: any) => i.headerName),
        dataMappings: [
          INDUSTRY_LATE_DISCLOSURE_FEE_COLS[0].children[0].field,
          INDUSTRY_LATE_DISCLOSURE_FEE_COLS[1].children[0].field,
          INDUSTRY_LATE_DISCLOSURE_FEE_COLS[1].children[1].field,
          INDUSTRY_LATE_DISCLOSURE_FEE_COLS[2].children[0].field,
          INDUSTRY_LATE_DISCLOSURE_FEE_COLS[2].children[1].field,
          INDUSTRY_LATE_DISCLOSURE_FEE_COLS[3].children[0].field,
          INDUSTRY_LATE_DISCLOSURE_FEE_COLS[3].children[1].field,
        ],
      },
      {
        title: TABLE_TITLES.firmTotalsLateTerminationFeeSummary,
        rowData: rowData.firmTotalsLateTerminationFeeSummary,
        columnLabels: FIRM_LATE_TERMINATION_COLS.map((i: any) => i.headerName),
        dataMappings: FIRM_LATE_TERMINATION_COLS.map((i: any) => i.field),
      },
      {
        title: TABLE_TITLES.industryTotalsLateTerminationFeeSummary,
        rowData: rowData.industryTotalsLateTerminationFeeSummary,
        columnLabels: INDUSTRY_LATE_TERMINATION_COLS.map((i: any) => i.headerName),
        dataMappings: INDUSTRY_LATE_TERMINATION_COLS.map((i: any) => i.field),
      }
    ];

    const csvData = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvData, '\n');
    return formatted;
  }

  exportWebCrdFormBdTimelinessSummary(
    rowData, // the trandformed data object
  ): string {
    const exportData: SummaryExportData[] = [
      {
        title: TABLE_TITLES.bdDisclosureEvents,
        rowData: rowData.bdDisclosureEvents,
        columnLabels: [
          [
            '',
            BD_DISCLOSURE_COLS[1].headerName,
            '',
            '',
            BD_DISCLOSURE_COLS[2].headerName,
          ],
          [
            BD_DISCLOSURE_COLS[0].headerName,
            ...BD_DISCLOSURE_COLS[1].children.map((i: any) => i.headerName),
            ...BD_DISCLOSURE_COLS[2].children.map((i: any) => i.headerName),
          ],
        ],
        dataMappings: [
          BD_DISCLOSURE_COLS[0].field,
          ...BD_DISCLOSURE_COLS[1].children.map((i: any) => i.field),
          ...BD_DISCLOSURE_COLS[2].children.map((i: any) => i.field),
        ],
      },
      {
        title: TABLE_TITLES.bdLateDisclosureEvents,
        rowData: rowData.bdLateDisclosureEvents,
        columnLabels: BD_LATE_DISCLOSURE_COLS.map((i: any) => i.headerName),
        dataMappings: BD_LATE_DISCLOSURE_COLS.map((i: any) => i.field),
      },
      {
        title: TABLE_TITLES.bdNonDisclosureEvents,
        rowData: rowData.bdNonDisclosureEvents,
        columnLabels: [
          [
            '',
            BD_NON_DISCLOSURE_COLS[1].headerName,
            '',
            '',
            BD_NON_DISCLOSURE_COLS[2].headerName,
          ],
          [
            BD_NON_DISCLOSURE_COLS[0].headerName,
            ...BD_NON_DISCLOSURE_COLS[1].children.map((i: any) => i.headerName),
            ...BD_NON_DISCLOSURE_COLS[2].children.map((i: any) => i.headerName),
          ],
        ],
        dataMappings: [
          BD_NON_DISCLOSURE_COLS[0].field,
          ...BD_NON_DISCLOSURE_COLS[1].children.map((i: any) => i.field),
          ...BD_NON_DISCLOSURE_COLS[2].children.map((i: any) => i.field),
        ],
      },
      {
        title: TABLE_TITLES.bdLateNonDisclosureEvents,
        rowData: rowData.bdLateNonDisclosureEvents,
        columnLabels: BD_LATE_NON_DISCLOSURE_COLS.map((i: any) => i.headerName),
        dataMappings: BD_LATE_NON_DISCLOSURE_COLS.map((i: any) => i.field),
      }
    ];

    const csvData = buildExportDataString(exportData);
    const formatted = trimTrailingChar(csvData, '\n');
    return formatted;
  }
}
