<div [ngClass]="toolbarConfig.containerClass" class="finra-toolbar-container toolbar-main-container pdf" #toolbarContainerElm>
  <div *ngFor="let row of toolbarConfig.rows; index as i" [ngClass]="row.rowClass" [ngStyle]="row.rowStyle" [attr.id]="row.rowId || ('toolbar-row-' + i)">
    <ng-container *ngIf="!row.hideRow">
      <div *ngFor="let cell of row.components">
        <report-finra-toolbar-cell
          [cell]="cell"
        ></report-finra-toolbar-cell>
      </div>
    </ng-container>
  </div>

  <div *ngIf="toolbarConfig.description">
    <hr />
    <p id="finra-toolbar-description" class="description" [innerHTML]="toolbarConfig.description"></p>
  </div>
</div>
