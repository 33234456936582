<div id="registered-representatives-composition-container">
  <report-reps-grid
    class="pdf"
    *ngFor="let repsData of registeredRepsMasterList; index as i"
    [title]="repsData.title"
    [rowData]="repsData.list"
    [columnDefs]="repsData.isAllReps ? allRepsColDefs : sectionRepsColDefs"
    [actionBtn]="repsData.actionBtn"
    [autoGroupColumnDef]="autoGroupColDef"
    [reportInstanceMetadata]="reportInstanceMetadata"
  ></report-reps-grid>
</div>
