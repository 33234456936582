import { FinraGridClickableCellComponent } from "src/app/components/fragments/finra-grid-clickablecell/finra-grid-clickablecell.component";
import {
  addFinraGridColumnId,
  getMonthYrDate,
  finraGridNumericValueFormatter,
  FINRA_GRID_NULL_VALUE,
  finraGridPercentValueFormatter,
} from "src/app/shared/utils/utils";

export interface AllMunicipalTransactionsData {
  period: string;
  industryRank: string;
  peerRank: string;
  peerPopulation: string;
  industryPopulation: string;
  totalCustomerCount: string;
  totalTransactionCount: string;
  peerGroup: string;
}

export interface TransactionsWithNoOSOnEmmaData {
  osEMMAPeriod: string;
  osEMMAFirmCount: string;
  IosEMMAIndustryRank: string;
  osEMMAPeerMean: string;
  osEMMAPeerMedian: string;
  osEMMAFirmPercentage: string;
  osEMMAPeerRank: string;
  osEMMAIndustryMean: string;
  osEMMAIndustryMedian: string;
}

export interface TransactionsLackingCurrentFinancialData {
  period: string;
  lackingIndustryMedian: string;
  lackingIndustryRank: string;
  lackingPeerMedian: string;
  lackingPeerRank: string;
  lackingFirmPercentage: string;
  lackingFirmCount: string;
  lackingPeerMean: string;
  lackingIndustryMean: string;
}

export interface TransactionDuringTradeMonthData {
  period: string;
  tradeMonthFirmCount: string;
  tradeMonthIndustryRank: string;
  tradeMonthPeerRank: string;
  tradeMonthPeerMean: string;
  tradeMonthFirmPercentage: string;
  tradeMonthPeerMedian: string;
  tradeMonthIndustryMean: string;
  tradeMonthIndustryMedian: string;
}

// the next two interfaces have keys/properties that starts with a number.
// this is not how variable names are supposed to be done conventionally
// but that is how the backend sends it so we will index objects with
// bracket notation instead of dot notation.
export interface TransactionDuring1To3OData {
  period: string;
  "1To3FirmIndustryRank": string;
  "1To3FirmPeerMedian": string;
  "1To3FirmPeerRank": string;
  "1To3FirmPercentage": string;
  "1To3FirmPeerMean": string;
  "1To3FirmIndustryMean": string;
  "1To3FirmIndustryMedian": string;
  "1To3FirmCount": string;
}

export interface TransactionDuring4To6Data {
  period: string;
  "4To6PeerMedian": string;
  "4To6FirmCount": string;
  "4To6PeerMean": string;
  "4To6IndustryRank": string;
  "4To6PeerRank": string;
  "4To6IndustryMedian": string;
  "4To6FirmPercentage": string;
  "4To6IndustryMean": string;
}

export interface ContinuingDisclosureResponseData {
  summary: {
    allMunicipalTransaction: AllMunicipalTransactionsData[];
    transactionsWithNoOSOnEmma: TransactionsWithNoOSOnEmmaData[];
    lackingCurrentFinancials: TransactionsLackingCurrentFinancialData[];
    transactionDuringTradeMonth: TransactionDuringTradeMonthData[];
    transactionDuring1To3O: TransactionDuring1To3OData[];
    transactionDuring4To6: TransactionDuring4To6Data[];
  };
}

// AG-GRID column definitions //
/**
 * @see: https://reportcenter.qa.finra.org/scrc/view_rpt.do?rptId=5458504&viewId=597&bgurl=show_rpt&backCatID=3&auditAction=2
 */

export const GET_MSRB_CD_ALL_TRANSACTIONS_COLUMN_DEFS = (
  peerGroupNumberClickHandler: (params) => void
) =>
  addFinraGridColumnId([
    {
      headerName: "Period",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) =>
        (<AllMunicipalTransactionsData>params.data).period,
      valueFormatter: (params) => getMonthYrDate(params.value),
    },
    {
      headerName: "Total Transaction Count",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) =>
        (<AllMunicipalTransactionsData>params.data).totalTransactionCount,
      valueFormatter: finraGridNumericValueFormatter,
    },
    {
      headerName: "Customer Transaction Count",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) =>
        (<AllMunicipalTransactionsData>params.data).totalCustomerCount,
      valueFormatter: finraGridNumericValueFormatter,
    },
    {
      headerName: "Peer Group",
      headerClass: "lines",
      cellClass: "lines",
      cellRendererFramework: FinraGridClickableCellComponent,
      cellRendererFrameworkOptions: {
        isClickable: (params) =>
          !!(<AllMunicipalTransactionsData>params.data).peerGroup,
        showButton: (params) => true,
        buttonClass: (param) => "peer-group-link",
        buttonLabel: (params) =>
          (<AllMunicipalTransactionsData>params.data).peerGroup ||
          FINRA_GRID_NULL_VALUE,
        handleClick: peerGroupNumberClickHandler,
      },
    },
    {
      headerName: "Peer Rank",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) =>
        (<AllMunicipalTransactionsData>params.data).peerRank,
      valueFormatter: finraGridNumericValueFormatter,
    },
    {
      headerName: "Peer Population",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) =>
        (<AllMunicipalTransactionsData>params.data).peerPopulation,
      valueFormatter: finraGridNumericValueFormatter,
    },
    {
      headerName: "Industry Rank",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) =>
        (<AllMunicipalTransactionsData>params.data).industryRank,
      valueFormatter: finraGridNumericValueFormatter,
    },
    {
      headerName: "Industry Population",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) =>
        (<AllMunicipalTransactionsData>params.data).industryPopulation,
      valueFormatter: finraGridNumericValueFormatter,
    },
  ]);

/**
 * Utility Function.
 * provide the properties to use on the data object; the order of the string MATTER!
 * @example:
 * - const noOsColumnDefs = CREATE_SECTION_COLUMN_DEFS([
 *    'noOsPeriod',
 *    'noOsCount',
 *    'noOsPercent',
 *    'noOsPeerMean',
 *    'noOsPeerMedian',
 *    'noOsPeerRank',
 *    'noOsIndustryMean',
 *    'noOsIndustryMedian',
 *    'noOsIndustryRank',
 *  ]);
 */

export const CREATE_SECTION_COLUMN_DEFS = (propsList: string[]) => {
  const columnDefs = addFinraGridColumnId([
    {
      headerName: "Period",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) => params.data[propsList[0]],
      valueFormatter: (params) => getMonthYrDate(params.value),
    },
    {
      headerName: "Count",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) => params.data[propsList[1]],
      valueFormatter: finraGridNumericValueFormatter,
    },
    {
      headerName: "Firm %",
      headerClass: "lines",
      cellClass: "lines",
      valueGetter: (params) => params.data[propsList[2]],
      valueFormatter: finraGridPercentValueFormatter,
    },
    {
      headerName: "Peer %",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Mean",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) => params.data[propsList[3]],
          valueFormatter: finraGridPercentValueFormatter,
        },
        {
          headerName: "Median",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) => params.data[propsList[4]],
          valueFormatter: finraGridPercentValueFormatter,
        },
        {
          headerName: "Rank",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) => params.data[propsList[5]],
          valueFormatter: finraGridNumericValueFormatter,
        },
      ],
    },
    {
      headerName: "Industry %",
      headerClass: "lines",
      cellClass: "lines",
      children: [
        {
          headerName: "Mean",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) => params.data[propsList[6]],
          valueFormatter: finraGridPercentValueFormatter,
        },
        {
          headerName: "Median",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) => params.data[propsList[7]],
          valueFormatter: finraGridPercentValueFormatter,
        },
        {
          headerName: "Rank",
          headerClass: "lines",
          cellClass: "lines",
          valueGetter: (params) => params.data[propsList[8]],
          valueFormatter: finraGridNumericValueFormatter,
        },
      ],
    },
  ]);

  return columnDefs;
};

export const transactionsWithNoOSOnEmmaColumnDefsMappings = [
  "osEMMAPeriod",
  "osEMMAFirmCount",
  "osEMMAFirmPercentage",
  "osEMMAPeerMean",
  "osEMMAPeerMedian",
  "osEMMAPeerRank",
  "osEMMAIndustryMean",
  "osEMMAIndustryMedian",
  "IosEMMAIndustryRank",
];
export const lackingCurrentFinancialsColumnDefsMappings = [
  "period",
  "lackingFirmCount",
  "lackingFirmPercentage",
  "lackingPeerMean",
  "lackingPeerMedian",
  "lackingPeerRank",
  "lackingIndustryMean",
  "lackingIndustryMedian",
  "lackingIndustryRank",
];
export const transactionDuringTradeMonthColumnDefsMappings = [
  "period",
  "tradeMonthFirmCount",
  "tradeMonthFirmPercentage",
  "tradeMonthPeerMean",
  "tradeMonthPeerMedian",
  "tradeMonthPeerRank",
  "tradeMonthIndustryMean",
  "tradeMonthIndustryMedian",
  "tradeMonthIndustryRank",
];
export const transactionDuring1To3OColumnDefsMappings = [
  "period",
  "1To3FirmCount",
  "1To3FirmPercentage",
  "1To3FirmPeerMean",
  "1To3FirmPeerMedian",
  "1To3FirmPeerRank",
  "1To3FirmIndustryMean",
  "1To3FirmIndustryMedian",
  "1To3FirmIndustryRank",
];
export const transactionDuring4To6ColumnDefsMappings = [
  "period",
  "4To6FirmCount",
  "4To6FirmPercentage",
  "4To6PeerMean",
  "4To6PeerMedian",
  "4To6PeerRank",
  "4To6IndustryMean",
  "4To6IndustryMedian",
  "4To6IndustryRank",
];

export const transactionsWithNoOSOnEmmaColumnDefs = CREATE_SECTION_COLUMN_DEFS(
  transactionsWithNoOSOnEmmaColumnDefsMappings
);
export const lackingCurrentFinancialsColumnDefs = CREATE_SECTION_COLUMN_DEFS(
  lackingCurrentFinancialsColumnDefsMappings
);
export const transactionDuringTradeMonthColumnDefs = CREATE_SECTION_COLUMN_DEFS(
  transactionDuringTradeMonthColumnDefsMappings
);
export const transactionDuring1To3OColumnDefs = CREATE_SECTION_COLUMN_DEFS(
  transactionDuring1To3OColumnDefsMappings
);
export const transactionDuring4To6ColumnDefs = CREATE_SECTION_COLUMN_DEFS(
  transactionDuring4To6ColumnDefsMappings
);

export const ALL_MSRB_CONTINUING_DISCLOSURES_COLUMN_DEFS = {
  allMunicipalTransaction: null, // this will be set manually in msrb.component.ts to apply the click listener
  transactionsWithNoOSOnEmma: transactionsWithNoOSOnEmmaColumnDefs,
  lackingCurrentFinancials: lackingCurrentFinancialsColumnDefs,
  transactionDuringTradeMonth: transactionDuringTradeMonthColumnDefs,
  transactionDuring1To3O: transactionDuring1To3OColumnDefs,
  transactionDuring4To6: transactionDuring4To6ColumnDefs,
};
