import { Injectable, Inject } from '@angular/core';
import {
  BEST_EXE_COL_NAMES,
  COL_HEADERS_BY_REPORT_NAME,
  EQUITY_REPORT_IDENTIFIERS,
  MarketOrderTimelinessStatisticalData,
  EquityReportShortNames,
  OATSComplianceMonthlyReportSections,
  OATSComplianceMonthlyAcceptedViewsObj,
  marketOrderTimelinessStatisticalTimebands,
  marketOrderTimelinessStatisticalTimebandsAggNum,
  marketOrderTimelinessStatisticalMappings,
  marketOrderTimelinessStatisticalTitles
} from 'src/app/configs/model/equities-report.model';
import {
  marketTypeExport,
  MarketTypesConfig,
  executionTypeExport,
  groupTypeExport,
  commonGroupKeysOrder,
  regNmsExceptionTypes
} from 'src/app/configs/summary-export/equity.export';
import {
  toFriendlyName,
  MONTH_NAMES_SHORT,
  MONTH_NAMES,
  addFinraGridColumnId,
  numericValueFormatter,
  buildColumnLabelsString,
  buildRowDataString,
  SummaryExportData,
  buildExportDataString,
  trimTrailingChar,
  copyObj,
  FINRA_GRID_NULL_VALUE,
  finraFormatValidValue,
} from '../shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class EquitiesService {
  baseUrl: string;

  constructor(
    @Inject('environment')
    environment
  ) {
    this.baseUrl = environment.ddwaBaseUrl;
  }

  public NO_PERIOD_DATA_MESSAGE = 'No information available for the period.';

  getOATSComplianceDetailViewName(viewName: string): string {
    const formattedViewName = viewName.toLowerCase().replace(/\s+/g, '_');
    switch (formattedViewName) {
      case 'unmatched_execution_reports':
        return 'unmatched_executions';

      case 'exchange_unmatched_route_reports':
        return 'unmatched_routes';

      case 'unmatched_inter_firm_route_reports':
        return 'unmatched_inter_firm_routes';

      case 'reporting_exception_code':
        return 'exception_code';

      default:
        return formattedViewName;
    }
  }

  getOATSComplianceDetailUrls(reportId: number, viewName: string): string {
    const useViewName = this.getOATSComplianceDetailViewName(viewName);
    return `${this.baseUrl}reportData/reportId/${reportId}/viewName/${useViewName}/dataType/d`;
  }

  /**
   * Format data for Equity OATS Compliance Monthly report
   * @param data the response data from the summary call
   * @param shouldGroupBySection an indicator if all a the data should be grouped by section or not
   * @returns {object | array}
   */
  getOATSComplianceMonthlyReportCard(
    data: object,
    shouldGroupBySection: boolean = false
  ) {
    const result: any = shouldGroupBySection ? {} : [];

    let totalAcceptedROEs = 0;
    let totalAcceptedROEsColumnName = '';

    OATSComplianceMonthlyReportSections.forEach(key => {
      if (!Array.isArray(data[key])) {
        return;
      }

      const sectionListData = [];

      const sectionInfo = {
        section: key,
        label: toFriendlyName(key),
        type: 'h1',
        clickable: key !== 'exceptionSummary'
      };
      if (!shouldGroupBySection) {
        result.push(sectionInfo);
      } else {
        sectionListData.push(sectionInfo);
      }

      let currentGroup = null;

      for (let i = 0; i < data[key].length; i++) {
        const group = data[key][i]['group'];

        if (group && group !== currentGroup) {
          currentGroup = group;

          const groupInfo = {
            label: toFriendlyName(group) + ' Group',
            type: 'h2'
          };
          if (!shouldGroupBySection) {
            result.push(groupInfo);
          } else {
            sectionListData.push(groupInfo);
          }
        }

        let label = data[key][i]['displayName'];
        let type = 'data';

        if (currentGroup != null) {
          label = label.replace(new RegExp('\\(' + group + '\\)', 'ig'), '');
          type = 'group-data';
        }

        if (label === 'Total No. of Accepted Reportable Order Events (ROE)') {
          totalAcceptedROEs = data[key][i]['value'];
          totalAcceptedROEsColumnName = data[key][i]['columnName'];
        }

        const isPercentage = (data[key][i]['columnName'] as string).search(/%|percent/i) > -1;
        const dataObj = {
          label: label,
          rawValue: data[key][i]['value'],
          value: isPercentage ? parseFloat(data[key][i]['value']).toFixed(2) : data[key][i]['value'],
          columnName: data[key][i]['columnName'],
          type: type,
          mask: isPercentage ? '%' : '#',
          isPercentage,
        };
        if (!shouldGroupBySection) {
          result.push(dataObj);
        } else {
          sectionListData.push(dataObj);
        }
      }

      if (shouldGroupBySection) {
        result[key] = sectionListData;
      } else {
        result.concat(sectionListData);
      }
    });

    const totalData = {
      section: 'total',
      label: 'Total No. of Accepted Reportable Order Events (ROE)',
      value:
        data['acceptedReportableOrderEventsCount'] &&
        data['acceptedReportableOrderEventsCount'][0]['value'],
      columnName:
        data['acceptedReportableOrderEventsCount'] &&
        data['acceptedReportableOrderEventsCount'][0]['columnName'],
      type: 'data-title',
      mask: '#'
    };

    if (shouldGroupBySection) {
      // result should be an array of arrays;
      // push the total data into an array and push that to result
      result['total'] = [totalData];
    } else {
      // result should be a flat array at this point;
      // just add the total data in the list
      result.unshift(totalData);
    }

    return result;
  }

  getFirmSummaryScoreCard(data) {
    let sortedData = [];
    data.forEach(element => {
      element['monthYear'] = `${
        MONTH_NAMES_SHORT[parseInt(element.monthOfYearNumber, 10)]
      } ${element.yearNumber}`;
      element['reportType'] =
        BEST_EXE_COL_NAMES[element.reportCardTypeDescription];
    });
    // setting data as per the required group order.
    const trr = data
      .filter(item => {
        return item.reportCardTypeDescription === 'TRR';
      })
      .sort(this.sortDesc);
    const actr = data
      .filter(item => {
        return item.reportCardTypeDescription === 'ACTR';
      })
      .sort(this.sortDesc);
    const beccqs = data
      .filter(item => {
        return item.reportCardTypeDescription === 'BECQS';
      })
      .sort(this.sortDesc);
    const bennm = data
      .filter(item => {
        return item.reportCardTypeDescription === 'BENNM';
      })
      .sort(this.sortDesc);
    const oc = data
      .filter(item => {
        return item.reportCardTypeDescription === 'OC';
      })
      .sort(this.sortDesc);
    const mot = data
      .filter(item => {
        return item.reportCardTypeDescription === 'MOT';
      })
      .sort(this.sortDesc);
    const tt = data
      .filter(item => {
        return item.reportCardTypeDescription === 'TT';
      })
      .sort(this.sortDesc);
    sortedData = [...sortedData, ...beccqs];
    sortedData = [...sortedData, ...bennm];
    sortedData = [...sortedData, ...mot];
    sortedData = [...sortedData, ...oc];
    sortedData = [...sortedData, ...tt];
    sortedData = [...sortedData, ...trr];
    sortedData = [...sortedData, ...actr];
    return sortedData;
  }

  // DDWA-3211 Sorting Firm Summary Scorecard data descending before display
  sortDesc(a, b) {
    const dateA = new Date(a.monthStartDate).getTime();
    const dateB = new Date(b.monthStartDate).getTime();
    return dateB - dateA;
  }

  getBestExecutionReport(data, reportName) {
    // Flattening the values for ag-grid
    data = this.flattenValues(data);
    // Filtering out Month and Year as its not displayed in row data
    data = data.filter(element => {
      return (
        element.columnName !== 'monthOfYearNumber' &&
        element.columnName !== 'yearNumber'
      );
    });
    // For best execution report only
    if (reportName === EQUITY_REPORT_IDENTIFIERS.BEST_EXE) {
      data.forEach(element => {
        // Adding the info alert at the bottom
        if (element.columnName === 'agencyAlertCount') {
          element.group = null;
          element.marketType = 'infoAlert';
        }
        // Removing the group for values that do not have one.
        if (element.group === 'general') {
          element.group = null;
        }
      });
    }
    return data;
  }

  getDataByMappingName(
    data: any[],
    prop: string
  ) {
    // create object map of list data by column name for easy/dynamic access
    // in the loops below
    const dataByMappingName = {};
    data.forEach((obj) => {
      dataByMappingName[obj[prop]] = obj;
    });
    return dataByMappingName;
  }

  /** Market Order Timeliness Statistics Methods */

  flattenTimeSeriesValuesByPeriod(
    i: number,
    dataByMappingName: { [key: string]: MarketOrderTimelinessStatisticalData },
    dataMap: { [key: string]: MarketOrderTimelinessStatisticalData[] }
  ) {
    const keyNumber = i + 1;
    // process firm info
    const firmCountInfo = dataByMappingName[`firmInterval${keyNumber}Count`];
    const firmPercentInfo = dataByMappingName[`firmInterval${keyNumber}Percent`];
    const firmData = this.flattenStatisticalValues([
      firmCountInfo,
      firmPercentInfo
    ]);
    dataMap.firm.push(...firmData);

    // process peer info
    const peerPercentInfo = dataByMappingName[`peerInterval${keyNumber}Percent`];
    const peerData = this.flattenStatisticalValues([
      peerPercentInfo
    ]);
    dataMap.peer.push(...peerData);

    // process industry info
    const industryPercentInfo = dataByMappingName[`indusInterval${keyNumber}Percent`];
    const industryData = this.flattenStatisticalValues([
      industryPercentInfo
    ]);
    dataMap.industry.push(...industryData);
  }

  flattenTimebandDistributionValuesByPeriod(
    i: number,
    dataByMappingName: { [key: string]: MarketOrderTimelinessStatisticalData },
    dataMap: { [key: string]: MarketOrderTimelinessStatisticalData[] }
  ) {
    const timebandKey = marketOrderTimelinessStatisticalTimebands[i];
    if (!timebandKey) {
      // do not add this row
      return;
    }

    const firmTimeband = dataByMappingName[`firmInterval${timebandKey}Percent`];
    const firmTimebandData = this.flattenStatisticalValues([
      firmTimeband
    ], false);

    const peerTimeband = dataByMappingName[`peerInterval${timebandKey}Percent`];
    const peerTimebandData = this.flattenStatisticalValues([
      peerTimeband
    ]);

    const industryTimeband = dataByMappingName[`indusInterval${timebandKey}Percent`];
    const industryTimebandData = this.flattenStatisticalValues([
      industryTimeband
    ]);

    dataMap.firmTimeband.push(firmTimebandData[0]);
    dataMap.peerTimeband.push(peerTimebandData[0]);
    dataMap.industryTimeband.push(industryTimebandData[0]);
  }

  getTimeSeriesTotalsByPeriod(
    i: number,
    periodsLookbackLength: number,
    dataByMappingName: { [key: string]: MarketOrderTimelinessStatisticalData },
  ) {
    // process totals in the item's values list
    let firmCountTotal = 0;
    let firmPercentTotal = 0;
    let peerPercentTotal = 0;
    let industryPercentTotal = 0;
    for (let k = 0; k < periodsLookbackLength; k++) {
      // aggregate totals for current period (represented by `i` variable in outer loop)
      const firmCount = dataByMappingName[`firmInterval${k + 1}Count`];
      const firmCountValue = firmCount.values[i];
      const newFirmCount = firmCountTotal + firmCountValue;
      firmCountTotal = newFirmCount;

      const firmPercent = dataByMappingName[`firmInterval${k + 1}Percent`];
      const firmPercentValue = firmPercent.values[i];
      const newFirmPercent = firmPercentTotal + firmPercentValue;
      firmPercentTotal = newFirmPercent;

      const peerPercent = dataByMappingName[`peerInterval${k + 1}Percent`];
      const peerPercentValue = peerPercent.values[i];
      const newPeerPercent = peerPercentTotal + peerPercentValue;
      peerPercentTotal = newPeerPercent;

      const industryPercent = dataByMappingName[`indusInterval${k + 1}Percent`];
      const industryPercentValue = industryPercent.values[i];
      const newIndustryPercent = industryPercentTotal + industryPercentValue;
      industryPercentTotal = newIndustryPercent;
    }

    return {
      firmCountTotal,
      firmPercentTotal,
      peerPercentTotal,
      industryPercentTotal,
    };
  }

  /**
   * https://jira.finra.org/browse/DDWA-6349
   * ---
   * @deprecated
   * in the timeband distributions tables, we are now ignoring ‘>=’ rows.
   * thus, this method is not needed as of now
  */
  aggregateTimebandDistributions(
    periodsLookbackLength: number,
    aggNum: number,
    iteration: number, // the loop iteration, basically i + 1 where i is the loop iteration starting at 0
    dataMap: { [key: string]: MarketOrderTimelinessStatisticalData[] },
    timebandAggMap: { [key: string]: any[] }
  ) {
    const firmAggTimebandPercentTotals: number[] = Array(periodsLookbackLength).fill(0).map((v, i) => 0);
    const peerAggTimebandPercentTotals: number[] = Array(periodsLookbackLength).fill(0).map((v, i) => 0);
    const industryAggTimebandPercentTotals: number[] = Array(periodsLookbackLength).fill(0).map((v, i) => 0);

    const firmValuesList = [];
    const peerValuesList = [];
    const industryValuesList = [];
    for (let k = 1; k <= aggNum; k++) {
      const index = iteration - k;
      // create 2-d array of items by values to aggreate
      firmValuesList.push(dataMap.firmTimeband[index].values);
      peerValuesList.push(dataMap.peerTimeband[index].values);
      industryValuesList.push(dataMap.industryTimeband[index].values);
    }
    for (let k = 0; k < periodsLookbackLength; k++) {
      // reduce items value by period
      const firmAggPercentTotal = firmValuesList.reduce((acc, cur) => acc + cur[k], 0);
      const peerAggPercentTotal = peerValuesList.reduce((acc, cur) => acc + cur[k], 0);
      const industryAggPercentTotal = industryValuesList.reduce((acc, cur) => acc + cur[k], 0);

      // add aggregated totals to the current period index
      firmAggTimebandPercentTotals[k] = firmAggPercentTotal;
      peerAggTimebandPercentTotals[k] = peerAggPercentTotal;
      industryAggTimebandPercentTotals[k] = industryAggPercentTotal;
    }

    // create totals row data and add to temporary list;
    // will be inserted later. this is to avoid conflict with loop logic and indexing
    // add the item and insertion index where item should go

    const insertIndex = dataMap.firmTimeband.length + timebandAggMap.firm.length;

    // firm
    const firmAggPercentTotalsData: MarketOrderTimelinessStatisticalData = {
      displayName: 'Total',
      columnName: 'percent',
      group: null,
      values: firmAggTimebandPercentTotals,
    };
    (<any> firmAggPercentTotalsData).shouldAddRowBorderBottomClass = true; // for visual separation in ag-grid
    const firmAggTotalsData = this.flattenStatisticalValues([
      firmAggPercentTotalsData
    ]);
    timebandAggMap.firm.push({
      item: firmAggTotalsData[0],
      index: insertIndex
    });

    // peer
    const peerAggPercentTotalsData: MarketOrderTimelinessStatisticalData = {
      displayName: 'Total',
      columnName: 'percent',
      group: null,
      values: peerAggTimebandPercentTotals
    };
    (<any> peerAggPercentTotalsData).shouldAddRowBorderBottomClass = true;
    const peerAggTotalsData = this.flattenStatisticalValues([
      peerAggPercentTotalsData,
    ]);
    timebandAggMap.peer.push({
      item: peerAggTotalsData[0],
      index: insertIndex
    });

    // industry
    const industryAggPercentTotalsData: MarketOrderTimelinessStatisticalData = {
      displayName: 'Total',
      columnName: 'percent',
      group: null,
      values: industryAggTimebandPercentTotals
    };
    (<any> industryAggPercentTotalsData).shouldAddRowBorderBottomClass = true;
    const industryAggTotalsData = this.flattenStatisticalValues([
      industryAggPercentTotalsData
    ]);
    timebandAggMap.industry.push({
      item: industryAggTotalsData[0],
      index: insertIndex
    });
  }

  addTimeSeriesTotals(
    dataMap: { [key: string]: MarketOrderTimelinessStatisticalData[] },
    totals: {
      firmCountTotals: number[];
      firmPercentTotals: number[];
      peerPercentTotals: number[];
      industryPercentTotals: number[];
    }
  ) {
    // create totals for firm, peer, industry

    // firm
    const firmCountTotalsData: MarketOrderTimelinessStatisticalData = {
      displayName: 'Total Market Orders',
      columnName: 'firmCount', // set to 'firmCount' on purpose so method can aggregate the next list item
      group: null,
      values: totals.firmCountTotals
    };
    const firmPercentTotalsData: MarketOrderTimelinessStatisticalData = {
      // object gets targeted by the previous iteration in the method
      displayName: '',
      columnName: '', // leaving blank to EquitiesService.formatValue method will return value as is
      group: null,
      values: ['', '', '', '', '', ''] // setting to empty strings list explicitly to display blank for firm percentage totals
    };
    const firmTotalsData = this.flattenStatisticalValues([
      firmCountTotalsData,
      firmPercentTotalsData
    ]);
    dataMap.firm.unshift(firmTotalsData[0]); // adding to top of list

    // peer
    // const peerPercentTotalsData: MarketOrderTimelinessStatisticalData = {
    //   displayName: 'Total',
    //   columnName: 'peerPercent', // this gets targeted by this.formatValue for formatting
    //   group: null,
    //   values: totals.peerPercentTotals
    // };
    // const peerTotalsData = this.flattenStatisticalValues([
    //   peerPercentTotalsData,
    // ]);
    // dataMap.peer.push(peerTotalsData[0]);

    // industry
    // const industryPercentTotalsData: MarketOrderTimelinessStatisticalData = {
    //   displayName: 'Total',
    //   columnName: 'industryPercent',
    //   group: null,
    //   values: totals.industryPercentTotals
    // };
    // const industryTotalsData = this.flattenStatisticalValues([
    //   industryPercentTotalsData
    // ]);
    // dataMap.industry.push(industryTotalsData[0]);
  }

  /**
   * https://jira.finra.org/browse/DDWA-6349
   * ---
   * @deprecated
   * in the timeband distributions tables, we are now ignoring ‘>=’ rows.
   * thus, this method is not needed as of now
  */
  insertAggregateTimebandDistributions(
    dataMap: { [key: string]: MarketOrderTimelinessStatisticalData[] },
    timebandAggMap: { [key: string]: any[] }
  ) {
    // insert the aggregated time bands
    const len = timebandAggMap.firm.length;
    for (let i = 0; i < len; i++) {
      const firmAggObj = timebandAggMap.firm[i];
      const peerAggObj = timebandAggMap.peer[i];
      const industryAggObj = timebandAggMap.industry[i];

      dataMap.firmTimeband.splice(firmAggObj.index, 0, firmAggObj.item);
      dataMap.peerTimeband.splice(peerAggObj.index, 0, peerAggObj.item);
      dataMap.industryTimeband.splice(industryAggObj.index, 0, industryAggObj.item);
    }
  }

  parseMarketOrderTimelinessStatisticalResponse(
    data: MarketOrderTimelinessStatisticalData[]
  ) {
    /**
     * https://jira.finra.org/browse/DDWA-5689
     *
     * The data structure of the summary of this report is not in the typical linear format.
     * each item in the list (the `data` argument to this function) also has a property named `values`,
     * which is another list of values, so there is a 2-dimensional-array situation at play.
     * Each item in the list represents a time series that spans vertically (row data) and the values
     * list on each item represents the periods, which spans horizontally (column data).
     *
     * There are 6 sections/tables for the view:
     * - firm
     * - firm timeband distribution
     * - peer
     * - peer timeband distribution
     * - industry
     * - industry timeband distribution
     *
     * firm has count and percent data for each period; peer and industry only has percent data.
     * firm, peer and industry has a totals row. AG-Grid has a totals aggregating capability however
     * this method does the calculation manually in order to remove that dependency and avoid any potential
     * bugs in the event AG-Grid updates their library.
     *
     * What this function does:
     * - initialize a data map object that contains the data for each of the 6 sections/tables
     * - create a map of all the list items by column name
     * - set loop for each period
     *   - get the firm, peer and industry data for that period via the map
     *   - flatten the values of each list item (flatten meaning creating a property on the list item object for each of the period values)
     *   - aggregate the total in each iteration of the periods loop
     *   - flatten the timebands of firm, peer and industry
     *   - aggregate the timebands by grouping number
     * - create and add aggregated to appropriate list in data map
     * - return data map
     */

    // prepare the data object for the row data
    const dataMap: {
      [key: string]: MarketOrderTimelinessStatisticalData[]
    } = {
      firm: [],
      firmTimeband: [],
      peer: [],
      peerTimeband: [],
      industry: [],
      industryTimeband: [],
    };

    // prepare the data object for the aggregate timeband distributions (used temporarily)
    const timebandAggMap = {
      firm: [],
      peer: [],
      industry: []
    };

    // prepare data mapping for easy access
    const dataByMappingName = this.getDataByMappingName(
      data,
      'columnName'
    );

    // using shorter variable name for local use
    const aggNum = marketOrderTimelinessStatisticalTimebandsAggNum;

    // set period lookback
    // current business requirement is 6 periods
    // this variable should reflect the length of the `values` in every item in the list
    const periodsLookbackLength = data && data[0].values.length || 0;

    // aggregare the totals while in the loop
    const firmCountTotals: number[] = Array(periodsLookbackLength).fill(0).map((v, i) => 0);
    /**
     * https://jira.finra.org/browse/DDWA-6349
     * ---
     * we are now ignoring/hiding the following:
     * - total percentages in firm statistics table.
     * - total percentages in peer statistics table (essentially the whole row).
     * - total percentages in industry statistics table (essentially the whole row).
     * although it is being calculated, it will be ignored in Equities.addTimeSeriesTotals method
    */
    const firmPercentTotals: number[] = Array(periodsLookbackLength).fill(0).map((v, i) => 0);
    const peerPercentTotals: number[] = Array(periodsLookbackLength).fill(0).map((v, i) => 0);
    const industryPercentTotals: number[] = Array(periodsLookbackLength).fill(0).map((v, i) => 0);

    for (let i = 0; i < periodsLookbackLength; i++) {
      // process time series
      this.flattenTimeSeriesValuesByPeriod(i, dataByMappingName, dataMap);

      // add aggregated totals to the current period index
      const totalsResults = this.getTimeSeriesTotalsByPeriod(i, periodsLookbackLength, dataByMappingName);
      firmCountTotals[i] = totalsResults.firmCountTotal;
      firmPercentTotals[i] = totalsResults.firmPercentTotal;
      peerPercentTotals[i] = totalsResults.peerPercentTotal;
      industryPercentTotals[i] = totalsResults.industryPercentTotal;

      // process timeband distributions
      this.flattenTimebandDistributionValuesByPeriod(i, dataByMappingName, dataMap);
    }

    this.addTimeSeriesTotals(dataMap, {
      firmCountTotals,
      firmPercentTotals,
      peerPercentTotals,
      industryPercentTotals,
    });

    // this.insertAggregateTimebandDistributions(dataMap, timebandAggMap);

    return dataMap;
  }

  createMonthYearColumnDefsStatistical(data, reportName) {
    // filter the data to get 'monthOfYearNumber' and 'yearNumber' values only
    data = data.filter(element => {
      return (
        element.columnName === 'monthOfYearNumber' ||
        element.columnName === 'yearNumber'
      );
    });

    // group is no longer needed
    const useBaseColDef = COL_HEADERS_BY_REPORT_NAME[reportName].filter(c => c.field !== 'group');

    // Get firm equity headers and build on that
    // Dynamically generate columnDefs for various reports based on the data.
    const firmColumnDefs = copyObj([...useBaseColDef]);
    for (let i = 0; i < 6; i++) {
      const colDef = {
        headerName: `${MONTH_NAMES[parseInt(data[0]['values'][i], 10)]} ${
          data[1]['values'][i]
        }`,
        children: [
          { headerName: 'Count', field: `countColumn${i}` },
          { headerName: '%', field: `percentColumn${i}` },
        ]
      };
      firmColumnDefs.push(colDef);
    }
    firmColumnDefs[0].cellRenderer = null;

    // Get basic equity headers and build on that
    // Dynamically generate columnDefs for various reports based on the data.
    const columnDefs = copyObj([...useBaseColDef]);
    for (let i = 0; i < 6; i++) {
      const month = MONTH_NAMES[parseInt(data[0]['values'][i], 10)];
      const year = data[1]['values'][i];
      const headerName = `${month} ${year}`;
      const colDef = {
        headerName,
        field: `column${i}`,
      };
      columnDefs.push(colDef);
    }
    columnDefs[0].cellRenderer = null;
    const columnDefsWithIds = addFinraGridColumnId(columnDefs);

    return {
      firm: addFinraGridColumnId(firmColumnDefs),
      firmTimeband: columnDefsWithIds,
      peer: columnDefsWithIds,
      peerTimeband: columnDefsWithIds,
      industry: columnDefsWithIds,
      industryTimeband: columnDefsWithIds,
    };
  }

  flattenStatisticalValues(
    data: MarketOrderTimelinessStatisticalData[],
    skipFirmPercent: boolean = true
  ): any[] {
    // flattenStatisticalValues relies on the `columnName` to determine
    // if the data is firm count, firm percent, or firm time band
    // if columnName starts with "firm", ends with "Percent", and does NOT include "To",
    // ignore it; it will be processed by the firm count iteration
   const newList = [];
    // values come as array so flattening it out for ag-grid.
    data.forEach((element, elementIndex) => {
      // process firm info
      // if firm count info, process it AND the percentage
      // the percent info is assumed to be the next item in the list
      const isFirmCountInfo = (<string> element.columnName).startsWith('firm') && (<string> element.columnName).endsWith('Count');
      if (isFirmCountInfo) {
        const firmCountInfo = element;
        const firmPercentInfo = data[elementIndex + 1];
        const length = firmCountInfo.values.length;
        for (let i = 0; i < length; i++) {
          const countValue = this.formatValue(
            firmCountInfo.values[i],
            firmCountInfo.columnName.toLowerCase()
          );
          element[`countColumn${i}`] = finraFormatValidValue(countValue);

          const percentValue = this.formatValue(
            firmPercentInfo.values[i],
            firmPercentInfo.columnName.toLowerCase(),
            true
          );
          element[`percentColumn${i}`] = finraFormatValidValue(percentValue, true);
        }
        (<any> element).percentValues = firmPercentInfo.values;
        newList.push(element);
        return;
      }
      const isFirmPercentInfo =
        (<string> element.columnName).startsWith('firm') &&
        (<string> element.columnName).endsWith('Percent');
      if (isFirmPercentInfo && skipFirmPercent) {
        // firm percent info will be processed on firm count iterations
        return;
      }
      // process peer/industry
      element.values.forEach((value, index) => {
        const columnValue = this.formatValue(
          value,
          element.columnName.toLowerCase(),
          true
        );
        element[`column${index}`] = finraFormatValidValue(columnValue);
      });
      newList.push(element);
    });
    return newList;
  }

  /** END Market Order Timeliness Statistics Methods */

  getContraFirmReport(data) {
    // Flattening the values for ag-grid
    data = this.flattenValues(data);
    // Filtering out Month and Year as its not displayed in row data
    data = data.filter(element => {
      return (
        element.columnName !== 'monthOfYearNumber' &&
        element.columnName !== 'yearNumber' &&
        // Dapi has inconsistent field name case so converting to lower case to filter
        element.columnName.toLowerCase() !== 'timeStampIntervalNumber'.toLowerCase()
      );
    });
    return data;
  }

  createMonthYearColumnDefs(data, reportName) {
    // filter the data to get 'monthOfYearNumber' and 'yearNumber' values only
    data = data.filter(element => {
      return (
        element.columnName === 'monthOfYearNumber' ||
        element.columnName === 'yearNumber'
      );
    });
    // Get basic equity headers and build on that
    const columnDefs = [...COL_HEADERS_BY_REPORT_NAME[reportName]];
    // Dynamically generate columnDefs for various reports based on the data.
    for (let i = 0; i < data[0]['values'].length; i++) {
      const colDef = {
        headerName: `${MONTH_NAMES[parseInt(data[0]['values'][i], 10)]} ${
          data[1]['values'][i]
        }`,
        field: `column${i}`
      };
      columnDefs.push(colDef);
    }
    return columnDefs;
  }

  flattenValues(data) {
    // values come as array so flattening it out for ag-grid.
    data.forEach(element => {
      element.values.forEach((value, index) => {
        const columnValue = this.formatValue(
          value,
          element.columnName.toLowerCase()
        );
        element[`column${index}`] = finraFormatValidValue(columnValue);
      });
    });
    return data;
  }

  /* This function fixes value for percent and number formatting
  TODO: Can we assume that values are always numeric?. If yes we can refactor this function
  */
  formatValue(value, columnName, shouldMultiply?: boolean) {
    if (columnName.indexOf('percent') > -1 || columnName.indexOf('%') > -1) {
      const returnValue = (value === null || value === undefined)
        ? FINRA_GRID_NULL_VALUE
        : numericValueFormatter(shouldMultiply ? (value * 100) : value, '%');
      return returnValue;
    }

    const shouldProcessAsInteger = (
      columnName.indexOf('ranknumber') > -1 ||
      columnName.indexOf('count') > -1
    );
    if (shouldProcessAsInteger) {
      return numericValueFormatter(value, '');
    }

    const notNumber = value === '' || value == null || value === undefined || isNaN(value);
    const useFormattedValue = notNumber ? value : new Intl.NumberFormat('en-US').format(value);
    return useFormattedValue;
  }

  addNoDataMessage(data) {
    // Adding no data available message for periods that do not have data
    for (let i = 0; i <= 5; i++) {
      const isNull = data.every(row => {
        return row[`column${i}`] == null;
      });

      data[0][`column${i}`] = isNull
        ? this.NO_PERIOD_DATA_MESSAGE
        : data[0][`column${i}`];
    }
    return data;
  }

  addNoDataMessageFirmSum(data) {
    const obj = data[0];
    Object.keys(obj).forEach(prop => {
      const isNull = data.every(row => {
        return row[prop] == null;
      });
      data[0][prop] = isNull ? this.NO_PERIOD_DATA_MESSAGE : data[0][prop];
    });
    return data;
  }

  transformViewsList(views: string[], reportName: string) {
    switch (reportName) {
      case EquityReportShortNames.OATS_COMPLIANCE_MONTHLY: {
        const isAcceptedView = viewName =>
          !!OATSComplianceMonthlyAcceptedViewsObj[viewName];
        const newViewsList = views.filter(isAcceptedView).sort();
        return newViewsList;
      }
      default: {
        return views;
      }
    }
  }

  /** Summary Export Logic */

  exportEquitySummaryData(reportName, rowData, columnDefs): string {
    const marketTypes = MarketTypesConfig[reportName];
    switch (reportName) {
      // has: market types, groups
      case EquityReportShortNames.CONTRA_REPORTING_FIRM_20_MINUTE_COMPLIANCE:
      case EquityReportShortNames.CONTRA_EXECUTING_FIRM_20_MINUTE_COMPLIANCE:
      case EquityReportShortNames.REPORTING_FIRM_10_SECOND_COMPLIANCE:
      case EquityReportShortNames.EXECUTING_FIRM_10_SECOND_COMPLIANCE: {
        return marketTypeExport(rowData, columnDefs, marketTypes);
      }
      case EquityReportShortNames.BEST_EXECUTION_OUTSIDE_OF_THE_INSIDE: {
        return marketTypeExport(
          rowData,
          columnDefs,
          marketTypes,
          true
        );
      }

      // has: market types, execution types, groups
      case EquityReportShortNames.REG_NMS_TRADE_THROUGH: {
        return executionTypeExport(
          rowData,
          columnDefs,
          marketTypes,
          regNmsExceptionTypes,
        );
      }

      // has: groups
      case EquityReportShortNames.MARKET_ORDER_TIMELINESS: {
        const newColDefs = columnDefs.map((i: any) => ({
          headerName: i.headerName,
          field: i.field
        }));
        newColDefs[0].headerName = 'Group';
        return groupTypeExport(
          rowData,
          newColDefs,
          'group',
          commonGroupKeysOrder,
        );
      }
      case EquityReportShortNames.FIRM_SUMMARY_SCORECARD: {
        const newColDefs = columnDefs.map((i: any) => ({
          headerName: i.headerName,
          field: i.field
        }));
        newColDefs[0].headerName = 'Report Type';
        return groupTypeExport(
          rowData,
          newColDefs,
          'reportType',
          null,
        );
      }

      // OATS Compliance Monthly Report Card
      case EquityReportShortNames.OATS_COMPLIANCE_MONTHLY: {
        const massCsvDataStringList = [];
        const rowDataIsArray = Array.isArray(rowData);
        let list;

        if (!rowDataIsArray) {
          list = OATSComplianceMonthlyReportSections.reduce((acc, key) => acc.concat(rowData[key]), []);
        } else {
          list = rowData;
        }

        const mainColumnsString = buildColumnLabelsString([
          'Name',
          'Group',
          'Label',
          'Value'
        ]);
        massCsvDataStringList.push(mainColumnsString);

        for (const item of list) {
          if (item.type === 'h1') {
            const itemH1Label = buildColumnLabelsString([item.label]);
            massCsvDataStringList.push(itemH1Label);
          } else if (item.type === 'h2') {
            const itemH2Label = buildColumnLabelsString(['', item.label]);
            massCsvDataStringList.push(itemH2Label);
          } else if (
            item.type === 'data' ||
            item.type === 'group-data' ||
            item.type === 'data-title'
          ) {
            const itemDataString = buildRowDataString({
              rowData: [item],
              dataMappings: ['', '', 'label', 'value']
            });
            massCsvDataStringList.push(itemDataString);
          }
        }

        const massCsvString = massCsvDataStringList.join('');
        return massCsvString;
      }

      case EquityReportShortNames.MARKET_ORDER_TIMELINESS_STATISTICAL: {
        const commonColumnLabels = [];
        const commonDataMappings = [];
        columnDefs['peer'].forEach(colDef => {
          commonColumnLabels.push(colDef.headerName);
          commonDataMappings.push(colDef.field);
        });
        const otherExportsSummaryInfo = marketOrderTimelinessStatisticalMappings
          .slice(1)
          .map((mapping) => {
            const exportObj: SummaryExportData = {
              title: marketOrderTimelinessStatisticalTitles[mapping],
              rowData: rowData[mapping],
              dataMappings: commonDataMappings,
              columnLabels: commonColumnLabels
            };
            return exportObj;
          });

        const firmfirstHeadingsRow = [''];
        commonColumnLabels.slice(1).forEach((heading) => {
          firmfirstHeadingsRow.push(heading, '');
        });
        const firmSecondHeadingsRow = ['Field Name'];
        const firmDataMappings = ['displayName'];

        const firmDataColumns = columnDefs['firm'].slice(1);
        for (const column of firmDataColumns) {
          column.children.forEach((childColumn) => {
            firmSecondHeadingsRow.push(childColumn.headerName);
            firmDataMappings.push(childColumn.field);
          });
        }
        const exportSummaryData: SummaryExportData[] = [
          {
            title: 'Firm Statistics',
            rowData: rowData['firm'],
            dataMappings: firmDataMappings,
            columnLabels: [
              firmfirstHeadingsRow,
              firmSecondHeadingsRow
            ]
          },
          ...otherExportsSummaryInfo
        ];

        const csvData = buildExportDataString(exportSummaryData);
        const formatted = trimTrailingChar(csvData, '\n');
        return formatted;
      }

      // no export for the given reportName
      default: {
        // `No summary export for report: ${reportName}`, rowData, columnDefs
        return null; // to let caller know if export was successful or not
      }
    }
  }
}
