import { Component, Input, OnInit } from '@angular/core';
import {
  faQuestionCircle, IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { GridOptions } from 'ag-grid-community';
import { ReportInstanceMetadata } from 'src/app/configs/model/reports.model';
import { BeastClickEventReportHelpLinkInfo } from 'src/app/interfaces/beast.interface';
import { BeastService } from 'src/app/services/beast.service';
import { CorpFinService } from 'src/app/services/corp-fin.service';
import { BeastClickActions } from '../../../../../../enums/beast.enum';

@Component({
  selector: 'public-offering-report',
  templateUrl: './public-offering-report.component.html',
  styleUrls: ['./public-offering-report.component.scss']
})
export class PublicOfferingReportComponent implements OnInit {
  @Input() reportInstanceMetadata: ReportInstanceMetadata;
  @Input() columnDefs;
  @Input() title;
  @Input() viewName;
  @Input() loading;
  @Input() helpLink;
  @Input() autoGroupColumnDef;
  @Input() gridOptions: GridOptions = {
    groupIncludeTotalFooter: false
  };

  _rowData: object[];

  @Input()
  get rowData(): object[] {
    return this._rowData;
  }

  set rowData(rowData: object[]) {
    this._rowData = rowData;

    if (rowData) {
      if (!this.viewName) {
        // @ts-ignore
        if (rowData.some(p => p.industryFilingPercent)) {
          this.viewName = 'firmCorpFin5110ReviewCurrentPeriodSubmissionSummary';
        }
        // @ts-ignore
        if (rowData.some(p => p.dealOver6MonthsFromInitialFilingCount)) {
          this.viewName = 'firmCorpFin5110ReviewPriorPeriodSubmissionSummary';
        }
        // @ts-ignore
        if (rowData.some(p => p.letterTypeCode)) {
          this.viewName = 'firmCorpFin5110ReviewCommentLetterSummary';
        }
        // @ts-ignore
        if (rowData.some(p => p.distributionMethodDescription)) {
          this.viewName = 'firmCorpFin5110ReviewDistributionMethodSummary';
        }
        // @ts-ignore
        if (rowData.some(p => p.dealClearedWithin1MonthPercent)) {
          this.viewName = 'firmCorpFin5110ReviewCurrentPeriodClearanceSummary';
        }
      }

        const chartData = this.corpFinService.getPublicOfferingFormattedChart(
          this.rowData,
          this.viewName
        );
        this.chartData = chartData;
      }
    }

  faQuestionCircle: IconDefinition = faQuestionCircle;
  chartData;
  viewType = 'chart';

  constructor(
    private corpFinService: CorpFinService,
    private beastService: BeastService,
  ) {
    this.corpFinService.setViewType('chart');
  }

  ngOnInit() {
    // Updating view type for all tables using behavior subject and loading chart data
    this.corpFinService.getViewTypeSubject().subscribe((viewType: string) => {
      this.viewType = viewType;
    });
  }

  switchView(viewType) {
    // Setting view type for all tables using behavior subject
    this.corpFinService.setViewType(viewType);
  }

  showView(viewType) {
    const currentViewType = this.corpFinService.getViewType();
    return ['all', viewType].includes(currentViewType);
  }

  yAxisTickFormatting(value) {
    return `${value}%`;
  }

  openHelp() {
    window.open(this.helpLink, '_blank');
    const eventInfo: BeastClickEventReportHelpLinkInfo = {
      reportType: this.reportInstanceMetadata.reportConfiguration.reportDisplayName
    };
    this.beastService.clickStream.postEvent(
      BeastClickActions.REPORT_HELP_LINK_CLICK,
      eventInfo
    );
  }
}
