/**
 * LOPR - Intrusion Detection Report: Model
 *
 * DML script - https://bitbucket.finra.org/projects/DDWA/repos/ddwa-db/commits/9965cd228bbaa04272e8a33075614dff9effe9c6
 */

import {
  addFinraGridColumnId,
  buildExportDataString,
  finraGridNumericValueFormatter,
  DATE_PIPE,
  SummaryExportData,
  trimTrailingChar,
} from 'src/app/shared/utils/utils';

/**
 * payload structure defined here:
 * https://bitbucket.finra.org/projects/DDWA/repos/ddwa-services/pull-requests/879/diff#code/ddwa-services/ddwa-services-service/src/main/java/org/finra/ddwa2/service/handler/impl/lopr/LoprIntrusionDetectionReportV1.java
*/
export interface LoprIntrusionDetectionGridData {
  participantCrd: string;
  exceptionReportDate: string;

  profitExceptionCount: string;
  harmExceptionCount: string;
  totalExceptionCount: string;
}

export interface LoprIntrusionDetectionSummaryResponse {
  summary: LoprIntrusionDetectionGridData[];
}

export const LOPR_INTRUSION_DETECTION_COLUMN_DEFS = addFinraGridColumnId([
  {
    headerName: 'Exception Periods',
    headerClass: 'lines',
    cellClass: 'lines',
    field: 'exceptionReportDate',
    valueFormatter: (params) => DATE_PIPE.transform(params.value, 'MM/dd/yyyy'),
  },
  {
    headerName: 'Potential Profit',
    headerClass: 'lines',
    cellClass: 'lines',
    field: 'profitExceptionCount',
    valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: 'Potential Victim',
    headerClass: 'lines',
    cellClass: 'lines',
    field: 'harmExceptionCount',
    valueFormatter: finraGridNumericValueFormatter,
  },
  {
    headerName: 'Total Exceptions',
    headerClass: 'lines',
    cellClass: 'lines',
    field: 'totalExceptionCount',
    valueFormatter: finraGridNumericValueFormatter,
  },
]);

export function exportLoprIntrusionDetectionSummaryData(rowData, title): string {
  const exportDataConfig: SummaryExportData[] = [
    {
      title: title,
      rowData: rowData['intrusion_detection'],
      columnLabels: [
        'Exception Periods',
        'Potential Profit',
        'Potential Victim',
        'Total Exceptions',
      ],
      dataMappings: [
        'exceptionReportDate',
        'profitExceptionCount',
        'harmExceptionCount',
        'totalExceptionCount',
      ],
    },
  ];
  
  const csvData = buildExportDataString(exportDataConfig);
  const formatted = trimTrailingChar(csvData, '\n');
  return formatted;
}