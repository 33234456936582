import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  ViewContainerRef
} from '@angular/core';
import { ChartDataService } from 'src/app/services/chart-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartData } from 'src/app/configs/model/chart-data.model';

@Component({
  selector: 'report-finra-chart',
  templateUrl: './finra-chart.component.html',
  styleUrls: ['./finra-chart.component.scss']
})
export class FinraChartComponent implements OnInit {
  @ViewChild('finraChart', { static: true }) finraChart: ElementRef;

  /** Options */

  @Input() lineTooltipTemplateValueFormatter: (arg: any) => any;
  @Input() lineSeriesTooltipTemplateValueFormatter: (arg: any) => any;

  @Input() rowData: any[] = [];
  @Input() view: any[] = [];
  @Input() template: string = '';
  @Input() showXAxis = true;
  @Input() showYAxis = true;
  @Input() gradient = false;
  @Input() showLegend = true;
  @Input() legendLabel = '';
  @Input() legendPosition = 'below';
  @Input() showXAxisLabel = false;
  @Input() xAxisLabel = '';
  @Input() showYAxisLabel = false;
  @Input() yAxisLabel = '';
  @Input() timeline = true;
  @Input() tooltipDisabled = false;
  @Input() autoScale = true; // line, area
  @Input() xAxisTicks = null;
  @Input() yAxisTicks = null;
  @Input() barPadding: number = 20;
  @Input() xAxisTickFormatting = null;
  @Input() yAxisTickFormatting = null;
  @Input() xScaleMin: number = null;
  @Input() xScaleMax: number = null;
  @Input() yScaleMin: number = null;
  @Input() yScaleMax: number = null;
  @Input() pieLabel: string;
  @Input() colorSchemeBar = {
    domain: ['#0081D1', '#5E26A3', '#9EC406', '#233E66']
  };
  @Input() colorScheme = {
    domain: [
      '#233e66',
      '#0082d1',
      '#9ec405',
      '#3c7d9a',
      '#768347',
      '#5e25a3',
      '#fb483d',
      '#4f6585',
      '#339bda',
      '#b1d037',
      '#668fa9',
      '#8d9564',
      '#7e51b5',
      '#fc6d64',
      '#7b8ba3',
      '#66b4e3',
      '#c5dc69',
      '#88a5ba',
      '#a5a982',
      '#9e7cc8',
      '#fd918b',
      '#a7b2c2',
      '#7fc0e8',
      '#d8e78b',
      '#abbecd',
      '#bfc1a5',
      '#bfa8da',
      '#fcb6b1',
      '#d3d8e0',
      '#99cded',
      '#ecf3cd'
    ]
  };


  constructor(
    private chartDataService: ChartDataService,
  ) {}

  ngOnInit() {
    // Setting width from client width on first load as well to fit all screen sizes.
    this.view = [this.finraChart.nativeElement.clientWidth - 20, 300];
  }

  onSelect(event) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // DDWA-2638 Setting view height and width on grid resize.
    this.view = [this.finraChart.nativeElement.clientWidth - 20, 300];
  }

  chartData() {
    this.chartDataService.getRelationShips().subscribe((data: ChartData[]) => {
      this.rowData = data;
    });
  }

  percentageFormatting(c) {
    const formattedPercent = Math.round(c);
    return formattedPercent;
  }

  formattedTooltip(model) {
    const tip = `${model.name}: ${Math.floor(model.value * 100) / 100}%`;
    const formatted =  model.count
      ? tip.concat(` | ${model.count}`)
      : tip;
    return formatted;
  }

  lineTooltipTemplateValueFormat(model) {
    const useValue = this.lineTooltipTemplateValueFormatter
      ? this.lineTooltipTemplateValueFormatter(model)
      : model.value;
    return useValue;
  }

  lineSeriesTooltipTemplateValueFormat(model) {
    const useValue = this.lineSeriesTooltipTemplateValueFormatter
      ? this.lineSeriesTooltipTemplateValueFormatter(model)
      : model.value;
    return useValue;
  }
}
