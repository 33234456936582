<div #cellContainerElm>
  <ng-container *ngIf="cell && !cell.hideCell">
    <ng-container *ngIf="!!cell.components && !cell.component">
      <div [ngClass]="cell.cellClass" [ngStyle]="cell.cellStyle">
        <report-finra-toolbar-cell
          *ngFor="let innerCell of cell.components"
          [cell]="innerCell"
        ></report-finra-toolbar-cell>
      </div>
    </ng-container>

    <ng-container *ngIf="!!cell.component && !cell.components">
      <div [ngClass]="cell.cellClass" [ngStyle]="cell.cellStyle" [attr.id]="cell.cellId">
        <ng-container [ngSwitch]="cell.component.type">
          <!-- Label -->
          <div *ngSwitchCase="toolbarComponentTypes.LABEL">
            <label> {{ cell.component.label }}: </label>
            <span class="text-truncate" [attr.id]="cell.component.labelId">
              {{
                (
                  cell.component.useValueObject
                    ? cell.component.valueObject[cell.component.valueObjectProp]
                    : cell.component.value
                ) | finraValidValue : cell.component.displayPipeFilter : cell.component.displayPipeFilterArg
              }}

              <!-- <span [innerHTML]="(cell.component.useValueObject ? cell.component.valueObject[cell.component.valueObjectProp] : cell.component.value) | finraValidValue : cell.component.displayPipeFilter : cell.component.displayPipeFilterArg"></span> -->
            </span>
          </div>

          <!-- Button -->
          <div
            *ngSwitchCase="toolbarComponentTypes.BUTTON"
            class="controls-container no-print"
            [ngClass]="cell.component.buttonClass"
            [ngStyle]="cell.component.buttonStyle"
            data-html2canvas-ignore
          >
            <span (click)="!!cell.component.clickHandler && cell.component.clickHandler($event)" [attr.id]="cell.component.buttonId">
              <fa-icon
                *ngIf="cell.component.buttonIcon"
                [icon]="cell.component.buttonIcon"
                [spin]="cell.component.spin"
                [ngClass]="cell.component.buttonIconClass"
                class="fa-lg dialog-tools-icon"
              ></fa-icon>
              {{
                cell.component.useLabelObject
                  ? cell.component.buttonLabelObject[cell.component.labelObjectProp]
                  : cell.component.buttonLabel
              }}
            </span>
          </div>

          <ng-container
            *ngSwitchCase="toolbarComponentTypes.CONTROL"
            [ngSwitch]="cell.component.controlType"
          >
            <label for="">{{ cell.component.controlLabel }}</label>
            <!-- Select -->
            <ng-container *ngSwitchCase="toolbarControlComponentTypes.SELECT">
              <mat-form-field
                [ngClass]="cell.component.formControlClass"
                [ngStyle]="cell.component.formControlStyle"
                [floatLabel]="'never'"
              >
                <ng-container [ngSwitch]="!!cell.component.formControl">
                  <mat-select
                    *ngSwitchCase="true"
                    [attr.data-formControlName]="cell.component.formControlName"
                    [formControl]="cell.component.formControl"
                    (selectionChange)="
                      cell.component.onSelectionChange &&
                        cell.component.onSelectionChange($event)
                    "
                  >
                    <mat-option
                      *ngFor="let i of cell.component.dataList"
                      [value]="
                        (cell.component.useObjectValueProperty && i[cell.component.valueProp] || i)
                      "
                    >
                      {{
                        (cell.component.useObjectDisplayProperty && i[cell.component.displayProp] || i)
                          | finraValidValue
                            : cell.component.displayPipeFilter
                            : cell.component.displayPipeFilterArg
                      }}
                    </mat-option>
                  </mat-select>

                  <mat-select
                    *ngSwitchCase="false"
                    [attr.data-formControlName]="cell.component.formControlName"
                    (selectionChange)="
                      cell.component.onSelectionChange &&
                        cell.component.onSelectionChange($event)
                    "
                  >
                    <mat-option
                      *ngFor="let i of cell.component.dataList"
                      [value]="
                        (cell.component.useObjectValueProperty && i[cell.component.valueProp] || i)
                      "
                    >
                      {{
                        (cell.component.useObjectDisplayProperty && i[cell.component.displayProp] || i)
                          | finraValidValue
                            : cell.component.displayPipeFilter
                            : cell.component.displayPipeFilterArg
                      }}
                    </mat-option>
                  </mat-select>
                </ng-container>
                <fa-icon
                  [icon]="faAngleDown"
                  class="fa-lg filter-icon no-print"
                  data-html2canvas-ignore
                ></fa-icon>
              </mat-form-field>
            </ng-container>

            <!-- Date Picker -->
            <ng-container
              *ngSwitchCase="toolbarControlComponentTypes.DATE_PICKER"
            >
              <mat-form-field>
                <mat-label>
                  {{ cell.component.datePickerLabel }}
                </mat-label>
                <ng-container [ngSwitch]="!!cell.component.formControl">
                  <input
                    *ngSwitchCase="true"
                    matInput
                    [formControl]="cell.component.formControl"
                    [matDatepicker]="picker"
                    [matDatepickerFilter]="cell.component.matDatepickerFilter"
                    [disabled]="true"
                    (dateInput)="
                      cell.component.onDateInput &&
                        cell.component.onDateInput('input', $event)
                    "
                  />
                  <input
                    *ngSwitchCase="false"
                    matInput
                    [matDatepicker]="picker"
                    [matDatepickerFilter]="cell.component.matDatepickerFilter"
                    [disabled]="true"
                    (dateInput)="
                      cell.component.onDateInput &&
                        cell.component.onDateInput('input', $event)
                    "
                  />
                </ng-container>
                <mat-datepicker-toggle
                  class="no-line-height"
                  matSuffix
                  [for]="picker"
                >
                  <mat-icon matDatepickerToggleIcon>
                    <fa-icon
                      [icon]="cell.component.datePickerIcon"
                      class="fa-lg"
                    ></fa-icon>
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="false"></mat-datepicker>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
